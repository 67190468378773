import { Button, Icon, Text } from '@orbiapp/components';

import { Styled } from './device-card.styled';
import { DeviceCardProps } from './device-card.types';

export function DeviceCard(props: DeviceCardProps) {
  const {
    buttonText,
    buttonTx,
    icon,
    subtitle,
    subtitleTx,
    title,
    titleTx,
    to,
  } = props;

  return (
    <Styled.DeviceCard
      flex
      flexAlign="center"
      flexDirection="column"
      px={31}
      py={65}
      r={8}
    >
      <Text variant="titleMd" text={title} tx={titleTx} />

      <Icon size={40} name={icon} mt={16} />

      <Text
        maxWidth="40ch"
        mt={16}
        text={subtitle}
        textAlign="center"
        tx={subtitleTx}
        variant="bodyMd"
      />

      <Button
        mt={32}
        text={buttonText}
        to={to}
        tx={buttonTx}
        variant="secondary"
      />
    </Styled.DeviceCard>
  );
}
