import { Chip } from '@orbiapp/components';
import { PostTarget } from 'dtos/student-dashboard/v1/post/post.dto';

export function PostTargetChip(props: PostTarget) {
  switch (props.type) {
    case 'activity':
      return <Chip variant={4} tx="label.posts.table.activity" />;

    case 'department':
      return <Chip variant={5} tx="label.posts.table.department" />;

    case 'membershipType':
      return <Chip variant={6} tx="label.posts.table.membership" />;
  }
}
