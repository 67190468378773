import Joi from 'joi';

import { OrgNodeErrors } from './org-node.errors';

export namespace OrgNodeSchema {
  export const departmentKeys = Joi.array()
    .items(Joi.string().required())
    .allow(null)
    .required();

  export const orgNodeKeys = Joi.array()
    .items(Joi.string().required())
    .required()
    .messages(OrgNodeErrors.orgNodeKeys);
}
