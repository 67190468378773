import { toScu } from '@orbiapp/components';

import { MembershipPeriodForm } from '../../../../../../models';
import {
  CreateMembershipPeriodDto,
  UpdateMembershipPeriodDto,
} from './periods.dto';

export const createPeriodMapper = (
  period: MembershipPeriodForm,
): CreateMembershipPeriodDto => {
  return {
    name: period.name,
    price: toScu(period.price),
    saleEnd: period.saleEnd,
    saleStart: period.saleStart,
    validFrom: period.lifetime ? null : period.validFrom,
    validTo: period.lifetime ? null : period.validTo,
  };
};

export const updatePeriodMapper = (
  period: MembershipPeriodForm,
): UpdateMembershipPeriodDto => {
  return {
    name: period.name,
    price: toScu(period.price),
    saleEnd: period.saleEnd,
    saleStart: period.saleStart,
    validFrom: period.lifetime ? null : period.validFrom,
    validTo: period.lifetime ? null : period.validTo,
  };
};
