import { joiResolver } from '@hookform/resolvers/joi';
import {
  Button,
  ControlledTextarea,
  INITIAL_MODAL_STATE,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalState,
  ModalTitle,
  useModalState,
} from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  EditPostCommentForm,
  EditPostCommentValidation,
  POST_MESSAGE_MAX_LENGTH,
} from '../../models';
import {
  UpdatePostCommentSelector,
  updatePostCommentThunk,
  useDispatch,
  useSelector,
} from '../../store';

type EditPostCommentContextValue = {
  postKey: string;
  postCommentKey: string;

  message: string | null;
};

export const EditPostCommentContext =
  React.createContext<ModalState<EditPostCommentContextValue>>(
    INITIAL_MODAL_STATE,
  );

function EditPostModal() {
  const { value, closeModal, isOpen } = React.useContext(
    EditPostCommentContext,
  );

  const dispatch = useDispatch();
  const updatePostCommentStatus = useSelector(
    UpdatePostCommentSelector.selectStatus,
  );

  const formMethods = useForm<EditPostCommentForm>({
    defaultValues: {
      message: value?.message ?? '',
    },
    resolver: joiResolver(EditPostCommentValidation),
  });

  const closeAndReset = () => {
    closeModal();

    window.setTimeout(() => {
      formMethods.reset();
    }, transitonDuration.slow);
  };

  const updatePostComment = formMethods.handleSubmit(async (data) => {
    if (!value?.postKey) return;

    const res = await dispatch(
      updatePostCommentThunk({
        message: data.message,
        postKey: value.postKey,
        postCommentKey: value.postCommentKey,
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      closeAndReset();
    }
  });

  const messageRef = React.useRef<HTMLTextAreaElement | null>(null);

  React.useEffect(() => {
    formMethods.reset({ message: value?.message ?? '' });

    if (isOpen) {
      messageRef.current?.focus();
    }
  }, [value, formMethods, isOpen]);

  return (
    <FormProvider {...formMethods}>
      <Modal
        hideCloseButton
        isOpen={isOpen}
        onClose={closeModal}
        width={700}
        zIndex={1005}
      >
        <ModalContentContainer>
          <ModalHeaderContainer>
            <ModalTitle tx="label.posts.edit-comment-modal.title" />
          </ModalHeaderContainer>

          <ModalBodyContainer>
            <ControlledTextarea
              name="message"
              labelTx="label.posts.create-post-modal.message"
              maxLength={POST_MESSAGE_MAX_LENGTH}
              ref={messageRef}
            />
          </ModalBodyContainer>

          <ModalFooterContainer flexJustify="end">
            <Button
              tx="button.cancel"
              variant="tertiary"
              onClick={closeAndReset}
            />

            <Button
              tx="label.posts.edit-comment-modal.button"
              variant="primary"
              onClick={updatePostComment}
              isLoading={updatePostCommentStatus === 'pending'}
            />
          </ModalFooterContainer>
        </ModalContentContainer>
      </Modal>
    </FormProvider>
  );
}

export function EditPostCommentProvider(props: React.PropsWithChildren) {
  const modalState = useModalState<EditPostCommentContextValue>();

  return (
    <EditPostCommentContext.Provider value={modalState}>
      <EditPostModal />

      {props.children}
    </EditPostCommentContext.Provider>
  );
}
