import { useDebounce } from '@orbiapp/components';
import React from 'react';

import { PartialDepartment, StatusType } from '../models';
import { searchDepartmentsThunk, useDispatch } from '../store';

export function useDepartmentSearch() {
  const [searchResult, setSearchResult] = React.useState<PartialDepartment[]>(
    [],
  );
  const [status, setStatus] = React.useState<StatusType>('idle');
  const [searchString, setSearchString] = React.useState<string>('');

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const search = (searchString: string) => {
    setSearchString(searchString);

    if (!searchString.length) return;

    debounce(async () => {
      setStatus('pending');

      const res = await dispatch(searchDepartmentsThunk({ searchString }));

      if (Array.isArray(res.payload)) {
        setSearchResult(res.payload);
      }

      setStatus('completed');
    });
  };

  return { status, search, searchResult, searchString, setSearchString };
}
