import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './offers.build';
import { initialOffersState } from './offers.initial';
import { reducers } from './offers.reducers';

const slice = createSlice({
  name: 'offers',
  initialState: initialOffersState,
  reducers,
  extraReducers,
});

export const offersActions = slice.actions;
export const offersReducer = slice.reducer;
