import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './orbi-pay.build';
import { initialOrbiPayState } from './orbi-pay.initial';
import { reducers } from './orbi-pay.reducers';

const orbiPaySlice = createSlice({
  name: 'orbi-pay',
  initialState: initialOrbiPayState,
  reducers,
  extraReducers,
});

export const orbiPayActions = orbiPaySlice.actions;
export const orbiPayReducer = orbiPaySlice.reducer;
