import {
  MembershipMultichoiceOptionForm,
  MembershipMultichoiceQuestionForm,
} from '../../../../../../models';
import {
  CreateMembershipMultichoiceOptionDto,
  CreateMembershipMultichoiceQuestionDto,
  UpdateMembershipMultichoiceOptionDto,
  UpdateMembershipMultichoiceQuestionDto,
} from './multichoice-questions.dto';

const createMultichoiceOptionMapper = (
  option: MembershipMultichoiceOptionForm,
  index: number,
): CreateMembershipMultichoiceOptionDto => ({
  index,
  label: option.label,
});

export const createMultichoiceMapper = (
  multichoice: MembershipMultichoiceQuestionForm,
): CreateMembershipMultichoiceQuestionDto => ({
  isRequired: multichoice.isRequired,
  options: multichoice.options.map(createMultichoiceOptionMapper),
  question: multichoice.question,
});

export const updateMultichoiceOptionMapper = (
  option: MembershipMultichoiceOptionForm,
  index: number,
): UpdateMembershipMultichoiceOptionDto => ({
  index,
  label: option.label,
  membershipMultichoiceOptionKey: option.isNew ? null : option.key,
});

export const updateMultichoiceMapper = (
  multichoice: MembershipMultichoiceQuestionForm,
): UpdateMembershipMultichoiceQuestionDto => ({
  isRequired: multichoice.isRequired,
  options: multichoice.options.map(updateMultichoiceOptionMapper),
  question: multichoice.question,
});
