import React from 'react';

import { IconName } from '../icon';
import { TxArgs } from '../text';
import { Styled } from './vertical-button.styled';

interface VerticalButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  icon?: IconName;
  text?: string;
  tx?: TxString;
  txArgs?: TxArgs;
}

export function VerticalButton(props: VerticalButtonProps) {
  const { icon, text, tx, txArgs, ...rest } = props;

  return (
    <Styled.RotatedBox>
      <Styled.VerticalButton {...rest}>
        <Styled.VerticalButtonText
          tx={tx}
          txArgs={txArgs}
          text={text}
          variant="buttonSm"
        />

        {icon && <Styled.VerticalButtonIcon name={icon} size={24} />}
      </Styled.VerticalButton>
    </Styled.RotatedBox>
  );
}
