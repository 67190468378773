export const transitonDuration = {
  default: 300,
  fast: 150,
  slow: 450,
};

export const transitions = {
  default: `${transitonDuration.default}ms ease-in-out`,
  fast: `${transitonDuration.fast}ms ease-in-out`,
  slow: `${transitonDuration.slow}ms ease-in-out`,
};
