import { Confirm } from '@orbiapp/components';
import React from 'react';
import { useBlocker } from 'react-router-dom';

import { CreateActivitySelector, useSelector } from '../../../../../store';
import { ActivityFormContext } from '../create-activity.context';
import { useRootPath } from './tab-header';

export function ActivityFormNavBlocker() {
  const activityFormContext = React.useContext(ActivityFormContext);
  const activityKey = useSelector(CreateActivitySelector.selectActivityKey);

  const blocker = useBlocker(activityFormContext.formState.isDirty);

  const rootPath = useRootPath();

  const nextPathIsCreateActivityRoute =
    blocker.location?.pathname.startsWith(rootPath);

  React.useEffect(() => {
    if (!activityKey && !nextPathIsCreateActivityRoute) return;

    blocker.proceed?.();
  }, [activityKey, blocker, nextPathIsCreateActivityRoute]);

  return (
    <Confirm
      isOpen={blocker.state === 'blocked' && !nextPathIsCreateActivityRoute}
      onCancel={blocker.reset || (() => {})}
      onConfirm={blocker.proceed || (() => {})}
      cancelTx="prompt.unsaved-changes.cancel"
      confirmTx="prompt.unsaved-changes.confirm"
      titleTx="prompt.unsaved-changes.title"
      messageTx="prompt.unsaved-changes.message"
    />
  );
}
