import { Box, BoxProps } from '../box';
import { ResponsiveBox } from '../responsive-box';

export function FormBox(props: BoxProps) {
  return (
    <ResponsiveBox
      maxWidth="100%"
      xs={
        <Box
          pt={0}
          px={32}
          pb={32}
          flex
          flexDirection="column"
          gap={8}
          maxWidth="100%"
          width={772 + 64}
          {...props}
        />
      }
    >
      <Box
        flex
        flexDirection="column"
        gap={8}
        maxWidth="100%"
        width={772 + 64}
        {...props}
      />
    </ResponsiveBox>
  );
}
