import { createAsyncThunk } from '@reduxjs/toolkit';

import { JobListItem } from '../../models';
import { OrbiApi, v2 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';

export const hideJobThunk = createAsyncThunk<undefined, string, ThunkApiConfig>(
  'jobs/hide',
  async (jobKey, thunkAPI) => {
    const res = await OrbiApi.call(v2.jobs.hideJob, jobKey);

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('hide-job:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('hide-job:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getExternalJobsThunk = createAsyncThunk<
  JobListItem[],
  undefined,
  ThunkApiConfig
>('jobs/get-external-jobs', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.jobs.getJobListItems, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
