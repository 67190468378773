import { MembershipCheckboxQuestionForm } from '../../../../../../models';
import {
  CreateMembershipCheckboxQuestionDto,
  UpdateMembershipCheckboxQuestionDto,
} from './checkbox-questions.dto';

export const createCheckboxMapper = (
  checkbox: MembershipCheckboxQuestionForm,
): CreateMembershipCheckboxQuestionDto => ({
  isRequired: checkbox.isRequired,
  question: checkbox.question,
});

export const updateCheckboxMapper = (
  checkbox: MembershipCheckboxQuestionForm,
): UpdateMembershipCheckboxQuestionDto => ({
  isRequired: checkbox.isRequired,
  question: checkbox.question,
});
