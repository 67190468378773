import { MembershipFreetextQuestionForm } from '../../../../../../models';
import {
  CreateMembershipFreetextQuestionDto,
  UpdateMembershipFreetextQuestionDto,
} from './freetext-questions.dto';

export const createFreetextMapper = (
  freetext: MembershipFreetextQuestionForm,
): CreateMembershipFreetextQuestionDto => ({
  isRequired: freetext.isRequired,
  question: freetext.question,
});

export const updateFreetextMapper = (
  freetext: MembershipFreetextQuestionForm,
): UpdateMembershipFreetextQuestionDto => ({
  isRequired: freetext.isRequired,
  question: freetext.question,
});
