import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SettingCardGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: ${theme.breakpoints.xs}px) {
        display: grid;
        gap: 32px;
        grid-template-columns: 1fr 1fr;
        width: fit-content;
      }
    `;
  }}
`;

const ContactDetailsGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      display: grid;
      gap: 16px;
      width: fit-content;
      grid-template-columns: 1fr;

      @media (min-width: ${theme.breakpoints.xs}px) {
        grid-template-columns: 1fr 1fr;
      }
    `;
  }}
`;

const CopyMembershipLinkBox = styled(Box)`
  width: fit-content;
  display: inline-flex;
  gap: 10px;
  align-items: center;
`;

export const Styled = {
  ContactDetailsGrid,
  CopyMembershipLinkBox,
  SettingCardGrid,
};
