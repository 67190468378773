import { BRANCH_IO_KEY } from '../../config';
import { Logger } from '../logger';

function createLink(params: Record<string, any>): Promise<string> {
  return new Promise((resolve, reject) => {
    window.branch.link(params, (err, link) => {
      if (err) {
        reject(err);
      } else {
        resolve(link);
      }
    });
  });
}

window.branch.init(BRANCH_IO_KEY, (err, data) => {
  if (err) {
    Logger.error('Branch IO init error', { err, data });
  }
});

export const branchIO = { createLink };
