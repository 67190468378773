import { joiResolver } from '@hookform/resolvers/joi';
import {
  Button,
  ControlledTextarea,
  INITIAL_MODAL_STATE,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalState,
  ModalTitle,
  Portal,
  useModalState,
} from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  CreatePostCommentForm,
  CreatePostCommentValidation,
  POST_COMMENT_MESSAGE_MAX_LENGTH,
} from '../../models';
import {
  CreatePostCommentSelector,
  createPostCommentThunk,
  useDispatch,
  useSelector,
} from '../../store';

export const CreatePostCommentContext =
  React.createContext<ModalState<string>>(INITIAL_MODAL_STATE);

function CreatePostCommentModal() {
  const { value, closeModal, isOpen } = React.useContext(
    CreatePostCommentContext,
  );

  const dispatch = useDispatch();
  const updatePostStatus = useSelector(CreatePostCommentSelector.selectStatus);

  const formMethods = useForm<CreatePostCommentForm>({
    defaultValues: {
      message: '',
    },
    resolver: joiResolver(CreatePostCommentValidation),
  });

  const closeAndReset = () => {
    closeModal();

    window.setTimeout(() => {
      formMethods.reset();
    }, transitonDuration.slow);
  };

  const createPostComment = formMethods.handleSubmit(async (data) => {
    if (!value) return;
    const res = await dispatch(
      createPostCommentThunk({
        message: data.message,
        postKey: value,
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      closeAndReset();
    }
  });

  const messageRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    formMethods.reset({ message: '' });

    if (isOpen) {
      messageRef.current?.focus();
    }
  }, [value, formMethods, isOpen]);

  return (
    <Portal>
      <FormProvider {...formMethods}>
        <Modal
          hideCloseButton
          zIndex={1005}
          width={700}
          onClose={closeModal}
          isOpen={isOpen}
        >
          <ModalContentContainer>
            <ModalHeaderContainer>
              <ModalTitle tx="label.posts.create-post-comment-modal.title" />
            </ModalHeaderContainer>

            <ModalBodyContainer>
              <ControlledTextarea
                name="message"
                labelTx="label.posts.create-post-modal.message"
                maxLength={POST_COMMENT_MESSAGE_MAX_LENGTH}
                ref={messageRef}
              />
            </ModalBodyContainer>

            <ModalFooterContainer flexJustify="end">
              <Button
                tx="button.cancel"
                variant="tertiary"
                onClick={closeAndReset}
              />

              <Button
                tx="label.posts.create-post-comment-modal.button"
                variant="primary"
                onClick={createPostComment}
                isLoading={updatePostStatus === 'pending'}
              />
            </ModalFooterContainer>
          </ModalContentContainer>
        </Modal>
      </FormProvider>
    </Portal>
  );
}

export function CreatePostCommentProvider(props: React.PropsWithChildren) {
  const modalState = useModalState<string>();

  return (
    <CreatePostCommentContext.Provider value={modalState}>
      <CreatePostCommentModal />

      {props.children}
    </CreatePostCommentContext.Provider>
  );
}
