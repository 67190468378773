import { palette } from './palette';

export const colors = {
  ...palette, // TODO - remove this when all designTokens are implemented

  textPrimary: palette.neutral[100],
  textSecondary: palette.neutral[400],
  textLight: palette.neutral.white,

  successPrimary: palette.success[200],
  errorPrimary: palette.alert[200],
  warningPrimary: palette.warning[200],
  informationPrimary: palette.information[200],

  backgroundPrimary: palette.neutral.white,
  backgroundTeritary: palette.neutral[1000],

  borderPrimary: palette.neutral[800],

  /*
   * Primary button
   */
  buttonPrimaryBackground: palette.neutral[100],
  buttonPrimaryBorder: palette.neutral[100],
  buttonPrimaryIcon: palette.neutral.white,
  buttonPrimaryLabel: palette.neutral.white,

  buttonPrimaryBackgroundHover: palette.transparent,
  buttonPrimaryBorderHover: palette.neutral[100],
  buttonPrimaryIconHover: palette.neutral[100],
  buttonPrimaryLabelHover: palette.neutral[100],

  buttonPrimaryBackgroundActive: palette.neutral[100],
  buttonPrimaryBorderActive: palette.primary[300],
  buttonPrimaryIconActive: palette.neutral.white,
  buttonPrimaryLabelActive: palette.neutral.white,

  buttonPrimaryBackgroundDisabled: palette.neutral[100],
  buttonPrimaryBorderDisabled: palette.neutral[100],
  buttonPrimaryIconDisabled: palette.neutral.white,
  buttonPrimaryLabelDisabled: palette.neutral.white,

  /*
   * Primary on dark button
   */
  buttonPrimaryOnDarkBackground: palette.neutral.white,
  buttonPrimaryOnDarkBorder: palette.transparent,
  buttonPrimaryOnDarkIcon: palette.neutral[100],
  buttonPrimaryOnDarkLabel: palette.neutral[100],

  buttonPrimaryOnDarkBackgroundHover: palette.transparent,
  buttonPrimaryOnDarkBorderHover: palette.neutral.white,
  buttonPrimaryOnDarkIconHover: palette.neutral.white,
  buttonPrimaryOnDarkLabelHover: palette.neutral.white,

  buttonPrimaryOnDarkBackgroundActive: palette.neutral.white,
  buttonPrimaryOnDarkBorderActive: palette.neutral.white,
  buttonPrimaryOnDarkIconActive: palette.neutral[100],
  buttonPrimaryOnDarkLabelActive: palette.neutral[100],

  buttonPrimaryOnDarkBackgroundDisabled: palette.neutral.white,
  buttonPrimaryOnDarkBorderDisabled: palette.transparent,
  buttonPrimaryOnDarkIconDisabled: palette.neutral[100],
  buttonPrimaryOnDarkLabelDisabled: palette.neutral[100],

  /*
   * Secondary button
   */
  buttonSecondaryBackground: palette.transparent,
  buttonSecondaryBorder: palette.secondary[300],
  buttonSecondaryIcon: palette.neutral[100],
  buttonSecondaryLabel: palette.neutral[100],

  buttonSecondaryBackgroundHover: palette.secondary[300],
  buttonSecondaryBorderHover: palette.secondary[300],
  buttonSecondaryIconHover: palette.neutral[100],
  buttonSecondaryLabelHover: palette.neutral[100],

  buttonSecondaryBackgroundActive: palette.transparent,
  buttonSecondaryBorderActive: palette.neutral[100],
  buttonSecondaryIconActive: palette.neutral[100],
  buttonSecondaryLabelActive: palette.neutral[100],

  buttonSecondaryBackgroundDisabled: palette.transparent,
  buttonSecondaryBorderDisabled: palette.secondary[300],
  buttonSecondaryIconDisabled: palette.neutral[100],
  buttonSecondaryLabelDisabled: palette.neutral[300],

  /*
   * Secondary on dark button
   */
  buttonSecondaryOnDarkBackground: palette.transparent,
  buttonSecondaryOnDarkBorder: palette.secondary[300],
  buttonSecondaryOnDarkIcon: palette.neutral.white,
  buttonSecondaryOnDarkLabel: palette.neutral.white,

  buttonSecondaryOnDarkBackgroundHover: palette.secondary[300],
  buttonSecondaryOnDarkBorderHover: palette.secondary[300],
  buttonSecondaryOnDarkIconHover: palette.neutral[100],
  buttonSecondaryOnDarkLabelHover: palette.neutral[100],

  buttonSecondaryOnDarkBackgroundActive: palette.transparent,
  buttonSecondaryOnDarkBorderActive: palette.neutral.white,
  buttonSecondaryOnDarkIconActive: palette.neutral.white,
  buttonSecondaryOnDarkLabelActive: palette.neutral.white,

  buttonSecondaryOnDarkBackgroundDisabled: palette.transparent,
  buttonSecondaryOnDarkBorderDisabled: palette.secondary[300],
  buttonSecondaryOnDarkIconDisabled: palette.neutral.white,
  buttonSecondaryOnDarkLabelDisabled: palette.neutral.white,

  /*
   * Tertiary button
   */
  buttonTertiaryBackground: palette.transparent,
  buttonTertiaryBorder: palette.transparent,
  buttonTertiaryIcon: palette.tertiary[100],
  buttonTertiaryLabel: palette.neutral[100],

  buttonTertiaryBackgroundHover: palette.neutral[900],
  buttonTertiaryBorderHover: palette.neutral[900],
  buttonTertiaryIconHover: palette.neutral[100],
  buttonTertiaryLabelHover: palette.neutral[100],

  buttonTertiaryBackgroundActive: palette.neutral[800],
  buttonTertiaryBorderActive: palette.neutral[800],
  buttonTertiaryIconActive: palette.neutral[100],
  buttonTertiaryLabelActive: palette.neutral[100],

  buttonTertiaryBackgroundDisabled: palette.transparent,
  buttonTertiaryBorderDisabled: palette.transparent,
  buttonTertiaryIconDisabled: palette.tertiary[100],
  buttonTertiaryLabelDisabled: palette.neutral[100],

  /*
   * Tertiary on dark button
   */
  buttonTertiaryOnDarkBackground: palette.transparent,
  buttonTertiaryOnDarkBorder: palette.transparent,
  buttonTertiaryOnDarkIcon: palette.neutral.white,
  buttonTertiaryOnDarkLabel: palette.neutral.white,

  buttonTertiaryOnDarkBackgroundHover: palette.neutral.white,
  buttonTertiaryOnDarkBorderHover: palette.neutral[100],
  buttonTertiaryOnDarkIconHover: palette.neutral[100],
  buttonTertiaryOnDarkLabelHover: palette.neutral[100],

  buttonTertiaryOnDarkBackgroundActive: palette.neutral[800],
  buttonTertiaryOnDarkBorderActive: palette.neutral[800],
  buttonTertiaryOnDarkIconActive: palette.neutral[100],
  buttonTertiaryOnDarkLabelActive: palette.neutral[100],

  buttonTertiaryOnDarkBackgroundDisabled: palette.transparent,
  buttonTertiaryOnDarkBorderDisabled: palette.transparent,
  buttonTertiaryOnDarkIconDisabled: palette.neutral.white,
  buttonTertiaryOnDarkLabelDisabled: palette.neutral.white,

  /*
   * Destructive button
   */
  buttonDestructiveBackground: palette.transparent,
  buttonDestructiveBorder: palette.transparent,
  buttonDestructiveIcon: palette.alert[200],
  buttonDestructiveLabel: palette.alert[200],

  buttonDestructiveBackgroundHover: palette.neutral[900],
  buttonDestructiveBorderHover: palette.transparent,
  buttonDestructiveIconHover: palette.alert[200],
  buttonDestructiveLabelHover: palette.alert[200],

  buttonDestructiveBackgroundActive: palette.neutral[800],
  buttonDestructiveBorderActive: palette.neutral[800],
  buttonDestructiveIconActive: palette.alert[200],
  buttonDestructiveLabelActive: palette.alert[200],

  buttonDestructiveBackgroundDisabled: palette.transparent,
  buttonDestructiveBorderDisabled: palette.transparent,
  buttonDestructiveIconDisabled: palette.alert[200],
  buttonDestructiveLabelDisabled: palette.alert[200],

  /*
   * Destructive on dark button
   */
  buttonDestructiveOnDarkBackground: palette.transparent,
  buttonDestructiveOnDarkBorder: palette.transparent,
  buttonDestructiveOnDarkIcon: palette.alert[300],
  buttonDestructiveOnDarkLabel: palette.alert[300],

  buttonDestructiveOnDarkBackgroundHover: palette.neutral[1000],
  buttonDestructiveOnDarkBorderHover: palette.transparent,
  buttonDestructiveOnDarkIconHover: palette.alert[200],
  buttonDestructiveOnDarkLabelHover: palette.alert[200],

  buttonDestructiveOnDarkBackgroundActive: palette.neutral[800],
  buttonDestructiveOnDarkBorderActive: palette.neutral[800],
  buttonDestructiveOnDarkIconActive: palette.alert[200],
  buttonDestructiveOnDarkLabelActive: palette.alert[200],

  buttonDestructiveOnDarkBackgroundDisabled: palette.transparent,
  buttonDestructiveOnDarkBorderDisabled: palette.transparent,
  buttonDestructiveOnDarkIconDisabled: palette.alert[300],
  buttonDestructiveOnDarkLabelDisabled: palette.alert[300],

  /**
   * Vertical button
   */
  verticalButtonBackground: palette.neutral[100],
  verticalButtonIcon: palette.neutral.white,
  verticalButtonLabel: palette.neutral.white,
  verticalButtonBorder: palette.transparent,

  verticalButtonBackgroundHover: palette.neutral[100],
  verticalButtonIconHover: palette.neutral.white,
  verticalButtonLabelHover: palette.neutral.white,
  verticalButtonBorderHover: palette.neutral[100],

  verticalButtonBackgroundActive: palette.neutral.white,
  verticalButtonIconActive: palette.neutral[100],
  verticalButtonLabelActive: palette.neutral[100],
  verticalButtonBorderActive: palette.neutral[100],

  verticalButtonBackgroundDisabled: palette.neutral[800],
  verticalButtonIconDisabled: palette.neutral[600],
  verticalButtonLabelDisabled: palette.neutral[600],
  verticalButtonBorderDisabled: palette.transparent,

  /*
   * Sidebar list item
   */
  sidebarListItemBackground: palette.transparent,
  sidebarListItemIcon: palette.neutral[500],
  sidebarListItemLabel: palette.neutral[500],

  sidebarListItemBackgroundActive: palette.neutral[900],
  sidebarListItemIconActive: palette.primary[300],
  sidebarListItemLabelActive: palette.neutral[100],

  /*
   * Input
   */
  inputText: palette.neutral[100],
  inputBackground: palette.neutral.white,
  inputBorder: palette.neutral[700],
  inputIcon: palette.neutral[500],
  inputLabel: palette.neutral[400],
  inputPlaceholder: palette.neutral[800],
  inputHelper: palette.neutral[100],
  inputHelperIcon: palette.neutral[500],
  inputApplyLabel: palette.neutral[300],

  inputTextFocus: palette.neutral[100],
  inputBackgroundFocus: palette.neutral.white,
  inputIconFocus: palette.neutral[500],
  inputLabelFocus: palette.neutral[400],
  inputBorderFocus: palette.neutral[300],
  inputPlaceholderFocus: palette.neutral[100],
  inputHelperFocus: palette.neutral[100],
  inputApplyLabelFocus: palette.primary[300],

  inputTextDisabled: palette.neutral[500],
  inputBackgroundDisabled: palette.neutral[1000],
  inputIconDisabled: palette.neutral[800],
  inputLabelDisabled: palette.neutral[600],
  inputBorderDisabled: palette.neutral[900],
  inputPlaceholderDisabled: palette.neutral[600],
  inputHelperDisabled: palette.transparent,
  inputHelperIconDisabled: palette.neutral[700],
  inputApplyLabelDisabled: palette.neutral[800],

  inputTextError: palette.neutral[100],
  inputBackgroundError: palette.neutral.white,
  inputIconError: palette.neutral[500],
  inputLabelError: palette.neutral[100],
  inputBorderError: palette.alert[200],
  inputPlaceholderError: palette.neutral[600],
  inputHelperError: palette.alert[200],
  inputApplyLabelError: palette.primary[300],

  /*
    Select
  */
  selectLabel: palette.neutral[100],
  selectSubtitle: palette.neutral[400],
  selectIcon: palette.neutral[100],

  /*
   * Dropdown button
   */
  dropdownButtonBackground: palette.transparent,
  dropdownButtonLabel: palette.neutral[100],
  dropdownButtonIcon: palette.neutral[500],
  dropdownButtonBorder: palette.neutral[800],

  dropdownButtonBackgroundHover: palette.neutral[1000],
  dropdownButtonLabelHover: palette.neutral[100],
  dropdownButtonIconHover: palette.neutral[500],
  dropdownButtonBorderHover: palette.neutral[800],

  dropdownButtonBackgroundActive: palette.primary[1000],
  dropdownButtonLabelActive: palette.neutral[100],
  dropdownButtonIconActive: palette.primary[300],
  dropdownButtonBorderActive: palette.primary[1000],

  dropdownButtonBackgroundToggled: palette.transparent,
  dropdownButtonLabelToggled: palette.neutral[100],
  dropdownButtonIconToggled: palette.primary[300],
  dropdownButtonBorderToggled: palette.primary[900],

  dropdownButtonBackgroundDisabled: palette.transparent,
  dropdownButtonLabelDisabled: palette.neutral[800],
  dropdownButtonIconDisabled: palette.neutral[800],
  dropdownButtonBorderDisabled: palette.neutral[1000],

  /*
   * Tab button
   */
  tabButtonBackground: palette.transparent,
  tabButtonBorder: palette.neutral[100],
  tabButtonIcon: palette.neutral[100],
  tabButtonLabel: palette.neutral[100],

  tabButtonBackgroundHover: palette.neutral[900],
  tabButtonBorderHover: palette.neutral[100],
  tabButtonIconHover: palette.neutral[100],
  tabButtonLabelHover: palette.neutral[100],

  tabButtonBackgroundActive: palette.transparent,
  tabButtonBorderActive: palette.primary[300],
  tabButtonIconActive: palette.neutral[100],
  tabButtonLabelActive: palette.neutral[100],

  tabButtonBackgroundToggled: palette.neutral[100],
  tabButtonBorderToggled: palette.neutral[100],
  tabButtonIconToggled: palette.neutral.white,
  tabButtonLabelToggled: palette.neutral.white,

  tabButtonBackgroundDisabled: palette.neutral[1000],
  tabButtonBorderDisabled: palette.transparent,
  tabButtonIconDisabled: palette.neutral[700],
  tabButtonLabelDisabled: palette.neutral[700],

  tabButtonErrorBackground: palette.transparent,
  tabButtonErrorBorder: palette.alert[200],
  tabButtonErrorIcon: palette.alert[200],
  tabButtonErrorLabel: palette.alert[200],

  tabButtonErrorToggledBackground: palette.alert[200],
  tabButtonErrorToggledBorder: palette.alert[200],
  tabButtonErrorToggledIcon: palette.neutral.white,
  tabButtonErrorToggledLabel: palette.neutral.white,

  /**
   * Tabs
   */
  tabIndicator: palette.primary[300],

  /*
   * IconButton
   */
  iconButtonBackground: palette.transparent,
  iconButtonBorder: palette.transparent,
  iconButtonIcon: palette.neutral[500],

  iconButtonBackgroundHover: palette.neutral[900],
  iconButtonBorderHover: palette.neutral[900],
  iconButtonIconHover: palette.neutral[500],

  iconButtonBackgroundActive: palette.primary[1000],
  iconButtonBorderActive: palette.primary[1000],
  iconButtonIconActive: palette.primary[300],

  iconButtonBackgroundToggled: palette.transparent,
  iconButtonBorderToggled: palette.transparent,
  iconButtonIconToggled: palette.primary[300],

  iconButtonBackgroundDisabled: palette.transparent,
  iconButtonBorderDisabled: palette.transparent,
  iconButtonIconDisabled: palette.neutral[700],

  iconButtonSuccessBackground: palette.success[400],
  iconButtonSuccessBorder: palette.transparent,
  iconButtonSuccessIcon: palette.success[200],

  iconButtonSuccessBackgroundHover: palette.success[400],
  iconButtonSuccessBorderHover: palette.transparent,
  iconButtonSuccessIconHover: palette.success[200],

  iconButtonSuccessBackgroundActive: palette.success[400],
  iconButtonSuccessBorderActive: palette.transparent,
  iconButtonSuccessIconActive: palette.success[300],

  iconButtonSuccessBackgroundToggled: palette.transparent,
  iconButtonSuccessBorderToggled: palette.transparent,
  iconButtonSuccessIconToggled: palette.success[200],

  iconButtonSuccessBackgroundDisabled: palette.neutral[900],
  iconButtonSuccessBorderDisabled: palette.transparent,
  iconButtonSuccessIconDisabled: palette.neutral[700],

  iconButtonDangerBackground: palette.transparent,
  iconButtonDangerBorder: palette.transparent,
  iconButtonDangerIcon: palette.alert[200],

  iconButtonDangerBackgroundHover: palette.alert[400],
  iconButtonDangerBorderHover: palette.transparent,
  iconButtonDangerIconHover: palette.alert[200],

  iconButtonDangerBackgroundActive: palette.alert[400],
  iconButtonDangerBorderActive: palette.transparent,
  iconButtonDangerIconActive: palette.alert[300],

  iconButtonDangerBackgroundToggled: palette.transparent,
  iconButtonDangerBorderToggled: palette.transparent,
  iconButtonDangerIconToggled: palette.alert[200],

  iconButtonDangerBackgroundDisabled: palette.neutral[900],
  iconButtonDangerBorderDisabled: palette.transparent,
  iconButtonDangerIconDisabled: palette.neutral[700],

  /*
   * IconButton onDark
   */
  iconButtonOnDarkBackground: palette.transparent,
  iconButtonOnDarkBorder: palette.transparent,
  iconButtonOnDarkIcon: palette.neutral.white,

  iconButtonOnDarkBackgroundHover: palette.neutral[400],
  iconButtonOnDarkBorderHover: palette.neutral[400],
  iconButtonOnDarkIconHover: palette.neutral.white,

  iconButtonOnDarkBackgroundActive: palette.primary[1000],
  iconButtonOnDarkBorderActive: palette.primary[1000],
  iconButtonOnDarkIconActive: palette.primary[300],

  iconButtonOnDarkBackgroundToggled: palette.transparent,
  iconButtonOnDarkBorderToggled: palette.transparent,
  iconButtonOnDarkIconToggled: palette.primary[300],

  iconButtonOnDarkBackgroundDisabled: palette.transparent,
  iconButtonOnDarkBorderDisabled: palette.transparent,
  iconButtonOnDarkIconDisabled: palette.neutral[200],

  /**
   * Solid icon button
   */
  solidIconButtonBackground: palette.neutral[100],
  solidIconButtonIcon: palette.neutral.white,

  solidIconButtonBackgroundHover: palette.neutral[100],
  solidIconButtonIconHover: palette.primary[300],

  solidIconButtonBackgroundActive: palette.neutral[100],
  solidIconButtonIconActive: palette.primary[300],

  solidIconButtonBackgroundDisabled: palette.neutral[900],
  solidIconButtonIconDisabled: palette.neutral[700],

  /*
   * Checkbox
   */
  checkboxBackground: palette.transparent,
  checkboxBorder: palette.neutral[500],
  checkboxIcon: palette.transparent,
  checkboxIconBackground: palette.transparent,
  checkboxLabel: palette.neutral[100],
  checkboxSubtitle: palette.neutral[400],

  checkboxBackgroundHover: palette.neutral[900],
  checkboxBorderHover: palette.neutral[500],
  checkboxIconHover: palette.transparent,
  checkboxIconBackgroundHover: palette.transparent,
  checkboxLabelHover: palette.primary[300],
  checkboxSubtitleHover: palette.primary[300],

  checkboxBackgroundActive: palette.primary[1000],
  checkboxBorderActive: palette.primary[300],
  checkboxIconActive: palette.transparent,
  checkboxIconBackgroundActive: palette.transparent,
  checkboxLabelActive: palette.primary[500],
  checkboxSubtitleActive: palette.primary[500],

  checkboxBackgroundToggled: palette.transparent,
  checkboxBorderToggled: palette.transparent,
  checkboxIconToggled: palette.neutral.white,
  checkboxIconBackgroundToggled: palette.primary[300],
  checkboxLabelToggled: palette.neutral[100],
  checkboxSubtitleToggled: palette.neutral[100],

  checkboxBackgroundDisabled: palette.transparent,
  checkboxBorderDisabled: palette.neutral[800],
  checkboxIconDisabled: palette.transparent,
  checkboxIconBackgroundDisabled: palette.neutral.white,
  checkboxLabelDisabled: palette.neutral[600],
  checkboxSubtitleDisabled: palette.neutral[600],

  checkboxBorderToggledActive: palette.transparent,
  checkboxBorderToggledHover: palette.transparent,

  checkboxBackgroundToggledDisabled: palette.neutral[800],
  checkboxBorderToggledDisabled: palette.transparent,
  checkboxIconToggledDisabled: palette.neutral.white,
  checkboxIconBackgroundToggledDisabled: palette.neutral[800],

  chip1Background: palette.secondary[500],
  chip1Border: palette.secondary[500],
  chip1Label: palette.neutral[100],

  chip2Background: palette.tertiary[700],
  chip2Border: palette.tertiary[700],
  chip2Label: palette.neutral[100],

  chip3Background: palette.transparent,
  chip3Border: palette.neutral[600],
  chip3Label: palette.neutral[100],

  chip4Background: palette.dataVisualisation[1],
  chip4Border: palette.transparent,
  chip4Label: palette.neutral[100],

  chip5Background: palette.dataVisualisation[3],
  chip5Border: palette.transparent,
  chip5Label: palette.neutral[100],

  chip6Background: palette.dataVisualisation[5],
  chip6Border: palette.transparent,
  chip6Label: palette.neutral[100],

  chip7Background: palette.dataVisualisation[7],
  chip7Border: palette.transparent,
  chip7Label: palette.neutral.white,

  chip8Background: palette.dataVisualisation[9],
  chip8Border: palette.transparent,
  chip8Label: palette.neutral.white,

  chip9Background: palette.dataVisualisation[11],
  chip9Border: palette.transparent,
  chip9Label: palette.neutral.white,

  chip10Background: palette.secondary[800],
  chip10Label: palette.neutral[100],
  chip10Border: palette.secondary[300],

  /**
   * Success stats chip
   */
  statsChipSuccessBackground: palette.success[400],
  statsChipSuccessLabel: palette.success[100],
  statsChipSuccessBorder: palette.success[200],

  /**
   * Danger stats chip
   */
  statsChipDangerBackground: palette.alert[400],
  statsChipDangerLabel: palette.alert[100],
  statsChipDangerBorder: palette.alert[200],

  /*
   * Info Status
   */
  statusInfoBackground: palette.information[400],
  statusInfoLabel: palette.information[200],
  statusInfoIcon: palette.information[100],
  statusInfoBorder: palette.information[200],

  /*
   * Error Status
   */
  statusErrorBackground: palette.alert[400],
  statusErrorLabel: palette.alert[200],
  statusErrorIcon: palette.alert[100],
  statusErrorBorder: palette.alert[200],

  /*
   * Primary Link
   */
  linkPrimaryLabel: palette.information[200],
  linkPrimaryBackground: palette.transparent,
  linkPrimaryDecoration: palette.information[200],

  linkPrimaryLabelHover: palette.information[100],
  linkPrimaryBackgroundHover: palette.neutral[900],
  linkPrimaryDecorationHover: palette.information[100],

  linkPrimaryLabelActive: palette.information[300],
  linkPrimaryBackgroundActive: palette.transparent,
  linkPrimaryDecorationActive: palette.information[300],

  linkPrimaryLabelVisited: palette.dataVisualisation[11],
  linkPrimaryBackgroundVisited: palette.transparent,
  linkPrimaryDecorationVisited: palette.dataVisualisation[11],

  linkPrimaryLabelDisabled: palette.neutral[600],
  linkPrimaryBackgroundDisabled: palette.transparent,
  linkPrimaryDecorationDisabled: palette.neutral[600],
  linkPrimaryDisabledOpacity: 1,

  /*
   * Secondary Link
   */
  linkSecondaryLabel: palette.neutral[400],
  linkSecondaryBackground: palette.transparent,
  linkSecondaryDecoration: palette.neutral[400],

  linkSecondaryLabelHover: palette.neutral[400],
  linkSecondaryBackgroundHover: palette.neutral[1000],
  linkSecondaryDecorationHover: palette.neutral[400],

  linkSecondaryLabelActive: palette.neutral[300],
  linkSecondaryBackgroundActive: palette.transparent,
  linkSecondaryDecorationActive: palette.neutral[300],

  linkSecondaryLabelVisited: palette.dataVisualisation[11],
  linkSecondaryBackgroundVisited: palette.transparent,
  linkSecondaryDecorationVisited: palette.dataVisualisation[11],

  linkSecondaryLabelDisabled: palette.neutral[700],
  linkSecondaryBackgroundDisabled: palette.transparent,
  linkSecondaryDecorationDisabled: palette.neutral[700],
  linkSecondaryDisabledOpacity: 1,

  /*
   * Tertiary Link
   */
  linkTertiaryLabel: palette.neutral[100],
  linkTertiaryBackground: palette.transparent,
  linkTertiaryDecoration: palette.neutral[100],

  linkTertiaryLabelHover: palette.neutral[100],
  linkTertiaryBackgroundHover: palette.neutral[900],
  linkTertiaryDecorationHover: palette.neutral[100],

  linkTertiaryLabelActive: palette.neutral[300],
  linkTertiaryBackgroundActive: palette.transparent,
  linkTertiaryDecorationActive: palette.neutral[300],

  linkTertiaryLabelVisited: palette.dataVisualisation[11],
  linkTertiaryBackgroundVisited: palette.transparent,
  linkTertiaryDecorationVisited: palette.dataVisualisation[11],

  linkTertiaryLabelDisabled: palette.neutral[600],
  linkTertiaryBackgroundDisabled: palette.transparent,
  linkTertiaryDecorationDisabled: palette.neutral[600],
  linkTertiaryDisabledOpacity: 0.5,

  /*
   * Quaternary Link
   */
  linkQuaternaryLabel: palette.neutral.white,
  linkQuaternaryBackground: palette.transparent,
  linkQuaternaryDecoration: palette.neutral.white,

  linkQuaternaryLabelHover: palette.neutral.white,
  linkQuaternaryBackgroundHover: palette.neutral[300],
  linkQuaternaryDecorationHover: palette.neutral.white,

  linkQuaternaryLabelActive: palette.neutral[900],
  linkQuaternaryBackgroundActive: palette.transparent,
  linkQuaternaryDecorationActive: palette.neutral[900],

  linkQuaternaryLabelVisited: palette.neutral.white,
  linkQuaternaryBackgroundVisited: palette.transparent,
  linkQuaternaryDecorationVisited: palette.neutral.white,

  linkQuaternaryLabelDisabled: palette.neutral.white,
  linkQuaternaryBackgroundDisabled: palette.transparent,
  linkQuaternaryDecorationDisabled: palette.neutral.white,
  linkQuaternaryDisabledOpacity: 0.5,

  /*
   * Warning Status
   */
  statusWarningBackground: palette.warning[400],
  statusWarningLabel: palette.warning[100],
  statusWarningIcon: palette.warning[100],
  statusWarningBorder: palette.warning[200],

  /*
   * Success Status
   */
  statusSuccessBackground: palette.success[400],
  statusSuccessLabel: palette.success[200],
  statusSuccessIcon: palette.success[100],
  statusSuccessBorder: palette.success[200],

  /*
   * Breadcrumb
   */
  breadcrumbLabel: palette.neutral[100],
  breadcrumbLabelActive: palette.neutral[600],
  breadcrumbIcon: palette.neutral[600],

  /*
   * Menu Item
   */
  menuItemLabel: palette.neutral[100],
  menuBorder: palette.neutral[100],
  menuItemBackground: palette.neutral.white,
  menuItemBackgroundHover: palette.neutral[900],
  menuItemBackgroundActive: palette.neutral[800],
  menuDividerBackground: palette.neutral[900],
  menuDividerLabelColor: palette.neutral[100],
  menuItemSelectedBackground: palette.neutral[800],
  menuFooterBackground: palette.neutral.white,
  menuFooterBorder: palette.neutral[100],
  menuItemLabelDisabled: palette.neutral[700],
  menuItemSelectedIcon: palette.primary[300],
  menuItemIcon: palette.transparent,

  /*
   * Table Placeholder
   */
  tablePlaceholderBackground: palette.neutral.white,
  tablePlaceholderGradientFrom: palette.neutral[900],
  tablePlaceholderGradientBetween: palette.neutral[1000],
  tablePlaceholderGradientTo: palette.neutral[900],

  /*
   * Table Header
   */
  tableHeaderCell: palette.neutral[400],
  tableHeaderBorder: palette.neutral[900],
  tableHeaderBackground: palette.neutral.white,
  tableHeadCellHover: palette.primary[300],
  tableHeadCellActive: palette.neutral[500],

  /*
   * Table Row
   */
  tableRowBackgroundActive: palette.neutral[800],
  tableRowEvenBackground: palette.neutral[1000],
  tableRowOddBackground: palette.neutral.white,
  tableRowBackgroundHover: palette.neutral[900],

  /*
   * Table Row Menu
   */
  tableRowMenuBackground: palette.neutral.white,

  /*
   * Indicator
   */
  indicatorBackground: palette.alert[200],
  indicatorLabel: palette.neutral.white,

  /*
   * Divider
   */
  dividerBorder: palette.neutral[900],
  dividerLabel: palette.neutral[100],

  /*
   * Primary Chat Bubble
   */
  chatBubblePrimaryBackground: palette.neutral[800],
  chatBubblePrimaryLabel: palette.neutral[400],
  chatBubblePrimaryTimestampLabel: palette.neutral[400],
  chatBubblePrimaryIcon: palette.transparent,

  /*
   * Secondary Chat Bubble
   */
  chatBubbleSecondaryBackground: palette.neutral[200],
  chatBubbleSecondaryLabel: palette.neutral.white,
  chatBubbleSecondaryTimestampLabel: palette.neutral[400],
  chatBubbleSecondaryIcon: palette.primary[200],

  /*
   * Modal
   */
  modalFooterShadow:
    'linear-gradient(0deg, rgba(11, 11, 13, 0.10) 0%, rgba(11, 11, 13, 0.00) 94.27%)',
  modalBackground: palette.neutral.white,
  modalOverlayBackground: 'rgba(0, 0, 0, 0.5)',

  /*
   * Tooltip
   */
  tooltipBackground: palette.neutral[100],
  tooltipLabel: palette.neutral.white,
  tooltipIcon: palette.neutral[500],

  /*
   * Switch
   */
  switchOverlayBackground: palette.transparent,
  switchBackground: palette.neutral[400],
  switchIcon: palette.neutral.white,
  switchLabel: palette.neutral[100],

  switchOverlayBackgroundHover: palette.neutral[1000],
  switchBackgroundHover: palette.neutral[400],
  switchIconHover: palette.neutral.white,
  switchLabelHover: palette.primary[300],

  switchOverlayBackgroundActive: palette.primary[1000],
  switchBackgroundActive: palette.neutral[400],
  switchIconActive: palette.neutral.white,
  switchIconFillActive: palette.primary[300],
  switchIconStrokeActive: palette.neutral.white,
  switchLabelActive: palette.primary[500],

  switchOverlayBackgroundToggled: palette.transparent,
  switchBackgroundToggled: palette.primary[300],
  switchIconToggled: palette.neutral.white,
  switchIconFillToggled: palette.neutral.white,
  switchIconColorToggled: palette.primary[300],
  switchLabelToggled: palette.neutral[100],

  switchOverlayBackgroundDisabled: palette.transparent,
  switchBackgroundDisabled: palette.neutral[800],
  switchIconDisabled: palette.neutral.white,
  switchIconDisabledColor: palette.neutral.white,
  switchIconColorDisabledToggled: palette.neutral[800],
  switchIconFillDisabledToggled: palette.neutral.white,
  switchLabelDisabled: palette.neutral[600],

  /*
   * Radio
   */
  radioBackground: palette.transparent,
  radioBorder: palette.neutral[500],
  radioCircle: palette.transparent,
  radioDot: palette.transparent,
  radioLabel: palette.neutral[100],
  radioSubtitle: palette.neutral[400],

  radioBackgroundHover: palette.neutral[900],
  radioBorderHover: palette.neutral[500],
  radioCircleHover: palette.transparent,
  radioDotHover: palette.transparent,
  radioLabelHover: palette.primary[300],
  radioSubtitleHover: palette.primary[300],

  radioBackgroundActive: palette.primary[1000],
  radioBorderActive: palette.primary[300],
  radioCircleActive: palette.transparent,
  radioDotActive: palette.transparent,
  radioLabelActive: palette.primary[500],
  radioSubtitleActive: palette.primary[500],

  radioBackgroundToggled: palette.transparent,
  radioBorderToggled: palette.primary[300],
  radioCircleToggled: palette.transparent,
  radioDotToggled: palette.primary[300],
  radioLabelToggled: palette.neutral[100],
  radioSubtitleToggled: palette.neutral[400],

  radioBackgroundDisabled: palette.transparent,
  radioBorderDisabled: palette.neutral[800],
  radioCircleDisabled: palette.transparent,
  radioDotDisabled: palette.transparent,
  radioLabelDisabled: palette.neutral[600],
  radioSubtitleDisabled: palette.neutral[600],

  radioDisabledBackgroundToggled: palette.transparent,
  radioDisabledBorderToggled: palette.neutral[800],
  radioDisabledCircleToggled: palette.transparent,
  radioDisabledDotToggled: palette.neutral[800],

  /*
   * Range
   */
  rangeIcon: palette.neutral[500],

  rangeTrackBackground: palette.neutral[700],

  rangeHandleBackground: palette.neutral[300],
  rangeHandleBorder: palette.neutral.white,

  /*
   * Empty state
   */
  emptyStateBackground: palette.neutral[1000],
  emptyStateLabel: palette.neutral[400],

  /*
   * Information banner
   */
  bannerInformationBackground: palette.information[200],
  bannerInformationLabel: palette.neutral.white,
  bannerInformationIcon: palette.neutral.white,
  bannerInformationShadowBackground:
    'linear-gradient(270deg, #2B6CB0 -7.14%, rgba(43, 108, 176, 0.50) 88.1%);',

  /*
   * Error banner
   */
  bannerErrorBackground: palette.alert[200],
  bannerErrorLabel: palette.neutral.white,
  bannerErrorIcon: palette.neutral.white,
  bannerErrorShadowBackground:
    'linear-gradient(270deg, #C53030 -7.14%, rgba(197, 48, 48, 0.50) 88.1%);',

  /*
   * Warning banner
   */
  bannerWarningBackground: palette.warning[300],
  bannerWarningLabel: palette.neutral[100],
  bannerWarningIcon: palette.neutral[100],
  bannerWarningShadowBackground:
    'linear-gradient(270deg, #F6E05E -7.14%, rgba(246, 224, 94, 0.50) 88.1%);',

  /*
   * Success banner
   */
  bannerSuccessBackground: palette.success[200],
  bannerSuccessLabel: palette.neutral[1000],
  bannerSuccessIcon: palette.neutral[1000],
  bannerSuccessShadowBackground:
    'linear-gradient(270deg, #68D391 -7.14%, rgba(104, 221, 145, 0.50) 88.1%);',

  /*
   * Error alert
   */
  alertErrorBackground: palette.neutral.white,
  alertErrorBorder: palette.alert[200],
  alertErrorLabel: palette.neutral[100],
  alertErrorTitle: palette.alert[200],
  alertErrorIcon: palette.alert[200],
  alertErrorIconBackground: palette.alert[400],

  /*
   * Warning alert
   */
  alertWarningBackground: palette.neutral.white,
  alertWarningBorder: palette.warning[200],
  alertWarningLabel: palette.neutral[100],
  alertWarningTitle: palette.warning[100],
  alertWarningIcon: palette.warning[200],
  alertWarningIconBackground: palette.warning[400],

  /*
   * Info alert
   */
  alertInfoBackground: palette.neutral.white,
  alertInfoBorder: palette.information[200],
  alertInfoLabel: palette.neutral[100],
  alertInfoTitle: palette.information[200],
  alertInfoIcon: palette.information[200],
  alertInfoIconBackground: palette.information[400],

  /*
   * Success alert
   */
  alertSuccessBackground: palette.neutral.white,
  alertSuccessBorder: palette.success[200],
  alertSuccessLabel: palette.neutral[100],
  alertSuccessTitle: palette.success[200],
  alertSuccessIcon: palette.success[200],
  alertSuccessIconBackground: palette.success[400],

  /*
   * Sidebar
   */
  sidebarBackground: palette.neutral[1000],
  sidebarBorder: palette.neutral[900],

  /*
   * Sidebar Item
   */
  sidebarItemBackground: palette.transparent,
  sidebarItemLabel: palette.neutral[400],
  sidebarItemIcon: palette.neutral[500],

  sidebarItemBackgroundToggled: palette.neutral[800],
  sidebarItemLabelToggled: palette.neutral[100],
  sidebarItemIconToggled: palette.primary[300],

  sidebarItemBackgroundActive: palette.transparent,
  sidebarItemLabelActive: palette.neutral[100],
  sidebarItemIconActive: palette.primary[300],

  sidebarItemBackgroundHover: palette.neutral[900],
  sidebarItemLabelHover: palette.neutral[400],
  sidebarItemIconHover: palette.neutral[500],

  /*
   * Sidebar Message
   */
  sidebarMessageBackground: palette.neutral[900],
  sidebarMessageBorder: palette.neutral[700],
  sidebarMessageIcon: palette.neutral[500],
  sidebarMessageIconToggled: palette.primary[300],

  sidebarMessageBorderToggled: palette.neutral[600],
  sidebarMessageBorderActive: palette.primary[300],
  sidebarMessageBorderHover: palette.neutral[600],

  /*
   * Sidebar Message Label
   */
  sidebarMessageLabelColor: palette.neutral.white,
  sidebarMessageLabelBackground: palette.neutral[100],

  /*
   * Toggle sidebar collapsed button
   */
  toggleSidebarCollapsedButtonBorder: palette.neutral[800],
  toggleSidebarCollapsedButtonBackground: palette.neutral[1000],

  toggleSidebarCollapsedButtonBorderActive: palette.transparent,
  toggleSidebarCollapsedButtonBackgroundActive: palette.neutral[1000],

  /*
   * Sidebar Item Notification Indicator
   */
  sidebarItemNotificationIndicatorBackground: palette.alert[200],

  /*
    Swiper dot
  */
  swiperDotBackground: palette.neutral[700],
  swiperDotActiveBackground: palette.primary[300],

  /*
   * Toolbar
   */
  toolbarBackground: palette.neutral[900],
  toolbarBottomBorder: palette.neutral[900],
  toolbarProfileIcon: palette.neutral[600],

  /*
   * Modal titel
   */
  modalTitle: palette.primary[300],
  modalSubtitle: palette.neutral[100],

  /*
   * Page title
   */
  pageTitle: palette.primary[300],

  /*
   * Content sidebar (right sidebar)
   */
  contentSidebarBorder: palette.neutral[800],
  contentSidebarBackground: palette.neutral.white,

  /*
   * Upload component
   */
  uploadBackground: palette.neutral[1000],
  uploadIcon: palette.neutral[500],
  uploadTitle: palette.neutral[100],
  uploadLabel: palette.neutral[100],
  uploadBorder: palette.neutral[500],
  uploadBackgroundHover: palette.transparent,

  /*
   * Selection card
   */
  selectionCardBackground: palette.neutral.white,
  selectionCardBorder: palette.neutral[700],
  selectionCardTitle: palette.neutral[100],
  selectionCardLabel: palette.neutral[100],
  selectionCardIcon: palette.success[200],
  selectionCardTooltipIcon: palette.neutral[500],

  selectionCardBorderToggled: palette.primary[300],

  selectionCardBackgroundDisabled: palette.neutral[900],
  selectionCardBorderDisabled: palette.neutral[800],

  /*
   * Tab header
   */
  tabHeaderBackground: palette.neutral[1000],

  /*
   * Error label
   */
  errorLabel: palette.alert[200],
  errorIcon: palette.alert[200],

  /*
   * List item
   */
  listItemBackground: palette.neutral.white,
  listItemBorder: palette.neutral[900],
  listItemIcon: palette.success[200],

  listItemBackgroundHover: palette.neutral[900],
  listItemBorderHover: palette.neutral[1000],

  listItemBackgroundActive: palette.neutral[800],
  listItemBorderActive: palette.neutral[900],

  /*
   * Error box
   */
  errorBoxBackground: palette.neutral[1000],
  errorBoxBorder: palette.neutral[900],
  errorBoxIcon: palette.alert[200],
  errorBoxLabel: palette.neutral[100],
  errorBoxTitle: palette.alert[200],

  /*
   * Copy to clipboard
   */
  copyToClipBoardIcon: palette.transparent,
  copyToClipBoardIconActive: palette.success[200],

  /*
   * Card
   */
  cardBorder: palette.neutral[800],
  cardBackground: palette.neutral.white,
  cardHeaderTitle: palette.neutral[100],
  cardHeaderSubtitle: palette.neutral[100],
  cardHeaderSecondarySubtitle: palette.neutral[400],
  cardHeaderUnit: palette.neutral[400],
  cardHeaderDescription: palette.neutral[400],

  /**
   * Chart
   */
  chartEmptyStateBackground: palette.neutral[1000],
  chartEmptyStateText: palette.neutral[400],
  chartEmptyStateIcon: palette.neutral[500],

  chartBackground: palette.neutral.white,
  chartLabelColor: palette.neutral[100],
  chartTooltipBackground: palette.neutral[100],
  chartTooltipColor: palette.neutral.white,
  chartTooltipBorder: palette.neutral.white,
  chartGridLinesColor: palette.neutral[900],

  /*
   * Confirm
   */
  confirmTitle: palette.primary[300],
  confirmMessage: palette.neutral[100],

  /*
   * Profile menu
   */
  profileMenuHeaderBackground: palette.neutral[200],
  profileMenuHeaderTitle: palette.neutral.white,
  profileMenuHeaderSubtitle: palette.neutral.white,

  profileMenuListItemBackground: palette.neutral.white,
  profileMenuListItemLabel: palette.neutral[100],
  profileMenuListItemIcon: palette.neutral[100],

  profileMenuListItemBackgroundHover: palette.neutral[900],

  profileMenuListItemBackgroundActive: palette.neutral[800],

  /**
   * Wysiwyg
   */
  unsavedChangesLabel: palette.alert[200],
  unsavedChangesIcon: palette.alert[200],

  /**
   * Avatar
   */
  avatarPlaceholder0Text: palette.neutral[300],
  avatarPlaceholder0BackgroundStart: palette.primary[300],
  avatarPlaceholder0BackgroundEnd: palette.primary[800],
  avatarPlaceholder0Border: palette.neutral.white,
  avatarPlaceholder0BorderHover: palette.neutral[700],

  avatarPlaceholder1Text: palette.neutral[300],
  avatarPlaceholder1BackgroundStart: palette.secondary[300],
  avatarPlaceholder1BackgroundEnd: palette.secondary[700],
  avatarPlaceholder1Border: palette.neutral.white,
  avatarPlaceholder1BorderHover: palette.neutral[700],

  avatarPlaceholder2Text: palette.neutral[300],
  avatarPlaceholder2BackgroundStart: palette.tertiary[100],
  avatarPlaceholder2BackgroundEnd: palette.tertiary[800],
  avatarPlaceholder2Border: palette.neutral.white,
  avatarPlaceholder2BorderHover: palette.neutral[700],

  avatarPlaceholder3Text: palette.neutral.white,
  avatarPlaceholder3BackgroundStart: palette.neutral[200],
  avatarPlaceholder3BackgroundEnd: palette.neutral[400],
  avatarPlaceholder3Border: palette.neutral.white,
  avatarPlaceholder3BorderHover: palette.neutral[700],

  /**
   * Password strength indicator
   */
  passwordStrengthIndicatorBackground: palette.neutral[800],
  passwordStrengthIndicatorBackgroundSuccess: palette.success[200],
  passwordStrengthIndicatorBackgroundError: palette.alert[200],

  /**
   * Radial progress
   */
  radialProgressSuccessTrack: palette.neutral[700],
  radialProgressSuccessText: palette.neutral[100],
  radialProgressSuccessProgress: palette.success[200],

  radialProgressSuccessLightTrack: palette.neutral[700],
  radialProgressSuccessLightText: palette.neutral[100],
  radialProgressSuccessLightProgress: palette.success[300],

  radialProgressWarningTrack: palette.neutral[700],
  radialProgressWarningText: palette.neutral[100],
  radialProgressWarningProgress: palette.warning[300],

  radialProgressDangerTrack: palette.neutral[700],
  radialProgressDangerText: palette.neutral[100],
  radialProgressDangerProgress: palette.alert[200],

  /**
   * Linear progress
   */
  linearProgressSuccessTrack: palette.neutral[600],
  linearProgressSuccessProgress: palette.success[200],

  linearProgressWarningTrack: palette.neutral[600],
  linearProgressWarningProgress: palette.warning[300],

  linearProgressDangerTrack: palette.neutral[600],
  linearProgressDangerProgress: palette.alert[200],

  /**
   * Callout
   */
  calloutInfoBackground: palette.information[400],
  calloutInfoTitle: palette.neutral[200],
  calloutInfoSubtitle: palette.neutral[200],
  calloutInfoIcon: palette.information[200],
  calloutInfoBorder: palette.information[200],

  calloutWarningBackground: palette.warning[400],
  calloutWarningTitle: palette.warning[100],
  calloutWarningSubtitle: palette.warning[100],
  calloutWarningIcon: palette.warning[200],
  calloutWarningBorder: palette.warning[200],

  calloutErrorBackground: palette.alert[400],
  calloutErrorTitle: palette.alert[100],
  calloutErrorSubtitle: palette.alert[100],
  calloutErrorIcon: palette.alert[200],
  calloutErrorBorder: palette.alert[200],

  calloutSuccessBackground: palette.success[400],
  calloutSuccessTitle: palette.success[100],
  calloutSuccessSubtitle: palette.success[100],
  calloutSuccessIcon: palette.success[200],
  calloutSuccessBorder: palette.success[200],

  /**
   * Sheet
   */
  sheetDisc: palette.neutral[700],
  sheetBackground: palette.neutral.white,

  /**
   * Backdrop
   */
  backdrop: 'rgba(0, 0, 0, 0.64)',

  /*
    PaperContainer component
  */
  paperContainerBackground: palette.neutral[800],

  /*
    Paper component
  */
  paperBackground: palette.neutral.white,

  /**
   * Blurred card
   */
  blurredCardIcon: palette.neutral[500],
  blurredCardTitle: palette.neutral[400],
  blurredCardSubtitle: palette.neutral[100],
  blurredCardBorder: palette.neutral.white,
  blurredCardBackground: 'rgba(247, 250, 252, 0.50)',

  /**
   * Select workspace menu
   */
  selectWorkspaceLabel: palette.neutral[100],
  selectWorkspaceIcon: palette.neutral[500],
  selectWorkspaceBackground: 'inherit',

  selectWorkspaceLabelHover: palette.neutral[100],
  selectWorkspaceIconHover: palette.neutral[500],
  selectWorkspaceBackgroundHover: palette.neutral[800],

  selectWorkspaceLabelActive: palette.neutral[100],
  selectWorkspaceIconActive: palette.primary[500],
  selectWorkspaceBackgroundActive: palette.primary[1000],

  selectWorkspaceLabelToggled: palette.neutral[100],
  selectWorkspaceIconToggled: palette.primary[500],
  selectWorkspaceBackgroundToggled: palette.neutral[800],

  selectWorkspaceEmptyStateLabel: palette.neutral[400],

  /*
    Form containers
  */
  formContainerBackground: palette.neutral[1000],
  formSectionBackground: palette.neutral.white,
  formSectionBorder: palette.neutral[800],
  formSectionDescription: palette.neutral[400],
  formSectionHeader: palette.neutral[100],

  /*
    Form header
  */
  formHeaderInputPlaceholderColor: palette.neutral[800],
  formHeaderInputColor: palette.primary[300],
  formHeaderSavedAtColor: palette.neutral[400],
  formHeaderBackground: palette.neutral.white,
  formHeaderEditedByMeta: palette.neutral[100],
  formHeaderEditedByMetaTitle: palette.neutral[400],
  formHeaderEditedByMetaIcon: palette.neutral[500],
  formHeaderInputBackgroundColor: palette.neutral.white,

  /**
   * Offers form upload csv row
   */
  offersFormUploadCsvRowIcon: palette.neutral[400],
  offersFormUploadCsvRowFileName: palette.neutral[100],
  offersFormUploadCsvRowCodesUploaded: palette.neutral[400],
  offersFormUploadCsvRowCodesConsumed: palette.alert[200],

  /**
   * Sign in
   */
  signInLeftSidebackground: palette.secondary[900],
  signInLeftSideTitle: palette.primary[300],
  signInLeftSideSubtitle: palette.neutral[100],
  signInRightSideTitle: palette.primary[300],

  /**
   * Access denied
   */
  accessDeniedTitle: palette.primary[300],
  accessDeniedSubtitle: palette.neutral[100],
  accessDeniedIcon: palette.alert[200],
  accessDeniedStudentIcon: palette.information[200],

  /**
   * Sign up
   */
  signUpRightSideTitle: palette.primary[300],
  signUpRightSideSubtitle: palette.neutral[100],

  /**
   * Onboarded
   */
  onboardedIcon: palette.success[200],

  /**
   * Email not verified
   */
  emailNotVerifiedIcon: palette.warning[200],

  /**
   * Invalid invitation
   */
  invalidInvitationIcon: palette.alert[200],

  /**
   * Invitation expired
   */
  invitationExpiredIcon: palette.information[200],

  /**
   * Invitation not found
   */
  invitationNotFoundIcon: palette.warning[200],

  /**
   * Menu button
   */
  menuButtonBackground: palette.neutral.white,
  menuButtonBackgroundHover: palette.neutral[100],
  menuButtonBackgroundActive: palette.neutral[100],

  menuButtonLabelBackground: palette.neutral[100],
  menuButtonLabelBackgroundHover: palette.neutral.white,
  menuButtonLabelBackgroundActive: palette.neutral[100],

  menuButtonLabelIcon: palette.neutral.white,
  menuButtonLabelIconHover: palette.neutral[100],
  menuButtonLabelIconActive: palette.neutral.white,

  menuButtonLabelText: palette.neutral.white,
  menuButtonLabelTextHover: palette.neutral[100],
  menuButtonLabelTextActive: palette.neutral.white,

  menuButtonLabelBorder: palette.neutral[100],

  menuButtonToggleBackground: palette.neutral[100],
  menuButtonToggleBackgroundHover: palette.neutral.white,
  menuButtonToggleBackgroundActive: palette.neutral[100],

  menuButtonToggleIcon: palette.neutral.white,
  menuButtonToggleIconHover: palette.neutral[100],
  menuButtonToggleIconActive: palette.neutral.white,

  menuButtonToggleBorder: palette.neutral[100],

  /**
   * Placeholder chart
   */
  placeholderChartBorder: palette.neutral[700],
  placeholderChartLabel: palette.neutral[900],
  placeholderChartLine: palette.neutral[800],

  placeholderChartGradientFrom: palette.neutral[1000],
  placeholderChartGradientBetween: palette.neutral[1000],
  placeholderChartGradientTo: palette.neutral.white,

  /**
   * Referrals
   */
  referralsInfoText: palette.neutral[300],
  referralsInfoTextBold: palette.neutral[100],
  referralsEmptyTableStateText: palette.neutral[100],
  referralsEmptyTableStateDescription: palette.neutral[300],

  innerContentContainerAlt: palette.neutral[1000],

  unsavedChangesBoxBackground: palette.neutral.white,
  unsavedChangesBoxBorder: palette.neutral[900],

  /**
   *  QR Code
   */
  qrCodeBackground: palette.neutral.white,
  expandedQrCodeBackground: palette.neutral.white,

  expandedQrCodeHeaderTitle: palette.neutral[100],
  expandedQrCodeHeaderSubtitle: palette.neutral[400],

  /**
   * Not found page
   */
  notFoundPageBackground: palette.neutral.white,

  /**
   * Calendar
   */
  calendarNowBackground: palette.secondary[300],
  calendarNowBackgroundHover: palette.secondary[400],

  calendarActiveBackground: palette.primary[600],
  calendarActiveBackgroundHover: palette.primary[700],

  calendarHasActiveBackground: palette.primary[300],
  calendarHasActiveBackgroundHover: palette.primary[400],

  /**
   * Convert drafts
   */
  convertDraftsText: palette.neutral[200],

  imageGalleryBackground: palette.neutral[100],

  /**
   * Select reaction button
   */
  selectReactionButtonBackground: palette.neutral.white,
  selectReactionButtonBorder: palette.neutral[800],
  selectReactionButtonBorderToggled: palette.primary[300],

  /**
   * Select reaction menu
   */
  selectReactionMenuBackground: palette.neutral.white,
};
