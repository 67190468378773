import React from 'react';

import { INPUT_BORDER_RADIUS, INPUT_X_PADDING } from '../../constants';
import { BoxProps } from '../box';
import { Styled } from './input-box.styled';

interface InputBoxProps extends BoxProps {
  disabled?: boolean;
  hasError?: boolean;
  floatLabel?: boolean;
  onClick?: () => void;
  isFocused?: boolean;

  focusTop?: number;
}

export const InputBox = React.forwardRef(
  (props: InputBoxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <Styled.InputBox
        px={INPUT_X_PADDING}
        r={INPUT_BORDER_RADIUS}
        gap={8}
        flex
        relative
        ref={ref}
        height={58}
        {...props}
      />
    );
  },
);
