import React from 'react';

import { Backdrop } from '../backdrop';
import { Styled } from './sheet.styled';
import { SheetProps } from './sheet.types';

export function Sheet(props: SheetProps) {
  const { children, isOpen, onClose, zIndex = 1000, ...rest } = props;

  return (
    <React.Fragment>
      <Backdrop zIndex={zIndex} isOpen={isOpen} onClick={onClose} />

      <Styled.Sheet zIndex={zIndex + 1} isOpen={isOpen} {...rest}>
        <Styled.SheetDisc />

        {children}
      </Styled.Sheet>
    </React.Fragment>
  );
}
