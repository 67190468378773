import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';

export namespace ReferralsSelector {
  const selectSelf = (state: RootState) => state.referrals.referrals;

  export const selectData = createSelector(
    selectSelf,
    (referrals) => referrals.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (referrals) => referrals.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (referrals) => referrals.error,
  );
}
