import { Box } from '../box';
import { Text } from '../text';

interface PageHeaderProps {
headingTx: TxString;
subtitleTx?: TxString;
headingText?: string; 
subtitleText?: string;
titleTestId?: string
}

export function PageHeader(props: PageHeaderProps) {
  return (
    <Box flex flexDirection='column' gap={8}>
    <Text
      color="pageTitle"
      tx={props.headingTx}
      text={props.headingText}
      data-testid={props.titleTestId}
      variant="titleMd"
      as="h1"
    />
    <Text
      color='textSecondary'
      tx={props.subtitleTx}
      text={props.subtitleText}
      variant='bodyMd'
    />
    </Box>
  );
}
