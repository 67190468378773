import { activities } from './activities';
import { auth } from './auth';
import { countries } from './countries';
import { fileUploads } from './file-uploads';
import { locations } from './locations';
import { offers } from './offers';
import { orbiPay } from './orbi-pay';
import { posts } from './posts';
import { questions } from './questions';
import { reactions } from './reactions';
import { referrals } from './referrals';
import { search } from './search';

export const v1 = {
  activities,
  auth,
  orbiPay,
  questions,
  referrals,
  search,
  offers,
  locations,
  countries,
  posts,
  fileUploads,
  reactions,
};
