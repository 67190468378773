import { ImageData } from '@orbiapp/components';

import { SegmentationType } from '../../department';
import { MembershipApplicationAnswer } from '../application';
import { MembershipMemberListItem } from '../membership-type.model';
import { MembershipMemberPeriod, MembershipStatsPeriod } from '../period';

export interface MembershipMember {
  answers: MembershipApplicationAnswer[] | null;
  email: string;
  fullName: string;
  membershipId: number | null;
  periods: MembershipMemberPeriod[];
  segmentation: SegmentationType;
  semester: number;
  userKey: string;
  profilePicture: ImageData | null;
}

export interface MembershipMemberListItemStats {
  answers: MembershipApplicationAnswer[] | null;
  email: string;
  foodPreferences: string | null;
  fullName: string;
  membershipId: number;
  periods: MembershipStatsPeriod[];
  segmentation: SegmentationType;
  semester: number;
  userKey: string;
}

export type MembersOrderByKey = keyof Pick<
  MembershipMemberListItem,
  'fullName' | 'email'
>;

export const membershipMembersSortableKeys: Record<
  MembersOrderByKey,
  MembersOrderByKey
> = {
  fullName: 'fullName',
  email: 'email',
};
