import {
  MembershipTypeSelector,
  SuggestedMembershipsSelector,
  useSelector,
} from '../store';

export function useSuggestedMembershipApplicationRequirements(
  maxCount: number,
  excludedMembershipTypeKeys: string[],
) {
  const suggestedMembershipApplicationRequirements = useSelector(
    SuggestedMembershipsSelector.selectData,
  );
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );

  if (!suggestedMembershipApplicationRequirements) {
    return null;
  }

  const excludedMembershipTypeKeysSet = new Set(excludedMembershipTypeKeys);

  if (membershipTypeKey) {
    excludedMembershipTypeKeysSet.add(membershipTypeKey);
  }

  return (
    suggestedMembershipApplicationRequirements
      ?.filter(
        (requirement) =>
          !excludedMembershipTypeKeysSet.has(requirement.membershipTypeKey),
      )
      .slice(0, maxCount) ?? null
  );
}
