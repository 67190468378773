import { Analytics } from '../../services';
import {
  createOrbiPayAccountThunk,
  createStripeAccountLinkThunk,
  getOrbiPaySettingsThunk,
  getPayoutThunk,
  getPayoutsThunk,
  getStripePublicKeyThunk,
} from './orbi-pay.actions';
import { OrbiPayBuilder } from './orbi-pay.types';

const getOrbiPaySettingsBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(getOrbiPaySettingsThunk.fulfilled, (state, action) => {
      state.orbiPaySettings.status = 'idle';
      state.orbiPaySettings.data = action.payload;
    })
    .addCase(getOrbiPaySettingsThunk.pending, (state) => {
      state.orbiPaySettings.status = 'pending';
      state.orbiPaySettings.error = null;
    })
    .addCase(getOrbiPaySettingsThunk.rejected, (state, action) => {
      state.orbiPaySettings.status = 'idle';
      state.orbiPaySettings.error = action.error;
    });
};

const createOrbiPayAccountBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(createOrbiPayAccountThunk.fulfilled, (state, action) => {
      Analytics.track('create_orbi_pay_account');

      state.createOrbiPayAccount.status = 'idle';
      state.createOrbiPayAccount.data = action.payload;
    })
    .addCase(createOrbiPayAccountThunk.pending, (state) => {
      state.createOrbiPayAccount.status = 'pending';
      state.createOrbiPayAccount.error = null;
    })
    .addCase(createOrbiPayAccountThunk.rejected, (state, action) => {
      state.createOrbiPayAccount.status = 'idle';
      state.createOrbiPayAccount.error = action.error;
    });
};

const createStripeAccountLinkBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(createStripeAccountLinkThunk.fulfilled, (state, action) => {
      state.createStripeAccountLink.status = 'idle';
      state.createStripeAccountLink.data = action.payload;
    })
    .addCase(createStripeAccountLinkThunk.pending, (state) => {
      state.createStripeAccountLink.status = 'pending';
      state.createStripeAccountLink.error = null;
    })
    .addCase(createStripeAccountLinkThunk.rejected, (state, action) => {
      state.createStripeAccountLink.status = 'idle';
      state.createStripeAccountLink.error = action.error;
    });
};

const getPayoutsBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(getPayoutsThunk.fulfilled, (state, action) => {
      state.payouts.status = 'idle';
      state.payouts.data = action.payload;
    })
    .addCase(getPayoutsThunk.pending, (state) => {
      state.payouts.status = 'pending';
      state.payouts.error = null;
    })
    .addCase(getPayoutsThunk.rejected, (state, action) => {
      state.payouts.status = 'idle';
      state.payouts.error = action.error;
    });
};

const getPayoutBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(getPayoutThunk.fulfilled, (state, action) => {
      state.payout.status = 'idle';
      state.payout.data = action.payload;
    })
    .addCase(getPayoutThunk.pending, (state) => {
      state.payout.status = 'pending';
      state.payout.error = null;
      state.payout.data = null;
    })
    .addCase(getPayoutThunk.rejected, (state, action) => {
      state.payout.status = 'idle';
      state.payout.error = action.error;
    });
};

const getStripePublicKeyBuild = (builder: OrbiPayBuilder) => {
  return builder
    .addCase(getStripePublicKeyThunk.fulfilled, (state, action) => {
      state.publicStripeKey.status = 'idle';
      state.publicStripeKey.data = action.payload;
    })
    .addCase(getStripePublicKeyThunk.pending, (state) => {
      state.publicStripeKey.status = 'pending';
      state.publicStripeKey.error = null;
    })
    .addCase(getStripePublicKeyThunk.rejected, (state, action) => {
      state.publicStripeKey.status = 'idle';
      state.publicStripeKey.error = action.error;
    });
};

export const extraReducers = (builder: OrbiPayBuilder) => {
  getPayoutBuild(builder);
  getPayoutsBuild(builder);
  getOrbiPaySettingsBuild(builder);
  createOrbiPayAccountBuild(builder);
  getStripePublicKeyBuild(builder);
  createStripeAccountLinkBuild(builder);
};
