import { t } from 'i18next';

import { isUndefined } from './utils';

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const stringHasLengthOrNull = (str: string | null): string | null =>
  str?.length ? str : null;

/**
 *
 * @description remove u00a0 unicode character from string
 */
export function removeEmptySpace(str: string) {
  return str.replaceAll(/\u00a0/g, ' ').replaceAll(' ', '');
}

export function getRandomKey() {
  return Math.random().toString(36).substring(2, 9);
}

export function stringEllipsis(str: string, maxLength: number) {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.slice(0, maxLength)}...`;
}

export function stringOrPx(value?: number | string) {
  if (isUndefined(value)) {
    return 'auto';
  }

  if (typeof value === 'string') {
    return value;
  }

  return `${value}px`;
}

export function isTxString(
  txString: any,
  txArgs?: Record<string, string | number>,
): txString is TxString {
  if (typeof txString !== 'string') {
    return false;
  }

  const translated = t(txString, txArgs ?? {});

  return translated !== txString;
}

export function pxToRem(px: number) {
  return `${px / 16}rem`;
}

const emailRegex =
  /(?:[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}|\[(?:IPv6:)?(?:[a-fA-F0-9]{1,4}:){1,7}[a-fA-F0-9]{1,4}\]|\[(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\]))/g;

export function extractEmails(text: string) {
  return text.match(emailRegex)?.map((email) => email.toString()) ?? [];
}
