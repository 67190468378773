import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './locations.build';
import { initialLocationsState } from './locations.initial';
import { reducers } from './locations.reducers';

const locationsSlice = createSlice({
  name: 'locations',
  initialState: initialLocationsState,
  reducers,
  extraReducers,
});

export const locationsReducer = locationsSlice.reducer;
