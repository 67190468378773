import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { JobListItem } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import { JobListItemDto } from './jobs.dto';
import { jobListItemMapper } from './jobs.mappers';

export const hideJob = async (jobKey: string): Promise<GeneralApiResult> => {
  const res = await apisauce.put<void, any>(`/v2/jobs/${jobKey}/hide`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getJobListItems = async (): Promise<
  GeneralApiResultWithData<JobListItem[]>
> => {
  const res = await apisauce.get<JobListItemDto[], any>('/v2/jobs');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(jobListItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getJobListItems', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
