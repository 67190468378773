import { css } from 'styled-components';

import { stringOrPx } from '../string';
import { isUndefined } from '../utils';
import { Gap } from './gap.types';

export const gapStyles = css<Gap>`
  ${(props) => {
    const { m, my, mx, mt, mr, mb, ml, p, px, py, pt, pr, pb, pl } = props;

    return css<Gap>`
      ${!isUndefined(m) &&
      css`
        margin: ${stringOrPx(m)};
      `};

      ${!isUndefined(my) &&
      css`
        margin-top: ${stringOrPx(my)};
        margin-bottom: ${stringOrPx(my)};
      `};

      ${!isUndefined(mx) &&
      css`
        margin-left: ${stringOrPx(mx)};
        margin-right: ${stringOrPx(mx)};
      `};

      ${!isUndefined(mt) &&
      css`
        margin-top: ${stringOrPx(mt)};
      `};

      ${!isUndefined(mr) &&
      css`
        margin-right: ${stringOrPx(mr)};
      `};

      ${!isUndefined(mb) &&
      css`
        margin-bottom: ${stringOrPx(mb)};
      `};

      ${!isUndefined(ml) &&
      css`
        margin-left: ${stringOrPx(ml)};
      `};

      ${!isUndefined(p) &&
      css`
        padding: ${stringOrPx(p)};
      `};

      ${!isUndefined(py) &&
      css`
        padding-top: ${stringOrPx(py)};
        padding-bottom: ${stringOrPx(py)};
      `};

      ${!isUndefined(px) &&
      css`
        padding-left: ${stringOrPx(px)};
        padding-right: ${stringOrPx(px)};
      `};

      ${!isUndefined(pt) &&
      css`
        padding-top: ${stringOrPx(pt)};
      `};

      ${!isUndefined(pr) &&
      css`
        padding-right: ${stringOrPx(pr)};
      `};

      ${!isUndefined(pb) &&
      css`
        padding-bottom: ${stringOrPx(pb)};
      `};

      ${!isUndefined(pl) &&
      css`
        padding-left: ${stringOrPx(pl)};
      `}
    `;
  }}
`;
