import { Box, LAYOUT_Z_INDEX } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const EnableCameraBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      position: absolute;
      inset: 0;
      background-color: ${theme.colors.scanQrCodesCardBackground};
      border: 1px solid ${theme.colors.scanQrCodesCardBorder};
    `;
  }}
`;

const WebCamera = styled.video<{ isFullscreen?: boolean }>`
  ${(props) => {
    const { isFullscreen, theme } = props;

    return css`
      background-color: ${theme.colors.scanQrCodesCardBackground};
      border: 1px solid ${theme.colors.scanQrCodesCardBorder};

      border-radius: 8;
      height: 100%;
      object-fit: cover;
      width: 100%;
      border-radius: 32px;
      position: relative;

      ${isFullscreen &&
      css`
        border: none !important;
        border-radius: 0 !important;
      `}
    `;
  }}
`;

const WebCameraBox = styled(Box)<{ isFullscreen?: boolean }>`
  ${(props) => {
    const { isFullscreen } = props;

    return css`
      height: 100%;
      width: 100%;
      position: relative;
      border-radius: 32px;

      ${isFullscreen &&
      css`
        position: fixed;
        inset: 0;
        border-radius: 0;
        z-index: ${LAYOUT_Z_INDEX.scanTickets.scanModal};
      `}
    `;
  }}
`;

const WebCameraOverlay = styled(Box)`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 32px;
`;

export const Styled = {
  EnableCameraBox,
  WebCamera,
  WebCameraBox,
  WebCameraOverlay,
};
