import {
  FormSection,
  FormSectionHeader,
  Switch,
  Text,
} from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import { CreateOfferForm } from '../../../../../models';
import { useOfferFormsSteps } from '../../helpers';

export function StudentVerification() {
  const formMethods = useFormContext<CreateOfferForm>();
  const requireStudentVerification = formMethods.watch(
    'requireStudentVerification',
  );

  const steps = useOfferFormsSteps();

  const toggleRequireStudentVerification = () => {
    formMethods.setValue(
      'requireStudentVerification',
      !requireStudentVerification,
      {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      },
    );
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.verification"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: steps.verification }}
        />
      </FormSectionHeader>

      <Switch
        tx="label.offer-form.require-student-verification-toggle"
        checked={requireStudentVerification}
        onClick={toggleRequireStudentVerification}
      />
    </FormSection>
  );
}
