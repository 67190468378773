import React from 'react';

export namespace Scroll {
  export function isAtLeft(node: HTMLDivElement) {
    return node.scrollLeft === 0;
  }

  export function isAtRight(node: HTMLDivElement) {
    return node.scrollLeft + node.clientWidth >= node.scrollWidth - 1;
  }

  export function isAtTop(node: HTMLDivElement) {
    return node.scrollTop === 0;
  }

  export function isAtBottom(node: HTMLDivElement) {
    return node.scrollTop + node.clientHeight >= node.scrollHeight - 1;
  }

  export function isOverflowing(node: HTMLDivElement) {
    return node.scrollWidth > node.clientWidth;
  }
}

interface ScrollValue {
  isAtTop: boolean;
  isAtBottom: boolean;
  isAtLeft: boolean;
  isAtRight: boolean;
}

export function useScroll(
  ref: React.RefObject<HTMLDivElement>,
  onScroll: (value: ScrollValue) => void,
) {
  const handleScroll = React.useCallback(() => {
    if (!ref.current) return;

    onScroll({
      isAtTop: Scroll.isAtTop(ref.current),
      isAtBottom: Scroll.isAtBottom(ref.current),
      isAtLeft: Scroll.isAtLeft(ref.current),
      isAtRight: Scroll.isAtRight(ref.current),
    });
  }, [onScroll, ref]);

  React.useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new ResizeObserver(handleScroll);

    element.addEventListener('scroll', handleScroll);
    observer.observe(element);

    handleScroll();

    return () => {
      observer.disconnect();
      element.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, ref]);
}
