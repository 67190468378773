import { PhoneErrors } from '../phone';

export namespace ContactDetailsErrors {
  export const name = {
    'string.empty': 'errors.name.empty',
  };

  export const email = {
    'string.empty': 'errors.email.empty',
  };

  export const phone = PhoneErrors.phone;
}
