import { getUID } from '@orbiapp/components';

import {
  FreeTextListItem,
  RequestedInfoType,
} from '../../../../../../../models';
import { FreeTextAnswerDto } from './free-text-answers.dto';

export const freetextListItemMapper = (
  freetextAnswerDto: FreeTextAnswerDto,
): FreeTextListItem => ({
  answer: freetextAnswerDto.answer,
  answeredAt: freetextAnswerDto.answeredAt,
  email: freetextAnswerDto.email,
  freetextListItemKey: getUID(),
  fullName: freetextAnswerDto.fullName,
  question: freetextAnswerDto.question,
  ticketTypeName: freetextAnswerDto.ticketTypeName,
  type: RequestedInfoType.FreeText,
});
