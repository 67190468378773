import { CreateIntegratedMembershipTypeForm } from '../../../../models';

export const CREATE_INTEGRATED_MEMBERSHIP_DEFAULT_VALUES: CreateIntegratedMembershipTypeForm =
  {
    backgroundImage: null,
    contactDetails: { email: '', name: '', phone: '' },
    description: '',
    logo: null,
    metadata: {
      apiKey: '',
      integrationUrl: '',
      source: 'montania',
    },
    name: '',
  };
