import Joi from 'joi';

import { LocationSchema } from './location.schema';

export const ActivityLocationSchema = Joi.object({
  label: LocationSchema.label,
  description: LocationSchema.description,
  coordinates: LocationSchema.coordinates,
  link: LocationSchema.link,
  city: LocationSchema.city,
});
