import React from 'react';

export function useFetch<T extends Record<string, any>>(
  fetcher: () => Promise<T | null>,
) {
  const [data, setData] = React.useState<T | null>(null);

  const fetchData = React.useCallback(async () => {
    const data = await fetcher();

    setData(data);
  }, [fetcher]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return data;
}
