import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { MembershipFreetextQuestionForm } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  createFreetextMapper,
  updateFreetextMapper,
} from './freetext-questions.mappers';

export const createFreetextQuestion = async (params: {
  membershipTypeKey: string;
  createFreetextQuestion: MembershipFreetextQuestionForm;
}): Promise<GeneralApiResultWithData<string>> => {
  const { membershipTypeKey, createFreetextQuestion } = params;

  const res = await apisauce.post<
    { membershipFreetextQuestionKey: string },
    any
  >(
    `/v2/membership-types/${membershipTypeKey}/freetext-questions`,
    createFreetextMapper(createFreetextQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.membershipFreetextQuestionKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createFreetextQuestion', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateFreetextQuestion = async (params: {
  membershipTypeKey: string;
  updateFreetextQuestion: MembershipFreetextQuestionForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, updateFreetextQuestion } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/freetext-questions/${updateFreetextQuestion.key}`,
    updateFreetextMapper(updateFreetextQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteFreetextQuestion = async (params: {
  membershipTypeKey: string;
  membershipFreetextQuestionKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipFreetextQuestionKey } = params;

  const res = await apisauce.delete<void, any>(
    `/v2/membership-types/${membershipTypeKey}/freetext-questions/${membershipFreetextQuestionKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
