import { LOCATION_CITIES, LocationCity, TAGS } from '../models';

export const injectMetadataInDescription = (
  description: string,
  tags: string[] | null,
  city: LocationCity | null,
) => {
  return `${description} ${(tags ?? []).join(' ')} ${city ? `#${city}` : ''}`;
};

export const getDescriptionMetadata = (
  description: string,
): {
  description: string;
  tags: string[] | null;
  city: LocationCity | null;
} => {
  let tags: string[] = [];
  let city: LocationCity | null = null;
  let newDescription = description;

  TAGS.forEach((tag) => {
    const tagWithHash = `#${tag}`;

    if (newDescription.includes(tagWithHash)) {
      newDescription = newDescription.replace(tagWithHash, '');
      tags.push(tagWithHash);
    }
  });

  LOCATION_CITIES.forEach((locationCity) => {
    const cityWithHash = `#${locationCity}`;

    if (newDescription.includes(cityWithHash)) {
      newDescription = newDescription.replace(cityWithHash, '');
      city = locationCity;
    }
  });

  return {
    city,
    description: newDescription.trim(),
    tags: tags.length ? tags : null,
  };
};
