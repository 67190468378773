import {
  Address,
  Coordinates,
  SearchAddressesParams,
  SearchCoordinatesParams,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  MembershipTypeType,
  PartialDepartment,
  PartialMembershipType,
  PartialUser,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const searchCoordinatesThunk = createAsyncThunk<
  Coordinates,
  SearchCoordinatesParams,
  ThunkApiConfig
>('search/coordinates', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchCoordinates, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const searchAddressesThunk = createAsyncThunk<
  Address[],
  SearchAddressesParams,
  ThunkApiConfig
>('search/addresses', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchAddresses, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const searchMembershipTypesThunk = createAsyncThunk<
  PartialMembershipType[],
  { searchString: string; types: MembershipTypeType[] },
  ThunkApiConfig
>('search/membership-types', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchMembershipTypes, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const searchUsersThunk = createAsyncThunk<
  PartialUser[],
  { searchString: string },
  ThunkApiConfig
>('search/users', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchUsers, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});

export const searchDepartmentsThunk = createAsyncThunk<
  PartialDepartment[],
  { searchString: string },
  ThunkApiConfig
>('search/departments', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.search.searchDepartments, params);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});
