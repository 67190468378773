import { Currency, TxArgs, translate } from '@orbiapp/components';

export function getOptionalLabelText(tx: TxString, txArgs?: TxArgs) {
  return translate('label.general.optional-label', {
    label: translate(tx, txArgs),
  });
}

export function getCurrencyLabelText(tx: TxString, currency: Currency) {
  return `${translate(tx)} (${currency})`;
}
