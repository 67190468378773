import { BoxProps } from '../box';
import { Styled } from './form-header.styled';

export function FormHeader(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Styled.FormHeader
      backgroundColor="formHeaderBackground"
      py={16}
      px={32}
      {...rest}
    >
      {children}
    </Styled.FormHeader>
  );
}
