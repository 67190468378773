export enum OrbiPayRole {
  Admin = 'orbi_pay_admin',
  OrbiPayNotOnboarded = 'orbi_pay_not_onboarded',
  Member = 'orbi_pay_member',
}

export const businessTypes = ['company', 'non_profit'] as const;
export const accountHolderTypes = ['individual', 'company'] as const;
export const collectTypes = ['currently_due', 'eventually_due'] as const;
export const linkTypes = ['account_onboarding', 'account_update'] as const;
