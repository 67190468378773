import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATION_STATE,
} from '../store.constants';
import {
  membershipApplicationsAdapter,
  membershipInvitesAdapater,
  membershipMembersAdapter,
  membershipPurchaseListItemsAdapter,
  membershipTypeListItemsAdapter,
} from './memberships.adapter';
import { MembershipsState } from './memberships.types';

export const initialMembershipsState: MembershipsState = {
  membershipMember: INITIAL_DATA_STATE,
  membershipMembers: {
    ...INITIAL_DATA_STATE,
    data: membershipMembersAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'fullName' },
    search: '',
  },

  checkIntegration: INITIAL_DATA_STATE,
  createIntegratedMembershipType: INITIAL_DATA_STATE,

  createMembershipInvitations: INITIAL_DATA_STATE,
  membershipInvites: {
    ...INITIAL_DATA_STATE,
    data: membershipInvitesAdapater.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'fullName' },
    includeStatus: null,
  },
  withdrawInvite: INITIAL_DATA_STATE,

  getSuggestedMemberships: INITIAL_DATA_STATE,

  createMembershipApplicationRequirements: INITIAL_DATA_STATE,
  deleteRequirement: INITIAL_DATA_STATE,
  updateCombinationType: INITIAL_DATA_STATE,

  updateGeneralInfo: INITIAL_DATA_STATE,

  acceptMembershipApplication: INITIAL_DATA_STATE,
  rejectMembershipApplication: INITIAL_DATA_STATE,
  requestMoreInfoOnMembershipApplication: INITIAL_DATA_STATE,
  withdrawMembershipApplication: INITIAL_DATA_STATE,
  membershipApplication: INITIAL_DATA_STATE,
  membershipApplications: {
    ...INITIAL_DATA_STATE,
    data: membershipApplicationsAdapter.getInitialState(),
    includeStatus: null,
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'fullName' },
  },

  createCheckboxQuestion: INITIAL_DATA_STATE,
  deleteCheckboxQuestion: INITIAL_DATA_STATE,
  updateCheckboxQuestion: INITIAL_DATA_STATE,

  createFreetextQuestion: INITIAL_DATA_STATE,
  deleteFreetextQuestion: INITIAL_DATA_STATE,
  updateFreetextQuestion: INITIAL_DATA_STATE,

  createMultichoiceQuestion: INITIAL_DATA_STATE,
  deleteMultichoiceQuestion: INITIAL_DATA_STATE,
  updateMultichoiceQuestion: INITIAL_DATA_STATE,

  createPeriod: INITIAL_DATA_STATE,
  deletePeriod: INITIAL_DATA_STATE,
  updatePeriod: INITIAL_DATA_STATE,

  refundMembershipPurchase: INITIAL_DATA_STATE,
  membershipPurchaseListItems: {
    ...INITIAL_DATA_STATE,
    data: membershipPurchaseListItemsAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'purchasedAt' },
  },

  membershipTypeStats: INITIAL_DATA_STATE,
  membershipMemberListItemStats: INITIAL_DATA_STATE,

  createMembershipType: INITIAL_DATA_STATE,
  dynamicMembershipLink: INITIAL_DATA_STATE,

  deleteMembershipType: INITIAL_DATA_STATE,

  membershipsTypeListItems: {
    ...INITIAL_DATA_STATE,
    data: membershipTypeListItemsAdapter.getInitialState(),
  },
  membershipType: INITIAL_DATA_STATE,
};
