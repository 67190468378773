const xxs = 480;
const xs = 768;
const sm = 1024;
const md = 1200;
const lg = 1680;
const xl = 1920;

/**
 * Breakpoints for responsive design in px width
 */
export const breakpoints = { xxs, xs, sm, md, lg, xl } as const;

export function getContentSidebarMinWidth() {
  if (!globalThis || globalThis.innerWidth >= lg) {
    return 611;
  }

  return 479;
}

export type Breakpoint = keyof typeof breakpoints;
