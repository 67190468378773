import Joi from 'joi';

export namespace LocationSchema {
  export const latitude = Joi.number().min(-90).max(90).required();

  export const longitude = Joi.number().min(-180).max(180).required();

  export const address = Joi.string().required().messages({
    'string.empty': 'errors.address.empty',
  });

  export const placeId = Joi.string().required().messages({
    'string.empty': 'errors.place-id.empty',
  });

  export const label = Joi.string().trim().required().messages({
    'string.empty': 'errors.office-location-label.empty',
    'any.required': 'errors.office-location-label.empty',
  });
}
