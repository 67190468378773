import React from 'react';

import { InputElement } from '../input-element-box/input-element-box.types';
import { PasswordStrengthOptions } from '../password-strength';
import { TxArgs } from '../text';

export interface StyledInputHelperBoxProps {
  disabled: boolean;
  hasError: boolean;
  floatLabel: boolean;
}

export interface FormFieldTxProps {
  error?: string;
  errorTx?: TxString;
  errorTxArgs?: TxArgs;
  helperText?: string;
  helperTx?: TxString;
  helperTxArgs?: TxArgs;
  label?: string;
  labelTx?: TxString;
  labelTxArgs?: TxArgs;
}

export interface InputBaseProps extends FormFieldTxProps {
  currentLength?: number;
  maxLength?: number;
  placeholderTx?: TxString;
  placeholderTxArgs?: TxArgs;

  leadingElements?: InputElement[];
  trailingElements?: InputElement[];
}

export interface InputProps
  extends React.ComponentPropsWithRef<'input'>,
    InputBaseProps {
  passwordStrengthOptions?: PasswordStrengthOptions;
}

export interface ControlledInputProps extends InputProps {
  deps?: string[];
  name: string;
}

export const datePickerTypes = ['date', 'datetime-local'] as const;

export type DatePickerType = (typeof datePickerTypes)[number];

export interface ControlledDatePickerProps extends InputProps {
  deps?: string[];

  name: string;

  type: DatePickerType;
}
