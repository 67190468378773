import {
  getDepartmentThunk,
  updateDepartmentThunk,
} from './department.actions';
import { DepartmentBuilder } from './department.types';

const getDepartmentBuild = (builder: DepartmentBuilder) => {
  return builder
    .addCase(getDepartmentThunk.fulfilled, (state, action) => {
      state.department.status = 'completed';
      state.department.data = action.payload;
    })
    .addCase(getDepartmentThunk.pending, (state) => {
      state.department.status = 'pending';
      state.department.error = null;
    })
    .addCase(getDepartmentThunk.rejected, (state, action) => {
      state.department.status = 'idle';
      state.department.error = action.error;
    });
};

const updateDepartmentBuild = (builder: DepartmentBuilder) => {
  return builder
    .addCase(updateDepartmentThunk.fulfilled, (state, action) => {
      state.updateDepartment.status = 'idle';

      if (!state.department.data) return;

      state.department.data = {
        ...state.department.data,

        coverImage: action.payload.coverImage,
        logo: action.payload.logo,

        about: action.meta.arg.about,
        email: action.meta.arg.email,
        facebookUrl: action.meta.arg.facebookUrl,
        instagramUrl: action.meta.arg.instagramUrl,
        name: action.meta.arg.name,
        phone: action.meta.arg.phone,
        twitterUrl: action.meta.arg.twitterUrl,
        websiteUrl: action.meta.arg.websiteUrl,
        youtubeUrl: action.meta.arg.youtubeUrl,
      };
    })
    .addCase(updateDepartmentThunk.pending, (state) => {
      state.updateDepartment.status = 'pending';
      state.updateDepartment.error = null;
    })
    .addCase(updateDepartmentThunk.rejected, (state, action) => {
      state.updateDepartment.status = 'idle';
      state.updateDepartment.error = action.error;
    });
};

export const extraReducers = (builder: DepartmentBuilder) => {
  getDepartmentBuild(builder);
  updateDepartmentBuild(builder);
};
