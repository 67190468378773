import { getCountriesThunk } from './countries.actions';
import { countriesAdapter } from './countries.adapter';
import { CountriesBuilder } from './countries.types';

function getCountriesBuild(builder: CountriesBuilder) {
  return builder
    .addCase(getCountriesThunk.pending, (state) => {
      state.countries.status = 'pending';
      state.countries.error = null;
    })
    .addCase(getCountriesThunk.fulfilled, (state, action) => {
      state.countries.status = 'idle';
      countriesAdapter.setAll(state.countries.data, action.payload);
    })
    .addCase(getCountriesThunk.rejected, (state, action) => {
      state.countries.status = 'idle';
      state.countries.error = action.error;
    });
}

export const extraReducers = (builder: CountriesBuilder) => {
  getCountriesBuild(builder);
};
