import {
  BreadcrumbsToolbar,
  TabButtonListItem,
  useCheckFeatureFlag,
} from '@orbiapp/components';
import React from 'react';

import {
  ActivityDataSelector,
  ActivityDraftSelector,
  useSelector,
} from '../../../../../store';
import { ActivityFormContext } from '../create-activity.context';

export function useRootPath() {
  const activityDraftKey = useSelector(ActivityDraftSelector.selectKey);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const formContext = React.useContext(ActivityFormContext);

  switch (formContext.mode) {
    case 'draft':
      return `/activities/draft/${activityDraftKey}`;

    case 'duplicate':
      return `/activities/duplicate-activity/${activityKey}`;

    case 'update':
      return `/activities/edit-activity/${activityKey}`;

    default:
      return '/activities/create-activity';
  }
}

export function TabHeader() {
  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const enableQuestions = useCheckFeatureFlag(
    'student_dashboard_show_questions',
  );

  const formContext = React.useContext(ActivityFormContext);

  const rootPath = useRootPath();

  const tabButtonListItems: TabButtonListItem[] = [
    {
      tx: 'label.create-activity.tabs.description.label',
      to: `${rootPath}/description`,
      isError: !!formContext.formState.errors.description,
    },
    {
      tx: 'label.create-activity.tabs.media.label',
      to: `${rootPath}/media`,
      isError: !!formContext.formState.errors.media,
    },
    {
      tx: 'label.create-activity.tabs.participants.label',
      to: `${rootPath}/participants`,
      isError: !!formContext.formState.errors.participants,
    },
    {
      tx: 'label.create-activity.tabs.tickets.label',
      to: `${rootPath}/tickets`,
      isError: !!formContext.formState.errors.tickets,
    },
    {
      tx: 'label.create-activity.tabs.requested-info.label',
      to: `${rootPath}/request-more-info`,
      isError: !!formContext.formState.errors.requestMoreInfo,
    },
    {
      tx: 'label.create-activity.tabs.co-hosting.label',
      to: `${rootPath}/co-hosting`,
      isError: !!formContext.formState.errors.coHosting,
    },
  ];

  if (enableQuestions) {
    tabButtonListItems.push({
      tx: 'label.create-activity.tabs.addons.label',
      to: `${rootPath}/addons`,
      isError: !!formContext.formState.errors.addons,
    });
  }

  switch (formContext.mode) {
    case 'create':
    case 'draft':
      return (
        <BreadcrumbsToolbar
          flexGrow={0.7}
          tabButtonListItems={tabButtonListItems}
          breadcrumbListItems={[
            {
              tx: 'label.breadcrumbs.activities.activities',
              to: '/activities',
            },
            {
              tx: 'label.breadcrumbs.activities.create-activity',
              to: rootPath,
            },
          ]}
        />
      );

    case 'duplicate':
      return (
        <BreadcrumbsToolbar
          flexGrow={0.3}
          tabButtonListItems={tabButtonListItems}
          breadcrumbListItems={[
            {
              tx: 'label.breadcrumbs.activities.activities',
              to: '/activities',
            },
            {
              text: activityTitle ?? '',
              to: `/activities/${activityKey}/description`,
              truncate: true,
            },
            {
              tx: 'label.breadcrumbs.activities.duplicate-activity',
              to: rootPath,
            },
          ]}
        />
      );

    case 'update':
      return (
        <BreadcrumbsToolbar
          flexGrow={0.3}
          tabButtonListItems={tabButtonListItems}
          breadcrumbListItems={[
            {
              tx: 'label.breadcrumbs.activities.activities',
              to: '/activities',
            },
            {
              text: activityTitle ?? '',
              to: `/activities/${activityKey}/description`,
              truncate: true,
            },
            {
              tx: 'label.breadcrumbs.activities.edit-activity',
              to: rootPath,
            },
          ]}
        />
      );
  }
}
