import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { theme } from '../../theme';
import { Box } from '../box';
import { Text } from '../text';
import { Styled } from './tooltip.styled';
import { TooltipProps } from './tooltip.types';

const reactTooltipStyles: React.CSSProperties = {
  padding: 0,
  background: theme.colors.tooltipBackground,
  zIndex: 9999,
  transition: 'none',
  borderRadius: 5,
};

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    disabled,
    placement,
    title,
    titleTx,
    titleTxArgs,
    hideTooltip,
    contentElement,
    clickable,

    ...rest
  } = props;

  const id = React.useId();

  return (
    <React.Fragment>
      <ReactTooltip
        clickable={clickable}
        style={reactTooltipStyles}
        place={placement}
        id={id}
        opacity={1}
      >
        <Styled.TooltipContent
          width="max-content"
          maxWidth={250}
          relative
          p={10}
          r={5}
        >
          {contentElement || (
            <Text
              text={title}
              tx={titleTx}
              txArgs={titleTxArgs}
              color="tooltipLabel"
              variant="bodySm"
              whiteSpace="normal"
            />
          )}
        </Styled.TooltipContent>
      </ReactTooltip>

      <Box
        {...rest}
        data-tooltip-position-strategy="fixed"
        data-tooltip-id={id}
        data-tooltip-hidden={disabled || hideTooltip}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
