import React from 'react';

export interface UseOnClickOutsideProps {
  handler: (event: MouseEvent) => void;
  isActive: boolean;
  ref: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[];
  event?: 'mousedown' | 'mouseup';
}

export function useOnClickOutside(props: UseOnClickOutsideProps) {
  const { event = 'mousedown', ref, isActive, handler } = props;

  React.useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!Array.isArray(ref)) {
        if (!ref.current) return;

        if (e.target instanceof Node && !ref.current.contains(e.target)) {
          handler(e);
        }
        return;
      }

      if (
        ref.every(
          (r) =>
            e.target instanceof Node &&
            r.current &&
            !r.current.contains(e.target),
        )
      ) {
        handler(e);
      }
    };

    if (isActive) {
      document.addEventListener(event, listener);
    }

    return () => {
      document.removeEventListener(event, listener);
    };
  }, [ref, isActive, handler, event]);
}
