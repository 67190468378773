import { SegmentationType } from '../../department';
import { MembershipApplicationPeriod, MembershipPeriod } from '../period';
import {
  MembershipCheckboxAnswer,
  MembershipFreetextAnswer,
  MembershipMultichoiceAnswer,
} from '../questions';

export const membershipApplicationStatuses = [
  'pending_review',
  'rejected',
  'withdrawn',
  'needs_more_info',
  'awaiting_completion',
  'completed',
] as const;

export type MembershipApplicationStatus =
  (typeof membershipApplicationStatuses)[number];

export interface MembershipApplicationListItem {
  createdAt: number;
  email: string;
  fullName: string;
  membershipApplicationKey: string;
  periods: MembershipPeriod[] | null;
  status: MembershipApplicationStatus;
}

export const needsMoreInfoReasons = ['answers', 'other'] as const;
export type NeedsMoreInfoReason = (typeof needsMoreInfoReasons)[number];

export interface MembershipApplicationStatusUpdate {
  createdAt: number;
  needsMoreInfoReason: NeedsMoreInfoReason | null;
  note: string | null;
  status: MembershipApplicationStatus;
}

export type MembershipApplicationAnswer =
  | MembershipCheckboxAnswer
  | MembershipFreetextAnswer
  | MembershipMultichoiceAnswer;

export interface MembershipApplication {
  answers: MembershipApplicationAnswer[] | null;
  createdAt: number;
  email: string | null;
  fullName: string | null;
  membershipApplicationKey: string;
  membershipId: number | null;
  periods: MembershipApplicationPeriod[];
  segmentation: SegmentationType;
  semester: number | null;
  status: MembershipApplicationStatus;
  statusUpdatedAt: number;
  statusUpdates: MembershipApplicationStatusUpdate[];
}

export interface RequestMoreInfoOnMembershipApplicationForm {
  membershipApplicationKey: string;
  note: string;
  reason: NeedsMoreInfoReason;
}

export interface RejectMembershipApplicationForm {
  membershipApplicationKey: string;
  note: string;
}

export type MembershipApplicationOrderByKey = keyof Pick<
  MembershipApplicationListItem,
  'fullName' | 'email' | 'createdAt' | 'status'
>;

export const membershipApplicationsSortableKeys: Record<
  MembershipApplicationOrderByKey,
  MembershipApplicationOrderByKey
> = {
  createdAt: 'createdAt',
  email: 'email',
  fullName: 'fullName',
  status: 'status',
};
