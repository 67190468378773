import { combineReducers } from 'redux';

import { accountReducer } from './account';
import { activityReducer } from './activities';
import { authReducer } from './auth';
import { countriesReducer } from './countries';
import { departmentReducer } from './department';
import { fileUploadsReducer } from './file-uploads';
import { globalUiStateReducer } from './global-ui-state';
import { jobsReducer } from './jobs';
import { locationsReducer } from './locations';
import { membershipsReducer } from './memberships';
import { offersReducer } from './offers';
import { orbiPayReducer } from './orbi-pay';
import { postsReducer } from './posts';
import { questionsReducer } from './questions';
import { reactionsReducer } from './reactions';
import { referralsReducer } from './referrals';
import { searchReducer } from './search';
import {
  getStateAfterEmulateUser,
  getStateAfterSignOut,
  getStateAfterSwitchWorkspace,
} from './store.reset';
import { teamReducer } from './team';

const rootReducer = combineReducers({
  account: accountReducer,
  activity: activityReducer,
  auth: authReducer,
  department: departmentReducer,
  globalUiState: globalUiStateReducer,
  jobs: jobsReducer,
  memberships: membershipsReducer,
  orbiPay: orbiPayReducer,
  posts: postsReducer,
  questions: questionsReducer,
  referrals: referralsReducer,
  team: teamReducer,
  search: searchReducer,
  offers: offersReducer,
  locations: locationsReducer,
  countries: countriesReducer,
  fileUploads: fileUploadsReducer,
  reactions: reactionsReducer,
});

export const reducer: typeof rootReducer = (state, action) => {
  switch (action.type) {
    case 'auth/sign-out/fulfilled':
      return rootReducer(getStateAfterSignOut(state), action);
    case 'account/update-workspace/pending':
      return rootReducer(getStateAfterSwitchWorkspace(state), action);
    case 'auth/setIsEmulating':
      return rootReducer(getStateAfterEmulateUser(state), action);
  }

  return rootReducer(state, action);
};
