import Joi from 'joi';

import { ContactDetails } from './contact-details.model';
import { ContactDetailsSchema } from './contact-details.schema';

export const ContactDetailsValidation = Joi.object<ContactDetails>({
  email: ContactDetailsSchema.email,
  name: ContactDetailsSchema.name,
  phone: ContactDetailsSchema.phone,
});
