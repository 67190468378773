export const CARD_ELEVATION = 5;

export const MENU_DROP_SHADOW = 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))';

export const elevation = {
  weak: '0px 2px 8px 4px rgba(11, 11, 13, 0.02)',
  medium: '0px 2px 8px rgba(11, 11, 13, 0.08)',
  strong: '0px 4px 16px 2px rgba(11, 11, 13, 0.08)',

  tableHeader: 'rgb(11 11 13 / 8%) -7px 2px 13px 0px',
  tableCellLeft: 'rgb(11 11 13 / 8%) 13px -15px 11px -8px',
  tableCellRight: 'rgb(11 11 13 / 8%) -13px -15px 11px -8px',
};
