import { Box, Text } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const TicketPriceAndDiscountsErrorBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.errorBoxBackground};
      border: 1px solid ${theme.colors.errorBoxBorder};
    `;
  }}
`;

const ClickableText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

export const Styled = {
  ClickableText,
  TicketPriceAndDiscountsErrorBox,
};
