import {
  IconButton,
  Tooltip,
  stringEllipsis,
  translate,
} from '@orbiapp/components';
import React from 'react';

import { ActivityDataSelector, useSelector } from '../../../../../store';
import { ActivityFormContext } from '../create-activity.context';

export function GoBackButton() {
  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const activityFormContext = React.useContext(ActivityFormContext);

  if (activityFormContext.mode !== 'update' || !activityTitle || !activityKey) {
    return null;
  }

  return (
    <Tooltip
      placement="right"
      title={stringEllipsis(
        translate('label.general.go-back-to', {
          label: activityTitle,
        }),
        40,
      )}
    >
      <IconButton icon="chevron-left" to={`/activities/${activityKey}`} />
    </Tooltip>
  );
}
