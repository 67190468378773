import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  activityQuestionsAdapter,
  questionsAdapter,
} from './questions.adapter';

export namespace ActivityQuestionsSelector {
  const selectSelf = (state: RootState) => state.questions.activityQuestions;

  export const selectStatus = createSelector(
    selectSelf,
    (activityQuestions) => activityQuestions.status,
  );

  const selectData = createSelector(
    selectSelf,
    (activityQuestions) => activityQuestions.data,
  );

  const activityQuestionsSelectors =
    activityQuestionsAdapter.getSelectors(selectData);

  export const selectAll = activityQuestionsSelectors.selectAll;
}

export namespace HideQuestionSelector {
  export const selectStatus = (state: RootState) =>
    state.questions.hideQuestion.status;
}

export namespace UpdateQuestionSelector {
  export const selectStatus = (state: RootState) =>
    state.questions.updateQuestion.status;
}

export namespace QuestionsSelector {
  const selectSelf = (state: RootState) => state.questions.questions;

  export const selectHideHandled = createSelector(
    selectSelf,
    (questions) => questions.hideHandled,
  );

  export const selectPagination = createSelector(
    selectSelf,
    (questions) => questions.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (questions) => questions.status,
  );

  const selectData = createSelector(selectSelf, (questions) => questions.data);

  const questionsSelectors = questionsAdapter.getSelectors(selectData);

  export const selectAll = questionsSelectors.selectAll;

  export const selectTotal = questionsSelectors.selectTotal;
}

export namespace UnhandledQuestionsSelector {
  export const selectData = (state: RootState) =>
    state.questions.unhandledQuestions.data;
}
