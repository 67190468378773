import { CountryCode } from '@orbiapp/components';

import { AccountHolderType, BusinessType } from '../models';

export const getBusinessTypeTx = (businessType: BusinessType): TxString => {
  switch (businessType) {
    case 'company':
      return `label.join-orbi-pay.general-information.business-type.company`;
    case 'non_profit':
      return `label.join-orbi-pay.general-information.business-type.non_profit`;
  }
};

export const getCountryCodeTx = (countryCode: CountryCode): TxString => {
  switch (countryCode) {
    case CountryCode.DK:
      return `label.join-orbi-pay.general-information.country.DK`;
    case CountryCode.NO:
      return `label.join-orbi-pay.general-information.country.NO`;
    case CountryCode.SE:
      return `label.join-orbi-pay.general-information.country.SE`;
    case CountryCode.FI:
      return `label.join-orbi-pay.general-information.country.FI`;
  }
};

export const getAccountHolderTypeTx = (
  accountHolderType: AccountHolderType,
): TxString => {
  switch (accountHolderType) {
    case 'company':
      return 'label.join-orbi-pay.payout-information.account-holder-name.company';
    case 'individual':
      return 'label.join-orbi-pay.payout-information.account-holder-name.individual';
  }
};

export const getAccountHolderTypeOptionTx = (
  accountHolderType: AccountHolderType,
): TxString => {
  switch (accountHolderType) {
    case 'company':
      return 'label.join-orbi-pay.payout-information.account-holder-type.company';
    case 'individual':
      return 'label.join-orbi-pay.payout-information.account-holder-type.individual';
  }
};
