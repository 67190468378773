import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Text } from '../text';

const CheckboxBackground = styled.div`
  ${(props) => {
    const { theme } = props;

    return css`
      width: 32px;
      height: 32px;
      padding: 3px;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${theme.colors.checkboxBackground};
    `;
  }}
`;

const IconBox = styled.div`
  cursor: pointer;
  z-index: 3;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Checkbox = styled.div<{ disabled?: boolean; checked?: boolean }>`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  position: relative;
  border-radius: 4px;

  ${(props) => {
    const { checked, disabled, theme } = props;

    if (checked && disabled) {
      return css`
        pointer-events: none;

        ${IconBox} {
          background-color: ${theme.colors
            .checkboxIconBackgroundToggledDisabled};
          border-color: ${theme.colors.checkboxBorderToggledDisabled};
        }

        svg {
          stroke: ${theme.colors.checkboxIconToggledDisabled};
        }
      `;
    }

    if (checked) {
      return css`
        ${IconBox} {
          background-color: ${theme.colors.checkboxIconBackgroundToggled};
          border-color: ${theme.colors.checkboxBorderToggled};
        }

        svg {
          stroke: ${theme.colors.checkboxIconToggled};
        }

        :active {
          ${IconBox} {
            border-color: ${theme.colors.checkboxBorderToggledActive};
            background-color: ${theme.colors.checkboxIconBackgroundToggled};
          }

          ${CheckboxBackground} {
            background-color: ${theme.colors.checkboxBackgroundActive};
          }
        }

        :hover {
          ${IconBox} {
            border-color: ${theme.colors.checkboxBorderToggledHover};
            background-color: ${theme.colors.checkboxIconBackgroundToggled};
          }

          ${CheckboxBackground} {
            background-color: ${theme.colors.checkboxBackgroundActive};
          }
        }
      `;
    }

    if (disabled) {
      return css`
        pointer-events: none;

        ${IconBox} {
          background-color: ${theme.colors.checkboxIconBackgroundDisabled};
          border-color: ${theme.colors.checkboxBorderDisabled};
        }

        svg {
          stroke: ${theme.colors.checkboxIconDisabled};
        }
      `;
    }

    return css`
      ${IconBox} {
        background-color: ${theme.colors.checkboxIconBackground};
        border-color: ${theme.colors.checkboxBorder};
      }

      svg {
        stroke: ${theme.colors.checkboxIcon};
      }

      :hover {
        ${IconBox} {
          border-color: ${theme.colors.checkboxBorderHover};
        }

        ${CheckboxBackground} {
          background-color: ${theme.colors.checkboxBackgroundHover};
        }
      }

      :active {
        ${IconBox} {
          border-color: ${theme.colors.checkboxBorderActive};
        }

        ${CheckboxBackground} {
          background-color: ${theme.colors.checkboxBackgroundActive};
        }
      }
    `;
  }}
`;

interface CheckboxWithLabelBoxProps {
  disabled?: boolean;
  hasSubTitle?: boolean;
}

const CheckboxSubtitle = styled(Text)``;

const CheckboxWithLabelBox = styled(Box)<CheckboxWithLabelBoxProps>`
  ${(props) => {
    const { disabled, theme, hasSubTitle } = props;

    if (disabled) {
      return css`
        pointer-events: none;
        color: ${theme.colors.checkboxLabelDisabled};

        ${hasSubTitle &&
        css`
          ${Checkbox} {
            margin-top: 1px;
          }
        `}

        ${CheckboxSubtitle} {
          color: ${theme.colors.checkboxSubtitleDisabled};
        }
      `;
    }

    return css`
      color: ${theme.colors.checkboxLabel};

      ${CheckboxSubtitle} {
        color: ${theme.colors.checkboxSubtitle};
      }

      ${hasSubTitle &&
      css`
        ${Checkbox} {
          margin-top: 1px;
        }
      `}
    `;
  }}
`;

export const Styled = {
  Checkbox,
  CheckboxBackground,
  CheckboxSubtitle,
  CheckboxWithLabelBox,
  IconBox,
};
