import { css } from 'styled-components';

import { pxToRem, stringOrPx } from '../string';
import { isBoolean, isUndefined } from '../utils';
import { Flex, FlexMap } from './flex.types';

export const flexStyles = css<Flex>`
  ${(props) => {
    const {
      alignSelf,
      columnGap,
      flex,
      flexAlign,
      flexBasis,
      flexDirection,
      flexGrow,
      flexJustify,
      flexShrink,
      flexWrap,
      gap,
      rowGap,
      wrapReverse,
    } = props;

    return css`
      ${!isUndefined(flex) &&
      css`
        display: flex;
      `}

      ${!isUndefined(rowGap) &&
      css`
        row-gap: ${pxToRem(rowGap)};
      `};

      ${!isUndefined(columnGap) &&
      css`
        column-gap: ${pxToRem(columnGap)};
      `};

      ${!isUndefined(gap) &&
      css`
        gap: ${stringOrPx(gap)};
      `};

      ${!isUndefined(flexDirection) &&
      css`
        flex-direction: ${flexDirection};
      `};

      ${!isUndefined(flexJustify) &&
      css`
        justify-content: ${FlexMap[flexJustify]};
      `};

      ${!isUndefined(flexAlign) &&
      css`
        align-items: ${FlexMap[flexAlign]};
      `};

      ${!isUndefined(flexGrow) &&
      css`
        flex-grow: ${flexGrow};
      `};

      ${!isUndefined(flexShrink) &&
      css`
        flex-shrink: ${flexShrink};
      `};

      ${!isUndefined(flexWrap) &&
      css`
        flex-wrap: ${isBoolean(flexWrap) ? 'wrap' : flexWrap};
      `};

      ${!isUndefined(wrapReverse) &&
      css`
        flex-wrap: wrap-reverse;
      `};

      ${!isUndefined(alignSelf) &&
      css`
        align-self: ${alignSelf};
      `};

      ${!isUndefined(flexBasis) &&
      css`
        flex-basis: ${stringOrPx(flexBasis)};
      `};
    `;
  }};
`;
