import styled, { css } from 'styled-components';

import { Box } from '../box';

const ListItem = styled(Box)`
  ${(props) => {
    const { onClick, theme } = props;

    return css`
      background-color: ${theme.colors.listItemBackground};

      :not(:last-child) {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: ${theme.colors.listItemBorder};
      }

      ${onClick &&
      css`
        cursor: pointer;
        transition: ${theme.transitions.fast};

        :hover {
          border-color: ${theme.colors.listItemBorderHover};
          background-color: ${theme.colors.listItemBackgroundHover};
        }

        :active {
          background-color: ${theme.colors.listItemBackgroundActive};
          background-color: ${theme.colors.listItemBackgroundActive};
        }
      `}
    `;
  }}
`;

export const Styled = { ListItem };
