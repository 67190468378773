import { ContactDetails } from '../../contact-details';
import { PartialOrgNode } from '../../org-node';

export const cohostRequestStatus = [
  'pending',
  'accepted',
  'rejected',
  'canceled',
  'not-sent',
] as const;
export declare type CohostRequestStatus = (typeof cohostRequestStatus)[number];

export interface CoHost {
  cohostRequestStatus: CohostRequestStatus;
  departmentKey: string;
  name: string;
  logoUrl: string | null;
  orgNodesBranch: PartialOrgNode[];
}

export interface Host {
  departmentKey: string;
  name: string;
  logoUrl: string | null;
  orgNodesBranch: PartialOrgNode[];
}

export interface CoHostRequestListItem {
  activityContact: ContactDetails;
  activityCoverImageUrl: string;
  activityEndDate: number;
  activityKey: string;
  activityStartDate: number;
  activityTitle: string;
  cohostRequestKey: string;
  createdAt: number;
  hostingDepartmentLogoUrl: string | null;
  hostingDepartmentName: string;
  hostingDepartmentOrgNodeName: string;
  hostingDepartmentKey: string;
}

export interface CreateCoHostRequest
  extends Pick<
    CoHost,
    'departmentKey' | 'name' | 'logoUrl' | 'orgNodesBranch'
  > {
  cohostRequestStatus: CohostRequestStatus;
}
