import styled, { css } from 'styled-components';

import { Box } from '../box';
import { BackdropProps } from './backdrop.types';

const Backdrop = styled(Box)<BackdropProps>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: ${theme.transitions.fast};
      background-color: rgba(0, 0, 0, 0);
      pointer-events: none;
      right: 0;

      ${isOpen &&
      css`
        background-color: ${theme.colors.backdrop};
        pointer-events: auto;
        cursor: pointer;
      `}
    `;
  }}
`;

export const Styled = { Backdrop };
