import { Text } from '../text';
import { Styled } from './chip.styled';
import { ChipProps } from './chip.types';

export function Chip(props: ChipProps) {
  const { children, text, tx, txArgs, ...rest } = props;

  return (
    <Styled.Chip {...rest}>
      {children || (
        <Text
          as="span"
          tx={tx}
          txArgs={txArgs}
          text={text}
          variant="bodySm"
          lineHeight="unset"
        />
      )}
    </Styled.Chip>
  );
}
