import Joi from 'joi';

import { RequestedInfo } from './requested-info.model';
import { OptionsSchema, RequestedInfoSchema } from './requested-info.schema';

export const RequestedInfoItemValidation = Joi.object<RequestedInfo>({
  enablePriceIncrement: RequestedInfoSchema.enablePriceIncrement.optional(),
  id: RequestedInfoSchema.id,
  index: RequestedInfoSchema.index,
  isNew: RequestedInfoSchema.isNew,
  isRequired: RequestedInfoSchema.isRequired,
  options: OptionsSchema.options,
  priceIncrement: RequestedInfoSchema.priceIncrement,
  question: RequestedInfoSchema.question,
  ticketTypeKeys: RequestedInfoSchema.ticketTypeKeys,
  type: RequestedInfoSchema.type,
});

export const CheckboxValidation = Joi.object({
  enablePriceIncrement: RequestedInfoSchema.enablePriceIncrement.required(),
  id: RequestedInfoSchema.id,
  index: RequestedInfoSchema.index,
  isNew: RequestedInfoSchema.isNew,
  isRequired: RequestedInfoSchema.isRequired,
  priceIncrement: RequestedInfoSchema.priceIncrement,
  question: RequestedInfoSchema.question,
  ticketTypeKeys: RequestedInfoSchema.ticketTypeKeys,
  type: RequestedInfoSchema.type,
});

export const FreeTextValidation = Joi.object({
  id: RequestedInfoSchema.id,
  index: RequestedInfoSchema.index,
  isNew: RequestedInfoSchema.isNew,
  isRequired: RequestedInfoSchema.isRequired,
  question: RequestedInfoSchema.question,
  ticketTypeKeys: RequestedInfoSchema.ticketTypeKeys,
  type: RequestedInfoSchema.type,
});

export const MultiChoiceValidation = Joi.object({
  enablePriceIncrement: RequestedInfoSchema.enablePriceIncrement.required(),
  id: RequestedInfoSchema.id,
  index: RequestedInfoSchema.index,
  isNew: RequestedInfoSchema.isNew,
  isRequired: RequestedInfoSchema.isRequired,
  options: OptionsSchema.options,
  question: RequestedInfoSchema.question,
  ticketTypeKeys: RequestedInfoSchema.ticketTypeKeys,
  type: RequestedInfoSchema.type,
});
