export type TextVariant =
  | 'bodyLg'
  | 'bodyLgBold'
  | 'bodyLgItalic'
  | 'bodyMd'
  | 'bodyMdBold'
  | 'bodyMdItalic'
  | 'bodySm'
  | 'bodySmBold'
  | 'bodySmItalic'
  | 'bodyXs'
  | 'buttonLg'
  | 'buttonMd'
  | 'buttonSm'
  | 'displayLg'
  | 'displayMd'
  | 'displaySm'
  | 'displayXl'
  | 'displayXs'
  | 'displayXxs'
  | 'titleLg'
  | 'titleMd'
  | 'titleSm'
  | 'titleXs';

export const textVariants: Record<
  TextVariant,
  {
    fontFamily: string;
    fontSize: number;
    letterSpacing: number;
    lineHeight: number;
  }
> = {
  displayXl: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 75,
    lineHeight: 82.5,
    letterSpacing: 5.25,
  },
  displayLg: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 61,
    lineHeight: 67.1,
    letterSpacing: 4.27,
  },
  displayMd: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 49,
    lineHeight: 53.9,
    letterSpacing: 3.43,
  },
  displaySm: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 39,
    lineHeight: 42.9,
    letterSpacing: 2.73,
  },
  displayXs: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 31,
    lineHeight: 34.1,
    letterSpacing: 2.17,
  },
  displayXxs: {
    fontFamily: 'RobotoFlex-Display',
    fontSize: 25,
    lineHeight: 27.5,
    letterSpacing: 1.75,
  },

  titleLg: {
    fontFamily: 'RobotoFlex-Title',
    fontSize: 31,
    lineHeight: 34.1,
    letterSpacing: 0.31,
  },
  titleMd: {
    fontFamily: 'RobotoFlex-Title',
    fontSize: 25,
    lineHeight: 27.5,
    letterSpacing: 0.25,
  },
  titleSm: {
    fontFamily: 'RobotoFlex-Title',
    fontSize: 20,
    lineHeight: 22,
    letterSpacing: 0.2,
  },
  titleXs: {
    fontFamily: 'RobotoFlex-Title',
    fontSize: 16,
    lineHeight: 17.6,
    letterSpacing: 0.16,
  },

  bodyLg: {
    fontFamily: 'RobotoFlex-Body',
    fontSize: 20,
    lineHeight: 26,
    letterSpacing: 0.2,
  },
  bodyMd: {
    fontFamily: 'RobotoFlex-Body',
    fontSize: 16,
    lineHeight: 20.8,
    letterSpacing: 0.16,
  },
  bodySm: {
    fontFamily: 'RobotoFlex-Body',
    fontSize: 13,
    lineHeight: 16.8,
    letterSpacing: 0.13,
  },
  bodyXs: {
    fontFamily: 'RobotoFlex-Body',
    fontSize: 10,
    lineHeight: 12,
    letterSpacing: 0.1,
  },

  bodyLgItalic: {
    fontFamily: 'RobotoFlex-BodyItalic',
    fontSize: 20,
    lineHeight: 26,
    letterSpacing: 0.2,
  },
  bodyMdItalic: {
    fontFamily: 'RobotoFlex-BodyItalic',
    fontSize: 16,
    lineHeight: 20.8,
    letterSpacing: 0.16,
  },
  bodySmItalic: {
    fontFamily: 'RobotoFlex-BodyItalic',
    fontSize: 13,
    lineHeight: 16.9,
    letterSpacing: 0.13,
  },

  bodyLgBold: {
    fontFamily: 'RobotoFlex-BodyBold',
    fontSize: 20,
    lineHeight: 26,
    letterSpacing: 0.2,
  },
  bodyMdBold: {
    fontFamily: 'RobotoFlex-BodyBold',
    fontSize: 16,
    lineHeight: 20.8,
    letterSpacing: 0.16,
  },
  bodySmBold: {
    fontFamily: 'RobotoFlex-BodyBold',
    fontSize: 13,
    lineHeight: 16.9,
    letterSpacing: 0.13,
  },

  buttonLg: {
    fontFamily: 'RobotoFlex-Button',
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.2,
  },
  buttonMd: {
    fontFamily: 'RobotoFlex-Button',
    fontSize: 16,
    lineHeight: 19.2,
    letterSpacing: 0.16,
  },
  buttonSm: {
    fontFamily: 'RobotoFlex-Button',
    fontSize: 13,
    lineHeight: 15.6,
    letterSpacing: 0.13,
  },
};
