import { TicketListItem } from '../../../../../../../models';
import { TicketListItemDto } from './sold.dto';

export const ticketListItemMapper = (
  ticketListItem: TicketListItemDto,
): TicketListItem => ({
  claimedAt: ticketListItem.claimedAt,
  consumedAt: ticketListItem.consumedAt,
  details: null,
  email: ticketListItem.email,
  emailTransfer: false,
  foodPreferences: ticketListItem.foodPreferences,
  fullName: ticketListItem.fullName,
  ticketKey: ticketListItem.ticketKey,
  ticketTypeName: ticketListItem.ticketTypeName,
  profilePicture: ticketListItem.profilePicture,
  userKey: ticketListItem.userKey,
});
