import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Radio,
  flattenFieldErrorsObject,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
  CombinationType,
  UpdateCombinationTypeForm,
  UpdateCombinationTypeFormValidation,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  MembershipTypeSelector,
  UpdateCombinationTypeSelector,
  updateCombinationTypeThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { SettingCard } from '../setting-card';

function PickCombinationTypeForm(props: {
  closeModal: () => void;
  defaultValue: CombinationType;
}) {
  const { closeModal, defaultValue } = props;

  const { handleSubmit, setValue, watch } = useForm<UpdateCombinationTypeForm>({
    resolver: joiResolver(UpdateCombinationTypeFormValidation),
    defaultValues: { combinationType: defaultValue },
  });

  const dispatch = useDispatch();

  const combinationType = watch('combinationType');
  const updateCombinationTypeStatus = useSelector(
    UpdateCombinationTypeSelector.selectStatus,
  );

  const isLoading = updateCombinationTypeStatus === 'pending';

  const selectCombinationTypeAll = () => {
    setValue('combinationType', 'all');
  };

  const selectCombinationTypeAny = () => {
    setValue('combinationType', 'any');
  };

  const verifyAndSubmit = handleSubmit(
    async (data) => {
      await dispatch(updateCombinationTypeThunk(data.combinationType));
      closeModal();
    },
    (err) => {
      Logger.warning('updateMembershipCombinationType Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <React.Fragment>
      <ModalBodyContainer>
        <Box flex flexWrap="wrap" gap={16}>
          <Radio
            checked={combinationType === 'all'}
            disabled={isLoading}
            onChange={selectCombinationTypeAll}
            tx="label.memberships.view-membership.settings-cards.combination-type.all"
          />
          <Radio
            checked={combinationType === 'any'}
            disabled={isLoading}
            onChange={selectCombinationTypeAny}
            tx="label.memberships.view-membership.settings-cards.combination-type.any"
          />
        </Box>
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button tx="button.cancel" onClick={closeModal} variant="tertiary" />
        <Button
          isLoading={isLoading}
          onClick={verifyAndSubmit}
          tx="label.memberships.view-membership.settings-cards.combination-type.button"
          variant="primary"
        />
      </ModalFooterContainer>
    </React.Fragment>
  );
}

export function CombinatonTypeSettingsCard() {
  const combinationType = useSelector(
    MembershipTypeSelector.selectCombinationType,
  );
  const requiredMembershipsCount = useSelector(
    MembershipTypeSelector.selectRequiredMembershipsCount,
  );

  const { closeModal, isOpen, openModal } = useModalState();

  if (!combinationType || requiredMembershipsCount < 2) {
    return null;
  }

  return (
    <React.Fragment>
      <SettingCard
        icon="pencil-square-outline"
        onClick={openModal}
        subtitleTx={
          combinationType === 'all'
            ? 'label.memberships.view-membership.settings-cards.combination-type.all'
            : 'label.memberships.view-membership.settings-cards.combination-type.any'
        }
        titleTx="label.memberships.view-membership.settings-cards.combination-type.title"
      />

      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalContentContainer>
          <ModalHeaderContainer>
            <ModalTitle tx="label.memberships.view-membership.settings-cards.combination-type.modal-header" />
          </ModalHeaderContainer>

          <PickCombinationTypeForm
            closeModal={closeModal}
            defaultValue={combinationType}
          />
        </ModalContentContainer>
      </Modal>
    </React.Fragment>
  );
}
