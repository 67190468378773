import React from 'react';

import { SortingOrder } from '../models';
import { sortArray } from '../utils/array';

export function useClientSort<T extends object>(
  items: T[],
  options: {
    defaultOrderBy: string;
    defaultSortOrder: SortingOrder;
  },
) {
  const [state, setState] = React.useState<{
    items: T[];
    sortOrder: SortingOrder;
    orderBy: string;
  }>({
    items,
    sortOrder: options.defaultSortOrder,
    orderBy: options.defaultOrderBy,
  });

  const onSort = (columnName: string) => {
    let sortOrder = state.sortOrder;
    if (columnName === state.orderBy) {
      sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      sortOrder = 'desc';
    }

    setState({
      items: sortArray(state.items, columnName, sortOrder),
      sortOrder,
      orderBy: columnName,
    });
  };

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      items: sortArray(items, prev.orderBy, prev.sortOrder),
    }));
  }, [items]);

  return { onSort, ...state };
}
