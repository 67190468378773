import { css } from 'styled-components';

export const numberFormatter = new Intl.NumberFormat('sv-SE');

export function isInfinity(value: number) {
  return value === Infinity || value === -Infinity;
}

export const isAnyTrue = (...array: boolean[]) => array.some((value) => value);

export function reduceDecimals<T extends number | undefined>(
  value: T,
  count: number = 2,
) {
  if (value === undefined) {
    return value;
  }

  return parseFloat(value.toFixed(count));
}

export function isUndefined(value?: any): value is undefined {
  return value === undefined;
}

export function isNumber(val: any): val is number {
  return typeof val === 'number';
}

export function isBoolean(val: any): val is boolean {
  return typeof val === 'boolean';
}

export function downloadFileContent(blob: Blob, filename: string) {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(url);
}

export function constructQueryURL<Params extends {}>(
  endpoint: string,
  params: Params,
) {
  const searchParams = { ...params };

  for (const key in searchParams) {
    if (searchParams[key] === undefined) {
      delete searchParams[key];
    }
  }

  const urlSearchParams = new URLSearchParams(searchParams);
  const queryString = urlSearchParams.toString();

  return `${endpoint}?${queryString}`;
}

export function getOrbiGradientBorder(width: number) {
  return css`
    background-clip: padding-box;
    position: relative;
    box-shadow: 0 0 0 ${width}px transparent;

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -${width}px;
      border-radius: inherit;
      background: radial-gradient(
          77.55% 75.84% at 105.2% 43.68%,
          #94dbeb 32.51%,
          rgba(148, 219, 235, 0) 100%
        ),
        radial-gradient(
          70.52% 106.83% at 3.19% 50%,
          #dcbfef 0%,
          rgba(220, 191, 239, 0) 100%
        ),
        radial-gradient(
          70.52% 106.83% at 3.19% 50%,
          #dcbfef 0%,
          rgba(220, 191, 239, 0) 100%
        ),
        linear-gradient(0deg, #ff7b1f, #ff7b1f);
    }
  `;
}

export function useInfinityScroll(onReachedEnd: () => void) {
  const handleListScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLDivElement;

    const isAtBottom =
      target.scrollTop > (target.scrollHeight - target.clientHeight) * 0.9;
    if (!isAtBottom) return;

    onReachedEnd();
  };

  return handleListScroll;
}
