import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import {
  MembersOrderByKey,
  MembershipMember,
  MembershipMemberListItem,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  MembershipMemberDto,
  MembershipMemberListItemDto,
} from './members.dto';
import {
  getMembershipMemberMapper,
  membershipMemberMapper,
} from './members.mappers';

export const getMembershipMembers = async (params: {
  membershipTypeKey: string;
  pagination: Pagination<MembersOrderByKey>;
  search: string | undefined;
}): Promise<GeneralApiResultWithData<MembershipMemberListItem[]>> => {
  const { membershipTypeKey, pagination, search } = params;

  const res = await apisauce.get<MembershipMemberListItemDto[], any>(
    `/v2/membership-types/${membershipTypeKey}/members`,
    {
      ...pagination,
      search: search?.length ? search : undefined,
    },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(membershipMemberMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipMembers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getMembershipMember = async (params: {
  membershipTypeKey: string;
  userKey: string;
}): Promise<GeneralApiResultWithData<MembershipMember>> => {
  const { membershipTypeKey, userKey } = params;

  const res = await apisauce.get<MembershipMemberDto, any>(
    `/v2/membership-types/${membershipTypeKey}/members/${userKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = getMembershipMemberMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipMember', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
