import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getOrbiGradientBorder } from '../../utils';
import { Box } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';

const MenuButton = styled.button`
  ${(props) => {
    const { theme } = props;

    return css`
      all: unset;
      display: flex;
      cursor: pointer;
      border-radius: 32px;
      gap: 1px;
      background-color: ${theme.colors.menuButtonBackground};
      position: relative;

      :hover {
        background-color: ${theme.colors.menuButtonBackgroundHover};
      }

      :active {
        background-color: ${theme.colors.menuButtonBackgroundActive};
      }
    `;
  }}
`;

const LabelBackground = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.menuButtonLabelBackground};
      z-index: 2;
      position: absolute;
      inset: 0;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    `;
  }}
`;

const LabelIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      z-index: 3;
      color: ${theme.colors.menuButtonLabelIcon};
    `;
  }}
`;

const LabelText = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      z-index: 3;
      color: ${theme.colors.menuButtonLabelText};
    `;
  }}
`;

const labelStyles = css`
  ${(props) => {
    const { theme } = props;

    return css`
      border: 2px solid ${theme.colors.menuButtonLabelBorder};
      position: relative;
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      gap: 4px;

      :hover {
        ${LabelBackground} {
          background-color: ${theme.colors.menuButtonLabelBackgroundHover};
        }

        ${LabelIcon} {
          color: ${theme.colors.menuButtonLabelIconHover};
        }

        ${LabelText} {
          color: ${theme.colors.menuButtonLabelTextHover};
        }
      }

      :active {
        ${getOrbiGradientBorder(2)};

        ::before {
          z-index: 1 !important;
        }

        ${LabelBackground} {
          background-color: ${theme.colors.menuButtonLabelBackgroundActive};
        }

        ${LabelIcon} {
          color: ${theme.colors.menuButtonLabelIconActive};
        }

        ${LabelText} {
          color: ${theme.colors.menuButtonLabelTextActive};
        }
      }
    `;
  }}
`;

const Label = styled(Box)`
  ${labelStyles}
`;

const LabelLink = styled(Link)`
  ${labelStyles};
  text-decoration: none;
`;

const ToggleBackground = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.menuButtonToggleBackground};
      z-index: 1;
      position: absolute;
      inset: 0;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
    `;
  }}
`;

const ToggleIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      z-index: 3;
      color: ${theme.colors.menuButtonToggleIcon};
    `;
  }}
`;

const Toggle = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border: 2px solid ${theme.colors.menuButtonToggleBorder};
      position: relative;

      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;

      :hover {
        ${ToggleBackground} {
          background-color: ${theme.colors.menuButtonToggleBackgroundHover};
        }

        ${ToggleIcon} {
          color: ${theme.colors.menuButtonToggleIconHover};
        }
      }

      :active {
        ${getOrbiGradientBorder(2)};

        ::before {
          z-index: 1 !important;
        }

        ${ToggleBackground} {
          background-color: ${theme.colors.menuButtonToggleBackgroundActive};
        }

        ${ToggleIcon} {
          color: ${theme.colors.menuButtonToggleIconActive};
        }
      }
    `;
  }}
`;

export const Styled = {
  MenuButton,
  Label,
  LabelLink,
  LabelBackground,
  LabelIcon,
  LabelText,
  Toggle,
  ToggleBackground,
  ToggleIcon,
};
