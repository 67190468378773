import styled, { css } from 'styled-components';

const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 100%;
  position: relative;
  height: 100%;
`;

const Table = styled.table<{ isFixed?: boolean }>`
  width: 100%;
  border-collapse: collapse;

  ${(props) => {
    const { isFixed } = props;

    return css`
      ${isFixed &&
      css`
        table-layout: fixed;
        border-spacing: 0;
      `}
    `;
  }}
`;

export const Styled = { TableContainer, Table };
