import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './department.build';
import { initialDepartmentState } from './department.initial';
import { reducers } from './department.reducers';

const departmentSlice = createSlice({
  name: 'department',
  initialState: initialDepartmentState,
  reducers,
  extraReducers,
});

export const departmentReducer = departmentSlice.reducer;
