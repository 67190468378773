import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './posts.build';
import { initialPostsState } from './posts.initial';
import { reducers } from './posts.reducers';

const postsSlice = createSlice({
  name: 'posts',
  initialState: initialPostsState,
  reducers,
  extraReducers,
});

export const postsActions = postsSlice.actions;
export const postsReducer = postsSlice.reducer;
