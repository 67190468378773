import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATED_STATE,
} from '../store.constants';
import { reactionsAdapter } from './reactions.adapter';
import { ReactionsState } from './reactions.types';

export const initialReactionsState: ReactionsState = {
  reactions: {
    ...INITIAL_PAGINATED_STATE,
    items: reactionsAdapter.getInitialState(),
    orderBy: undefined,
    pageSize: 25,
  },

  createReaction: INITIAL_DATA_STATE,
  deleteReaction: INITIAL_DATA_STATE,
};
