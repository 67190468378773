import { toScu } from '@orbiapp/components';

import {
  ActivityCategory,
  ActivityLink,
  ActivityListItem,
  ActivityRecord,
  ActivityRequestedInfoOption,
  CreateCoHostRequest,
  DiscountCode,
  MembershipTypeDiscount,
  RequestedInfo,
  RequestedInfoType,
  RequiredMembershipTypes,
  TicketType,
  UpdateActivityForm,
} from '../../../../../models';
import { injectMetadataInDescription } from '../../../../../utils';
import {
  CheckboxQuestionDto,
  DiscountCodeDto,
  FreetextQuestionDto,
  LinkDto,
  MembershipTypeDiscountDto,
  MultiChoiceOptionDto,
  MultichoiceQuestionDto,
  TicketTypeDto,
  UpdateActivityDto,
} from './activities.dto';

const multichoiceOptionMapper = (
  option: ActivityRequestedInfoOption,
  index: number,
): MultiChoiceOptionDto => ({
  index,
  label: option.label,
  priceIncrement: toScu(option.priceIncrement),
  multichoiceOptionKey: option.isNew ? null : option.id,
});

const requestedInfoMapper = (requestedInfo: RequestedInfo[] | null) => {
  const checkboxQuestions: CheckboxQuestionDto[] = [];
  const freetextQuestions: FreetextQuestionDto[] = [];
  const multichoiceQuestions: MultichoiceQuestionDto[] = [];

  if (!requestedInfo) {
    return {
      checkboxQuestions,
      freetextQuestions,
      multichoiceQuestions,
    };
  }

  requestedInfo.forEach((requestedInfo) => {
    const index =
      checkboxQuestions.length +
      freetextQuestions.length +
      multichoiceQuestions.length;

    switch (requestedInfo.type) {
      case RequestedInfoType.Checkbox:
        checkboxQuestions.push({
          index,
          priceIncrement: toScu(requestedInfo.priceIncrement),
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
          checkboxQuestionKey: requestedInfo.isNew ? null : requestedInfo.id,
        });
        return;

      case RequestedInfoType.FreeText:
        freetextQuestions.push({
          index,
          isRequired: requestedInfo.isRequired,
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
          freetextQuestionKey: requestedInfo.isNew ? null : requestedInfo.id,
        });
        return;

      case RequestedInfoType.MultiChoice:
        multichoiceQuestions.push({
          index,
          isRequired: requestedInfo.isRequired,
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
          multichoiceQuestionKey: requestedInfo.isNew ? null : requestedInfo.id,
          multichoiceOptions: requestedInfo.options.map(
            multichoiceOptionMapper,
          ),
        });
        return;
    }
  });

  return {
    checkboxQuestions,
    freetextQuestions,
    multichoiceQuestions,
  };
};

const cohostMapper = (cohost: CreateCoHostRequest): string =>
  cohost.departmentKey;

const linkMapper = (link: ActivityLink): LinkDto => ({
  label: link.label,
  url: link.url,
});

const discountCodeMapper = (discountCode: DiscountCode): DiscountCodeDto => ({
  code: discountCode.code,
  discount: toScu(discountCode.discount),
  discountCodeKey: discountCode.isNew ? null : discountCode.discountCodeKey,
});

const membershipTypeDiscountMapper = (
  membershipTypeDiscount: MembershipTypeDiscount,
): MembershipTypeDiscountDto => ({
  discount: toScu(membershipTypeDiscount.discount),
  membershipTypeKey: membershipTypeDiscount.membershipTypeKey,
  membershipTypeDiscountKey: membershipTypeDiscount.isNew
    ? null
    : membershipTypeDiscount.membershipTypeDiscountKey,
});

const membershipTypeKeyMapper = (
  requiredMembershipType: RequiredMembershipTypes['membershipApplicationRequirements'][number],
): string => requiredMembershipType.membershipTypeKey;

const ticketTypeMapper = (ticketType: TicketType): TicketTypeDto => ({
  description: ticketType.description?.length ? ticketType.description : null,
  discountCodes: ticketType.discountCodes?.length
    ? ticketType.discountCodes.map(discountCodeMapper)
    : null,
  endDate: ticketType.endDate,
  isTransferable: ticketType.isTransferable,
  maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
  membershipTypeDiscounts: ticketType.membershipTypeDiscounts?.length
    ? ticketType.membershipTypeDiscounts.map(membershipTypeDiscountMapper)
    : null,
  name: ticketType.name,
  price: toScu(ticketType.price),
  requiredMembershipTypes: ticketType.requiredMembershipTypes
    ?.membershipApplicationRequirements.length
    ? {
        combinationType: ticketType.requiredMembershipTypes.combinationType,
        membershipTypeKeys:
          ticketType.requiredMembershipTypes.membershipApplicationRequirements.map(
            membershipTypeKeyMapper,
          ),
      }
    : null,
  startDate: ticketType.startDate,
  ticketCount: ticketType.ticketCount,
  ticketTypeKey: ticketType.ticketTypeKey,
});

const categoryMapper = (category: ActivityCategory): string =>
  category.activityCategoryKey;

export const updateActivityMapper = (
  updateActivityForm: UpdateActivityForm,
): UpdateActivityDto => {
  const { checkboxQuestions, freetextQuestions, multichoiceQuestions } =
    requestedInfoMapper(updateActivityForm.requestMoreInfo.requestedInfo);

  const showAttendance = updateActivityForm.tickets.ticketTypes?.length
    ? updateActivityForm.tickets.showAttendance
    : false;

  const updateActivityDto: UpdateActivityDto = {
    checkboxQuestions:
      checkboxQuestions.length !== 0 ? checkboxQuestions : null,
    cohosts: updateActivityForm.coHosting.cohosts?.map(cohostMapper) ?? null,
    contactDetails: {
      email: updateActivityForm.description.contactDetails.email,
      name: updateActivityForm.description.contactDetails.name,
      phone: updateActivityForm.description.contactDetails.phone?.length
        ? updateActivityForm.description.contactDetails.phone
        : null,
    },
    coverImage: {
      base64: updateActivityForm.media.coverImage!.base64,
      fileName: updateActivityForm.media.coverImage!.fileName,
      fileType: updateActivityForm.media.coverImage!.fileType,
    },
    description:
      updateActivityForm.description.tags?.length ||
      updateActivityForm.description.location.city
        ? injectMetadataInDescription(
            updateActivityForm.description.description,
            updateActivityForm.description.tags,
            updateActivityForm.description.location.city,
          )
        : updateActivityForm.description.description,
    endDate: updateActivityForm.description.endDate,
    freetextQuestions:
      freetextQuestions.length !== 0 ? freetextQuestions : null,
    links: updateActivityForm.description.links?.map(linkMapper) ?? null,
    location: {
      coordinates: updateActivityForm.description.location.coordinates,
      description: updateActivityForm.description.location.description
        ? updateActivityForm.description.location.description
        : null,
      label: updateActivityForm.description.location.label,
      link: updateActivityForm.description.location.link?.length
        ? updateActivityForm.description.location.link
        : null,
    },
    multichoiceQuestions:
      multichoiceQuestions.length !== 0 ? multichoiceQuestions : null,
    questionsActivated: updateActivityForm.addons.questionsActivated,
    segmentation: updateActivityForm.participants.segmentation,
    startDate: updateActivityForm.description.startDate,
    ticketTypes: !!updateActivityForm.tickets.ticketTypes?.length
      ? updateActivityForm.tickets.ticketTypes.map(ticketTypeMapper)
      : null,
    title: updateActivityForm.description.title,

    showAttendance,
    showAttendees: showAttendance,
  };

  if (updateActivityForm.description.categories.length) {
    updateActivityDto.categories =
      updateActivityForm.description.categories.map(categoryMapper);
  }

  return updateActivityDto;
};

export function activityRecordMapper(
  activityListItem: ActivityListItem,
): ActivityRecord {
  return {
    activityKey: activityListItem.activityKey,
    activityTitle: activityListItem.title,
  };
}
