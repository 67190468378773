import React from 'react';

export const ShareScanLinkSidebarContext = React.createContext({
  shareLinkSidebarIsOpen: false,
  closeSidebar: () => {},
  toggleSidebar: () => {},
});

export function ShareScanLinkSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [shareLinkSidebarIsOpen, setshareLinkSidebarIsOpen] =
    React.useState(false);

  const closeSidebar = () => {
    setshareLinkSidebarIsOpen(false);
  };

  const toggleSidebar = () => {
    setshareLinkSidebarIsOpen(
      (shareLinkSidebarIsOpen) => !shareLinkSidebarIsOpen,
    );
  };

  const value = {
    closeSidebar,
    shareLinkSidebarIsOpen,
    toggleSidebar,
  };

  return (
    <ShareScanLinkSidebarContext.Provider value={value} {...props}>
      {children}
    </ShareScanLinkSidebarContext.Provider>
  );
}
