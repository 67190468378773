import axios from 'axios';

import { Base64File, FileType } from '../components';
import { isAcceptedFileType } from './file';
import { getUID } from './uid';

export const removeBase64Meta = (str: string) =>
  str.substring(str.indexOf(',') + 1);

export const constructBase64 = (image?: Base64File | null) => {
  if (!image) return '';

  const mimetype = image.fileType.split('/')[1];

  return `data:image/${mimetype};base64,${image.base64}`;
};

export const getBlobFromUrl = async (url: string, signal?: AbortSignal) => {
  try {
    const res = await axios.get<Blob>(url, { signal, responseType: 'blob' });

    return res.data;
  } catch (err) {
    return null;
  }
};

export const getBase64FileFromBlob = async (
  blob: Blob | null,
): Promise<Base64File | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!blob) {
        throw new Error('Blob is null');
      }

      const reader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = () => {
        const base64 = reader.result;
        if (typeof base64 !== 'string') {
          return resolve(null);
        }

        const fileTypeRaw = base64.split(':')[1].split(';')[0];
        const fileType = isAcceptedFileType(fileTypeRaw)
          ? fileTypeRaw
          : FileType.ImagePng;

        const base64File = {
          base64: removeBase64Meta(base64),
          fileName: `${getUID().toLowerCase()}.${fileType.split('/')[1]}`,
          fileType,
          fileSize: blob.size,
        };

        return resolve(base64File);
      };

      reader.onerror = (err) => {
        reject(null);
      };
    } catch (err) {
      resolve(null);
    }
  });
};

export async function getFileFromUrl(url: string) {
  return getBase64FileFromBlob(await getBlobFromUrl(url));
}
