import {
  Box,
  Button,
  Icon,
  Image,
  Link,
  ResponsiveBox,
  SplitScreenContentContainer,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { assets } from '../../../assets';
import { NotOnboardedToolbar } from '../../../components';
import { OPEN_APP_URL } from '../../../constants';
import { AccountSelector, useSelector } from '../../../store';

function AccessDeniedContent() {
  return (
    <React.Fragment>
      <Icon
        name="question-mark-circle-solid"
        size={100}
        color="accessDeniedStudentIcon"
      />

      <Box flex flexDirection="column" gap={8}>
        <Text
          color="accessDeniedTitle"
          variant="titleMd"
          tx="label.auth.access-denied-student.title"
          textAlign="center"
        />

        <ResponsiveBox
          sm={
            <Text
              color="accessDeniedSubtitle"
              tx="label.auth.access-denied-student.subtitle"
              textAlign="center"
            />
          }
        >
          <Text
            color="accessDeniedSubtitle"
            tx="label.auth.access-denied-student.subtitle-alt"
            textAlign="center"
          />
        </ResponsiveBox>

        <ResponsiveBox
          sm={
            <Image
              width={250}
              height={250}
              mx="auto"
              src={assets.appQrCode}
              alt=""
            />
          }
        >
          <Box width="100%">
            <Link href={OPEN_APP_URL} plain>
              <Button
                variant="primary"
                tx="button.enter-the-app"
                mt={32}
                width="100%"
                large
              />
            </Link>
          </Box>
        </ResponsiveBox>
      </Box>
    </React.Fragment>
  );
}

export function AccessDeniedStudent() {
  const onlyHasStudentWorkspace = useSelector(
    AccountSelector.selectOnlyHasStudentWorkspace,
  );

  if (!onlyHasStudentWorkspace) {
    return <Navigate to="/activities" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <SplitScreenContentContainer>
        <AccessDeniedContent />

        <Box absolute bottom={40}>
          <Link
            tx="link.auth.back-to-orbi"
            href={OPEN_APP_URL}
            variant="secondary"
            small
          />
        </Box>
      </SplitScreenContentContainer>
    </React.Fragment>
  );
}
