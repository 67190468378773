export namespace TicketTypeErrors {
  export const customErrors = {
    endDate: 'number.min.activity',
    endDateEvent: 'number.min.event',
    startDate: 'number.min.ticket-type',
    ticketCount: 'number.old',
    ticketCountAboveMax: 'number.max',
    ticketCountZero: 'number.min',
    ticketMinPrice: 'number.min',
    ticketPriceWithDiscountBelowMin: 'number.min',
  };

  export const ticketTypeName = {
    'string.empty': 'errors.name.empty',
  };

  export const description = {
    'string.empty': 'errors.description.empty',
  };

  export const price = {
    'number.base': 'errors.price.empty',
    'number.min': 'errors.price.min',
    'number.unsafe': 'errors.price.max',
    'number.integer': 'errors.price.integer',
  };

  export const endDate = {
    'number.min.ticket-type': 'errors.end-date.ticket-type',
    'number.min.activity': 'errors.end-date.activity',
    'number.min.event': 'errors.end-date.event',
    'number.base': 'errors.end-date.min',
    'number.min': 'errors.end-date.min',
    'number.max': 'errors.end-date.activity',
  };

  export const startDate = {
    'number.min': 'errors.start-date.min',
    'number.max': 'errors.start-date.max',
    'number.base': 'errors.start-date.min',
  };

  export const ticketCount = {
    'number.base': 'errors.max-count.empty',
    'number.min': 'errors.max-count.empty',
    'number.max': 'errors.max-count.max',
    'number.integer': 'errors.max-count.integer',
  };

  export const updateTicketCount = {
    ...ticketCount,
    'number.old': 'errors.max-count.old',
  };

  export const maxTicketCountPerUser = {
    'number.base': 'errors.max-count-per-user.empty',
    'number.min': 'errors.max-count-per-user.empty',
    'number.max': 'errors.max-count-per-user.max-count-ref',
    'any.ref': 'errors.max-count-per-user.max-count-ref',
    'number.integer': 'errors.max-count-per-user.integer',
    'number.unsafe': 'errors.max-count-per-user.max',
  };

  export const discountCodes = {
    'array.min': 'errors.discount-codes.empty',
  };

  export const membershipTypeDiscounts = {
    'array.min': 'errors.membership-discounts.empty',
  };
}
