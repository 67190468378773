import React from 'react';

import { Logger } from '../services';

export function usePlaySound() {
  const [enableSoundFeedback, setEnableSoundFeedback] = React.useState(true);

  const toggleSoundFeedback = () => {
    setEnableSoundFeedback((prev) => !prev);
  };

  const playSound = React.useCallback(
    (sound: 'error' | 'failure' | 'success') => {
      try {
        if (!enableSoundFeedback) return;

        const audio = new Audio();

        switch (sound) {
          case 'error':
            audio.src = '/assets/error.mp3';
            break;

          case 'failure':
            audio.src = '/assets/failure.mp3';
            break;

          case 'success':
            audio.src = '/assets/success.mp3';
            break;
        }

        audio.play();
      } catch (err) {
        Logger.error('Error playing sound', { err, enableSoundFeedback });
      }
    },
    [enableSoundFeedback],
  );

  return { enableSoundFeedback, playSound, toggleSoundFeedback };
}
