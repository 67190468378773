import React from 'react';

import { Time } from '../constants';

export function useDebounce(options?: { timeout?: number }) {
  const timeoutRef = React.useRef<number | null>(null);

  return (debounce: () => void) => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      debounce();
    }, options?.timeout ?? 500 * Time.Millisecond);
  };
}
