import {
  Box,
  Button,
  ControlledTextarea,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalSubtitle,
  ModalTitle,
  Switch,
} from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm, POST_MESSAGE_MAX_LENGTH } from '../../../models';
import { getOptionalLabelText } from '../../../utils';
import { UploadFiles, UploadedImages } from '../components';
import { CreatePostFormProps } from '../create-post-modal.types';

function NotificationsSelect() {
  const formContext = useFormContext<CreatePostForm>();

  const pushTo = formContext.watch('pushTo');

  const togglePushNotifications = () => {
    formContext.setValue('pushTo', pushTo === 'followers' ? null : 'followers');
  };

  return (
    <Box
      p={8}
      gap={16}
      flex
      flexDirection="column"
      backgroundColor="backgroundTeritary"
      r={8}
    >
      <Switch
        text={getOptionalLabelText(
          'label.posts.create-post-modal.send-push-notification',
        )}
        subtitleTx="label.posts.create-post-modal.department-followers"
        onClick={togglePushNotifications}
        checked={pushTo === 'followers'}
      />
    </Box>
  );
}

export function DepartmentPostForm(props: CreatePostFormProps) {
  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle tx="label.posts.create-post-modal.new-department-post" />
        <ModalSubtitle
          mt={16}
          tx="label.posts.create-post-modal.department-post-subtitle"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <ControlledTextarea
          name="message"
          labelTx="label.posts.create-post-modal.message"
          maxLength={POST_MESSAGE_MAX_LENGTH}
          autoFocus
        />

        <UploadedImages />

        <NotificationsSelect />
      </ModalBodyContainer>

      <ModalFooterContainer>
        <UploadFiles />

        <Box gap={16} flex>
          <Button
            tx="button.cancel"
            variant="tertiary"
            onClick={props.closeAndReset}
          />

          <Button
            tx="label.posts.create-post-modal.post"
            variant="primary"
            onClick={props.createPost}
            isLoading={props.isLoading}
          />
        </Box>
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
