import { useEffect, useRef } from 'react';

import { Box } from '../box';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function startConfetti(canvas: HTMLCanvasElement | null) {
  if (!canvas) return;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const confettiArray: {
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
    speed: number;
    angle: number;
  }[] = [];

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  function shootExplosiveConfetti(x: number, y: number) {
    for (let i = 0; i < 200; i++) {
      const width = randomInRange(10, 12);
      const height = randomInRange(6, 12);
      const color = getRandomColor();
      const speed = randomInRange(8, 15);
      const angle = randomInRange(1, 2 * Math.PI);

      const confetti = {
        x,
        y,
        width,
        height,
        color,
        speed,
        angle,
      };

      confettiArray.push(confetti);
    }
  }

  function drawExplosiveConfetti() {
    if (!ctx) return;

    for (let i = 0; i < confettiArray.length; i++) {
      const confetti = confettiArray[i];

      ctx.fillStyle = confetti.color;
      ctx.fillRect(confetti.x, confetti.y, confetti.width, confetti.height);

      confetti.x += Math.cos(confetti.angle) * confetti.speed;
      confetti.y += Math.sin(confetti.angle) * confetti.speed;

      confetti.speed -= 0.1;

      // Fade away effect
      const alpha = confetti.speed / 10; // Adjust the divisor for the fade speed
      ctx.globalAlpha = alpha;

      if (confetti.speed <= 0) {
        confettiArray.splice(i, 1);
        i--;
      }
    }

    ctx.globalAlpha = 1; // Reset globalAlpha for subsequent drawing
  }

  function animateExplosiveConfetti() {
    if (!ctx || !canvas) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawExplosiveConfetti();
    requestAnimationFrame(animateExplosiveConfetti);
  }

  // Trigger explosive confetti from bottom left and bottom right corners
  shootExplosiveConfetti(0, canvas.height);
  shootExplosiveConfetti(canvas.width, canvas.height);

  window.setTimeout(() => {
    shootExplosiveConfetti(0, canvas.height);
    shootExplosiveConfetti(canvas.width, canvas.height);
  }, 500);

  window.setTimeout(() => {
    shootExplosiveConfetti(0, canvas.height);
    shootExplosiveConfetti(canvas.width, canvas.height);
  }, 800);

  window.setTimeout(() => {
    shootExplosiveConfetti(0, canvas.height);
    shootExplosiveConfetti(canvas.width, canvas.height);
  }, 1000);

  window.setTimeout(() => {
    shootExplosiveConfetti(0, canvas.height);
    shootExplosiveConfetti(canvas.width, canvas.height);
  }, 1100);

  animateExplosiveConfetti();
}

export function Confetti() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    startConfetti(canvasRef.current);
  }, []);

  return (
    <Box
      onClick={() => {
        startConfetti(canvasRef.current);
      }}
      fixed
      inset
    >
      <canvas ref={canvasRef} />
    </Box>
  );
}
