import Joi from 'joi';

import { POST_COMMENT_MESSAGE_MAX_LENGTH } from './post-comment.constants';
import { PostCommentErrors } from './post-comment.errors';

export namespace CreatePostCommentSchema {
  export const message = Joi.string()
    .max(POST_COMMENT_MESSAGE_MAX_LENGTH)
    .required()
    .messages(PostCommentErrors.message);
}

export namespace EditPostCommentSchema {
  export const message = Joi.string()
    .max(POST_COMMENT_MESSAGE_MAX_LENGTH)
    .required()
    .messages(PostCommentErrors.message);
}
