import React from 'react';

import { Box } from '../box';
import { Button } from '../button';
import { IconButton, OnDarkIconButton } from '../icon-button';
import { Link } from '../link';
import { Text } from '../text';
import { Styled } from './banner.styled';
import {
  BannerProps,
  BannerVariant,
  GetBannerSettingsReturn,
} from './banner.types';

function getBannerSettings(variant: BannerVariant): GetBannerSettingsReturn {
  switch (variant) {
    case 'information':
      return {
        backgroundColor: 'bannerInformationBackground',
        bannerShadowBackground: 'bannerInformationShadowBackground',
        buttonIsDark: true,
        buttonVariant: 'tertiary',
        closeButtonIsDark: true,
        iconColor: 'bannerInformationIcon',
        labelColor: 'bannerInformationLabel',
        linkVariant: 'quaternary',
      };

    case 'error':
      return {
        backgroundColor: 'bannerErrorBackground',
        bannerShadowBackground: 'bannerErrorShadowBackground',
        buttonIsDark: true,
        buttonVariant: 'tertiary',
        closeButtonIsDark: true,
        iconColor: 'bannerErrorIcon',
        labelColor: 'bannerErrorLabel',
        linkVariant: 'quaternary',
      };

    case 'warning':
      return {
        backgroundColor: 'bannerWarningBackground',
        bannerShadowBackground: 'bannerWarningShadowBackground',
        buttonIsDark: false,
        buttonVariant: 'tertiary',
        closeButtonIsDark: false,
        iconColor: 'bannerWarningIcon',
        labelColor: 'bannerWarningLabel',
        linkVariant: 'tertiary',
      };

    case 'success':
      return {
        backgroundColor: 'bannerSuccessBackground',
        bannerShadowBackground: 'bannerSuccessShadowBackground',
        buttonIsDark: true,
        buttonVariant: 'tertiary',
        closeButtonIsDark: true,
        iconColor: 'bannerSuccessIcon',
        labelColor: 'bannerSuccessLabel',
        linkVariant: 'quaternary',
      };
  }
}

export function Banner(props: React.PropsWithChildren<BannerProps>) {
  const {
    buttonHref,
    buttonOnClick,
    buttonText,
    buttonTo,
    buttonTx,
    buttonTxArgs,
    linkHref,
    linkOnClick,
    linkTarget,
    linkText,
    linkTo,
    linkTx,
    linkTxArgs,
    message,
    messageTx,
    messageTxArgs,
    onClose,
    title,
    titleTx,
    titleTxArgs,
    variant,
    ...rest
  } = props;

  const bannerSettings = getBannerSettings(variant);

  return (
    <Styled.Banner
      backgroundColor={bannerSettings.backgroundColor}
      flex
      flexAlign="center"
      flexJustify="center"
      minHeight={56}
      relative
      {...rest}
    >
      <Styled.BannerShadow
        backgroundColor={bannerSettings.bannerShadowBackground}
        absolute
        right={0}
        width={50}
        height="100%"
      />

      <Styled.BannerInnerBox
        flex
        flexAlign="center"
        height="100%"
        gap={16}
        overflow="auto"
        pl={24}
        pr={80}
        py={4}
      >
        <Box flex flexAlign="center" gap={8}>
          <Text
            as="h6"
            color={bannerSettings.labelColor}
            m="0 auto"
            text={title}
            textAlign="center"
            tx={titleTx}
            txArgs={titleTxArgs}
            variant="bodyMdBold"
            whiteSpace="nowrap"
          />

          <Text
            color={bannerSettings.labelColor}
            text={message}
            textAlign="center"
            tx={messageTx}
            txArgs={messageTxArgs}
            variant="bodyMd"
            whiteSpace="nowrap"
          />
        </Box>

        {(linkTo || linkHref) && (
          <Box whiteSpace="nowrap">
            <Link
              onClick={linkOnClick}
              target={linkTarget}
              text={linkText}
              to={linkTo}
              tx={linkTx}
              txArgs={linkTxArgs}
              variant={bannerSettings.linkVariant}
              href={linkHref}
            />
          </Box>
        )}

        {(buttonOnClick || buttonHref || buttonTo) && (
          <Button
            href={buttonHref}
            isDark={bannerSettings.buttonIsDark}
            onClick={buttonOnClick}
            text={buttonText}
            to={buttonTo}
            tx={buttonTx}
            txArgs={buttonTxArgs}
            variant={bannerSettings.buttonVariant}
            whiteSpace="nowrap"
          />
        )}

        {onClose && (
          <Styled.BannerCloseIconBox
            absolute
            backgroundColor={bannerSettings.backgroundColor}
            flex
            flexAlign="center"
            flexJustify="center"
            height="100%"
            right={0}
            width={50}
          >
            {bannerSettings.closeButtonIsDark ? (
              <OnDarkIconButton onClick={onClose} icon="x-circle-outline" />
            ) : (
              <IconButton onClick={onClose} icon="x-circle-outline" />
            )}
          </Styled.BannerCloseIconBox>
        )}
      </Styled.BannerInnerBox>
    </Styled.Banner>
  );
}
