import { PayloadAction } from '@reduxjs/toolkit';

import { initialAuthState } from './auth.initial';
import { AuthState, AuthStatePayload } from './auth.types';

const reset = (state: AuthState) => {
  Object.assign(state, initialAuthState);
};

const setAuthState = (
  state: AuthState,
  action: PayloadAction<Partial<AuthStatePayload>>,
) => {
  state.authState.data = {
    ...state.authState.data,
    ...action.payload,
  };
};

const clearError = (state: AuthState) => {
  state.authState.error = null;
};

const setIsEmulating = (state: AuthState, action: PayloadAction<boolean>) => {
  state.authState.data.isEmulating = action.payload;
};

export const reducers = {
  reset,

  clearError,
  setAuthState,
  setIsEmulating,
};
