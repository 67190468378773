import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './referrals.build';
import { initialReferralsState } from './referrals.initial';
import { reducers } from './referrals.reducers';

const referralsSlice = createSlice({
  name: 'referrals',
  initialState: initialReferralsState,
  reducers,
  extraReducers,
});

export const referralsReducer = referralsSlice.reducer;
