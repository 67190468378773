import { ContentSidebar } from '@orbiapp/components';

import {
  PostSelector,
  postsActions,
  useDispatch,
  useSelector,
} from '../../store';
import { PostsSidebarContent } from '../posts-sidebar-content';

export function PostsSidebar() {
  const postKey = useSelector(PostSelector.selectPostKey);
  const isLoading = useSelector(PostSelector.selectIsLoading);

  const dispatch = useDispatch();

  const isOpen = !!postKey || isLoading;

  const closePostsSidebar = () => {
    dispatch(postsActions.resetPost());
  };

  return (
    <ContentSidebar
      backgroundColor="backgroundTeritary"
      isOpen={isOpen}
      onClose={closePostsSidebar}
      width={460}
    >
      <PostsSidebarContent />
    </ContentSidebar>
  );
}
