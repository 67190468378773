import { INITIAL_DATA_STATE } from '../store.constants';
import { locationsAdapter } from './locations.adapter';
import { LocationsState } from './locations.types';

export const initialLocationsState: LocationsState = {
  locations: {
    ...INITIAL_DATA_STATE,
    data: locationsAdapter.getInitialState(),
  },
};
