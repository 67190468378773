import Joi from 'joi';

import { MembershipTypeSchema } from './membership-type.schema';

export const GeneralInfoFormValidation = Joi.object({
  backgroundImage: MembershipTypeSchema.backgroundImage,
  description: MembershipTypeSchema.description,
  logo: MembershipTypeSchema.logo,
  name: MembershipTypeSchema.name,
  contactDetails: MembershipTypeSchema.contactDetails,
});

export const SettingsFormValidation = Joi.object({
  requiredMembershipTypes: MembershipTypeSchema.requiredMembershipTypes,
  type: MembershipTypeSchema.type,
});

export const QuestionsFormValidation = Joi.object({
  enabled: MembershipTypeSchema.enabled,
  createMembershipQuestions: MembershipTypeSchema.createMembershipQuestions,
});

export const PeriodsFormValidation = Joi.object({
  periods: MembershipTypeSchema.periods,
});

export const CreateMembershipInvitationsFormValidation = Joi.object({
  emails: MembershipTypeSchema.emails,
  membershipPeriodKeys: MembershipTypeSchema.membershipPeriodKeys,
});

export const CreateStandardMembershipInvitationsFormValidation = Joi.object({
  emails: MembershipTypeSchema.emails,
  membershipPeriodKeys: MembershipTypeSchema.membershipPeriodKeys.allow(
    null,
    Joi.any(),
  ),
});

export const CreateMembershipTypeFormValidation = Joi.object({
  backgroundImage: MembershipTypeSchema.backgroundImage,
  contactDetails: MembershipTypeSchema.contactDetails,
  createMembershipQuestions: MembershipTypeSchema.createMembershipQuestions,
  description: MembershipTypeSchema.description,
  enabled: MembershipTypeSchema.enabled,
  logo: MembershipTypeSchema.logo,
  name: MembershipTypeSchema.name,
  periods: MembershipTypeSchema.periods,
  requiredMembershipTypes: MembershipTypeSchema.requiredMembershipTypes,
  type: MembershipTypeSchema.type,
});
