import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATION_STATE,
} from '../store.constants';
import {
  activityQuestionsAdapter,
  questionsAdapter,
} from './questions.adapter';
import { QuestionState } from './questions.types';

export const initialQuestionsState: QuestionState = {
  activityQuestions: {
    ...INITIAL_DATA_STATE,
    data: activityQuestionsAdapter.getInitialState(),
  },
  hideQuestion: INITIAL_DATA_STATE,
  questions: {
    ...INITIAL_DATA_STATE,
    data: questionsAdapter.getInitialState(),
    hideHandled: false,
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'createdAt' },
  },
  selectedActivityQuestion: null,
  selectedQuestion: null,
  unhandledQuestions: { ...INITIAL_DATA_STATE, data: 0 },
  updateQuestion: INITIAL_DATA_STATE,
};
