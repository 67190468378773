import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Text,
  Toolbar,
  ToolbarContentContainer,
} from '@orbiapp/components';
import React from 'react';

import { ActivityDataSelector, useSelector } from '../../../../store';
import { DeviceCard } from '../shared/device-card';
import { ScanQrCodesContainer } from '../shared/scan-qr-codes-container';
import {
  ShareScanLinkSidebar,
  ShareScanLinkSidebarContext,
} from '../shared/share-scan-link-sidebar';

function PickDevice() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const { shareLinkSidebarIsOpen, toggleSidebar } = React.useContext(
    ShareScanLinkSidebarContext,
  );

  return (
    <React.Fragment>
      <Box flexJustify="between" flexWrap="wrap" flex gap={16}>
        <Box flex flexDirection="column" gap={4}>
          <Box flex gap={8} relative>
            <IconButton
              mt={4}
              to={`/activities/${activityKey}`}
              icon="chevron-left"
            />

            <Text
              color="pageTitle"
              as="h1"
              tx="title.activities.consume-tickets"
              variant="titleMd"
            />
          </Box>

          <Text
            variant="bodyMd"
            ml={32}
            maxWidth="84ch"
            tx="label.scan-qr-codes.pick-device.header-text"
          />
        </Box>

        <Button
          variant="secondary"
          tx="label.scan-qr-codes.share-scan-link"
          icon={shareLinkSidebarIsOpen ? 'x-mark' : 'link'}
          onClick={toggleSidebar}
        />
      </Box>

      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        flexGrow={1}
        flexJustify="center"
        py={56}
        px={8}
        backgroundColor="scanQrCodesContentAreaBackground"
      >
        <Box flex flexDirection="column" flexAlign="center" gap={8}>
          <Text
            variant="titleMd"
            textAlign="center"
            tx="label.scan-qr-codes.pick-device.title"
          />
          <Text
            variant="bodyMd"
            textAlign="center"
            tx="label.scan-qr-codes.pick-device.subtitle"
          />
        </Box>

        <Box mt={32} flex gap={32} flexWrap="wrap" flexJustify="center">
          <DeviceCard
            buttonTx="label.scan-qr-codes.pick-device.web-camera.button-text"
            icon="video-camera-outline"
            subtitleTx="label.scan-qr-codes.pick-device.web-camera.subtitle"
            titleTx="label.scan-qr-codes.pick-device.web-camera.title"
            to={`/activities/${activityKey}/consume-tickets/camera`}
          />

          <DeviceCard
            buttonTx="label.scan-qr-codes.pick-device.qr-scanner.button-text"
            icon="qr-code"
            subtitleTx="label.scan-qr-codes.pick-device.qr-scanner.subtitle"
            titleTx="label.scan-qr-codes.pick-device.qr-scanner.title"
            to={`/activities/${activityKey}/consume-tickets/device`}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function PickScanDeviceToolbar() {
  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!activityTitle) {
    return null;
  }

  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer
        flexAlign="center"
        flexWrap="nowrap"
        flexJustify="between"
      >
        <BreadCrumbs flexGrow={0.4}>
          <BreadCrumb
            to="/activities"
            tx="label.breadcrumbs.activities.activities"
          />
          <BreadCrumb
            maxWidth="28ch"
            overflow="hidden"
            text={activityTitle}
            textOverflow="ellipsis"
            to={`/activities/${activityKey}/description`}
            whiteSpace="nowrap"
          />
          <BreadCrumb
            tx="label.breadcrumbs.activities.pick-scan-device"
            isLast
          />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function PickScanDevice() {
  return (
    <ScanQrCodesContainer>
      <PickScanDeviceToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <PickDevice />
          </InnerContentContainer>
        </ContentContainer>

        <ShareScanLinkSidebar />
      </InnerPageContainer>
    </ScanQrCodesContainer>
  );
}
