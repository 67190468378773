import { TextVariant } from '@orbiapp/theme';

import { translate } from '../../i18n';
import { Avatar, AvatarProps } from '../avatar';
import { Box, BoxProps } from '../box';
import { StatsChip, StatsChipProps } from '../chip-stats';
import { PlaceholderBar } from '../placeholder-bar';
import { TextProps } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './card.styled';

export interface CardHeaderProps {
  avatarSrc?: AvatarProps['src'];
  avatarFallbackLetter?: AvatarProps['fallbackLetter'];
  avatarVariant?: AvatarProps['variant'];

  actionElement?: JSX.Element;
  description?: TextProps['text'];
  descriptionTx?: TextProps['tx'];
  descriptionTxArgs?: TextProps['txArgs'];

  title?: string;
  titleTx?: TextProps['tx'];
  titleTxArgs?: TextProps['txArgs'];

  statusVariantText?: TextProps['text'];
  statusVariantTx?: TextProps['tx'];
  statusVariantTxArgs?: TextProps['txArgs'];
  statusVariant?: StatsChipProps['variant'];
  statusVariantTextVariant?: TextVariant;

  subtitle?: JSX.Element;
  subtitleTx?: TextProps['tx'];
  subtitleText?: TextProps['text'];
  subtitleTxArgs?: TextProps['txArgs'];

  unit?: TextProps['text'];
  unitTx?: TextProps['tx'];
  unitPosition?: 'left' | 'right';

  isLoading?: boolean;
}

export function CardHeaderSubtitle(props: TextProps) {
  return (
    <Styled.CardHeaderSubtitle
      color="cardHeaderSubtitle"
      whiteSpace="nowrap"
      {...props}
    />
  );
}

export function CardHeaderUnit(props: TextProps) {
  return (
    <Styled.CardHeaderUnit color="cardHeaderUnit" variant="bodySm" {...props} />
  );
}

export function CardHeader(props: CardHeaderProps & BoxProps) {
  const {
    actionElement,

    avatarFallbackLetter,
    avatarSrc,
    avatarVariant,

    description,
    descriptionTx,
    descriptionTxArgs,

    title,
    titleTx,
    titleTxArgs,

    statusVariant,
    statusVariantText,
    statusVariantTextVariant,
    statusVariantTx,
    statusVariantTxArgs,

    subtitle,
    subtitleText,
    subtitleTx,
    subtitleTxArgs,

    unit,
    unitPosition,
    unitTx,

    isLoading,

    ...rest
  } = props;

  const translatedTitle = titleTx
    ? translate(titleTx, titleTxArgs ?? {})
    : title;

  const cardHeaderTitle = (
    <Styled.CardHeaderTitle
      maxWidth="20ch"
      text={translatedTitle}
      color="cardHeaderTitle"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    />
  );

  return (
    <Box relative flex flexDirection="column" gap={4} {...rest}>
      <Box flexAlign="center" flex flexJustify="between">
        {isLoading && (
          <Box
            absolute
            backgroundColor="cardBackground"
            flex
            flexDirection="column"
            gap={8}
            inset
            zIndex={2}
          >
            <PlaceholderBar height={20} width={130} />
            <PlaceholderBar width={210} height={20} />
          </Box>
        )}

        <Box flex gap={8}>
          {(avatarSrc || avatarFallbackLetter) && (
            <Avatar
              fallbackLetter={avatarFallbackLetter}
              height={32}
              src={avatarSrc}
              variant={avatarVariant}
              width={32}
            />
          )}

          {translatedTitle && translatedTitle.length > 20 ? (
            <Tooltip placement="bottom" title={translatedTitle}>
              {cardHeaderTitle}
            </Tooltip>
          ) : (
            cardHeaderTitle
          )}
        </Box>

        {actionElement}
      </Box>

      <Box gap={16} flexAlign="center" flex>
        <Box
          flexAlign="end"
          flexDirection={unitPosition === 'left' ? 'row' : 'row-reverse'}
          gap={4}
          flex
        >
          {(unit || unitTx) && <CardHeaderUnit text={unit} tx={unitTx} />}

          {subtitle || (
            <CardHeaderSubtitle
              color="cardHeaderSubtitle"
              text={subtitleText}
              tx={subtitleTx}
              txArgs={subtitleTxArgs}
              whiteSpace="nowrap"
            />
          )}
        </Box>

        {statusVariant && (statusVariantText || statusVariantTx) && (
          <StatsChip
            text={statusVariantText}
            textVariant={statusVariantTextVariant}
            tx={statusVariantTx}
            txArgs={statusVariantTxArgs}
            variant={statusVariant}
          />
        )}
      </Box>

      {(description || descriptionTx) && (
        <Styled.CardHeaderDescription
          color="cardHeaderDescription"
          text={description}
          tx={descriptionTx}
          txArgs={descriptionTxArgs}
        />
      )}
    </Box>
  );
}

export function Card(props: BoxProps) {
  const { children, onClick, ...rest } = props;

  return (
    <Styled.Card
      backgroundColor="cardBackground"
      cursor={onClick ? 'pointer' : 'auto'}
      flex
      flexDirection="column"
      gap={16}
      onClick={onClick}
      p={16}
      r={8}
      {...rest}
    >
      {children}
    </Styled.Card>
  );
}
