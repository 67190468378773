import {
  Box,
  ContentSidebarContentContainer,
  MenuItemProps,
  PostCard,
  Spinner,
  getAvatarVariantFromString,
  translate,
  useInfinityScroll,
} from '@orbiapp/components';
import React from 'react';

import { useReactions } from '../../helpers';
import { GetPostCommentsPageParams } from '../../models';
import {
  ActivityDataSelector,
  DepartmentSelector,
  PostCommentsSelector,
  PostSelector,
  getPostCommentsThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { getActorAvatarSrc } from '../../utils';
import { DeletePostContext } from '../delete-post-confirm';
import { EditPostContext } from '../edit-post-modal';
import { PostCommentsSection } from '../post-comments-section';
import { PostSidebarHeader } from '../post-sidebar-header';
import { ReactionsModalContext } from '../reactions-modal';

export function usePostCommentsScroll() {
  const postKey = useSelector(PostSelector.selectPostKey);
  const postCommentCount = useSelector(PostSelector.selectCommentCount);
  const pageSize = useSelector(PostCommentsSelector.selectPageSize);
  const orderBy = useSelector(PostCommentsSelector.selectOrderBy);
  const startCursor = useSelector(PostCommentsSelector.selectStartCursor);
  const sortOrder = useSelector(PostCommentsSelector.selectSortOrder);
  const ids = useSelector(PostCommentsSelector.selectIds);
  const status = useSelector(PostCommentsSelector.selectStatus);

  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const dispatch = useDispatch();

  const getComments = React.useCallback(
    (params?: Partial<GetPostCommentsPageParams>) => {
      if (!postKey) return;

      dispatch(
        getPostCommentsThunk({
          orderBy,
          sortOrder,
          last: pageSize,
          activityKey: activityKey ?? undefined,
          postKey,
          ...params,
        }),
      );
    },
    [activityKey, dispatch, orderBy, pageSize, postKey, sortOrder],
  );

  const handleListScroll = useInfinityScroll(() => {
    if (status === 'pending' || !startCursor || ids.length === postCommentCount)
      return;

    getComments({ before: startCursor });
  });

  React.useEffect(getComments, [getComments]);

  return handleListScroll;
}

function usePostCardMenuItems() {
  const postKey = useSelector(PostSelector.selectPostKey);
  const isDeleted = useSelector(PostSelector.selectIsDeleted);
  const actorKey = useSelector(PostSelector.selectActorKey);
  const message = useSelector(PostSelector.selectMessage);
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const { openModal } = React.useContext(EditPostContext);
  const { openConfirm } = React.useContext(DeletePostContext);

  const editPost = React.useCallback(() => {
    if (!postKey) return;

    openModal({ postKey, message });
  }, [openModal, postKey, message]);

  const menuItems = React.useMemo(() => {
    let menuItemProps: MenuItemProps[] = [];

    const openDeletePostConfirm = () => {
      if (!postKey) return;

      openConfirm(postKey);
    };

    if (actorKey === departmentKey && !isDeleted) {
      menuItemProps.push({
        gap: 8,
        tx: 'label.posts.card.edit-post',
        onClick: editPost,
        iconColor: 'textSecondary',
        icon: 'pencil-square-outline',
      });
    }

    if (!isDeleted) {
      menuItemProps.push({
        tx: 'label.posts.card.delete-post',
        gap: 8,
        icon: 'trash-outline',
        iconColor: 'errorPrimary',
        color: 'errorPrimary',
        onClick: openDeletePostConfirm,
      });
    }

    return menuItemProps;
  }, [departmentKey, editPost, isDeleted, actorKey, postKey, openConfirm]);

  return menuItems;
}

function PostCardWrapper() {
  const reactionsModalContext = React.useContext(ReactionsModalContext);
  const post = useSelector(PostSelector.selectData);

  const menuItems = usePostCardMenuItems();

  const changeReaction = useReactions({
    key: post?.postKey,
    type: 'post',
  });

  if (!post) {
    return null;
  }

  const openReactionsModal = () => {
    reactionsModalContext.openModal({
      reactionType: 'all',
      reactions: post.reactions,
      targetKey: post.postKey,
    });
  };

  return (
    <PostCard
      images={post.files?.map((file) => file.image)}
      isDeleted={post.isDeleted}
      menuItems={menuItems}
      avatarSrc={getActorAvatarSrc(post.actor)}
      avatarVariant={getAvatarVariantFromString(post.actor.actorKey)}
      avatarFallbackLetter={post.actor.actorName.charAt(0)}
      message={
        post.isDeleted
          ? translate('label.posts.card.deleted-content')
          : post.message
      }
      publishedAt={post.createdAt}
      title={post.actor.actorName}
      reactions={post.reactions}
      selectedReaction={post.reactions.self}
      onSelectedReactionChange={changeReaction}
      onReactionsClick={openReactionsModal}
    />
  );
}

export function PostsSidebarContent() {
  const isLoading = useSelector(PostSelector.selectIsLoading);

  const handleListScroll = usePostCommentsScroll();

  if (isLoading) {
    return (
      <ContentSidebarContentContainer>
        <Box flex flexJustify="center">
          <Spinner />
        </Box>
      </ContentSidebarContentContainer>
    );
  }

  return (
    <ContentSidebarContentContainer
      onScroll={handleListScroll}
      flex
      flexDirection="column"
      gap={16}
    >
      <PostSidebarHeader />

      <PostCardWrapper />

      <PostCommentsSection />
    </ContentSidebarContentContainer>
  );
}
