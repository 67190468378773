import { Color } from '@orbiapp/theme';
import { css } from 'styled-components';

import { Flex, flexStyles } from './flex';
import { Gap, gapStyles } from './gap';
import { Position, positionStyles } from './position.styles';
import { Radius, Shape, radiusStyles, shapeStyles } from './shape';
import { isUndefined } from './utils';

export interface BaseComponentProps extends Flex, Shape, Radius, Gap, Position {
  backgroundColor?: Color;
  cursor?: string;
  height?: number | string;
  hidden?: boolean;
  maxHeight?: number | string;
  maxWidth?: number | string;
  minHeight?: number | string;
  minWidth?: number | string;
  overflow?: string;
  overflowWrap?: string;
  textAlign?: 'left' | 'center' | 'right';
  textOverflow?: 'clip' | 'ellipsis';
  pointerEvents?: 'none' | 'auto';
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
    | 'break-spaces';
  width?: number | string;
  wordBreak?: 'break-all' | 'break-word' | 'keep-all';
}

const pxOrString = (value: number | string) =>
  typeof value === 'string' ? value : `${value}px`;

export const baseComponentStyles = css<BaseComponentProps>`
  ${(props) => {
    const {
      backgroundColor,
      cursor,
      height,
      hidden,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      overflow,
      overflowWrap,
      textAlign,
      textOverflow,
      theme,
      whiteSpace,
      width,
      wordBreak,
      pointerEvents,
    } = props;

    return css`
      ${gapStyles};
      ${flexStyles};
      ${shapeStyles};
      ${radiusStyles};
      ${positionStyles};

      ${overflow &&
      css`
        overflow: ${overflow} !important;
      `}

      ${cursor &&
      css`
        cursor: ${cursor} !important;
      `}

      ${pointerEvents &&
      css`
        pointer-events: ${pointerEvents} !important;
      `}

      ${hidden &&
      css`
        display: none !important;
      `}

      ${!isUndefined(minWidth) &&
      css`
        min-width: ${pxOrString(minWidth)} !important;
      `}

      ${!isUndefined(minHeight) &&
      css`
        min-height: ${pxOrString(minHeight)} !important;
      `}

      ${!isUndefined(maxWidth) &&
      css`
        max-width: ${pxOrString(maxWidth)} !important;
      `}

      ${!isUndefined(maxHeight) &&
      css`
        max-height: ${pxOrString(maxHeight)} !important;
      `}

      ${!isUndefined(width) &&
      css`
        width: ${pxOrString(width)} !important;
      `}

      ${!isUndefined(height) &&
      css`
        height: ${pxOrString(height)} !important;
      `}

      ${!isUndefined(whiteSpace) &&
      css`
        white-space: ${whiteSpace} !important;
      `}

      ${!isUndefined(textAlign) &&
      css`
        text-align: ${textAlign} !important;
      `}

      ${!isUndefined(textOverflow) &&
      css`
        text-overflow: ${textOverflow} !important;
      `}

      ${!isUndefined(backgroundColor) &&
      css`
        background-color: ${theme.colors[backgroundColor]} !important;
      `}

      ${!isUndefined(overflowWrap) &&
      css`
        overflow-wrap: ${overflowWrap} !important;
      `}

      ${!isUndefined(wordBreak) &&
      css`
        word-break: ${wordBreak} !important;
      `}
    `;
  }};
`;
