import styled, { css } from 'styled-components';

import { Box } from '../box';
import { GetStatsChipColorsReturn } from './chip-stats.types';

export const StatsChip = styled(Box)<GetStatsChipColorsReturn>`
  ${(props) => {
    const { theme, backgroundColor, borderColor, labelColor } = props;

    return css`
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;

      background-color: ${theme.colors[backgroundColor]};
      border: 1px solid ${theme.colors[borderColor]};
      color: ${theme.colors[labelColor]};
    `;
  }}
`;

export const Styled = { StatsChip };
