import { JobListItem } from '../../../../../models';
import { JobListItemDto } from './jobs.dto';

export function jobListItemMapper(jobListItemDto: JobListItemDto): JobListItem {
  return {
    companyName: jobListItemDto.companyName,
    endDate: jobListItemDto.endDate,
    isHidden: jobListItemDto.isHidden,
    jobKey: jobListItemDto.jobKey,
    logo: jobListItemDto.logo,
    startDate: jobListItemDto.startDate,
    stats: jobListItemDto.stats,
    title: jobListItemDto.title,
  };
}
