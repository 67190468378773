import styled, { css } from 'styled-components';

const RangeInput = styled.input`
  ${(props) => {
    const { theme } = props;

    return css`
      -webkit-appearance: none;
      flex-grow: 1;
      background-color: transparent;

      &::-webkit-slider-runnable-track {
        background-color: ${theme.colors.rangeTrackBackground};
        border-radius: 300px;
        cursor: pointer;
        height: 8px;
        width: 100%;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: ${theme.colors.rangeHandleBackground};
        border-radius: 50%;
        border: 1px solid ${theme.colors.rangeHandleBorder};
        cursor: pointer;
        height: 16px;
        margin-top: -4px;
        width: 16px;
      }
    `;
  }}
`;

export const Styled = { RangeInput };
