import styled, { css } from 'styled-components';

import { Box } from '../box';

const BlurredCard = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      backdrop-filter: blur(50px);
      border: 1px solid ${theme.colors['blurredCardBorder']};
    `;
  }}
`;

export const Styled = { BlurredCard };
