import { createEntityAdapter } from '@reduxjs/toolkit';

import { Question } from '../../models';

export const activityQuestionsAdapter = createEntityAdapter<Question, string>({
  selectId: (question) => question.questionKey,
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});

export const questionsAdapter = createEntityAdapter<Question, string>({
  selectId: (question) => question.questionKey,
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});
