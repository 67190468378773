import React from 'react';

import { Styled } from './table-header.styled';
import {
  TableHeaderContextType,
  TableHeaderProps,
  TableHeaderProviderProps,
} from './table-header.types';

export const TableHeaderContext = React.createContext<
  TableHeaderContextType<any>
>({
  onSort: () => {},
  sortableColumns: [],
  sortOrder: 'asc',
  orderBy: '',
});

function TableHeaderProvider<T extends string>(
  props: TableHeaderProviderProps<T>,
) {
  const { children, ...rest } = props;

  return (
    <TableHeaderContext.Provider value={rest}>
      {children}
    </TableHeaderContext.Provider>
  );
}

export function TableHeader<T extends string>(props: TableHeaderProps<T>) {
  const { children, onSort, sortableColumns, sortOrder, orderBy, ...rest } =
    props;

  return (
    <Styled.TableHeader {...rest}>
      <TableHeaderProvider
        onSort={onSort}
        orderBy={orderBy}
        sortableColumns={sortableColumns}
        sortOrder={sortOrder}
      >
        {children}
      </TableHeaderProvider>
    </Styled.TableHeader>
  );
}
