export namespace OptionsErrors {
  export const label = {
    'string.empty': 'errors.label.empty',
    'string.max': 'errors.label.max',
  };

  export const options = {
    'array.min': 'errors.options.empty',
    'array.includes': 'errors.multichoice.missing-free-option',
  };
}

export namespace RequestedInfoErrors {
  export const customErrors = {
    priceIncrementMinPrice: 'number.min',
    missingFreeOption: 'array.includes',
  };

  export const priceIncrement = {
    'number.base': 'errors.price.empty',
    'number.min': 'errors.price.min',
    'number.unsafe': 'errors.price.max',
    'number.integer': 'errors.price.integer',
  };

  export const question = {
    'string.empty': 'errors.question.empty',
  };

  export const ticketTypeKeys = {
    'array.includesRequiredUnknowns': 'errors.request-more-info.empty',
  };
}
