import { Box, BoxProps } from '../box';
import { Icon, IconName } from '../icon';
import { Text, TextProps } from '../text';
import { Styled } from './blurred-card.styled';

export interface BlurredCardProps extends Omit<BoxProps, 'title'> {
  titleTx?: TextProps['tx'];
  title?: TextProps['text'];

  subtitleTx?: TextProps['tx'];
  subtitle?: TextProps['text'];

  icon?: IconName;
}

export function BlurredCard(props: BlurredCardProps) {
  const { titleTx, title, subtitleTx, subtitle, icon, ...rest } = props;

  return (
    <Styled.BlurredCard
      backgroundColor="blurredCardBackground"
      flex
      flexDirection="column"
      gap={8}
      p={16}
      r={4}
      width="fit-content"
      {...rest}
    >
      {icon && <Icon name={icon} size={24} color="blurredCardIcon" />}

      <Box flex flexDirection="column" gap={4}>
        <Text
          variant="bodySm"
          color="blurredCardTitle"
          tx={titleTx}
          text={title}
        />

        <Text
          variant="bodyMdBold"
          color="blurredCardSubtitle"
          tx={subtitleTx}
          text={subtitle}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        />
      </Box>
    </Styled.BlurredCard>
  );
}
