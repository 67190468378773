import {
  Box,
  Button,
  Confetti,
  Icon,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSide,
  Text,
  getAvatarVariantFromString,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { NotOnboardedToolbar } from '../../../components';
import { DepartmentInvitationSelector, useSelector } from '../../../store';

export function OnBoarded() {
  const departmentInvitation = useSelector(
    DepartmentInvitationSelector.selectData,
  );

  if (!departmentInvitation) {
    return <Navigate to="/" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <Confetti />

      <SplitScreenContainer>
        <SplitScreenLeftSide
          avatarSrc={
            departmentInvitation.departmentDetails.logo?.thumbnail64.url
          }
          avatarFallbackLetter={departmentInvitation.departmentDetails.name.charAt(
            0,
          )}
          avatarVariant={getAvatarVariantFromString(
            departmentInvitation.departmentDetails.departmentKey,
          )}
          title={departmentInvitation.departmentDetails.name}
          blurredCards={[
            {
              titleTx: 'label.auth.sign-up.followers',
              subtitle: departmentInvitation.departmentDetails.followerCount,
              icon: 'user-group-outline',
            },
            {
              titleTx: 'label.auth.sign-up.university',
              subtitle: departmentInvitation.departmentDetails.schoolName,
              icon: 'building-library-outline',
            },
            {
              titleTx: 'label.auth.sign-up.events-hosted',
              subtitle: departmentInvitation.departmentDetails.activityCount,
              icon: 'ticket-outline',
            },
          ]}
        />

        <SplitScreenContentContainer>
          <Icon name="check-circle-solid" size={100} color="onboardedIcon" />

          {departmentInvitation.userExists ? (
            <Text
              variant="titleMd"
              tx="label.auth.onboarded.added-to-workspace"
              color="signUpRightSideTitle"
              textAlign="center"
              txArgs={{
                departmentName: departmentInvitation.departmentDetails.name,
              }}
            />
          ) : (
            <Text
              variant="titleMd"
              tx="label.auth.onboarded.title"
              color="signUpRightSideTitle"
              textAlign="center"
            />
          )}

          <Box width="100%">
            <Button
              variant="primary"
              tx="button.enter-dashboard"
              to="/activities"
              width="100%"
              large
            />
          </Box>
        </SplitScreenContentContainer>
      </SplitScreenContainer>
    </React.Fragment>
  );
}
