import { fromScu } from '@orbiapp/components';

import { MembershipPurchaseListItem } from '../../../../../../models';
import { MembershipPurchaseDto } from './membership-purchases.dto';

export const membershipPurchaseMapper = (
  membershipPurchaseDto: MembershipPurchaseDto,
): MembershipPurchaseListItem => {
  return {
    fullName: membershipPurchaseDto.fullName,
    membershipPeriodName: membershipPurchaseDto.membershipPeriodName,
    membershipPurchaseKey: membershipPurchaseDto.membershipPurchaseKey,
    price: fromScu(membershipPurchaseDto.price),
    purchasedAt: membershipPurchaseDto.purchasedAt,
    refundedAt: membershipPurchaseDto.refundedAt,
    userKey: membershipPurchaseDto.userKey,
    profilePicture: membershipPurchaseDto.profilePicture,
  };
};
