import { t } from 'i18next';

export function translate(
  txString: TxString,
  args?: Record<string, string | number>,
): string {
  if (args) {
    return t(txString, args);
  } else {
    return t(txString);
  }
}
