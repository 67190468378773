import {
  Box,
  Confirm,
  ConfirmState,
  INITIAL_CONFIRM_STATE,
  Link,
  MenuItemProps,
  PostCommentCard,
  Spinner,
  Text,
  getAvatarVariantFromString,
  translate,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import { useReactions } from '../../helpers';
import {
  DeletePostCommentSelector,
  DepartmentSelector,
  PostCommentsSelector,
  PostSelector,
  deletePostCommentThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { getActorAvatarSrc } from '../../utils';
import {
  CreatePostCommentContext,
  CreatePostCommentProvider,
} from '../create-post-comment-modal';
import { EditPostCommentContext } from '../edit-post-comment-modal';
import { ReactionsModalContext } from '../reactions-modal';

const PostCommentSectionContext = React.createContext<ConfirmState<string>>(
  INITIAL_CONFIRM_STATE,
);

function PostCommentsSectionProvider(props: React.PropsWithChildren) {
  const confirm = useConfirm();

  return (
    <PostCommentSectionContext.Provider value={confirm}>
      {props.children}
    </PostCommentSectionContext.Provider>
  );
}

function DeleteCommentConfirm() {
  const postKey = useSelector(PostSelector.selectPostKey);
  const deleteCommentStatus = useSelector(
    DeletePostCommentSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const confirm = React.useContext(PostCommentSectionContext);

  const deleteComment = async () => {
    if (!confirm.confirmValue || !postKey) return;

    await dispatch(
      deletePostCommentThunk({
        postCommentKey: confirm.confirmValue,
        postKey,
      }),
    );

    confirm.closeConfirm();
  };

  return (
    <Confirm
      isOpen={confirm.isOpen}
      isLoading={deleteCommentStatus === 'pending'}
      onConfirm={deleteComment}
      onCancel={confirm.closeConfirm}
      titleTx="prompt.delete-comment.title"
      messageTx="prompt.delete-comment.message"
      confirmTx="prompt.delete-comment.confirm"
      cancelTx="button.cancel"
    />
  );
}

function OpenCreatePostCommentModalButton() {
  const postKey = useSelector(PostSelector.selectPostKey);

  const { openModal } = React.useContext(CreatePostCommentContext);

  const handleClick = () => {
    openModal(postKey);
  };

  return (
    <Link
      onClick={handleClick}
      tx="label.posts.comments.add-comment"
      variant="secondary"
    />
  );
}

interface PostCommentCardWrapperProps {
  postCommentKey: string;
}

function PostCommentCardWrapper(props: PostCommentCardWrapperProps) {
  const { postCommentKey } = props;

  const postKey = useSelector(PostSelector.selectPostKey);
  const postComment = useSelector((state) => {
    return PostCommentsSelector.selectById(state, postCommentKey);
  });
  const postActorKey = useSelector(PostSelector.selectActorKey);
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const deleteCommentConfirm = React.useContext(PostCommentSectionContext);
  const editCommentModal = React.useContext(EditPostCommentContext);
  const reactionsModalContext = React.useContext(ReactionsModalContext);

  const openDeleteCommentConfirm = () => {
    deleteCommentConfirm.openConfirm(postComment.postCommentKey);
  };

  const openConfirm = () => {
    if (!postKey) return;

    editCommentModal.openModal({
      message: postComment.message,
      postCommentKey: postComment.postCommentKey,
      postKey,
    });
  };

  const changeReaction = useReactions({
    type: 'post_comment',
    key: postComment.postCommentKey,
  });

  const openReactionsModal = () => {
    reactionsModalContext.openModal({
      reactionType: 'all',
      reactions: postComment.reactions,
      targetKey: postComment.postCommentKey,
    });
  };

  const menuItems: MenuItemProps[] = [];
  if (!postComment.isDeleted) {
    menuItems.push({
      tx: 'label.posts.comment-card.delete-comment',
      gap: 8,
      icon: 'trash-outline',
      iconColor: 'errorPrimary',
      color: 'errorPrimary',
      onClick: openDeleteCommentConfirm,
    });
  }

  if (!postComment.isDeleted && postComment.actor.actorKey === departmentKey) {
    menuItems.push({
      tx: 'label.posts.comment-card.edit-comment',
      gap: 8,
      icon: 'pencil-square-outline',
      onClick: openConfirm,
    });
  }

  return (
    <PostCommentCard
      authorTx={
        postComment.actor.actorKey === postActorKey
          ? 'label.posts.comment-card.author'
          : undefined
      }
      menuItems={menuItems}
      isDeleted={postComment.isDeleted}
      avatarSrc={getActorAvatarSrc(postComment.actor)}
      avatarVariant={getAvatarVariantFromString(postComment.actor.actorKey)}
      avatarFallbackLetter={postComment.actor.actorName.charAt(0)}
      message={
        postComment.isDeleted
          ? translate('label.posts.card.deleted-content')
          : postComment.message
      }
      publishedAt={postComment.createdAt}
      title={postComment.actor.actorName}
      reactions={postComment.reactions}
      selectedReaction={postComment.reactions.self}
      onSelectedReactionChange={changeReaction}
      onReactionsClick={openReactionsModal}
    />
  );
}

function renderPostCommentCard(postCommentKey: string) {
  return (
    <PostCommentCardWrapper
      postCommentKey={postCommentKey}
      key={postCommentKey}
    />
  );
}

export function PostCommentsSection() {
  const ids = useSelector(PostCommentsSelector.selectIds);
  const status = useSelector(PostCommentsSelector.selectStatus);
  const postCommentCount = useSelector(PostSelector.selectCommentCount);

  return (
    <PostCommentsSectionProvider>
      <CreatePostCommentProvider>
        <DeleteCommentConfirm />

        <Box flex flexDirection="column" gap={16}>
          <Box flex flexJustify="between" flexAlign="center">
            <Text
              variant="bodySmBold"
              tx="label.posts.comments.comment-amount"
              txArgs={{ amount: postCommentCount }}
            />

            <OpenCreatePostCommentModalButton />
          </Box>

          <Box flex flexDirection="column" gap={8} flexGrow={1}>
            {ids.map(renderPostCommentCard)}

            {status === 'pending' && (
              <Box p={8} flex flexJustify="center">
                <Spinner />
              </Box>
            )}
          </Box>
        </Box>
      </CreatePostCommentProvider>
    </PostCommentsSectionProvider>
  );
}
