import { Time, getUID } from '@orbiapp/components';

import { MembershipPeriod, MembershipPeriodForm } from '../models';

export const isActivePeriod = (
  period: Pick<MembershipPeriod, 'validFrom' | 'validTo'>,
) => {
  const now = Date.now();

  return (
    (period.validFrom === null || period.validFrom <= now) &&
    (period.validTo === null || period.validTo >= now)
  );
};

export const hasValidMembershipPeriod = (
  periods: Pick<MembershipPeriod, 'validFrom' | 'validTo'>[],
) => periods.some(isActivePeriod);

export const getPeriodFormDefaultValues = (): MembershipPeriodForm => ({
  key: getUID(),
  lifetime: false,
  name: '',
  price: 0,
  saleEnd: Date.now() + Time.Hour,
  saleStart: Date.now(),
  validFrom: Date.now(),
  validTo: Date.now() + Time.Day,
});
