import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { countriesAdapter } from './countries.adapter';

export namespace CountriesSelector {
  const selectSelf = (state: RootState) => state.countries.countries;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = countriesAdapter.getSelectors(
    (state: RootState) => state.countries.countries.data,
  );

  export const selectAll = selectors.selectAll;
}
