import {
  Confirm,
  ConfirmState,
  INITIAL_CONFIRM_STATE,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import {
  DeletePostSelector,
  deletePostThunk,
  useDispatch,
  useSelector,
} from '../../store';

export const DeletePostContext = React.createContext<ConfirmState<string>>(
  INITIAL_CONFIRM_STATE,
);

export function DeletePostProvider(props: React.PropsWithChildren) {
  const deletePostStatus = useSelector(DeletePostSelector.selectStatus);

  const dispatch = useDispatch();

  const confirm = useConfirm<string>();

  const deletePost = React.useCallback(async () => {
    if (!confirm.confirmValue) return;

    const res = await dispatch(deletePostThunk(confirm.confirmValue));

    if (res.meta.requestStatus === 'fulfilled') {
      confirm.closeConfirm();
    }
  }, [dispatch, confirm]);

  return (
    <DeletePostContext.Provider value={confirm}>
      <Confirm
        isOpen={confirm.isOpen}
        isLoading={deletePostStatus === 'pending'}
        onConfirm={deletePost}
        onCancel={confirm.closeConfirm}
        titleTx="prompt.delete-post.title"
        messageTx="prompt.delete-post.message"
        confirmTx="prompt.delete-post.confirm"
        cancelTx="button.cancel"
      />

      {props.children}
    </DeletePostContext.Provider>
  );
}
