import React from 'react';

import { ImageData } from '../../models';
import { formatDate } from '../../utils';
import { Avatar, AvatarVariant } from '../avatar';
import { Box } from '../box';
import { CollapsibleText } from '../collapsible-text';
import { IconButton } from '../icon-button';
import { ImageGrid } from '../image-grid';
import { Menu, MenuItem, MenuItemProps, useMenu } from '../menu';
import { ReactionsGroup, ReactionsGroupProps } from '../reactions-group';
import { Text } from '../text';

interface PostCardProps extends ReactionsGroupProps {
  message: string | null;

  avatarSrc?: string;
  avatarFallbackLetter?: string;
  avatarVariant?: AvatarVariant;

  publishedAt: number;

  title: string;

  menuItems?: MenuItemProps[];

  images?: ImageData[];

  isDeleted?: boolean;
}

const SHOW_MORE_TRHESHOLD = 200;

export function PostCard(props: PostCardProps) {
  const menuState = useMenu();

  const renderMenuItem = (item: MenuItemProps, index: number) => {
    const menuItemClickHandler = item.onClick
      ? (e: React.MouseEvent<HTMLDivElement>) => {
          menuState.closeMenu();
          item.onClick?.(e);
        }
      : undefined;

    return <MenuItem key={index} {...item} onClick={menuItemClickHandler} />;
  };

  return (
    <Box
      backgroundColor="backgroundPrimary"
      flex
      flexDirection="column"
      gap={8}
      p={16}
      r={8}
    >
      <Box gap={8} flex flexJustify="between">
        <Box overflow="hidden" flexAlign="center" gap={4} flex>
          <Box width={32} height={32}>
            <Avatar
              width={32}
              height={32}
              src={props.avatarSrc}
              fallbackLetter={props.avatarFallbackLetter}
              variant={props.avatarVariant}
            />
          </Box>

          <Text
            overflow="hidden"
            text={props.title}
            whiteSpace="nowrap"
            variant="bodySmBold"
            textOverflow="ellipsis"
          />

          <Text
            text={formatDate(props.publishedAt, 'timeAgo')}
            color="textSecondary"
            variant="bodyXs"
            whiteSpace="nowrap"
          />
        </Box>

        {!!props.menuItems?.length && (
          <Box ref={menuState.clickOutsideRef} relative>
            <IconButton
              isActive={menuState.isOpen}
              onClick={menuState.toggleMenu}
              icon="ellipsis-vertical"
            />

            <Menu
              top="100%"
              mt={8}
              right={0}
              absolute
              isOpen={menuState.isOpen}
              zIndex={10}
              width={195}
            >
              {props.menuItems.map(renderMenuItem)}
            </Menu>
          </Box>
        )}
      </Box>

      {props.images?.length && (
        <ImageGrid
          gridSourceResolution="thumbnail512"
          height={180}
          images={props.images}
        />
      )}

      {props.message && (
        <CollapsibleText
          text={props.message}
          threshold={SHOW_MORE_TRHESHOLD}
          variant={props.isDeleted ? 'bodyMdItalic' : undefined}
          color={props.isDeleted ? 'textSecondary' : undefined}
        />
      )}

      <ReactionsGroup
        onReactionsClick={props.onReactionsClick}
        reactions={props.reactions}
        onSelectedReactionChange={props.onSelectedReactionChange}
        selectedReaction={props.selectedReaction}
      />
    </Box>
  );
}
