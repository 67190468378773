import React from 'react';

const FullscreenContext = React.createContext<{
  isFullscreen: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
}>({
  isFullscreen: false,
  enterFullscreen: () => {},
  exitFullscreen: () => {},
});

export function FullscreenProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const enterFullscreen = () => {
    document.documentElement.requestFullscreen();
  };

  const exitFullscreen = () => {
    document.exitFullscreen();
  };

  React.useEffect(() => {
    const onFullscreenChangeHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        exitFullscreen();
      }
    };

    window.addEventListener('fullscreenchange', onFullscreenChangeHandler);
    window.addEventListener('keyup', handleKeyPress);

    return () => {
      window.removeEventListener('fullscreenchange', onFullscreenChangeHandler);
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <FullscreenContext.Provider
      value={{ isFullscreen, enterFullscreen, exitFullscreen }}
    >
      {children}
    </FullscreenContext.Provider>
  );
}

export function useFullscreen() {
  return React.useContext(FullscreenContext);
}
