import React from 'react';

import { ReactionType } from '../../models';
import { Avatar, AvatarVariant } from '../avatar';
import { Box } from '../box';
import { IconName } from '../icon';
import { ReactionsGroupItem } from '../reactions-group';
import { Text, TxArgs } from '../text';
import { Styled } from './reactions-row.styled';

export interface ReactionsRowProps {
  reactionType: ReactionType;

  followerCount?: number;

  subtitle?: string;
  subtitleTx?: TxString;
  subtitleTxArgs?: TxArgs;

  title: string;
  titleTx?: TxString;
  titleTxArgs?: TxArgs;

  avatarSrc?: string;
  avatarFallback?: string;
  avatarVariant?: AvatarVariant;
}

function getIconName(reactionType: ReactionType): IconName {
  switch (reactionType) {
    case 'fire':
      return 'fire';

    case 'party_popper':
      return 'party-popper';

    case 'red_heart':
      return 'heart';

    case 'thumbs_up':
      return 'thumbs-up';
  }
}

export function ReactionsRow(props: ReactionsRowProps) {
  const {
    avatarSrc,
    avatarFallback,
    avatarVariant,

    title,
    titleTx,
    titleTxArgs,

    subtitle,
    subtitleTx,
    subtitleTxArgs,

    followerCount,
  } = props;

  return (
    <Styled.ReactionsRow p={16} flexAlign="center" flex gap={8}>
      <Box relative>
        <Avatar
          fallbackLetter={avatarFallback}
          variant={avatarVariant}
          src={avatarSrc}
        />

        <Box bottom={-4} right={-4} absolute>
          <ReactionsGroupItem icon={getIconName(props.reactionType)} />
        </Box>
      </Box>

      <Box flex flexDirection="column">
        <Box flex gap={4} flexAlign="center">
          <Text
            text={title}
            tx={titleTx}
            txArgs={titleTxArgs}
            variant="bodyMdBold"
          />
          {followerCount && (
            <React.Fragment>
              <Text text="·" variant="bodySm" color="textSecondary" />
              <Text
                text={followerCount}
                variant="bodySm"
                color="textSecondary"
              />
            </React.Fragment>
          )}
        </Box>

        {(subtitle || subtitleTx) && (
          <Text
            text={subtitle}
            tx={subtitleTx}
            txArgs={subtitleTxArgs}
            variant="bodySm"
            color="textSecondary"
          />
        )}
      </Box>
    </Styled.ReactionsRow>
  );
}
