import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './memberships.build';
import { initialMembershipsState } from './memberships.initial';
import { reducers } from './memberships.reducers';

const membershipsSlice = createSlice({
  name: 'memberships',
  initialState: initialMembershipsState,
  reducers,
  extraReducers,
});

export const membershipsActions = membershipsSlice.actions;
export const membershipsReducer = membershipsSlice.reducer;
