import styled, { css } from 'styled-components';

import { Box } from '../box';

const TooltipContent = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.tooltipBackground};
      border: 1px solid ${theme.colors.tooltipBackground};
    `;
  }}
`;

export const Styled = { TooltipContent };
