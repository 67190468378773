import React from 'react';

import { Box } from '../box';
import { Styled } from './radio.styled';
import { RadioProps } from './radio.types';

export function Radio(props: RadioProps) {
  const {
    checked,
    disabled,
    onChange,
    subtitle,
    subtitleTx,
    subtitleTxArgs,
    text,
    dotPlacement = 'right',
    tx,
    txArgs,
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onChange(!checked);
  };

  const radio = (
    <Styled.Radio
      mt={subtitleTx || subtitle ? 4 : 0}
      checked={checked}
      onClick={handleClick}
      disabled={disabled}
    >
      <Styled.RadioBackground />
      <Styled.RadioCircle />
      <Styled.RadioDot />
    </Styled.Radio>
  );

  if (tx || text) {
    return (
      <Styled.RadioTextBox
        checked={checked}
        disabled={disabled}
        flex
        flexAlign={subtitleTx || subtitle ? 'start' : 'center'}
        gap={8}
        onClick={handleClick}
        width="fit-content"
        flexDirection={dotPlacement === 'right' ? 'row' : 'row-reverse'}
      >
        <Box flex flexDirection="column">
          <Styled.RadioText
            text={text}
            tx={tx}
            txArgs={txArgs}
            variant="bodyMd"
          />
          <Styled.RadioSubtitle
            text={subtitle}
            tx={subtitleTx}
            txArgs={subtitleTxArgs}
            variant="bodySm"
          />
        </Box>

        {radio}
      </Styled.RadioTextBox>
    );
  }

  return radio;
}
