import styled, { css } from 'styled-components';

import { getTextStyles } from '../text';
import {
  RadialProgressPathProps,
  RadialProgressTextProps,
  RadialProgressTrackProps,
} from './radial-progress.types';

const RadialProgressTrack = styled.path<RadialProgressTrackProps>`
  ${(props) => {
    const { theme, trackColor, disabled } = props;

    return css`
      stroke: ${theme.colors[trackColor]};
      fill-opacity: 0;

      ${disabled &&
      css`
        opacity: 0.3;
      `}
    `;
  }}
`;

const RadialProgressPath = styled.path<RadialProgressPathProps>`
  ${(props) => {
    const { theme, progressColor, disabled } = props;

    return css`
      stroke: ${theme.colors[progressColor]};
      stroke-linecap: round;
      fill-opacity: 0;

      ${disabled &&
      css`
        opacity: 0.3;
      `}
    `;
  }}
`;

const RadialProgressText = styled.text<RadialProgressTextProps>`
  ${(props) => {
    const { theme, textColor, disabled } = props;

    return css`
      ${getTextStyles('displaySm', theme)};

      fill: ${theme.colors[textColor]};
      text-anchor: middle;
      dominant-baseline: central;

      ${disabled &&
      css`
        opacity: 0.3;
      `}
    `;
  }}
`;

export const Styled = {
  RadialProgressTrack,
  RadialProgressPath,
  RadialProgressText,
};
