import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

import { reducer } from './store.reducer';
import { RootState } from './store.types';

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const useDispatch = () => useReduxDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
