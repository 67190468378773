import { ActivityCategory, DescriptionForm } from '../../../../../../models';
import {
  ActivityCategoryDto,
  ActivityCategorySuggestDto,
} from './categories.dto';

export const activityCategoryMapper = (
  activityCategoryDto: ActivityCategoryDto,
): ActivityCategory => ({
  activityCategoryKey: activityCategoryDto.activityCategoryKey,
  name: activityCategoryDto.name,
});

export const activityCategorySuggestMapper = (
  activityCategorySuggest: Pick<DescriptionForm, 'description' | 'title'>,
): ActivityCategorySuggestDto => ({
  description: activityCategorySuggest.description,
  title: activityCategorySuggest.title,
});
