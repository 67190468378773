import { RequestedInfoType } from '../../shared';

export interface ActivityFreeText {
  id: string;
  index: number;
  isNew: boolean;
  isRequired: boolean;
  question: string;
  ticketTypeKeys: string[];
  type: RequestedInfoType.FreeText;
}

export interface ActivityRequestedInfoOption {
  id: string;
  index: number;
  isNew: boolean;
  label: string;
  priceIncrement: number;
}

export interface ActivityMultiChoice {
  enablePriceIncrement: boolean;
  id: string;
  index: number;
  isNew: boolean;
  isRequired: boolean;
  options: ActivityRequestedInfoOption[];
  question: string;
  ticketTypeKeys: string[];
  type: RequestedInfoType.MultiChoice;
}

export interface ActivityCheckboxAnswer {
  isChecked: boolean;
  question: string;
  type: RequestedInfoType.Checkbox;
}

export interface ActivityFreetextAnswer {
  answer: string;
  question: string;
  type: RequestedInfoType.FreeText;
}

export interface ActivityMultichoiceAnswer {
  label: string;
  priceIncrement: number;
  question: string;
  type: RequestedInfoType.MultiChoice;
}

export interface ActivityCheckbox {
  enablePriceIncrement: boolean;
  id: string;
  index: number;
  isNew: boolean;
  priceIncrement: number;
  question: string;
  ticketTypeKeys: string[];
  type: RequestedInfoType.Checkbox;
}

export type RequestedInfo =
  | ActivityFreeText
  | ActivityMultiChoice
  | ActivityCheckbox;

export type RequestedInfoAnswer =
  | ActivityCheckboxAnswer
  | ActivityFreetextAnswer
  | ActivityMultichoiceAnswer;

export interface FreeTextListItem {
  answer: string;
  answeredAt: number;
  email: string | null;
  freetextListItemKey: string;
  fullName: string | null;
  question: string;
  ticketTypeName: string;
  type: RequestedInfoType.FreeText;
}

export interface MultiChoiceListItem {
  type: RequestedInfoType.MultiChoice;
  id: string;
  question: string;
  answers: {
    numberOfAnswers: number;
    index: number;
    label: string;
  }[];
}

export interface CheckboxListItem {
  type: RequestedInfoType.Checkbox;
  id: string;
  question: string;
  answers: { yes: number; no: number };
}

export interface RequestedInfoStats {
  checkboxStats: CheckboxListItem[];
  multichoiceStats: MultiChoiceListItem[];
}

export type FreeTextListItemsOrderByKey =
  | keyof Pick<
      FreeTextListItem,
      | 'answer'
      | 'answeredAt'
      | 'email'
      | 'fullName'
      | 'question'
      | 'ticketTypeName'
    >
  | 'createdAt';

export const freeTextListItemsSortableKeys: Record<
  FreeTextListItemsOrderByKey,
  FreeTextListItemsOrderByKey
> = {
  answer: 'answer',
  answeredAt: 'answeredAt',
  createdAt: 'createdAt',
  email: 'email',
  fullName: 'fullName',
  question: 'question',
  ticketTypeName: 'ticketTypeName',
};
