import { Box, IconButton, useFullscreen } from '@orbiapp/components';
import React from 'react';

export function ToggleFullscreenButton(props: { disabled?: boolean }) {
  const { disabled } = props;

  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  if (isFullscreen) {
    return (
      <Box fixed top={24} right={24} zIndex={101}>
        <IconButton
          disabled={disabled}
          icon="x-circle-outline"
          onClick={exitFullscreen}
        />
      </Box>
    );
  }

  return (
    <IconButton
      disabled={disabled}
      icon="arrows-pointing-out"
      onClick={enterFullscreen}
    />
  );
}
