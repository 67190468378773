import { RefundLimitExceededError } from '../shared';

export type RefundMembershipPeriodErrorResponse =
  | string
  | RefundLimitExceededError
  | undefined;

interface PendingInvitationsError {
  code: 0;
  message: string;
  metadata: { count: number };
}

export type DeleteMembershipTypeErrorResponse =
  | string
  | PendingInvitationsError
  | undefined;

export namespace MembershipTypeErrors {
  export const description = {
    'string.empty': 'errors.description.empty',
    'string.max': 'errors.description.max',
  };

  export const logo = {
    'object.base': 'errors.logo.empty',
  };

  export const name = {
    'string.empty': 'errors.name.empty',
    'string.max': 'errors.name.max',
  };

  export const type = {
    'any.only': 'errors.membership-type.invalid',
  };

  export const backgroundImage = {
    'any.required': 'errors.background-image.empty',
    'object.base': 'errors.background-image.empty',
    'any.only': 'errors.background-image.empty',
  };

  export const facebookUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const instagramUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const twitterUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const youtubeUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const websiteUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const membershipPeriodKeys = {
    'array.base': 'errors.membership-period-keys.empty',
    'array.min': 'errors.membership-period-keys.empty',
    'any.only': 'errors.membership-period-keys.empty',
    'array.includesRequiredUnknowns': 'errors.membership-period-keys.empty',
  };
}

interface UnknownMembershipSourceError {
  code: 0;
  message: string;
}

interface InvalidUrlError {
  code: 1;
  message: string;
}

interface InvalidApiKeyError {
  code: 2;
  message: string;
}

interface DuplicationError {
  code: 3;
  message: string;
}

export type CheckIntegrationErrorResponse =
  | string
  | UnknownMembershipSourceError
  | InvalidUrlError
  | InvalidApiKeyError
  | DuplicationError
  | undefined;
