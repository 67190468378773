import { Checkbox } from '@orbiapp/components';
import React from 'react';

import { segmentationValues } from '../../../../../../../models';
import { ActivityFormContext } from '../../../create-activity.context';
import { getSegmentatonTypeTx } from '../segmentation-picker.utils';

export function SegmentationPickerSemester() {
  const formContext = React.useContext(ActivityFormContext);

  const setSegmentationValues = React.useCallback(
    (values: number[]) => {
      formContext.setValue('participants.segmentation', values, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
    },
    [formContext],
  );

  const segmentation = formContext.watch('participants.segmentation');

  const renderSegmentationCheckbox = (value: number) => {
    const isChecked = segmentation.includes(value);

    const toggleSegmentationValue = () => {
      const newState = [...segmentation];

      if (!isChecked) {
        setSegmentationValues([...newState, value].sort((a, b) => a - b));
        return;
      }

      newState.splice(newState.indexOf(value), 1);
      setSegmentationValues(newState.sort((a, b) => a - b));
    };

    return (
      <Checkbox
        checked={isChecked}
        key={`toggle-button-seg-${value}`}
        onChange={toggleSegmentationValue}
        tx={getSegmentatonTypeTx('semester')}
        txArgs={{ semester: value }}
      />
    );
  };

  return (
    <React.Fragment>
      {segmentationValues.map(renderSegmentationCheckbox)}
    </React.Fragment>
  );
}
