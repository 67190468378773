import { PageSize } from '../components';

const sortingOrder = ['asc', 'desc'] as const;
export type SortingOrder = (typeof sortingOrder)[number];

export interface Pagination<OrderBy = undefined> {
  limit: number;
  offset: number;
  orderBy: OrderBy;
  sortOrder: SortingOrder;
}

function getHasNextPage<OrderBy>(
  length: number,
  pagination: Pagination<OrderBy>,
) {
  return length > pagination.offset + pagination.limit;
}

export function getPaginatorProps<DataType, OrderBy>(
  data: DataType[],
  pagination: Pagination<OrderBy>,
) {
  return {
    pageSize: pagination.limit as PageSize,
    hasPrevPage: pagination.offset > 0,
    currentPage: pagination.offset / pagination.limit,
    hasNextPage: getHasNextPage(data.length, pagination),
  };
}
