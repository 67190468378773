import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  MembershipMemberListItemStats,
  MembershipTypeStats,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../api.constants';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  MembershipMemberListItemStatsDto,
  MembershipTypeStatsDto,
} from './stats.dto';
import {
  membershipMemberListItemStatsMapper,
  membershipTypeStatsMapper,
} from './stats.mappers';

export const getMembershipMemberListItemStats = async (
  membershipTypeKey: string,
): Promise<GeneralApiResultWithData<MembershipMemberListItemStats[]>> => {
  const res = await apisauce.get<MembershipMemberListItemStatsDto[], any>(
    `/v2/membership-types/${membershipTypeKey}/stats/members`,
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getMembershipMembersStats },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(membershipMemberListItemStatsMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipMemberListItemStats', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getMembershipTypeStats = async (
  membershipTypeKey: string,
): Promise<GeneralApiResultWithData<MembershipTypeStats>> => {
  const res = await apisauce.get<MembershipTypeStatsDto, any>(
    `/v2/membership-types/${membershipTypeKey}/stats`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = membershipTypeStatsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipTypeStats', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
