import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import { ActivityListItem, ActivityOrderByKey } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../api.constants';
import { getGeneralApiProblem } from '../../../../api.utils';
import { activityListItemMapper } from './listed.mappers';

export const getActivities = async (params: {
  pagination: Pagination<ActivityOrderByKey>;
}): Promise<GeneralApiResultWithData<ActivityListItem[]>> => {
  const { pagination } = params;

  const res = await apisauce.get<ActivityListItem[], any>(
    '/v2/activities/listed/items',
    pagination,
    { timeout: CUSTOM_TIMEOUTS.getActivities },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(activityListItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivities', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
