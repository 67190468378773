import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { externalJobsAdapter } from './jobs.adapter';

export namespace HideJobSelector {
  export const selectStatus = (state: RootState) => state.jobs.hideJob.status;
}

export namespace ExternalJobsSelector {
  const selectSelf = (state: RootState) => state.jobs.externalJobs;

  export const selectStatus = createSelector(
    selectSelf,
    (externalJobs) => externalJobs.status,
  );

  const selectData = createSelector(
    selectSelf,
    (externalJobs) => externalJobs.data,
  );

  const externalJobsSelectors = externalJobsAdapter.getSelectors(selectData);

  export const selectAll = externalJobsSelectors.selectAll;
}
