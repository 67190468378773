import { GlobalAlert, Time, TxArgs } from '@orbiapp/components';

import { AlertType } from '../models';

export function getAlert(
  type: AlertType,
  subtitleTxArgs?: TxArgs,
): GlobalAlert {
  switch (type) {
    case 'unknown':
      return {
        variant: 'error',
        subtitleTx: 'action.general.subtitle.error',
      };

    case 'max-number-of-files-reached':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.max-number-of-files-reached',
        subtitleTxArgs,
      };

    case 'image-too-large-size':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-size',
        subtitleTxArgs,
      };

    case 'image-too-small-width':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-small-width',
        subtitleTxArgs,
      };

    case 'image-too-small-height':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-small-height',
        subtitleTxArgs,
      };

    case 'image-too-large-height':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-height',
        subtitleTxArgs,
      };

    case 'image-too-large-width':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.image-too-large-width',
        subtitleTxArgs,
      };

    case 'image-type-invalid':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.invalid-image-type',
      };

    case 'missing-file':
      return {
        variant: 'info',
        subtitleTx: 'errors.upload.missing-file',
      };

    case 'file-type-not-allowed':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.file-type-not-allowed',
      };

    case 'withdraw-invite:error':
      return {
        variant: 'error',
        subtitleTx: 'action.withdraw-invite.error.subtitle',
      };

    case 'withdraw-invite:success':
      return {
        variant: 'success',
        subtitleTx: 'action.withdraw-invite.success.subtitle',
      };

    case 'unknown-upload-error':
      return {
        variant: 'error',
        subtitleTx: 'errors.upload.general',
      };

    case 'element-not-found':
      return {
        variant: 'error',
        subtitleTx: 'errors.stripe.element',
      };

    case 'token-error':
      return {
        variant: 'error',
        subtitleTx: 'errors.stripe.token',
      };

    case 'email-not-verified':
      return {
        variant: 'error',
        subtitleTx: 'action.email-not-verified.error.subtitle',
        subtitleTxArgs,
      };

    case 'sign-in':
      return {
        variant: 'error',
        subtitleTx: 'errors.email-or-password',
      };

    case 'join-orbi-pay':
      return {
        variant: 'error',
        subtitleTx: 'action.join-orbi-pay.error.subtitle',
      };

    case 'invalid-role':
      return {
        variant: 'error',
        subtitleTx: 'errors.sign-in.invalid-role.title',
      };

    case 'create-membership-invitations:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-membership-invitations.error.subtitle',
      };

    case 'create-invitations:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-invitations.error.subtitle',
      };

    case 'create-invitations:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-invitations.success.subtitle',
      };

    case 'send-email-verification:success':
      return {
        variant: 'success',
        subtitleTx: 'action.send-email-verification.success.subtitle',
        subtitleTxArgs,
      };

    case 'send-email-verification:error':
      return {
        variant: 'error',
        subtitleTx: 'action.send-email-verification.error.subtitle',
      };

    case 'send-email-verification:too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.send-email-verification.too-many-requests.subtitle',
      };

    case 'accept-application:error':
      return {
        variant: 'error',
        subtitleTx: 'action.accept-application.error.subtitle',
      };

    case 'accept-application:success':
      return {
        variant: 'success',
        subtitleTx: 'action.accept-application.success.subtitle',
      };

    case 'request-more-info:error':
      return {
        variant: 'error',
        subtitleTx: 'action.request-more-info.error.subtitle',
      };

    case 'request-more-info:success':
      return {
        variant: 'success',
        subtitleTx: 'action.request-more-info.success.subtitle',
      };

    case 'reject-application:error':
      return {
        variant: 'error',
        subtitleTx: 'action.reject-application.error.subtitle',
      };

    case 'reject-application:success':
      return {
        variant: 'success',
        subtitleTx: 'action.reject-application.success.subtitle',
      };

    case 'add-requirement:error':
      return {
        variant: 'error',
        subtitleTx: 'action.add-requirement.error.subtitle',
      };

    case 'add-requirement:success':
      return {
        variant: 'success',
        subtitleTx: 'action.add-requirement.success.subtitle',
      };

    case 'delete-period:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-period.error.subtitle',
      };

    case 'delete-period:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-period.success.subtitle',
      };

    case 'delete-period:invitations-not-zero':
      return {
        autoHideDuration: 20 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.delete-period.invitations-not-zero.subtitle',
        subtitleTxArgs,
      };

    case 'delete-question:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-question.error.subtitle',
      };

    case 'delete-question:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-question.success.subtitle',
      };

    case 'delete-requirement:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-requirement.error.subtitle',
      };

    case 'delete-requirement:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-requirement.success.subtitle',
      };

    case 'reset-password:success':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'success',
        subtitleTx: 'action.reset-password.success.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:error':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.error.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:email-not-found':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.email-not-found.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:email-badly-formatted':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.email-badly-formatted.subtitle',
        subtitleTxArgs,
      };

    case 'reset-password:too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.reset-password.too-many-requests.subtitle',
        subtitleTxArgs,
      };

    case 'update-account-settings:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-user.success.subtitle',
      };

    case 'update-account-settings:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-user.error.subtitle',
      };

    case 'unpublish-activity:success':
      return {
        variant: 'success',
        subtitleTx: 'action.unpublish-activity.success.subtitle',
      };

    case 'add-membership-question:error': {
      return {
        variant: 'error',
        subtitleTx: 'action.add-membership-question.error.subtitle',
      };
    }

    case 'add-membership-question:success': {
      return {
        variant: 'success',
        subtitleTx: 'action.add-membership-question.success.subtitle',
      };
    }

    case 'update-membership-question:error': {
      return {
        variant: 'error',
        subtitleTx: 'action.update-membership-question.error.subtitle',
      };
    }

    case 'add-period:success': {
      return {
        variant: 'success',
        subtitleTx: 'action.add-period.success.subtitle',
      };
    }

    case 'add-period:error': {
      return {
        variant: 'error',
        subtitleTx: 'action.add-period.error.subtitle',
      };
    }

    case 'update-period:success': {
      return {
        variant: 'success',
        subtitleTx: 'action.update-period.success.subtitle',
      };
    }

    case 'update-period:error': {
      return {
        variant: 'error',
        subtitleTx: 'action.update-period.error.subtitle',
      };
    }

    case 'update-membership-question:success': {
      return {
        variant: 'success',
        subtitleTx: 'action.update-membership-question.success.subtitle',
      };
    }

    case 'unpublish-activity:error':
      return {
        variant: 'error',
        subtitleTx: 'action.unpublish-activity.error.subtitle',
      };

    case 'create-qr-scan-token:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-qr-scan-token.error.subtitle',
      };

    case 'create-qr-scan-token:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-qr-scan-token.success.subtitle',
      };

    case 'delete-qr-scan-token:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-qr-scan-token.error.subtitle',
      };

    case 'delete-qr-scan-token:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-qr-scan-token.success.subtitle',
      };

    case 'create-activity:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-activity.error.subtitle',
      };

    case 'create-activity:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-activity.success.subtitle',
      };

    case 'save-activity-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.save-activity-draft.error.subtitle',
      };

    case 'save-activity-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.save-activity-draft.success.subtitle',
      };

    case 'update-activity:success':
      return {
        autoHideDuration: 15 * Time.Second,
        variant: 'success',
        subtitleTx: 'action.update-activity.success.subtitle',
      };

    case 'update-activity-0:error':
      return {
        autoHideDuration: 30 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.update-activity.error.0',
        subtitleTxArgs,
      };

    case 'update-activity-1:error':
      return {
        autoHideDuration: 30 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.update-activity.error.1',
        subtitleTxArgs,
      };

    case 'update-activity:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-activity.error.subtitle',
      };

    case 'reject-cohosting-request:success':
      return {
        subtitleTx: 'action.reject-cohosting-request.success.subtitle',
        variant: 'success',
      };

    case 'reject-cohosting-request:error':
      return {
        subtitleTx: 'action.reject-cohosting-request.error.subtitle',
        variant: 'error',
      };

    case 'accept-cohosting-request:success':
      return {
        subtitleTx: 'action.accept-cohosting-request.success.subtitle',
        variant: 'success',
      };

    case 'accept-cohosting-request:error':
      return {
        subtitleTx: 'action.accept-cohosting-request.error.subtitle',
        variant: 'error',
      };

    case 'delete-activity-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-activity-draft.success.subtitle',
      };

    case 'delete-activity-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-activity-draft.error.subtitle',
      };

    case 'scan-ticket:error':
      return {
        variant: 'error',
        subtitleTx: 'action.scan-ticket.error.subtitle',
      };

    case 'scan-ticket:not-found':
      return {
        variant: 'error',
        subtitleTx: 'action.scan-ticket.not-found.subtitle',
      };

    case 'scan-ticket:wrong-event':
      return {
        variant: 'error',
        subtitleTx: 'action.scan-ticket.wrong-event.subtitle',
      };

    case 'undo-scan-ticket:error':
      return {
        variant: 'error',
        subtitleTx: 'action.undo-scan-ticket.error.subtitle',
      };

    case 'undo-scan-ticket:success':
      return {
        variant: 'success',
        subtitleTx: 'action.undo-scan-ticket.success.subtitle',
      };

    case 'download-users-data:error':
      return {
        variant: 'error',
        subtitleTx: 'action.download-users-data.error.subtitle',
      };

    case 'refund-ticket:success':
      return {
        variant: 'success',
        subtitleTx: 'action.refund-ticket.success.subtitle',
      };

    case 'refund-ticket:error':
      return {
        variant: 'error',
        subtitleTx: 'action.refund-ticket.error.subtitle',
      };

    case 'refund-ticket:limit-exceeded':
      return {
        autoHideDuration: 15 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.refund-ticket.refund-limit.subtitle',
        subtitleTxArgs,
      };

    case 'refund-membership-period:success':
      return {
        variant: 'success',
        subtitleTx: 'action.refund-membership-period.success.subtitle',
      };

    case 'refund-membership-period:error':
      return {
        variant: 'error',
        subtitleTx: 'action.refund-membership-period.error.subtitle',
      };

    case 'refund-membership-period:limit-exceeded':
      return {
        autoHideDuration: 15 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.refund-membership-period.refund-limit.subtitle',
        subtitleTxArgs,
      };

    case 'delete-membership-type:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-membership-type.error.subtitle',
      };

    case 'delete-membership-type:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-membership-type.success.subtitle',
      };

    case 'delete-membership-type:invitations-not-zero':
      return {
        autoHideDuration: 20 * Time.Second,
        variant: 'error',
        subtitleTx:
          'action.delete-membership-type.invitations-not-zero.subtitle',
        subtitleTxArgs,
      };

    case 'consume-ticket:success':
      return {
        variant: 'success',
        subtitleTx: 'action.consume-ticket.success.subtitle',
      };

    case 'consume-ticket:error':
      return {
        variant: 'error',
        subtitleTx: 'action.consume-ticket.error.subtitle',
      };

    case 'undo-ticket-consume:success':
      return {
        variant: 'success',
        subtitleTx: 'action.undo-ticket-consume.success.subtitle',
      };

    case 'undo-ticket-consume:error':
      return {
        variant: 'error',
        subtitleTx: 'action.undo-ticket-consume.error.subtitle',
      };

    case 'hide-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.hide-job.success.subtitle',
      };

    case 'hide-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.hide-job.error.subtitle',
      };

    case 'close-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.close-job.success.subtitle',
      };

    case 'close-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.close-job.error.subtitle',
      };

    case 'create-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-job.success.subtitle',
      };

    case 'create-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-job.error.subtitle',
      };

    case 'update-job:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-job.success.subtitle',
      };

    case 'update-job:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-job.error.subtitle',
      };

    case 'create-membership:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-membership.success.subtitle',
      };

    case 'create-membership:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-membership.error.subtitle',
      };

    case 'update-department:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-department.success.subtitle',
      };

    case 'update-department:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-department.error.subtitle',
      };

    case 'error-in-form':
      return {
        variant: 'error',
        subtitleTx: 'errors.general.error-in-form',
      };

    case 'publish-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.publish-offer.success.subtitle',
        subtitleTxArgs,
      };

    case 'publish-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.publish-offer.error.subtitle',
      };

    case 'update-offer:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-offer.success.subtitle',
      };

    case 'update-offer:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-offer.error.subtitle',
      };

    case 'invite-team-member:success':
      return {
        variant: 'success',
        subtitleTx: 'action.invite-team-member.success.subtitle',
      };

    case 'invite-team-member:error':
      return {
        variant: 'error',
        subtitleTx: 'action.invite-team-member.error.subtitle',
      };

    case 'remove-pending-invitation:success':
      return {
        variant: 'success',
        subtitleTx: 'action.remove-pending-invitation.success.subtitle',
      };

    case 'remove-pending-invitation:error':
      return {
        variant: 'error',
        subtitleTx: 'action.remove-pending-invitation.error.subtitle',
      };

    case 'remove-department-member:success':
      return {
        variant: 'success',
        subtitleTx: 'action.remove-department-member.success.subtitle',
        subtitleTxArgs,
      };

    case 'remove-department-member:error':
      return {
        variant: 'error',
        subtitleTx: 'action.remove-department-member.error.subtitle',
        subtitleTxArgs,
      };

    case 'update-team-member:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-team-member.success.subtitle',
        subtitleTxArgs,
      };

    case 'update-team-member:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-team-member.error.subtitle',
        subtitleTxArgs,
      };

    case 'update-membership:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-membership.success.subtitle',
      };

    case 'update-membership:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-membership.error.subtitle',
      };

    case 'update-question:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-question.success.subtitle',
      };

    case 'update-question:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-question.error.subtitle',
      };

    case 'answer-question:success':
      return {
        variant: 'success',
        subtitleTx: 'action.answer-question.success.subtitle',
      };

    case 'answer-question:error':
      return {
        variant: 'error',
        subtitleTx: 'action.answer-question.error.subtitle',
      };

    case 'check-integration:error':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.create-membership.error.default',
      };

    case 'check-integration:invalid-url':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.create-membership.error.invalid-url',
      };

    case 'check-integration:invalid-api-key':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.create-membership.error.invalid-api-key',
      };

    case 'check-integration:unsupported-source':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.create-membership.error.unsupported-source',
      };

    case 'check-integration:already-exists':
      return {
        autoHideDuration: 10 * Time.Second,
        variant: 'error',
        subtitleTx: 'action.create-membership.error.already-exists',
        subtitleTxArgs,
      };

    case 'create-post:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-post.error.subtitle',
      };

    case 'create-post:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-post.success.subtitle',
      };

    case 'update-post:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-post.error.subtitle',
      };

    case 'update-post:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-post.success.subtitle',
      };

    case 'delete-post:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-post.error.subtitle',
      };

    case 'delete-post:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-post.success.subtitle',
      };

    case 'delete-post-comment:error':
      return {
        variant: 'error',
        subtitleTx: 'action.delete-post-comment.error.subtitle',
      };

    case 'delete-post-comment:success':
      return {
        variant: 'success',
        subtitleTx: 'action.delete-post-comment.success.subtitle',
      };

    case 'create-post-comment:error':
      return {
        variant: 'error',
        subtitleTx: 'action.create-post-comment.error.subtitle',
      };

    case 'create-post-comment:success':
      return {
        variant: 'success',
        subtitleTx: 'action.create-post-comment.success.subtitle',
      };

    case 'update-post-comment:error':
      return {
        variant: 'error',
        subtitleTx: 'action.update-post-comment.error.subtitle',
      };

    case 'update-post-comment:success':
      return {
        variant: 'success',
        subtitleTx: 'action.update-post-comment.success.subtitle',
      };

    case 'firebase/network-request-failed':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.network-request-failed',
      };

    case 'firebase/too-many-requests':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.too-many-requests',
      };

    case 'firebase/user-disabled':
      return {
        variant: 'error',
        subtitleTx: 'action.firebase.user-disabled',
      };

    case 'convert-activity-draft:error':
      return {
        variant: 'error',
        subtitleTx: 'action.convert-activity-draft.error.subtitle',
      };

    case 'convert-activity-draft:success':
      return {
        variant: 'success',
        subtitleTx: 'action.convert-activity-draft.success.subtitle',
      };
  }
}
