import {
  Box,
  INPUT_BORDER_RADIUS,
  INPUT_X_PADDING,
  INPUT_Y_PADDING,
  getTextStyles,
} from '@orbiapp/components';
import styled, { css } from 'styled-components';

const InputBox = styled(Box)<{ focused: boolean }>`
  ${(props) => {
    const { focused, theme } = props;

    return css`
      cursor: text;

      label {
        ${getTextStyles('bodySm', theme)}
        color: ${theme.colors.inputLabel} !important;
        transform: translateY(-50%);
        top: 18px;
      }

      ${focused &&
      css`
        label {
          color: ${theme.colors.inputLabelFocus} !important;
        }

        .stripe-iban-element {
          border-color: ${theme.colors.inputBorderFocus} !important;
        }
      `}

      .stripe-iban-element {
        border: none;
        outline: none;
        width: 100%;
        font-weight: 300;
        height: 58px;
        background-color: ${theme.colors.inputBackground} !important;
        border-color: ${theme.colors.inputBorder};
        color: ${theme.colors.inputText};
        border-width: 1px;
        border-style: solid;
        padding-left: ${INPUT_X_PADDING}px;
        padding-top: ${INPUT_Y_PADDING * 1.8}px;
        padding-bottom: ${INPUT_Y_PADDING}px;
        padding-right: ${INPUT_X_PADDING}px;
        border-radius: ${INPUT_BORDER_RADIUS}px;
        ${getTextStyles('bodyMd', theme)};

        ::placeholder {
          ${getTextStyles('bodySm', theme)}
          color: ${theme.colors.inputPlaceholder} !important;
        }

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    `;
  }}
`;

export const Styled = { InputBox };
