import { stringHasLengthOrNull } from '@orbiapp/components';

import { GeneralInfoForm } from '../../../../../../models';
import { UpdateMembershipTypeInfoDto } from './general-info.dto';

export const updateMembershipTypeInfoMapper = (
  generalInfo: GeneralInfoForm,
): UpdateMembershipTypeInfoDto => {
  return {
    description: generalInfo.description,
    contactDetails: {
      email: generalInfo.contactDetails.email,
      name: generalInfo.contactDetails.name,
      phone: stringHasLengthOrNull(generalInfo.contactDetails.phone),
    },
    name: generalInfo.name,
    logo: {
      base64: generalInfo.logo!.base64,
      fileName: generalInfo.logo!.fileName,
      fileType: generalInfo.logo!.fileType,
    },
    backgroundImage: {
      base64: generalInfo.backgroundImage!.base64,
      fileName: generalInfo.backgroundImage!.fileName,
      fileType: generalInfo.backgroundImage!.fileType,
    },
  };
};
