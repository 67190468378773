export namespace PostErrors {
  export const target = {
    key: {
      'string.empty': 'errors.select.none-selected',
    },
  };

  export const message = {
    'string.empty': 'errors.message.empty',
  };
}
