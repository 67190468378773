import { GeneralApiResultWithData } from '@orbiapp/components';

import { Referral } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import { ReferralDto } from './referrals.dto';
import { referralOrNullMapper } from './referrals.mappers';

export const getReferrals = async (): Promise<
  GeneralApiResultWithData<Referral[]>
> => {
  const res = await apisauce.get<ReferralDto[], any>('/v1/referrals');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data
      .map(referralOrNullMapper)
      .filter((referralOrNull): referralOrNull is Referral => !!referralOrNull)
      .sort((a, b) => b.createdAt - a.createdAt);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getReferrals', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
