import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import {
  FreeTextListItem,
  FreeTextListItemsOrderByKey,
} from '../../../../../../../models';
import { Logger } from '../../../../../../logger';
import { apisauce } from '../../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../../api.constants';
import { getGeneralApiProblem } from '../../../../../api.utils';
import { FreeTextAnswerDto } from './free-text-answers.dto';
import { freetextListItemMapper } from './free-text-answers.mappers';

export const getFreeTextListItems = async (params: {
  activityKey: string;
  pagination: Pagination<FreeTextListItemsOrderByKey>;
}): Promise<GeneralApiResultWithData<FreeTextListItem[]>> => {
  const { activityKey, pagination } = params;

  const res = await apisauce.get<FreeTextAnswerDto[], any>(
    `/v2/activities/${activityKey}/stats/freetext-answers`,
    pagination,
    { timeout: CUSTOM_TIMEOUTS.getFreeTextListItems },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(freetextListItemMapper);
    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getFreeTextListItems', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
