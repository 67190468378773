import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './questions.build';
import { initialQuestionsState } from './questions.initial';
import { reducers } from './questions.reducers';

const questionsSlice = createSlice({
  name: 'questions',
  reducers,
  initialState: initialQuestionsState,
  extraReducers,
});

export const questionsActions = questionsSlice.actions;
export const questionsReducer = questionsSlice.reducer;
