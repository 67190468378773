import { Box } from '@orbiapp/components';
import styled from 'styled-components';

const SegmentationButtonGrid = styled(Box)<{ isSemester: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  gap: 16px;
`;

export const Styled = { SegmentationButtonGrid };
