import { BoxProps } from '../box';
import { Styled } from './toolbar.styled';
import { ToolbarProps } from './toolbar.types';

export function Toolbar(props: ToolbarProps) {
  const { children, ...rest } = props;

  return (
    <Styled.Toolbar
      flex
      flexAlign="center"
      role="toolbar"
      zIndex={100}
      {...rest}
    >
      {children}
    </Styled.Toolbar>
  );
}

export function ToolbarContentContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Styled.ToolbarContentContainer
      flex
      flexWrap="wrap"
      height="100%"
      width="100%"
      gap={16}
      overflow="auto"
      minHeight={64}
      {...rest}
    >
      {children}
    </Styled.ToolbarContentContainer>
  );
}
