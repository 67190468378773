import { GeneralApiResultWithData } from '@orbiapp/components';

import { TicketDetails } from '../../../../../../../models';
import { Logger } from '../../../../../../logger';
import { apisauce } from '../../../../../api';
import { getGeneralApiProblem } from '../../../../../api.utils';
import { TicketDetailsDto } from './details.dto';
import { ticketDetailsMapper } from './details.mappers';

export const getTicketDetails = async (params: {
  activityKey: string;
  ticketKey: string;
}): Promise<GeneralApiResultWithData<TicketDetails>> => {
  const { activityKey, ticketKey } = params;

  const res = await apisauce.get<TicketDetailsDto, any>(
    `/v1/activities/${activityKey}/tickets/${ticketKey}/details`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = ticketDetailsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTicketDetails', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
