import React from 'react';

import { Box, BoxProps } from '../../components/box';
import { Icon, IconName } from '../../components/icon';
import { Text, TxArgs } from '../../components/text';
import { useOnClickOutside } from '../../helpers';
import { Styled } from './wysiwyg.styled';

type WysiwygMode = 'edit' | 'preview';

interface WysiwygProviderProps {
  defaultMode?: WysiwygMode;
  disabled?: boolean;
}

interface WyiswygEditContainerProps extends BoxProps {
  disableEnterPreviewMode?: boolean;
}

interface WysiwygContextValue {
  disabled: boolean;
  mode: WysiwygMode;
  setModeToEdit: () => void;
  setModeToPreview: () => void;
  toggleMode: () => void;
}

const WysiwygContext = React.createContext<WysiwygContextValue>({
  disabled: false,
  mode: 'preview',
  setModeToEdit: () => {},
  setModeToPreview: () => {},
  toggleMode: () => {},
});

export function useWysiwyg() {
  const context = React.useContext(WysiwygContext);

  if (!context) {
    throw new Error('useWysiwyg must be used within a WysiwygProvider');
  }

  return context;
}

export function WysiwygProvider(
  props: React.PropsWithChildren<WysiwygProviderProps>,
) {
  const { defaultMode = 'preview', disabled = false, children } = props;

  const [mode, setMode] = React.useState<WysiwygMode>(defaultMode);

  const setModeToEdit = () => {
    setMode('edit');
  };

  const setModeToPreview = () => {
    setMode('preview');
  };

  const toggleMode = () => {
    setMode((mode) => (mode === 'edit' ? 'preview' : 'edit'));
  };

  return (
    <WysiwygContext.Provider
      value={{ disabled, mode, setModeToEdit, setModeToPreview, toggleMode }}
    >
      {children}
    </WysiwygContext.Provider>
  );
}

export function WyiswygEditContainer(props: WyiswygEditContainerProps) {
  const { disableEnterPreviewMode, ...rest } = props;

  const wysiwyg = useWysiwyg();

  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside({
    event: 'mouseup',
    isActive: wysiwyg.mode === 'edit' && !disableEnterPreviewMode,
    handler: wysiwyg.setModeToPreview,
    ref: ref as React.MutableRefObject<HTMLElement>,
  });

  React.useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !disableEnterPreviewMode) {
        e.stopPropagation();
        wysiwyg.setModeToPreview();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => window.removeEventListener('keydown', handleEscape);
  }, [wysiwyg, disableEnterPreviewMode]);

  return <Box ref={ref} {...rest} />;
}

function WysiwygActionsContainer(props: BoxProps) {
  return <Styled.WysiwygActionsContainer {...props} />;
}

interface WysiwygPreviewContainerProps extends BoxProps {
  actions?: React.ReactNode;
  icon?: IconName;
  iconImgSrc?: string;
  label?: string;
  labelTx?: TxString;
  labelTxArgs?: TxArgs;
  unsavedChanges?: boolean;
  unsavedChangesText?: string;
  unsavedChangesTx?: TxString;
  unsavedChangesTxArgs?: TxArgs;
  value?: string;
  valueTx?: TxString;
  valueTxArgs?: TxArgs;
  emptyText?: string;
  emptyTextTx?: TxString;
  emptyTextTxArgs?: TxArgs;
}

export function WysiwygPreviewContainer(props: WysiwygPreviewContainerProps) {
  const {
    children,
    actions,
    icon,
    iconImgSrc,
    label,
    labelTx,
    labelTxArgs,
    unsavedChanges,
    unsavedChangesText,
    unsavedChangesTx,
    unsavedChangesTxArgs,
    value,
    valueTx,
    valueTxArgs,
    emptyText,
    emptyTextTx,
    emptyTextTxArgs,
    ...rest
  } = props;

  return (
    <Styled.WysiwygPreviewContainer
      flex
      flexAlign="start"
      width="fit-content"
      gap={16}
      px={24}
      py={4}
      {...rest}
    >
      <Box flexGrow={1} flex flexDirection="column" gap={8}>
        <Box flex minHeight={43}>
          {(icon || !!iconImgSrc) && (
            <Box maxHeight={43} flex flexAlign="center" mr={8}>
              {icon ? (
                <Icon name={icon} color="inputIcon" />
              ) : (
                <img alt="" src={iconImgSrc} />
              )}
            </Box>
          )}

          <Box flex flexDirection="column" flexJustify="start" gap={4}>
            <Text
              color="inputLabel"
              text={label}
              tx={labelTx}
              txArgs={labelTxArgs}
              variant="bodySm"
            />
            {!value?.length && (emptyText || emptyTextTx) ? (
              <Text
                color="inputText"
                text={emptyText}
                tx={emptyTextTx}
                txArgs={emptyTextTxArgs}
                variant="bodyMdItalic"
                wordBreak="break-word"
                whiteSpace="pre-line"
              />
            ) : value || valueTx ? (
              <Text
                color="inputText"
                text={value}
                tx={valueTx}
                txArgs={valueTxArgs}
                variant="bodyMd"
                wordBreak="break-word"
                whiteSpace="pre-line"
              />
            ) : (
              children
            )}
          </Box>

          {unsavedChanges && (
            <Box flex flexAlign="center" ml={16}>
              <Icon
                name="exclamation-circle-outline"
                color="unsavedChangesIcon"
              />
            </Box>
          )}
        </Box>

        {unsavedChanges && (unsavedChangesText || unsavedChangesTx) && (
          <Text
            text={unsavedChangesText}
            tx={unsavedChangesTx}
            txArgs={unsavedChangesTxArgs}
            variant="bodyMdItalic"
            color="unsavedChangesLabel"
            height={21}
          />
        )}
      </Box>

      {actions && <WysiwygActionsContainer>{actions}</WysiwygActionsContainer>}
    </Styled.WysiwygPreviewContainer>
  );
}
