import { Box, Divider, Icon, Radio, Text, Tooltip } from '@orbiapp/components';
import React from 'react';

import {
  MembershipTypeQuestionForm,
  RequestedInfoType,
} from '../../../../../models';
import {
  getMembershipRequestedInfoDefaultValues,
  getRequestedInfoTypeTooltipTx,
  getRequestedInfoTypeTx,
} from '../../../../../utils';
import { RequestedInfoFormHeaderProps } from './requested-info-form-header.types';

function SelectQuestionType<T extends MembershipTypeQuestionForm>(
  props: RequestedInfoFormHeaderProps<T>,
) {
  const { defaultValues, setDefaultValues } = props;

  const renderRequestedInfoType = (requestedInfoType: RequestedInfoType) => {
    const newDefaultValues =
      getMembershipRequestedInfoDefaultValues(requestedInfoType);

    const onChange = () => {
      setDefaultValues({
        ...newDefaultValues,
        question: defaultValues?.question ?? newDefaultValues.question,
        isRequired: defaultValues?.isRequired ?? newDefaultValues.isRequired,
        key: defaultValues?.key ?? newDefaultValues.key,
      } as T);
    };

    return (
      <Box key={requestedInfoType} gap={14} flex>
        <Radio
          checked={defaultValues?.type === requestedInfoType}
          onChange={onChange}
          tx={getRequestedInfoTypeTx(requestedInfoType)}
        />

        <Tooltip
          placement="bottom"
          titleTx={getRequestedInfoTypeTooltipTx(requestedInfoType)}
        >
          <Icon color="checkmarkIcon" name="question-mark-circle-solid" />
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box flex flexDirection="column" gap={24}>
      {Object.values(RequestedInfoType).map(renderRequestedInfoType)}
    </Box>
  );
}

export function RequestedInfoFormHeader<T extends MembershipTypeQuestionForm>(
  props: RequestedInfoFormHeaderProps<T>,
) {
  const { disableSelectType, titleTx } = props;

  return (
    <React.Fragment>
      <Text variant="bodyLgBold" tx={titleTx} />

      {!disableSelectType && (
        <React.Fragment>
          <SelectQuestionType {...props} />

          <Divider />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
