import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { BoxProps, WrapBoxProps } from './box.types';

const Box = styled('div')<BoxProps>`
  ${baseComponentStyles};
`;

const WrapBox = styled(Box)<WrapBoxProps>`
  ${baseComponentStyles};

  ${(props) => {
    const { breakpoint, theme } = props;

    return css`
      flex-wrap: wrap;

      @media (min-width: ${theme.breakpoints[breakpoint]}px) {
        flex-wrap: unset;
      }
    `;
  }}
`;

export const Styled = { Box, WrapBox };
