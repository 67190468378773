import { Box, BoxProps } from '../../components';

export function MainContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box height="100vh" flex flexDirection="column" {...rest}>
      {children}
    </Box>
  );
}
