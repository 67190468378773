import { fromScu } from '@orbiapp/components';

import {
  MembershipPayoutPaymentItem,
  MembershipPayoutPaymentWithRefundItem,
  MembershipPayoutRefundItem,
  OrbiPayAdmin,
  OrbiPayAdminMember,
  OrbiPayMember,
  OrbiPayNotOnboarded,
  OrbiPayRole,
  OrbiPaySettings,
  Payout,
  PayoutItem,
  PayoutItemByActivity,
  PayoutItemByDepartment,
  PayoutItemByMembership,
  PayoutListItem,
  PayoutMembershipPeriod,
  PayoutPaymentItem,
  PayoutPaymentWithRefundItem,
  PayoutRefundItem,
} from '../../../../../models';
import {
  OrbiPayAdminDto,
  OrbiPayAdminMemberDto,
  OrbiPayMemberDto,
  OrbiPayNotOnboardedDto,
  OrbiPaySettingsDto,
  PayoutDto,
  PayoutItemByActivityDto,
  PayoutItemByDepartmentDto,
  PayoutItemByMembershipDto,
  PayoutListItemDto,
  PayoutMembershipPeriodDto,
  PayoutPayoutItemDto,
} from './orbi-pay.dto';

const orbiPayAdminMemberMapper = (
  orbiPayAdminMemberDto: OrbiPayAdminMemberDto,
): OrbiPayAdminMember => ({
  departmentKey: orbiPayAdminMemberDto.departmentKey,
  departmentName: orbiPayAdminMemberDto.departmentName,
  orbiPayMemberKey: orbiPayAdminMemberDto.orbiPayMemberKey,
  role: OrbiPayRole.Member,
});

const orbiPayNotOnboardedMapper = (
  orbiPayNotOnboardedDto: OrbiPayNotOnboardedDto,
): OrbiPayNotOnboarded => ({
  estimatedCurrency: orbiPayNotOnboardedDto.estimatedCurrency,
  estimatedFixedCost: orbiPayNotOnboardedDto.estimatedFixedCost,
  estimatedVariableCostPermille:
    orbiPayNotOnboardedDto.estimatedVariableCostPermille,
  role: OrbiPayRole.OrbiPayNotOnboarded,
});

const orbiPayMemberMapper = (
  orbiPayMemberDto: OrbiPayMemberDto,
): OrbiPayMember => ({
  currency: orbiPayMemberDto.currency,
  orbiPayMemberKey: orbiPayMemberDto.orbiPayMemberKey,
  parentDepartmentKey: orbiPayMemberDto.parentDepartmentKey,
  parentDepartmentName: orbiPayMemberDto.parentDepartmentName,
  role: OrbiPayRole.Member,
  stripeRequirements: orbiPayMemberDto.stripeRequirements
    ? {
        deadline: orbiPayMemberDto.stripeRequirements.deadline
          ? orbiPayMemberDto.stripeRequirements.deadline
          : null,
        isDisabled: orbiPayMemberDto.stripeRequirements.isDisabled,
        requirementType: orbiPayMemberDto.stripeRequirements.requirementType,
      }
    : null,
});

const orbiPayAdminMapper = (
  orbiPayAdminDto: OrbiPayAdminDto,
): OrbiPayAdmin => ({
  currency: orbiPayAdminDto.currency,
  fixedCost: orbiPayAdminDto.fixedCost,
  orbiPayAdminKey: orbiPayAdminDto.orbiPayAdminKey,
  orbiPayMembers: orbiPayAdminDto.orbiPayMembers.map(orbiPayAdminMemberMapper),
  role: OrbiPayRole.Admin,
  stripeAccountId: orbiPayAdminDto.stripeAccountId,
  stripeRequirements: orbiPayAdminDto.stripeRequirements
    ? {
        deadline: orbiPayAdminDto.stripeRequirements.deadline
          ? orbiPayAdminDto.stripeRequirements.deadline
          : null,
        isDisabled: orbiPayAdminDto.stripeRequirements.isDisabled,
        requirementType: orbiPayAdminDto.stripeRequirements.requirementType,
      }
    : null,
  variableCostPermille: orbiPayAdminDto.variableCostPermille,
  vatPercent: orbiPayAdminDto.vatPercent,
});

export const orbiPaySettingsMapper = (
  orbiPaySettingsDto: OrbiPaySettingsDto,
): OrbiPaySettings => {
  switch (orbiPaySettingsDto.orbiPayRole) {
    case OrbiPayRole.OrbiPayNotOnboarded:
      return orbiPayNotOnboardedMapper(orbiPaySettingsDto.orbiPay);

    case OrbiPayRole.Member:
      return orbiPayMemberMapper(orbiPaySettingsDto.orbiPay);

    default:
      return orbiPayAdminMapper(orbiPaySettingsDto.orbiPay as OrbiPayAdminDto);
  }
};

export const payoutListItemMapper = (
  payoutListItemDto: PayoutListItemDto,
): PayoutListItem => ({
  amount: fromScu(payoutListItemDto.amount),
  createdAt: payoutListItemDto.createdAt,
  payoutId: payoutListItemDto.payoutId,
  status: payoutListItemDto.status,
});

const payoutItemMapper = (
  payoutItemDto: PayoutItemByActivityDto['payoutItems'][number],
): PayoutItem => {
  switch (payoutItemDto.type) {
    case 'payment':
      const payoutPayment: PayoutPaymentItem = {
        createdAt: payoutItemDto.createdAt,
        fees: fromScu(payoutItemDto.fees),
        id: payoutItemDto.id,
        netIncome: fromScu(payoutItemDto.netIncome),
        paymentAmount: fromScu(payoutItemDto.paymentAmount),
        tickets: payoutItemDto.tickets.map((ticket) => ({
          isRefunded: ticket.isRefunded,
          price: fromScu(ticket.price),
          ticketTypeKey: ticket.ticketTypeKey,
          ticketTypeName: ticket.name,
        })),
        type: payoutItemDto.type,
      };
      return payoutPayment;

    case 'payment_with_refund':
      const payoutWithRefund: PayoutPaymentWithRefundItem = {
        createdAt: payoutItemDto.createdAt,
        fees: fromScu(payoutItemDto.fees),
        id: payoutItemDto.id,
        netIncome: fromScu(payoutItemDto.netIncome),
        paymentAmount: fromScu(payoutItemDto.paymentAmount),
        refundAmount: fromScu(payoutItemDto.refundAmount),
        reversedFees: fromScu(payoutItemDto.reversedFees),
        tickets: payoutItemDto.tickets.map((ticket) => ({
          isRefunded: ticket.isRefunded,
          price: fromScu(ticket.price),
          ticketTypeKey: ticket.ticketTypeKey,
          ticketTypeName: ticket.name,
        })),
        type: payoutItemDto.type,
      };
      return payoutWithRefund;

    case 'refund':
      const refund: PayoutRefundItem = {
        createdAt: payoutItemDto.createdAt,
        id: payoutItemDto.id,
        netIncome: fromScu(payoutItemDto.netIncome),
        refundAmount: fromScu(payoutItemDto.refundAmount),
        reversedFees: fromScu(payoutItemDto.reversedFees),
        tickets: payoutItemDto.tickets.map((ticket) => ({
          isRefunded: ticket.isRefunded,
          price: fromScu(ticket.price),
          ticketTypeKey: ticket.ticketTypeKey,
          ticketTypeName: ticket.name,
        })),
        type: payoutItemDto.type,
      };
      return refund;
  }
};

const payoutItemsByActivityMapper = (
  payoutItemByActivityDto: PayoutItemByActivityDto,
): PayoutItemByActivity => ({
  activityKey: payoutItemByActivityDto.activityKey,
  activityTitle: payoutItemByActivityDto.activityTitle,
  payoutItems: payoutItemByActivityDto.payoutItems.map(payoutItemMapper),
});

const membershipPayoutPeriodMapper = (
  payoutMembershipPeriodDto: PayoutMembershipPeriodDto,
): PayoutMembershipPeriod => {
  return {
    isRefunded: payoutMembershipPeriodDto.isRefunded,
    membershipPeriodKey: payoutMembershipPeriodDto.membershipPeriodKey,
    name: payoutMembershipPeriodDto.name,
    price: fromScu(payoutMembershipPeriodDto.price),
  };
};

const membershipPayoutItemMapper = (
  membershipPayoutItemDto: PayoutItemByMembershipDto['payoutItems'][number],
): PayoutItemByMembership['payoutItems'][number] => {
  switch (membershipPayoutItemDto.type) {
    case 'payment':
      const payment: MembershipPayoutPaymentItem = {
        createdAt: membershipPayoutItemDto.createdAt,
        fees: fromScu(membershipPayoutItemDto.fees),
        id: membershipPayoutItemDto.id,
        membershipPeriods: membershipPayoutItemDto.membershipPeriods.map(
          membershipPayoutPeriodMapper,
        ),
        netIncome: fromScu(membershipPayoutItemDto.netIncome),
        paymentAmount: fromScu(membershipPayoutItemDto.paymentAmount),
        type: 'payment',
      };
      return payment;

    case 'payment_with_refund':
      const paymentWithRefund: MembershipPayoutPaymentWithRefundItem = {
        createdAt: membershipPayoutItemDto.createdAt,
        fees: fromScu(membershipPayoutItemDto.fees),
        id: membershipPayoutItemDto.id,
        membershipPeriods: membershipPayoutItemDto.membershipPeriods.map(
          membershipPayoutPeriodMapper,
        ),
        netIncome: fromScu(membershipPayoutItemDto.netIncome),
        paymentAmount: fromScu(membershipPayoutItemDto.paymentAmount),
        refundAmount: fromScu(membershipPayoutItemDto.refundAmount),
        reversedFees: fromScu(membershipPayoutItemDto.reversedFees),
        type: 'payment_with_refund',
      };
      return paymentWithRefund;

    case 'refund':
      const refund: MembershipPayoutRefundItem = {
        createdAt: membershipPayoutItemDto.createdAt,
        id: membershipPayoutItemDto.id,
        membershipPeriods: membershipPayoutItemDto.membershipPeriods.map(
          membershipPayoutPeriodMapper,
        ),
        netIncome: fromScu(membershipPayoutItemDto.netIncome),
        refundAmount: fromScu(membershipPayoutItemDto.refundAmount),
        reversedFees: fromScu(membershipPayoutItemDto.reversedFees),
        type: 'refund',
      };
      return refund;
  }
};

const payoutItemByMembershipMapper = (
  payoutItemByMembershipDto: PayoutItemByMembershipDto,
): PayoutItemByMembership => {
  return {
    membershipKey: payoutItemByMembershipDto.membershipKey,
    membershipName: payoutItemByMembershipDto.membershipName,
    payoutItems: payoutItemByMembershipDto.payoutItems.map(
      membershipPayoutItemMapper,
    ),
  };
};

const payoutItemByDepartmentMapper = (
  payoutItemByDepartmentDto: PayoutItemByDepartmentDto,
): PayoutItemByDepartment => ({
  departmentKey: payoutItemByDepartmentDto.departmentKey,
  departmentName: payoutItemByDepartmentDto.departmentName,
  payoutItemsByActivity: payoutItemByDepartmentDto.payoutItemsByActivity.map(
    payoutItemsByActivityMapper,
  ),
  payoutItemsByMembership:
    payoutItemByDepartmentDto.payoutItemsByMembership.map(
      payoutItemByMembershipMapper,
    ),
});

const payoutsMapper = (
  payoutPayoutItemDto: PayoutPayoutItemDto,
): Payout['payouts'][number] => ({
  id: payoutPayoutItemDto.id,
  netIncome: fromScu(payoutPayoutItemDto.netIncome),
  type: payoutPayoutItemDto.type,
});

export const payoutMapper = (payoutDto: PayoutDto): Payout => ({
  arrivalDate: payoutDto.arrivalDate,
  departmentAddress: {
    addressLine1: payoutDto.departmentAddress.addressLine1,
    addressLine2: payoutDto.departmentAddress.addressLine2,
    city: payoutDto.departmentAddress.city,
    country: payoutDto.departmentAddress.country,
    postalCode: payoutDto.departmentAddress.postalCode,
  },
  departmentName: payoutDto.departmentName,
  payoutId: payoutDto.payoutId,
  payoutItemsByDepartment: payoutDto.payoutItemsByDepartment.map(
    payoutItemByDepartmentMapper,
  ),
  payouts: payoutDto.payouts.map(payoutsMapper),
  vatPercent: payoutDto.vatPercent,
});
