import { account } from './account';
import { activities } from './activities';
import { department } from './department';
import { jobs } from './jobs';
import { membershipTypes } from './membership-types';
import { team } from './team';

export const v2 = {
  account,
  activities,
  department,
  jobs,
  membershipTypes,
  team,
};
