import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const UnsavedChangesBoxStyled = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border: 1px solid ${theme.colors.unsavedChangesBoxBorder};
    `;
  }}
`;

export const Styled = { UnsavedChangesBoxStyled };
