import { fromScu } from '@orbiapp/components';

import { ActivityDiscountStatsItem } from '../../../../../../../models';
import { DiscountStatsDto } from './discounts.dto';

export const discountStatsMapper = (
  discountStatsDto: DiscountStatsDto,
): ActivityDiscountStatsItem[] => {
  const activityDiscountStatsItems: ActivityDiscountStatsItem[] = [];

  discountStatsDto.discounts.forEach((discount) => {
    switch (discount.type) {
      case 'discount_code':
        activityDiscountStatsItems.push({
          code: discount.code,
          discountCount: discount.discountCount,
          discountSum: fromScu(discount.discountSum),
          type: discount.type,
        });

        break;

      case 'membership':
        activityDiscountStatsItems.push({
          discountCount: discount.discountCount,
          discountSum: fromScu(discount.discountSum),
          name: discount.name,
          type: 'membership',
        });

        break;
    }
  });

  return activityDiscountStatsItems;
};
