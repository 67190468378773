export namespace MembershipDiscountErrors {
  export const customErrors = {
    priceBelowZero: 'ticket-price-below-zero',
    priceBelowMin: 'price-below-min',
  };

  export const membershipTypeKey = {
    'string.empty': 'errors.select.none-selected',
  };

  export const discountType = {
    'any.only': 'errors.select.none-selected',
  };

  export const discount = {
    'price-below-min': 'errors.discount.price-below-min',
    'ticket-price-below-zero': 'errors.discount.ticket-price-below-zero',
    'number.base': 'errors.discount.price-below-min',
    'number.min': 'errors.discount.min',
    'number.integer': 'errors.discount.integer',
    'number.max': 'errors.discount.max',
    'number.any': 'errors.discount.integer',
  };
}
