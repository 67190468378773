import { Confirm } from '@orbiapp/components';
import { useBlocker } from 'react-router-dom';

export function NavBlocker(props: { shouldBlock: boolean }) {
  const { shouldBlock } = props;

  const blocker = useBlocker(shouldBlock);

  return (
    <Confirm
      isOpen={blocker.state === 'blocked'}
      onCancel={blocker.reset || (() => {})}
      onConfirm={blocker.proceed || (() => {})}
      cancelTx="prompt.unsaved-changes.cancel"
      confirmTx="prompt.unsaved-changes.confirm"
      titleTx="prompt.unsaved-changes.title"
      messageTx="prompt.unsaved-changes.message"
    />
  );
}
