import styled, { css } from 'styled-components';

import { Box } from '../box';
import { getTextStyles } from '../text';

const OuterInputBox = styled(Box)<{ isFocused: boolean }>`
  ${(props) => {
    const { isFocused } = props;

    return css`
      ${isFocused &&
      css`
        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      `}
    `;
  }}
`;

const Input = styled.input`
  ${(props) => {
    const { theme } = props;

    return css`
      ${getTextStyles('bodyMd', theme)}

      border: none;
      outline: none;
      width: 100%;
      margin-bottom: -16px;
      flex-grow: 1;

      -webkit-appearance: none;

      ::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      ::-webkit-date-and-time-value {
        text-align: left;
      }

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      ::placeholder {
        ${getTextStyles('bodySm', theme)}
      }

      :-webkit-autofill + label {
        ${getTextStyles('bodySm', theme)}
        top: 18px;
      }

      :placeholder-shown + label {
        ${getTextStyles('bodySm', theme)}
        top: 18px;
      }
    `;
  }}
`;

export const Styled = { Input, OuterInputBox };
