import {
  getQuestionsByActivityThunk,
  getQuestionsThunk,
  getUnhandledQuestionsCountThunk,
  setQuestionToggleIsHiddenThunk,
  updateQuestionThunk,
} from './questions.actions';
import {
  activityQuestionsAdapter,
  questionsAdapter,
} from './questions.adapter';
import { QuestionsBuilder } from './questions.types';
import { getNewQuestionState } from './questions.utils';

const getQuestionsByActivityBuild = (builder: QuestionsBuilder) => {
  return builder
    .addCase(getQuestionsByActivityThunk.fulfilled, (state, action) => {
      state.activityQuestions.status = 'idle';
      state.selectedActivityQuestion = null;
      activityQuestionsAdapter.setAll(
        state.activityQuestions.data,
        action.payload,
      );
    })
    .addCase(getQuestionsByActivityThunk.pending, (state) => {
      state.activityQuestions.status = 'pending';
      state.activityQuestions.error = null;
    })
    .addCase(getQuestionsByActivityThunk.rejected, (state, action) => {
      state.activityQuestions.status = 'idle';
      state.activityQuestions.error = action.error;
    });
};

const getQuestionsUnhandledCountBuild = (builder: QuestionsBuilder) => {
  return builder
    .addCase(getUnhandledQuestionsCountThunk.fulfilled, (state, action) => {
      state.unhandledQuestions.data = action.payload;
      state.unhandledQuestions.status = 'idle';
    })
    .addCase(getUnhandledQuestionsCountThunk.pending, (state) => {
      state.unhandledQuestions.status = 'pending';
      state.unhandledQuestions.error = null;
    })
    .addCase(getUnhandledQuestionsCountThunk.rejected, (state, action) => {
      state.unhandledQuestions.status = 'idle';
      state.unhandledQuestions.error = action.error;
    });
};

const getQuestionsBuild = (builder: QuestionsBuilder) => {
  return builder
    .addCase(getQuestionsThunk.fulfilled, (state, action) => {
      state.questions.status = 'idle';

      questionsAdapter.addMany(state.questions.data, action.payload);

      if (state.selectedQuestion) {
        const questionIsInPayload = !!action.payload.find(
          (question) =>
            question.questionKey === state.selectedQuestion?.questionKey,
        );

        if (!questionIsInPayload) {
          state.selectedQuestion = null;
        }
      }
    })
    .addCase(getQuestionsThunk.pending, (state, action) => {
      state.questions.error = null;
      state.questions.pagination = action.meta.arg;
      state.questions.status = 'pending';
    })
    .addCase(getQuestionsThunk.rejected, (state, action) => {
      state.questions.status = 'idle';
      state.questions.error = action.error;
    });
};

const updateQuestionBuild = (builder: QuestionsBuilder) => {
  return builder
    .addCase(updateQuestionThunk.fulfilled, (state, action) => {
      const payload = action.meta.arg;
      const questionKey = payload.questionKey;

      const activityQuestion = activityQuestionsAdapter
        .getSelectors()
        .selectById(state.questions.data, questionKey);

      if (activityQuestion) {
        activityQuestionsAdapter.updateOne(state.activityQuestions.data, {
          changes: getNewQuestionState(activityQuestion, payload),
          id: questionKey,
        });
      }

      const question = questionsAdapter
        .getSelectors()
        .selectById(state.questions.data, questionKey);

      if (question) {
        questionsAdapter.updateOne(state.questions.data, {
          changes: getNewQuestionState(question, payload),
          id: questionKey,
        });
      }

      if (state.selectedQuestion?.questionKey === questionKey) {
        state.selectedQuestion = getNewQuestionState(
          state.selectedQuestion,
          payload,
        );
      }

      if (state.selectedActivityQuestion?.questionKey === questionKey) {
        state.selectedActivityQuestion = getNewQuestionState(
          state.selectedActivityQuestion,
          payload,
        );
      }

      state.updateQuestion.status = 'idle';
    })
    .addCase(updateQuestionThunk.pending, (state) => {
      state.updateQuestion.status = 'pending';
      state.updateQuestion.error = null;
    })
    .addCase(updateQuestionThunk.rejected, (state, action) => {
      state.updateQuestion.status = 'idle';
      state.updateQuestion.error = action.error;
    });
};

const setQuestionToggleIsHiddenBuild = (builder: QuestionsBuilder) => {
  return builder
    .addCase(setQuestionToggleIsHiddenThunk.fulfilled, (state, action) => {
      const { isHidden, questionKey } = action.meta.arg;

      activityQuestionsAdapter.updateOne(state.activityQuestions.data, {
        changes: { isHidden },
        id: questionKey,
      });

      questionsAdapter.updateOne(state.questions.data, {
        changes: { isHidden },
        id: questionKey,
      });

      if (state.selectedQuestion?.questionKey === questionKey) {
        state.selectedQuestion.isHidden = isHidden;
      }

      if (state.selectedActivityQuestion?.questionKey === questionKey) {
        state.selectedActivityQuestion.isHidden = isHidden;
      }

      state.hideQuestion.status = 'idle';
    })
    .addCase(setQuestionToggleIsHiddenThunk.pending, (state) => {
      state.hideQuestion.status = 'pending';
      state.hideQuestion.error = null;
    })
    .addCase(setQuestionToggleIsHiddenThunk.rejected, (state, action) => {
      state.hideQuestion.status = 'idle';
      state.hideQuestion.error = action.error;
    });
};

export const extraReducers = (builder: QuestionsBuilder) => {
  getQuestionsBuild(builder);
  updateQuestionBuild(builder);
  getQuestionsByActivityBuild(builder);
  setQuestionToggleIsHiddenBuild(builder);
  getQuestionsUnhandledCountBuild(builder);
};
