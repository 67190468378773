import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './reactions.build';
import { initialReactionsState } from './reactions.initial';
import { reducers } from './reactions.reducers';

const reactionsSlice = createSlice({
  name: 'reactions',
  initialState: initialReactionsState,
  reducers,
  extraReducers,
});

export const reactionsActions = reactionsSlice.actions;
export const reactionsReducer = reactionsSlice.reducer;
