import { ImageData } from '@orbiapp/components';

import { ContactDetails } from '../../contact-details';
import { GeneralInfoForm } from '../membership-type.model';

export const integrationSource = ['montania', 'memlist'] as const;

export type MembershipTypeIntegrationSource =
  (typeof integrationSource)[number];

interface CreateMontaniaMembershipTypeMetadata {
  apiKey: string;
  integrationUrl: string;
  source: 'montania';
}

interface CreateMemlistMembershipTypeMetadata {
  integrationUrl: string;
  refreshToken: string;
  source: 'memlist';
}

export type IntegratedMembershipMetadata =
  | CreateMontaniaMembershipTypeMetadata
  | CreateMemlistMembershipTypeMetadata;

export interface CreateIntegratedMembershipTypeForm extends GeneralInfoForm {
  metadata: IntegratedMembershipMetadata;
}

export interface IntegratedMembershipType {
  activeCount: number;
  backgroundImage: ImageData | null;
  contactDetails: ContactDetails;
  description: string;
  logo: ImageData;
  membershipTypeKey: string;
  name: string;
  source: MembershipTypeIntegrationSource;
  totalCount: number;
}
