import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  InnerContentContainer,
  InnerPageContainer,
  PageContainer,
  PageHeader,
  ResponsiveBox,
  SolidIconButton,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
} from '@orbiapp/components';
import React from 'react';

import {
  PostsSidebar,
  PostsTable,
  QuickActionsContext,
} from '../../../components';
import { Analytics } from '../../../services';

function OpenCreatePostModalButton() {
  const { createPostModalState } = React.useContext(QuickActionsContext);

  const handleCreatePostClick = () => {
    Analytics.track('click_create_post__dashboard');

    createPostModalState.openModal();
  };

  return (
    <ResponsiveBox
      xs={
        <Button
          onClick={handleCreatePostClick}
          tx="button.posts.create"
          variant="primary"
        />
      }
    >
      <Tooltip placement="left" titleTx="button.posts.create">
        <SolidIconButton
          icon="plus-circle-outline"
          onClick={handleCreatePostClick}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function PostsContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between" flexAlign='start'>
        <PageHeader headingTx='title.posts.dashboard' subtitleTx='title.posts.subtitle' /> 
        <OpenCreatePostModalButton />
      </Box>

      <PostsTable enableFiltering />
    </React.Fragment>
  );
}

function PostsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.posts.posts" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Posts() {
  return (
    <PageContainer>
      <PostsToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <PostsContent />
          </InnerContentContainer>
        </ContentContainer>

        <PostsSidebar />
      </InnerPageContainer>
    </PageContainer>
  );
}
