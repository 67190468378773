import { ImageData } from '@orbiapp/components';

import { TicketDetails } from './details';

interface TicketEmailTransfer {
  transferredAt: number;
  email: string;
  fullName: string;
}

export interface TicketListItem {
  claimedAt: number;
  consumedAt: number | null;
  details: TicketDetails | null;
  email: string | null;
  emailTransfer: boolean;
  foodPreferences: string | null;
  fullName: string | null;
  ticketKey: string;
  ticketTypeName: string;
  profilePicture: ImageData | null;
  userKey: string | null;
}

export interface TicketHistoryListItem {
  purchaseCreatedAt: number;
  ticketEmailTransfer: TicketEmailTransfer | null;
  ticketHistoryListItemKey: string;
  ticketRefundedAt: number | null;
  ticketTypeName: string;
  userFullName: string | null;
}

export type ShrodingersTicketStatus =
  | 'alreadyConsumed'
  | 'successfullyConsumed'
  | 'refunded'
  | 'emailTransferred'
  | 'transferred';

type ShrodingersTicketHolderType = 'user' | 'email';

export interface ShrodingersTicketMembership {
  membershipTypeKey: string;

  expiredAt: number | null;
  membershipTypeName: string;
  validFrom: number | null;
}

interface ShrodingersTicketHolder {
  type: ShrodingersTicketHolderType;
  name: string;
  email: string;
}

export interface ShrodingersTicket {
  ticketPurchaseKey: string;

  activityTitle: string;
  claimedAt: number;
  requiredMemberships: Array<ShrodingersTicketMembership> | null;
  status: ShrodingersTicketStatus;
  statusUpdatedAt: number;
  ticketHolder: ShrodingersTicketHolder | null;
  ticketTypeName: string;
}

export type TicketListItemOrderByKey = keyof Pick<
  TicketListItem,
  | 'claimedAt'
  | 'consumedAt'
  | 'foodPreferences'
  | 'email'
  | 'fullName'
  | 'ticketTypeName'
>;

export const soldTicketsSortableKeys: Record<
  TicketListItemOrderByKey,
  TicketListItemOrderByKey
> = {
  claimedAt: 'claimedAt',
  consumedAt: 'consumedAt',
  email: 'email',
  foodPreferences: 'foodPreferences',
  fullName: 'fullName',
  ticketTypeName: 'ticketTypeName',
};
