import { initialAccountState } from './account';
import { initialActivitiesState } from './activities';
import { initialAuthState } from './auth';
import { initialCountriesState } from './countries';
import { initialDepartmentState } from './department';
import { initialFileUploadsState } from './file-uploads';
import { initialGlobalUiState } from './global-ui-state';
import { initialJobsState } from './jobs';
import { initialLocationsState } from './locations';
import { initialMembershipsState } from './memberships';
import { initialOffersState } from './offers';
import { initialOrbiPayState } from './orbi-pay';
import { initialPostsState } from './posts';
import { initialQuestionsState } from './questions';
import { initialReactionsState } from './reactions';
import { initialReferralsState } from './referrals';
import { initialSearchState } from './search';
import { RootState } from './store.types';
import { initialTeamState } from './team';

export function getStateAfterSignOut(state?: Partial<RootState>): RootState {
  return {
    account: initialAccountState,
    activity: initialActivitiesState,
    auth: initialAuthState,
    department: initialDepartmentState,
    globalUiState: initialGlobalUiState,
    jobs: initialJobsState,
    memberships: initialMembershipsState,
    orbiPay: initialOrbiPayState,
    posts: initialPostsState,
    questions: initialQuestionsState,
    referrals: initialReferralsState,
    team: initialTeamState,
    search: initialSearchState,
    offers: initialOffersState,
    locations: initialLocationsState,
    countries: initialCountriesState,
    fileUploads: initialFileUploadsState,
    reactions: initialReactionsState,
  };
}

export function getStateAfterSwitchWorkspace(
  state?: Partial<RootState>,
): RootState {
  return {
    account: initialAccountState,
    activity: initialActivitiesState,
    auth: state?.auth ?? initialAuthState,
    department: initialDepartmentState,
    globalUiState: initialGlobalUiState,
    jobs: initialJobsState,
    memberships: initialMembershipsState,
    orbiPay: initialOrbiPayState,
    posts: initialPostsState,
    questions: initialQuestionsState,
    referrals: initialReferralsState,
    team: initialTeamState,
    search: initialSearchState,
    offers: initialOffersState,
    locations: initialLocationsState,
    countries: initialCountriesState,
    fileUploads: initialFileUploadsState,
    reactions: initialReactionsState,
  };
}

export function getStateAfterEmulateUser(
  state?: Partial<RootState>,
): RootState {
  return {
    account: initialAccountState,
    activity: initialActivitiesState,
    auth: state?.auth ?? initialAuthState,
    department: initialDepartmentState,
    globalUiState: initialGlobalUiState,
    jobs: initialJobsState,
    memberships: initialMembershipsState,
    orbiPay: initialOrbiPayState,
    posts: initialPostsState,
    questions: initialQuestionsState,
    referrals: initialReferralsState,
    team: initialTeamState,
    search: initialSearchState,
    offers: initialOffersState,
    locations: initialLocationsState,
    countries: initialCountriesState,
    fileUploads: initialFileUploadsState,
    reactions: initialReactionsState,
  };
}
