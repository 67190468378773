import { PayloadAction } from '@reduxjs/toolkit';

import {
  MembershipApplicationStatus,
  MembershipInviteStatus,
} from '../../models';
import { initialMembershipsState } from './memberships.initial';
import { MembershipsState } from './memberships.types';

const reset = (state: MembershipsState) => {
  Object.assign(state, initialMembershipsState);
};

const clearMembershipError = (state: MembershipsState) => {
  state.membershipType.error = null;
};

const clearCreateMembership = (state: MembershipsState) => {
  state.createMembershipType.data =
    initialMembershipsState.createMembershipType.data;
  state.createIntegratedMembershipType.data =
    initialMembershipsState.createIntegratedMembershipType.data;
};

const clearMembership = (state: MembershipsState) => {
  state.createIntegratedMembershipType =
    initialMembershipsState.createIntegratedMembershipType;
  state.membershipInvites = initialMembershipsState.membershipInvites;
  state.membershipType = initialMembershipsState.membershipType;
  state.membershipMembers = initialMembershipsState.membershipMembers;
  state.membershipMember = initialMembershipsState.membershipMember;
  state.membershipApplication = initialMembershipsState.membershipApplication;
  state.membershipApplications = initialMembershipsState.membershipApplications;
  state.dynamicMembershipLink = initialMembershipsState.dynamicMembershipLink;
  state.membershipTypeStats = initialMembershipsState.membershipTypeStats;
  state.membershipMemberListItemStats =
    initialMembershipsState.membershipMemberListItemStats;
  state.membershipPurchaseListItems =
    initialMembershipsState.membershipPurchaseListItems;
};

const setMembershipApplicationsIncludeStatus = (
  state: MembershipsState,
  action: PayloadAction<MembershipApplicationStatus | null>,
) => {
  state.membershipApplications.pagination =
    initialMembershipsState.membershipApplications.pagination;
  state.membershipApplications.includeStatus = action.payload;
};

const clearMembershipApplications = (state: MembershipsState) => {
  state.membershipApplications = initialMembershipsState.membershipApplications;
};

const clearMembershipApplication = (state: MembershipsState) => {
  state.membershipApplication.data = null;
};

const clearReviewApplications = (state: MembershipsState) => {
  state.membershipApplications = initialMembershipsState.membershipApplications;
};

const clearCheckMembershipError = (state: MembershipsState) => {
  state.checkIntegration.error = null;
};

const clearMembershipInvitationResult = (state: MembershipsState) => {
  state.createMembershipInvitations =
    initialMembershipsState.createMembershipInvitations;
};

const clearMembershipInvites = (state: MembershipsState) => {
  state.membershipInvites = initialMembershipsState.membershipInvites;
};

const setMembershipInvitesIncludeStatus = (
  state: MembershipsState,
  action: PayloadAction<MembershipInviteStatus | null>,
) => {
  state.membershipInvites.pagination =
    initialMembershipsState.membershipInvites.pagination;
  state.membershipInvites.includeStatus = action.payload;
};

const clearMembershipMembers = (state: MembershipsState) => {
  state.membershipMembers = {
    ...initialMembershipsState.membershipMembers,
    search: state.membershipMembers.search,
  };
};

const clearMembershipMember = (state: MembershipsState) => {
  state.membershipMember = initialMembershipsState.membershipMember;
};

const clearMembershipMemberListItemStats = (state: MembershipsState) => {
  state.membershipMemberListItemStats =
    initialMembershipsState.membershipMemberListItemStats;
};

const clearMembershipPurchaseListItems = (state: MembershipsState) => {
  state.membershipPurchaseListItems =
    initialMembershipsState.membershipPurchaseListItems;
};

const setMembershipMembersSearch = (
  state: MembershipsState,
  action: PayloadAction<string>,
) => {
  state.membershipMembers.search = action.payload;
};

export const reducers = {
  reset,

  clearMembershipMembers,
  clearMembershipMember,
  setMembershipMembersSearch,

  clearCheckMembershipError,

  clearMembershipInvitationResult,
  clearMembershipInvites,
  setMembershipInvitesIncludeStatus,

  clearMembershipApplication,
  clearMembershipApplications,
  clearReviewApplications,
  setMembershipApplicationsIncludeStatus,

  clearMembershipPurchaseListItems,

  clearMembershipMemberListItemStats,

  clearMembership,
  clearMembershipError,
  clearCreateMembership,
};
