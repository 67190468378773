import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';

export namespace AuthStateSelector {
  const selectSelf = (state: RootState) => state.auth.authState;

  const selectData = createSelector(selectSelf, (authState) => authState.data);

  export const selectAccountVerified = createSelector(
    selectData,
    (data) => data.accountVerified,
  );

  export const selectAuthenticated = createSelector(
    selectData,
    (data) => data.authenticated,
  );

  export const selectError = createSelector(selectSelf, (data) => data.error);

  export const selectStatus = createSelector(selectSelf, (data) => data.status);

  export const selectInitializing = createSelector(
    selectData,
    (data) => data.initializing,
  );

  export const selectIsEmulating = (state: RootState) =>
    state.auth.authAccount.data &&
    state.auth.authAccount.data.userKey !== state.account.account.data?.userKey;

  export const selectIsSuperAdmin = createSelector(
    [
      selectIsEmulating,
      (state: RootState) => state.auth.authAccount.data?.workspaces ?? null,
      (state: RootState) => state.account.account.data?.workspaces ?? null,
    ],
    (isEmulating, workspaces, accountWorkspaces) => {
      if (isEmulating) {
        return workspaces?.some((workspace) => workspace.type === 'admin');
      }

      return accountWorkspaces?.some((workspace) => workspace.type === 'admin');
    },
  );
}

export namespace SendEmailVerificationSelector {
  export const selectStatus = (state: RootState) =>
    state.auth.sendEmailVerification.status;
}

export namespace CustomSignInTokenSelector {
  const selectSelf = (state: RootState) => state.auth.customSignInToken;

  export const selectStatus = createSelector(
    selectSelf,
    (customSignInToken) => customSignInToken.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (customSignInToken) => customSignInToken.data,
  );
}

export namespace AuthAccountSelector {
  export const selectStatus = (state: RootState) =>
    state.auth.authAccount.status;
}
