import { Box } from '../../components';

export function PageContainer(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Box overflow="hidden" width="100%" flex flexDirection="column">
      {children}
    </Box>
  );
}

export function InnerPageContainer(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Box height="100%" flex overflow="hidden">
      {children}
    </Box>
  );
}
