import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';
import { PostDto } from 'dtos/student-dashboard/v1/post/post.dto';

import {
  CreatePostCommentParams,
  CreatePostForm,
  DeletePostCommentParams,
  EditPostCommentForm,
  EditPostForm,
  GetPostCommentsPageParams,
  GetPostsPageParams,
  Page,
  PartialPostComment,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getGeneralApiProblem } from '../../../api.utils';
import { createPostMapper, getPostCommentsMapper } from './posts.mappers';

export const createPost = async (
  postForm: CreatePostForm,
): Promise<GeneralApiResultWithData<PostDto>> => {
  const res = await apisauce.post<PostDto, any>(
    '/v1/posts',
    createPostMapper(postForm),
    { timeout: CUSTOM_TIMEOUTS.createPost },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('createPost', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updatePost = async (
  postForm: EditPostForm,
): Promise<GeneralApiResultWithData<PostDto>> => {
  const res = await apisauce.patch<PostDto, any>(
    `/v1/posts/${postForm.postKey}`,
    {
      message: postForm.message,
    },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('updatePost', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updatePostComment = async (
  postCommentForm: EditPostCommentForm,
): Promise<GeneralApiResultWithData<PartialPostComment>> => {
  const { postKey, postCommentKey, message } = postCommentForm;

  const res = await apisauce.patch<PartialPostComment, any>(
    `/v1/posts/${postKey}/comments/${postCommentKey}`,
    {
      message,
    },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('updatePostComment', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPosts = async (
  params: GetPostsPageParams,
): Promise<GeneralApiResultWithData<Page<PostDto>>> => {
  const res = await apisauce.get<Page<PostDto>, any>('/v1/posts', {
    ...params,
    targetType: params.targetType === 'all' ? undefined : params.targetType,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getPosts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPost = async (
  postKey: string,
): Promise<GeneralApiResultWithData<PostDto>> => {
  const res = await apisauce.get<PostDto, any>(`/v1/posts/${postKey}`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getPost', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deletePost = async (
  postKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<any, any>(`/v1/posts/${postKey}`);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getPostComments = async (
  params: GetPostCommentsPageParams,
): Promise<GeneralApiResultWithData<Page<PartialPostComment>>> => {
  const { postKey, ...rest } = getPostCommentsMapper(params);

  const res = await apisauce.get<Page<PartialPostComment>, any>(
    `/v1/posts/${postKey}/comments`,
    rest,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getPostComments', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deletePostComment = async (
  params: DeletePostCommentParams,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<any, any>(
    `/v1/posts/${params.postKey}/comments/${params.postCommentKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createPostComment = async (
  params: CreatePostCommentParams,
): Promise<GeneralApiResultWithData<PartialPostComment>> => {
  const { postKey, ...rest } = params;

  const res = await apisauce.post<PartialPostComment, any>(
    `/v1/posts/${postKey}/comments`,
    rest,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('createPostComment', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
