import React from 'react';

import { FlexPosition, isNumber } from '../../utils';
import { Box } from '../box';
import { Text } from '../text';
import { Styled } from './input-helper.styled';
import { InputHelperProps } from './input-helper.types';

function getFlexJustify(
  showLengthText: boolean,
  showHelperText: boolean,
): FlexPosition {
  if (showLengthText && showHelperText) {
    return 'between';
  }

  if (showLengthText && !showHelperText) {
    return 'end';
  }

  if (!showLengthText && showHelperText) {
    return 'start';
  }

  return 'between';
}

function _InputHelper(
  props: InputHelperProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    currentLength,
    disabled,
    hasError,
    helperText,
    helperTx,
    helperTxArgs = {},
    maxLength,
    ...rest
  } = props;

  const showLengthText =
    isNumber(currentLength) && isNumber(maxLength) && !disabled;
  const showHelperText = !!helperText || !!helperTx;

  return (
    <Box
      flex
      flexAlign="start"
      flexJustify={getFlexJustify(showLengthText, showHelperText)}
      gap={16}
      mt={4}
      pl={24}
      pr={16}
      ref={ref}
      {...rest}
    >
      {showHelperText && (
        <Text
          color={hasError ? 'inputHelperError' : 'inputHelper'}
          text={helperText}
          tx={helperTx}
          txArgs={helperTxArgs}
          variant="bodyMd"
        />
      )}

      {showLengthText && (
        <Styled.LengthText
          color={hasError ? 'inputHelperError' : 'inputHelper'}
          variant="bodyMd"
          whiteSpace="nowrap"
        >
          {currentLength} / {maxLength}
        </Styled.LengthText>
      )}
    </Box>
  );
}

export const InputHelper = React.forwardRef(_InputHelper);
