import { BoxProps } from '../box';
import { Text, TxProps } from '../text';
import { Styled } from './divider.styled';

interface DividerProps extends BoxProps, TxProps {}

export function Divider(props: DividerProps) {
  const { children, tx, text, txArgs, ...rest } = props;

  if (children || tx || text) {
    return (
      <Styled.Divider flex flexAlign="center" gap={16} {...rest}>
        <Styled.DividerSegment />

        {children || (
          <Text
            as="span"
            text={text}
            tx={tx}
            txArgs={txArgs}
            variant="bodySm"
            whiteSpace="nowrap"
          />
        )}

        <Styled.DividerSegment />
      </Styled.Divider>
    );
  }

  return (
    <Styled.Divider flex {...rest}>
      <Styled.DividerSegment />
      <Styled.DividerSegment />
    </Styled.Divider>
  );
}
