import styled, { css, keyframes } from 'styled-components';

import { Box } from '../box';

const placeholderAnimate = keyframes`
  0%{ background-position: -325px 0; }
  100%{ background-position: 325px 0; }
`;

const placeholderAnimateChart = keyframes`
  0%{ background-position: -1000px 0; }
  100%{ background-position: 1000px 0; }
`;

const placeholderAnimation = css`
  ${(props) => {
    const { theme } = props;

    return css`
      overflow: hidden;
      animation-duration: 1.6s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ${placeholderAnimate};
      background: linear-gradient(
        to right,
        ${theme.colors.tablePlaceholderGradientFrom} 2%,
        ${theme.colors.tablePlaceholderGradientBetween} 18%,
        ${theme.colors.tablePlaceholderGradientTo} 33%
      );
      background-size: 1000px;

      background-color: ${theme.colors.tablePlaceholderBackground};
      z-index: 2;
      top: 10px;
      opacity: 0.5;
      border-radius: 8px;
    `;
  }}
`;

const PlaceholderBar = styled(Box)`
  ${placeholderAnimation};
`;

const PlaceholderChart = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      overflow: hidden;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ${placeholderAnimateChart};
      background: linear-gradient(
        to right,
        ${theme.colors.placeholderChartGradientFrom} 2%,
        ${theme.colors.placeholderChartGradientBetween} 6%,
        ${theme.colors.placeholderChartGradientTo} 15%
      );
      background-size: 1000px;

      z-index: 2;
      opacity: 0.5;

      width: 100%;
      height: 100%;

      border-left: 1px solid ${theme.colors.placeholderChartBorder};
      border-bottom: 1px solid ${theme.colors.placeholderChartBorder};

      path {
        stroke: ${theme.colors.placeholderChartLine};
      }
    `;
  }}
`;

const PlaceholderBarLabelDot = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      ${placeholderAnimation};

      width: 12px;
      height: 12px;
      background-color: ${theme.colors.placeholderChartLabel};
      border-radius: 50%;
    `;
  }}
`;

export const Styled = {
  PlaceholderBar,
  PlaceholderChart,
  PlaceholderBarLabelDot,
};
