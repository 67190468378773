import styled, { css } from 'styled-components';

import { Box } from '../box';
import { ChipProps, ChipVariant, GetChipColorsReturn } from './chip.types';

function getChipColors(variant: ChipVariant): GetChipColorsReturn {
  switch (variant) {
    case 1:
      return {
        backgroundColor: 'chip1Background',
        borderColor: 'chip1Border',
        labelColor: 'chip1Label',
      };

    case 2:
      return {
        backgroundColor: 'chip2Background',
        borderColor: 'chip2Border',
        labelColor: 'chip2Label',
      };

    case 3:
      return {
        backgroundColor: 'chip3Background',
        borderColor: 'chip3Border',
        labelColor: 'chip3Label',
      };

    case 4:
      return {
        backgroundColor: 'chip4Background',
        borderColor: 'chip4Border',
        labelColor: 'chip4Label',
      };

    case 5:
      return {
        backgroundColor: 'chip5Background',
        borderColor: 'chip5Border',
        labelColor: 'chip5Label',
      };

    case 6:
      return {
        backgroundColor: 'chip6Background',
        borderColor: 'chip6Border',
        labelColor: 'chip6Label',
      };

    case 7:
      return {
        backgroundColor: 'chip7Background',
        borderColor: 'chip7Border',
        labelColor: 'chip7Label',
      };

    case 8:
      return {
        backgroundColor: 'chip8Background',
        borderColor: 'chip8Border',
        labelColor: 'chip8Label',
      };

    case 9:
      return {
        backgroundColor: 'chip9Background',
        borderColor: 'chip9Border',
        labelColor: 'chip9Label',
      };

    case 10:
      return {
        backgroundColor: 'chip10Background',
        borderColor: 'chip10Border',
        labelColor: 'chip10Label',
      };
  }
}

export const Chip = styled(Box)<Partial<ChipProps>>`
  ${(props) => {
    const { theme, variant = 1, disabled } = props;

    const chipColors = getChipColors(variant);

    return css`
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;

      background-color: ${theme.colors[chipColors.backgroundColor]};
      border: 1px solid ${theme.colors[chipColors.borderColor]};
      color: ${theme.colors[chipColors.labelColor]};

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    `;
  }}
`;

export const Styled = { Chip };
