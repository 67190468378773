import styled, { css } from 'styled-components';

import { Box } from '../../components';

const InnerContentContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      overflow-y: auto;
      overflow-x: hidden;
      padding: 16px;
      gap: 16px;

      @media (min-width: ${theme.breakpoints.lg}px) {
        padding: 32px;
        gap: 32px;
      }
    `;
  }}
`;

const InnerContentContainerAlt = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-gutter: stable;
`;

export const Styled = {
  InnerContentContainer,
  InnerContentContainerAlt,
};
