import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  MembershipApplicationListItem,
  MembershipInviteListItem,
  MembershipMemberListItem,
  MembershipPurchaseListItem,
  MembershipTypeListItem,
} from '../../models';

export const membershipTypeListItemsAdapter = createEntityAdapter<
  MembershipTypeListItem,
  string
>({
  selectId: (membershipType) => membershipType.membershipTypeKey,
});

export const membershipApplicationsAdapter = createEntityAdapter<
  MembershipApplicationListItem,
  string
>({
  selectId: (application) => application.membershipApplicationKey,
});

export const membershipInvitesAdapater = createEntityAdapter<
  MembershipInviteListItem,
  string
>({
  selectId: (invite) => invite.membershipApplicationKey,
});

export const membershipMembersAdapter = createEntityAdapter<
  MembershipMemberListItem,
  string
>({
  selectId: (member) => member.userKey,
});

export const membershipPurchaseListItemsAdapter = createEntityAdapter<
  MembershipPurchaseListItem,
  string
>({
  selectId: (purchase) => purchase.membershipPurchaseKey,
});
