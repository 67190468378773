import { Box, Icon, Text, formatDate } from '@orbiapp/components';
import React from 'react';

import { ShrodingersTicket } from '../../../../../../models';
import { ScanResultCardHeader } from '../scan-result-card-header';
import { ScanResultMemberships } from '../scan-result-memberships';
import { UndoScanButton } from '../undo-scan-button';

export function AlreadyConsumed(props: ShrodingersTicket) {
  const { activityTitle, claimedAt, requiredMemberships, ticketHolder } = props;

  return (
    <React.Fragment>
      <Box flex flexDirection="column" gap={16}>
        <ScanResultCardHeader status="alreadyConsumed" />

        <Box flex flexDirection="column" gap={16}>
          {ticketHolder && (
            <Box flex gap={16} flexAlign="center">
              <Icon name="user-circle-outline" />
              <Box>
                <Text variant="bodyMd" text={ticketHolder.name} />
                <Text variant="bodySm" text={ticketHolder.email} />
              </Box>
            </Box>
          )}

          <Box flex gap={16} flexAlign="center">
            <Icon name="ticket-outline" />
            <Box>
              <Text variant="bodyMd" text={activityTitle} />
              <Text
                variant="bodySm"
                txArgs={{
                  claimedAt: formatDate(claimedAt, 'DD MMM YYYY HH:mm'),
                }}
                tx="label.scan-qr-codes.scan-result-card.claimed-at"
              />
            </Box>
          </Box>

          <ScanResultMemberships
            requiredMemberships={requiredMemberships}
            ticketHolder={ticketHolder}
          />
        </Box>
      </Box>

      <UndoScanButton />
    </React.Fragment>
  );
}
