import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const uploadFileThunk = createAsyncThunk<string, File, ThunkApiConfig>(
  'file-uploads/upload',
  async (file, thunkAPI) => {
    const res = await OrbiApi.call(v1.fileUploads.uploadFile, file);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const deleteFileUploadThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('file-uploads/delete', async (fileUploadKey, thunkAPI) => {
  const res = await OrbiApi.call(v1.fileUploads.deleteFileUpload, [
    fileUploadKey,
  ]);

  if (res.kind === 'ok') {
    return;
  }

  return thunkAPI.rejectWithValue(res);
});
