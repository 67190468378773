import { createAsyncThunk } from '@reduxjs/toolkit';

import '../../models';
import { PartialOrgNode } from '../../models';
import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const getCountriesThunk = createAsyncThunk<
  PartialOrgNode[],
  undefined,
  ThunkApiConfig
>('countries/get-countries', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.countries.getCountries, undefined);

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }

  return res.data;
});
