import { Box } from '@orbiapp/components';
import styled from 'styled-components';

const SummaryListItemGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 120px);
  gap: 16px;
`;

export const Styled = { SummaryListItemGrid };
