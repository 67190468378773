import { Link } from '../link';
import { Text } from '../text';
import { Styled } from './tab-button.styled';
import { TabButtonProps } from './tab-button.types';

export function TabButton(props: TabButtonProps) {
  const { disabled, isActive, text, tx, txArgs, isError, to, onClick } = props;

  const tabButton = (
    <Styled.TabButton
      disabled={disabled}
      isActive={isActive}
      isError={isError}
      onClick={onClick}
      to={to}
    >
      <Text
        text={text}
        tx={tx}
        txArgs={txArgs}
        variant="bodySm"
        whiteSpace="nowrap"
      />
    </Styled.TabButton>
  );

  if (to && !disabled) {
    return (
      <Link plain to={to}>
        {tabButton}
      </Link>
    );
  }

  return tabButton;
}
