import {
  Box,
  Button,
  ContentContainer,
  IconButton,
  Image,
  InnerContentContainer,
  List,
  ListItem,
  Text,
  Tooltip,
  capitalizeFirstLetter,
  constructBase64,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { CreateIntegratedMembershipTypeForm } from '../../../../../models';
import {
  CreateIntegratedMembershipTypeSelector,
  createIntegratedMembershipTypeThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { CreateIntegratedMembershipFormNavBlocker } from '../components';

export function CreateIntegratedMembershipSummary() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const createIntegratedMembershipTypeFormContext =
    useFormContext<CreateIntegratedMembershipTypeForm>();

  const createIntegratedMembershipTypeStatus = useSelector(
    CreateIntegratedMembershipTypeSelector.selectStatus,
  );

  const [contactDetails, description, logo, name, source] =
    createIntegratedMembershipTypeFormContext.getValues([
      'contactDetails',
      'description',
      'logo',
      'name',
      'metadata.source',
    ]);

  if (!createIntegratedMembershipTypeFormContext.formState.isDirty) {
    return <Navigate to="/memberships/create-integration/select-service" />;
  }

  const handlePrevious = () => {
    navigate('/memberships/create-integration/general-info');
  };

  const createIntegratedMembershipType = async () => {
    const res = await dispatch(
      createIntegratedMembershipTypeThunk(
        createIntegratedMembershipTypeFormContext.getValues(),
      ),
    );

    if (typeof res.payload === 'string') {
      navigate(`/memberships/${res.payload}/overview`);
    }
  };

  return (
    <React.Fragment>
      <CreateIntegratedMembershipFormNavBlocker shouldBlock />

      <ContentContainer>
        <InnerContentContainer>
          <Box flex flexJustify="between" flexWrap gap={16}>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.create-membership.tabs.summary"
              variant="titleMd"
            />

            <Button
              isLoading={createIntegratedMembershipTypeStatus === 'pending'}
              onClick={createIntegratedMembershipType}
              tx="button.memberships.create-integration"
              variant="primary"
            />
          </Box>

          <Box flexWrap="wrap" flex gap={24}>
            <Image
              height={188}
              width={188}
              r="50%"
              src={constructBase64(logo)}
            />

            <List>
              <ListItem
                titleTx="label.memberships.create-membership.summary.external-service"
                subtitle={capitalizeFirstLetter(source)}
              />

              <ListItem
                titleTx="label.memberships.create-membership.summary.membership-name"
                subtitle={name}
              />

              <ListItem
                titleTx="label.memberships.create-membership.summary.description"
                subtitle={description}
              />

              <ListItem
                titleTx="label.memberships.create-membership.summary.name"
                subtitle={contactDetails.name}
              />

              <ListItem
                titleTx="label.memberships.create-membership.summary.email"
                subtitle={contactDetails.email}
              />

              <ListItem
                titleTx="label.memberships.create-membership.summary.phone"
                subtitle={
                  contactDetails.phone ||
                  translate(
                    'label.memberships.create-membership.summary.no-phone',
                  )
                }
              />
            </List>
          </Box>
        </InnerContentContainer>

        <Box p={32} flex flexJustify="between" gap={16}>
          <Tooltip titleTx="button.previous" placement="right">
            <IconButton
              icon="arrow-left-circle-outline"
              onClick={handlePrevious}
            />
          </Tooltip>

          <Tooltip titleTx="button.continue" placement="left">
            <IconButton icon="arrow-right-circle-outline" disabled />
          </Tooltip>
        </Box>
      </ContentContainer>
    </React.Fragment>
  );
}
