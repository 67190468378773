import { Box } from '../box';
import { Button } from '../button';
import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './empty-state.styled';
import { EmptyStateProps } from './empty-state.types';

export function EmptyState(props: EmptyStateProps) {
  const {
    buttonIcon,
    buttonOnClick,
    buttonText,
    buttonTx,
    title,
    titleTx,
    titleTxArgs,
    to,
    children,
    href,
    ...rest
  } = props;

  return (
    <Styled.EmptyState
      flex
      flexAlign="center"
      flexDirection="column"
      flexJustify="center"
      gap={16}
      r={8}
      {...rest}
    >
      {children || (
        <Text
          textAlign="center"
          variant="bodyMd"
          text={title}
          tx={titleTx}
          txArgs={titleTxArgs}
        />
      )}

      {(buttonOnClick || to || href) && (
        <Button href={href} onClick={buttonOnClick} to={to} variant="secondary">
          <Box flex flexAlign="center" gap={8}>
            {buttonIcon && <Icon name={buttonIcon} />}
            <Text variant="buttonSm" text={buttonText} tx={buttonTx} />
          </Box>
        </Button>
      )}
    </Styled.EmptyState>
  );
}
