import React from 'react';

import { Box, BoxProps } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './callout.styled';
import { CalloutProps, GetCalloutColorsReturn } from './callout.types';

function getCalloutColors(
  variant: CalloutProps['variant'],
): GetCalloutColorsReturn {
  switch (variant) {
    case 'error':
      return {
        icon: 'exclamation-circle-solid',
        iconColor: 'calloutErrorIcon',
        backgroundColor: 'calloutErrorBackground',
        borderColor: 'calloutErrorBorder',
        subtitleColor: 'calloutErrorSubtitle',
        titleColor: 'calloutErrorTitle',
      };

    case 'info':
      return {
        icon: 'question-mark-circle-solid',
        iconColor: 'calloutInfoIcon',
        backgroundColor: 'calloutInfoBackground',
        borderColor: 'calloutInfoBorder',
        subtitleColor: 'calloutInfoSubtitle',
        titleColor: 'calloutInfoTitle',
      };

    case 'success':
      return {
        icon: 'check-circle-solid',
        iconColor: 'calloutSuccessIcon',
        backgroundColor: 'calloutSuccessBackground',
        borderColor: 'calloutSuccessBorder',
        subtitleColor: 'calloutSuccessSubtitle',
        titleColor: 'calloutSuccessTitle',
      };

    case 'warning':
      return {
        icon: 'exclamation-triangle-solid',
        iconColor: 'calloutWarningIcon',
        backgroundColor: 'calloutWarningBackground',
        borderColor: 'calloutWarningBorder',
        subtitleColor: 'calloutWarningSubtitle',
        titleColor: 'calloutWarningTitle',
      };
  }
}

export function Callout(props: CalloutProps & BoxProps) {
  const {
    variant,

    title,
    titleTx,
    titleTxArgs,

    subtitle,
    subtitleTx,
    subtitleTxArgs,

    ...rest
  } = props;

  const colors = getCalloutColors(variant);

  return (
    <Styled.Callout
      backgroundColor={colors.backgroundColor}
      borderColor={colors.borderColor}
      flex
      gap={8}
      p={8}
      r={4}
      {...rest}
    >
      <Icon color={colors.iconColor} name={colors.icon} size={22} />

      <Box>
        {(title || titleTx) && (
          <Text
            color={colors.titleColor}
            text={title}
            tx={titleTx}
            txArgs={titleTxArgs}
            variant="bodySmBold"
          />
        )}

        {(subtitle || subtitleTx) &&
          (React.isValidElement(subtitle) ? (
            subtitle
          ) : (
            <Text
              color={colors.subtitleColor}
              text={subtitle as string | undefined}
              tx={subtitleTx}
              txArgs={subtitleTxArgs}
              variant="bodySm"
            />
          ))}
      </Box>
    </Styled.Callout>
  );
}
