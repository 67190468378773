import { breakpoints } from '@orbiapp/theme';
import React from 'react';

import { Box } from '../box';
import { IconButton } from '../icon-button';
import { Link } from '../link';
import { Tooltip } from '../tooltip';
import { Styled } from './sidebar.styled';
import {
  SidebarItemProps,
  SidebarMessageProps,
  SidebarProps,
  SidebarProviderProps,
} from './sidebar.types';

export const SidebarContext = React.createContext({
  closeSidebar: () => {},
  collapsed: false,
  openSidebar: () => {},
  toggleSidebar: () => {},
});

export function SidebarProvider(props: SidebarProviderProps) {
  const { children } = props;

  const [collapsed, setCollapsed] = React.useState(
    window.innerWidth < breakpoints.xs,
  );

  const closeSidebar = React.useCallback(() => setCollapsed(true), []);
  const openSidebar = React.useCallback(() => setCollapsed(false), []);
  const toggleSidebar = React.useCallback(
    () => setCollapsed((collapsed) => !collapsed),
    [],
  );

  const value = {
    closeSidebar,
    collapsed,
    openSidebar,
    toggleSidebar,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}

export function SidebarItem(props: SidebarItemProps) {
  const {
    hasNotification,
    href,
    icon,
    iconActive = icon,
    isActive = false,
    text,
    to,
    tooltip,
    tooltipPlacement = 'right',
    tooltipTx,
    tooltipTxArgs,
    tx,
    children,
    ...rest
  } = props;

  const sidebarContext = React.useContext(SidebarContext);

  if (!sidebarContext) {
    throw new Error('SidebarItem must be used within a Sidebar');
  }

  const sidebarItemContent = (
    <Styled.SidebarItem
      collapsed={sidebarContext.collapsed}
      cursor="pointer"
      flex
      flexAlign="center"
      gap={8}
      isActive={isActive}
      r={4}
      relative
      role={!to && !href ? 'menuitem' : undefined}
      {...rest}
    >
      <Styled.SidebarItemIcon
        minWidth={24}
        minHeight={24}
        name={isActive ? iconActive : icon}
      />

      {!sidebarContext.collapsed && (
        <Box flex flexAlign="center" width="100%">
          <Styled.SidebarItemLabel
            overflow="hidden"
            text={text}
            tx={tx}
            whiteSpace="nowrap"
          />
          {children}
        </Box>
      )}

      {hasNotification && (
        <Styled.SidebarItemNotificationIndicator
          collapsed={sidebarContext.collapsed}
        />
      )}
    </Styled.SidebarItem>
  );

  const sidebarItem =
    (tooltip || tooltipTx) && sidebarContext.collapsed ? (
      <Tooltip placement={tooltipPlacement} title={tooltip} titleTx={tooltipTx}>
        {sidebarItemContent}
      </Tooltip>
    ) : (
      sidebarItemContent
    );

  if (to) {
    return (
      <Link plain role="menuitem" to={to}>
        {sidebarItem}
      </Link>
    );
  }

  if (href) {
    return (
      <a role="menuitem" href={href}>
        {sidebarItem}
      </a>
    );
  }

  return sidebarItem;
}

export function SidebarMessage(props: SidebarMessageProps) {
  const {
    hasNotification,
    href,
    icon,
    iconActive = icon,
    isActive = false,
    label,
    to,
    tooltip,
    tooltipPlacement = 'right',
    tooltipTx,
    tooltipTxArgs,
    children,
    ...rest
  } = props;

  const sidebarContext = React.useContext(SidebarContext);

  if (!sidebarContext) {
    throw new Error('SidebarMessage must be used within a Sidebar');
  }

  const sidebarMessageContent = (
    <Styled.SidebarMessage
      collapsed={sidebarContext.collapsed}
      cursor="pointer"
      flex
      gap={8}
      isActive={isActive}
      r={8}
      relative
      flexDirection="column"
      {...rest}
    >
      <Box flex flexAlign="center">
        <Box flex gap={8} flexAlign="center" flexJustify="between" width="100%">
          <Styled.SidebarItemIcon name={isActive ? iconActive : icon} />

          {label && !sidebarContext.collapsed && (
            <Styled.SidebarMessageLabel
              variant="bodyXs"
              px={4}
              py={2}
              r={3}
              tx={label.tx}
              text={label.text}
              txArgs={label.txArgs}
              mb="auto"
            />
          )}
        </Box>

        {hasNotification && (
          <Styled.SidebarItemNotificationIndicator
            collapsed={sidebarContext.collapsed}
          />
        )}
      </Box>

      {!sidebarContext.collapsed && children}
    </Styled.SidebarMessage>
  );

  const sidebarMessage =
    (tooltip || tooltipTx) && sidebarContext.collapsed ? (
      <Tooltip placement={tooltipPlacement} title={tooltip} titleTx={tooltipTx}>
        {sidebarMessageContent}
      </Tooltip>
    ) : (
      sidebarMessageContent
    );

  if (to) {
    return (
      <Link plain to={to}>
        {sidebarMessage}
      </Link>
    );
  }

  if (href) {
    return <a href={href}>{sidebarMessage}</a>;
  }

  return sidebarMessage;
}

function ToggleSidebarCollapsedButton() {
  const sidebarContext = React.useContext(SidebarContext);

  return (
    <Styled.ToggleSidebarCollapsedButton
      absolute
      onClick={sidebarContext.toggleSidebar}
      right={-20}
      zIndex={10}
    >
      <IconButton
        iconSize={20}
        icon={sidebarContext.collapsed ? 'chevron-right' : 'chevron-left'}
      />
    </Styled.ToggleSidebarCollapsedButton>
  );
}

function SidebarBackdrop(props: { zIndex?: number }) {
  const sidebarContext = React.useContext(SidebarContext);

  return (
    <Styled.SidebarBackdrop
      collapsed={sidebarContext.collapsed}
      onClick={sidebarContext.toggleSidebar}
      {...props}
    />
  );
}

export function Sidebar(props: SidebarProps) {
  const { children, backdropZIndex, ...rest } = props;

  const sidebarContext = React.useContext(SidebarContext);

  if (!sidebarContext) {
    throw new Error('Sidebar must be used within a SidebarProvider');
  }

  return (
    <React.Fragment>
      <SidebarBackdrop zIndex={backdropZIndex} />

      <Styled.Sidebar collapsed={sidebarContext.collapsed} {...rest}>
        <ToggleSidebarCollapsedButton />

        <Styled.SidebarContent
          flex
          flexDirection="column"
          gap={16}
          relative
          role="menu"
        >
          {children}
        </Styled.SidebarContent>
      </Styled.Sidebar>
    </React.Fragment>
  );
}
