import * as Sentry from '@sentry/react';
import { FirebaseError, initializeApp } from 'firebase/app';
import { User } from 'firebase/auth';
import * as Firebase from 'firebase/auth';

import { firebaseConfig } from '../../config';
import { authActions, store } from '../../store';

export const firebase = initializeApp(firebaseConfig);

export const signIn = async (email: string, password: string) => {
  try {
    const res = await Firebase.signInWithEmailAndPassword(
      Firebase.getAuth(firebase),
      email,
      password,
    );

    return res;
  } catch (err) {
    if (err instanceof FirebaseError) {
      return err;
    }

    return new FirebaseError('auth/unknown', '');
  }
};

export const signInWithCustomToken = async (customToken: string) => {
  try {
    const res = await Firebase.signInWithCustomToken(
      Firebase.getAuth(firebase),
      customToken,
    );

    return res;
  } catch (err) {
    if (err instanceof FirebaseError) {
      return err;
    }

    return new FirebaseError('auth/unknown', '');
  }
};

export async function onAuthStateChanged(user: User | null) {
  if (!user) {
    Sentry.setUser(null);

    store.dispatch(
      authActions.setAuthState({
        authenticated: false,
        initializing: false,
        accountVerified: false,
      }),
    );

    return;
  }

  Sentry.setUser({
    email: user.email ?? undefined,
    username: user.displayName ?? undefined,
    id: user.uid,
  });

  store.dispatch(
    authActions.setAuthState({
      authenticated: true,
      initializing: false,
      accountVerified: user.emailVerified,
    }),
  );
}
