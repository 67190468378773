import { getReferralsThunk } from './referrals.actions';
import { ReferralsBuilder } from './referrals.types';

const getReferralsBuild = (builder: ReferralsBuilder) => {
  return builder
    .addCase(getReferralsThunk.fulfilled, (state, action) => {
      state.referrals.status = 'idle';
      state.referrals.data = action.payload;
    })
    .addCase(getReferralsThunk.pending, (state) => {
      state.referrals.status = 'pending';
      state.referrals.error = null;
    })
    .addCase(getReferralsThunk.rejected, (state, action) => {
      state.referrals.status = 'idle';
      state.referrals.error = action.error;
    });
};

export const extraReducers = (builder: ReferralsBuilder) => {
  getReferralsBuild(builder);
};
