import Joi from 'joi';

import { DepartmentForm } from './department.model';
import { DepartmentSchema } from './department.schema';

export const DepartmentSettingsValidation = Joi.object<DepartmentForm>({
  about: DepartmentSchema.about,
  coverImage: DepartmentSchema.coverImage,
  email: DepartmentSchema.email,
  facebookUrl: DepartmentSchema.facebookUrl,
  instagramUrl: DepartmentSchema.instagramUrl,
  logo: DepartmentSchema.logo,
  name: DepartmentSchema.name,
  phone: DepartmentSchema.phone,
  twitterUrl: DepartmentSchema.twitterUrl,
  websiteUrl: DepartmentSchema.websiteUrl,
  youtubeUrl: DepartmentSchema.youtubeUrl,
});
