import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { ActivityDraft } from '../../../../../../models';
import { activityDraftCache } from '../../../../../cache';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { V1GetActivityDraft, V2GetActivityDraft } from './draft.dto';
import { V1DraftMappers, V2DraftMappers } from './draft.mappers';

const threshold = 1691744596578;

export const getActivityDrafts = async (params: {
  useBackendStorage: boolean;
}): Promise<GeneralApiResultWithData<ActivityDraft[]>> => {
  let drafts: Array<
    V1GetActivityDraft.ActivityDraftDto | V2GetActivityDraft.ActivityDraftDto
  > | null;

  if (params.useBackendStorage) {
    const res = await apisauce.get<
      Array<V2GetActivityDraft.ActivityDraftDto>,
      any
    >('/v1/activities/drafts');

    if (!res.ok) {
      return getGeneralApiProblem(res);
    }

    drafts = res.data ?? null;
  } else {
    drafts = await activityDraftCache.getAll<
      V1GetActivityDraft.ActivityDraftDto | V2GetActivityDraft.ActivityDraftDto
    >();
  }

  if (!drafts) {
    Logger.error('getActivityDrafts', {
      err: 'Failed to get activity drafts',
    });
    return { kind: 'ok', data: [] };
  }

  return {
    kind: 'ok',
    data: drafts
      .map((draft) =>
        draft.lastUpdated > threshold
          ? V2DraftMappers.getActivityDraftMapper(
              draft as V2GetActivityDraft.ActivityDraftDto,
            )
          : V1DraftMappers.getActivityDraftMapper(
              draft as V1GetActivityDraft.ActivityDraftDto,
            ),
      )
      .sort((a, b) => b.lastUpdated - a.lastUpdated),
  };
};

export const getActivityDraft = async (params: {
  draftId: string;
  useBackendStorage: boolean;
}): Promise<GeneralApiResultWithData<ActivityDraft>> => {
  let draftDto:
    | V1GetActivityDraft.ActivityDraftDto
    | V2GetActivityDraft.ActivityDraftDto
    | null;
  if (params.useBackendStorage) {
    const res = await apisauce.get<V2GetActivityDraft.ActivityDraftDto, any>(
      '/v1/activities/drafts/' + params.draftId,
    );

    if (!res.ok) {
      return getGeneralApiProblem(res);
    }

    draftDto = res.data ?? null;
  } else {
    draftDto = await activityDraftCache.get<
      V1GetActivityDraft.ActivityDraftDto | V2GetActivityDraft.ActivityDraftDto
    >(params.draftId);
  }

  if (!draftDto) {
    Logger.error('getActivityDraft', {
      err: 'Failed to get activity draft',
    });
    return { kind: 'bad-request' };
  }

  const draft: ActivityDraft =
    draftDto.lastUpdated > threshold
      ? V2DraftMappers.getActivityDraftMapper(
          draftDto as V2GetActivityDraft.ActivityDraftDto,
        )
      : V1DraftMappers.getActivityDraftMapper(
          draftDto as V1GetActivityDraft.ActivityDraftDto,
        );

  return { kind: 'ok', data: draft };
};

export const deleteActivityDraft = async (params: {
  key: string;
  useBackendStorage: boolean;
}): Promise<GeneralApiResult> => {
  if (params.useBackendStorage) {
    const res = await apisauce.delete<unknown, any>(
      '/v1/activities/drafts/' + params.key,
    );

    if (!res.ok) {
      return getGeneralApiProblem(res);
    }
  } else {
    const res = await activityDraftCache.remove(params.key);

    if (!res) {
      Logger.error('deleteActivityDraft', {
        err: 'Failed to delete activity draft',
      });
      return { kind: 'bad-request' };
    }
  }

  return { kind: 'ok' };
};

export const createActivityDraft = async (params: {
  activityDraft: ActivityDraft;
  useBackendStorage: boolean;
}): Promise<GeneralApiResult> => {
  const dto = V2DraftMappers.createActivityDraftMapper(params.activityDraft);

  if (params.useBackendStorage) {
    const res = await apisauce.put<unknown, any>(
      '/v1/activities/drafts/' + dto.key,
      dto,
    );

    if (!res.ok) {
      return getGeneralApiProblem(res);
    }
  } else {
    const res = await activityDraftCache.upsert(dto);

    if (!res) {
      Logger.error('createActivityDraft', {
        err: 'Failed to create activity draft',
      });
      return { kind: 'bad-request' };
    }
  }

  return { kind: 'ok' };
};
