import {
  Box,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  IconButton,
  Image,
  InnerContentContainer,
  Text,
  Tooltip,
  constructBase64,
  flattenFieldErrorsObject,
  formatDate,
  parseCurrency,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Navigate, useBlocker, useNavigate } from 'react-router-dom';

import {
  CreateMembershipTypeForm,
  MembershipApplicationRequirement,
  MembershipPeriodForm,
  MembershipTypeQuestionForm,
  MembershipTypeType,
  RequestedInfoType,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CreateMembershipTypeSelector,
  OrbiPaySettingsSelector,
  createMembershipTypeThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  SummaryList,
  SummaryListItem,
  SummaryListItemGrid,
} from '../../components/summary-list';
import { Styled } from './summary.styled';

function getRequestedInfoTypeTx(type: RequestedInfoType): TxString {
  switch (type) {
    case RequestedInfoType.Checkbox:
      return 'label.memberships.create-membership.summary.requested-info.checkbox';
    case RequestedInfoType.FreeText:
      return 'label.memberships.create-membership.summary.requested-info.freetext';
    case RequestedInfoType.MultiChoice:
      return 'label.memberships.create-membership.summary.requested-info.multichoice';
    default:
      return 'label.memberships.create-membership.summary.requested-info.none-selected';
  }
}

function getMembershipTypeTx(type: MembershipTypeType | null): TxString {
  switch (type) {
    case 'standard':
      return 'label.memberships.create-membership.summary.standard';
    case 'invite_only':
      return 'label.memberships.create-membership.summary.invite-only';
    case 'needs_approval':
      return 'label.memberships.create-membership.summary.needs-approval';
    default:
      return 'label.memberships.create-membership.summary.none-selected';
  }
}

function SummaryMetadata() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const [
    createMembershipQuestions,
    description,
    logo,
    name,
    periods,
    requiredMembershipTypes,
    type,
  ] = createMembershipTypeFormContext.getValues([
    'createMembershipQuestions',
    'description',
    'logo',
    'name',
    'periods',
    'requiredMembershipTypes',
    'type',
  ]);

  return (
    <Box flexWrap="wrap" flex gap={32}>
      <Image height={188} r="50%" src={constructBase64(logo)} width={188} />

      <Box flexWrap="wrap" flex gap={16}>
        <SummaryList>
          <SummaryListItem
            subtitle={name}
            titleTx="label.memberships.create-membership.summary.membership-name"
          />

          <SummaryListItem titleTx="label.memberships.create-membership.summary.description">
            <Text maxWidth="65ch" text={description} variant="bodySm" />
          </SummaryListItem>
        </SummaryList>

        <SummaryList>
          <SummaryListItem titleTx="label.memberships.create-membership.summary.type">
            <Chip mt={4} variant={1} tx={getMembershipTypeTx(type)} />
          </SummaryListItem>

          {requiredMembershipTypes && (
            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.memberships-count"
              subtitle={
                requiredMembershipTypes.membershipApplicationRequirements.length
              }
            />
          )}

          {createMembershipQuestions && (
            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.questions-count"
              subtitle={createMembershipQuestions.length}
            />
          )}

          {periods && (
            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.period-count"
              subtitle={periods.length}
            />
          )}
        </SummaryList>
      </Box>
    </Box>
  );
}

function RequiredMemberships() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const requiredMembershipTypes = createMembershipTypeFormContext.getValues(
    'requiredMembershipTypes',
  );

  if (!requiredMembershipTypes?.membershipApplicationRequirements.length) {
    return null;
  }

  const renderGridItem = (membership: MembershipApplicationRequirement) => {
    return (
      <Styled.SummaryGridItem
        flex
        gap={16}
        key={membership.membershipTypeKey}
        p={16}
      >
        <Image height={40} r="50%" width={40} src={membership.logoUrl} />

        <SummaryListItem title={membership.name}>
          <Text
            maxWidth="46ch"
            variant="bodySm"
            text={membership.departmentName}
          />
        </SummaryListItem>
      </Styled.SummaryGridItem>
    );
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.create-membership.summary.required-memberships"
      />

      <Styled.SummaryGrid>
        {requiredMembershipTypes.membershipApplicationRequirements.map(
          renderGridItem,
        )}
      </Styled.SummaryGrid>
    </Box>
  );
}

function Questions() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const createMembershipQuestions = createMembershipTypeFormContext.getValues(
    'createMembershipQuestions',
  );

  if (!createMembershipQuestions) {
    return null;
  }

  const renderGridItem = (question: MembershipTypeQuestionForm) => {
    return (
      <Styled.SummaryGridItem
        flex
        flexDirection="column"
        gap={8}
        key={question.key}
        p={16}
      >
        <SummaryList>
          <SummaryListItem titleTx="label.memberships.create-membership.summary.requested-info.label">
            <Chip
              mt={4}
              variant={1}
              tx={getRequestedInfoTypeTx(question.type)}
            />
          </SummaryListItem>

          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.question"
            subtitle={question.question}
          />
        </SummaryList>
      </Styled.SummaryGridItem>
    );
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.create-membership.summary.questions"
      />

      <Styled.SummaryGrid>
        {createMembershipQuestions.map(renderGridItem)}
      </Styled.SummaryGrid>
    </Box>
  );
}

function Periods() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const periods = createMembershipTypeFormContext.getValues('periods');

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  if (!periods) {
    return null;
  }

  const renderGridItem = (period: MembershipPeriodForm) => {
    return (
      <Styled.SummaryGridItem
        flex
        flexDirection="column"
        gap={8}
        key={period.key}
        p={16}
      >
        <SummaryList>
          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.period-name"
            subtitle={period.name}
          />

          <SummaryListItemGrid>
            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.valid-from"
              subtitle={
                period.validFrom
                  ? formatDate(period.validFrom, 'DD MMM YYYY HH:mm')
                  : translate('label.memberships.update-period-modal.lifetime')
              }
            />

            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.valid-to"
              subtitle={
                period.validTo
                  ? formatDate(period.validTo, 'DD MMM YYYY HH:mm')
                  : translate('label.memberships.update-period-modal.lifetime')
              }
            />
          </SummaryListItemGrid>

          <SummaryListItemGrid>
            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.sale-start"
              subtitle={formatDate(period.saleStart, 'DD MMM YYYY HH:mm')}
            />

            <SummaryListItem
              titleTx="label.memberships.create-membership.summary.sale-end"
              subtitle={formatDate(period.saleEnd, 'DD MMM YYYY HH:mm')}
            />
          </SummaryListItemGrid>

          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.price"
            subtitle={parseCurrency(period.price, currency)}
          />
        </SummaryList>
      </Styled.SummaryGridItem>
    );
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.create-membership.summary.periods"
      />

      <Styled.SummaryGrid>{periods.map(renderGridItem)}</Styled.SummaryGrid>
    </Box>
  );
}

function SummaryContent() {
  return (
    <Box flex flexDirection="column" gap={32}>
      <SummaryMetadata />

      <RequiredMemberships />

      <Questions />

      <Periods />
    </Box>
  );
}

function CreateMembershipTypeFormNavBlocker() {
  const createdMembershipTypeKey = useSelector(
    CreateMembershipTypeSelector.selectData,
  );

  const blocker = useBlocker(true);

  const isCreateMembershipRoute = blocker.location?.pathname.includes(
    '/memberships/create-membership',
  );

  React.useEffect(() => {
    if (createdMembershipTypeKey || isCreateMembershipRoute) {
      blocker.proceed?.();
    }
  }, [createdMembershipTypeKey, blocker, isCreateMembershipRoute]);

  return (
    <Confirm
      isOpen={
        blocker.state === 'blocked' &&
        !isCreateMembershipRoute &&
        !createdMembershipTypeKey
      }
      onCancel={blocker.reset || (() => {})}
      onConfirm={blocker.proceed || (() => {})}
      cancelTx="prompt.unsaved-changes.cancel"
      confirmTx="prompt.unsaved-changes.confirm"
      titleTx="prompt.unsaved-changes.title"
      messageTx="prompt.unsaved-changes.message"
    />
  );
}

export function CreateMembershipSummary() {
  const createMembershipTypeStatus = useSelector(
    CreateMembershipTypeSelector.selectStatus,
  );

  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  if (!createMembershipTypeFormContext.formState.isDirty) {
    return <Navigate to="/memberships/create-membership/general-info" />;
  }

  const handlePrevious = () => {
    navigate('/memberships/create-membership/periods');
  };

  const createMembership = createMembershipTypeFormContext.handleSubmit(
    async (data) => {
      const res = await dispatch(createMembershipTypeThunk(data));

      navigate(
        typeof res.payload === 'string'
          ? `/memberships/${res.payload}/overview`
          : '/memberships',
      );
    },
    (err) => {
      Logger.warning('createMembership Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <React.Fragment>
      <CreateMembershipTypeFormNavBlocker />

      <ContentContainer>
        <InnerContentContainer>
          <Box flexJustify="between" flexWrap gap={16} flex>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.create-membership.tabs.summary"
              variant="titleMd"
            />

            <Button
              isLoading={createMembershipTypeStatus === 'pending'}
              onClick={createMembership}
              tx="button.memberships.create-membership"
              variant="primary"
            />
          </Box>

          <SummaryContent />
        </InnerContentContainer>

        <Box p={32} flex flexJustify="between" gap={16}>
          <Tooltip titleTx="button.previous" placement="right">
            <IconButton
              onClick={handlePrevious}
              icon="arrow-left-circle-outline"
            />
          </Tooltip>

          <Tooltip titleTx="button.continue" placement="left">
            <IconButton disabled icon="arrow-right-circle-outline" />
          </Tooltip>
        </Box>
      </ContentContainer>
    </React.Fragment>
  );
}
