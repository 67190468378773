import { joiResolver } from '@hookform/resolvers/joi';
import { Confirm, Modal, useConfirm } from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { CreatePostForm, CreatePostValidation } from '../../models';
import {
  CreatePostSelector,
  createPostThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { QuickActionsContext } from '../quick-actions';
import { ActivityPostForm } from './activity-post-form';
import { ModalContentProps } from './create-post-modal.types';
import { DepartmentPostForm } from './department-post-form';
import { MembershipPostForm } from './membership-post-form';
import { PickTargetType } from './pick-target-type';

function ModalContent(props: ModalContentProps) {
  const createPostStatus = useSelector(CreatePostSelector.selectStatus);

  const dispatch = useDispatch();

  const formContext = useFormContext<CreatePostForm>();

  const targetType = formContext.watch('target.type');

  const isLoading = createPostStatus === 'pending';

  const createPost = formContext.handleSubmit(async (data) => {
    const res = await dispatch(createPostThunk(data));

    if (res.meta.requestStatus === 'fulfilled') {
      props.closeAndReset();
    }
  });

  switch (targetType) {
    case 'activity':
      return (
        <ActivityPostForm
          createPost={createPost}
          closeAndReset={props.closeAndReset}
          isLoading={isLoading}
        />
      );

    case 'department':
      return (
        <DepartmentPostForm
          createPost={createPost}
          closeAndReset={props.closeAndReset}
          isLoading={isLoading}
        />
      );

    case 'membershipType':
      return (
        <MembershipPostForm
          createPost={createPost}
          closeAndReset={props.closeAndReset}
          isLoading={isLoading}
        />
      );

    default:
      return <PickTargetType />;
  }
}

export function CreatePostModal() {
  const { createPostModalState } = React.useContext(QuickActionsContext);

  const defaultValues: CreatePostForm = React.useMemo(
    () => ({
      fileUploads: [],
      message: '',
      pushTo: null,
      target: createPostModalState.value ?? {
        key: '',
        type: undefined,
      },
    }),
    [createPostModalState.value],
  );

  const confirm = useConfirm();

  const formMethods = useForm<CreatePostForm>({
    resolver: joiResolver(CreatePostValidation),
    defaultValues,
  });

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  const closeAndReset = () => {
    createPostModalState.closeModal();
    confirm.closeConfirm();

    window.setTimeout(() => {
      formMethods.reset();
    }, transitonDuration.default);
  };

  const closeWithConfirm = () => {
    const isDirty = Object.values(formMethods.formState.dirtyFields).some(
      (isDirty) => isDirty,
    );

    if (!isDirty) {
      closeAndReset();
      return;
    }

    confirm.openConfirm();
  };

  const targetType = formMethods.watch('target.type');

  return (
    <React.Fragment>
      <Confirm
        isOpen={confirm.isOpen}
        onCancel={confirm.closeConfirm}
        onConfirm={closeAndReset}
        titleTx="prompt.discard-post-form.title"
        messageTx="prompt.discard-post-form.message"
        confirmTx="prompt.discard-post-form.confirm"
        cancelTx="prompt.discard-post-form.cancel"
        zIndex={1901}
      />

      <Modal
        width={!targetType ? 500 : 850}
        isOpen={createPostModalState.isOpen}
        onClose={closeWithConfirm}
        hideCloseButton
        zIndex={1900}
      >
        <FormProvider {...formMethods}>
          <ModalContent closeAndReset={closeAndReset} />
        </FormProvider>
      </Modal>
    </React.Fragment>
  );
}
