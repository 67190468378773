import { Box, BoxProps, Image } from '@orbiapp/components';
import { ForwardedRef, forwardRef } from 'react';

import { Styled } from './membership-card.styled';

const Wordmark = () => {
  return (
    <Box flex flexJustify="end">
      <Image height={29} src="/assets/SVG_orbi-logo-white.svg" />
    </Box>
  );
};

interface MembershipCardProps extends BoxProps {
  backgroundImageUrl: string | null;
  hideWordmark?: boolean;
  logoUrl: string | null;
  name: string | null;
}

export const MembershipCard = forwardRef(
  (props: MembershipCardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { backgroundImageUrl, hideWordmark, logoUrl, name, ...rest } = props;

    return (
      <Styled.MembershipCard
        flex
        flexDirection="column"
        flexJustify="between"
        ref={ref}
        src={backgroundImageUrl ?? ''}
        {...rest}
      >
        <Box flex flexAlign="center" gap={8}>
          <Styled.MembershipCardLogo src={logoUrl ?? ''} />

          <Styled.MembershipCardName
            maxWidth="28ch"
            text={name ?? ''}
            variant="bodyMdBold"
            overflow="hidden"
            overflowWrap="break-word"
          />
        </Box>

        {!hideWordmark && <Wordmark />}
      </Styled.MembershipCard>
    );
  },
);
