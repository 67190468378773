import { Base64File, ImageData } from '@orbiapp/components';

import { ContactDetails } from '../contact-details';
import { IntegratedMembershipType } from './integrated';
import {
  MembershipMemberListItemPeriod,
  MembershipPeriodForm,
  MembershipTypePeriod,
} from './period';
import {
  MembershipTypeQuestion,
  MembershipTypeQuestionForm,
} from './questions';
import {
  CreateMembershipApplicationRequirements,
  MembershipApplicationRequirements,
} from './required-memberships';

const membershipTypeSources = ['orbi', 'montania'] as const;

export type MembershipTypeSource = (typeof membershipTypeSources)[number];

export const membershipTypeTypes = [
  'standard',
  'needs_approval',
  'invite_only',
] as const;
export type MembershipTypeType = (typeof membershipTypeTypes)[number];

export interface GeneralInfoForm {
  backgroundImage: Base64File | null;
  contactDetails: ContactDetails;
  description: string;
  logo: Base64File | null;
  name: string;
}

export interface SettingsForm {
  requiredMembershipTypes: CreateMembershipApplicationRequirements | null;
  type: MembershipTypeType;
}

export interface QuestionsForm {
  enabled: boolean;
  createMembershipQuestions: MembershipTypeQuestionForm[] | null;
}

export interface PeriodsForm {
  periods: MembershipPeriodForm[] | null;
}

export interface CreateMembershipTypeForm
  extends GeneralInfoForm,
    SettingsForm,
    QuestionsForm,
    PeriodsForm {}

export interface MembershipTypeListItem {
  createdAt: number;
  logo: ImageData;
  logoUrl: string;
  membershipTypeKey: string;
  name: string;
  pendingApplicationCount: number;
  source: MembershipTypeSource;
  totalMemberCount: number;
  type: MembershipTypeType;
}

export interface MembershipType {
  backgroundImage: ImageData | null;
  contactDetails: ContactDetails;
  description: string;
  logo: ImageData;
  membershipQuestions: MembershipTypeQuestion[] | null;
  membershipTypeKey: string;
  name: string;
  pendingApplicationCount: number;
  pendingInvitationCount: number;
  periods: MembershipTypePeriod[] | null;
  requiredMembershipTypes: MembershipApplicationRequirements | null;
  type: MembershipTypeType;
}

export type MembershipTypeWrapper =
  | {
      type: 'default';
      membershipType: MembershipType;
    }
  | {
      type: 'integration';
      membershipType: IntegratedMembershipType;
    };

export interface MembershipMemberListItem {
  email: string;
  fullName: string;
  periods: MembershipMemberListItemPeriod[];
  profilePicture: ImageData | null;
  userKey: string;
}

export interface UpdateMembershipTypeGeneralInfoResponse {
  backgroundImage: ImageData;
  logo: ImageData;
}

export interface PartialMembershipType {
  name: string;
  logo: ImageData;
  departmentName: string;
  membershipTypeKey: string;
}
