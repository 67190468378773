import {
  CreateMembershipApplicationRequirementsResult,
  MembershipApplicationRequirement,
} from '../../../../../../models';
import { CreateMembershipApplicationRequirementsResultDto } from './membership-application-requirements.dto';

export const createMembershipApplicationRequirementsMapper = (
  membershipApplicationRequirements: MembershipApplicationRequirement[],
) => ({
  membershipTypeKeys: membershipApplicationRequirements.map(
    (membershipApplicationRequirements) =>
      membershipApplicationRequirements.membershipTypeKey,
  ),
});

export const createMembershipApplicationRequirementsResultMapper = (
  createMembershipApplicationRequirementsResultDto: CreateMembershipApplicationRequirementsResultDto,
): CreateMembershipApplicationRequirementsResult => {
  const membershipApplicationRequirements =
    createMembershipApplicationRequirementsResultDto.membershipApplicationRequirements.map(
      (membershipApplicationRequirement) => ({
        membershipApplicationRequirementKey:
          membershipApplicationRequirement.membershipApplicationRequirementKey,
        membershipTypeKey: membershipApplicationRequirement.membershipTypeKey,
      }),
    );

  return { membershipApplicationRequirements };
};
