import currency from 'currency.js';

const currencies = ['DKK', 'NOK', 'SEK', 'EUR'] as const;
export type Currency = (typeof currencies)[number];

export function toScu(value: number) {
  return value * 100;
}

export function fromScu(value: number) {
  return value / 100;
}

function getParseCurrencyOptions(symbol: Currency): currency.Options {
  switch (symbol) {
    case 'SEK':
      return { separator: ' ', decimal: ',', symbol: 'SEK ' };

    case 'NOK':
      return { separator: ' ', decimal: ',', symbol: 'NOK ' };

    case 'DKK':
      return { separator: ' ', decimal: '.', symbol: 'DKK ' };

    case 'EUR':
      return { separator: ' ', decimal: ',', symbol: 'EUR ' };
  }
}

export function parseCurrency(price: number, symbol: Currency) {
  const formatter = currency(price);

  return formatter.format(getParseCurrencyOptions(symbol));
}

export enum CountryCode {
  SE = 'SE',
  DK = 'DK',
  NO = 'NO',
  FI = 'FI',
}

export const countryToCurrency = (country: CountryCode): Currency => {
  switch (country) {
    case CountryCode.DK:
      return 'DKK';

    case CountryCode.NO:
      return 'NOK';

    case CountryCode.FI:
      return 'EUR';

    default:
      return 'SEK';
  }
};
