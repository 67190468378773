import * as _activities from './activities.endpoints';
import * as coHostRequests from './co-host-requests';
import * as createActivity from './create-activity.endpoints';
import * as draft from './draft';
import * as qrScanTokens from './qr-scan-tokens';
import * as tickets from './tickets';

export const activities = {
  ..._activities,
  ...createActivity,
  coHostRequests,
  draft,
  qrScanTokens,
  tickets,
};
