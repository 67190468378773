import { deleteFileUploadThunk, uploadFileThunk } from './file-uploads.actions';
import { FileUploadsBuilder } from './file-uploads.types';

const uploadFileBuild = (builder: FileUploadsBuilder) => {
  return builder
    .addCase(uploadFileThunk.fulfilled, (state, action) => {
      state.uploadFile.status = 'completed';
      state.uploadFile.data = action.payload;
    })
    .addCase(uploadFileThunk.pending, (state) => {
      state.uploadFile.status = 'pending';
      state.uploadFile.error = null;
    })
    .addCase(uploadFileThunk.rejected, (state, action) => {
      state.uploadFile.status = 'idle';
      state.uploadFile.error = action.error;
    });
};

const deleteFileUploadBuild = (builder: FileUploadsBuilder) => {
  return builder
    .addCase(deleteFileUploadThunk.fulfilled, (state) => {
      state.deleteFile.status = 'completed';
    })
    .addCase(deleteFileUploadThunk.pending, (state) => {
      state.deleteFile.status = 'pending';
      state.deleteFile.error = null;
    })
    .addCase(deleteFileUploadThunk.rejected, (state, action) => {
      state.deleteFile.status = 'idle';
      state.deleteFile.error = action.error;
    });
};

export const extraReducers = (builder: FileUploadsBuilder) => {
  uploadFileBuild(builder);
  deleteFileUploadBuild(builder);
};
