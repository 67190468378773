import React from 'react';

import { Time } from '../../constants';
import { Banner } from '../banner';

export function InternetStatusBanner(props: {
  offlineMessageTx: TxString;
  recentlyOnlineMessageTx: TxString;
}) {
  const { offlineMessageTx, recentlyOnlineMessageTx } = props;

  const [status, setStatus] = React.useState<
    'online' | 'offline' | 'recently-online'
  >('online');

  const [isClosed, setIsClosed] = React.useState(false);

  const timeoutRef = React.useRef(0);

  const closeBanner = () => setIsClosed(true);

  React.useEffect(() => {
    const handleOnline = () => {
      setIsClosed(false);
      setStatus('recently-online');

      timeoutRef.current = window.setTimeout(
        () => setStatus('online'),
        5 * Time.Second,
      );
    };

    const handleOffline = () => {
      window.clearTimeout(timeoutRef.current);
      setStatus('offline');
      setIsClosed(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  React.useEffect(() => {
    setStatus(window.navigator.onLine ? 'online' : 'offline');
  }, []);

  if (isClosed) {
    return null;
  }

  switch (status) {
    case 'offline':
      return (
        <Banner
          messageTx={offlineMessageTx}
          variant="error"
          onClose={closeBanner}
        />
      );

    case 'recently-online':
      return (
        <Banner
          variant="success"
          messageTx={recentlyOnlineMessageTx}
          onClose={closeBanner}
        />
      );

    default:
      return null;
  }
}
