import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Link } from '../link';

const ProfileMenu = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      user-select: none;
      box-shadow: ${theme.elevation.strong};
    `;
  }}
`;

const ProfileMenuHeader = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.profileMenuHeaderBackground};
    `;
  }}
`;

const ProfileMenuListItem = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.profileMenuListItemBackground};

      :hover {
        background-color: ${theme.colors.profileMenuListItemBackgroundHover};
      }

      :active {
        background-color: ${theme.colors.profileMenuListItemBackgroundActive};
      }

      :last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    `;
  }}
`;

const ProfileMenuListItemLink = styled(Link)`
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  width: 100%;
`;

export const Styled = {
  ProfileMenu,
  ProfileMenuHeader,
  ProfileMenuListItem,
  ProfileMenuListItemLink,
};
