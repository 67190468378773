export type FlexPosition =
  | 'start'
  | 'center'
  | 'end'
  | 'between'
  | 'around'
  | 'evenly';

export type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type AlignSelf = 'start' | 'end' | 'center' | 'stretch' | 'baseline';

export interface Flex {
  alignSelf?: AlignSelf;
  columnGap?: number;
  flex?: boolean;
  flexAlign?: FlexPosition;
  flexBasis?: number | string;
  flexDirection?: FlexDirection;
  flexGrow?: number;
  flexJustify?: FlexPosition;
  flexShrink?: number;
  flexWrap?: boolean | 'wrap' | 'nowrap' | 'wrap-reverse';
  gap?: number | string;
  rowGap?: number;
  wrapReverse?: boolean;
}

export enum FlexMap {
  'start' = 'flex-start',
  'end' = 'flex-end',
  'center' = 'center',
  'between' = 'space-between',
  'evenly' = 'space-evenly',
  'around' = 'space-around',
}
