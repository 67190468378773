import {
  createReactionThunk,
  deleteReactionThunk,
  getReactionsThunk,
} from './reactions.actions';
import { reactionsAdapter } from './reactions.adapter';
import { initialReactionsState } from './reactions.initial';
import { ReactionsBuilder } from './reactions.types';

const getPostsBuild = (builder: ReactionsBuilder) => {
  return builder
    .addCase(getReactionsThunk.fulfilled, (state, action) => {
      state.reactions.status = 'idle';

      reactionsAdapter.addMany(state.reactions.items, action.payload.items);

      state.reactions.pageInfo = action.payload.pageInfo;
      state.reactions.totalCount = action.payload.totalCount;
      state.reactions.pageSize =
        action.meta.arg.first ??
        action.meta.arg.last ??
        initialReactionsState.reactions.pageSize;
    })
    .addCase(getReactionsThunk.pending, (state) => {
      state.reactions.status = 'pending';
      state.reactions.error = null;
    })
    .addCase(getReactionsThunk.rejected, (state, action) => {
      state.reactions.status = 'idle';
      state.reactions.error = action.error;
    });
};

const deleteReactionBuild = (builder: ReactionsBuilder) => {
  return builder
    .addCase(deleteReactionThunk.fulfilled, (state) => {
      state.deleteReaction.status = 'idle';
    })
    .addCase(deleteReactionThunk.pending, (state) => {
      state.deleteReaction.status = 'pending';
      state.deleteReaction.error = null;
    })
    .addCase(deleteReactionThunk.rejected, (state, action) => {
      state.deleteReaction.status = 'idle';
      state.deleteReaction.error = action.error;
    });
};

const createReactionBuild = (builder: ReactionsBuilder) => {
  return builder
    .addCase(createReactionThunk.fulfilled, (state) => {
      state.createReaction.status = 'idle';
    })
    .addCase(createReactionThunk.pending, (state) => {
      state.createReaction.status = 'pending';
      state.createReaction.error = null;
    })
    .addCase(createReactionThunk.rejected, (state, action) => {
      state.createReaction.status = 'idle';
      state.createReaction.error = action.error;
    });
};

export const extraReducers = (builder: ReactionsBuilder) => {
  getPostsBuild(builder);
  deleteReactionBuild(builder);
  createReactionBuild(builder);
};
