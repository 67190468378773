import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ControlledInput,
  ControlledTextarea,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  PaperContentContainer,
  Spinner,
  Text,
  flattenFieldErrorsObject,
  getFileFromUrl,
  useFetch,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  UnsavedChangesBox,
  UploadCoverImage,
  UploadLogo,
} from '../../../../components';
import {
  DEPARTMENT_ABOUT_MAX_LENGTH,
  DEPARTMENT_LINK_MAX_LENGTH,
  DEPARTMENT_NAME_MAX_LENGTH,
  DEPARTMENT_PHONE_MAX_LENGTH,
  DepartmentForm,
  DepartmentSettingsValidation,
  EMAIL_MAX_LENGTH,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  DepartmentSelector,
  UpdateDepartmentSelector,
  updateDepartmentThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getOptionalLabelText } from '../../../../utils';

function DepartmentSettingsFormContent(props: {
  defaultValues: DepartmentForm;
}) {
  const { defaultValues } = props;

  const updateDepartmentSettingsStatus = useSelector(
    UpdateDepartmentSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const formMethods = useForm<DepartmentForm>({
    defaultValues,
    resolver: joiResolver(DepartmentSettingsValidation),
    shouldFocusError: false,
  });

  const updateDepartmentSettings = formMethods.handleSubmit(
    async (departmentSettingsForm) => {
      await dispatch(updateDepartmentThunk(departmentSettingsForm));

      formMethods.reset(departmentSettingsForm);
    },
    (err) => {
      Logger.warning('updateDepartmentSettings Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const reset = () => {
    formMethods.reset();
  };

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <PaperContentContainer>
      <InnerPaperContentContainer>
        <FormProvider {...formMethods}>
          <InnerPaperContentContainerSectionsContainer>
            {(formMethods.formState.isDirty ||
              updateDepartmentSettingsStatus === 'pending') && (
              <UnsavedChangesBox
                onSave={updateDepartmentSettings}
                onRevert={reset}
                isLoading={updateDepartmentSettingsStatus === 'pending'}
              />
            )}

            <InnerPaperContentContainerSection>
              <Box flex flexDirection="column" gap={16}>
                <Text
                  variant="bodyMdBold"
                  tx="label.department-settings.department-details"
                />

                <ControlledInput
                  labelTx="label.department-settings.department-info.department-name.label"
                  maxLength={DEPARTMENT_NAME_MAX_LENGTH}
                  name="name"
                  required
                />

                <ControlledTextarea
                  labelTx="label.department-settings.department-info.about.label"
                  maxLength={DEPARTMENT_ABOUT_MAX_LENGTH}
                  name="about"
                  required
                />
              </Box>
            </InnerPaperContentContainerSection>

            <InnerPaperContentContainerSection>
              <Box flex flexDirection="column" gap={16}>
                <Text
                  variant="bodyMdBold"
                  tx="label.department-settings.media"
                />

                <Box flexWrap gap={16} flex flexJustify="between">
                  <UploadLogo name="logo" />

                  <UploadCoverImage name="coverImage" />
                </Box>
              </Box>
            </InnerPaperContentContainerSection>

            <InnerPaperContentContainerSection>
              <Box flex flexDirection="column" gap={8}>
                <Text
                  variant="bodyMdBold"
                  tx="label.department-settings.contact"
                  mb={16}
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'envelope-outline',
                    },
                  ]}
                  labelTx="label.department-settings.department-info.email.label"
                  maxLength={EMAIL_MAX_LENGTH}
                  name="email"
                  required
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'phone-outline',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.phone.label',
                  )}
                  maxLength={DEPARTMENT_PHONE_MAX_LENGTH}
                  name="phone"
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'globe-solid',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.website.label',
                  )}
                  maxLength={DEPARTMENT_LINK_MAX_LENGTH}
                  name="websiteUrl"
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'facebook-colored',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.facebook.label',
                  )}
                  maxLength={DEPARTMENT_LINK_MAX_LENGTH}
                  name="facebookUrl"
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'instagram-colored',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.instagram.label',
                  )}
                  maxLength={DEPARTMENT_LINK_MAX_LENGTH}
                  name="instagramUrl"
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'x-colored',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.x.label',
                  )}
                  maxLength={DEPARTMENT_LINK_MAX_LENGTH}
                  name="twitterUrl"
                />

                <ControlledInput
                  leadingElements={[
                    {
                      type: 'icon',
                      name: 'youtube-colored',
                    },
                  ]}
                  label={getOptionalLabelText(
                    'label.department-settings.department-info.youtube.label',
                  )}
                  maxLength={DEPARTMENT_LINK_MAX_LENGTH}
                  name="youtubeUrl"
                />
              </Box>
            </InnerPaperContentContainerSection>
          </InnerPaperContentContainerSectionsContainer>
        </FormProvider>
      </InnerPaperContentContainer>
    </PaperContentContainer>
  );
}

function useDefaultValues() {
  const departmentSettings = useSelector(DepartmentSelector.selectData);

  const getDefaultValues =
    React.useCallback(async (): Promise<DepartmentForm | null> => {
      if (!departmentSettings) {
        return null;
      }

      const logo = departmentSettings.logo?.original.url
        ? await getFileFromUrl(departmentSettings.logo.original.url)
        : null;

      const coverImage = departmentSettings.coverImage?.original.url
        ? await getFileFromUrl(departmentSettings.coverImage.original.url)
        : null;

      return {
        about: departmentSettings.about,
        coverImage,
        email: departmentSettings.email,
        facebookUrl: departmentSettings.facebookUrl,
        instagramUrl: departmentSettings.instagramUrl,
        logo,
        name: departmentSettings.name,
        phone: departmentSettings.phone,
        twitterUrl: departmentSettings.twitterUrl,
        websiteUrl: departmentSettings.websiteUrl,
        youtubeUrl: departmentSettings.youtubeUrl,
      };
    }, [departmentSettings]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

export function Department() {
  const defaultValues = useDefaultValues();

  if (!defaultValues) {
    return (
      <Box flex flexJustify="center" mx="auto" pt={100}>
        <Spinner />
      </Box>
    );
  }

  return <DepartmentSettingsFormContent defaultValues={defaultValues} />;
}
