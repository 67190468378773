import { Box, Icon, Image, Spinner } from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm, CreatePostFormFileUpload } from '../../../models';
import { deleteFileUploadThunk, useDispatch } from '../../../store';
import { Styled } from './uploaded-images.styled';

function UploadedFile(props: CreatePostFormFileUpload) {
  const { base64, id, status, fileUploadKey } = props;

  const dispatch = useDispatch();

  const formContext = useFormContext<CreatePostForm>();

  const deleteFile = () => {
    formContext.setValue(
      'fileUploads',
      formContext.getValues('fileUploads').filter((file) => file.id !== id),
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    if (fileUploadKey) {
      dispatch(deleteFileUploadThunk(fileUploadKey));
    }
  };

  return (
    <Box
      flex
      flexAlign="center"
      flexJustify="center"
      height={48}
      r={4}
      relative
      width={48}
      key={id}
    >
      <Image width={48} height={48} r={4} src={base64} />

      <Styled.DeleteFileIcon
        zIndex={5}
        absolute
        top={-10}
        right={-10}
        name="x-circle-solid"
        onClick={deleteFile}
      />

      {status === 'error' && (
        <Icon
          zIndex={3}
          color="alertErrorIcon"
          absolute
          name="exclamation-triangle-outline"
        />
      )}

      {status === 'pending' && <Spinner zIndex={3} absolute />}

      {status !== 'success' && (
        <Box
          backgroundColor="backdrop"
          zIndex={2}
          absolute
          width="100%"
          height="100%"
          r={4}
        />
      )}
    </Box>
  );
}

function renderFileUpload(fileUpload: CreatePostFormFileUpload) {
  return <UploadedFile key={fileUpload.id} {...fileUpload} />;
}

export function UploadedImages() {
  const formContext = useFormContext<CreatePostForm>();

  const fileUploads = formContext.watch('fileUploads');

  if (!fileUploads.length) {
    return null;
  }

  return (
    <Box flex gap={8} flexWrap>
      {fileUploads.map(renderFileUpload)}
    </Box>
  );
}
