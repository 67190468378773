import { joiResolver } from '@hookform/resolvers/joi';
import {
  Address,
  Box,
  Button,
  Checkbox,
  Chip,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledDatePicker,
  ControlledInput,
  ControlledTextarea,
  Divider,
  EmptyState,
  INPUT_X_PADDING,
  Icon,
  IconButton,
  InnerContentContainer,
  Input,
  InputChip,
  InputChips,
  InputRefContext,
  InputRefProvider,
  Link,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  WrapBox,
  createSearchContext,
  flattenFieldErrorsObject,
  getUID,
  isTxString,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  useCoordinatesSearch,
  useLocationSearch,
} from '../../../../../helpers';
import {
  ActivityCategory,
  ActivityLink,
  AddLinkValidation,
  CONTACT_NAME_MAX_LENGTH,
  DepartmentLocation,
  DescriptionForm,
  EMAIL_MAX_LENGTH,
  LINK_LABEL_MAX_LENGTH,
  LINK_MAX_LENGTH,
  LOCATION_CITIES,
  LOCATION_DESCRIPTION_MAX_LENGTH,
  LOCATION_LINK_MAX_LENGTH,
  LocationCity,
  MAX_NUMBER_OF_ACTIVITY_CATEGORIES,
  OTHER_ACTIVITY_CATEGORY_KEY,
  PHONE_MAX_LENGTH,
  TAGS,
  TITLE_MAX_LENGTH,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  ActivityCategoriesSelector,
  DepartmentSelector,
  SuggestActivityCategoriesSelector,
  activityActions,
  getActivityCategoriesThunk,
  searchActions,
  suggestActivityCategoriesThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  getActivityDescriptionMaxLength,
  getOptionalLabelText,
} from '../../../../../utils';
import {
  GoBackButton,
  PublishEventButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { ActivityFormContext } from '../create-activity.context';
import { Styled } from './description.styled';
import { ActivityLinkSidebarContextType } from './description.types';

const DESCRIPTION_MIN_LENGTH = 30;

const ActivityCategorySearchContext = createSearchContext<ActivityCategory>();

const CATEGORY_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = CATEGORY_ITEM_HEIGHT * 9;

const sessionToken = getUID();

const activityLinkSidebarContextDefaultValue: ActivityLinkSidebarContextType['data'] =
  {
    defaultValues: null,
    mode: 'create',
    selectedIndex: 0,
  };

const ActivityLinkSidebarContext =
  React.createContext<ActivityLinkSidebarContextType>({
    data: activityLinkSidebarContextDefaultValue,
    setData: () => {},
  });

export function ActivityLinkSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [data, setData] = React.useState<
    ActivityLinkSidebarContextType['data']
  >(activityLinkSidebarContextDefaultValue);

  return (
    <ActivityLinkSidebarContext.Provider value={{ data, setData }}>
      {children}
    </ActivityLinkSidebarContext.Provider>
  );
}

function ActivityLinks() {
  const enableTags = useSelector(DepartmentSelector.selectEnableTags);

  const stepNumberOffset = enableTags ? 0 : -1;

  const formContext = React.useContext(ActivityFormContext);

  const links = formContext.watch('description.links');

  const { data, setData } = React.useContext(ActivityLinkSidebarContext);

  const addNewLink = () => {
    setData({
      defaultValues: { label: '', url: '' },
      mode: 'create',
      selectedIndex: 0,
    });
  };

  const renderLinkTableRow = (link: ActivityLink, index: number) => {
    const editLink = () =>
      setData({
        mode: 'edit',
        selectedIndex: index,
        defaultValues: link,
      });

    const removeLink = (e: React.MouseEvent) => {
      e.stopPropagation();

      if (index === data.selectedIndex) {
        setData({
          mode: 'create',
          selectedIndex: 0,
          defaultValues: null,
        });
      }

      const newState = (links ?? []).filter((_, i) => i !== index);
      formContext.setValue(
        'description.links',
        newState.length ? newState : null,
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );
    };

    return (
      <TableRow
        highlight={data.mode === 'edit' && data.selectedIndex === index}
        key={`${link.label}-${link.url}-${index}`}
        onClick={editLink}
      >
        <TableCell text={link.label} />
        <TableCell text={link.url} />
        <TableCell width={50} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={removeLink} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box gap={16} flex flexDirection="column">
      <Box flex flexJustify="between">
        <Text
          text={getOptionalLabelText(
            'label.create-activity.description.steps.7',
            { stepNumber: 7 + stepNumberOffset },
          )}
          variant="bodyMdBold"
        />

        <Tooltip
          placement="left"
          titleTx="label.create-activity.description.links.add-link"
        >
          <IconButton
            disabled={data.defaultValues !== null}
            icon="plus-circle-outline"
            onClick={addNewLink}
          />
        </Tooltip>
      </Box>
      {!links?.length ? (
        <EmptyState
          buttonOnClick={addNewLink}
          buttonTx="label.create-activity.description.links.empty-state.button"
          titleTx="label.create-activity.description.links.empty-state.title"
        />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.create-activity.description.links.link-label.label" />
              <TableHead tx="label.create-activity.description.links.url.label" />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>

          <TableBody>{links.map(renderLinkTableRow)}</TableBody>
        </Table>
      )}
    </Box>
  );
}

export function ActivityLinkSidebar() {
  const location = useLocation();

  const formContext = React.useContext(ActivityFormContext);

  const { data, setData } = React.useContext(ActivityLinkSidebarContext);

  const isOpen = !!data.defaultValues;

  const closeSidebar = () =>
    setData({
      mode: 'create',
      selectedIndex: 0,
      defaultValues: null,
    });

  const formMethods = useForm<ActivityLink>({
    defaultValues: data.defaultValues ?? {
      label: '',
      url: '',
    },
    resolver: joiResolver(AddLinkValidation),
  });

  const addLink = formMethods.handleSubmit(
    (data) => {
      formContext.setValue(
        'description.links',
        [...(formContext.getValues('description.links') ?? []), data],
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );
      closeSidebar();
    },
    (err) => {
      Logger.warning('addEventLink Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset(
      data.defaultValues ?? {
        label: '',
        url: '',
      },
    );
  }, [data.defaultValues, formMethods]);

  if (!location.pathname.endsWith('/description')) {
    return null;
  }

  return (
    <ContentSidebar isOpen={isOpen} onClose={closeSidebar} width={400}>
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx={
            data.mode === 'create'
              ? 'label.create-activity.description.links.add-link'
              : 'label.create-activity.description.links.edit-link'
          }
        />

        <FormProvider {...formMethods}>
          <ControlledInput
            labelTx="label.create-activity.description.links.link-label.label"
            maxLength={LINK_LABEL_MAX_LENGTH}
            name="label"
            required
          />

          <ControlledInput
            labelTx="label.create-activity.description.links.url.label"
            maxLength={LINK_MAX_LENGTH}
            name="url"
            required
          />
        </FormProvider>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button onClick={addLink} tx="button.save" variant="secondary" />
      </ContentSidebarFooterContainer>
    </ContentSidebar>
  );
}

const LocationLinkInput = () => {
  const formContext = React.useContext(ActivityFormContext);

  const coordinates = formContext.watch('description.location.coordinates');

  if (!coordinates) {
    return (
      <ControlledInput
        label={getOptionalLabelText(
          'label.create-activity.description.location.link',
        )}
        maxLength={LOCATION_LINK_MAX_LENGTH}
        name="description.location.link"
      />
    );
  }

  return (
    <ControlledInput
      disabled
      labelTx="label.create-activity.description.location.link"
      maxLength={LOCATION_LINK_MAX_LENGTH}
      name="description.location.link"
    />
  );
};

const LocationSearchInput = () => {
  const dispatch = useDispatch();

  const formContext = React.useContext(ActivityFormContext);

  const locationInputRef = React.useRef<HTMLInputElement>(null);

  const [showPickCity, setShowPickCity] = React.useState(false);

  const enableTags = useSelector(DepartmentSelector.selectEnableTags);
  const departmentLocations = useSelector(
    DepartmentSelector.selectDepartmentLocations,
  );

  const { search, searchResult, searchString, setSearchString } =
    useLocationSearch(
      sessionToken,
      formContext.getValues('description.location.label'),
    );

  const [label, city, coordinates] = formContext.watch([
    'description.location.label',
    'description.location.city',
    'description.location.coordinates',
  ]);

  const { search: searchCoordinates } = useCoordinatesSearch(sessionToken);

  const handleSearchStringChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderSearchResultMenuItem = (location: Address) => {
    const handleSelect = async () => {
      formContext.setValue('description.location.description', '', {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
      formContext.setValue('description.location.label', location.address, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
      formContext.setFocus('description.location.description');
      setSearchString(location.address);

      const coordinates = await searchCoordinates(location.placeId);
      if (coordinates) {
        formContext.setValue(
          'description.location.coordinates',
          {
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
          {
            shouldDirty: true,
            shouldValidate: formContext.formState.isSubmitted,
          },
        );

        dispatch(searchActions.clearAddresses());
      }
    };

    return (
      <MenuItem
        key={`search-result-${location.placeId}`}
        onClick={handleSelect}
      >
        <Text as="span" text={location.address} variant="bodySm" />
      </MenuItem>
    );
  };

  const clearLocation = () => {
    dispatch(searchActions.clearAddresses());
    setSearchString('');
    formContext.setValue('description.location.coordinates', null, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    formContext.setValue('description.location.label', '', {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    formContext.setValue('description.location.city', null, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });

    window.setTimeout(() => {
      locationInputRef.current?.focus();
    }, 0);
  };

  const handleUseCustomLocationClick = () => {
    formContext.setValue('description.location.coordinates', null, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    formContext.setValue('description.location.label', searchString, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    formContext.setFocus('description.location.link');

    setShowPickCity(true);
  };

  const renderLocationCity = (locationCity: LocationCity) => {
    const pickLocationCity = () => {
      formContext.setValue('description.location.city', locationCity, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
      setShowPickCity(false);
    };

    return (
      <Chip
        cursor="pointer"
        key={`location-city-${locationCity}`}
        onClick={pickLocationCity}
        text={locationCity}
        variant={2}
      />
    );
  };

  const closePickCity = () => {
    setShowPickCity(false);
  };

  const renderDepartmentLocation = (departmentLocation: DepartmentLocation) => {
    const isSelected =
      departmentLocation.coordinates.latitude === coordinates?.latitude &&
      departmentLocation.coordinates.longitude === coordinates?.longitude;

    const handleDepartmentLocationClick = () => {
      formContext.setValue(
        'description.location.coordinates',
        departmentLocation.coordinates,
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );

      formContext.setValue(
        'description.location.label',
        departmentLocation.address,
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );

      formContext.setValue('description.location.link', null, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });

      setSearchString(departmentLocation.address);
    };

    return (
      <Chip
        cursor={isSelected ? 'auto' : 'pointer'}
        variant={isSelected ? 3 : 1}
        key={`department-location-${departmentLocation.placeId}`}
        onClick={isSelected ? undefined : handleDepartmentLocationClick}
        text={departmentLocation.address}
      />
    );
  };

  const disabled = !!label;

  return (
    <React.Fragment>
      {enableTags && departmentLocations && (
        <Box px={INPUT_X_PADDING} flex gap={16} flexWrap>
          {departmentLocations.map(renderDepartmentLocation)}
        </Box>
      )}

      <Box relative>
        <Input
          ref={locationInputRef}
          errorTx={
            isTxString(
              formContext.formState.errors.description?.location?.label
                ?.message,
            )
              ? formContext.formState.errors.description?.location?.label
                  ?.message
              : undefined
          }
          leadingElements={[
            {
              type: 'icon',
              color: disabled ? 'inputIconDisabled' : 'inputIcon',
              name: 'magnifying-glass',
            },
          ]}
          trailingElements={[
            {
              type: 'button',
              icon: 'x-circle-outline',
              onClick: clearLocation,
              hidden: !label,
            },
          ]}
          disabled={disabled}
          labelTx="label.create-activity.description.location.address"
          onChange={handleSearchStringChange}
          value={city ? `${searchString} - ${city}` : searchString}
        />

        {!label && (
          <Menu
            top="100%"
            mt={8}
            width="100%"
            absolute
            isOpen={searchString.length > 0 || searchResult.length > 0}
          >
            {searchString.length > 0 && (
              <MenuItem onClick={handleUseCustomLocationClick}>
                <Box gap={8} flexAlign="center" flex>
                  <Icon color="customLocationPlusIcon" name="plus" />

                  <Text
                    variant="bodyMdBold"
                    tx="label.create-activity.description.location.use-custom-location"
                  />

                  <Text variant="bodyMd" text={searchString} />
                </Box>
              </MenuItem>
            )}

            {searchResult.length > 0 &&
              searchResult.map(renderSearchResultMenuItem)}
          </Menu>
        )}

        {showPickCity && enableTags && (
          <Styled.PickCityBox
            zIndex={10}
            absolute
            top="100%"
            mt={8}
            width="fit-content"
          >
            <Box px={24} py={16} relative flex flexAlign="center" gap={16}>
              <Text
                variant="bodyMd"
                tx="label.create-activity.description.location.pick-city"
              />

              <Box flex gap={16} flexWrap>
                {LOCATION_CITIES.map(renderLocationCity)}
              </Box>

              <Box absolute top={-10} right={-10}>
                <IconButton onClick={closePickCity} icon="x-circle-outline" />
              </Box>
            </Box>
          </Styled.PickCityBox>
        )}
      </Box>
    </React.Fragment>
  );
};

function TagCheckboxes() {
  const formContext = React.useContext(ActivityFormContext);

  const tags = formContext.watch('description.tags');

  const renderTabCheckbox = (tag: string) => {
    const _tag = `#${tag}`;

    const isChecked = !!tags?.includes(_tag);

    const onChange = () => {
      tags?.includes(_tag)
        ? tags?.splice(tags.indexOf(_tag), 1)
        : tags?.push(_tag);

      formContext.setValue('description.tags', tags, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
    };

    return (
      <Checkbox
        disabled={tags === null}
        checked={isChecked}
        key={tag}
        onChange={onChange}
        text={tag}
      />
    );
  };

  return <Styled.TagsGrid>{TAGS.map(renderTabCheckbox)}</Styled.TagsGrid>;
}

function TagsErrorMessage() {
  const formState = useFormState<DescriptionForm>();

  if (!isTxString(formState.errors?.tags?.message)) {
    return null;
  }

  return (
    <Text
      color="errorLabel"
      tx={formState.errors?.tags?.message}
      variant="bodyMd"
    />
  );
}

function ToggleUseTags() {
  const formContext = React.useContext(ActivityFormContext);

  const isActive = !!formContext.watch('description.tags');

  const toggleUseTags = () => {
    formContext.setValue('description.tags', isActive ? null : [], {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  return (
    <Box flexDirection="column" gap={10} flex>
      <Switch
        checked={isActive}
        tx="label.create-activity.description.tags.toggle-use-tags"
        onClick={toggleUseTags}
      />

      <TagsErrorMessage />
    </Box>
  );
}

function Tags() {
  const enableTags = useSelector(DepartmentSelector.selectEnableTags);

  if (!enableTags) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={24}>
      <Text
        text={getOptionalLabelText(
          'label.create-activity.description.steps.3',
          { stepNumber: 3 },
        )}
        variant="bodyMdBold"
      />

      <ToggleUseTags />

      <TagCheckboxes />
    </Box>
  );
}

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton icon="arrow-left-circle-outline" disabled />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton
          to={`${rootPath}/media`}
          icon="arrow-right-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

function ActivityCategoryMenuItem(props: {
  activityCategory: ActivityCategory;
}) {
  const { activityCategory } = props;

  const formContext = React.useContext(ActivityFormContext);

  const inputRef = React.useContext(InputRefContext);

  const selectedActivityCategories = useWatch({
    control: formContext.control,
    name: 'description.categories',
  });

  const isChecked = selectedActivityCategories.some(
    (selectedActivityCategory) =>
      selectedActivityCategory.activityCategoryKey ===
      activityCategory.activityCategoryKey,
  );

  const otherActivityCategoryIsSelected = selectedActivityCategories.some(
    (x) => x.activityCategoryKey === OTHER_ACTIVITY_CATEGORY_KEY,
  );

  const thisItemIsActivityCategoryOther =
    activityCategory.activityCategoryKey === OTHER_ACTIVITY_CATEGORY_KEY;

  const menuItemIsDisabled =
    !isChecked &&
    (selectedActivityCategories.length === MAX_NUMBER_OF_ACTIVITY_CATEGORIES ||
      otherActivityCategoryIsSelected ||
      (!otherActivityCategoryIsSelected &&
        thisItemIsActivityCategoryOther &&
        selectedActivityCategories.length > 0));

  const toggleActivityCategory = () => {
    formContext.setValue(
      'description.categories',
      isChecked
        ? selectedActivityCategories.filter(
            (selectedActivityCategory) =>
              selectedActivityCategory.activityCategoryKey !==
              activityCategory.activityCategoryKey,
          )
        : selectedActivityCategories.concat(activityCategory) ?? [
            activityCategory,
          ],
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    inputRef.current?.focus();
  };

  return (
    <React.Fragment>
      {thisItemIsActivityCategoryOther && <Divider />}
      <MenuItem
        onClick={toggleActivityCategory}
        gap={8}
        flex
        flexAlign="center"
        disabled={menuItemIsDisabled}
      >
        <Checkbox
          checked={isChecked}
          onChange={toggleActivityCategory}
          disabled={menuItemIsDisabled}
        />
        <Text as="span" text={activityCategory.name} variant="bodyMd" />
      </MenuItem>
    </React.Fragment>
  );
}

function renderActivityCategory(activityCategory: ActivityCategory) {
  return (
    <ActivityCategoryMenuItem
      key={activityCategory.activityCategoryKey}
      activityCategory={activityCategory}
    />
  );
}

function ActivityCategoriesInput() {
  const activityCategories = useSelector(ActivityCategoriesSelector.selectData);

  const suggestActivityCategoriesStatus = useSelector(
    SuggestActivityCategoriesSelector.selectStatus,
  );

  const suggestActivityCategories = useSelector(
    SuggestActivityCategoriesSelector.selectData,
  );

  const formContext = React.useContext(ActivityFormContext);

  const searchContext = React.useContext(
    ActivityCategorySearchContext.SearchContext,
  );

  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useContext(InputRefContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu();

  const selectedActivityCategories = useWatch({
    control: formContext.control,
    name: 'description.categories',
  });

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.search(e.target.value);

    setSearchString(e.target.value);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const chips: InputChip<string>[] = selectedActivityCategories.map(
    (activityCategory) => ({
      id: activityCategory.activityCategoryKey,
      text: activityCategory.name,
      maxWidth: 'unset',
    }),
  );

  const removeChip = (id: string) => {
    const newState = selectedActivityCategories.filter(
      (selectedActivityCategory) =>
        selectedActivityCategory.activityCategoryKey !== id,
    );

    formContext.setValue('description.categories', newState, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.result.length;

  const items = React.useMemo(() => {
    return searchContext.result
      .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
      .map((activityCategory) => activityCategory.item);
  }, [searchContext.result]);

  React.useEffect(() => {
    if (activityCategories?.length && suggestActivityCategories?.length) {
      const values = activityCategories.filter((activityCategory) =>
        suggestActivityCategories.includes(
          activityCategory.activityCategoryKey,
        ),
      );

      formContext.setValue('description.categories', values, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
    }
  }, [activityCategories, suggestActivityCategories, formContext]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(activityActions.clearSuggestActivityCategories());
    };
  }, [selectedActivityCategories, dispatch]);

  return (
    <Box ref={menuState.clickOutsideRef}>
      <InputChips
        hideErrorMessage={menuState.isOpen}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={100}
        value={searchString}
        ref={inputRef}
        showInput={menuState.isOpen || !!searchString}
        chips={suggestActivityCategoriesStatus === 'pending' ? [] : chips}
        disabled={suggestActivityCategoriesStatus === 'pending'}
        errorTx={
          isTxString(
            formContext.formState.errors.description?.categories?.message,
          )
            ? formContext.formState.errors.description?.categories?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElements={[
          {
            type: 'icon',
            name: 'magnifying-glass',
          },
        ]}
        trailingElements={[
          {
            type: 'button',
            icon: menuIsOpen ? 'chevron-up' : 'chevron-down',
            onClick: handleToggleMenu,
            tooltipTx: menuIsOpen
              ? 'label.tooltip.collapse'
              : 'label.tooltip.expand',
          },
        ]}
        labelTx="label.create-activity.description.categories.label"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
            left={0}
          >
            {items.map(renderActivityCategory)}
          </Menu>
        }
      />
    </Box>
  );
}

function AutoSuggestError({
  showInputIsEmptyError,
}: {
  showInputIsEmptyError: boolean;
}) {
  const suggestActivityCategories = useSelector(
    SuggestActivityCategoriesSelector.selectData,
  );

  const suggestActivityCategoriesStatus = useSelector(
    SuggestActivityCategoriesSelector.selectStatus,
  );

  if (suggestActivityCategoriesStatus === 'pending') {
    return null;
  }

  if (showInputIsEmptyError) {
    return (
      <Text
        variant="bodyMd"
        color="errorLabel"
        tx="label.create-activity.description.categories.autosuggest-empty-input"
      />
    );
  }

  if (suggestActivityCategories?.length !== 0) {
    return null;
  }

  return (
    <Text
      variant="bodyMd"
      color="errorLabel"
      tx="label.create-activity.description.categories.autosuggest-failed"
    />
  );
}

function ActivityCategories({
  showInputIsEmptyError,
  suggestCategoriesOnClick,
}: {
  showInputIsEmptyError: boolean;
  suggestCategoriesOnClick: () => void;
}) {
  const activityCategories = useSelector(ActivityCategoriesSelector.selectData);

  const suggestActivityCategoriesStatus = useSelector(
    SuggestActivityCategoriesSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const content = (
    <InputRefProvider>
      <ActivityCategoriesInput />
    </InputRefProvider>
  );

  React.useEffect(() => {
    if (activityCategories === null) {
      dispatch(getActivityCategoriesThunk());
    }
  }, [dispatch, activityCategories]);

  React.useEffect(() => {
    return () => {
      dispatch(activityActions.clearSuggestActivityCategories());
    };
  }, [dispatch]);

  return (
    <Box flex flexDirection="column" gap={24}>
      <Box flex gap={16} flexAlign="center">
        <Text
          tx="label.create-activity.description.steps.2"
          variant="bodyMdBold"
        />
        <Tooltip
          placement="top"
          titleTx="label.create-activity.description.categories.autosuggest-description"
        >
          <Link
            tx="label.create-activity.description.categories.autosuggest"
            variant="secondary"
            onClick={suggestCategoriesOnClick}
            disabled={suggestActivityCategoriesStatus === 'pending'}
          />
        </Tooltip>

        <AutoSuggestError showInputIsEmptyError={showInputIsEmptyError} />
      </Box>
      {activityCategories?.length ? (
        <ActivityCategorySearchContext.Provider
          keys={['name']}
          defaultValue={activityCategories}
        >
          {content}
        </ActivityCategorySearchContext.Provider>
      ) : (
        content
      )}
    </Box>
  );
}

const useSuggestCategories = () => {
  const dispatch = useDispatch();

  const formContext = React.useContext(ActivityFormContext);

  const [showInputIsEmptyError, setShowInputIsEmptyError] =
    React.useState(false);

  const suggestCategories = (onBlurMode: boolean) => {
    const description = formContext.getValues('description.description');
    const title = formContext.getValues('description.title');

    if (onBlurMode) {
      if (!title.length || !description.length) {
        return;
      }

      if (description.length < DESCRIPTION_MIN_LENGTH) {
        return;
      }

      if (formContext.getValues('description.categories').length) {
        return;
      }
    }

    if (!title.length && !description.length) {
      setShowInputIsEmptyError(true);
      return;
    }
    setShowInputIsEmptyError(false);

    dispatch(suggestActivityCategoriesThunk({ description, title }));
  };

  return {
    showInputIsEmptyError,
    suggestCategoriesOnBlur: () => suggestCategories(true),
    suggestCategoriesOnClick: () => suggestCategories(false),
  };
};

export function CreateActivityDescription() {
  const enableTags = useSelector(DepartmentSelector.selectEnableTags);

  const {
    showInputIsEmptyError,
    suggestCategoriesOnBlur,
    suggestCategoriesOnClick,
  } = useSuggestCategories();

  const stepNumberOffset = enableTags ? 0 : -1;

  return (
    <React.Fragment>
      <InnerContentContainer>
        <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
          <Box
            flex
            flexAlign="center"
            flexWrap="wrap"
            gap={16}
            height="fit-content"
          >
            <GoBackButton />

            <Text
              as="h1"
              color="pageTitle"
              tx="label.create-activity.tabs.description.label"
              variant="titleMd"
            />
          </Box>

          <Box flex flexWrap="wrap" gap={16}>
            <SaveActivityAsDraftButton />

            <PublishEventButton />
          </Box>
        </Box>

        <Box flex flexDirection="column" gap={24}>
          <Text
            tx="label.create-activity.description.steps.1"
            variant="bodyMdBold"
          />

          <Box flex flexDirection="column" gap={32}>
            <ControlledInput
              labelTx="label.create-activity.description.title.title"
              maxLength={TITLE_MAX_LENGTH}
              name="description.title"
              required
              onBlur={suggestCategoriesOnBlur}
            />

            <ControlledTextarea
              labelTx="label.create-activity.description.title.description"
              maxLength={getActivityDescriptionMaxLength(enableTags)}
              name="description.description"
              required
              onBlur={suggestCategoriesOnBlur}
            />
          </Box>
        </Box>

        <ActivityCategories
          showInputIsEmptyError={showInputIsEmptyError}
          suggestCategoriesOnClick={suggestCategoriesOnClick}
        />

        <Tags />

        <Box flex flexDirection="column" gap={24}>
          <Text
            tx="label.create-activity.description.steps.4"
            txArgs={{ stepNumber: 4 + stepNumberOffset }}
            variant="bodyMdBold"
          />

          <WrapBox breakpoint="xs" flex gap={32}>
            <ControlledDatePicker
              labelTx="label.create-activity.description.date-and-time.start-date"
              name="description.startDate"
              required
              type="datetime-local"
            />

            <ControlledDatePicker
              labelTx="label.create-activity.description.date-and-time.end-date"
              name="description.endDate"
              required
              type="datetime-local"
            />
          </WrapBox>
        </Box>

        <Box flex flexDirection="column" gap={24}>
          <Text
            tx="label.create-activity.description.steps.5"
            txArgs={{ stepNumber: 5 + stepNumberOffset }}
            variant="bodyMdBold"
          />

          <LocationSearchInput />

          <LocationLinkInput />

          <ControlledTextarea
            name="description.location.description"
            label={getOptionalLabelText(
              'label.create-activity.description.location.extra',
            )}
            maxLength={LOCATION_DESCRIPTION_MAX_LENGTH}
          />
        </Box>

        <Box flex flexDirection="column" gap={24}>
          <Text
            tx="label.create-activity.description.steps.6"
            txArgs={{ stepNumber: 6 + stepNumberOffset }}
            variant="bodyMdBold"
          />

          <ControlledInput
            labelTx="label.create-activity.description.contact-person.name.label"
            maxLength={CONTACT_NAME_MAX_LENGTH}
            name="description.contactDetails.name"
            required
          />

          <WrapBox breakpoint="xs" flex gap={32}>
            <ControlledInput
              labelTx="label.create-activity.description.contact-person.email.label"
              maxLength={EMAIL_MAX_LENGTH}
              name="description.contactDetails.email"
              required
              type="email"
            />

            <ControlledInput
              label={getOptionalLabelText(
                'label.create-activity.description.contact-person.phone.label',
              )}
              maxLength={PHONE_MAX_LENGTH}
              name="description.contactDetails.phone"
              type="tel"
            />
          </WrapBox>
        </Box>

        <Box flex flexDirection="column" gap={24}>
          <ActivityLinks />
        </Box>
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
