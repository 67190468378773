import React from 'react';

import { IconName } from '../components/icon';

interface UsePasswordInputReturn {
  icon: IconName;
  inputType: 'password' | 'text';
  toggleShowPassword: () => void;
}

export function usePasswordInput(): UsePasswordInputReturn {
  const [showPassword, setShowPassword] = React.useState(false);

  const iconName = showPassword ? 'eye-outline' : 'eye-slash-outline';

  const toggleShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  return {
    icon: iconName,
    inputType: showPassword ? 'text' : 'password',
    toggleShowPassword,
  };
}
