import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const InnerPaperContentContainer = styled(Box)`
  ${(props) => {
    return css`
      border-radius: ${props.theme.borderRadii.s}px;
    `;
  }}
`;

const PaperContentContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.paperContainerBackground};
      scrollbar-gutter: stable;
      padding: 32px 16px;

      @media (min-width: ${theme.breakpoints.xs}px) {
        padding: 32px;
      }
    `;
  }}
`;

export const Styled = {
  InnerPaperContentContainer,
  PaperContentContainer,
};
