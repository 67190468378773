import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './team.build';
import { initialTeamState } from './team.initial';
import { reducers } from './team.reducers';

const teamSlice = createSlice({
  name: 'team',
  initialState: initialTeamState,
  reducers,
  extraReducers,
});

export const teamReducer = teamSlice.reducer;
