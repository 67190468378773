import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { CoHostRequestListItem } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  CohostRequestDto,
  UpdateCoHostingRequestParams,
} from './co-host-requests.dto';
import {
  coHostRequestMapper,
  updateCoHostRequestMapper,
} from './co-host-requests.mappers';

export const getCoHostingRequests = async (): Promise<
  GeneralApiResultWithData<CoHostRequestListItem[]>
> => {
  const res = await apisauce.get<CohostRequestDto[], any>(
    '/v1/activities/cohost-requests',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(coHostRequestMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCoHostingRequests', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCoHostingRequest = async (
  params: UpdateCoHostingRequestParams,
): Promise<GeneralApiResult> => {
  const [cohostRequestKey, data] = updateCoHostRequestMapper(params);

  const res = await apisauce.put<void, any>(
    `/v1/activities/cohost-requests/${cohostRequestKey}`,
    data,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
