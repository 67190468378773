import { Currency } from '@orbiapp/components';
import { createSelector } from '@reduxjs/toolkit';

import { OrbiPayRole } from '../../models';
import { RootState } from '../store.types';

export namespace PayoutSelector {
  const selectSelf = (state: RootState) => state.orbiPay.payout;

  export const selectData = createSelector(selectSelf, (payout) => payout.data);

  export const selectStatus = createSelector(
    selectSelf,
    (payout) => payout.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (payout) => payout.error,
  );
}

export namespace PublicStripeKeySelector {
  const selectSelf = (state: RootState) => state.orbiPay.publicStripeKey;

  export const selectPublicStripeKey = createSelector(
    selectSelf,
    (publicStripeKey) => publicStripeKey.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (publicStripeKey) => publicStripeKey.status,
  );
}

export namespace OrbiPaySettingsSelector {
  const selectSelf = (state: RootState) => state.orbiPay.orbiPaySettings;

  export const selectStatus = createSelector(
    selectSelf,
    (orbiPaySettings) => orbiPaySettings.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (orbiPaySettings) => orbiPaySettings.data,
  );

  export const selectOrbiPayRole = createSelector(
    selectData,
    (data) => data?.role ?? OrbiPayRole.OrbiPayNotOnboarded,
  );

  export const selectRequirementsType = createSelector(selectData, (data) => {
    if (data?.role !== OrbiPayRole.Admin) return null;

    return data?.stripeRequirements?.requirementType ?? null;
  });

  export const selectIsDisabled = createSelector(selectData, (data) => {
    if (data?.role !== OrbiPayRole.Admin) return null;

    return data?.stripeRequirements?.isDisabled ?? null;
  });

  export const selectAllowPaidTickets = createSelector(selectData, (data) => {
    if (data?.role === OrbiPayRole.Admin) {
      return (
        !data?.stripeRequirements?.isDisabled &&
        data?.stripeRequirements?.requirementType !== 'currently_due'
      );
    }

    return data?.role !== OrbiPayRole.OrbiPayNotOnboarded;
  });

  export const selectCurrency = createSelector(selectData, (data): Currency => {
    if (data?.role === OrbiPayRole.OrbiPayNotOnboarded) {
      return 'SEK';
    }

    return data?.currency ?? 'SEK';
  });
}

export namespace PayoutsSelector {
  const selectSelf = (state: RootState) => state.orbiPay.payouts;

  export const selectStatus = createSelector(
    selectSelf,
    (payouts) => payouts.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (payouts) => payouts.data,
  );

  export const selectPayoutsFiltering = createSelector(
    selectSelf,
    (payouts) => payouts.payoutsFiltering,
  );

  export const selectStartDate = createSelector(
    selectPayoutsFiltering,
    (payoutsFiltering) => payoutsFiltering.startDate,
  );

  export const selectEndDate = createSelector(
    selectPayoutsFiltering,
    (payoutsFiltering) => payoutsFiltering.endDate,
  );
}

export namespace CreateStripeAccountLinkSelector {
  export const selectStatus = (state: RootState) =>
    state.orbiPay.createStripeAccountLink.status;
}

export namespace CreateOrbiPayAccountSelector {
  const selectSelf = (state: RootState) => state.orbiPay.createOrbiPayAccount;

  export const selectStatus = createSelector(
    selectSelf,
    (createOrbiPayAccount) => createOrbiPayAccount.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (createOrbiPayAccount) => createOrbiPayAccount.data,
  );
}
