import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const UploadCoverImageContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      min-width: fill-available;

      @media (min-width: ${theme.breakpoints.xs}px) {
        min-width: fit-content;
      }
    `;
  }}
`;

export const Styled = { UploadCoverImageContainer };
