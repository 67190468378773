import Joi from 'joi';

import { CoHostingErrors } from './co-hosting.errors';
import { cohostRequestStatus } from './co-hosting.model';

namespace CoHost {
  export const departmentKey = Joi.string().required();

  export const coHostName = Joi.string().required();

  export const logoUrl = Joi.string().allow(null).required();

  export const status = Joi.string()
    .valid(...cohostRequestStatus)
    .required();

  export const orgNodesBranch = Joi.any();
}

export namespace CoHostingSchema {
  export const coHost = Joi.object({
    departmentKey: CoHost.departmentKey,
    name: CoHost.coHostName,
    logoUrl: CoHost.logoUrl,
    cohostRequestStatus: CoHost.status,
    orgNodesBranch: CoHost.orgNodesBranch,
  }).required();

  export const coHosts = Joi.array()
    .items(coHost)
    .allow(null)
    .required()
    .messages(CoHostingErrors.coHosts);
}
