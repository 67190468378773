import React from 'react';

import { Box } from '../box';
import { Icon } from '../icon';
import { Link } from '../link';
import { Spinner } from '../spinner';
import { Text } from '../text';
import { Styled } from './button.styled';
import { ButtonProps } from './button.types';

function _Button(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    children,
    disabled,
    href,
    icon,
    iconPlacement = 'left',
    iconSize,
    isDark,
    isLoading,
    target,
    text,
    to,
    tx,
    txArgs,
    variant,
    large = false,
    ...rest
  } = props;

  const button = (
    <Styled.Button
      aria-disabled={disabled}
      disabled={disabled}
      flex
      flexAlign="center"
      flexDirection="row"
      flexJustify="center"
      gap={8}
      isDark={isDark}
      isLoading={isLoading}
      ref={ref}
      relative
      role="button"
      type="button"
      variant={variant}
      large={large}
      {...rest}
    >
      {isLoading && (
        <Box absolute inset flex flexJustify="center" flexAlign="center">
          <Spinner size={18} />
        </Box>
      )}

      <Styled.ChildrenBox isLoading={isLoading}>
        {children || (
          <Box
            flex
            flexAlign="center"
            flexDirection={iconPlacement === 'left' ? 'row' : 'row-reverse'}
            gap={8}
          >
            {icon && (
              <Icon size={iconSize ? iconSize : large ? 24 : 16} name={icon} />
            )}

            <Text
              as="span"
              text={text}
              tx={tx}
              txArgs={txArgs}
              variant={large ? 'buttonMd' : 'buttonSm'}
            />
          </Box>
        )}
      </Styled.ChildrenBox>
    </Styled.Button>
  );

  if (to) {
    return (
      <Link plain target={target} to={to}>
        {button}
      </Link>
    );
  }

  if (href) {
    return (
      <Link plain target={target} href={href}>
        {button}
      </Link>
    );
  }

  return button;
}

export const Button = React.forwardRef(_Button);
