import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './activities.build';
import { initialActivitiesState } from './activities.initial';
import { reducers } from './activities.reducers';

const activitySlice = createSlice({
  name: 'activity',
  reducers,
  initialState: initialActivitiesState,
  extraReducers,
});

export const activityActions = activitySlice.actions;
export const activityReducer = activitySlice.reducer;
