import { PayloadAction } from '@reduxjs/toolkit';

import { CreateReactionParams, DeleteReactionParams } from '../../models';
import { postCommentsAdapter, postsAdapter } from './posts.adapter';
import { initialPostsState } from './posts.initial';
import { PostsState } from './posts.types';
import { getReactionsState } from './posts.utils';

const reset = (state: PostsState) => {
  Object.assign(state, initialPostsState);
};

const resetPosts = (state: PostsState) => {
  postsAdapter.removeAll(state.posts.items);

  state.posts = initialPostsState.posts;
};

const resetPost = (state: PostsState) => {
  state.post = initialPostsState.post;
  state.postComments = initialPostsState.postComments;
};

const updatePostReactions = (
  state: PostsState,
  action: PayloadAction<CreateReactionParams | DeleteReactionParams>,
) => {
  if (state.post.data?.postKey === action.payload.target.key) {
    state.post.data.reactions = getReactionsState(
      state.post.data.reactions,
      action.payload,
    );
  }

  const post = postsAdapter
    .getSelectors()
    .selectById(state.posts.items, action.payload.target.key);

  const reactions = getReactionsState(post.reactions, action.payload);

  postsAdapter.updateOne(state.posts.items, {
    id: action.payload.target.key,
    changes: { reactions },
  });
};

const updatePostCommentReactions = (
  state: PostsState,
  action: PayloadAction<CreateReactionParams | DeleteReactionParams>,
) => {
  const postComment = postCommentsAdapter
    .getSelectors()
    .selectById(state.postComments.items, action.payload.target.key);

  const reactions = getReactionsState(postComment.reactions, action.payload);

  postCommentsAdapter.updateOne(state.postComments.items, {
    id: action.payload.target.key,
    changes: { reactions },
  });
};

const setQ = (state: PostsState, action: PayloadAction<string | undefined>) => {
  state.posts.extra.q = action.payload;
};

export const reducers = {
  reset,
  resetPosts,
  resetPost,
  updatePostReactions,
  updatePostCommentReactions,
  setQ,
};
