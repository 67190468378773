import { Box, LAYOUT_Z_INDEX, Text } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const ScanWithQrScannerCard = styled(Box)<{ isFullscreen: boolean }>`
  ${(props) => {
    const { isFullscreen, theme } = props;

    return css`
      max-width: 425px;
      height: 425px;
      background-color: ${theme.colors.scanQrCodesCardBackground};
      border: 1px solid ${theme.colors.scanQrCodesCardBorder};

      ${isFullscreen &&
      css`
        position: fixed;
        inset: 0;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: ${LAYOUT_Z_INDEX.scanTickets.scanModal};
        border-radius: 0;
      `}
    `;
  }}
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const MustFocusWindow = styled(Box)`
  background-color: rgba(0, 0, 0, 0.7);
  inset: 0;
  position: fixed;
  z-index: ${LAYOUT_Z_INDEX.scanTickets.mustFocusWindow};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  display: none;
`;

const MustFocusWindowText = styled(Text)`
  color: #fff;
`;

export const Styled = {
  HiddenInput,
  MustFocusWindow,
  MustFocusWindowText,
  ScanWithQrScannerCard,
};
