import Fuse, { FuseResult } from 'fuse.js';
import React from 'react';

function useListSearch<T>(props: { keys?: string[]; defaultValue: T[] }) {
  const { keys, defaultValue } = props;

  const [result, setResult] = React.useState<FuseResult<T>[]>(
    defaultValue.map((item, index) => ({ item, refIndex: index, score: 1 })),
  );

  const fuse = React.useMemo(
    () =>
      new Fuse(defaultValue, {
        keys: keys ?? [],
        threshold: 0.2,
        includeScore: true,
      }),
    [defaultValue, keys],
  );

  const search = React.useCallback(
    (searchString: string) => {
      const res = fuse.search(searchString);

      if (!res.length) {
        setResult(
          defaultValue.map((item, index) => ({
            item,
            refIndex: index,
            score: 1,
          })),
        );
        return;
      }

      setResult(res);
    },
    [defaultValue, fuse],
  );

  return { result, search };
}

export function createSearchContext<T>() {
  const SearchContext = React.createContext<{
    result: FuseResult<T>[];
    search: (searchString: string) => void;
  }>({
    result: [],
    search: () => {},
  });

  const Provider = (
    props: React.PropsWithChildren<{ defaultValue: T[]; keys?: string[] }>,
  ) => {
    const { children, ...rest } = props;

    const { result, search } = useListSearch(rest);

    return (
      <SearchContext.Provider value={{ result, search }}>
        {children}
      </SearchContext.Provider>
    );
  };

  return { SearchContext, Provider };
}
