import { CountryCode } from '@orbiapp/components';
import Joi from 'joi';

import { EmailSchema } from '../email';
import { OrbiPayErrors } from './orbi-pay.errors';
import { accountHolderTypes, businessTypes } from './orbi-pay.types';

export namespace OrbiPaySchema {
  export const acceptedTermsOfService = Joi.boolean()
    .valid(true)
    .required()
    .messages(OrbiPayErrors.acceptedTermsOfService);

  export const businessType = Joi.string()
    .valid(...businessTypes)
    .required()
    .messages(OrbiPayErrors.businessType);

  export const email = EmailSchema.required();

  export const accountHolderType = Joi.string()
    .valid(...accountHolderTypes)
    .required()
    .messages(OrbiPayErrors.accountHolderType);

  export const accountHolderName = Joi.string().required().messages({
    'string.empty': 'errors.name.empty',
  });

  export const countryCode = Joi.string()
    .valid(...Object.keys(CountryCode))
    .messages(OrbiPayErrors.countryCode);

  export const vatPercent = Joi.allow(
    Joi.number().integer().min(0).max(100).required(),
    Joi.string().required(),
  ).messages(OrbiPayErrors.vatPercent);

  export const refreshUrl = Joi.string().uri().required();

  export const returnUrl = Joi.string().uri().required();
}
