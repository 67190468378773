import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SettingsGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      display: grid;
      column-gap: 8px;
      row-gap: 32px;
      grid-template-columns: 1fr;

      @media (min-width: ${theme.breakpoints.xs}px) {
        grid-template-columns: 1fr 1fr;
      }
    `;
  }}
`;

export const Styled = { SettingsGrid };
