export namespace OrbiPayErrors {
  export const acceptedTermsOfService = {
    'any.only': 'errors.terms-of-service.empty',
    'any.required': 'errors.terms-of-service.empty',
  };

  export const businessType = {
    'any.only': 'errors.select.none-selected',
  };

  export const accountHolderType = {
    'any.only': 'errors.select.none-selected',
  };

  export const accountHolderName = {
    'string.empty': 'errors.name.empty',
  };

  export const countryCode = {
    'any.only': 'errors.select.none-selected',
  };

  export const vatPercent = {
    'any.only': 'errors.select.none-selected',
    'number.base': 'errors.select.none-selected',
  };
}
