import {
  Avatar,
  AvatarProps,
  BlurredCard,
  BlurredCardProps,
  Box,
  BoxProps,
  Image,
  ResponsiveBox,
  Text,
  TextProps,
} from '../../components';
import appImage from './app-image.png';
import orbiLogo from './orbi-logo.png';
import { Styled } from './split-screen-container.styled';

interface SplitScreenLeftSideProps extends Omit<BoxProps, 'title'> {
  avatarSrc?: string;
  avatarVariant?: AvatarProps['variant'];
  avatarFallbackLetter?: AvatarProps['fallbackLetter'];

  title?: TextProps['text'];
  titleTx?: TextProps['tx'];
  titleTxArgs?: TextProps['txArgs'];

  blurredCards: BlurredCardProps[];
}

export function SplitScreenContainer(props: BoxProps) {
  return (
    <Styled.SplitScreenContainer flexGrow={1} width="100%" flex {...props} />
  );
}

function renderBlurredCard(props: BlurredCardProps, index: number) {
  return <BlurredCard key={`blurred-card-${index}`} width={150} {...props} />;
}

export function SplitScreenLeftSide(props: SplitScreenLeftSideProps) {
  const {
    avatarSrc,
    avatarFallbackLetter,
    avatarVariant,
    title,
    titleTx,
    titleTxArgs,
    blurredCards,
    ...rest
  } = props;

  return (
    <Styled.SplitScreenLeftSide
      flexGrow={1}
      flexJustify="center"
      flexAlign="center"
      flex
      flexDirection="column"
      px={32}
      gap={24}
      p={32}
      {...rest}
    >
      <Avatar
        variant={avatarVariant}
        fallbackLetter={avatarFallbackLetter}
        src={avatarSrc}
        width={64}
        height={64}
      />

      <Text
        as="h1"
        maxWidth="30ch"
        mb={8}
        text={title}
        textAlign="center"
        tx={titleTx}
        txArgs={titleTxArgs}
        variant="titleMd"
      />

      <Box flex flexWrap flexJustify="center" gap={16}>
        {blurredCards.map(renderBlurredCard)}
      </Box>
    </Styled.SplitScreenLeftSide>
  );
}

export function SplitScreenLeftSideAltAppUser() {
  return (
    <ResponsiveBox
      flexGrow={1}
      sm={
        <Box
          flexGrow={1}
          flexJustify="end"
          flexAlign="center"
          flex
          backgroundColor="signInLeftSidebackground"
          flexDirection="column"
          px={32}
        >
          <Box
            absolute
            bottom={0}
            flex
            flexDirection="column"
            flexAlign="center"
            px={32}
            maxHeight="80%"
            overflow="hidden"
          >
            <Image width={120} height={120} src={orbiLogo} />
            <Image maxWidth="100%" width={550} height="65%" src={appImage} />
          </Box>
        </Box>
      }
    />
  );
}

export function SplitScreenContentContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box
      flexGrow={1}
      flexJustify="center"
      flexAlign="center"
      flex
      relative
      {...rest}
    >
      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        flexGrow={1}
        gap={32}
        p={32}
        maxWidth="100%"
      >
        <Box
          flexAlign="center"
          flex
          flexDirection="column"
          width={480}
          gap={32}
          maxWidth="100%"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
