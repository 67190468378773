import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SummaryGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 32px;
`;

const SummaryGridItem = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border-radius: 8px;
      max-width: 100%;
      border: 1px solid ${theme.colors.summaryGridItemBorder};
    `;
  }}
`;

export const Styled = {
  SummaryGrid,
  SummaryGridItem,
};
