import { Button } from '@orbiapp/components';
import React from 'react';

import {
  CreateActivityDraftSelector,
  createActivityDraftThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { ActivityFormContext } from '../create-activity.context';

export function SaveActivityAsDraftButton() {
  const createDraftStatus = useSelector(
    CreateActivityDraftSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const activityFormContext = React.useContext(ActivityFormContext);

  if (!window.__FLAGS__.ENABLE_CACHE || activityFormContext.mode === 'update') {
    return null;
  }

  const saveActivityDraft = () => {
    const formValues = activityFormContext.getValues();

    activityFormContext.reset(formValues);

    dispatch(createActivityDraftThunk(formValues));
  };

  return (
    <Button
      disabled={createDraftStatus === 'pending'}
      onClick={saveActivityDraft}
      tx="label.create-activity.tooltip.save-draft"
      variant="secondary"
    />
  );
}
