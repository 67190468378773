import {
  CheckboxListItem,
  MultiChoiceListItem,
  RequestedInfoStats,
  RequestedInfoType,
} from '../../../../../../../models';
import {
  CheckboxStatsDto,
  MultiChoiceStatsDto,
  RequestedInfoStatsDto,
} from './requested-info.dto';

const multichoiceStatsMapper = ({
  multichoiceQuestionKey,
  question,
  answers,
}: MultiChoiceStatsDto): MultiChoiceListItem => ({
  answers: answers.map(({ selectCount, label }, index) => ({
    index,
    label,
    numberOfAnswers: selectCount,
  })),
  id: multichoiceQuestionKey,
  question,
  type: RequestedInfoType.MultiChoice,
});

const checkboxStatsMapper = ({
  checkboxQuestionKey,
  checkedCount,
  question,
  uncheckedCount,
}: CheckboxStatsDto): CheckboxListItem => ({
  id: checkboxQuestionKey,
  question,
  answers: { yes: checkedCount, no: uncheckedCount },
  type: RequestedInfoType.Checkbox,
});

export const requestedInfoStatsMapper = (
  requestedInfoStatsDto: RequestedInfoStatsDto,
): RequestedInfoStats => {
  return {
    checkboxStats: requestedInfoStatsDto.checkboxStats.map(checkboxStatsMapper),
    multichoiceStats: requestedInfoStatsDto.multichoiceStats.map(
      multichoiceStatsMapper,
    ),
  };
};
