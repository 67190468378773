import { INITIAL_DATA_STATE } from '../store.constants';
import { countriesAdapter } from './countries.adapter';
import { CountriesState } from './countries.types';

export const initialCountriesState: CountriesState = {
  countries: {
    ...INITIAL_DATA_STATE,
    data: countriesAdapter.getInitialState(),
  },
};
