import {
  Box,
  ControlledInput,
  ControlledTextarea,
  FormSection,
  FormSectionHeader,
  Text,
} from '@orbiapp/components';

import { UploadCoverImage } from '../../../../../components';
import {
  OFFER_DESCRIPTION_MAX_LENGTH,
  OFFER_TITLE_MAX_LENGTH,
} from '../../../../../models';

export function GeneralInformation() {
  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.general-information"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: 1 }}
        />
      </FormSectionHeader>

      <Box gap={16} flexDirection="column" flex>
        <UploadCoverImage name="coverImage" />

        <ControlledInput
          labelTx="label.offer-form.title"
          maxLength={OFFER_TITLE_MAX_LENGTH}
          name="title"
          placeholderTx="placeholder.title-offers"
        />
        <ControlledTextarea
          labelTx="label.offer-form.description"
          maxLength={OFFER_DESCRIPTION_MAX_LENGTH}
          name="description"
          resize="vertical"
          minHeight={53}
        />
      </Box>
    </FormSection>
  );
}
