import { PayloadAction } from '@reduxjs/toolkit';

import { initialOffersState } from './offers.initial';
import { OffersState } from './offers.types';

function reset(state: OffersState) {
  Object.assign(state, initialOffersState);
}

function clearOffers(state: OffersState) {
  state.offers.data = initialOffersState.offers.data;
}

function resetCreateOffer(state: OffersState) {
  state.createOffer.data = initialOffersState.createOffer.data;
}

function resetOffer(state: OffersState) {
  state.offer.data = initialOffersState.offer.data;
}

function setOffersSearch(state: OffersState, action: PayloadAction<string>) {
  state.offers.search = action.payload;
}

function clearOffersLink(state: OffersState) {
  state.offersLink = initialOffersState.offersLink;
}

export const reducers = {
  reset,

  clearOffers,
  resetCreateOffer,

  setOffersSearch,

  clearOffersLink,

  resetOffer,
};
