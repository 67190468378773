import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATED_STATE,
} from '../store.constants';
import { postCommentsAdapter, postsAdapter } from './posts.adapter';
import { PostsState } from './posts.types';

export const initialPostsState: PostsState = {
  post: INITIAL_DATA_STATE,
  posts: {
    ...INITIAL_PAGINATED_STATE,
    items: postsAdapter.getInitialState(),
    orderBy: 'createdAt',

    extra: {
      q: undefined,
      targetType: undefined,
      activityKeys: undefined,

      lastRequestParams: null,
    },
  },

  createPost: INITIAL_DATA_STATE,
  updatePost: INITIAL_DATA_STATE,
  deletePost: INITIAL_DATA_STATE,

  postComments: {
    ...INITIAL_PAGINATED_STATE,
    items: postCommentsAdapter.getInitialState(),
    orderBy: 'createdAt',
  },

  deletePostComment: INITIAL_DATA_STATE,
  createPostComment: INITIAL_DATA_STATE,
  updatePostComment: INITIAL_DATA_STATE,
};
