import Joi from 'joi';

import { CreateOfferSchema } from './offer.schema';

export const CreateOfferValidation = Joi.object({
  offerCategoryKey: CreateOfferSchema.offerCategoryKey,
  title: CreateOfferSchema.title,
  description: CreateOfferSchema.description,
  coverImage: CreateOfferSchema.coverImage,

  startDate: CreateOfferSchema.startDate,
  endDate: CreateOfferSchema.endDate,
  contactName: CreateOfferSchema.contactName,
  contactEmail: CreateOfferSchema.contactEmail,

  isConsumable: CreateOfferSchema.isConsumable,
  consumeWaitMinutes: CreateOfferSchema.consumeWaitMinutes,

  link: CreateOfferSchema.link,

  code: CreateOfferSchema.code,
  files: CreateOfferSchema.files,

  requiredMembershipTypes: CreateOfferSchema.requiredMembershipTypes,

  locations: CreateOfferSchema.locations,
  requireStudentVerification: CreateOfferSchema.requireStudentVerification,

  countryKeys: CreateOfferSchema.countryKeys,
});

export const UpdateOfferValidation = Joi.object({
  offerCategoryKey: CreateOfferSchema.offerCategoryKey,
  title: CreateOfferSchema.title,
  description: CreateOfferSchema.description,
  coverImage: CreateOfferSchema.coverImage,

  startDate: CreateOfferSchema.startDate,
  endDate: CreateOfferSchema.endDate,
  contactName: CreateOfferSchema.contactName,
  contactEmail: CreateOfferSchema.contactEmail,

  isConsumable: CreateOfferSchema.isConsumable,
  consumeWaitMinutes: CreateOfferSchema.consumeWaitMinutes,

  link: CreateOfferSchema.link,

  code: CreateOfferSchema.code,
  files: CreateOfferSchema.files,

  requiredMembershipTypes: CreateOfferSchema.requiredMembershipTypes,

  locations: CreateOfferSchema.locations,
  requireStudentVerification: CreateOfferSchema.requireStudentVerification,

  countryKeys: CreateOfferSchema.countryKeys,
});
