import { fromScu } from '@orbiapp/components';

import {
  ContactDetails,
  IntegratedMembershipType,
  MembershipApplicationRequirement,
  MembershipType,
  MembershipTypeListItem,
  MembershipTypePeriod,
  MembershipTypeQuestion,
  MembershipTypeWrapper,
  RequestedInfoType,
} from '../../../../../models';
import {
  IntegratedMembershipTypeDto,
  MembershipApplicationRequirementDto,
  MembershipMultichoiceOptionDto,
  MembershipPeriodDto,
  MembershipTypeDto,
  MembershipTypeListItemDto,
  MembershipTypeWrapperDto,
} from './membership-types.dto';

export const membershipTypeWrapperMapper = async (
  membershipTypeWrapperDto: MembershipTypeWrapperDto,
): Promise<MembershipTypeWrapper> => {
  const membershipTypeMapper = async (
    membershipTypeDto: MembershipTypeDto,
  ): Promise<MembershipType> => {
    const membershipQuestions: MembershipTypeQuestion[] = [];

    const multichoiceOptionMapper = (
      option: MembershipMultichoiceOptionDto,
    ) => ({
      index: option.index,
      label: option.label,
      key: option.membershipMultichoiceOptionKey,
      isNew: false,
    });

    membershipTypeDto.checkboxQuestions?.forEach((question) => {
      membershipQuestions.push({
        createdAt: question.createdAt,
        index: question.index,
        isRequired: question.isRequired,
        key: question.membershipCheckboxQuestionKey,
        question: question.question,
        type: RequestedInfoType.Checkbox,
      });
    });

    membershipTypeDto.freetextQuestions?.forEach((question) => {
      membershipQuestions.push({
        createdAt: question.createdAt,
        index: question.index,
        isRequired: question.isRequired,
        key: question.membershipFreetextQuestionKey,
        question: question.question,
        type: RequestedInfoType.FreeText,
      });
    });

    membershipTypeDto.multichoiceQuestions?.forEach((question) => {
      membershipQuestions.push({
        createdAt: question.createdAt,
        index: question.index,
        isRequired: question.isRequired,
        key: question.membershipMultichoiceQuestionKey,
        options: question.options
          .map(multichoiceOptionMapper)
          .sort((a, b) => a.index - b.index),
        question: question.question,
        type: RequestedInfoType.MultiChoice,
      });
    });

    const periodMapper = (
      period: MembershipPeriodDto,
    ): MembershipTypePeriod => ({
      key: period.membershipPeriodKey,
      name: period.name,
      price: fromScu(period.price),
      saleEnd: period.saleEnd,
      saleStart: period.saleStart,
      validFrom: period.validFrom,
      validTo: period.validTo,
    });

    const periods = membershipTypeDto.periods?.map(periodMapper) ?? null;

    const requiredMembershipTypesMapper = () => {
      const { requiredMembershipTypes } = membershipTypeDto;

      if (!requiredMembershipTypes) {
        return null;
      }

      const membershipTypeMapper = (
        membershipType: MembershipApplicationRequirementDto,
      ): MembershipApplicationRequirement => {
        return {
          membershipApplicationRequirementKey:
            membershipType.membershipApplicationRequirementKey,
          departmentName: membershipType.departmentName,
          logoUrl: membershipType.logoUrl,
          membershipTypeKey: membershipType.membershipTypeKey,
          name: membershipType.name,
        };
      };

      return {
        combinationType: requiredMembershipTypes.combinationType,
        membershipApplicationRequirements:
          requiredMembershipTypes.membershipTypes.map(membershipTypeMapper),
      };
    };

    const requiredMembershipTypes = requiredMembershipTypesMapper();

    const contactDetails: ContactDetails = {
      email: membershipTypeDto.contactDetails.email,
      name: membershipTypeDto.contactDetails.name,
      phone: membershipTypeDto.contactDetails.phone,
    };

    const membershipType: MembershipType = {
      backgroundImage: membershipTypeDto.backgroundImageData,
      contactDetails,
      description: membershipTypeDto.description,
      logo: membershipTypeDto.logoData,
      membershipQuestions: membershipQuestions.length
        ? membershipQuestions
        : null,
      membershipTypeKey: membershipTypeDto.membershipTypeKey,
      name: membershipTypeDto.name,
      pendingApplicationCount: membershipTypeDto.pendingApplicationCount,
      pendingInvitationCount: membershipTypeDto.pendingInvitationCount,
      periods,
      requiredMembershipTypes,
      type: membershipTypeDto.type,
    };

    return membershipType;
  };

  const integratedMembershipTypeWrapper = async (
    integratedMembershipTypeDto: IntegratedMembershipTypeDto,
  ): Promise<IntegratedMembershipType> => {
    const contactDetails: ContactDetails = {
      email: integratedMembershipTypeDto.contactDetails.email ?? '',
      name: integratedMembershipTypeDto.contactDetails.name ?? '',
      phone: integratedMembershipTypeDto.contactDetails.phone,
    };

    return {
      activeCount: integratedMembershipTypeDto.activeCount,
      backgroundImage: integratedMembershipTypeDto.backgroundImageData,
      contactDetails,
      description: integratedMembershipTypeDto.description,
      logo: integratedMembershipTypeDto.logoData,
      membershipTypeKey: integratedMembershipTypeDto.membershipTypeKey,
      name: integratedMembershipTypeDto.name,
      source: 'montania',
      totalCount: integratedMembershipTypeDto.totalCount,
    };
  };

  if (membershipTypeWrapperDto.type === 'default') {
    const membershipType = await membershipTypeMapper(
      membershipTypeWrapperDto.payload,
    );
    return { type: 'default', membershipType };
  }

  const membershipType = await integratedMembershipTypeWrapper(
    membershipTypeWrapperDto.payload,
  );

  return { type: 'integration', membershipType };
};

export const membershipTypeListItemMapper = (
  membershipTypeListItemDto: MembershipTypeListItemDto,
): MembershipTypeListItem => ({
  createdAt: membershipTypeListItemDto.createdAt,
  logo: membershipTypeListItemDto.logo,
  logoUrl: membershipTypeListItemDto.logoUrl,
  pendingApplicationCount: membershipTypeListItemDto.pendingApplicationCount,
  membershipTypeKey: membershipTypeListItemDto.membershipTypeKey,
  name: membershipTypeListItemDto.name,
  source: membershipTypeListItemDto.source,
  totalMemberCount: membershipTypeListItemDto.totalMemberCount,
  type: membershipTypeListItemDto.type,
});
