import { Coordinates } from '@orbiapp/components';
import Joi from 'joi';

namespace CoordinatesSchema {
  export const latitude = Joi.number().min(-90).max(90).required();
  export const longitude = Joi.number().min(-180).max(180).required();
}

export const CoordinatesValidation = Joi.object<Coordinates>({
  latitude: CoordinatesSchema.latitude,
  longitude: CoordinatesSchema.longitude,
});
