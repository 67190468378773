import React, { useId } from 'react';

import { PlaceholderBar } from '../../placeholder-bar';
import { Styled } from './table-placeholder-row.styled';

export function TablePlaceholderRows(props: {
  rowCount: number;
  layout: (string | number)[];
}) {
  const { rowCount, layout } = props;

  const id = useId();

  const renderTablePlaceholderCol = (_: any, index: number) => {
    return (
      <Styled.TablePlaceholderCell
        width={layout[index % layout.length]}
        key={`table-placeholder-cell-${id}-${index}`}
      >
        <PlaceholderBar height={36} width="100%" />
      </Styled.TablePlaceholderCell>
    );
  };

  function renderTablePlaceholderRow(_: any, index: number) {
    return (
      <Styled.TablePlaceholderRow key={`table-placeholder-row-${id}-${index}`}>
        {layout.map(renderTablePlaceholderCol)}
      </Styled.TablePlaceholderRow>
    );
  }

  return (
    <React.Fragment>
      {Array.from(new Array(rowCount)).map(renderTablePlaceholderRow)}
    </React.Fragment>
  );
}
