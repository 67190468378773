import { fromScu, getUID } from '@orbiapp/components';

import {
  MembershipApplication,
  MembershipApplicationAnswer,
  MembershipApplicationListItem,
  MembershipApplicationPeriod,
  MembershipApplicationStatusUpdate,
  MembershipCheckboxAnswer,
  MembershipFreetextAnswer,
  MembershipMultichoiceAnswer,
  MembershipPeriod,
  RejectMembershipApplicationForm,
  RequestMoreInfoOnMembershipApplicationForm,
  RequestedInfoType,
} from '../../../../../../models';
import {
  CheckboxAnswerDto,
  FreetextAnswerDto,
  MembershipApplicationDto,
  MembershipApplicationListItemDto,
  MembershipApplicationPeriodDto,
  MembershipApplicationStatusUpdateDto,
  MultichoiceAnswerDto,
  RejectMembershipApplicationDto,
  RequestMoreInfoOnMembershipApplicationDto,
} from './applications.dto';

const checkboxAnswerMapper = (
  answer: CheckboxAnswerDto,
): MembershipCheckboxAnswer => ({
  index: answer.index,
  isChecked: answer.isChecked,
  key: getUID(),
  question: answer.question,
  type: RequestedInfoType.Checkbox,
});

const freetextAnswerMapper = (
  answer: FreetextAnswerDto,
): MembershipFreetextAnswer => ({
  answer: answer.answer,
  index: answer.index,
  key: getUID(),
  question: answer.question,
  type: RequestedInfoType.FreeText,
});

const multichoiceAnswerMapper = (
  answer: MultichoiceAnswerDto,
): MembershipMultichoiceAnswer => ({
  index: answer.index,
  key: getUID(),
  label: answer.label,
  question: answer.question,
  type: RequestedInfoType.MultiChoice,
});

export const membershipApplicationListItemMapper = (
  membershipApplicationListItemDto: MembershipApplicationListItemDto,
): MembershipApplicationListItem => {
  const periods =
    membershipApplicationListItemDto.periods?.map(
      (period): MembershipPeriod => {
        return {
          key: getUID(),
          name: period.name,
          validFrom: period.validFrom,
          validTo: period.validTo,
        };
      },
    ) ?? null;

  return {
    createdAt: membershipApplicationListItemDto.createdAt,
    email: membershipApplicationListItemDto.email,
    fullName: membershipApplicationListItemDto.fullName,
    membershipApplicationKey:
      membershipApplicationListItemDto.membershipApplicationKey,
    periods,
    status: membershipApplicationListItemDto.status,
  };
};

export const membershipApplicationMapper = (
  membershipApplicationDto: MembershipApplicationDto,
): MembershipApplication => {
  const statusMapper = (
    status: MembershipApplicationStatusUpdateDto,
  ): MembershipApplicationStatusUpdate => {
    return {
      createdAt: status.createdAt,
      needsMoreInfoReason: status.needsMoreInfoReason,
      note: status.note,
      status: status.status,
    };
  };

  const periodMapper = (
    period: MembershipApplicationPeriodDto,
  ): MembershipApplicationPeriod => ({
    key: getUID(),
    name: period.name,
    price: fromScu(period.price),
    validFrom: period.validFrom,
    validTo: period.validTo,
  });

  const answers: MembershipApplicationAnswer[] = [
    ...(membershipApplicationDto.checkboxAnswers?.map(checkboxAnswerMapper) ??
      []),
    ...(membershipApplicationDto.freetextAnswers?.map(freetextAnswerMapper) ??
      []),
    ...(membershipApplicationDto.multichoiceAnswers?.map(
      multichoiceAnswerMapper,
    ) ?? []),
  ];

  const statusUpdates = membershipApplicationDto.statusUpdates
    .map(statusMapper)
    .sort((a, b) => b.createdAt - a.createdAt);

  const periods = membershipApplicationDto.periods.map(periodMapper);

  return {
    answers: answers.length ? answers : null,
    createdAt: membershipApplicationDto.createdAt,
    email: membershipApplicationDto.email,
    fullName: membershipApplicationDto.fullName,
    membershipApplicationKey: membershipApplicationDto.membershipApplicationKey,
    membershipId: membershipApplicationDto.membershipId,
    periods,
    segmentation: membershipApplicationDto.segmentation,
    semester: membershipApplicationDto.semester,
    status: membershipApplicationDto.status,
    statusUpdatedAt: membershipApplicationDto.statusUpdatedAt,
    statusUpdates,
  };
};

export const rejectMembershipApplicationMapper = (
  rejectMembershipApplicationForm: RejectMembershipApplicationForm,
): RejectMembershipApplicationDto => {
  return {
    note: rejectMembershipApplicationForm.note,
  };
};

export const requestMoreInfoOnMembershipApplicationMapper = (
  requestMoreInfoOnMembershipApplicationForm: RequestMoreInfoOnMembershipApplicationForm,
): RequestMoreInfoOnMembershipApplicationDto => {
  return {
    note: requestMoreInfoOnMembershipApplicationForm.note,
    reason: requestMoreInfoOnMembershipApplicationForm.reason,
  };
};
