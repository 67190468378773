import React from 'react';

import { Box } from '../box';
import { Button } from '../button';
import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './alert.styled';
import { AlertProps, AlertVariant, GetAlertVariantReturn } from './alert.types';

function getAlertVariant(variant: AlertVariant): GetAlertVariantReturn {
  switch (variant) {
    case 'error':
      return {
        colors: {
          backgroundColor: 'alertErrorBackground',
          borderColor: 'alertErrorBorder',
          iconBackgroundColor: 'alertErrorIconBackground',
          iconColor: 'alertErrorIcon',
          labelColor: 'alertErrorLabel',
          titleColor: 'alertErrorTitle',
        },
        icon: 'exclamation-circle-outline',
      };

    case 'warning':
      return {
        colors: {
          backgroundColor: 'alertWarningBackground',
          borderColor: 'alertWarningBorder',
          iconBackgroundColor: 'alertWarningIconBackground',
          iconColor: 'alertWarningIcon',
          labelColor: 'alertWarningLabel',
          titleColor: 'alertWarningTitle',
        },
        icon: 'exclamation-triangle-outline',
      };

    case 'info':
      return {
        colors: {
          backgroundColor: 'alertInfoBackground',
          borderColor: 'alertInfoBorder',
          iconBackgroundColor: 'alertInfoIconBackground',
          iconColor: 'alertInfoIcon',
          labelColor: 'alertInfoLabel',
          titleColor: 'alertInfoTitle',
        },
        icon: 'information-circle-outline',
      };

    case 'success':
      return {
        colors: {
          backgroundColor: 'alertSuccessBackground',
          borderColor: 'alertSuccessBorder',
          iconBackgroundColor: 'alertSuccessIconBackground',
          iconColor: 'alertSuccessIcon',
          labelColor: 'alertSuccessLabel',
          titleColor: 'alertSuccessTitle',
        },
        icon: 'check-circle-outline',
      };
  }
}

export function Alert(props: React.PropsWithChildren<AlertProps>) {
  const {
    buttonIsLoading,
    buttonOnClick,
    buttonText,
    buttonTo,
    buttonTx,
    buttonTxArgs,
    children,
    secondaryButtonOnClick,
    secondaryButtonText,
    secondaryButtonTx,
    secondaryButtonTxArgs,
    subtitle,
    subtitleTx,
    subtitleTxArgs,
    title,
    titleTx,
    titleTxArgs,
    variant,
  } = props;

  const { colors, icon } = getAlertVariant(variant);

  return (
    <Styled.Alert
      borderColor={colors.borderColor}
      backgroundColor={colors.backgroundColor}
      r={8}
    >
      <Styled.AlertIconBox
        backgroundColor={colors.iconBackgroundColor}
        flexAlign="center"
        flexJustify="center"
        height="100%"
      >
        <Icon color={colors.iconColor} name={icon} size={40} />
      </Styled.AlertIconBox>

      <Box
        flex
        flexAlign="center"
        flexGrow={1}
        flexJustify="between"
        flexWrap="wrap"
        height="100%"
        gap={16}
        p={16}
      >
        {children || (
          <React.Fragment>
            <Box flex flexDirection="column">
              <Text
                color={colors.titleColor}
                text={title}
                tx={titleTx}
                txArgs={titleTxArgs}
                variant="bodyLgBold"
              />
              <Text
                color={colors.labelColor}
                text={subtitle}
                tx={subtitleTx}
                txArgs={subtitleTxArgs}
                variant="bodyMd"
              />
            </Box>

            {(secondaryButtonOnClick || buttonOnClick || buttonTo) && (
              <Box flex gap={24}>
                {secondaryButtonOnClick && (
                  <Button
                    variant="tertiary"
                    onClick={secondaryButtonOnClick}
                    text={secondaryButtonText}
                    tx={secondaryButtonTx}
                    txArgs={secondaryButtonTxArgs}
                  />
                )}
                {(buttonOnClick || buttonTo) && (
                  <Button
                    isLoading={buttonIsLoading}
                    onClick={buttonOnClick}
                    text={buttonText}
                    to={buttonTo}
                    tx={buttonTx}
                    txArgs={buttonTxArgs}
                    variant="primary"
                  />
                )}
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </Styled.Alert>
  );
}
