import {
  createOfferThunk,
  getOfferCategoriesThunk,
  getOfferThunk,
  getOffersLinkThunk,
  getOffersThunk,
  toggleIsDisabledThunk,
  updateOfferThunk,
} from './offers.actions';
import { offerCategoriesAdapter, partialOffersAdapter } from './offers.adapter';
import { OffersBuilder } from './offers.types';

function updateOfferBuild(builder: OffersBuilder) {
  return builder
    .addCase(updateOfferThunk.pending, (state) => {
      state.updateOffer.status = 'pending';
      state.updateOffer.error = null;
    })
    .addCase(updateOfferThunk.fulfilled, (state, action) => {
      partialOffersAdapter.updateOne(state.offers.data, {
        changes: action.payload,
        id: action.payload.offerKey,
      });

      if (state.offer.data) {
        state.offer.data.editMeta = action.payload.editMeta;
        state.offer.data.startDate = action.payload.startDate;
        state.offer.data.endDate = action.payload.endDate;
        state.offer.data.title = action.payload.title;
      }

      state.updateOffer.status = 'idle';
    })
    .addCase(updateOfferThunk.rejected, (state, action) => {
      state.updateOffer.status = 'idle';
      state.updateOffer.error = action.error;
    });
}

function toggleIsDisabledBuild(builder: OffersBuilder) {
  return builder
    .addCase(toggleIsDisabledThunk.pending, (state) => {
      state.toggleIsDisabled.status = 'pending';
      state.toggleIsDisabled.error = null;
    })
    .addCase(toggleIsDisabledThunk.fulfilled, (state, action) => {
      partialOffersAdapter.updateOne(state.offers.data, {
        changes: {
          disabledAt: action.payload.disabledAt,
          editMeta: action.payload.editMeta,
        },
        id: action.payload.offerKey,
      });

      if (state.offer.data) {
        state.offer.data.disabledAt = action.payload.disabledAt;
        state.offer.data.editMeta = action.payload.editMeta;
      }

      state.toggleIsDisabled.status = 'idle';
    })
    .addCase(toggleIsDisabledThunk.rejected, (state, action) => {
      state.toggleIsDisabled.status = 'idle';
      state.toggleIsDisabled.error = action.error;
    });
}

function createOfferBuild(builder: OffersBuilder) {
  return builder
    .addCase(createOfferThunk.pending, (state) => {
      state.createOffer.status = 'pending';
      state.createOffer.error = null;
    })
    .addCase(createOfferThunk.fulfilled, (state, action) => {
      partialOffersAdapter.addOne(state.offers.data, action.payload);

      state.createOffer.status = 'idle';
      state.createOffer.data = action.payload.offerKey;
    })
    .addCase(createOfferThunk.rejected, (state, action) => {
      state.createOffer.status = 'idle';
      state.createOffer.error = action.error;
    });
}

function getOfferBuild(builder: OffersBuilder) {
  return builder
    .addCase(getOfferThunk.pending, (state) => {
      state.offer.status = 'pending';
      state.offer.error = null;
    })
    .addCase(getOfferThunk.fulfilled, (state, action) => {
      state.offer.status = 'idle';
      state.offer.data = action.payload;
    })
    .addCase(getOfferThunk.rejected, (state, action) => {
      state.offer.status = 'idle';
      state.offer.error = action.error;
    });
}

function getOffersBuild(builder: OffersBuilder) {
  return builder
    .addCase(getOffersThunk.pending, (state, action) => {
      state.offers.status = 'pending';
      state.offers.error = null;
      state.offers.pagination = action.meta.arg;
    })
    .addCase(getOffersThunk.fulfilled, (state, action) => {
      state.offers.status = 'idle';
      if (state.offers.search) {
        partialOffersAdapter.setAll(state.offers.data, action.payload);
      } else {
        partialOffersAdapter.setMany(state.offers.data, action.payload);
      }
    })
    .addCase(getOffersThunk.rejected, (state, action) => {
      state.offers.status = 'idle';
      state.offers.error = action.error;
    });
}

function getOfferCategoriesBuild(builder: OffersBuilder) {
  return builder
    .addCase(getOfferCategoriesThunk.pending, (state) => {
      state.offerCategories.status = 'pending';
      state.offerCategories.error = null;
    })
    .addCase(getOfferCategoriesThunk.fulfilled, (state, action) => {
      state.offerCategories.status = 'idle';

      offerCategoriesAdapter.setAll(state.offerCategories.data, action.payload);
    })
    .addCase(getOfferCategoriesThunk.rejected, (state, action) => {
      state.offerCategories.status = 'idle';
      state.offerCategories.error = action.error;
    });
}

function getOffersLinkBuild(builder: OffersBuilder) {
  return builder
    .addCase(getOffersLinkThunk.pending, (state) => {
      state.offersLink.status = 'pending';
      state.offersLink.error = null;
    })
    .addCase(getOffersLinkThunk.fulfilled, (state, action) => {
      state.offersLink.status = 'idle';
      state.offersLink.data = action.payload;
    })
    .addCase(getOffersLinkThunk.rejected, (state, action) => {
      state.offersLink.status = 'idle';
      state.offersLink.error = action.error;
    });
}

export function extraReducers(builder: OffersBuilder) {
  getOfferCategoriesBuild(builder);

  createOfferBuild(builder);

  getOfferBuild(builder);
  getOffersBuild(builder);

  updateOfferBuild(builder);

  toggleIsDisabledBuild(builder);

  getOffersLinkBuild(builder);
}
