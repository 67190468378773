import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { reactionsAdapter } from './reactions.adapter';

export namespace ReactionsSelector {
  const selectSelf = (state: RootState) => state.reactions.reactions;

  export const selectIsLoading = createSelector(
    selectSelf,
    (reactions) => reactions.status === 'pending',
  );

  const selectData = createSelector(selectSelf, (reactions) => reactions.items);

  const reactionsSelectors = reactionsAdapter.getSelectors(selectData);

  export const selectIsEmpty = createSelector(
    selectSelf,
    (reactions) => reactions.items.ids.length === 0,
  );

  export const selectAll = reactionsSelectors.selectAll;

  export const selectById = reactionsSelectors.selectById;

  export const selectIds = reactionsSelectors.selectIds;

  export const selectStartCursor = createSelector(
    selectSelf,
    (reactions) => reactions.pageInfo.startCursor,
  );

  export const selectEndCursor = createSelector(
    selectSelf,
    (reactions) => reactions.pageInfo.endCursor,
  );

  export const selectPageSize = createSelector(
    selectSelf,
    (reactions) => reactions.pageSize,
  );

  export const selectHasPreviousPage = createSelector(
    selectSelf,
    (reactions) => reactions.pageInfo.hasPreviousPage,
  );

  export const selectHasNextPage = createSelector(
    selectSelf,
    (reactions) => reactions.pageInfo.hasNextPage,
  );

  export const selectTotalCount = createSelector(
    selectSelf,
    (reactions) => reactions.totalCount,
  );
}

export namespace CreateReactionSelector {
  const selectSelf = (state: RootState) => state.reactions.createReaction;

  export const selectStatus = createSelector(
    selectSelf,
    (createReaction) => createReaction.status,
  );
}

export namespace DeleteReactionSelector {
  const selectSelf = (state: RootState) => state.reactions.deleteReaction;

  export const selectStatus = createSelector(
    selectSelf,
    (deleteReaction) => deleteReaction.status,
  );
}
