import { INITIAL_DATA_STATE } from '../store.constants';
import { AuthState } from './auth.types';

export const initialAuthState: AuthState = {
  authState: {
    ...INITIAL_DATA_STATE,
    data: {
      accountVerified: false,
      authenticated: false,
      initializing: true,
      isEmulating: !!sessionStorage.getItem('EMULATE_USER'),
    },
  },

  authAccount: INITIAL_DATA_STATE,
  sendEmailVerification: INITIAL_DATA_STATE,
  customSignInToken: INITIAL_DATA_STATE,
};
