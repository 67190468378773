import { Box, BoxProps } from '@orbiapp/components';

import { Styled } from './paper-content-container.styled';

export function PaperContentContainer(props: BoxProps) {
  return (
    <Styled.PaperContentContainer
      flex
      flexAlign="start"
      flexJustify="center"
      width="100%"
      height="100%"
      overflow="auto"
      {...props}
    />
  );
}

export function InnerPaperContentContainer(props: BoxProps) {
  return (
    <Styled.InnerPaperContentContainer
      overflow="visible"
      flex
      flexDirection="column"
      width={900}
      gap={32}
      maxWidth="100%"
      {...props}
    />
  );
}

export function InnerPaperContentContainerSection(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box r={8} p={16} backgroundColor="paperBackground" {...rest}>
      {children}
    </Box>
  );
}

export function InnerPaperContentContainerSectionsContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box flex flexDirection="column" gap={16} {...rest}>
      {children}
    </Box>
  );
}
