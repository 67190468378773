import React from 'react';

import { ImageData } from '../../models';
import { isNumber } from '../../utils';
import { Box } from '../box';
import { OnDarkIconButton } from '../icon-button';
import { Image } from '../image';
import { Portal } from '../portal';
import { Text } from '../text';
import { Styled } from './image-gallery.styled';

export function useImageGallery() {
  const [state, setState] = React.useState<{
    isOpen: boolean;
    activeIndex: number | null;
  }>({
    isOpen: false,
    activeIndex: null,
  });

  const showImage = (index: number) => {
    setState({ isOpen: true, activeIndex: index });
  };

  const showGrid = () => {
    setState({ isOpen: true, activeIndex: null });
  };

  const close = () => {
    setState({ isOpen: false, activeIndex: null });
  };

  const open = (index: number) => {
    setState({ isOpen: true, activeIndex: index ?? null });
  };

  return { ...state, showImage, showGrid, close, open };
}

export type UseImageGallery = ReturnType<typeof useImageGallery>;

interface ImageGalleryProps extends UseImageGallery {
  images: ImageData[];
}

export function ImageGallery(props: ImageGalleryProps) {
  if (!props.isOpen) {
    return null;
  }

  const renderImage = (imageData: ImageData, index: number) => {
    const handleImagePress = () => {
      props.showImage(index);
    };

    return (
      <Styled.ImageGalleryGridItem height={180} overflow="hidden">
        <Image
          cursor="pointer"
          objectFit="cover"
          onClick={handleImagePress}
          width="100%"
          height="100%"
          key={index}
          src={imageData.thumbnail512.url}
        />
      </Styled.ImageGalleryGridItem>
    );
  };

  const isFullscreen = isNumber(props.activeIndex);

  const goToNextImage = () => {
    if (isNumber(props.activeIndex)) {
      props.showImage(props.activeIndex + 1);
    }
  };

  const goToPreviousImage = () => {
    if (isNumber(props.activeIndex)) {
      props.showImage(props.activeIndex - 1);
    }
  };

  return (
    <Portal>
      <Box
        flex
        flexDirection="column"
        zIndex={15000}
        inset
        fixed
        backgroundColor="imageGalleryBackground"
        flexJustify={isFullscreen ? 'between' : 'start'}
        height="100vh"
      >
        <Box width="100%" flex flexJustify="between" p={32}>
          <OnDarkIconButton onClick={props.close} icon="x-mark" />
          {isFullscreen && (
            <OnDarkIconButton
              onClick={props.showGrid}
              icon="squares-2x2-outline"
            />
          )}
        </Box>

        {isFullscreen && isNumber(props.activeIndex) ? (
          <Box
            flex
            flexGrow={1}
            flexJustify="center"
            maxHeight="100%"
            overflow="hidden"
          >
            <Image
              src={props.images[props.activeIndex].original.url}
              mx="auto"
              maxWidth="100%"
              maxHeight="100%"
              objectFit="contain"
            />
          </Box>
        ) : (
          <Styled.ImageGalleryGrid>
            {props.images.map(renderImage)}
          </Styled.ImageGalleryGrid>
        )}

        {isNumber(props.activeIndex) && props.images.length > 1 && (
          <Box
            flex
            flexAlign="center"
            flexJustify="center"
            gap={16}
            mt={8}
            p={32}
          >
            <OnDarkIconButton
              disabled={props.activeIndex === 0}
              onClick={goToPreviousImage}
              icon="chevron-left"
            />

            <Text
              color="textLight"
              text={`${props.activeIndex + 1} / ${props.images.length}`}
            />

            <OnDarkIconButton
              disabled={props.activeIndex === props.images.length - 1}
              onClick={goToNextImage}
              icon="chevron-right"
            />
          </Box>
        )}
      </Box>
    </Portal>
  );
}
