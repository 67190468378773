import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledInput,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Switch,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  CreateMembershipFreetextQuestionValidation,
  FREETEXT_QUESTION_MAX_LENGTH,
  MembershipFreetextQuestionForm,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { RequestedInfoFormHeader } from '../requested-info-form-header';

function ToggleIsRequired(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { setValue, watch } = useFormContext<MembershipFreetextQuestionForm>();
  const { isRequired } = watch();

  const toggleIsRequired = () => setValue('isRequired', !isRequired);

  return (
    <Switch
      checked={isRequired}
      disabled={isLoading}
      onClick={toggleIsRequired}
      tx="label.memberships.create-membership.questions.is-required"
    />
  );
}

export function EditFreeTextForm(props: {
  closeModal: () => void;
  defaultValues: MembershipFreetextQuestionForm;
  isLoading?: boolean;
  onSubmitted: (data: MembershipFreetextQuestionForm) => void;
}) {
  const { closeModal, defaultValues, isLoading, onSubmitted } = props;

  const formMethods = useForm<MembershipFreetextQuestionForm>({
    resolver: joiResolver(CreateMembershipFreetextQuestionValidation),
    defaultValues,
  });

  const addFreeTextQuestion = formMethods.handleSubmit(
    (data) => {
      onSubmitted(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('updateMembershipFreetextQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.memberships.view-membership.questions.edit-title" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <Box flexDirection="column" flex gap={24}>
            <ToggleIsRequired isLoading={isLoading} />

            <ControlledInput
              disabled={isLoading}
              labelTx="label.memberships.create-membership.questions.question"
              maxLength={FREETEXT_QUESTION_MAX_LENGTH}
              name="question"
              required
            />
          </Box>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button onClick={closeModal} tx="button.cancel" variant="tertiary" />
          <Button
            isLoading={isLoading}
            onClick={addFreeTextQuestion}
            tx="button.save"
            variant="secondary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </FormProvider>
  );
}

export function FreeTextForm(props: {
  defaultValues: MembershipFreetextQuestionForm;
  disableSelectType?: boolean;
  isLoading?: boolean;
  onSubmitted: (data: MembershipFreetextQuestionForm) => void;
  setDefaultValues: (data: MembershipFreetextQuestionForm) => void;
  titleTx: TxString;
}) {
  const {
    defaultValues,
    disableSelectType,
    isLoading,
    onSubmitted,
    setDefaultValues,
    titleTx,
  } = props;

  const formMethods = useForm<MembershipFreetextQuestionForm>({
    resolver: joiResolver(CreateMembershipFreetextQuestionValidation),
    defaultValues,
  });

  const addFreeTextQuestion = formMethods.handleSubmit(
    (data) => {
      onSubmitted(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('createMembershipFreetextQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <RequestedInfoFormHeader
          defaultValues={defaultValues}
          disableSelectType={disableSelectType}
          setDefaultValues={setDefaultValues}
          titleTx={titleTx}
        />

        <Box flex flexDirection="column" gap={24}>
          <ToggleIsRequired isLoading={isLoading} />

          <ControlledInput
            disabled={isLoading}
            labelTx="label.memberships.create-membership.questions.question"
            maxLength={FREETEXT_QUESTION_MAX_LENGTH}
            name="question"
            required
          />
        </Box>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={addFreeTextQuestion}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}
