import { useDebounce } from '@orbiapp/components';
import React from 'react';

import { PartialUser, StatusType } from '../models';
import { searchUsersThunk, useDispatch } from '../store';

export function useUserSearch() {
  const [searchResult, setSearchResult] = React.useState<PartialUser[]>([]);
  const [status, setStatus] = React.useState<StatusType>('idle');
  const [searchString, setSearchString] = React.useState<string>('');

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const search = (searchString: string) => {
    setStatus('idle');

    setSearchString(searchString);

    if (!searchString.length) return;

    debounce(async () => {
      setStatus('pending');

      const res = await dispatch(searchUsersThunk({ searchString }));

      if (Array.isArray(res.payload)) {
        setSearchResult(res.payload);
      }

      setStatus('completed');
    });
  };

  const clearSearch = () => {
    setSearchString('');
    setSearchResult([]);
  };

  return {
    searchResult,
    status,
    searchString,
    setSearchString,
    search,
    clearSearch,
  };
}
