import { fromScu, getUID } from '@orbiapp/components';

import {
  Activity,
  ActivityCategory,
  ActivityCheckbox,
  ActivityFreeText,
  ActivityLink,
  ActivityMultiChoice,
  ActivityRequestedInfoOption,
  CoHost,
  DiscountCode,
  LocationCity,
  MembershipTypeDiscount,
  PartialOrgNode,
  RequestedInfo,
  RequestedInfoType,
  RequiredMembershipType,
  TicketType,
  TicketTypeStats,
} from '../../../../../models';
import { getDescriptionMetadata } from '../../../../../utils';
import {
  ActivityCategoryDto,
  ActivityDto,
  CohostDto,
  DiscountCodeDto,
  LinkDto,
  MembershipTypeDiscountDto,
  MembershipTypeDto,
  MultiChoiceOptionDto,
  OrgNodeDto,
  TicketTypeDto,
  TicketTypeStatsDto,
} from './activities.dto';

const discountCodeMapper = (discountCode: DiscountCodeDto): DiscountCode => ({
  code: discountCode.code,
  discount: fromScu(discountCode.discount),
  discountCodeKey: discountCode.discountCodeKey,
  discountType: 'fixed',
  isNew: false,
});

const membershipTypeDiscountMapper = (
  membershipTypeDiscount: MembershipTypeDiscountDto,
): MembershipTypeDiscount => ({
  discount: fromScu(membershipTypeDiscount.discount),
  discountType: 'fixed',
  isNew: false,
  membershipTypeDiscountKey: membershipTypeDiscount.membershipTypeDiscountKey,
  membershipTypeKey: membershipTypeDiscount.membershipTypeKey,
  departmentName: membershipTypeDiscount.departmentName,
  logoUrl: membershipTypeDiscount.logoUrl,
  name: membershipTypeDiscount.name,
});

const membershipTypeMapper = (
  membershipType: MembershipTypeDto,
): RequiredMembershipType => ({
  departmentName: membershipType.departmentName,
  key: getUID(),
  logoUrl: membershipType.logoUrl,
  membershipTypeKey: membershipType.membershipTypeKey,
  name: membershipType.name,
});

const ticketTypeMapper = (ticketType: TicketTypeDto): TicketType => ({
  description: ticketType.description,
  discountCodes: ticketType.discountCodes?.map(discountCodeMapper) ?? null,
  endDate: ticketType.endDate,
  isNew: false,
  isTransferable: ticketType.isTransferable,
  maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
  membershipTypeDiscounts:
    ticketType.membershipTypeDiscounts?.map(membershipTypeDiscountMapper) ??
    null,
  name: ticketType.name,
  price: fromScu(ticketType.price),
  requiredMembershipTypes: ticketType.requiredMembershipTypes
    ? {
        combinationType: ticketType.requiredMembershipTypes.combinationType,
        membershipApplicationRequirements:
          ticketType.requiredMembershipTypes.membershipTypes.map(
            membershipTypeMapper,
          ),
      }
    : null,
  startDate: ticketType.startDate,
  ticketCount: ticketType.ticketCount,
  ticketTypeKey: ticketType.ticketTypeKey,
});

const requestedInfoMapper = (
  checkboxQuestions: ActivityDto['checkboxQuestions'],
  freetextQuestions: ActivityDto['freetextQuestions'],
  multichoiceQuestions: ActivityDto['multichoiceQuestions'],
) => {
  const requestedInfo: RequestedInfo[] = [];

  checkboxQuestions?.forEach((checkboxQuestion) => {
    const checkbox: ActivityCheckbox = {
      enablePriceIncrement: checkboxQuestion.priceIncrement > 0,
      id: checkboxQuestion.checkboxQuestionKey,
      index: checkboxQuestion.index,
      isNew: false,
      priceIncrement: fromScu(checkboxQuestion.priceIncrement),
      question: checkboxQuestion.question,
      ticketTypeKeys: checkboxQuestion.ticketTypeKeys,
      type: RequestedInfoType.Checkbox,
    };
    requestedInfo.push(checkbox);
  });

  freetextQuestions?.forEach((freetextQuestion) => {
    const freetext: ActivityFreeText = {
      id: freetextQuestion.freetextQuestionKey,
      index: freetextQuestion.index,
      isNew: false,
      isRequired: freetextQuestion.isRequired,
      question: freetextQuestion.question,
      ticketTypeKeys: freetextQuestion.ticketTypeKeys,
      type: RequestedInfoType.FreeText,
    };
    requestedInfo.push(freetext);
  });

  const multichoiceOptionMapper = (
    option: MultiChoiceOptionDto,
  ): ActivityRequestedInfoOption => ({
    id: option.multichoiceOptionKey,
    index: option.index,
    isNew: false,
    label: option.label,
    priceIncrement: fromScu(option.priceIncrement),
  });

  multichoiceQuestions?.forEach((multichoiceQuestion) => {
    const multichoice: ActivityMultiChoice = {
      enablePriceIncrement: multichoiceQuestion.multichoiceOptions.some(
        (option) => option.priceIncrement > 0,
      ),
      id: multichoiceQuestion.multichoiceQuestionKey,
      index: multichoiceQuestion.index,
      isNew: false,
      isRequired: multichoiceQuestion.isRequired,
      options: multichoiceQuestion.multichoiceOptions.map(
        multichoiceOptionMapper,
      ),
      question: multichoiceQuestion.question,
      ticketTypeKeys: multichoiceQuestion.ticketTypeKeys,
      type: RequestedInfoType.MultiChoice,
    };
    requestedInfo.push(multichoice);
  });

  return requestedInfo;
};

const orgNodesBranchMapper = (branch: OrgNodeDto): PartialOrgNode => ({
  name: branch.name,
  orgNodeKey: branch.orgNodeKey,
  parentKey: branch.parentKey,
  type: branch.type,
});

const linkMapper = (link: LinkDto): ActivityLink => ({
  label: link.label,
  url: link.url,
});

const ticketTypeStatsMapper = (
  ticketTypeStats: TicketTypeStatsDto,
): TicketTypeStats => ({
  consumedTicketCount: ticketTypeStats.consumedTicketCount,
  incomeSum: fromScu(ticketTypeStats.incomeSum),
  name: ticketTypeStats.name,
  numberOfSoldTickets: ticketTypeStats.numberOfSoldTickets,
  ticketTypeKey: ticketTypeStats.ticketTypeKey,
});

const cohostMapper = (cohost: CohostDto): CoHost => ({
  cohostRequestStatus: cohost.cohostRequestStatus,
  departmentKey: cohost.departmentKey,
  logoUrl: cohost.logoUrl,
  name: cohost.name,
  orgNodesBranch: cohost.orgNodesBranch.map(orgNodesBranchMapper),
});

const categoryMapper = (category: ActivityCategoryDto): ActivityCategory => ({
  activityCategoryKey: category.activityCategoryKey,
  name: category.name,
});

export const getActivityMapper = (
  activityDto: ActivityDto,
  options: { enableTags: boolean },
): Activity => {
  let description = activityDto.description;
  let tags: string[] | null = null;
  let city: LocationCity | null = null;

  if (options.enableTags) {
    const descriptionMetadata = getDescriptionMetadata(activityDto.description);

    description = descriptionMetadata.description;
    tags = descriptionMetadata.tags;
    city = descriptionMetadata.city;
  }

  const ticketTypes = activityDto.ticketTypes?.map(ticketTypeMapper) ?? null;
  const requestedInfo = requestedInfoMapper(
    activityDto.checkboxQuestions,
    activityDto.freetextQuestions,
    activityDto.multichoiceQuestions,
  ).sort((a, b) => a.index - b.index);

  const showAttendance =
    activityDto.showAttendance || activityDto.showAttendees;

  const activity: Activity = {
    activityKey: activityDto.activityKey,
    categories: activityDto.categories.map(categoryMapper),
    cohosts: activityDto.cohosts?.map(cohostMapper) ?? null,
    contactDetails: {
      email: activityDto.contactDetails.email,
      name: activityDto.contactDetails.name,
      phone: activityDto.contactDetails.phone,
    },
    coverImage: activityDto.coverImageData,
    createdAt: activityDto.createdAt,
    description,
    endDate: activityDto.endDate,
    host: {
      departmentKey: activityDto.host.departmentKey,
      logoUrl: activityDto.host.logoUrl,
      name: activityDto.host.name,
      orgNodesBranch: activityDto.host.orgNodesBranch.map(orgNodesBranchMapper),
    },
    links: activityDto.links?.map(linkMapper) ?? null,
    location: {
      city,
      coordinates: activityDto.location.coordinates,
      description: activityDto.location.description,
      label: activityDto.location.label,
      link: activityDto.location.link,
    },
    questionsActivated: activityDto.questionsActivated,
    requestedInfo: requestedInfo.length !== 0 ? requestedInfo : null,
    segmentation: activityDto.segmentation,
    startDate: activityDto.startDate,
    stats: {
      numberOfClicks: activityDto.stats.numberOfClicks,
      numberOfQuestions: activityDto.stats.numberOfQuestions,
      numberOfSaved: activityDto.stats.numberOfSaved,
      ticketTypeStats: activityDto.stats.ticketTypeStats.map(
        ticketTypeStatsMapper,
      ),
    },
    tags,
    ticketTypes,
    title: activityDto.title,

    showAttendance,
  };

  return activity;
};
