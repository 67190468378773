import styled, { css } from 'styled-components';

import { stringOrPx } from '../../../utils';

const TablePlaceholderRow = styled.tr`
  height: 56px;

  td {
    padding: 10px;
    position: relative;
  }
`;

const TablePlaceholderCell = styled.td`
  ${(props) => {
    const { width } = props;

    return css`
      width: ${stringOrPx(width)};
      min-width: ${stringOrPx(width)};
    `;
  }};
`;

export const Styled = { TablePlaceholderRow, TablePlaceholderCell };
