import styled, { css } from 'styled-components';

import { Box } from '../box';
import { StyledModalProps } from './modal.types';

const ModalBackdrop = styled(Box)<StyledModalProps>`
  ${(props) => {
    const { theme, isOpen } = props;

    return css`
      background-color: ${theme.colors.modalOverlayBackground};
      pointer-events: none;
      opacity: 0;
      transition: ${theme.transitions.default};

      ${isOpen &&
      css`
        opacity: 1;
        pointer-events: all;
      `}
    `;
  }}
`;

const Modal = styled(Box)<StyledModalProps>`
  ${(props) => {
    const { theme, isOpen } = props;

    return css`
      transition: opacity ${theme.transitions.default};
      pointer-events: none;
      opacity: 0;
      background-color: ${theme.colors.modalBackground};
      max-width: 90%;
      max-height: 90%;
      border-radius: 8px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      ${isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
      `}
    `;
  }}
`;

const ModalHeaderContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.modalBackground};
    `;
  }}
`;

const ModalFooterContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.modalBackground};
    `;
  }}
`;

export const Styled = {
  Modal,
  ModalBackdrop,
  ModalFooterContainer,
  ModalHeaderContainer,
};
