import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './account.build';
import { initialAccountState } from './account.initial';
import { reducers } from './account.reducers';

const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers,
  extraReducers,
});

export const accountReducer = accountSlice.reducer;
