import { getObjectValue } from './object';

type KeyMatching<O, V> = {
  [K in keyof O]-?: O[K] extends V ? K : never;
}[keyof O];

export function sumOver<O extends Record<string, any>>(
  array: O[],
  key: KeyMatching<O, number>,
) {
  const returnValue = array.reduce((sum, curr) => sum + curr[key], 0);

  if (isNaN(returnValue)) return 0;

  return returnValue;
}

export function createUniqueArray<T>(data: T[], key: keyof T) {
  return Array.from(new Map(data.map((value) => [value[key], value])).values());
}

export function countOccurances<T extends Record<string, any>>(
  arr: T[],
  key: keyof T,
) {
  return arr.reduce((acc, curr) => {
    if (acc[curr[key]]) {
      acc[curr[key]]++;
      return acc;
    }

    acc[curr[key]] = 1;
    return acc;
  }, {} as Record<string, number>);
}

export function sortArray<T extends object>(
  items: T[],
  orderBy: string,
  sortingOrder: 'asc' | 'desc' = 'asc',
): T[] {
  if (items.length < 2) {
    return items;
  }

  return [...items].sort((a, b) => {
    const orderByA = getObjectValue(a, orderBy);
    const orderByB = getObjectValue(b, orderBy);

    if (typeof orderByA === 'number' && typeof orderByB === 'number') {
      return sortingOrder === 'asc' ? orderByA - orderByB : orderByB - orderByA;
    }

    if (typeof orderByA === 'string' && typeof orderByB === 'string') {
      return sortingOrder === 'asc'
        ? orderByA.localeCompare(orderByB)
        : orderByB.localeCompare(orderByA);
    }

    if (typeof orderByA === 'boolean' && typeof orderByB === 'boolean') {
      return sortingOrder === 'asc'
        ? Number(orderByA) - Number(orderByB)
        : Number(orderByB) - Number(orderByA);
    }

    return 0;
  });
}
