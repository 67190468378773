import styled from 'styled-components';

import { Label } from '../text';
import { InputLabelProps } from './input-label.types';

const InputLabel = styled(Label)<InputLabelProps>`
  pointer-events: none;
  position: absolute;
`;

export const Styled = { InputLabel };
