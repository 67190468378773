import React from 'react';

import { Box } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './checkbox.styled';
import { CheckboxProps } from './checkbox.types';

function _Checkbox(
  props: CheckboxProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    checked,
    disabled,
    labelVariant = 'bodyMd',
    labelWidth,
    onChange,
    subtitle,
    subtitleTx,
    subtitleTxArgs,
    text,
    tx,
    txArgs,
    ...rest
  } = props;

  const toggleCheckbox = () => {
    onChange?.();
  };

  const nativeCheckbox = (
    <input
      checked={checked}
      hidden
      onChange={onChange}
      ref={ref}
      type="checkbox"
    />
  );

  const hasSubtitle = !!subtitleTx || !!subtitle;

  if (tx || text) {
    return (
      <Styled.CheckboxWithLabelBox
        aria-disabled={disabled}
        cursor="pointer"
        disabled={disabled}
        flex
        flexAlign={hasSubtitle ? 'start' : 'center'}
        gap={8}
        onClick={toggleCheckbox}
        role="checkbox"
        width="fit-content"
        hasSubTitle={hasSubtitle}
      >
        {nativeCheckbox}

        <Styled.Checkbox checked={checked} disabled={disabled} {...rest}>
          <Styled.CheckboxBackground />

          <Styled.IconBox>
            <Icon name="check" size={16} />
          </Styled.IconBox>
        </Styled.Checkbox>

        <Box flex flexDirection="column">
          <Text
            as="span"
            text={text}
            tx={tx}
            txArgs={txArgs}
            variant={labelVariant}
            width={labelWidth}
          />
          {hasSubtitle && (
            <Styled.CheckboxSubtitle
              text={subtitle}
              tx={subtitleTx}
              txArgs={subtitleTxArgs}
              variant="bodySm"
            />
          )}
        </Box>
      </Styled.CheckboxWithLabelBox>
    );
  }

  return (
    <Styled.Checkbox checked={checked} disabled={disabled} {...rest}>
      {nativeCheckbox}

      <Styled.CheckboxBackground />

      <Styled.IconBox onClick={toggleCheckbox}>
        <Icon name="check" size={16} />
      </Styled.IconBox>
    </Styled.Checkbox>
  );
}

export const Checkbox = React.forwardRef(_Checkbox);
