import Joi from 'joi';

import {
  MembershipCheckboxQuestionSchema,
  MembershipFreetextQuestionSchema,
  MembershipMultichoiceQuestionSchema,
} from './questions.schema';

export const CreateMembershipCheckboxQuestionValidation = Joi.object({
  isRequired: MembershipCheckboxQuestionSchema.isRequired,
  key: MembershipCheckboxQuestionSchema.key,
  question: MembershipCheckboxQuestionSchema.question,
  type: MembershipCheckboxQuestionSchema.type,
});

export const CreateMembershipMultichoiceQuestionValidation = Joi.object({
  isRequired: MembershipMultichoiceQuestionSchema.isRequired,
  key: MembershipMultichoiceQuestionSchema.key,
  options: MembershipMultichoiceQuestionSchema.createOptions,
  question: MembershipMultichoiceQuestionSchema.question,
  type: MembershipMultichoiceQuestionSchema.type,
});

export const CreateMembershipFreetextQuestionValidation = Joi.object({
  isRequired: MembershipFreetextQuestionSchema.isRequired,
  key: MembershipFreetextQuestionSchema.key,
  question: MembershipFreetextQuestionSchema.question,
  type: MembershipFreetextQuestionSchema.type,
});
