import React from 'react';

type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export function useFilter<T extends Record<string, any>>(
  items: T[],
  ...keys: StringValues<T>[]
) {
  const [value, setValue] = React.useState('');

  const handleValueChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const filteredItems = items.filter((item) => {
    return keys.some((key) => {
      return item[key].toLowerCase().includes(value.toLowerCase());
    });
  });

  return { filteredItems, handleValueChange, value };
}
