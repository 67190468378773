import { INITIAL_DATA_STATE } from '../store.constants';
import {
  addressesAdapter,
  departmentsAdapter,
  membershipTypesAdapter,
  usersAdapter,
} from './search.adapter';
import { SearchState } from './search.types';

export const initialSearchState: SearchState = {
  addresses: {
    ...INITIAL_DATA_STATE,
    data: addressesAdapter.getInitialState(),
  },
  coordinates: INITIAL_DATA_STATE,
  membershipTypes: {
    ...INITIAL_DATA_STATE,
    data: membershipTypesAdapter.getInitialState(),
  },
  users: {
    ...INITIAL_DATA_STATE,
    data: usersAdapter.getInitialState(),
  },
  departments: {
    ...INITIAL_DATA_STATE,
    data: departmentsAdapter.getInitialState(),
  },
};
