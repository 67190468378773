import { Confirm } from '@orbiapp/components';
import React from 'react';
import { useBlocker } from 'react-router';

import {
  CreateIntegratedMembershipTypeSelector,
  useSelector,
} from '../../../../../store';

export function CreateIntegratedMembershipFormNavBlocker(props: {
  shouldBlock: boolean;
}) {
  const { shouldBlock } = props;

  const createdIntegratedMembershipTypeKey = useSelector(
    CreateIntegratedMembershipTypeSelector.selectData,
  );

  const blocker = useBlocker(shouldBlock);

  const nextRouteIsMembershipRoute = blocker.location?.pathname.includes(
    '/memberships/create-integration',
  );

  React.useEffect(() => {
    if (nextRouteIsMembershipRoute || createdIntegratedMembershipTypeKey) {
      blocker.proceed?.();
    }
  }, [createdIntegratedMembershipTypeKey, blocker, nextRouteIsMembershipRoute]);

  return (
    <Confirm
      isOpen={
        blocker.state === 'blocked' &&
        shouldBlock &&
        !nextRouteIsMembershipRoute
      }
      onCancel={blocker.reset || (() => {})}
      onConfirm={blocker.proceed || (() => {})}
      cancelTx="prompt.unsaved-changes.cancel"
      confirmTx="prompt.unsaved-changes.confirm"
      titleTx="prompt.unsaved-changes.title"
      messageTx="prompt.unsaved-changes.message"
    />
  );
}
