import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { locationsAdapter } from './locations.adapter';

export namespace LocationsSelector {
  const selectSelf = (state: RootState) => state.locations.locations;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = locationsAdapter.getSelectors(
    (state: RootState) => state.locations.locations.data,
  );

  export const selectAll = selectors.selectAll;
}
