import Joi from 'joi';

import { CoordinatesValidation } from '../../shared';
import {
  LOCATION_CITIES,
  LOCATION_DESCRIPTION_MAX_LENGTH,
  LOCATION_LABEL_MAX_LENGTH,
  LOCATION_LINK_MAX_LENGTH,
} from './location.constants';
import { LocationErrors } from './location.errors';

export namespace LocationSchema {
  export const label = Joi.string()
    .trim()
    .max(LOCATION_LABEL_MAX_LENGTH)
    .required()
    .messages(LocationErrors.label);

  export const description = Joi.string()
    .trim()
    .max(LOCATION_DESCRIPTION_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(LocationErrors.description);

  export const coordinates = CoordinatesValidation.allow(null).required();

  export const link = Joi.string()
    .trim()
    .max(LOCATION_LINK_MAX_LENGTH)
    .allow(null, '')
    .required();

  export const city = Joi.valid(...LOCATION_CITIES)
    .allow(null)
    .required();
}
