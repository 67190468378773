import { OrgNodeType, PartialOrgNode } from '../models';

export function orgNodeBranchTypeToNumber(orgNodeType: OrgNodeType) {
  switch (orgNodeType) {
    case 'country':
      return 0;
    case 'school':
      return 1;
    case 'union':
      return 2;
    case 'section':
      return 3;
  }
}

export function joinOrgNodeBranchNames(orgNodesBranch: PartialOrgNode[]) {
  return [...orgNodesBranch]
    .sort((orgNodeBranchA, orgNodeBranchB) => {
      const orgNodeBranchTypeA = orgNodeBranchTypeToNumber(orgNodeBranchA.type);
      const orgNodeBranchTypeB = orgNodeBranchTypeToNumber(orgNodeBranchB.type);

      if (orgNodeBranchTypeA < orgNodeBranchTypeB) {
        return -1;
      }

      if (orgNodeBranchTypeA > orgNodeBranchTypeB) {
        return 1;
      }

      return 0;
    })
    .map((orgNodeBranch) => orgNodeBranch.name)
    .join(' > ');
}
