import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ControlledInput,
  ControlledSelect,
  accountRoles,
} from '@orbiapp/components';
import { useForm } from 'react-hook-form';

import { InviteTeamMembersForm, InviteTeamMembersValidation } from '../models';
import {
  CreateInvitationsSelector,
  PendingInvitationsSelector,
  UsersSelector,
  createInvitationsThunk,
  useDispatch,
  useSelector,
} from '../store';
import { getTeamRoleTx } from '../utils';

const TEAM_MEMBER_ROLE_OPTIONS = accountRoles.map((accountRole) => ({
  value: accountRole,
  tx: getTeamRoleTx(accountRole),
}));

function renderInvitationRow(_: any, index: number) {
  return (
    <Box flexWrap gap={16} flex key={`invitation-row-${index}`}>
      <Box flexGrow={1}>
        <ControlledInput
          name={`invitations.${index}.email`}
          labelTx="label.team.email"
        />
      </Box>

      <Box flexGrow={1}>
        <ControlledSelect
          name={`invitations.${index}.role`}
          labelTx="label.team.role"
          options={TEAM_MEMBER_ROLE_OPTIONS}
        />
      </Box>
    </Box>
  );
}

export function useInviteTeamMembers(props: { onSuccess: () => void }) {
  const { onSuccess } = props;

  const existingTeamMemberEmails = useSelector(UsersSelector.selectAllEmails);
  const pendingTeamMemberEmails = useSelector(
    PendingInvitationsSelector.selectAllEmails,
  );
  const createInvitationsStatus = useSelector(
    CreateInvitationsSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const formMethods = useForm<InviteTeamMembersForm>({
    resolver: joiResolver(
      InviteTeamMembersValidation({
        existingTeamMemberEmails,
        pendingTeamMemberEmails,
      }),
    ),
    defaultValues: {
      invitations: [
        { email: '', role: 'admin' },
        { email: '', role: 'admin' },
        { email: '', role: 'admin' },
      ],
    },
  });

  const invites = formMethods.watch('invitations');

  const everyEmailIsEmptyString = invites.every(
    (invitation) => invitation.email === '',
  );

  const sendInvites = formMethods.handleSubmit(async (data) => {
    if (everyEmailIsEmptyString) return;

    const invitations = data.invitations.filter(
      (invitation) => invitation.email !== '',
    );

    const res = await dispatch(createInvitationsThunk({ invitations }));
    if (res.meta.requestStatus === 'fulfilled') {
      onSuccess?.();
    }
  });

  const addInvite = () => {
    formMethods.setValue(
      'invitations',
      [...invites, { email: '', role: 'admin' }],
      {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      },
    );
  };

  const isLoading = createInvitationsStatus === 'pending';

  return {
    everyEmailIsEmptyString,
    formMethods,
    sendInvites,
    addInvite,
    isLoading,
    invites: invites.map(renderInvitationRow),
  };
}
