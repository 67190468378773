import React from 'react';

import { Box, BoxProps } from '../box';
import { Styled } from './placeholder-bar.styled';

export function PlaceholderBar(props: BoxProps) {
  return <Styled.PlaceholderBar {...props} />;
}

export function PlaceholderChart(props: BoxProps) {
  const svgChartLineRef = React.useRef<SVGLineElement>(null);
  const svgChartRef = React.useRef<SVGSVGElement>(null);

  const setChartLinePath = React.useCallback(() => {
    const svgChart = svgChartRef.current;
    const svgChartLine = svgChartLineRef.current;
    if (!svgChart || !svgChartLine) return;

    const chartHeight = svgChart.clientHeight;
    const chartWidth = svgChart.clientWidth;

    const pathValues = [
      ['M', 0, chartHeight],
      [
        'C',
        chartWidth * 0.11,
        chartHeight * 0.4,
        chartWidth * 0.25,
        chartHeight * 0.93,
        chartWidth * 0.5,
        chartHeight * 0.5,
      ],
      [
        'C',
        chartWidth * 0.75,
        chartHeight * 0.15,
        chartWidth * 0.89,
        chartHeight * 0.88,
        chartWidth,
        chartHeight * 0.5,
      ],
    ];

    const path = pathValues.map((point) => point.join(' ')).join(' ');

    svgChartLine.setAttribute('d', path);
  }, [svgChartLineRef, svgChartRef]);

  React.useEffect(() => {
    if (!svgChartRef.current) return;

    setChartLinePath();

    const observer = new ResizeObserver(() => {
      setChartLinePath();
    });

    observer.observe(svgChartRef.current);

    return () => {
      observer.disconnect();
    };
  }, [setChartLinePath]);

  return (
    <Box gap={24} flex flexDirection="column" {...props}>
      <PlaceholderBar mx="auto" height={20} width={210} />

      <Box flex flexDirection="column" flexGrow={1}>
        <Box flexGrow={1} flex gap={4}>
          <Box flex flexDirection="column" flexJustify="between">
            <Styled.PlaceholderBarLabelDot />
            <Styled.PlaceholderBarLabelDot />
            <Styled.PlaceholderBarLabelDot />
          </Box>

          <Styled.PlaceholderChart>
            <svg ref={svgChartRef} style={{ width: '100%', height: '100%' }}>
              <path fill="transparent" strokeWidth={4} ref={svgChartLineRef} />
            </svg>
          </Styled.PlaceholderChart>
        </Box>

        <Box mx={32} py={8} flexJustify="between" flex>
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
          <Styled.PlaceholderBarLabelDot />
        </Box>
      </Box>
    </Box>
  );
}
