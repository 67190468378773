import { theme } from '../../../theme';

export const colors = [
  theme.colors.dataVisualisation[1],
  theme.colors.dataVisualisation[3],
  theme.colors.dataVisualisation[5],
  theme.colors.dataVisualisation[7],
  theme.colors.dataVisualisation[9],
  theme.colors.dataVisualisation[11],
  theme.colors.dataVisualisation[13],
  theme.colors.dataVisualisation[15],
];

export const compareColors = Object.values(theme.colors.dataVisualisation);
