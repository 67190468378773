import {
  Avatar,
  Box,
  Image,
  LAYOUT_Z_INDEX,
  Link,
  ProfileMenu,
  ProfileMenuHeader,
  ProfileMenuList,
  ProfileMenuListItem,
  Toolbar,
  ToolbarContentContainer,
  getAvatarVariantFromString,
  getPlacementMargin,
  useAnchoredMenu,
} from '@orbiapp/components';

import { assets } from '../../assets';
import { AccountSelector, useSelector } from '../../store';

export function NotOnboardedToolbar() {
  const email = useSelector(AccountSelector.selectEmail);
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const anchoredMenu = useAnchoredMenu({
    placement: 'bottom-end',
  });

  const avatarVariant = userKey
    ? getAvatarVariantFromString(userKey)
    : undefined;

  return (
    <Toolbar backgroundColor="toolbarBackground">
      <ToolbarContentContainer py={8} flexJustify="between" flexAlign="center">
        <Link plain to="/">
          <Image alt="" src={assets.orbiLogoOrange} />
        </Link>

        <Box ref={anchoredMenu.clickOutsideRef} ml="auto">
          <Avatar
            cursor="pointer"
            fallbackLetter={fullName?.[0]}
            highlight={anchoredMenu.isOpen}
            onClick={anchoredMenu.toggleMenu}
            ref={anchoredMenu.anchorRef}
            src={profilePicture?.thumbnail64.url ?? undefined}
            variant={avatarVariant}
          />

          {anchoredMenu.isOpen && (
            <ProfileMenu
              fixed
              ref={anchoredMenu.menuRef}
              zIndex={LAYOUT_Z_INDEX.profileMenu}
              {...getPlacementMargin('bottom-end')}
            >
              <ProfileMenuHeader
                avatarVariant={avatarVariant}
                email={email ?? ''}
                fullName={fullName ?? ''}
                profilePictureSrc={profilePicture?.thumbnail64.url ?? ''}
              />

              <ProfileMenuList>
                <ProfileMenuListItem
                  icon="power"
                  onClick={anchoredMenu.closeMenu}
                  to="/sign-out"
                  tx="label.toolbar-profile-menu.sign-out"
                />
              </ProfileMenuList>
            </ProfileMenu>
          )}
        </Box>
      </ToolbarContentContainer>
    </Toolbar>
  );
}
