import { INITIAL_DATA_STATE } from '../store.constants';
import { pendingInvitationsAdapter, usersAdapter } from './team.adapter';
import { TeamState } from './team.types';

export const initialTeamState: TeamState = {
  departmentInvitation: INITIAL_DATA_STATE,

  createInvitations: INITIAL_DATA_STATE,

  deleteInvitation: INITIAL_DATA_STATE,

  pendingInvitations: {
    ...INITIAL_DATA_STATE,
    data: pendingInvitationsAdapter.getInitialState(),
  },

  users: {
    ...INITIAL_DATA_STATE,
    data: usersAdapter.getInitialState(),
  },
  removeUser: INITIAL_DATA_STATE,
  updateUser: INITIAL_DATA_STATE,
};
