import { css } from 'styled-components';

import { pxToRem } from './string';
import { isBoolean, isNumber, isUndefined } from './utils';

export interface Position {
  absolute?: boolean;
  bottom?: number | string;
  fixed?: boolean;
  inset?: boolean | number | string;
  left?: number | string;
  relative?: boolean;
  right?: number | string;
  sticky?: boolean;
  top?: number | string;
  zIndex?: number | string;
}

function getUnit(value: number | string) {
  if (typeof value === 'string') {
    return value;
  }

  return pxToRem(value);
}

export const positionStyles = css<Position>`
  ${(props) => () => {
    const {
      absolute,
      bottom,
      fixed,
      inset,
      left,
      relative,
      right,
      sticky,
      top,
      zIndex,
    } = props;

    return css`
      ${relative &&
      css`
        position: relative;
      `}

      ${absolute &&
      css`
        position: absolute;
      `}

      ${sticky &&
      css`
        position: sticky;
      `}

     ${fixed &&
      css`
        position: fixed;
      `}

      ${!isUndefined(zIndex) &&
      css`
        z-index: ${zIndex} !important;
      `};

      ${isBoolean(inset) &&
      css`
        inset: 0;
      `};

      ${isNumber(inset) &&
      css`
        inset: ${getUnit(inset)};
      `};

      ${!isUndefined(top) &&
      css`
        top: ${getUnit(top)};
      `}

      ${!isUndefined(bottom) &&
      css`
        bottom: ${getUnit(bottom)};
      `}

    ${!isUndefined(left) &&
      css`
        left: ${getUnit(left)};
      `}
    
    ${!isUndefined(right) &&
      css`
        right: ${getUnit(right)};
      `}
    `;
  }}
`;
