import styled, { css } from 'styled-components';

import { Box } from '../box';

const ExpandedQrCode = styled(Box)`
  border-width: 12px;
  border-style: solid;
  border-image: linear-gradient(
      180deg,
      #f36c0d 0%,
      #caa3e3 53.65%,
      #43c0dc 90.62%
    )
    1;
`;

const QrCodeBox = styled(Box)<{ isFullscreen: boolean; size: number }>`
  ${(props) => {
    const { isFullscreen, size, theme } = props;

    if (!isFullscreen) {
      return css`
        width: ${size}px;
        height: ${size}px;
      `;
    }

    return css`
      width: 240px;
      height: 240px;

      @media (min-width: ${theme.breakpoints.xs}px) {
        width: ${size}px;
        height: ${size}px;
      }
    `;
  }}
`;

const LogoBox = styled(Box)<{ isFullscreen: boolean; size: number }>`
  ${(props) => {
    const { isFullscreen, size, theme } = props;

    if (!isFullscreen) {
      return css`
        width: ${size * 1.1}px;
        height: ${size * 1.1}px;

        img {
          width: ${size}px;
          height: ${size}px;
        }
      `;
    }

    return css`
      width: 48px;
      height: 48px;

      img {
        width: 24px;
        height: 24px;
      }

      @media (min-width: ${theme.breakpoints.xs}px) {
        width: ${size * 1.1}px;
        height: ${size * 1.1}px;

        img {
          width: ${size}px;
          height: ${size}px;
        }
      }
    `;
  }}
`;

export const Styled = { ExpandedQrCode, QrCodeBox, LogoBox };
