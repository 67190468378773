import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';

export namespace UploadFileSelector {
  const selectSelf = (state: RootState) => state.fileUploads.uploadFile;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );
}
