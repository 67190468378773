import { Box, Spinner, Text } from '@orbiapp/components';

import { ScanTicketSelector, useSelector } from '../../../../../store';
import { AlreadyConsumed } from './already-consumed';
import { EmailTransferred } from './email-transferred';
import { Refunded } from './refunded';
import { ScanResultCardContainer } from './scan-result-card-container';
import { SuccessfullyConsumed } from './successfully-consumed';
import { Transferred } from './transferred';

export function ScanResultCard() {
  const scanningResult = useSelector(ScanTicketSelector.selectData);
  const scanningStatus = useSelector(ScanTicketSelector.selectStatus);

  if (scanningStatus === 'pending') {
    return (
      <ScanResultCardContainer>
        <Box
          flex
          flexAlign="center"
          flexDirection="column"
          flexJustify="center"
          height="100%"
          gap={16}
        >
          <Spinner />

          <Text
            variant="bodySm"
            tx="label.scan-qr-codes.scan-result-card.loading-text"
          />
        </Box>
      </ScanResultCardContainer>
    );
  }

  switch (scanningResult?.status) {
    case 'alreadyConsumed':
      return (
        <ScanResultCardContainer>
          <AlreadyConsumed {...scanningResult} />
        </ScanResultCardContainer>
      );

    case 'emailTransferred':
      return (
        <ScanResultCardContainer>
          <EmailTransferred {...scanningResult} />
        </ScanResultCardContainer>
      );

    case 'refunded':
      return (
        <ScanResultCardContainer>
          <Refunded {...scanningResult} />
        </ScanResultCardContainer>
      );

    case 'successfullyConsumed':
      return (
        <ScanResultCardContainer>
          <SuccessfullyConsumed {...scanningResult} />
        </ScanResultCardContainer>
      );

    case 'transferred':
      return (
        <ScanResultCardContainer>
          <Transferred {...scanningResult} />
        </ScanResultCardContainer>
      );

    default:
      return null;
  }
}
