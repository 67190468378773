import {
  CreateOfferForm,
  Offer,
  OfferCategory,
  PartialOffer,
} from '../../../../../models';
import {
  CreateOfferDto,
  OfferCategoryDto,
  OfferDto,
  PartialOfferDto,
} from './offer.dto';

export function offerCategoryMapper(
  offerCategoryDto: OfferCategoryDto,
): OfferCategory {
  return {
    emoji: offerCategoryDto.emoji,
    name: offerCategoryDto.name,
    offerCategoryKey: offerCategoryDto.offerCategoryKey,
  };
}

export function offerMapper(offerDto: OfferDto): Offer {
  if (offerDto.type === 'consumable') {
    return {
      type: 'consumable',
      consumeWaitMinutes: offerDto.consumeWaitMinutes,
      contactEmail: offerDto.contactEmail,
      contactName: offerDto.contactName,
      coverImage: offerDto.coverImage,
      description: offerDto.description,
      disabledAt: offerDto.disabledAt,
      editMeta: offerDto.editMeta,
      endDate: offerDto.endDate,
      locations: offerDto.locations,
      offerCategory: offerCategoryMapper(offerDto.offerCategory),
      offerKey: offerDto.offerKey,
      requiredMembershipTypes: offerDto.requiredMembershipTypes,
      startDate: offerDto.startDate,
      title: offerDto.title,
      requireStudentVerification: offerDto.requireStudentVerification,
      countryKeys: offerDto.countryKeys,
    };
  }

  return {
    type: 'online',
    code: offerDto.code,
    contactEmail: offerDto.contactEmail,
    contactName: offerDto.contactName,
    coverImage: offerDto.coverImage,
    description: offerDto.description,
    disabledAt: offerDto.disabledAt,
    editMeta: offerDto.editMeta,
    endDate: offerDto.endDate,
    link: offerDto.link,
    locations: offerDto.locations,
    offerCategory: offerCategoryMapper(offerDto.offerCategory),
    offerKey: offerDto.offerKey,
    startDate: offerDto.startDate,
    title: offerDto.title,
    requiredMembershipTypes: offerDto.requiredMembershipTypes,
    requireStudentVerification: offerDto.requireStudentVerification,
    countryKeys: offerDto.countryKeys,
  };
}

export function partialOfferMapper(
  partialOfferDto: PartialOfferDto,
): PartialOffer {
  return {
    contactName: partialOfferDto.contactName,
    disabledAt: partialOfferDto.disabledAt,
    editMeta: partialOfferDto.editMeta,
    endDate: partialOfferDto.endDate,
    offerKey: partialOfferDto.offerKey,
    startDate: partialOfferDto.startDate,
    title: partialOfferDto.title,
  };
}

export function createOfferMapper(
  createOffer: CreateOfferForm,
): CreateOfferDto {
  return {
    offerCategoryKey: createOffer.offerCategoryKey,
    title: createOffer.title,
    description: createOffer.description,
    coverImage: createOffer.coverImage,

    startDate: createOffer.startDate,
    endDate: createOffer.endDate,
    contactName: createOffer.contactName,
    contactEmail: createOffer.contactEmail,

    code: createOffer.code,
    link: createOffer.link,

    files: createOffer.files,

    isConsumable: createOffer.isConsumable,
    consumeWaitMinutes: createOffer.consumeWaitMinutes,

    requiredMembershipTypes: createOffer.requiredMembershipTypes
      ? {
          combinationType: createOffer.requiredMembershipTypes.combinationType,
          membershipTypeKeys:
            createOffer.requiredMembershipTypes.membershipTypes.map(
              (membershipType) => membershipType.membershipTypeKey,
            ),
        }
      : null,

    requireStudentVerification: createOffer.requireStudentVerification,

    locations: createOffer.locations,
    countryKeys: createOffer.countryKeys,
  };
}
