import {
  Avatar,
  Box,
  Button,
  DangerIconButton,
  FormSection,
  FormSectionHeader,
  INITIAL_MODAL_STATE,
  List,
  ListItem,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalState,
  ModalTitle,
  Radio,
  SearchInput,
  Spinner,
  Text,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useMembershipSearch } from '../../../../../helpers';
import {
  CombinationType,
  CreateOfferForm,
  PartialMembershipType,
} from '../../../../../models';
import {
  getSuggestedMembershipsThunk,
  searchActions,
  useDispatch,
} from '../../../../../store';
import { useOfferFormsSteps } from '../../helpers';

const ModalContext = React.createContext<ModalState>(INITIAL_MODAL_STATE);

function PickCombinationType() {
  const formContext = useFormContext<CreateOfferForm>();

  const requiredMembershipTypes = formContext.watch('requiredMembershipTypes');

  if (
    !requiredMembershipTypes?.membershipTypes ||
    requiredMembershipTypes.membershipTypes.length < 2
  ) {
    return null;
  }

  const handleCombinationTypeChange =
    (combinationType: CombinationType) => () => {
      formContext.setValue(
        'requiredMembershipTypes.combinationType',
        combinationType,
      );
    };

  return (
    <Box gap={24} flexWrap flex>
      <Radio
        dotPlacement="left"
        checked={requiredMembershipTypes.combinationType === 'all'}
        onChange={handleCombinationTypeChange('all')}
        tx="label.create-activity.tickets.ticket-items.ticket.combination-type.all"
      />
      <Radio
        dotPlacement="left"
        checked={requiredMembershipTypes.combinationType === 'any'}
        onChange={handleCombinationTypeChange('any')}
        tx="label.create-activity.tickets.ticket-items.ticket.combination-type.any"
      />
    </Box>
  );
}

function RequiredMembershipsModalContent() {
  const dispatch = useDispatch();

  const formContext = useFormContext<CreateOfferForm>();

  const modalState = React.useContext(ModalContext);

  const { search, searchResult, status, searchString, setSearchString } =
    useMembershipSearch(['needs_approval', 'standard']);

  const [selectedMemberships, setSelectedMemberships] = React.useState<
    PartialMembershipType[]
  >([]);

  const selectedMembershipKeys = new Set([
    ...selectedMemberships.map((membership) => membership.membershipTypeKey),
    ...(
      formContext.getValues('requiredMembershipTypes.membershipTypes') ?? []
    ).map((membership) => membership.membershipTypeKey),
  ]);

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);

    if (e.target.value.trim()) {
      search(e.target.value);
    } else {
      dispatch(searchActions.clearMembershipTypes());
    }
  };

  const renderPartialMembership = (
    partialMembershipType: PartialMembershipType,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      if (
        !selectedMembershipKeys.has(partialMembershipType.membershipTypeKey)
      ) {
        setSelectedMemberships([...selectedMemberships, partialMembershipType]);
      } else {
        setSelectedMemberships(
          selectedMemberships.filter(
            (membership) =>
              membership.membershipTypeKey !==
              partialMembershipType.membershipTypeKey,
          ),
        );
      }
    };

    return (
      <ListItem
        key={`search-result-membership-${partialMembershipType.membershipTypeKey}`}
        avatarSrc={partialMembershipType.logo.thumbnail64.url}
        onClick={addMembershipToRequiredMemberships}
        subtitle={partialMembershipType.departmentName}
        title={partialMembershipType.name}
      />
    );
  };

  const handleSave = () => {
    const requiredMembershipTypes = formContext.getValues(
      'requiredMembershipTypes',
    );

    if (!requiredMembershipTypes) {
      formContext.setValue('requiredMembershipTypes', {
        membershipTypes: selectedMemberships,
        combinationType: 'all',
      });
    } else {
      formContext.setValue('requiredMembershipTypes.membershipTypes', [
        ...requiredMembershipTypes.membershipTypes,
        ...selectedMemberships,
      ]);
    }

    modalState.closeModal();

    setSearchString('');
    setSelectedMemberships([]);
    dispatch(searchActions.clearMembershipTypes());
  };

  React.useEffect(() => {
    dispatch(getSuggestedMembershipsThunk({ includeHidden: true }));
  }, [dispatch]);

  const suggestedMemberships: PartialMembershipType[] = [];

  const notAddedSearchResults = searchResult.filter(
    (membership) => !selectedMembershipKeys.has(membership.membershipTypeKey),
  );

  return (
    <ModalContentContainer>
      <ModalHeaderContainer flex flexDirection="column" gap={32}>
        <ModalTitle tx="label.create-activity.tickets.add-required-memberships" />

        <SearchInput
          onChange={handleSearchChange}
          placeholderTx="placeholder.search-for-memberships"
          value={searchString}
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        {status === 'pending' ? (
          <Box mx="auto">
            <Spinner />
          </Box>
        ) : (
          <React.Fragment>
            {searchString.length > 0 && !notAddedSearchResults.length && (
              <Text
                textAlign="center"
                tx="placeholder.no-search-results"
                variant="bodySm"
              />
            )}

            {notAddedSearchResults.length > 0 && (
              <React.Fragment>
                <Text
                  variant="bodyMdBold"
                  tx="label.memberships.create-membership.settings.search-results"
                />

                <List>
                  {notAddedSearchResults.map(renderPartialMembership)}
                </List>
              </React.Fragment>
            )}

            {suggestedMemberships.length > 0 && (
              <React.Fragment>
                <Text
                  variant="bodyMdBold"
                  tx="label.memberships.create-membership.settings.suggested-memberships"
                />

                <List>{suggestedMemberships.map(renderPartialMembership)}</List>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {selectedMemberships.length > 0 && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.required-memberships"
            />

            <List>{selectedMemberships.map(renderPartialMembership)}</List>
          </React.Fragment>
        )}
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          tx="button.cancel"
          onClick={modalState.closeModal}
          variant="tertiary"
        />
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function RequiredMembershipsList() {
  const formContext = useFormContext<CreateOfferForm>();

  const requiredMembershipTypes = formContext.watch('requiredMembershipTypes');

  if (!requiredMembershipTypes) {
    return null;
  }

  const renderSelectedMembership = (
    partialMembershipType: PartialMembershipType,
  ) => {
    const removeSelectedMembershipApplicationRequirement = () => {
      const newState = formContext
        .getValues('requiredMembershipTypes.membershipTypes')
        .filter(
          (membership) =>
            membership.membershipTypeKey !==
            partialMembershipType.membershipTypeKey,
        );

      if (newState.length === 0) {
        formContext.setValue('requiredMembershipTypes', null);
      } else {
        formContext.setValue(
          'requiredMembershipTypes.membershipTypes',
          newState,
        );
      }
    };

    return (
      <ListItem
        key={`selected-membership-type-${partialMembershipType.membershipTypeKey}`}
      >
        <Box overflow="hidden" flex gap={16} flexAlign="center">
          <Avatar src={partialMembershipType.logo.thumbnail64.url} />

          <Box overflow="hidden">
            <Text
              overflow="hidden"
              text={partialMembershipType.name}
              textOverflow="ellipsis"
              variant="bodyMd"
              whiteSpace="nowrap"
            />
            <Text
              overflow="hidden"
              text={partialMembershipType.departmentName}
              textOverflow="ellipsis"
              variant="bodySm"
              whiteSpace="nowrap"
            />
          </Box>
        </Box>

        <DangerIconButton
          icon="minus-circle-outline"
          onClick={removeSelectedMembershipApplicationRequirement}
        />
      </ListItem>
    );
  };

  return (
    <List>
      {requiredMembershipTypes.membershipTypes.map(renderSelectedMembership)}
    </List>
  );
}

function AddRequiredMembershipsModal() {
  const modalState = React.useContext(ModalContext);

  return (
    <Modal
      width={800}
      onClose={modalState.closeModal}
      isOpen={modalState.isOpen}
    >
      <RequiredMembershipsModalContent />
    </Modal>
  );
}

function OpenModalButton() {
  const modalState = React.useContext(ModalContext);

  return (
    <Button
      variant="secondary"
      tx="button.add-membership"
      onClick={modalState.openModal}
    />
  );
}

export function RequiredMemberships() {
  const modalState = useModalState();

  const steps = useOfferFormsSteps();

  return (
    <ModalContext.Provider value={modalState}>
      <FormSection>
        <FormSectionHeader>
          <Box flexWrap flex flexJustify="between" gap={16} flexAlign="start">
            <Box>
              <Box flex gap={4}>
                <Text
                  tx="label.offer-form.headers.required-memberships"
                  variant="bodyMdBold"
                  color="formSectionHeader"
                  txArgs={{ step: steps.requiredMemberships }}
                />
                <Text
                  tx="label.general.optional"
                  variant="bodyMdItalic"
                  color="formSectionHeader"
                />
              </Box>
            </Box>

            <OpenModalButton />
          </Box>
        </FormSectionHeader>

        <PickCombinationType />

        <RequiredMembershipsList />

        <AddRequiredMembershipsModal />
      </FormSection>
    </ModalContext.Provider>
  );
}
