export const placements = [
  'top-start',
  'top',
  'top-end',
  'left-start',
  'left',
  'left-end',
  'right-start',
  'right',
  'right-end',
  'bottom-start',
  'bottom',
  'bottom-end',
] as const;

export type Placement = (typeof placements)[number];

/**
 * Returns the coordinates of the element based on the placement
 * @param elementNode The element to position such as a tooltip or menu
 * @param anchorNode The element to anchor the element to such as a button or icon
 * @param placement The placement of the element relative to the anchor
 * @param margin The margin between the element and the anchor
 */
export const getPlacementCords = (
  elementNode: HTMLElement,
  anchorNode: HTMLElement,
  placement: Placement,
) => {
  const elementRect = elementNode.getBoundingClientRect();
  const anchorRect = anchorNode.getBoundingClientRect();

  switch (placement) {
    case 'bottom':
      return {
        left: anchorRect.left + anchorRect.width / 2 - elementRect.width / 2,
        top: anchorRect.bottom,
      };

    case 'bottom-end':
      return {
        top: anchorRect.bottom,
        left: anchorRect.left + anchorRect.width - elementRect.width,
      };

    case 'bottom-start':
      return { top: anchorRect.bottom, left: anchorRect.left };

    case 'left':
      return {
        top: anchorRect.bottom - anchorRect.height / 2 - elementRect.height / 2,
        left: anchorRect.left - elementRect.width,
      };

    case 'left-end':
      return {
        left: anchorRect.left - elementRect.width,
        top: anchorRect.top + anchorRect.height - elementRect.height,
      };

    case 'left-start':
      return {
        left: anchorRect.left - elementRect.width,
        top: anchorRect.top,
      };

    case 'right':
      return {
        left: anchorRect.right,
        top: anchorRect.bottom - anchorRect.height / 2 - elementRect.height / 2,
      };

    case 'right-end':
      return {
        left: anchorRect.right,
        top: anchorRect.top + anchorRect.height - elementRect.height,
      };

    case 'right-start':
      return { left: anchorRect.right, top: anchorRect.top };

    case 'top':
      return {
        top: anchorRect.top - elementRect.height,
        left: anchorRect.left + anchorRect.width / 2 - elementRect.width / 2,
      };

    case 'top-end':
      return {
        top: anchorRect.top - elementRect.height,
        left: anchorRect.left + anchorRect.width - elementRect.width,
      };

    case 'top-start':
      return {
        top: anchorRect.top - elementRect.height,
        left: anchorRect.left,
      };
  }
};

export const getPlacementPadding = (placement: Placement) => {
  switch (placement) {
    case 'bottom':
    case 'bottom-start':
    case 'bottom-end':
      return { pt: 10 };

    case 'top':
    case 'top-start':
    case 'top-end':
      return { pb: 10 };

    case 'right':
    case 'right-start':
    case 'right-end':
      return { pl: 10 };

    case 'left':
    case 'left-start':
    case 'left-end':
      return { pr: 10 };
  }
};

export const getPlacementMargin = (placement: Placement) => {
  switch (placement) {
    case 'bottom':
    case 'bottom-start':
    case 'bottom-end':
      return { mt: 10 };

    case 'top':
    case 'top-start':
    case 'top-end':
      return { mb: 10 };

    case 'right':
    case 'right-start':
    case 'right-end':
      return { ml: 10 };

    case 'left':
    case 'left-start':
    case 'left-end':
      return { mr: 10 };
  }
};
