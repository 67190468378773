import { Box, BoxProps } from '../box';
import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './breadcrumbs.styled';
import { BreadCrumbProps } from './breadcrumbs.types';

export function BreadCrumb(props: BreadCrumbProps) {
  const { to = '', isLast, ...rest } = props;

  if (isLast) {
    const { isLast, ...rest } = props;

    return (
      <Styled.LastBreadCrumb to={to}>
        <Text whiteSpace="nowrap" variant="bodySm" {...rest} />
      </Styled.LastBreadCrumb>
    );
  }

  return (
    <Styled.BreadCrumb to={to}>
      <Text whiteSpace="nowrap" variant="bodySm" {...rest} />

      <Icon color="breadcrumbIcon" name="chevron-right" size={16} />
    </Styled.BreadCrumb>
  );
}

export function BreadCrumbs(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box flex flexAlign="center" gap={8} {...rest}>
      {children}
    </Box>
  );
}
