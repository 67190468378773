import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  CreateReactionParams,
  DeleteReactionParams,
  GetReactionActorsPageParams,
  Page,
  PartialActorWithReaction,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';

export async function createReaction(
  params: CreateReactionParams,
): Promise<GeneralApiResult> {
  const res = await apisauce.put<any, any>('/v1/reactions/create', params);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function deleteReaction(
  params: DeleteReactionParams,
): Promise<GeneralApiResult> {
  const res = await apisauce.put<any, any>('/v1/reactions/delete', params);

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export const getReactions = async (
  params: GetReactionActorsPageParams,
): Promise<GeneralApiResultWithData<Page<PartialActorWithReaction>>> => {
  const res = await apisauce.get<Page<PartialActorWithReaction>, any>(
    '/v1/reactions/actors',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getReactions', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
