import {
  Box,
  Checkbox,
  FormSection,
  FormSectionHeader,
  InputChip,
  InputChips,
  InputRefContext,
  InputRefProvider,
  Menu,
  MenuItem,
  Text,
  createSearchContext,
  isTxString,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateOfferForm, PartialOrgNode } from '../../../../../models';
import { CountriesSelector, useSelector } from '../../../../../store';

const CountriesSearchContext = createSearchContext<PartialOrgNode>();

const COUNTRY_ITEM_HEIGHT = 42;
const MENU_MAX_HEIGHT = COUNTRY_ITEM_HEIGHT * 9;

function CountriesInputChips() {
  const formMethods = useFormContext<CreateOfferForm>();

  const selectedCountryKeys = formMethods.watch('countryKeys');
  const countries = useSelector(CountriesSelector.selectAll);

  const searchContext = React.useContext(CountriesSearchContext.SearchContext);

  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useContext(InputRefContext);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuState = useMenu();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    searchContext.search(e.target.value);

    setSearchString(e.target.value);

    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  };

  const countryNames = new Map(
    countries.map((country) => [country.orgNodeKey, country.name]),
  );

  const chips: InputChip<string>[] = selectedCountryKeys.map((countryKey) => ({
    id: countryKey,
    text: countryNames.get(countryKey) ?? '',
  }));

  const removeChip = (id: string) => {
    formMethods.setValue(
      'countryKeys',
      selectedCountryKeys.filter((key) => key !== id),
      {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      },
    );
  };

  const handleToggleMenu = () => {
    menuState.toggleMenu();

    if (menuState.isOpen) {
      inputRef.current?.blur();
    } else {
      inputRef.current?.focus();
    }
  };

  const menuIsOpen = menuState.isOpen && !!searchContext.result.length;

  const items = React.useMemo(() => {
    return searchContext.result
      .sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
      .map((country) => country.item);
  }, [searchContext.result]);

  const renderCountryMenuItem = (country: PartialOrgNode) => {
    const isChecked = selectedCountryKeys.includes(country.orgNodeKey);

    const toggleCountry = () => {
      searchContext.search('');
      setSearchString('');

      if (isChecked) {
        removeChip(country.orgNodeKey);
      } else {
        formMethods.setValue(
          'countryKeys',
          [...selectedCountryKeys, country.orgNodeKey],
          {
            shouldDirty: true,
            shouldValidate: formMethods.formState.isSubmitted,
          },
        );
      }
    };

    return (
      <MenuItem
        onClick={toggleCountry}
        gap={8}
        flex
        flexAlign="center"
        key={country.orgNodeKey}
      >
        <Checkbox checked={isChecked} onChange={toggleCountry} />
        <Text as="span" text={country.name} variant="bodyMd" />
      </MenuItem>
    );
  };

  return (
    <Box ref={menuState.clickOutsideRef}>
      <InputChips
        hideErrorMessage={menuState.isOpen}
        onChange={handleInputChange}
        onFocus={menuState.openMenu}
        maxChips={100}
        value={searchString}
        ref={inputRef}
        showInput={menuState.isOpen || !!searchString}
        chips={chips}
        errorTx={
          isTxString(formMethods.formState.errors.countryKeys?.message)
            ? formMethods.formState.errors.countryKeys?.message
            : undefined
        }
        onRemoveChip={removeChip}
        leadingElements={[
          {
            type: 'icon',
            name: 'magnifying-glass',
          },
        ]}
        trailingElements={[
          {
            type: 'button',
            icon: menuIsOpen ? 'chevron-up' : 'chevron-down',
            onClick: handleToggleMenu,
            tooltipTx: menuIsOpen
              ? 'label.tooltip.collapse'
              : 'label.tooltip.expand',
            tooltipPlacement: 'left',
          },
        ]}
        labelTx="label.offer-form.countries"
        menuElement={
          <Menu
            absolute
            isOpen={menuIsOpen}
            maxHeight={MENU_MAX_HEIGHT}
            ref={menuRef}
            mt={8}
            top="100%"
            width="100%"
            left={0}
          >
            {items.map(renderCountryMenuItem)}
          </Menu>
        }
      />
    </Box>
  );
}

export function Countries() {
  const countries = useSelector(CountriesSelector.selectAll);

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.countries"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: 4 }}
        />
        <Text
          color="formSectionDescription"
          tx="label.offer-form.headers.countries-description"
          variant="bodyMd"
        />
      </FormSectionHeader>

      <CountriesSearchContext.Provider keys={['name']} defaultValue={countries}>
        <InputRefProvider>
          <CountriesInputChips />
        </InputRefProvider>
      </CountriesSearchContext.Provider>
    </FormSection>
  );
}
