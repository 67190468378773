import { SlimActor } from '../models';

export function getActorAvatarSrc(actor: SlimActor) {
  switch (actor.type) {
    case 'department':
      return actor.department.logo?.thumbnail64.url;

    case 'company':
      return actor.company.logo.thumbnail64.url;

    default:
      return actor.user?.profilePicture?.thumbnail64.url;
  }
}
