import {
  Avatar,
  Box,
  InputChip,
  InputChips,
  Link,
  Menu,
  MenuItem,
  Portal,
  Status,
  Text,
  extractEmails,
  getAvatarVariantFromString,
  parseFileAsText,
  useArrows,
  useMenu,
  usePlacementProps,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useUserSearch } from '../../../../../../helpers';
import {
  EmailSchema,
  MAX_MEMBERSHIP_INVITES_COUNT,
  MembershipInvitesForm,
  PartialUser,
} from '../../../../../../models';
import { Analytics } from '../../../../../../services';
import { MembershipTypeSelector, useSelector } from '../../../../../../store';
import { InviteMembersContext } from '../invite-members-modal';

const ACCEPTED_UPLOAD_TYPES = ['.csv', '.txt', '.xlsx', '.xls'] as const;

export function SearchForMembers() {
  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  const menuState = useMenu();

  const formContext = useFormContext<MembershipInvitesForm>();
  const inviteMembersContext = React.useContext(InviteMembersContext);

  const emails = formContext.watch('emails');

  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const inputChipsBoxRef = React.useRef<HTMLDivElement>(null);
  const uploadRef = React.useRef<HTMLInputElement>(null);

  const {
    search,
    clearSearch,
    status,
    searchString,
    searchResult,
    setSearchString,
  } = useUserSearch();

  const handleSetEmails = (newEmails: string[]) => {
    const newState = Array.from(
      new Set([
        ...emails,
        ...newEmails.filter((email) => !EmailSchema.validate(email).error),
      ]),
    ).slice(0, MAX_MEMBERSHIP_INVITES_COUNT);

    formContext.setValue('emails', newState, {
      shouldValidate: formContext.formState.isSubmitted,
      shouldDirty: true,
    });
  };

  const addEmailsToInvite = () => {
    if (!searchString) return;

    const extractedEmails = extractEmails(searchString);

    if (extractedEmails.length >= 2) {
      Analytics.track('membership_bulk_invite');
    }

    if (extractedEmails.length > 0) {
      handleSetEmails(extractedEmails);

      setSearchString('');
    }
  };

  const searchForUsers: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value.trim();

    setSearchString(value);

    if (value.endsWith(',')) {
      addEmailsToInvite();
      return;
    }

    if (value.length) {
      menuState.openMenu();
    }

    if (!value.length) {
      clearSearch();
      return;
    }

    if (value.length <= 254) {
      search(value);
    }
  };

  const uniqueResults = searchResult?.filter(
    (user) => !emails?.includes(user.email),
  );

  const [placementProps, updatePlacementProps] =
    usePlacementProps(inputChipsBoxRef);

  const handleFocus = () => {
    menuState.openMenu();
    updatePlacementProps();
  };

  const handleRemoveChip = (email: string) => {
    formContext.setValue(
      'emails',
      emails.filter((e) => e !== email),
      {
        shouldValidate: formContext.formState.isSubmitted,
        shouldDirty: true,
      },
    );
  };

  const chips = React.useMemo(
    () =>
      emails.map(
        (email): InputChip<string> => ({
          text: email,
          id: email,
          maxWidth: '30ch',
        }),
      ),
    [emails],
  );

  const isOpen =
    menuState.isOpen && !!searchString.length && status === 'completed';

  const handleToggleMenuItem = (index: number) => {
    const email = uniqueResults?.[index]?.email;
    if (!email) return;

    handleSetEmails([email]);
    setSearchString('');
  };

  const activeIndex = useArrows({
    maxIndex: uniqueResults.length - 1,
    minIndex: 0,
    onToggle: handleToggleMenuItem,
    disabled: !isOpen,
  });

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key !== 'Enter') return;

    addEmailsToInvite();
    handleToggleMenuItem(activeIndex);
  };

  const renderSearchResultMenuItem = (user: PartialUser, index: number) => {
    const { email, firstName, lastName, userKey, profilePicture } = user;

    const stageEmailForInvite = () => {
      handleSetEmails([email]);
      searchInputRef.current?.focus();
      setSearchString('');
    };

    return (
      <MenuItem
        isSelected={activeIndex === index}
        key={user.userKey}
        onClick={stageEmailForInvite}
      >
        <Box gap={8} flex>
          <Avatar
            width={24}
            height={24}
            src={profilePicture?.thumbnail64.url}
            fallbackLetter={firstName[0]}
            variant={getAvatarVariantFromString(userKey)}
          />
          <Box>
            <Text text={`${firstName} ${lastName}`} color="textPrimary" />
            <Text text={email} variant="bodySm" color="textSecondary" />
          </Box>
        </Box>
      </MenuItem>
    );
  };

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    if (!e.target.files) return;

    const file = Array.from(e.target.files)[0];
    const result = await parseFileAsText(file);

    handleSetEmails(extractEmails(result));

    e.target.value = '';
  };

  const openFilePicker = () => {
    uploadRef.current?.click();
  };

  const handleBlur = () => {
    updatePlacementProps();
  };

  React.useEffect(() => {
    updatePlacementProps();
  }, [chips, updatePlacementProps]);

  React.useEffect(() => {
    if (!inviteMembersContext.isOpen) {
      menuState.closeMenu();
    }
  }, [inviteMembersContext.isOpen, menuState]);

  return (
    <Box>
      <Box flex flexDirection="column" gap={16}>
        {membershipType !== 'standard' && (
          <Text
            tx="label.memberships.invite-members.invite-members"
            variant="bodyMdBold"
          />
        )}

        <Box flex flexDirection="column" gap={4}>
          <Box ref={inputChipsBoxRef}>
            <InputChips
              maxChips={MAX_MEMBERSHIP_INVITES_COUNT}
              onKeyUp={handleKeyDown}
              chips={chips}
              onRemoveChip={handleRemoveChip}
              onBlur={handleBlur}
              labelTx="placeholder.search-users"
              leadingElements={[
                {
                  type: 'icon',
                  name: 'magnifying-glass',
                },
              ]}
              trailingElements={[
                {
                  type: 'spinner',
                  size: 16,
                  hidden: status !== 'pending',
                },
                {
                  type: 'button',
                  icon:
                    isOpen && searchResult.length
                      ? 'chevron-up'
                      : 'chevron-down',
                  onClick: menuState.toggleMenu,
                  disabled: !searchString.length,
                },
              ]}
              onChange={searchForUsers}
              onFocus={handleFocus}
              ref={searchInputRef}
              showInput={!!searchString.length}
              value={searchString}
              menuElement={
                <Portal>
                  <Menu
                    isOpen={isOpen}
                    ref={menuState.menuRef}
                    zIndex={10000}
                    fixed
                    mt={8}
                    {...placementProps}
                  >
                    {!!uniqueResults.length ? (
                      uniqueResults.map(renderSearchResultMenuItem)
                    ) : (
                      <React.Fragment>
                        {status === 'completed' && (
                          <MenuItem flex gap={4}>
                            <Text
                              tx="label.memberships.invite-members.empty-state"
                              color="textSecondary"
                            />
                            {extractEmails(searchString).length > 0 && (
                              <Link
                                tx="link.general.invite-via-email"
                                variant="secondary"
                                onClick={addEmailsToInvite}
                              />
                            )}
                          </MenuItem>
                        )}
                      </React.Fragment>
                    )}
                  </Menu>
                  ,
                </Portal>
              }
            />
          </Box>

          <Box flex flexJustify="between" gap={16} px={16}>
            <Link tx="button.import-via" onClick={openFilePicker} />

            <Status
              variant="info"
              text={`${emails.length} / ${MAX_MEMBERSHIP_INVITES_COUNT}`}
            />
          </Box>

          <input
            accept={ACCEPTED_UPLOAD_TYPES.join(',')}
            type="file"
            hidden
            onChange={handleFileUpload}
            ref={uploadRef}
          />
        </Box>
      </Box>
    </Box>
  );
}
