import styled, { css } from 'styled-components';

import { Box } from '../../box';

const TooltipIndicatorDot = styled(Box)<{ color: string }>`
  ${(props) => {
    const { color } = props;

    return css`
      background-color: ${color};
    `;
  }}
`;

export const Styled = { TooltipIndicatorDot };
