import Joi from 'joi';

import { BoolSchema, MAX_PRICE, MIN_PRICE } from '../../shared';
import { PERIOD_NAME_MAX_LENGTH } from './period.constants';
import { MembershipPeriodErrors } from './period.errors';
import { MembershipPeriodForm } from './period.model';

namespace MembershipPeriodUtils {
  export const price = (
    price: number,
    helpers: Joi.CustomHelpers<MembershipPeriodForm>,
  ) => {
    if (price === 0) {
      return price;
    }

    if (price < MIN_PRICE) {
      return helpers.error(MembershipPeriodErrors.customErrors.periodMinPrice);
    }

    if (price > MAX_PRICE) {
      return helpers.error(MembershipPeriodErrors.customErrors.periodMaxPrice);
    }

    return price;
  };
}

export namespace MembershipPeriodSchema {
  export const key = Joi.string().required();

  export const lifetime = BoolSchema.required();

  export const name = Joi.string()
    .max(PERIOD_NAME_MAX_LENGTH)
    .required()
    .messages(MembershipPeriodErrors.name);

  export const price = Joi.number()
    .integer()
    .custom(MembershipPeriodUtils.price)
    .required()
    .messages(MembershipPeriodErrors.price);

  export const validFrom = Joi.number().integer().allow(null).required();

  export const validTo = Joi.number()
    .integer()
    .min(Joi.ref('validFrom'))
    .allow(null)
    .required()
    .messages(MembershipPeriodErrors.validTo);

  export const saleStart = Joi.number().integer().required();

  export const saleEnd = Joi.number()
    .integer()
    .min(Joi.ref('saleStart'))
    .required()
    .messages(MembershipPeriodErrors.saleEnd);
}
