import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  ActivityCategory,
  DescriptionForm,
  OTHER_ACTIVITY_CATEGORY_KEY,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { ActivityCategoryDto } from './categories.dto';
import {
  activityCategoryMapper,
  activityCategorySuggestMapper,
} from './categories.mappers';

export const getCategories = async (): Promise<
  GeneralApiResultWithData<ActivityCategory[]>
> => {
  const res = await apisauce.get<ActivityCategoryDto[], any>(
    '/v2/activities/categories',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data
      .map(activityCategoryMapper)
      .sort((a, b) => a.name.localeCompare(b.name));

    const index = data.findIndex(
      (x) => x.activityCategoryKey === OTHER_ACTIVITY_CATEGORY_KEY,
    );

    if (index > -1) {
      const [removed] = data.splice(index, 1);
      data.push(removed);
    }

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCategories', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const suggestCategories = async (
  activityCategorySuggest: Pick<DescriptionForm, 'description' | 'title'>,
): Promise<GeneralApiResultWithData<string[]>> => {
  const res = await apisauce.post<string[], any>(
    '/v2/activities/categories/suggest',
    activityCategorySuggestMapper(activityCategorySuggest),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map((x) => x);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('suggestCategories', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
