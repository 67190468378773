import React from 'react';
import { Trans as I18nTrans } from 'react-i18next';

import { translate } from '../../i18n';
import { Styled } from './text.styled';
import { LabelProps, TextProps } from './text.types';

function _Label(props: LabelProps, ref: React.ForwardedRef<HTMLLabelElement>) {
  const { children, text, tx, txArgs, ...rest } = props;

  const i18nText = tx && translate(tx, txArgs ?? {});
  const content = i18nText || text?.toString() || children;

  return (
    <Styled.Label ref={ref} {...rest}>
      {content}
    </Styled.Label>
  );
}

export function Trans(
  props: Omit<React.ComponentProps<typeof I18nTrans>, 'i18nKey'> & {
    i18nKey: TxString;
  },
) {
  const { i18nKey, ...rest } = props;

  return <I18nTrans i18nKey={i18nKey as string} {...rest} />;
}

function _Text(
  props: TextProps,
  ref: React.ForwardedRef<HTMLParagraphElement>,
) {
  const { children, text, tx, as, txArgs, ...rest } = props;

  const i18nText = tx && translate(tx, txArgs ?? {});
  const content = i18nText || text?.toString() || children;

  switch (as) {
    case 'span':
      return (
        <Styled.Span ref={ref} {...rest}>
          {content}
        </Styled.Span>
      );

    case 'h1':
      return (
        <Styled.H1 ref={ref} {...rest}>
          {content}
        </Styled.H1>
      );

    case 'h2':
      return (
        <Styled.H2 ref={ref} {...rest}>
          {content}
        </Styled.H2>
      );

    case 'h3':
      return (
        <Styled.H3 ref={ref} {...rest}>
          {content}
        </Styled.H3>
      );

    case 'h4':
      return (
        <Styled.H4 ref={ref} {...rest}>
          {content}
        </Styled.H4>
      );

    case 'h5':
      return (
        <Styled.H5 ref={ref} {...rest}>
          {content}
        </Styled.H5>
      );

    case 'h6':
      return (
        <Styled.H6 ref={ref} {...rest}>
          {content}
        </Styled.H6>
      );

    default:
      return (
        <Styled.Paragraph ref={ref} {...rest}>
          {content}
        </Styled.Paragraph>
      );
  }
}

export const Label = React.forwardRef(_Label);
export const Text = React.forwardRef(_Text);
