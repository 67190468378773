import styled, { css } from 'styled-components';

import { Box } from '../box';

const ReactionsRow = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      :not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.borderPrimary};
      }
    `;
  }}
`;

export const Styled = { ReactionsRow };
