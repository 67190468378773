import styled, { css } from 'styled-components';

import { Box } from '../../components';
import { LAYOUT_Z_INDEX } from '../../constants';
import { stringOrPx } from '../../utils';
import { StyledContentSidebarFixedProps } from './content-sidebar.types';

const ContentSidebar = styled(Box)<StyledContentSidebarFixedProps>`
  ${(props) => {
    const { isOpen, theme, sidebarWidth = 300 } = props;

    return css`
      background-color: ${theme.colors.contentSidebarBackground};
      overflow: hidden;
      position: fixed;
      right: 0;
      top: 0;
      z-index: ${LAYOUT_Z_INDEX.contentSidebar};
      transition: width ${theme.transitions.fast};

      ${isOpen &&
      css`
        border-left: 1px solid ${theme.colors.contentSidebarBorder};
        width: ${stringOrPx(sidebarWidth)};
        min-width: ${stringOrPx(sidebarWidth)};
      `}

      @media (min-width: ${theme.breakpoints.md}px) {
        position: unset;
        z-index: ${LAYOUT_Z_INDEX.contentSidebarMd};
      }
    `;
  }}
`;

const ContentSidebarBackdrop = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      position: fixed;
      width: 0;
      height: 0;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      top: 0;
      left: 0;
      z-index: ${LAYOUT_Z_INDEX.contentSidebar};

      ${isOpen &&
      css`
        opacity: 1;
        width: 100%;
        height: 100%;
      `}

      @media (min-width: ${theme.breakpoints.md}px) {
        z-index: ${LAYOUT_Z_INDEX.contentSidebarMd};

        display: none;
      }
    `;
  }}
`;

const ContentSidebarContentContainer = styled(Box)<{
  isOpen: boolean;
  sidebarWidth?: number;
}>`
  ${(props) => {
    const { isOpen, sidebarWidth, theme } = props;

    return css`
      width: 0;
      transition: ${theme.transitions.fast};

      ${isOpen &&
      css`
        width: ${stringOrPx(sidebarWidth)};
        min-width: ${stringOrPx(sidebarWidth)};
      `}
    `;
  }}
`;

export const Styled = {
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarBackdrop,
};
