import Joi from 'joi';

import { BoolSchema, RequestedInfoType } from '../../shared';
import {
  CHECKBOX_QUESTION_MAX_LENGTH,
  FREETEXT_QUESTION_MAX_LENGTH,
  MULTICHOICE_OPTION_LABEL_MAX_LENGTH,
  MULTICHOICE_QUESTION_MAX_LENGTH,
} from './questions.constants';
import {
  CheckboxQuestionErrors,
  FreetextQuestionErrors,
  MultichoiceQuestionErrors,
} from './questions.errors';

export namespace MembershipCheckboxQuestionSchema {
  export const key = Joi.string().required();

  export const isRequired = BoolSchema.required();

  export const question = Joi.string()
    .max(CHECKBOX_QUESTION_MAX_LENGTH)
    .required()
    .messages(CheckboxQuestionErrors.question);

  export const type = Joi.string().valid(RequestedInfoType.Checkbox).required();
}

export namespace MembershipMultichoiceQuestionSchema {
  export const key = Joi.string().required();

  export const isRequired = BoolSchema.required();

  export const question = Joi.string()
    .max(MULTICHOICE_QUESTION_MAX_LENGTH)
    .required()
    .messages(MultichoiceQuestionErrors.question);

  export const type = Joi.string()
    .valid(RequestedInfoType.MultiChoice)
    .required();

  export const createOptions = Joi.array()
    .items(
      Joi.object({
        key: Joi.string().required(),
        index: Joi.number().integer().min(0).required(),
        isNew: BoolSchema.required(),
        label: Joi.string()
          .max(MULTICHOICE_OPTION_LABEL_MAX_LENGTH)
          .required()
          .messages(MultichoiceQuestionErrors.optionLabel),
      }),
    )
    .min(2)
    .required();
}

export namespace MembershipFreetextQuestionSchema {
  export const key = Joi.string().required();

  export const isRequired = BoolSchema.required();

  export const question = Joi.string()
    .max(FREETEXT_QUESTION_MAX_LENGTH)
    .required()
    .messages(FreetextQuestionErrors.question);

  export const type = Joi.string().valid(RequestedInfoType.FreeText).required();
}
