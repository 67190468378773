import { Banner } from '@orbiapp/components';
import React from 'react';

import { ORBI_PAY_FREE_PERIOD_FOR_REFERRALS } from '../../constants';
import { useNavigateStripeConnectForm } from '../../helpers';
import { OrbiPayRole } from '../../models';
import {
  DepartmentSelector,
  OrbiPaySettingsSelector,
  useSelector,
} from '../../store';

function EventuallyDueBanner() {
  const orbiPaySettings = useSelector(OrbiPaySettingsSelector.selectData);

  const navigateStripeConnectForm = useNavigateStripeConnectForm();

  if (orbiPaySettings?.role === OrbiPayRole.Member) {
    return (
      <Banner
        buttonTx="button.update-info"
        data-testid="orbi-pay-banner-eventually-due"
        messageTx="label.orbi-pay.orbi-pay-settings.eventually-due.alert"
        variant="information"
        titleTx="label.orbi-pay.orbi-pay-settings.orbi-pay-member-subtitle"
        titleTxArgs={{ departmentName: orbiPaySettings.parentDepartmentName }}
      />
    );
  }

  const onBannerClick = () => {
    navigateStripeConnectForm({
      type: 'account_update',
      collect: 'eventually_due',
    });
  };

  return (
    <Banner
      buttonOnClick={onBannerClick}
      buttonTx="button.update-info"
      data-testid="orbi-pay-banner-eventually-due"
      messageTx="label.orbi-pay.orbi-pay-settings.eventually-due.alert"
      variant="information"
    />
  );
}

function CurrentlyDueBanner() {
  const orbiPaySettings = useSelector(OrbiPaySettingsSelector.selectData);

  const navigateStripeConnectForm = useNavigateStripeConnectForm();

  if (orbiPaySettings?.role === OrbiPayRole.Member) {
    return (
      <Banner
        buttonTx="button.update-info"
        data-testid="orbi-pay-banner-currently-due"
        messageTx="label.orbi-pay.orbi-pay-settings.currently-due.alert"
        variant="warning"
        titleTx="label.orbi-pay.orbi-pay-settings.orbi-pay-member-subtitle"
        titleTxArgs={{ departmentName: orbiPaySettings.parentDepartmentName }}
      />
    );
  }

  const onBannerClick = () => {
    navigateStripeConnectForm({
      type: 'account_update',
      collect: 'currently_due',
    });
  };

  return (
    <Banner
      buttonOnClick={onBannerClick}
      buttonTx="button.update-info"
      data-testid="orbi-pay-banner-currently-due"
      messageTx="label.orbi-pay.orbi-pay-settings.currently-due.alert"
      variant="warning"
    />
  );
}

function DisabledBanner() {
  const orbiPaySettings = useSelector(OrbiPaySettingsSelector.selectData);

  const navigateStripeConnectForm = useNavigateStripeConnectForm();

  if (orbiPaySettings?.role === OrbiPayRole.Member) {
    return (
      <Banner
        buttonTx="button.update-info"
        data-testid="orbi-pay-banner-disabled"
        messageTx="label.orbi-pay.orbi-pay-settings.disabled.alert"
        variant="error"
        titleTx="label.orbi-pay.orbi-pay-settings.orbi-pay-member-subtitle"
        titleTxArgs={{ departmentName: orbiPaySettings.parentDepartmentName }}
      />
    );
  }

  const onBannerClick = () => {
    navigateStripeConnectForm({
      type: 'account_update',
      collect: 'currently_due',
    });
  };

  return (
    <Banner
      buttonOnClick={onBannerClick}
      buttonTx="button.update-info"
      data-testid="orbi-pay-banner-disabled"
      messageTx="label.orbi-pay.orbi-pay-settings.disabled.alert"
      variant="error"
    />
  );
}

function NotOnboardedBanner() {
  const referredAt = useSelector(DepartmentSelector.selectReferredAt);
  const [isClosed, setIsClosed] = React.useState(false);

  if (isClosed || !referredAt) {
    return null;
  }

  const timePassed = Date.now() - referredAt;
  const timeLeft = ORBI_PAY_FREE_PERIOD_FOR_REFERRALS - timePassed;

  if (timeLeft < 0) {
    return null;
  }

  const closeBanner = () => {
    setIsClosed(true);
  };

  return (
    <Banner
      linkTo="/orbi-pay"
      linkTx="label.free-orbi-pay-banner.link"
      messageTx="label.free-orbi-pay-banner.message"
      titleTx="label.free-orbi-pay-banner.title"
      variant="information"
      onClose={closeBanner}
      zIndex={200}
    />
  );
}

export function OrbiPayBanner() {
  const orbiPaySettings = useSelector(OrbiPaySettingsSelector.selectData);

  if (!orbiPaySettings) {
    return null;
  }

  if (orbiPaySettings.role === OrbiPayRole.OrbiPayNotOnboarded) {
    return <NotOnboardedBanner />;
  }

  if (!orbiPaySettings.stripeRequirements) {
    return null;
  }

  if (orbiPaySettings.stripeRequirements.isDisabled) {
    return <DisabledBanner />;
  }

  if (orbiPaySettings.stripeRequirements.requirementType === 'currently_due') {
    return <CurrentlyDueBanner />;
  }

  if (orbiPaySettings.stripeRequirements.requirementType === 'eventually_due') {
    return <EventuallyDueBanner />;
  }

  return null;
}
