import Joi from 'joi';

import { BoolSchema, MIN_PRICE, requestedInfoTypes } from '../../shared';
import {
  REQUESTED_INFO_OPTION_LABEL_MAX_LENGTH,
  REQUESTED_INFO_QUESTION_MAX_LENGTH,
} from './requested-info.constants';
import { OptionsErrors, RequestedInfoErrors } from './requested-info.errors';
import {
  ActivityMultiChoice,
  ActivityRequestedInfoOption,
  RequestedInfo,
} from './requested-info.model';

namespace RequestedInfoUtils {
  export const priceIncrement = (
    additionalCost: number,
    helpers: Joi.CustomHelpers<RequestedInfo>,
  ) => {
    if (additionalCost === 0) {
      return additionalCost;
    }

    if (additionalCost < MIN_PRICE) {
      return helpers.error(
        RequestedInfoErrors.customErrors.priceIncrementMinPrice,
      );
    }

    return additionalCost;
  };

  export const options = (
    multichoiceOptions: ActivityRequestedInfoOption[],
    helpers: Joi.CustomHelpers<ActivityMultiChoice>,
  ) => {
    const multichoice: ActivityMultiChoice = helpers.state.ancestors[0];

    const hasFreeOption = multichoiceOptions.some(
      (option) => option.priceIncrement === 0,
    );

    if (!multichoice.isRequired || hasFreeOption) {
      return multichoiceOptions;
    }

    return helpers.error(RequestedInfoErrors.customErrors.missingFreeOption);
  };
}

export namespace OptionsSchema {
  export const priceIncrement = Joi.number()
    .integer()
    .custom(RequestedInfoUtils.priceIncrement)
    .messages(RequestedInfoErrors.priceIncrement);

  export const option = Joi.object({
    id: Joi.string().required(),
    index: Joi.number().integer().required(),
    isNew: BoolSchema.required(),
    label: Joi.string()
      .trim()
      .max(REQUESTED_INFO_OPTION_LABEL_MAX_LENGTH)
      .required()
      .messages(OptionsErrors.label),
    priceIncrement: priceIncrement.required(),
  });

  export const options = Joi.array()
    .min(2)
    .items(option)
    .custom(RequestedInfoUtils.options)
    .messages(OptionsErrors.options)
    .optional();
}

export namespace RequestedInfoSchema {
  export const question = Joi.string()
    .max(REQUESTED_INFO_QUESTION_MAX_LENGTH)
    .required()
    .messages(RequestedInfoErrors.question);

  export const ticketTypeKeys = Joi.array()
    .unique()
    .items(Joi.string().required())
    .messages(RequestedInfoErrors.ticketTypeKeys);

  export const enablePriceIncrement = BoolSchema;

  export const isRequired = BoolSchema.optional();

  export const isNew = BoolSchema.required();

  export const priceIncrement = OptionsSchema.priceIncrement;

  export const index = Joi.number().integer().required();

  export const id = Joi.string().required();

  export const type = Joi.alternatives(requestedInfoTypes).required();
}
