import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Callout,
  ControlledInput,
  ControlledPasswordInput,
  ControlledSelect,
  Form,
  Icon,
  Input,
  Link,
  OptionProps,
  OrbiLoader,
  ResponsiveBox,
  SignInForm,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSide,
  Text,
  getAvatarVariantFromString,
  getBrowserLanguage,
  usePasswordInput,
} from '@orbiapp/components';
import i18next from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { SignedOutToolbar } from '../../../components';
import { TERMS_AND_CONDITIONS_URL } from '../../../constants';
import {
  CreateAccountByInvitationForm,
  CreateAccountByInvitationValidation,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_NUMBERS,
  PASSWORD_MIN_SPECIAL_CHARACTERS,
  SignInValidation,
} from '../../../models';
import {
  AuthStateSelector,
  CreateAccountByInvitationSelector,
  DepartmentInvitationSelector,
  SignUpSelector,
  UpdateAccountByInvitationSelector,
  createAccountByInvitationThunk,
  getDepartmentInvitationThunk,
  signOutThunk,
  updateAccountByInvitationThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { isAnyPending } from '../../../utils';

const LANGUAGE_OPTIONS: OptionProps[] = [
  {
    value: 'en',
    tx: 'label.languages.en',
  },
  {
    value: 'sv',
    tx: 'label.languages.sv',
  },
];

function UserExistsPasswordInput() {
  const { icon, inputType, toggleShowPassword } = usePasswordInput();

  return (
    <ControlledInput
      trailingElements={[
        {
          type: 'button',
          icon,
          onClick: toggleShowPassword,
        },
      ]}
      labelTx="label.auth.password"
      name="password"
      type={inputType}
      errorTxArgs={{
        minCharacters: PASSWORD_MIN_LENGTH,
        minSpecialCharacters: PASSWORD_MIN_SPECIAL_CHARACTERS,
        minNumbers: PASSWORD_MIN_NUMBERS,
      }}
    />
  );
}

function UserExistsForm() {
  const departmentInvitation = useSelector(
    DepartmentInvitationSelector.selectData,
  );

  const authStatus = useSelector(AuthStateSelector.selectStatus);
  const updateUserByInvitationStatus = useSelector(
    UpdateAccountByInvitationSelector.selectStatus,
  );

  const userExistsFormMethods = useForm<SignInForm>({
    defaultValues: { email: departmentInvitation?.email ?? '', password: '' },
    resolver: joiResolver(SignInValidation),
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = userExistsFormMethods.handleSubmit(async (data) => {
    if (!departmentInvitation) return;

    const res = await dispatch(
      updateAccountByInvitationThunk({
        email: data.email,
        password: data.password,
        departmentInvitationKey: departmentInvitation.departmentInvitationKey,
      }),
    );
    if (res.meta.requestStatus !== 'fulfilled') return;

    navigate('/onboarded', { replace: true });
  });

  const isLoading = isAnyPending(authStatus, updateUserByInvitationStatus);

  return (
    <Form
      flex
      flexDirection="column"
      formMethods={userExistsFormMethods}
      gap={32}
      onSubmit={onSubmit}
      width="100%"
    >
      {departmentInvitation && (
        <Callout
          variant="info"
          subtitleTx="label.auth.sign-up.create-account-user-exists-callout"
          subtitleTxArgs={{
            departmentName: departmentInvitation.departmentDetails.name,
          }}
        />
      )}

      <ControlledInput disabled labelTx="label.auth.email" name="email" />

      <UserExistsPasswordInput />

      <Button
        width="100%"
        isLoading={isLoading}
        tx="button.auth.log-in"
        type="submit"
        variant="primary"
        large
      />

      <Box flex flexJustify="center" flexAlign="center" gap={4}>
        <Link to="/reset-password" small tx="link.auth.forgot-your-password" />
      </Box>
    </Form>
  );
}

function SignUpFromInvitationForm() {
  const departmentInvitation = useSelector(
    DepartmentInvitationSelector.selectData,
  );

  const createUserByInvitationStatus = useSelector(
    CreateAccountByInvitationSelector.selectStatus,
  );
  const authStatus = useSelector(AuthStateSelector.selectStatus);

  const joinOrbiFormMethods = useForm<CreateAccountByInvitationForm>({
    defaultValues: {
      firstName: '',
      language: getBrowserLanguage(),
      lastName: '',
      password: '',
    },
    resolver: joiResolver(CreateAccountByInvitationValidation),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = joinOrbiFormMethods.handleSubmit(async (data) => {
    if (!departmentInvitation) return;

    const res = await dispatch(
      createAccountByInvitationThunk({
        departmentInvitationKey: departmentInvitation.departmentInvitationKey,
        email: departmentInvitation.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        language: data.language,
      }),
    );
    if (res.meta.requestStatus !== 'fulfilled') return;

    i18next.changeLanguage(data.language);
    navigate('/onboarded', { replace: true });
  });

  const [firstName, lastName] = joinOrbiFormMethods.watch([
    'firstName',
    'lastName',
  ]);

  const clearFirstName = () => {
    joinOrbiFormMethods.setValue('firstName', '');
    joinOrbiFormMethods.setFocus('firstName');
  };

  const clearLastName = () => {
    joinOrbiFormMethods.setValue('lastName', '');
    joinOrbiFormMethods.setFocus('lastName');
  };

  const isLoading = isAnyPending(authStatus, createUserByInvitationStatus);

  return (
    <Form
      flex
      flexDirection="column"
      formMethods={joinOrbiFormMethods}
      gap={32}
      onSubmit={onSubmit}
      width="100%"
    >
      {departmentInvitation && (
        <Callout
          variant="info"
          subtitleTx="label.auth.sign-up.create-account-callout"
          subtitleTxArgs={{
            departmentName: departmentInvitation.departmentDetails.name,
          }}
        />
      )}

      <Input
        defaultValue={departmentInvitation?.email ?? ''}
        disabled
        labelTx="label.auth.email"
      />

      <ControlledSelect
        disabled={isLoading}
        labelTx="label.auth.language"
        name="language"
        options={LANGUAGE_OPTIONS}
      />

      <ControlledInput
        disabled={isLoading}
        labelTx="label.auth.first-name"
        name="firstName"
        type="text"
        trailingElements={[
          {
            type: 'button',
            icon: 'x-circle-outline',
            onClick: clearFirstName,
            hidden: !firstName.length,
          },
        ]}
      />

      <ControlledInput
        disabled={isLoading}
        labelTx="label.auth.last-name"
        name="lastName"
        type="text"
        trailingElements={[
          {
            type: 'button',
            icon: 'x-circle-outline',
            onClick: clearLastName,
            hidden: !lastName.length,
          },
        ]}
      />

      <ControlledPasswordInput
        minCharacters={PASSWORD_MIN_LENGTH}
        minSpecialCharacters={PASSWORD_MIN_SPECIAL_CHARACTERS}
        minNumbers={PASSWORD_MIN_NUMBERS}
        labelTx="label.auth.password"
        name="password"
      />

      <Button
        variant="primary"
        tx="button.auth.create-account"
        type="submit"
        isLoading={isLoading}
        width="100%"
        large
      />

      <Box flex flexJustify="center">
        <ResponsiveBox
          xxs={
            <Box flex gap={4} flexAlign="center">
              <Text variant="bodySm" tx="label.auth.sign-up.terms" />
              <Link
                href={TERMS_AND_CONDITIONS_URL}
                target="_blank"
                small
                tx="label.auth.sign-up.terms-link"
              />
            </Box>
          }
        >
          <Box flex gap={4} flexAlign="center" flexDirection="column">
            <Text variant="bodySm" tx="label.auth.sign-up.terms" />
            <Link
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              small
              tx="label.auth.sign-up.terms-link"
            />
          </Box>
        </ResponsiveBox>
      </Box>
    </Form>
  );
}

function SignUpFromInvitationContent() {
  const departmentInvitation = useSelector(
    DepartmentInvitationSelector.selectData,
  );
  const teamInvitationError = useSelector(
    DepartmentInvitationSelector.selectError,
  );

  if (teamInvitationError || !departmentInvitation) {
    return (
      <Box
        flex
        flexDirection="column"
        flexAlign="center"
        gap={32}
        data-testid="sign-up-from-invitation-error"
      >
        <Icon
          name="exclamation-triangle-solid"
          size={100}
          color="invitationNotFoundIcon"
        />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.not-found.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.not-found.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (departmentInvitation.consumedAt) {
    return (
      <Box
        flex
        flexDirection="column"
        flexAlign="center"
        gap={32}
        data-testid="sign-up-from-invitation-consumed"
      >
        <Icon name="x-circle-solid" size={100} color="invalidInvitationIcon" />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.consumed.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.consumed.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (Date.now() > departmentInvitation.expiresAt) {
    return (
      <Box
        flex
        flexDirection="column"
        flexAlign="center"
        gap={32}
        data-testid="sign-up-from-invitation-expired"
      >
        <Icon name="clock-solid" size={100} color="invitationExpiredIcon" />

        <Box flex flexDirection="column" gap={4} textAlign="center">
          <Text
            variant="titleMd"
            tx="label.join-orbi.expired.title"
            color="signUpRightSideTitle"
          />

          <Text
            tx="label.join-orbi.expired.subtitle"
            variant="bodyMd"
            color="signUpRightSideSubtitle"
          />
        </Box>
      </Box>
    );
  }

  if (departmentInvitation.userExists) {
    return (
      <React.Fragment>
        <Box flex flexDirection="column" gap={8}>
          <Text
            textAlign="center"
            tx="label.auth.sign-in.title"
            variant="titleMd"
            width="32ch"
            color="signUpRightSideTitle"
          />
        </Box>

        <UserExistsForm />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text
        variant="titleMd"
        tx="label.auth.sign-up.title"
        color="signUpRightSideTitle"
        textAlign="center"
      />

      <SignUpFromInvitationForm />
    </React.Fragment>
  );
}

export function Content() {
  const {
    departmentDetails,
    isConsumed,
    isExpired,
    teamInvitationStatus,
    error,
  } = useSelector(DepartmentInvitationSelector.selectSignUpByInvitationContent);

  const { departmentInvitationKey } = useParams<{
    departmentInvitationKey: string;
  }>();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!departmentInvitationKey) return;

    dispatch(getDepartmentInvitationThunk(departmentInvitationKey));
  }, [dispatch, departmentInvitationKey]);

  if (teamInvitationStatus !== 'completed' && !error) {
    return <OrbiLoader data-testid="sign-up-from-invitation-content-loader" />;
  }

  return (
    <React.Fragment>
      <SignedOutToolbar />

      {!departmentDetails || isConsumed || isExpired ? (
        <SplitScreenContentContainer data-testid="sign-up-by-invitation-content">
          <SignUpFromInvitationContent />
        </SplitScreenContentContainer>
      ) : (
        <SplitScreenContainer data-testid="sign-up-by-invitation-content">
          <SplitScreenLeftSide
            data-testid="sign-up-from-invitation-left-side"
            avatarSrc={departmentDetails.logo?.thumbnail64.url}
            avatarFallbackLetter={departmentDetails.name.charAt(0)}
            avatarVariant={getAvatarVariantFromString(
              departmentDetails.departmentKey,
            )}
            title={departmentDetails.name}
            blurredCards={[
              {
                titleTx: 'label.auth.sign-up.followers',
                subtitle: departmentDetails.followerCount,
                icon: 'user-group-outline',
                width: 150,
              },
              {
                titleTx: 'label.auth.sign-up.university',
                subtitle: departmentDetails.schoolName,
                icon: 'building-library-outline',
                width: 150,
              },
              {
                titleTx: 'label.auth.sign-up.events-hosted',
                subtitle: departmentDetails.activityCount,
                icon: 'ticket-outline',
                width: 150,
              },
            ]}
          />

          <SplitScreenContentContainer>
            <SignUpFromInvitationContent />
          </SplitScreenContentContainer>
        </SplitScreenContainer>
      )}
    </React.Fragment>
  );
}

export function SignUpFromInvitation() {
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  const isNotIdle = useSelector(SignUpSelector.selectIsNotIdle);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!authenticated || isNotIdle) return;

    dispatch(signOutThunk());
  }, [dispatch, authenticated, isNotIdle]);

  if (authenticated || isNotIdle) {
    return <OrbiLoader data-testid="sign-up-from-invitation-loader" />;
  }

  return <Content />;
}

export function SignUp() {
  return <Navigate to="/sign-in" replace />;
}
