import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  DeletePeriodErrorResponse,
  MembershipPeriodForm,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { createPeriodMapper, updatePeriodMapper } from './periods.mappers';

export const deletePeriod = async (params: {
  membershipTypeKey: string;
  membershipPeriodKey: string;
}): Promise<GeneralApiResult<DeletePeriodErrorResponse>> => {
  const { membershipTypeKey, membershipPeriodKey } = params;

  const res = await apisauce.delete<void, DeletePeriodErrorResponse>(
    `/v2/membership-types/${membershipTypeKey}/periods/${membershipPeriodKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createPeriod = async (params: {
  membershipTypeKey: string;
  createPeriod: MembershipPeriodForm;
}): Promise<GeneralApiResultWithData<string>> => {
  const { membershipTypeKey, createPeriod } = params;

  const res = await apisauce.post<{ membershipPeriodKey: string }, any>(
    `/v2/membership-types/${membershipTypeKey}/periods`,
    createPeriodMapper(createPeriod),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.membershipPeriodKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createPeriod', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updatePeriod = async (params: {
  membershipTypeKey: string;
  updatePeriod: MembershipPeriodForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, updatePeriod } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/periods/${updatePeriod.key}`,
    updatePeriodMapper(updatePeriod),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
