import {
  Box,
  Button,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  SelectionCard,
  useCheckFeatureFlag,
} from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import { PostTargetType } from 'dtos/base/post-target-type';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm } from '../../../models';
import { DepartmentSelector, useSelector } from '../../../store';
import { QuickActionsContext } from '../../quick-actions';

export function PickTargetType() {
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const enablePostsInMembership = useCheckFeatureFlag(
    'student_dashboard_posts_in_memberships',
  );

  const { createPostModalState } = React.useContext(QuickActionsContext);
  const formContext = useFormContext<CreatePostForm>();

  const targetType = formContext.watch('target.type');

  const [selectedTargetType, setSelectedTargetType] = useState<PostTargetType>(
    targetType ?? 'department',
  );

  const selectDepartmentTargetType = () => {
    setSelectedTargetType('department');
  };

  const selectActivityTargetType = () => {
    setSelectedTargetType('activity');
  };

  const selectMembershipTargetType = () => {
    setSelectedTargetType('membershipType');
  };

  const continueToForm = () => {
    if (!selectedTargetType) return;

    formContext.setValue('target.type', selectedTargetType);

    if (selectedTargetType === 'department' && departmentKey) {
      formContext.setValue('target.key', departmentKey);
    }
  };

  const closeAndReset = () => {
    createPostModalState.closeModal();
    formContext.reset();

    window.setTimeout(() => {
      setSelectedTargetType('department');
    }, transitonDuration.slow);
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle
          textAlign="center"
          tx="label.posts.create-post-modal.choose-where-to-post"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <Box gap={24} flex flexDirection="column">
          <SelectionCard
            onClick={selectDepartmentTargetType}
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            titleTx="label.posts.create-post-modal.department"
            toggled={selectedTargetType === 'department'}
          />

          <SelectionCard
            onClick={selectActivityTargetType}
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            titleTx="label.posts.create-post-modal.event"
            toggled={selectedTargetType === 'activity'}
          />

          {enablePostsInMembership && (
            <SelectionCard
              onClick={selectMembershipTargetType}
              minWidth={300}
              flexBasis={0}
              flexGrow={1}
              titleTx="label.posts.create-post-modal.membership"
              toggled={selectedTargetType === 'membershipType'}
            />
          )}
        </Box>
      </ModalBodyContainer>

      <ModalFooterContainer flexJustify="end">
        <Button tx="button.cancel" variant="tertiary" onClick={closeAndReset} />

        <Button
          tx="button.continue"
          variant="primary"
          onClick={continueToForm}
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
