import { Question } from '../../../../../models';
import { QuestionDto } from './questions.dto';

export const questionMapper = (question: QuestionDto): Question => ({
  activityKey: question.activityKey,
  activityTitle: question.activityTitle,
  answer: question.answer,
  answeredAt: question.answeredAt,
  answeredByFullName: question.answeredByFullName,
  answerUpdatedAt: question.answerUpdatedAt,
  askedByEmail: question.askedByEmail,
  askedByFullName: question.askedByFullName,
  createdAt: question.createdAt,
  isHidden: question.isHidden,
  question: question.question,
  questionKey: question.activityQuestionKey,
});
