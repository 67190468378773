import React from 'react';

import { useScroll } from '../../helpers';
import { useTheme } from '../../theme';
import { Box } from '../box';
import { TABLE_SHADOW_WIDTH } from './table.constants';
import { Styled } from './table.styled';
import { TableContextProps, TableProps } from './table.types';

export const TableContext = React.createContext<TableContextProps>({
  leftShadowRef: { current: null },
  rightShadowRef: { current: null },
  tableContainerRef: { current: null },
});

function TableProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const leftShadowRef = React.useRef<HTMLDivElement>(null);
  const rightShadowRef = React.useRef<HTMLDivElement>(null);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <TableContext.Provider
      value={{ leftShadowRef, rightShadowRef, tableContainerRef }}
    >
      {children}
    </TableContext.Provider>
  );
}

function TableShadows() {
  const { leftShadowRef, rightShadowRef, tableContainerRef } =
    React.useContext(TableContext);

  const theme = useTheme();

  useScroll(tableContainerRef, (value) => {
    const leftShadow = leftShadowRef.current;
    const rightShadow = rightShadowRef.current;
    if (!leftShadow || !rightShadow) return;

    leftShadow.style.display = value.isAtLeft ? 'none' : 'block';
    rightShadow.style.display = value.isAtRight ? 'none' : 'block';
  });

  return (
    <React.Fragment>
      <div
        ref={leftShadowRef}
        style={{
          boxShadow: theme.elevation.tableCellLeft,
          display: 'none',
          height: '100%',
          left: 64 - TABLE_SHADOW_WIDTH,
          opacity: 0,
          position: 'absolute',
          top: 0,
          width: TABLE_SHADOW_WIDTH,
          zIndex: 11,
          pointerEvents: 'none',
        }}
      ></div>

      <div
        ref={rightShadowRef}
        style={{
          boxShadow: theme.elevation.tableCellRight,
          display: 'none',
          height: '100%',
          opacity: 0,
          position: 'absolute',
          right: 72 - TABLE_SHADOW_WIDTH / 2,
          top: 0,
          width: TABLE_SHADOW_WIDTH,
          zIndex: 11,
          pointerEvents: 'none',
        }}
      ></div>
    </React.Fragment>
  );
}

function TableContainer(props: React.PropsWithChildren) {
  const { children } = props;

  const { tableContainerRef } = React.useContext(TableContext);

  return (
    <Styled.TableContainer ref={tableContainerRef}>
      {children}
    </Styled.TableContainer>
  );
}

function renderCol(width: number, index: number) {
  return <col key={`table-layout-col-${index}`} span={1} width={width} />;
}

export function Table(props: TableProps) {
  const { layout, children } = props;

  return (
    <TableProvider>
      <Box relative zIndex={12}>
        <TableShadows />

        <TableContainer>
          <Styled.Table isFixed={!!layout}>
            {layout && <colgroup>{layout.map(renderCol)}</colgroup>}

            {children}
          </Styled.Table>
        </TableContainer>
      </Box>
    </TableProvider>
  );
}
