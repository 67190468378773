import { Box, BoxProps } from '../box';

export interface PasswordStrengthOptions {
  minCharacters: number;
  minNumbers: number;
  minSpecialCharacters: number;
}

interface InputStrengthProps extends PasswordStrengthOptions, BoxProps {
  value: string;
  hasError?: boolean;
}

const specialSymbolRegexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
const numberRegexp = /\d/g;

export function getPasswordStrength(
  password: string,
  options: PasswordStrengthOptions,
) {
  const strength = {
    score: 0,
    length: password.length,
    numbers: 0,
    specialCharacters: 0,
  };

  if (password.length >= options.minCharacters) {
    strength.score += 1;
  }

  strength['numbers'] = Array.from(password.matchAll(numberRegexp)).length;
  if (strength['numbers'] >= options.minNumbers) {
    strength.score += 1;
  }

  strength['specialCharacters'] = Array.from(
    password.matchAll(specialSymbolRegexp),
  ).length;
  if (strength['specialCharacters'] >= options.minSpecialCharacters) {
    strength.score += 1;
  }

  return strength;
}

export function InputStrength(props: InputStrengthProps) {
  const { value, hasError, minCharacters, minNumbers, minSpecialCharacters } =
    props;

  const strength = getPasswordStrength(value, {
    minCharacters,
    minNumbers,
    minSpecialCharacters,
  });

  return (
    <Box mt={8} flex gap={4} {...props}>
      <Box
        backgroundColor={
          strength.score < 1 && hasError
            ? 'passwordStrengthIndicatorBackgroundError'
            : strength.score > 0
            ? 'passwordStrengthIndicatorBackgroundSuccess'
            : 'passwordStrengthIndicatorBackground'
        }
        height={4}
        width="100%"
      />
      <Box
        backgroundColor={
          strength.score < 2 && hasError
            ? 'passwordStrengthIndicatorBackgroundError'
            : strength.score > 1
            ? 'passwordStrengthIndicatorBackgroundSuccess'
            : 'passwordStrengthIndicatorBackground'
        }
        height={4}
        width="100%"
      />
      <Box
        backgroundColor={
          strength.score < 3 && hasError
            ? 'passwordStrengthIndicatorBackgroundError'
            : strength.score > 2
            ? 'passwordStrengthIndicatorBackgroundSuccess'
            : 'passwordStrengthIndicatorBackground'
        }
        height={4}
        width="100%"
      />
    </Box>
  );
}
