import styled, { css } from 'styled-components';

import { Box } from '../box';

const ChartGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      max-width: 100%;
      width: 100%;
      display: grid;
      gap: 16px;
      grid-auto-rows: 500px;

      grid-template-columns: 1fr;

      @media (min-width: ${theme.breakpoints.sm}px) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 550px;
      }
    `;
  }}
`;

export const Styled = { ChartGrid };
