import { LoadingContainer } from '@orbiapp/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ActivityDraftSelector,
  ActivitySelector,
  activityActions,
  getActivityDraftThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { NotFound } from '../../../shared';
import { CreateActivity } from '../create-activity/create-activity';

export function CreateActivityFromDraft() {
  const activityStatus = useSelector(ActivitySelector.selectStatus);
  const activityError = useSelector(ActivitySelector.selectError);
  const activityDraft = useSelector(ActivityDraftSelector.selectData);

  const dispatch = useDispatch();

  const { draftKey } = useParams<{ draftKey: string }>();

  React.useEffect(() => {
    if (!draftKey) return;

    dispatch(getActivityDraftThunk(draftKey));

    return () => {
      dispatch(activityActions.clearActivityDraft());
    };
  }, [draftKey, dispatch]);

  if (activityError) {
    return <NotFound continueTo="/activities" />;
  }

  if (activityStatus === 'pending' || !draftKey || !activityDraft) {
    return <LoadingContainer />;
  }

  return <CreateActivity defaultValues={activityDraft.data} mode="draft" />;
}
