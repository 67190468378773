import { createSlice } from '@reduxjs/toolkit';

import { AlertType } from '../../models';
import { initialGlobalUiState } from './global-ui-state.initial';
import { reducers } from './global-ui-state.reducers';

const globalUiStateSlice = createSlice({
  name: 'global-ui-state',
  initialState: initialGlobalUiState,
  reducers,
});

export const setAlert = (
  alertType: AlertType,
  args?: Record<string, string | number>,
) => globalUiStateSlice.actions.setAlert({ alertType, args });

export const globalUiStateActions = globalUiStateSlice.actions;
export const globalUiStateReducer = globalUiStateSlice.reducer;
