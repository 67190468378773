import { joiResolver } from '@hookform/resolvers/joi';
import {
  BreadcrumbsToolbar,
  InnerPageContainer,
  PageContainer,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Outlet } from 'react-router-dom';

import {
  CreateMembershipTypeForm,
  CreateMembershipTypeFormValidation,
} from '../../../../models';
import { membershipsActions, useDispatch } from '../../../../store';
import { CREATE_MEMBERSHIP_DEFAULT_VALUES } from './create-membership.constants';

export function CreateMembershipType() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(membershipsActions.clearCreateMembership());
    };
  }, [dispatch]);

  const formMethods = useForm<CreateMembershipTypeForm>({
    defaultValues: CREATE_MEMBERSHIP_DEFAULT_VALUES,
    resolver: joiResolver(CreateMembershipTypeFormValidation),
  });

  return (
    <FormProvider {...formMethods}>
      <PageContainer>
        <BreadcrumbsToolbar
          flexGrow={0.5}
          breadcrumbListItems={[
            {
              to: '/memberships',
              tx: 'label.breadcrumbs.memberships.memberships',
            },
            {
              tx: 'label.breadcrumbs.memberships.create-membership',
              to: '/memberships/create-membership',
            },
          ]}
          tabButtonListItems={[
            {
              text: `1. ${translate(
                'label.memberships.create-membership.tabs.general-info',
              )}`,
              to: '/memberships/create-membership/general-info',
              disabledClick: true,
            },
            {
              text: `2. ${translate(
                'label.memberships.create-membership.tabs.settings',
              )}`,
              to: '/memberships/create-membership/settings',
              disabledClick: true,
            },
            {
              text: `3. ${translate(
                'label.memberships.create-membership.tabs.questions',
              )}`,
              to: '/memberships/create-membership/questions',
              disabledClick: true,
            },
            {
              text: `4. ${translate(
                'label.memberships.create-membership.tabs.periods',
              )}`,
              to: '/memberships/create-membership/periods',
              disabledClick: true,
            },
            {
              text: `5. ${translate(
                'label.memberships.create-membership.tabs.summary',
              )}`,
              to: '/memberships/create-membership/summary',
              disabledClick: true,
            },
          ]}
        />

        <InnerPageContainer>
          <Outlet />
        </InnerPageContainer>
      </PageContainer>
    </FormProvider>
  );
}
