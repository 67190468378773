import styled, { css } from 'styled-components';

import { Box, BoxProps } from '../box';

interface StyledInputElementBox extends BoxProps {
  disabled?: boolean;
  hasError?: boolean;
}

const InputElementBox = styled(Box)<StyledInputElementBox>`
  ${(props) => {
    const { disabled, hasError, theme } = props;

    const baseStyles = css`
      width: fit-content;
    `;

    if (disabled) {
      return css`
        ${baseStyles};
        color: ${theme.colors.inputIconDisabled};
      `;
    }

    if (hasError) {
      return css`
        ${baseStyles};
        color: ${theme.colors.inputIconError};
      `;
    }

    return css`
      ${baseStyles};
      color: ${theme.colors.inputIcon};
    `;
  }}
`;

export const Styled = { InputElementBox };
