import { AnchoredMenuState, Time, useAnchoredMenu } from '@orbiapp/components';
import React from 'react';

export const ProfileMenuContext = React.createContext<AnchoredMenuState>(
  {} as AnchoredMenuState,
);

export function ProfileMenuProvider(props: React.PropsWithChildren) {
  const anchoredMenu = useAnchoredMenu({
    placement: 'bottom-end',
    autoCloseDuration: 5 * Time.Second,
  });

  return <ProfileMenuContext.Provider value={anchoredMenu} {...props} />;
}
