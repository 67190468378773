import { translate } from '../../i18n';
import { EditMeta } from '../../models';
import { formatDate } from '../../utils';
import { Avatar, AvatarVariant, getAvatarVariantFromString } from '../avatar';
import { Box } from '../box';
import { TableCell, TableCellProps } from '../table/table-cell';
import { Text } from '../text';

interface UpdatedTableCellProps extends TableCellProps {
  editMeta: EditMeta;
}

interface UpdatedByTableCellProps extends UpdatedTableCellProps {
  fallbackTx: TxString;
}

interface AvatarTableCellProps extends TableCellProps {
  avatarVariant?: AvatarVariant;
  avatarSrc?: string;
  fallbackLetter?: string;
  text: string;
}

export function AvatarTableCell(props: AvatarTableCellProps) {
  const { avatarVariant, avatarSrc, fallbackLetter, text, ...rest } = props;

  return (
    <TableCell {...rest}>
      <Box flexAlign="center" gap={8} flex>
        <Avatar
          variant={avatarVariant}
          src={avatarSrc}
          fallbackLetter={fallbackLetter}
        />

        <Text textTransform="capitalize" variant="bodySm" text={text} />
      </Box>
    </TableCell>
  );
}

export function UpdatedByTableCell(props: UpdatedByTableCellProps) {
  const { editMeta, fallbackTx, ...rest } = props;

  const fullName = editMeta.updatedBy
    ? `${editMeta.updatedBy.firstName} ${editMeta.updatedBy.lastName}`
    : translate(fallbackTx);

  const avatarVariant = editMeta.updatedBy?.userKey
    ? getAvatarVariantFromString(editMeta.updatedBy.userKey)
    : undefined;

  const fallbackLetter = editMeta.updatedBy?.firstName[0];
  const avatarSrc = editMeta.updatedBy?.profilePicture?.thumbnail64.url;

  return (
    <AvatarTableCell
      {...rest}
      avatarVariant={avatarVariant}
      avatarSrc={avatarSrc}
      fallbackLetter={fallbackLetter}
      text={fullName}
    />
  );
}

export function UpdatedAtTableCell(props: UpdatedTableCellProps) {
  const { editMeta, ...rest } = props;

  return (
    <TableCell
      {...rest}
      text={formatDate(
        editMeta.updatedAt ?? editMeta.createdAt,
        'DD MMM YYYY HH:mm',
      )}
    />
  );
}
