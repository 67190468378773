import {
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Text,
  WrapBox,
} from '@orbiapp/components';

import {
  EMAIL_MAX_LENGTH,
  OFFER_CONTACT_NAME_MAX_LENGTH,
} from '../../../../../models';

export function ContactDetails() {
  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.contact-details"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: 2 }}
        />
      </FormSectionHeader>

      <WrapBox breakpoint="xs" gap={16} flex>
        <ControlledInput
          labelTx="label.offer-form.contact-name"
          maxLength={OFFER_CONTACT_NAME_MAX_LENGTH}
          name="contactName"
        />
        <ControlledInput
          labelTx="label.offer-form.contact-email"
          maxLength={EMAIL_MAX_LENGTH}
          name="contactEmail"
        />
      </WrapBox>
    </FormSection>
  );
}
