import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ControlledInput,
  ControlledSelect,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  Input,
  Language,
  OptionProps,
  OrbiLoader,
  PaperContentContainer,
  Text,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { UnsavedChangesBox } from '../../../../components';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  UpdateAccountForm,
  UpdateAccountValidation,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  AccountSelector,
  UpdateAccountSelector,
  updateAccountThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { Styled } from './account-settings.styled';

const LANGUAGE_OPTIONS: OptionProps[] = [
  {
    value: Language.EN,
    tx: 'label.languages.en',
  },
  {
    value: Language.SV,
    tx: 'label.languages.sv',
  },
];

function AccountSettingsForm(props: UpdateAccountForm) {
  const userEmail = useSelector(AccountSelector.selectEmail);
  const updateAccountStatus = useSelector(UpdateAccountSelector.selectStatus);

  const dispatch = useDispatch();

  const formMethods = useForm<UpdateAccountForm>({
    resolver: joiResolver(UpdateAccountValidation),
    defaultValues: props,
    shouldFocusError: false,
  });

  const updateUser = formMethods.handleSubmit(
    async (data) => {
      await dispatch(updateAccountThunk(data));

      if (data.language !== props.language) {
        window.location.reload();
      }

      formMethods.reset(data);
    },
    (err) => {
      Logger.warning('updateUser Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const reset = () => {
    formMethods.reset();
  };

  return (
    <InnerPaperContentContainer>
      {(formMethods.formState.isDirty || updateAccountStatus === 'pending') && (
        <UnsavedChangesBox
          onRevert={reset}
          onSave={updateUser}
          isLoading={updateAccountStatus === 'pending'}
        />
      )}

      <InnerPaperContentContainerSectionsContainer>
        <InnerPaperContentContainerSection>
          <Box flex flexDirection="column" gap={16}>
            <Text tx="label.settings.profile" variant="bodyMdBold" as="h1" />

            <Box flex flexDirection="column" gap={32}>
              <Styled.SettingsGrid>
                <Input
                  disabled
                  labelTx="label.account-settings.account-info.email"
                  readOnly
                  value={userEmail ?? ''}
                />

                <FormProvider {...formMethods}>
                  <ControlledSelect
                    width="100%"
                    labelTx="label.account-settings.language.label"
                    name="language"
                    options={LANGUAGE_OPTIONS}
                  />

                  <ControlledInput
                    labelTx="label.account-settings.account-info.first-name"
                    maxLength={FIRST_NAME_MAX_LENGTH}
                    name="firstName"
                    required
                  />

                  <ControlledInput
                    labelTx="label.account-settings.account-info.last-name"
                    maxLength={LAST_NAME_MAX_LENGTH}
                    name="lastName"
                    required
                  />
                </FormProvider>
              </Styled.SettingsGrid>
            </Box>
          </Box>
        </InnerPaperContentContainerSection>
      </InnerPaperContentContainerSectionsContainer>
    </InnerPaperContentContainer>
  );
}

export function AccountSettings() {
  const firstName = useSelector(AccountSelector.selectFirstName);
  const lastName = useSelector(AccountSelector.selectLastName);
  const language = useSelector(AccountSelector.selectLanguage);

  if (!firstName || !lastName || !language) {
    return <OrbiLoader />;
  }

  const defaultValues: UpdateAccountForm = {
    firstName,
    lastName,
    language,
  };

  return (
    <PaperContentContainer>
      <AccountSettingsForm {...defaultValues} />
    </PaperContentContainer>
  );
}
