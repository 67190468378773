import { Box, LAYOUT_Z_INDEX } from '@orbiapp/components';
import styled, { css, keyframes } from 'styled-components';

const scaleIn = keyframes`
  0% {
    transform: scale(0);
  }
  
  100% {
    transform: scale(1);
  }
`;

const ScanResultCard = styled(Box)<{ isFullscreen: boolean }>`
  ${(props) => {
    const { isFullscreen, theme } = props;

    return css`
      max-width: 100%;
      width: 425px;
      position: relative;

      background-color: ${theme.colors.scanQrCodesCardBackground};
      border: 1px solid ${theme.colors.scanQrCodesCardBorder};
      animation: ${scaleIn} ${theme.transitions.fast};

      ${isFullscreen &&
      css`
        position: fixed;
        bottom: 24px;
        right: 24px;
        z-index: ${LAYOUT_Z_INDEX.scanTickets.scanResultCard};
        height: fit-content;
        box-shadow: ${theme.elevation.medium};

        @media (max-width: ${theme.breakpoints.xs}) {
          right: unset;
          margin: 0 auto;
        }
      `}
    `;
  }}
`;

export const Styled = { ScanResultCard };
