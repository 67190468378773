import { Box } from '@orbiapp/components';
import styled from 'styled-components';

const ResponsiveGrid = styled(Box)`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;

export const Styled = { ResponsiveGrid };
