import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { StyledIconProps } from './icon.types';

const Icon = styled.span<StyledIconProps>`
  ${(props) => {
    const { color, theme, size } = props;

    return css`
      ${baseComponentStyles};

      color: ${color ? theme.colors[color] : 'inherit'};
      height: ${size}px;
      width: ${size}px;
      min-width: ${size}px;
      min-height: ${size}px;
    `;
  }}
`;

export const Styled = { Icon };
