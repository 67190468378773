import { initialReactionsState } from './reactions.initial';
import { ReactionsState } from './reactions.types';

const reset = (state: ReactionsState) => {
  Object.assign(state, initialReactionsState);
};

const resetReactions = (state: ReactionsState) => {
  state.reactions = initialReactionsState.reactions;
};

export const reducers = { reset, resetReactions };
