import { css } from 'styled-components';

import { pxToRem } from '../string';
import { isNumber, isUndefined } from '../utils';
import { Radius, Shape } from './shape.types';

export const shapeStyles = css<Shape>`
  ${(props) => {
    const { shape } = props;

    const styles: ReturnType<typeof css>[] = [];

    if (isUndefined(shape)) return css``;

    if (!isUndefined(shape) && !isUndefined(shape?.tl)) {
      styles.push(css`
        border-top-left-radius: ${isNumber(shape.tl)
          ? pxToRem(shape.tl)
          : shape.tl};
      `);
    }

    if (!isUndefined(shape?.tr)) {
      styles.push(css`
        border-top-right-radius: ${isNumber(shape.tr)
          ? pxToRem(shape.tr)
          : shape.tr};
      `);
    }

    if (!isUndefined(shape?.br)) {
      styles.push(css`
        border-bottom-right-radius: ${isNumber(shape.br)
          ? pxToRem(shape.br)
          : shape.br};
      `);
    }

    if (!isUndefined(shape?.bl)) {
      styles.push(css`
        border-bottom-left-radius: ${isNumber(shape.bl)
          ? pxToRem(shape.bl)
          : shape.bl};
      `);
    }

    return css`
      ${styles.map((style) => style)}
    `;
  }}
`;

export const radiusStyles = css<Radius>`
  ${(props) => {
    const { r } = props;

    if (isUndefined(r)) {
      return css``;
    }

    return css`
      border-radius: ${isNumber(r) ? pxToRem(r) : r};
    `;
  }};
`;
