import Joi from 'joi';

import { EmailValidationOptions } from './team.model';
import { TeamSchema } from './team.schema';

export const UpdateUserValidation = Joi.object({
  role: TeamSchema.role,
});

export const InviteTeamMembersValidation = (options: EmailValidationOptions) =>
  Joi.object({
    invitations: TeamSchema.invitations(options),
  });
