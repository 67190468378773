import { StatsigProvider, useFeatureGate } from '@statsig/react-bindings';
import React from 'react';

const featureFlags = [
  'student_dashboard_memlist_membership',
  'student_dashboard_show_questions',
  'student_dashboard_posts_in_memberships',

  'workspace_menu_item_subtitle',

  'company_dashboard_enable_job_budget',
] as const;

export type FeatureFlag = (typeof featureFlags)[number];

export function useCheckFeatureFlag(name: FeatureFlag) {
  const featureGate = useFeatureGate(name);

  return featureGate.value;
}

export type StatsigEnv = 'production' | 'staging';

type FeatureFlagProviderProps = React.PropsWithChildren<{
  env: StatsigEnv;

  sdkKey: string;

  userEmail?: string;
  userKey?: string;
}>;

export function FeatureFlagProvider(props: FeatureFlagProviderProps) {
  const { children, userEmail, userKey, sdkKey, env } = props;

  return (
    <StatsigProvider
      user={{ userID: userKey, email: userEmail }}
      sdkKey={sdkKey}
      options={{ environment: { tier: env } }}
    >
      {children}
    </StatsigProvider>
  );
}
