import { initialJobsState } from './jobs.initial';
import { JobState } from './jobs.types';

function reset(state: JobState) {
  Object.assign(state, initialJobsState);
}

export const reducers = {
  reset,
};
