import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  Chip,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ControlledSelect,
  ControlledTextarea,
  EmptyState,
  INITIAL_MODAL_STATE,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LoadingContainer,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalState,
  ModalTitle,
  OptionProps,
  PageContainer,
  Select,
  Spinner,
  Status,
  TabButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  flattenFieldErrorsObject,
  formatDate,
  paginatorOptions,
  translate,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useDataGridPagination } from '../../../../helpers';
import {
  MembershipApplicationAnswer,
  MembershipApplicationListItem,
  MembershipApplicationPeriod,
  MembershipApplicationStatus,
  NOTE_MAX_LENGTH,
  NeedsMoreInfoReason,
  RejectMembershipApplicationForm,
  RejectMembershipApplicationFormValidation,
  RequestMoreInfoOnMembershipApplicationForm,
  RequestMoreInfoOnMembershipApplicationFormValidation,
  RequestedInfoType,
  membershipApplicationStatuses,
  membershipApplicationsSortableKeys,
  needsMoreInfoReasons,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  AcceptApplicationSelector,
  INITIAL_PAGINATION_STATE,
  MembershipApplicationSelector,
  MembershipApplicationsSelector,
  MembershipTypeSelector,
  RejectApplicationSelector,
  RequestMoreInfoOnMembershipApplicationSelector,
  acceptApplicationThunk,
  getMembershipApplicationThunk,
  getMembershipApplicationsThunk,
  getMembershipTypeThunk,
  membershipsActions,
  rejectApplicationThunk,
  requestMoreInfoOnMembershipApplicationThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { NotFound } from '../../../shared';
import { SummaryList, SummaryListItem } from '../components/summary-list';
import { Styled } from './review-applications.styled';
import {
  GetStatusPillOptionsReturn,
  HistoryLogItemProps,
} from './review-applications.types';

const ReviewApplicationsContext = React.createContext<{
  rejectApplicationModalState: ModalState;
  requestMoreInfoModalState: ModalState;
}>({
  rejectApplicationModalState: INITIAL_MODAL_STATE,
  requestMoreInfoModalState: INITIAL_MODAL_STATE,
});

function ReviewApplicationsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const rejectApplicationModalState = useModalState();
  const requestMoreInfoModalState = useModalState();

  const value = {
    rejectApplicationModalState,
    requestMoreInfoModalState,
  };

  return (
    <ReviewApplicationsContext.Provider value={value}>
      {children}
    </ReviewApplicationsContext.Provider>
  );
}

function getStatusPillOptions(
  status: MembershipApplicationStatus,
): GetStatusPillOptionsReturn {
  switch (status) {
    case 'awaiting_completion':
      return {
        tx: 'label.memberships.view-membership.application-status.awaiting-completion',
        variant: 'info',
      };

    case 'completed':
      return {
        tx: 'label.memberships.view-membership.application-status.completed',
        variant: 'success',
      };

    case 'needs_more_info':
      return {
        tx: 'label.memberships.view-membership.application-status.needs-more-info',
        variant: 'info',
      };

    case 'pending_review':
      return {
        tx: 'label.memberships.view-membership.application-status.pending-review',
        variant: 'info',
      };

    case 'rejected':
      return {
        tx: 'label.memberships.view-membership.application-status.rejected',
        variant: 'error',
      };

    case 'withdrawn':
      return {
        tx: 'label.memberships.view-membership.application-status.withdrawn',
        variant: 'error',
      };
  }
}

const getApplicationStatusOptionTx = (
  status: MembershipApplicationStatus | null,
): TxString => {
  switch (status) {
    case 'awaiting_completion':
      return 'label.memberships.review-applications.application-status.awaiting-completion';

    case 'completed':
      return 'label.memberships.review-applications.application-status.completed';

    case 'needs_more_info':
      return 'label.memberships.review-applications.application-status.needs-more-info';

    case 'pending_review':
      return 'label.memberships.review-applications.application-status.pending-review';

    case 'rejected':
      return 'label.memberships.review-applications.application-status.rejected';

    case 'withdrawn':
      return 'label.memberships.review-applications.application-status.withdrawn';

    default:
      return 'label.memberships.review-applications.application-status.all';
  }
};

function getHistoryLogItemTx(status: MembershipApplicationStatus): TxString {
  switch (status) {
    case 'withdrawn':
      return 'label.memberships.view-membership.review-applications.history.withdrawn';

    case 'rejected':
      return 'label.memberships.view-membership.review-applications.history.rejected';

    case 'pending_review':
      return 'label.memberships.view-membership.review-applications.history.pending-review';

    case 'needs_more_info':
      return 'label.memberships.view-membership.review-applications.history.needs-more-info';

    case 'completed':
      return 'label.memberships.view-membership.review-applications.history.completed';

    default:
      return 'label.memberships.view-membership.review-applications.history.awaiting-completion';
  }
}

function getNeedMoreInfoReasonTx(reason: NeedsMoreInfoReason): TxString {
  switch (reason) {
    case 'answers':
      return 'label.memberships.view-membership.review-applications.request-more-info.answers';
    case 'other':
      return 'label.memberships.view-membership.review-applications.request-more-info.other';
  }
}

function MembershipApplicationsEmptyState() {
  return (
    <EmptyState titleTx="label.memberships.review-applications.empty-state.title" />
  );
}

const TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  email: 200,
  status: 200,
  createdAt: 200,
};

function ReviewApplicationsContent() {
  const membershipApplications = useSelector(
    MembershipApplicationsSelector.selectAll,
  );

  const membershipApplicationsStatus = useSelector(
    MembershipApplicationsSelector.selectStatus,
  );

  const membershipApplicationsPagination = useSelector(
    MembershipApplicationsSelector.selectPagination,
  );

  const selectMembershipApplicationKey = useSelector(
    MembershipApplicationSelector.selectMembershipApplicationKey,
  );

  const isLoading = membershipApplicationsStatus === 'pending';

  const dispatch = useDispatch();

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination({
      data: membershipApplications,
      pagination: membershipApplicationsPagination,
      reset: membershipsActions.clearMembershipApplications,
      thunk: getMembershipApplicationsThunk,
    });

  const renderPendingApplicationRow = (
    membershipApplication: MembershipApplicationListItem,
  ) => {
    const selectRow = () => {
      if (
        selectMembershipApplicationKey ===
        membershipApplication.membershipApplicationKey
      )
        return;

      dispatch(
        getMembershipApplicationThunk(
          membershipApplication.membershipApplicationKey,
        ),
      );
    };

    return (
      <TableRow
        highlight={
          membershipApplication.membershipApplicationKey ===
          selectMembershipApplicationKey
        }
        key={membershipApplication.membershipApplicationKey}
        onClick={selectRow}
      >
        <TableCell
          width={TABLE_COLUMN_WIDTHS.fullName}
          text={membershipApplication.fullName}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.email}
          text={membershipApplication.email}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status {...getStatusPillOptions(membershipApplication.status)} />
        </TableCell>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.createdAt}
          text={formatDate(
            membershipApplication.createdAt,
            'DD MMM YYYY HH:mm',
          )}
        />
      </TableRow>
    );
  };

  if (
    (!membershipApplications || membershipApplications.length === 0) &&
    isLoading
  ) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.view-membership.review-applications.full-name" />
            <TableHead tx="label.memberships.view-membership.review-applications.email" />
            <TableHead tx="label.memberships.view-membership.review-applications.status" />
            <TableHead tx="label.memberships.view-membership.review-applications.applied-at" />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (
    (!membershipApplications || membershipApplications.length === 0) &&
    !isLoading
  ) {
    return <MembershipApplicationsEmptyState />;
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={membershipApplicationsPagination.orderBy}
          sortableColumns={Object.values(membershipApplicationsSortableKeys)}
          sortOrder={membershipApplicationsPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.memberships.view-membership.review-applications.full-name"
              name={membershipApplicationsSortableKeys.fullName}
            />
            <TableHead
              tx="label.memberships.view-membership.review-applications.email"
              name={membershipApplicationsSortableKeys.email}
            />
            <TableHead
              tx="label.memberships.view-membership.review-applications.status"
              name={membershipApplicationsSortableKeys.status}
            />
            <TableHead
              tx="label.memberships.view-membership.review-applications.applied-at"
              name={membershipApplicationsSortableKeys.createdAt}
            />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderPendingApplicationRow)}</TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function SegmentationPill() {
  const segmentation = useSelector(
    MembershipApplicationSelector.selectSegmentation,
  );
  const semester = useSelector(MembershipApplicationSelector.selectSemester);

  if (typeof semester !== 'number') {
    return null;
  }

  if (segmentation === 'semester') {
    return (
      <Chip
        tx="label.memberships.view-membership.semester"
        txArgs={{ semester: semester.toString() }}
        variant={2}
      />
    );
  }

  return (
    <Chip
      tx="label.memberships.view-membership.year"
      txArgs={{ year: (Math.floor(semester / 2) + 1).toString() }}
      variant={2}
    />
  );
}

function ApplicantAnswer(props: MembershipApplicationAnswer) {
  const { type, question } = props;

  switch (type) {
    case RequestedInfoType.Checkbox:
      const { isChecked } = props;

      return (
        <SummaryListItem
          title={question}
          subtitleTx={isChecked ? 'label.boolean.yes' : 'label.boolean.no'}
        />
      );

    case RequestedInfoType.MultiChoice:
      const { label } = props;

      return <SummaryListItem title={question} subtitle={label} />;

    case RequestedInfoType.FreeText:
      const { answer } = props;

      return <SummaryListItem title={question} subtitle={answer} />;
  }
}

function renderPeriodChip(period: MembershipApplicationPeriod) {
  return <Chip key={period.key} variant={1} text={period.name} />;
}

function ChosenPeriods() {
  const periods = useSelector(MembershipApplicationSelector.selectPeriods);

  if (!periods) {
    return null;
  }

  return (
    <SummaryList>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.view-membership.review-applications.selected-periods"
      />

      <Box flex flexWrap="wrap" gap={16}>
        {periods.map(renderPeriodChip)}
      </Box>
    </SummaryList>
  );
}

function renderApplicantAnswer(answer: MembershipApplicationAnswer) {
  return <ApplicantAnswer {...answer} />;
}

const ApplicantAnswers = () => {
  const answers = useSelector(MembershipApplicationSelector.selectAnswers);

  if (!answers) {
    return null;
  }

  return (
    <SummaryList>
      <Text
        tx="label.memberships.view-membership.review-applications.applicant-answers"
        variant="bodyMdBold"
      />

      {answers.map(renderApplicantAnswer)}
    </SummaryList>
  );
};

function ApplicationGeneralInfo() {
  return (
    <React.Fragment>
      <ChosenPeriods />
      <ApplicantAnswers />
    </React.Fragment>
  );
}

function HistoryLogItem(props: HistoryLogItemProps) {
  const { createdAt, hideDots, note, titleTx, titleTxArgs } = props;

  return (
    <Box gap={16} flex>
      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        flexJustify="between"
        gap={8}
      >
        <Styled.InfoIcon name="information-circle-outline" />

        {!hideDots && (
          <Box flex flexAlign="center" flexDirection="column" gap={8}>
            <Styled.Dot />
            <Styled.Dot />
            <Styled.Dot />
          </Box>
        )}
      </Box>

      <Box gap={8} flex flexDirection="column">
        <Box gap={4} flex flexWrap="wrap">
          <Text
            variant="bodySm"
            text={formatDate(createdAt, 'DD MMM YYYY HH:mm')}
          />
          <Text variant="bodySm" tx={titleTx} txArgs={titleTxArgs} />
        </Box>

        {note && (
          <React.Fragment>
            <Styled.NoteBox pl={16}>
              <Text maxWidth="48ch" variant="bodySmItalic" text={note} />
            </Styled.NoteBox>
            <Box height={28} />
          </React.Fragment>
        )}
      </Box>
    </Box>
  );
}

function renderHistoryLogItem(
  historyLogItem: HistoryLogItemProps,
  index: number,
) {
  return (
    <HistoryLogItem
      key={`${historyLogItem.titleTx}-${index}`}
      {...historyLogItem}
    />
  );
}

function ApplicationHistory() {
  const statusUpdates = useSelector(
    MembershipApplicationSelector.selectStatusUpdates,
  );
  const fullName = useSelector(MembershipApplicationSelector.selectFullName);

  if (!statusUpdates || statusUpdates.length === 0) {
    return (
      <Text
        variant="bodySm"
        textAlign="center"
        tx="label.memberships.view-membership.review-applications.no-history"
      />
    );
  }

  const historyLogItems: HistoryLogItemProps[] = [...statusUpdates]
    .sort((a, b) => b.createdAt - a.createdAt)
    .map((statusUpdate, index) => {
      if (index === statusUpdates.length - 1) {
        const historyLogItemProps: HistoryLogItemProps = {
          note: statusUpdate.note,
          hideDots: true,
          createdAt: statusUpdate.createdAt,
          titleTx:
            'label.memberships.view-membership.review-applications.history.applied',
          titleTxArgs: {},
        };

        if (fullName && historyLogItemProps.titleTxArgs) {
          historyLogItemProps.titleTxArgs.fullName = fullName;
        }

        return historyLogItemProps;
      }

      const historyLogItemProps: HistoryLogItemProps = {
        createdAt: statusUpdate.createdAt,
        hideDots: false,
        note: statusUpdate.note,
        titleTx: getHistoryLogItemTx(statusUpdate.status),
        titleTxArgs: {},
      };

      if (fullName && historyLogItemProps.titleTxArgs) {
        historyLogItemProps.titleTxArgs.fullName = fullName;
      }

      return historyLogItemProps;
    });

  return (
    <Box flex flexDirection="column" gap={8}>
      {historyLogItems.map(renderHistoryLogItem)}
    </Box>
  );
}

function AcceptApplicationButton() {
  const acceptApplicationStatus = useSelector(
    AcceptApplicationSelector.selectStatus,
  );
  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectApplicationStatus,
  );

  const dispatch = useDispatch();

  const acceptApplication = () => dispatch(acceptApplicationThunk());

  const disabled = new Set<MembershipApplicationStatus | null>([
    'awaiting_completion',
    'completed',
    'rejected',
    'withdrawn',
  ]).has(applicationStatus);

  return (
    <Tooltip
      placement="left"
      titleTxArgs={
        applicationStatus
          ? { status: translate(getStatusPillOptions(applicationStatus).tx) }
          : undefined
      }
      titleTx={
        disabled && applicationStatus
          ? 'label.memberships.view-membership.review-applications.accept-application-tooltip-disabled'
          : 'label.memberships.view-membership.review-applications.accept-application-tooltip'
      }
    >
      <IconButton
        disabled={disabled || acceptApplicationStatus === 'pending'}
        icon="hand-thumb-up-outline"
        onClick={acceptApplication}
      />
    </Tooltip>
  );
}

function RejectApplicationButton() {
  const rejectApplicationStatus = useSelector(
    RejectApplicationSelector.selectStatus,
  );
  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectApplicationStatus,
  );

  const { rejectApplicationModalState } = React.useContext(
    ReviewApplicationsContext,
  );

  const disabled = new Set<MembershipApplicationStatus | null>([
    'completed',
    'rejected',
    'withdrawn',
  ]).has(applicationStatus);

  return (
    <Tooltip
      placement="left"
      titleTxArgs={
        applicationStatus
          ? { status: translate(getStatusPillOptions(applicationStatus).tx) }
          : undefined
      }
      titleTx={
        disabled && applicationStatus
          ? 'label.memberships.view-membership.review-applications.reject-application-tooltip-disabled'
          : 'label.memberships.view-membership.review-applications.reject-application-tooltip'
      }
    >
      <IconButton
        disabled={disabled || rejectApplicationStatus === 'pending'}
        icon="x-circle-outline"
        onClick={rejectApplicationModalState.openModal}
      />
    </Tooltip>
  );
}

function RejectApplicationModal() {
  const membershipApplicationKey = useSelector(
    MembershipApplicationSelector.selectMembershipApplicationKey,
  )!;
  const rejectApplicationStatus = useSelector(
    RejectApplicationSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const { rejectApplicationModalState } = React.useContext(
    ReviewApplicationsContext,
  );

  const formMethods = useForm<RejectMembershipApplicationForm>({
    resolver: joiResolver(RejectMembershipApplicationFormValidation),
    defaultValues: {
      note: '',
      membershipApplicationKey,
    },
  });

  React.useEffect(() => {
    formMethods.reset({
      note: '',
      membershipApplicationKey,
    });
  }, [formMethods, membershipApplicationKey]);

  const rejectApplication = formMethods.handleSubmit(
    async (data) => {
      await dispatch(
        rejectApplicationThunk({
          note: data.note,
          membershipApplicationKey,
        }),
      );

      formMethods.reset();

      rejectApplicationModalState.closeModal();
    },
    (err) => {
      Logger.warning('rejectMembershipApplication Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <Modal
      width={500}
      isOpen={rejectApplicationModalState.isOpen}
      onClose={rejectApplicationModalState.closeModal}
    >
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle
            tx="title.memberships.reject-application"
            textAlign="center"
          />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <FormProvider {...formMethods}>
            <ControlledTextarea
              disabled={rejectApplicationStatus === 'pending'}
              maxLength={NOTE_MAX_LENGTH}
              name="note"
              labelTx="label.memberships.view-membership.review-applications.note"
            />
          </FormProvider>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button
            tx="button.cancel"
            variant="tertiary"
            onClick={rejectApplicationModalState.closeModal}
          />
          <Button
            variant="primary"
            isLoading={rejectApplicationStatus === 'pending'}
            ml="auto"
            onClick={rejectApplication}
            tx="button.send"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>
  );
}

const needMoreInfoReasonOptions: OptionProps[] = needsMoreInfoReasons.map(
  (reason) => ({
    tx: getNeedMoreInfoReasonTx(reason),
    value: reason,
  }),
);

const RequestMoreInfoIconButton = () => {
  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectApplicationStatus,
  );

  const { requestMoreInfoModalState } = React.useContext(
    ReviewApplicationsContext,
  );

  const disabled = !new Set<MembershipApplicationStatus | null>([
    'pending_review',
    'needs_more_info',
  ]).has(applicationStatus);

  return (
    <Tooltip
      placement="left"
      titleTxArgs={
        applicationStatus
          ? { status: translate(getStatusPillOptions(applicationStatus).tx) }
          : undefined
      }
      titleTx={
        disabled && applicationStatus
          ? 'label.memberships.view-membership.review-applications.request-more-info-tooltip-disabled'
          : 'label.memberships.view-membership.review-applications.request-more-info-tooltip'
      }
    >
      <IconButton
        onClick={requestMoreInfoModalState.openModal}
        icon="chat-bubble-left-ellipsis-outline"
        disabled={disabled}
      />
    </Tooltip>
  );
};

function RequestMoreInfoModal() {
  const membershipHasQuestions = useSelector(
    MembershipTypeSelector.selectHasQuestions,
  );
  const membershipApplicationKey = useSelector(
    MembershipApplicationSelector.selectMembershipApplicationKey,
  )!;

  const requestMoreInfoStatus = useSelector(
    RequestMoreInfoOnMembershipApplicationSelector.selectStatus,
  );
  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectApplicationStatus,
  );

  const dispatch = useDispatch();

  const { requestMoreInfoModalState } = React.useContext(
    ReviewApplicationsContext,
  );

  const formMethods = useForm<RequestMoreInfoOnMembershipApplicationForm>({
    resolver: joiResolver(RequestMoreInfoOnMembershipApplicationFormValidation),
    defaultValues: {
      note: '',
      reason: membershipHasQuestions ? 'answers' : 'other',
      membershipApplicationKey,
    },
  });

  const requestMoreInfo = formMethods.handleSubmit(
    async (data) => {
      await dispatch(
        requestMoreInfoOnMembershipApplicationThunk({
          reason: data.reason,
          note: data.note,
          membershipApplicationKey,
        }),
      );

      formMethods.reset();

      requestMoreInfoModalState.closeModal();
    },
    (err) => {
      Logger.warning('requestMoreInfoMembershipApplication Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      note: '',
      reason: membershipHasQuestions ? 'answers' : 'other',
      membershipApplicationKey,
    });
  }, [formMethods, membershipApplicationKey, membershipHasQuestions]);

  const disabled = !new Set<MembershipApplicationStatus | null>([
    'pending_review',
    'needs_more_info',
  ]).has(applicationStatus);

  return (
    <Modal
      width={500}
      isOpen={requestMoreInfoModalState.isOpen}
      onClose={requestMoreInfoModalState.closeModal}
    >
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle
            tx="title.memberships.request-more-info"
            textAlign="center"
          />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <FormProvider {...formMethods}>
            {membershipHasQuestions && (
              <ControlledSelect
                width="100%"
                disabled={requestMoreInfoStatus === 'pending'}
                labelTx="label.memberships.view-membership.review-applications.request-more-info.reason"
                name="reason"
                options={needMoreInfoReasonOptions}
                required
              />
            )}

            <ControlledTextarea
              disabled={requestMoreInfoStatus === 'pending'}
              labelTx="label.memberships.view-membership.review-applications.note"
              maxLength={NOTE_MAX_LENGTH}
              name="note"
            />
          </FormProvider>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button
            tx="button.cancel"
            variant="tertiary"
            onClick={requestMoreInfoModalState.closeModal}
          />
          <Button
            variant="primary"
            disabled={disabled}
            isLoading={requestMoreInfoStatus === 'pending'}
            ml="auto"
            onClick={requestMoreInfo}
            tx="button.send"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>
  );
}

function ReviewApplicationsSidebarContent() {
  const fullName = useSelector(MembershipApplicationSelector.selectFullName);
  const email = useSelector(MembershipApplicationSelector.selectEmail);
  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectApplicationStatus,
  );

  const [activeTab, setActiveTab] = React.useState<'general-info' | 'history'>(
    'general-info',
  );

  const handleTabClick = (tab: 'general-info' | 'history') => () => {
    setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Box flex flexDirection="column" gap={24}>
        <Box flex flexDirection="column" gap={8}>
          <Box flexWrap="wrap" flexJustify="between" flex>
            <Text
              text={fullName ?? translate('placeholder.deleted-user')}
              variant="bodyLgBold"
            />

            {applicationStatus && (
              <Status {...getStatusPillOptions(applicationStatus)} />
            )}
          </Box>

          <Text
            variant="bodyMd"
            text={email ?? translate('placeholder.deleted-user')}
          />

          <SegmentationPill />
        </Box>
      </Box>

      <Box flex flexDirection="column" gap={24}>
        <Box flex gap={16} flexJustify="center">
          <TabButton
            isActive={activeTab === 'general-info'}
            onClick={handleTabClick('general-info')}
            tx="label.memberships.view-membership.review-applications.tab-buttons.general-info"
          />
          <TabButton
            isActive={activeTab === 'history'}
            onClick={handleTabClick('history')}
            tx="label.memberships.view-membership.review-applications.tab-buttons.history"
          />
        </Box>

        {activeTab === 'general-info' && <ApplicationGeneralInfo />}
        {activeTab === 'history' && <ApplicationHistory />}
      </Box>

      <Styled.SelectOptionBox
        flex
        flexAlign="center"
        flexJustify="between"
        width="100%"
        px={24}
        py={16}
        r={8}
      >
        <Text tx="label.general.select-an-option" variant="bodyMdBold" />

        <Box gap={16} flex>
          <AcceptApplicationButton />

          <RejectApplicationButton />

          <RequestMoreInfoIconButton />
        </Box>
      </Styled.SelectOptionBox>
    </React.Fragment>
  );
}

function ReviewApplicationsSidebar() {
  const membershipApplicationKey = useSelector(
    MembershipApplicationSelector.selectMembershipApplicationKey,
  );

  const applicationStatus = useSelector(
    MembershipApplicationSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const isLoading = applicationStatus === 'pending';

  const closeSidebar = () => {
    dispatch(membershipsActions.clearMembershipApplication());
  };

  return (
    <ReviewApplicationsProvider>
      <ContentSidebar
        isOpen={!!membershipApplicationKey}
        onClose={closeSidebar}
        width={470}
      >
        <ContentSidebarContentContainer>
          {isLoading && (
            <Box flex flexJustify="center">
              <Spinner />
            </Box>
          )}

          {!isLoading && membershipApplicationKey && (
            <ReviewApplicationsSidebarContent />
          )}
        </ContentSidebarContentContainer>
      </ContentSidebar>

      <RejectApplicationModal />

      <RequestMoreInfoModal />
    </ReviewApplicationsProvider>
  );
}

function ReviewApplicationsToolbar() {
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );
  const membershipTypeName = useSelector(
    MembershipTypeSelector.selectMembershipTypeName,
  );

  if (!membershipTypeName) {
    return null;
  }

  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer flexJustify="between">
        <BreadCrumbs flexGrow={0.4}>
          <BreadCrumb
            to="/memberships"
            tx="label.breadcrumbs.memberships.memberships"
          />

          <BreadCrumb
            maxWidth="28ch"
            overflow="hidden"
            text={membershipTypeName}
            textOverflow="ellipsis"
            to={`/memberships/${membershipTypeKey}`}
            whiteSpace="nowrap"
          />

          <BreadCrumb
            isLast
            tx="label.breadcrumbs.memberships.review-applications"
          />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

const APPLICATION_STATUS_OPTIONS: OptionProps[] = [
  {
    tx: getApplicationStatusOptionTx(null),
    value: 'all',
  },
  ...membershipApplicationStatuses.map((status) => ({
    tx: getApplicationStatusOptionTx(status),
    value: status,
  })),
];

const SelectApplicationStatus = () => {
  const dispatch = useDispatch();

  const includeStatus = useSelector(
    MembershipApplicationsSelector.selectIncludeStatus,
  );

  const pagination = useSelector(
    MembershipApplicationsSelector.selectPagination,
  );

  const handleApplicationStatusChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    dispatch(membershipsActions.clearMembershipApplications());
    dispatch(
      membershipsActions.setMembershipApplicationsIncludeStatus(
        e.target.value === 'all'
          ? null
          : (e.target.value as MembershipApplicationStatus),
      ),
    );
    dispatch(
      getMembershipApplicationsThunk({
        ...INITIAL_PAGINATION_STATE,
        orderBy: pagination.orderBy,
        sortOrder: pagination.sortOrder,
      }),
    );
  };

  return (
    <Select
      keepOpenOnSelect
      width={300}
      labelTx="label.memberships.review-applications.application-status.label"
      value={includeStatus ?? 'all'}
      onChange={handleApplicationStatusChange}
      options={APPLICATION_STATUS_OPTIONS}
    />
  );
};

export function ReviewApplications() {
  const membershipTypeStatus = useSelector(MembershipTypeSelector.selectStatus);
  const membershipTypeError = useSelector(MembershipTypeSelector.selectError);
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  const { membershipTypeKey } = useParams<{
    membershipTypeKey: string;
  }>();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!membershipTypeKey) return;

    dispatch(getMembershipTypeThunk(membershipTypeKey));

    return () => {
      dispatch(membershipsActions.clearReviewApplications());
      dispatch(membershipsActions.clearMembership());
    };
  }, [dispatch, membershipTypeKey]);

  if (membershipTypeError) {
    return <NotFound continueTo="/memberships" />;
  }

  if (membershipTypeStatus === 'pending' || isIntegrated) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <ReviewApplicationsToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <Box flexAlign="center" gap={16} flexWrap="wrap" flex>
              <IconButton
                icon="chevron-left"
                to={`/memberships/${membershipTypeKey}/overview`}
              />

              <Text
                color="pageTitle"
                as="h1"
                tx="title.memberships.review-applications"
                variant="titleMd"
              />
            </Box>

            <Box flex flexDirection="column" gap={8}>
              <SelectApplicationStatus />

              <ReviewApplicationsContent />
            </Box>
          </InnerContentContainer>
        </ContentContainer>

        <ReviewApplicationsSidebar />
      </InnerPageContainer>
    </PageContainer>
  );
}
