import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';
import axios from 'axios';

import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import { UploadFileDto } from './file-uploads.dto';

export const uploadFile = async (
  file: File,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<UploadFileDto, any>('/v1/file-uploads', {
    name: file.name,
    mimeType: file.type,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    await axios.put(res.data.url, file, {
      headers: res.data.headers,
    });

    return { kind: 'ok', data: res.data.fileUploadKey };
  } catch (err) {
    Logger.error('uploadFile', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export async function deleteFileUpload(
  fileUploadKeys: string[],
): Promise<GeneralApiResult> {
  const res = await apisauce.put<any, any>('/v1/file-uploads/delete', {
    fileUploadKeys,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}
