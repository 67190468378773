export const DESCRIPTION_MAX_LENGTH = 5000;
export const TITLE_MAX_LENGTH = 100;
export const LINK_LABEL_MAX_LENGTH = 100;
export const LINK_MAX_LENGTH = 2000;

export const MIN_NUMBER_OF_ACTIVITY_CATEGORIES = 1;
export const MAX_NUMBER_OF_ACTIVITY_CATEGORIES = 2;

export const TAG_REGEXP = new RegExp(`#(?![0-9_]+)([a-zA-ZäöåÄÖÅ0-9_]{1,})`);

export const segmentationValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const TAGS = [
  'Alkoholfritt',
  'Brunch',
  'Fika',
  'Föreläsning',
  'Frukost',
  'Kravall',
  'Lunch',
  'Middag',
  'Näringsliv',
  'Nattklubb',
  'Övrigt',
  'Pluggstuga',
  'Pub',
  'Seminarie',
  'Spelkväll',
  'Spelning/Bandspelning',
  'Sport',
];
