import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const MembershipCharts = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      gap: 16px;
      display: flex;
      flex-direction: column;

      @media (min-width: ${theme.breakpoints.xs}px) {
        gap: 32px;
        flex-direction: row;
        flex-wrap: wrap;
      }
    `;
  }}
`;

export const Styled = { MembershipCharts };
