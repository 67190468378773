import { INITIAL_DATA_STATE } from '../store.constants';
import { AccountState } from './account.types';

export const initialAccountState: AccountState = {
  account: INITIAL_DATA_STATE,

  createAccountByInvitation: INITIAL_DATA_STATE,
  updateAccountByInvitation: INITIAL_DATA_STATE,

  updateAccount: INITIAL_DATA_STATE,

  updateWorkspace: INITIAL_DATA_STATE,
  switchWorkspace: INITIAL_DATA_STATE,
};
