import Joi from 'joi';

import {
  CreatePostCommentSchema,
  EditPostCommentSchema,
} from './post-comment.schema';

export const CreatePostCommentValidation = Joi.object({
  message: CreatePostCommentSchema.message,
});

export const EditPostCommentValidation = Joi.object({
  message: EditPostCommentSchema.message,
});
