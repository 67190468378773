interface DeletePeriodError {
  code: 0;
  message: string;
  metadata: { count: number };
}

export type DeletePeriodErrorResponse = string | DeletePeriodError | undefined;

export namespace MembershipPeriodErrors {
  export const customErrors = {
    periodMinPrice: 'number.min',
    periodMaxPrice: 'number.unsafe',
  };

  export const price = {
    'number.base': 'errors.price.empty',
    'number.min': 'errors.price.min',
    'number.unsafe': 'errors.price.max',
    'number.integer': 'errors.price.integer',
  };

  export const validTo = {
    'number.min': 'errors.valid-to.min',
  };

  export const saleEnd = {
    'number.min': 'errors.sale-end.min',
  };

  export const name = {
    'string.empty': 'errors.period-name.empty',
  };
}
