import { PropsWithChildren, createContext } from 'react';

import { Styled } from './table-row.styled';
import { TableRowContextType, TableRowProps } from './table-row.types';

export const TableRowContext = createContext<TableRowContextType>({});

function TableRowProvider(props: PropsWithChildren<TableRowContextType>) {
  const { children, ...rest } = props;

  return (
    <TableRowContext.Provider value={rest}>{children}</TableRowContext.Provider>
  );
}

export function TableRow(props: TableRowProps) {
  const { children, to, ...rest } = props;

  return (
    <TableRowProvider to={to}>
      <Styled.TableRow to={to} {...rest}>
        {children}
      </Styled.TableRow>
    </TableRowProvider>
  );
}
