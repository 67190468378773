import { ImageData } from '../../models';
import { Box } from '../box';
import { Image } from '../image';
import {
  ImageGallery,
  UseImageGallery,
  useImageGallery,
} from '../image-gallery';
import { Text } from '../text';

interface ImageGridProps {
  images: ImageData[];
  width?: number;
  height: number;

  gridSourceResolution?: keyof Pick<
    ImageData,
    | 'thumbnail1024'
    | 'thumbnail256'
    | 'thumbnail512'
    | 'thumbnail64'
    | 'original'
  >;
}

export interface ImageGridPropsContentProps
  extends ImageGridProps,
    UseImageGallery {}

function ImageGridContent(props: ImageGridPropsContentProps) {
  const { images, width, height, gridSourceResolution = 'thumbnail64' } = props;

  const openGalleryImage = (index: number) => () => {
    props.showImage(index);
  };

  if (images.length === 1) {
    return (
      <Image
        cursor="pointer"
        onClick={openGalleryImage(0)}
        r={4}
        objectFit="cover"
        height={height}
        width={width}
        src={images[0][gridSourceResolution].url}
      />
    );
  }

  if (images.length === 2) {
    return (
      <Box width={width} height={height} gap={4} flex>
        <Box width="100%" height="100%">
          <Image
            cursor="pointer"
            r={4}
            objectFit="cover"
            width="100%"
            height="100%"
            src={images[0][gridSourceResolution].url}
            onClick={openGalleryImage(0)}
          />
        </Box>
        <Box width="100%" height="100%">
          <Image
            cursor="pointer"
            r={4}
            objectFit="cover"
            width="100%"
            height="100%"
            src={images[1][gridSourceResolution].url}
            onClick={openGalleryImage(1)}
          />
        </Box>
      </Box>
    );
  }

  if (images.length === 3) {
    return (
      <Box width={width} height={height} gap={4} flex>
        <Box height="100%" width="100%">
          <Image
            cursor="pointer"
            r={4}
            objectFit="cover"
            width="100%"
            height="100%"
            src={images[0][gridSourceResolution].url}
            onClick={openGalleryImage(0)}
          />
        </Box>

        <Box flex flexDirection="column" gap={4} width="100%" height="100%">
          <Box width="100%" height="100%" overflow="hidden">
            <Image
              cursor="pointer"
              r={4}
              objectFit="cover"
              width="100%"
              height="100%"
              src={images[1][gridSourceResolution].url}
              onClick={openGalleryImage(1)}
            />
          </Box>

          <Box width="100%" height="100%" overflow="hidden">
            <Image
              cursor="pointer"
              r={4}
              objectFit="cover"
              width="100%"
              height="100%"
              src={images[2][gridSourceResolution].url}
              onClick={openGalleryImage(2)}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box width={width} height={height} gap={4} flex>
      <Box height="100%" width="100%">
        <Image
          cursor="pointer"
          r={4}
          objectFit="cover"
          width="100%"
          height="100%"
          src={images[0][gridSourceResolution].url}
          onClick={openGalleryImage(0)}
        />
      </Box>

      <Box flex flexDirection="column" gap={4} width="100%" height="100%">
        <Box width="100%" height="100%" overflow="hidden">
          <Image
            cursor="pointer"
            r={4}
            objectFit="cover"
            width="100%"
            height="100%"
            src={images[1][gridSourceResolution].url}
            onClick={openGalleryImage(1)}
          />
        </Box>

        <Box
          cursor="pointer"
          width="100%"
          height="100%"
          overflow="hidden"
          flex
          flexJustify="center"
          flexAlign="center"
          relative
          onClick={props.showGrid}
        >
          <Box r={4} zIndex={1} absolute inset={0} backgroundColor="backdrop" />

          <Image
            r={4}
            objectFit="cover"
            width="100%"
            height="100%"
            src={images[2][gridSourceResolution].url}
          />

          <Text
            r={4}
            zIndex={2}
            color="textLight"
            text={`+ ${images.length - 3}`}
            absolute
          />
        </Box>
      </Box>
    </Box>
  );
}

export function ImageGrid(props: ImageGridProps) {
  const imageGallery = useImageGallery();

  return (
    <>
      <ImageGallery images={props.images} {...imageGallery} />
      <ImageGridContent {...imageGallery} {...props} />
    </>
  );
}
