import { createEntityAdapter } from '@reduxjs/toolkit';

import { OfferCategory, PartialOffer } from '../../models';

export const partialOffersAdapter = createEntityAdapter<PartialOffer, string>({
  selectId: (partialOffer) => partialOffer.offerKey,
});

export const offerCategoriesAdapter = createEntityAdapter<
  OfferCategory,
  string
>({
  selectId: (offerCategory) => offerCategory.offerCategoryKey,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});
