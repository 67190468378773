import { translate } from '../../i18n';
import { Styled } from './image.styled';
import { ImageProps } from './image.types';

export function Image(props: ImageProps) {
  const { alt, altTx, ...rest } = props;

  const _alt = altTx ? translate(altTx) : alt;

  return <Styled.Image alt={_alt} {...rest} />;
}
