import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  addressesAdapter,
  departmentsAdapter,
  membershipTypesAdapter,
  usersAdapter,
} from './search.adapter';

export namespace SearchAddressesSelector {
  const selectSelf = (state: RootState) => state.search.addresses;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = addressesAdapter.getSelectors(
    (state: RootState) => state.search.addresses.data,
  );

  export const selectAll = selectors.selectAll;
}

export namespace SearchCoordinatesSelector {
  const selectSelf = (state: RootState) => state.search.coordinates;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  export const selectData = createSelector(selectSelf, (state) => state.data);
}

export namespace SearchMembershipTypesSelector {
  const selectSelf = (state: RootState) => state.search.membershipTypes;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = membershipTypesAdapter.getSelectors(
    (state: RootState) => state.search.membershipTypes.data,
  );

  export const selectAll = selectors.selectAll;
}

export namespace SearchUsersSelector {
  const selectSelf = (state: RootState) => state.search.users;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = usersAdapter.getSelectors(
    (state: RootState) => state.search.users.data,
  );

  export const selectAll = selectors.selectAll;
}

export namespace SearchDepartmentsSelector {
  const selectSelf = (state: RootState) => state.search.departments;

  export const selectStatus = createSelector(
    selectSelf,
    (state) => state.status,
  );

  const selectors = departmentsAdapter.getSelectors(
    (state: RootState) => state.search.departments.data,
  );

  export const selectAll = selectors.selectAll;
}
