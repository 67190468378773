import { OrbiLoader, SwitchableWorkspace } from '@orbiapp/components';
import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import {
  AccountSelector,
  AuthStateSelector,
  SwitchWorkspaceSelector,
  switchWorkspaceThunk,
  updateWorkspaceThunk,
  useDispatch,
  useSelector,
} from '../../../store';

export function SwitchWorkspace() {
  const switchWorkspaceError = useSelector(SwitchWorkspaceSelector.selectError);
  const isEmulating = useSelector(AuthStateSelector.selectIsEmulating);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const onlyHasCompanyWorkspace = useSelector(
    AccountSelector.selectOnlyHasCompanyWorkspace,
  );

  const { workspaceKey, type } = useParams<{
    workspaceKey: string;
    type: SwitchableWorkspace['type'];
  }>();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const switchToCompany = React.useCallback(
    async (companyKey: string, emulateUserKey?: string) => {
      if (!onlyHasCompanyWorkspace) {
        await dispatch(updateWorkspaceThunk({ type: 'company', companyKey }));
      }

      dispatch(
        switchWorkspaceThunk({
          type: 'company',
          companyKey,
          emulateUserKey,
        }),
      );
    },
    [dispatch, onlyHasCompanyWorkspace],
  );

  const switchToDepartment = React.useCallback(
    async (departmentKey: string, emulateUserKey?: string) => {
      await dispatch(
        updateWorkspaceThunk({ type: 'department', departmentKey }),
      );

      navigate('/activities', { replace: true });

      dispatch(
        switchWorkspaceThunk({
          type: 'department',
          departmentKey,
          emulateUserKey,
        }),
      );
    },
    [dispatch, navigate],
  );

  React.useEffect(() => {
    if (!workspaceKey || !type) return;

    const emulateUserKey = isEmulating && userKey ? userKey : undefined;

    switch (type) {
      case 'company':
        switchToCompany(workspaceKey, emulateUserKey);
        return;

      case 'department':
        switchToDepartment(workspaceKey, emulateUserKey);
        return;
    }
  }, [
    workspaceKey,
    type,
    isEmulating,
    userKey,
    switchToCompany,
    switchToDepartment,
  ]);

  if (!workspaceKey || !type || switchWorkspaceError) {
    return <Navigate to="/activities" replace />;
  }

  return <OrbiLoader />;
}
