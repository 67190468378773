import { createEntityAdapter } from '@reduxjs/toolkit';

import { PendingDepartmentInvitation, User } from '../../models';

export const usersAdapter = createEntityAdapter<User, string>({
  selectId: (user) => user.userKey,
});

export const pendingInvitationsAdapter = createEntityAdapter<
  PendingDepartmentInvitation,
  string
>({
  selectId: (invitation) => invitation.departmentInvitationKey,
});
