import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledDatePicker,
  ControlledInput,
  INPUT_X_PADDING,
  INPUT_Y_PADDING,
  Input,
  Link,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Switch,
  Text,
  flattenFieldErrorsObject,
  formatDate,
  translate,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  CreateMembershipPeriodValidation,
  MembershipPeriodForm,
  PERIOD_NAME_MAX_LENGTH,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { OrbiPaySettingsSelector, useSelector } from '../../../../../store';
import { getCurrencyLabelText } from '../../../../../utils';

function ValidFromFormField(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { watch } = useFormContext<MembershipPeriodForm>();

  const lifetime = watch('lifetime');

  if (lifetime) {
    return (
      <Input
        disabled
        labelTx="label.memberships.update-period-modal.valid-from"
        defaultValue={translate(
          'label.memberships.update-period-modal.lifetime',
        )}
      />
    );
  }

  return (
    <ControlledDatePicker
      name="validFrom"
      labelTx="label.memberships.update-period-modal.valid-from"
      disabled={isLoading}
      min={formatDate(Date.now(), 'YYYY-MM-DDTHH:mm')}
      type="datetime-local"
    />
  );
}

function ValidToFormField(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { watch } = useFormContext<MembershipPeriodForm>();

  const [validFrom, lifetime] = watch(['validFrom', 'lifetime']);

  if (lifetime) {
    return (
      <Input
        disabled
        labelTx="label.memberships.update-period-modal.valid-to"
        defaultValue={translate(
          'label.memberships.update-period-modal.lifetime',
        )}
      />
    );
  }

  return (
    <ControlledDatePicker
      disabled={isLoading}
      labelTx="label.memberships.update-period-modal.valid-to"
      min={formatDate(validFrom ?? 0, 'YYYY-MM-DDTHH:mm')}
      name="validTo"
      type="datetime-local"
    />
  );
}

function SalesStartFormField(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  return (
    <ControlledDatePicker
      disabled={isLoading}
      labelTx="label.memberships.update-period-modal.sales-start"
      min={formatDate(Date.now(), 'YYYY-MM-DDTHH:mm')}
      name="saleStart"
      type="datetime-local"
    />
  );
}

function SaleEndFormField(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { watch } = useFormContext<MembershipPeriodForm>();

  const saleStart = watch('saleStart');

  return (
    <ControlledDatePicker
      name="saleEnd"
      labelTx="label.memberships.update-period-modal.sales-end"
      disabled={isLoading}
      min={formatDate(saleStart, 'YYYY-MM-DDTHH:mm')}
      type="datetime-local"
    />
  );
}

function ToggleLifetime(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { clearErrors, watch, setValue } =
    useFormContext<MembershipPeriodForm>();

  const lifetime = watch('lifetime');

  const toggleLifetime = () => {
    setValue('validFrom', lifetime ? Date.now() : null);

    setValue('validTo', lifetime ? Date.now() : null);

    setValue('lifetime', !lifetime);

    clearErrors(['validFrom', 'validTo']);
  };

  return (
    <Switch
      checked={lifetime}
      disabled={isLoading}
      onClick={toggleLifetime}
      tx="label.memberships.update-period-modal.lifetime-membership"
    />
  );
}

function PeriodFormPriceInput(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );
  const orbiPaySettingsStatus = useSelector(
    OrbiPaySettingsSelector.selectStatus,
  );

  return (
    <Box>
      <ControlledInput
        type="number"
        disabled={!allowPaidTickets || isLoading}
        label={getCurrencyLabelText(
          'label.memberships.update-period-modal.price',
          currency,
        )}
        name="price"
        required
      />

      {orbiPaySettingsStatus !== 'pending' && !allowPaidTickets && (
        <Box
          flex
          flexDirection="column"
          gap={3}
          mt={5}
          px={INPUT_X_PADDING}
          py={INPUT_Y_PADDING}
        >
          <Text
            color="errorLabel"
            variant="bodyMd"
            tx="label.memberships.update-period-modal.disabled-message"
          />
          <Link tx="link.general.fill-out-missing-info" to="/orbi-pay" />
        </Box>
      )}
    </Box>
  );
}

export function EditPeriodForm(props: {
  closeModal: () => void;
  defaultValues: MembershipPeriodForm;
  isLoading?: boolean;
  onSubmitted: (data: MembershipPeriodForm) => void;
}) {
  const { closeModal, defaultValues, isLoading, onSubmitted } = props;

  const formMethods = useForm<MembershipPeriodForm>({
    resolver: joiResolver(CreateMembershipPeriodValidation),
    defaultValues,
  });

  const savePeriod = formMethods.handleSubmit(
    (data) => {
      onSubmitted(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('updateMembershipPeriod Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.memberships.view-membership.periods.edit-title" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <ToggleLifetime isLoading={isLoading} />

          <ControlledInput
            disabled={isLoading}
            labelTx="label.memberships.update-period-modal.name"
            maxLength={PERIOD_NAME_MAX_LENGTH}
            name="name"
            required
          />

          <Box flexWrap flex gap={16}>
            <Box width="fit-content" flexGrow={1}>
              <ValidFromFormField isLoading={isLoading} />
            </Box>
            <Box width="fit-content" flexGrow={1}>
              <ValidToFormField isLoading={isLoading} />
            </Box>
          </Box>

          <Box flexWrap flex gap={16}>
            <Box width="fit-content" flexGrow={1}>
              <SalesStartFormField isLoading={isLoading} />
            </Box>
            <Box width="fit-content" flexGrow={1}>
              <SaleEndFormField isLoading={isLoading} />
            </Box>
          </Box>

          <PeriodFormPriceInput isLoading={isLoading} />
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button onClick={closeModal} tx="button.cancel" variant="tertiary" />
          <Button
            isLoading={isLoading}
            onClick={savePeriod}
            tx="button.save"
            variant="secondary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </FormProvider>
  );
}

export function PeriodForm(props: {
  defaultValues: MembershipPeriodForm;
  isLoading?: boolean;
  onSubmitted: (data: MembershipPeriodForm) => void;
  titleTx: TxString;
}) {
  const { defaultValues, isLoading, onSubmitted, titleTx } = props;

  const formMethods = useForm<MembershipPeriodForm>({
    resolver: joiResolver(CreateMembershipPeriodValidation),
    defaultValues,
  });

  const savePeriod = formMethods.handleSubmit(onSubmitted, (err) => {
    Logger.warning('createMembershipPeriod Validation', {
      err: flattenFieldErrorsObject(err),
    });
  });

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Text variant="bodyLgBold" tx={titleTx} />

        <ToggleLifetime isLoading={isLoading} />

        <ControlledInput
          disabled={isLoading}
          labelTx="label.memberships.update-period-modal.name"
          maxLength={PERIOD_NAME_MAX_LENGTH}
          name="name"
          required
        />

        <Box flexWrap flex gap={16}>
          <Box width="fit-content" flexGrow={1}>
            <ValidFromFormField isLoading={isLoading} />
          </Box>

          <Box width="fit-content" flexGrow={1}>
            <ValidToFormField isLoading={isLoading} />
          </Box>
        </Box>

        <Box flexWrap flex gap={16}>
          <Box width="fit-content" flexGrow={1}>
            <SalesStartFormField isLoading={isLoading} />
          </Box>

          <Box width="fit-content" flexGrow={1}>
            <SaleEndFormField isLoading={isLoading} />
          </Box>
        </Box>

        <PeriodFormPriceInput isLoading={isLoading} />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={savePeriod}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}
