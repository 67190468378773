const primary = {
  100: '#B75109',
  200: '#D55E0B',
  300: '#F36C0D',
  400: '#F57E2B',
  500: '#F6914A',
  600: '#F8A368',
  700: '#F9B586',
  800: '#FBC8A4',
  900: '#FCDAC3',
  1000: '#FEEDE1',
};

const secondary = {
  100: '#9F58CC',
  200: '#B47ED8',
  300: '#CAA3E3',
  400: '#D1AFE6',
  500: '#D7BAEA',
  600: '#DEC5EE',
  700: '#E4D1F1',
  800: '#EBDDF4',
  900: '#F2E8F8',
  1000: '#F8F3FC',
};

const tertiary = {
  100: '#43C0DC',
  200: '#6CCDE3',
  300: '#94DBEB',
  400: '#A1E0ED',
  500: '#AFE4F0',
  600: '#BCE9F3',
  700: '#CAEDF5',
  800: '#D7F2F8',
  900: '#E4F6FA',
  1000: '#F2FBFD',
};

const neutral = {
  100: '#0B0B0D',
  200: '#1A202C',
  300: '#2D3748',
  400: '#4A5568',
  500: '#718096',
  600: '#A0AEC0',
  700: '#CBD5E0',
  800: '#E2E8F0',
  900: '#EDF2F7',
  1000: '#F7FAFC',
  white: '#ffffff',
};

const alert = {
  100: '#63171B',
  200: '#C53030',
  300: '#FC8181',
  400: '#FFF5F5',
};

const information = {
  100: '#1A365D',
  200: '#2B6CB0',
  300: '#63B3ED',
  400: '#EBF8FF',
};

const success = {
  100: '#1C4532',
  200: '#25855A',
  300: '#68D391',
  400: '#F0FFF4',
};

const warning = {
  100: '#5F370E',
  200: '#B7791F',
  300: '#F6E05E',
  400: '#FFFFF0',
};

const dataVisualisation = {
  1: '#FFA600',
  2: '#FFCA66',
  3: '#FF7C43',
  4: '#FFB08E',
  5: '#F95D6A',
  6: '#FB9EA6',
  7: '#D45087',
  8: '#E596B7',
  9: '#A05195',
  10: '#C697BF',
  11: '#665191',
  12: '#A397BD',
  13: '#2F4B7C',
  14: '#8293B0',
  15: '#003F5C',
  16: '#668C9D',
};

export const palette = {
  alert,
  dataVisualisation,
  information,
  neutral,
  primary,
  secondary,
  success,
  tertiary,
  transparent: 'rgba(0, 0, 0, 0)',
  warning,
};
