import { fromScu } from '@orbiapp/components';

import {
  ActivityCheckboxAnswer,
  ActivityFreetextAnswer,
  ActivityMultichoiceAnswer,
  RequestedInfoAnswer,
  RequestedInfoType,
  TicketDetails,
  TicketTransferHistoryItem,
} from '../../../../../../../models';
import {
  CheckboxAnswerDto,
  FreetextAnswerDto,
  MultichoiceAnswerDto,
  TicketDetailsDto,
  TicketTransferHistoryDto,
} from './details.dto';

const freetextAnswersMapper = (
  freetextAnswerDto: FreetextAnswerDto,
): ActivityFreetextAnswer => ({
  answer: freetextAnswerDto.answer,
  question: freetextAnswerDto.question,
  type: RequestedInfoType.FreeText,
});

const multichoiceAnswersMapper = (
  multichoiceAnswerDto: MultichoiceAnswerDto,
): ActivityMultichoiceAnswer => ({
  label: multichoiceAnswerDto.label,
  priceIncrement: fromScu(multichoiceAnswerDto.priceIncrement),
  question: multichoiceAnswerDto.question,
  type: RequestedInfoType.MultiChoice,
});

const checkboxMapper = (
  checkboxAnswerDto: CheckboxAnswerDto,
): ActivityCheckboxAnswer => ({
  isChecked: checkboxAnswerDto.isChecked,
  question: checkboxAnswerDto.question,
  type: RequestedInfoType.Checkbox,
});

const ticketTransferHistoryItemMapper = (
  ticketTransferHistoryItemDto: TicketTransferHistoryDto,
): TicketTransferHistoryItem => ({
  email: ticketTransferHistoryItemDto.email,
  fullName: ticketTransferHistoryItemDto.fullName,
  transferredAt: ticketTransferHistoryItemDto.transferredAt,
  transferType: ticketTransferHistoryItemDto.transferType,
});

export const ticketDetailsMapper = (
  ticketDetailsDto: TicketDetailsDto,
): TicketDetails => {
  const requestedInfoAnswers: RequestedInfoAnswer[] = [];

  ticketDetailsDto.checkboxAnswers?.forEach((checkboxAnswer) => {
    requestedInfoAnswers.push(checkboxMapper(checkboxAnswer));
  });

  ticketDetailsDto.freetextAnswers?.forEach((freetextAnswer) => {
    requestedInfoAnswers.push(freetextAnswersMapper(freetextAnswer));
  });

  ticketDetailsDto.multichoiceAnswers?.forEach((multichoiceAnswer) => {
    requestedInfoAnswers.push(multichoiceAnswersMapper(multichoiceAnswer));
  });

  let usedDiscount: TicketDetails['usedDiscount'] = null;

  if (ticketDetailsDto.usedDiscount?.discountSource === 'membership') {
    usedDiscount = {
      discount: fromScu(ticketDetailsDto.usedDiscount.discount),
      discountSource: 'membership',
      membershipTypeName: ticketDetailsDto.usedDiscount.membershipTypeName,
    };
  }

  if (ticketDetailsDto.usedDiscount?.discountSource === 'code') {
    usedDiscount = {
      discount: fromScu(ticketDetailsDto.usedDiscount.discount),
      discountCode: ticketDetailsDto.usedDiscount.discountCode,
      discountSource: 'code',
    };
  }

  return {
    usedDiscount,
    ticketTransferHistory:
      ticketDetailsDto.ticketTransferHistory?.map(
        ticketTransferHistoryItemMapper,
      ) ?? null,
    paidAmount: fromScu(ticketDetailsDto.paidAmount),
    requestedInfoAnswers:
      requestedInfoAnswers.length !== 0 ? requestedInfoAnswers : null,
  };
};
