import React from 'react';
import { useController } from 'react-hook-form';

import {
  ArrowsContext,
  ArrowsProvider,
  useCombinedRefs,
  useOnClickOutside,
} from '../../helpers';
import { translate } from '../../i18n';
import { isNumber, isTxString } from '../../utils';
import { Box } from '../box';
import { Icon } from '../icon';
import { InputBox } from '../input-box';
import { InputElementBox } from '../input-element-box/input-element-box';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import { Menu, MenuItem, useMenu } from '../menu';
import { Text } from '../text';
import { SELECT_X_PADDING } from './select.constants';
import {
  ControlledSelectProps,
  OptionProps,
  SelectContextType,
  SelectForwardRef,
} from './select.types';

const SelectContext = React.createContext<SelectContextType>({
  onSelect: () => {},
  value: '',
});

const SelectProvider = SelectContext.Provider;

function Option(props: OptionProps) {
  const {
    notPickable,
    icon,
    subtitle,
    subtitleTx,
    subtitleTxArgs,
    text,
    tx,
    txArgs,
    value,
    index,
  } = props;

  const selectContext = React.useContext(SelectContext);
  const activeIndex = React.useContext(ArrowsContext);

  const isSelected = isNumber(index) && index === activeIndex;

  const menuItemRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (
      isSelected &&
      menuItemRef.current &&
      'scrollIntoView' in menuItemRef.current
    ) {
      menuItemRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isSelected]);

  if (!selectContext) {
    throw new Error('Option must be used within a Select');
  }

  const handleMenuItemClick = () => selectContext.onSelect(value);

  return (
    <MenuItem
      notPickable={notPickable}
      flex
      flexAlign="center"
      gap={8}
      isSelected={selectContext.value === value || isSelected}
      onClick={handleMenuItemClick}
      width="100%"
      ref={menuItemRef}
    >
      {icon && <Icon color="selectIcon" name={icon} />}

      <Box width="100%" flex flexDirection="column" overflow="hidden">
        <Text
          text={text}
          tx={tx}
          txArgs={txArgs}
          variant="bodyMd"
          color="selectLabel"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        />

        {(!!subtitle || !!subtitleTx) && (
          <Text
            text={subtitle}
            tx={subtitleTx}
            txArgs={subtitleTxArgs}
            variant="bodySm"
            color="selectSubtitle"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth="100%"
          />
        )}
      </Box>
    </MenuItem>
  );
}

export function ControlledSelect(props: ControlledSelectProps) {
  const {
    control,
    defaultValue,
    deps,
    disabled,
    name,
    onBlur,
    onChange,
    ...rest
  } = props;

  const controller = useController({
    control,
    defaultValue,
    name,
    rules: { deps },
  });

  const onSelectHandler: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    controller.field.onChange(e);
    onChange?.(e);
  };

  const onBlurHandler = () => {
    controller.field.onBlur();
    onBlur?.();
  };

  const errorTx = isTxString(controller.fieldState.error?.message)
    ? controller.fieldState.error?.message
    : undefined;

  return (
    <Select
      disabled={disabled}
      onBlur={onBlurHandler}
      onChange={onSelectHandler}
      ref={controller.field.ref}
      value={controller.field.value}
      errorTx={errorTx}
      hasError={!!errorTx}
      {...rest}
    />
  );
}

function renderNativeOption(option: OptionProps) {
  return <option key={option.value} value={option.value} />;
}

function renderOption(option: OptionProps, index: number) {
  return <Option key={option.value} index={index} {...option} />;
}

export const Select: SelectForwardRef = React.forwardRef(
  (props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const {
      defaultIsOpen,
      disabled = false,
      error,
      errorTx,
      errorTxArgs,
      getOverrides,
      helperText,
      helperTx,
      helperTxArgs,
      hideInputHelper,
      invertMenu,
      label,
      labelTx,
      labelTxArgs,
      trailingElements,
      leadingElements,
      maxWidth,
      onBlur,
      onChange,
      onClose,
      options,
      placeholder,
      placeholderTx,
      placeholderTxArgs,
      value,
      width,
      keepOpenOnSelect,
      ...rest
    } = props;

    const menuState = useMenu({
      defaultIsOpen,
      onClickOutside: onClose,
    });

    const handleClose = React.useCallback(() => {
      menuState.closeMenu();
      onClose?.();
    }, [menuState, onClose]);

    const selectRef = React.useRef<HTMLSelectElement>(null);
    const combinedRefs = useCombinedRefs(ref, selectRef);
    const labelRef = React.useRef<HTMLLabelElement>(null);
    const selectInputRef = React.useRef<HTMLDivElement>(null);

    const handleSelect = (value: string) => {
      if (selectRef.current) {
        selectRef.current.value = value;

        const index = options.findIndex((option) => option.value === value);

        selectRef.current.selectedIndex = index;
        selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      }

      if (!keepOpenOnSelect) {
        handleClose();
      }
    };

    const handleToggleMenuOption = (index: number) => {
      handleSelect(options[index].value);
    };

    const handleMenuClose = () => {
      onBlur?.();
      handleClose();
    };

    useOnClickOutside({
      handler: handleMenuClose,
      isActive: menuState.isOpen,
      ref: menuState.menuRef as React.MutableRefObject<HTMLDivElement>,
    });

    const selectedOption = options.find((option) => option.value === value);

    const _placeholder = placeholderTx
      ? translate(placeholderTx, placeholderTxArgs ?? {}).toString()
      : placeholder;

    const overrideOptions = getOverrides?.(selectedOption);

    const textValue = overrideOptions?.valueTx
      ? translate(overrideOptions.valueTx, overrideOptions.valueTxArgs ?? {})
      : overrideOptions?.value
      ? overrideOptions.value
      : selectedOption?.text || value?.toString() || _placeholder;

    const hasError = props.hasError || !!error || !!errorTx;

    React.useEffect(() => {
      if (disabled) {
        handleClose();
      }
    }, [disabled, handleClose]);

    return (
      <Box maxWidth={maxWidth} width={width} relative>
        <InputBox
          disabled={disabled}
          hasError={hasError}
          floatLabel={!!textValue || menuState.isOpen}
          relative
          isFocused={menuState.isOpen}
          width={width}
          maxWidth={maxWidth}
          ref={menuState.menuRef}
          {...rest}
        >
          <InputElementBox items={leadingElements} />

          <Box overflow="hidden" relative flexGrow={1}>
            <InputLabel
              disabled={disabled}
              hasError={hasError}
              ref={labelRef}
              text={overrideOptions?.label ?? label}
              tx={overrideOptions?.labelTx ?? labelTx}
              txArgs={overrideOptions?.labelTxArgs ?? labelTxArgs}
              color={menuState.isOpen ? 'inputLabelFocus' : undefined}
            />

            <Box
              flex
              flexAlign="center"
              flexJustify="between"
              cursor="pointer"
              pointerEvents={disabled ? 'none' : 'auto'}
              onClick={menuState.isOpen ? handleClose : menuState.openMenu}
              ref={selectInputRef}
              height="100%"
              flexGrow={1}
              mb={-16}
            >
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                color={disabled ? 'inputLabelDisabled' : 'inputText'}
                variant="bodySm"
                tx={selectedOption?.tx}
                text={textValue}
                mb={-16}
              />
            </Box>
          </Box>

          <SelectProvider value={{ onSelect: handleSelect, value }}>
            <ArrowsProvider
              minIndex={0}
              maxIndex={options.length - 1}
              onToggle={handleToggleMenuOption}
              disabled={!menuState.isOpen || disabled}
            >
              <Menu
                absolute
                bottom={invertMenu ? '100%' : 'unset'}
                width="100%"
                isOpen={menuState.isOpen}
                left={0}
                maxHeight={props.menuMaxHeight ?? 300}
                mb={invertMenu ? 10 : 'unset'}
                mt={invertMenu ? 'unset' : 10}
                top={invertMenu ? 'unset' : '100%'}
                height={props.menuHeight}
              >
                {options.map(renderOption)}
              </Menu>
            </ArrowsProvider>
          </SelectProvider>

          <select hidden onChange={onChange} ref={combinedRefs} value={value}>
            {options.map(renderNativeOption)}
          </select>

          <InputElementBox
            items={[
              ...(trailingElements ?? []),
              {
                type: 'button',
                icon: menuState.isOpen ? 'chevron-up' : 'chevron-down',
                onClick: disabled ? undefined : menuState.toggleMenu,
                disabled,
              },
            ]}
          />
        </InputBox>

        <InputHelper
          disabled={disabled}
          hasError={hasError}
          helperText={hideInputHelper ? undefined : error || helperText}
          helperTx={hideInputHelper ? undefined : errorTx || helperTx}
          helperTxArgs={errorTxArgs || helperTxArgs}
          pl={SELECT_X_PADDING}
        />
      </Box>
    );
  },
);
