import Joi from 'joi';

import { MinTicketCounts, TicketType } from './ticket-types.model';
import { TicketTypeSchema } from './ticket-types.schema';

/**
 * @see will be used for ticket types in the TicketFormSidebar
 */
export const CreateTicketTypeFormValidation = (options: {
  activityEndDate: number;
}) =>
  Joi.object<TicketType>({
    description: TicketTypeSchema.description,
    discountCodes: TicketTypeSchema.discountCodes,
    endDate: TicketTypeSchema.endDateTicketForm(options.activityEndDate),
    isNew: TicketTypeSchema.isNew,
    isTransferable: TicketTypeSchema.isTransferable,
    maxTicketCountPerUser: TicketTypeSchema.maxTicketCountPerUser,
    membershipTypeDiscounts: TicketTypeSchema.membershipTypeDiscounts,
    name: TicketTypeSchema.ticketTypeName,
    price: TicketTypeSchema.price,
    requiredMembershipTypes: TicketTypeSchema.requiredMembershipTypes,
    startDate: TicketTypeSchema.startDate,
    ticketCount: TicketTypeSchema.ticketCount,
    ticketTypeKey: TicketTypeSchema.ticketTypeKey,
  });

/**
 * @see will be used for ticket types in the TicketFormSidebar
 */
export const UpdateTicketTypeFormValidation = (options: {
  minTicketCounts: MinTicketCounts;
  activityEndDate: number;
}) =>
  Joi.object<TicketType>({
    description: TicketTypeSchema.description,
    discountCodes: TicketTypeSchema.discountCodes,
    endDate: TicketTypeSchema.endDateTicketForm(options.activityEndDate),
    isNew: TicketTypeSchema.isNew,
    isTransferable: TicketTypeSchema.isTransferable,
    maxTicketCountPerUser: TicketTypeSchema.maxTicketCountPerUser,
    membershipTypeDiscounts: TicketTypeSchema.membershipTypeDiscounts,
    name: TicketTypeSchema.ticketTypeName,
    price: TicketTypeSchema.price,
    requiredMembershipTypes: TicketTypeSchema.requiredMembershipTypes,
    startDate: TicketTypeSchema.updateStartDate,
    ticketCount: TicketTypeSchema.updateTicketCount(options.minTicketCounts),
    ticketTypeKey: TicketTypeSchema.ticketTypeKey,
  });

/**
 * @see will be used for ticket types in the ActivityObject
 */
export const CreateTicketTypeValidation = Joi.object<TicketType>({
  description: TicketTypeSchema.description,
  discountCodes: TicketTypeSchema.discountCodes,
  endDate: TicketTypeSchema.endDate,
  isNew: TicketTypeSchema.isNew,
  isTransferable: TicketTypeSchema.isTransferable,
  maxTicketCountPerUser: TicketTypeSchema.maxTicketCountPerUser,
  membershipTypeDiscounts: TicketTypeSchema.membershipTypeDiscounts,
  name: TicketTypeSchema.ticketTypeName,
  price: TicketTypeSchema.price,
  requiredMembershipTypes: TicketTypeSchema.requiredMembershipTypes,
  startDate: TicketTypeSchema.startDate,
  ticketCount: TicketTypeSchema.ticketCount,
  ticketTypeKey: TicketTypeSchema.ticketTypeKey,
});

/**
 * @see will be used for ticket types in the ActivityObject
 */
export const UpdateTicketTypeValidation = (options: {
  minTicketCounts: MinTicketCounts;
}) =>
  Joi.object<TicketType>({
    description: TicketTypeSchema.description,
    discountCodes: TicketTypeSchema.discountCodes,
    endDate: TicketTypeSchema.endDate,
    isNew: TicketTypeSchema.isNew,
    isTransferable: TicketTypeSchema.isTransferable,
    maxTicketCountPerUser: TicketTypeSchema.maxTicketCountPerUser,
    membershipTypeDiscounts: TicketTypeSchema.membershipTypeDiscounts,
    name: TicketTypeSchema.ticketTypeName,
    price: TicketTypeSchema.price,
    requiredMembershipTypes: TicketTypeSchema.requiredMembershipTypes,
    startDate: TicketTypeSchema.updateStartDate,
    ticketCount: TicketTypeSchema.updateTicketCount(options.minTicketCounts),
    ticketTypeKey: TicketTypeSchema.ticketTypeKey,
  });
