import Joi from 'joi';

import { ANSWER_MAX_LENGTH } from './question.constants';
import { QuestionErrors } from './question.errors';

export namespace QuestionSchema {
  export const answer = Joi.string()
    .max(ANSWER_MAX_LENGTH)
    .required()
    .messages(QuestionErrors.answer);
}
