import React from 'react';

import { ReactionType } from '../../models';
import { useInfinityScroll } from '../../utils';
import { Box } from '../box';
import { Icon, IconName } from '../icon';
import { Modal, ModalBodyContainer, ModalContentContainer } from '../modal';
import { ReactionsRow, ReactionsRowProps } from '../reactions-row';
import { Spinner } from '../spinner';
import { Tabs } from '../tabs';
import { Text, TxArgs } from '../text';

export interface ReactionsModalHeaderItemProps {
  tx?: TxString;
  text?: string;
  txArgs?: TxArgs;
  icon?: IconName;

  reactionType?: ReactionType;
}

interface ReactionsModalProps {
  isOpen: boolean;
  onClose: () => void;

  headerItems: ReactionsModalHeaderItemProps[];
  items: ReactionsRowProps[];
  onTabChange: (reactionType?: ReactionType) => void;
  onReachedEnd: () => void;

  isLoading?: boolean;
}

function renderHeaderItem(props: ReactionsModalHeaderItemProps, index: number) {
  return (
    <Tabs.HeaderItem
      flex
      flexDirection="column"
      gap={8}
      width={125}
      key={index}
    >
      {(props.tx || props.text) && (
        <Text text={props.text} tx={props.tx} txArgs={props.txArgs} />
      )}

      {props.icon && <Icon name={props.icon} />}
    </Tabs.HeaderItem>
  );
}

function renderReactionsRow(props: ReactionsRowProps, index: number) {
  return <ReactionsRow key={index} {...props} />;
}

export function ReactionsModal(props: ReactionsModalProps) {
  const { headerItems, onTabChange, onReachedEnd } = props;

  const handleTabChange = React.useCallback(
    (index: number) => {
      onTabChange(headerItems[index].reactionType);
    },
    [onTabChange, headerItems],
  );

  const handleListScroll = useInfinityScroll(onReachedEnd);

  return (
    <Modal
      hideCloseButton
      isOpen={props.isOpen}
      onClose={props.onClose}
      width={550}
      zIndex={13500}
    >
      <ModalContentContainer>
        <ModalBodyContainer
          onScroll={handleListScroll}
          overflow="auto"
          px={0}
          py={0}
          height={500}
        >
          <Tabs onTabChange={handleTabChange}>
            <Tabs.Header zIndex={30} sticky top={0} px={16} pt={40}>
              {headerItems.map(renderHeaderItem)}
            </Tabs.Header>

            <Tabs.Body>{props.items.map(renderReactionsRow)}</Tabs.Body>

            {props.isLoading && (
              <Box
                mt={props.items.length === 0 ? 64 : 0}
                flex
                flexJustify="center"
                p={16}
              >
                <Spinner />
              </Box>
            )}
          </Tabs>
        </ModalBodyContainer>
      </ModalContentContainer>
    </Modal>
  );
}
