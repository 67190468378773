import Joi from 'joi';

import { EmailSchema } from '../email';
import { CoverImageValidation, LogoValidation } from '../file';
import { PhoneSchema } from '../phone';
import {
  DEPARTMENT_ABOUT_MAX_LENGTH,
  DEPARTMENT_LINK_MAX_LENGTH,
  DEPARTMENT_NAME_MAX_LENGTH,
} from './department.constants';
import { DepartmentErrors } from './department.errors';

export namespace DepartmentSchema {
  export const about = Joi.string()
    .allow(null, '')
    .max(DEPARTMENT_ABOUT_MAX_LENGTH)
    .required();

  export const coverImage = CoverImageValidation.required().messages(
    DepartmentErrors.coverImage,
  );

  export const facebookUrl = Joi.string()
    .uri()
    .max(DEPARTMENT_LINK_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(DepartmentErrors.facebookUrl);

  export const instagramUrl = Joi.string()
    .uri()
    .max(DEPARTMENT_LINK_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(DepartmentErrors.instagramUrl);

  export const twitterUrl = Joi.string()
    .uri()
    .max(DEPARTMENT_LINK_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(DepartmentErrors.twitterUrl);

  export const youtubeUrl = Joi.string()
    .uri()
    .max(DEPARTMENT_LINK_MAX_LENGTH)
    .allow(null, '')
    .required()
    .messages(DepartmentErrors.youtubeUrl);

  export const websiteUrl = Joi.string()
    .uri()
    .max(DEPARTMENT_LINK_MAX_LENGTH)
    .allow(null, '')
    .messages(DepartmentErrors.websiteUrl);

  export const logo = LogoValidation.required().messages(DepartmentErrors.logo);

  export const name = Joi.string()
    .max(DEPARTMENT_NAME_MAX_LENGTH)
    .required()
    .messages(DepartmentErrors.name);

  export const email = EmailSchema.required();

  export const phone = PhoneSchema.allow(null, '').required();
}
