import React from 'react';

import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './avatar.styled';
import { AvatarProps, AvatarVariant } from './avatar.types';
import fallbackBackground from './background.png';

function hashCode(string: string) {
  let hash = 0;

  if (string.length === 0) {
    return hash;
  }

  for (let i = 0; i < string.length; i++) {
    const chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}

export function getAvatarVariantFromString(string: string): AvatarVariant {
  return (hashCode(string.substring(string.length - 4, string.length - 1)) %
    4) as AvatarProps['variant'] as AvatarVariant;
}

function _Avatar(props: AvatarProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { children, fallbackLetter, icon, src, variant, alt, ...rest } = props;

  const [imageError, setImageError] = React.useState(false);

  const onLoadingError = () => setImageError(true);

  if (children) {
    return (
      <Styled.AvatarBox ref={ref} {...rest}>
        {children}
      </Styled.AvatarBox>
    );
  }

  if (icon) {
    return (
      <Styled.AvatarBox ref={ref} {...rest}>
        <Icon name={icon} />
      </Styled.AvatarBox>
    );
  }

  if (variant === undefined && !src && !fallbackLetter) {
    return (
      <Styled.AvatarBox fallbackSrc={fallbackBackground} ref={ref} {...rest} />
    );
  }

  if (!src || imageError) {
    return (
      <Styled.AvatarBox variant={variant} ref={ref} {...rest}>
        <Text variant="bodyMdBold">{fallbackLetter?.slice(0, 1)}</Text>
      </Styled.AvatarBox>
    );
  }

  return (
    <Styled.AvatarBox src={src} ref={ref} {...rest}>
      <Styled.Avatar alt={alt} onError={onLoadingError} src={src} />
    </Styled.AvatarBox>
  );
}
export const Avatar = React.forwardRef(_Avatar);
