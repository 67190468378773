import { CreateMembershipTypeForm } from '../../../../models';

export const CREATE_MEMBERSHIP_DEFAULT_VALUES: CreateMembershipTypeForm = {
  backgroundImage: null,
  contactDetails: {
    email: '',
    name: '',
    phone: '',
  },
  createMembershipQuestions: null,
  description: '',
  enabled: false,
  logo: null,
  name: '',
  periods: null,
  requiredMembershipTypes: null,
  type: 'standard',
};
