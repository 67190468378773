import { Styled } from './icon.styled';
import { IconProps } from './icon.types';
import { icons } from './icons';

export function Icon(props: IconProps) {
  const { size = 24, name, color, ...rest } = props;

  const svg = icons[name];

  return (
    <Styled.Icon color={color} size={size} {...rest}>
      <span
        dangerouslySetInnerHTML={{ __html: svg ?? '' }}
        style={{ width: size, height: size, display: 'inline-flex' }}
      ></span>
    </Styled.Icon>
  );
}
