import { Box, Spinner } from '../../components';
import { ContentContainer, InnerContentContainer } from '../content-container';
import { InnerPageContainer, PageContainer } from '../page-container';

export function LoadingContainer() {
  return (
    <PageContainer>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <Box flex flexJustify="center" flexAlign="center" height="100%">
              <Spinner />
            </Box>
          </InnerContentContainer>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
