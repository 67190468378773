import { FieldValues, FormProvider } from 'react-hook-form';

import { Styled } from './form.styled';
import { FormProps } from './form.types';

export function Form<T extends FieldValues, K>(props: FormProps<T, K>) {
  const { children, formMethods, ...rest } = props;

  const formContent = (
    <Styled.Form method="POST" {...rest}>
      {children}
    </Styled.Form>
  );

  if (formMethods) {
    return <FormProvider {...formMethods}>{formContent}</FormProvider>;
  }

  return formContent;
}
