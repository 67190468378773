import React from 'react';

import { theme } from '../../theme';
import { Box, BoxProps } from '../box';

const TabsContext = React.createContext({
  activeIndex: 0,
  setActiveIndex: (index: number) => {},

  indicatorStyle: {},
  setIndicatorStyle: (style: React.CSSProperties) => {},

  tabHeaderRefs: {} as React.MutableRefObject<(HTMLDivElement | null)[]>,
});

interface BoxPropsWithIndex extends BoxProps {
  index: number;
}

interface TabContainerProps extends BoxProps {
  children: React.ReactElement[] | React.ReactElement;
}

function TabsIndicator() {
  const { indicatorStyle } = React.useContext(TabsContext);

  return (
    <Box
      absolute
      height={4}
      bottom={0}
      backgroundColor="tabIndicator"
      style={{
        transition: `all ${theme.transitions.default}`,
        ...indicatorStyle,
      }}
    />
  );
}

function TabsHeaderItem(props: BoxProps) {
  const { children, index, ...rest } = props as BoxPropsWithIndex;

  const { setActiveIndex, tabHeaderRefs } = React.useContext(TabsContext);

  const handleClick = () => {
    setActiveIndex(index);
  };

  const setRef = (el: HTMLDivElement | null) => {
    tabHeaderRefs.current[index] = el;
  };

  return (
    <Box
      key={index}
      ref={setRef}
      cursor="pointer"
      p={8}
      onClick={handleClick}
      flex
      flexAlign="center"
      flexJustify="center"
      {...rest}
    >
      {children}
    </Box>
  );
}

function TabsHeader(props: TabContainerProps) {
  const { children, ...rest } = props;

  return (
    <Box backgroundColor="backgroundPrimary" flex relative {...rest}>
      {Array.isArray(children)
        ? children.map((child, index) =>
            React.cloneElement(child, { index, key: index }),
          )
        : React.cloneElement(children, { index: 0 })}

      <TabsIndicator />
    </Box>
  );
}

function TabsBodyItem(props: BoxProps) {
  const { children, index, ...rest } = props as BoxPropsWithIndex;

  const { activeIndex } = React.useContext(TabsContext);

  if (index !== activeIndex) {
    return null;
  }

  return <Box {...rest}>{children}</Box>;
}

function TabsBody(props: TabContainerProps) {
  const { children, ...rest } = props;

  return (
    <Box {...rest}>
      {Array.isArray(children)
        ? children.map((child, index) =>
            React.cloneElement(child, { index, key: index }),
          )
        : React.cloneElement(children, { index: 0 })}
    </Box>
  );
}

interface TabsProps extends BoxProps {
  initialIndex?: number;
  onTabChange?: (index: number) => void;
}

export function Tabs(props: TabsProps) {
  const { initialIndex = 0, onTabChange, ...rest } = props;

  const [activeIndex, setActiveIndex] = React.useState(initialIndex);
  const [indicatorStyle, setIndicatorStyle] = React.useState({});

  const tabHeaderRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  React.useEffect(() => {
    const element = tabHeaderRefs.current[activeIndex];
    if (!element) return;

    const { offsetLeft, offsetWidth } = element;

    setIndicatorStyle({
      left: `${offsetLeft}px`,
      width: `${offsetWidth}px`,
    });
  }, [activeIndex]);

  React.useEffect(() => {
    onTabChange?.(activeIndex);
  }, [activeIndex, onTabChange]);

  return (
    <TabsContext.Provider
      value={{
        tabHeaderRefs,
        activeIndex,
        setActiveIndex,
        indicatorStyle,
        setIndicatorStyle,
      }}
    >
      <Box {...rest} />
    </TabsContext.Provider>
  );
}

Tabs.Header = TabsHeader;
Tabs.HeaderItem = TabsHeaderItem;

Tabs.Body = TabsBody;
Tabs.BodyItem = TabsBodyItem;
