import {
  ReactionTarget,
  ReactionTargetType,
  ReactionType,
} from '@orbiapp/components';

import {
  createReactionThunk,
  deleteReactionThunk,
  useDispatch,
} from '../store';

interface UseReactionsProps {
  type: ReactionTargetType;
  key: string | undefined;
}

export function useReactions(props: UseReactionsProps) {
  const dispatch = useDispatch();

  return (reactionType: ReactionType | null) => {
    if (!props.key) return;

    const target: ReactionTarget = {
      key: props.key,
      type: props.type,
    };

    if (reactionType) {
      return dispatch(createReactionThunk({ target, type: reactionType }));
    }

    return dispatch(deleteReactionThunk({ target }));
  };
}
