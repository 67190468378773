import { Breakpoint } from '@orbiapp/theme';
import React from 'react';

import { Box } from '../box';
import { BreakpointValues, ResponsiveBoxProps } from './responsive-box.types';
import { getActiveBreakpoint } from './responsive-box.utils';

function useResponsiveBox(props: BreakpointValues) {
  const [activeBreakpoint, setActiveBreakpoint] = React.useState<
    Breakpoint | 'children' | null
  >(getActiveBreakpoint(window.innerWidth, props));

  React.useEffect(() => {
    const handleResize = () => {
      const nextBreakpoint = getActiveBreakpoint(window.innerWidth, props);
      if (nextBreakpoint !== activeBreakpoint) {
        setActiveBreakpoint(nextBreakpoint);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props, activeBreakpoint]);

  return activeBreakpoint;
}

export function ResponsiveBox(props: ResponsiveBoxProps) {
  const { children, xxs, xs, sm, md, lg, xl, ...rest } = props;

  const activeBreakpoint = useResponsiveBox({
    children,
    xxs,
    xs,
    sm,
    md,
    lg,
    xl,
  });

  if (!activeBreakpoint || !props[activeBreakpoint]) {
    return null;
  }

  return (
    <Box flex {...rest}>
      {props[activeBreakpoint]}
    </Box>
  );
}
