import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { MembershipMultichoiceQuestionForm } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  createMultichoiceMapper,
  updateMultichoiceMapper,
} from './multichoice-questions.mappers';

export const createMultichoiceQuestion = async (params: {
  membershipTypeKey: string;
  createMultichoiceQuestion: MembershipMultichoiceQuestionForm;
}): Promise<GeneralApiResultWithData<string>> => {
  const { membershipTypeKey, createMultichoiceQuestion } = params;

  const res = await apisauce.post<
    { membershipMultichoiceQuestionKey: string },
    any
  >(
    `/v2/membership-types/${membershipTypeKey}/multichoice-questions`,
    createMultichoiceMapper(createMultichoiceQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.membershipMultichoiceQuestionKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createMultichoiceQuestion', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateMultichoiceQuestion = async (params: {
  membershipTypeKey: string;
  updateMultichoiceQuestion: MembershipMultichoiceQuestionForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, updateMultichoiceQuestion } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/multichoice-questions/${updateMultichoiceQuestion.key}`,
    updateMultichoiceMapper(updateMultichoiceQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteMultichoiceQuestion = async (params: {
  membershipTypeKey: string;
  membershipMultichoiceQuestionKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipMultichoiceQuestionKey } = params;

  const res = await apisauce.delete<void, any>(
    `/v2/membership-types/${membershipTypeKey}/multichoice-questions/${membershipMultichoiceQuestionKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
