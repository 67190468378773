import { createAsyncThunk } from '@reduxjs/toolkit';
import { PostDto } from 'dtos/student-dashboard/v1/post/post.dto';

import {
  CreatePostCommentParams,
  CreatePostForm,
  DeletePostCommentParams,
  EditPostCommentForm,
  EditPostForm,
  GetPostCommentsPageParams,
  GetPostsPageParams,
  Page,
  PartialPostComment,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { getPostsParams } from './posts.utils';

export const getPostsThunk = createAsyncThunk<
  Page<PostDto>,
  Partial<GetPostsPageParams>,
  ThunkApiConfig
>('posts/get-posts', async (params, thunkAPI) => {
  const state = thunkAPI.getState();

  const res = await OrbiApi.call(
    v1.posts.getPosts,
    getPostsParams(
      {
        activityKeys: state.posts.posts.extra.activityKeys,
        q: state.posts.posts.extra.q,
        targetType: state.posts.posts.extra.targetType,
        orderBy: state.posts.posts.orderBy,
        sortOrder: state.posts.posts.sortOrder,
        pageSize: state.posts.posts.pageSize,
      },
      params,
    ),
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createPostThunk = createAsyncThunk<
  PostDto,
  CreatePostForm,
  ThunkApiConfig
>('posts/create', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.createPost, params);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('create-post:success'));

    const state = thunkAPI.getState();
    if (state.posts.posts.extra.lastRequestParams) {
      await thunkAPI.dispatch(
        getPostsThunk(state.posts.posts.extra.lastRequestParams),
      );
    }

    return res.data;
  }

  thunkAPI.dispatch(setAlert('create-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updatePostThunk = createAsyncThunk<
  PostDto,
  EditPostForm,
  ThunkApiConfig
>('posts/update', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.updatePost, params);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-post:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('update-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updatePostCommentThunk = createAsyncThunk<
  PartialPostComment,
  EditPostCommentForm,
  ThunkApiConfig
>('posts/update-comment', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.updatePostComment, params);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-post-comment:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('update-post-comment:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getPostThunk = createAsyncThunk<PostDto, string, ThunkApiConfig>(
  'posts/get',
  async (postKey, thunkAPI) => {
    const res = await OrbiApi.call(v1.posts.getPost, postKey);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const deletePostThunk = createAsyncThunk<void, string, ThunkApiConfig>(
  'posts/delete-post',
  async (postKey, thunkAPI) => {
    const res = await OrbiApi.call(v1.posts.deletePost, postKey);

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-post:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-post:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getPostCommentsThunk = createAsyncThunk<
  Page<PartialPostComment>,
  GetPostCommentsPageParams,
  ThunkApiConfig
>('posts/get-comments', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.getPostComments, params);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const deletePostCommentThunk = createAsyncThunk<
  void,
  DeletePostCommentParams,
  ThunkApiConfig
>('posts/delete-post-comment', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.deletePostComment, params);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-post-comment:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('delete-post-comment:error'));
  return thunkAPI.rejectWithValue(res);
});

export const createPostCommentThunk = createAsyncThunk<
  PartialPostComment,
  CreatePostCommentParams,
  ThunkApiConfig
>('posts/create-post-comment', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.posts.createPostComment, params);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('create-post-comment:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('create-post-comment:error'));
  return thunkAPI.rejectWithValue(res);
});
