import React from 'react';

import { AlertVariant, TxArgs } from '../../components';

interface AlertType {
  variant: AlertVariant;

  autoHideDuration?: number;

  subtitle?: string;
  subtitleTx: TxString;
  subtitleTxArgs?: TxArgs;
}

interface AlertContextType {
  alert: AlertType | null;
  addAlert: (alert: AlertType) => void;
  clearAlert: () => void;
}

const AlertContext = React.createContext<AlertContextType>({} as any);

export function useAlertContext() {
  return React.useContext(AlertContext);
}

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [alert, setAlert] = React.useState<AlertType | null>(null);

  const addAlert = (alert: AlertType) => {
    setAlert(alert);
  };

  const clearAlert = () => {
    setAlert(null);
  };

  return (
    <AlertContext.Provider value={{ alert, addAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
}
