import { getUID } from '@orbiapp/components';

import {
  MembershipInviteListItem,
  MembershipInviteResult,
  MembershipInvitesForm,
  MembershipInvitesResult,
  MembershipPeriod,
} from '../../../../../../models';
import {
  CreateMembershipInvitesDto,
  CreateMembershipInvitesResultDto,
  MembershipInviteListItemDto,
} from './invites.dto';

export const createMembershipInvitationsMapper = (
  membershipInvitesForm: MembershipInvitesForm,
): CreateMembershipInvitesDto => {
  return {
    emails: membershipInvitesForm.emails,
    membershipPeriodKeys: membershipInvitesForm.membershipPeriodKeys?.length
      ? membershipInvitesForm.membershipPeriodKeys
      : null,
  };
};

export const createMembershipInvitationsResultMapper = (
  createMembershipInvitesResultDto: CreateMembershipInvitesResultDto,
): MembershipInvitesResult => {
  const invites = createMembershipInvitesResultDto.invites.map(
    (invite): MembershipInviteResult => ({
      email: invite.email,
      fullName: invite.fullName,
      membershipApplicationKey: invite.membershipApplicationKey,
      result: invite.result,
      userKey: invite.userKey,
      profilePicture: invite.profilePicture,
    }),
  );

  return { invites };
};

export const membershipInviteListItemMapper = (
  membershipInviteListItemDto: MembershipInviteListItemDto,
): MembershipInviteListItem => {
  const periods =
    membershipInviteListItemDto.periods?.map((period): MembershipPeriod => {
      return {
        key: getUID(),
        name: period.name,
        validFrom: period.validFrom,
        validTo: period.validTo,
      };
    }) ?? null;

  return {
    createdAt: membershipInviteListItemDto.createdAt,
    email: membershipInviteListItemDto.email,
    fullName: membershipInviteListItemDto.fullName,
    membershipApplicationKey:
      membershipInviteListItemDto.membershipApplicationKey,
    periods,
    status: membershipInviteListItemDto.status,
    profilePicture: membershipInviteListItemDto.profilePicture,
    userKey: membershipInviteListItemDto.userKey,
  };
};
