import { MENU_DROP_SHADOW } from '@orbiapp/theme';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { Box } from '../box';
import { MenuItemProps } from './menu.types';

const Menu = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      outline: 1px solid ${theme.colors.menuBorder};
      border-radius: 8px;
      list-style: none;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      z-index: 20;
      overflow: overlay;

      ${!isOpen &&
      css`
        display: none;
      `}

      filter: ${MENU_DROP_SHADOW};
    `;
  }}
`;

const menuItemStyles = css<MenuItemProps>`
  ${(props) => {
    const { theme, isSelected, onClick, notPickable, disabled, to } = props;

    return css`
      color: ${theme.colors.menuItemLabel};
      display: flex;
      align-items: center;

      ${disabled &&
      css`
        color: ${theme.colors.menuItemLabelDisabled};
        pointer-events: none;
        cursor: auto;
      `}

      ${!isSelected &&
      css`
        background-color: ${theme.colors.menuItemBackground};
      `}

      ${notPickable &&
      css`
        pointer-events: none;
        cursor: auto;
      `}

      ${(onClick || to) &&
      !notPickable &&
      !disabled &&
      css`
        cursor: pointer;

        :hover {
          background-color: ${theme.colors.menuItemBackgroundHover};
        }

        :active {
          background-color: ${theme.colors.menuItemBackgroundActive};
        }
      `};

      ${isSelected &&
      css`
        background-color: ${theme.colors.menuItemSelectedBackground};
      `}
    `;
  }}
`;

const MenuFooter = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border-top: 1px solid ${theme.colors.menuFooterBorder};
    `;
  }}
`;

const MenuItem = styled.li<MenuItemProps>`
  ${baseComponentStyles};
  ${menuItemStyles};
`;

const MenuItemLink = styled(Link)<MenuItemProps>`
  ${baseComponentStyles};
  ${menuItemStyles};

  text-decoration: none;
`;

export const Styled = { Menu, MenuItem, MenuItemLink, MenuFooter };
