import Joi from 'joi';

import { MembershipApplicationRequirementSchema } from './required-memberships.schema';

export const CreateRequiredMembershipTypesValidation = Joi.object({
  combinationType: MembershipApplicationRequirementSchema.combinationType,
  membershipApplicationRequirements:
    MembershipApplicationRequirementSchema.membershipApplicationRequirements,
});

export const UpdateCombinationTypeFormValidation = Joi.object({
  combinationType: MembershipApplicationRequirementSchema.combinationType,
});
