import {
  Box,
  IconButton,
  InnerContentContainer,
  Switch,
  Text,
  Tooltip,
} from '@orbiapp/components';
import React from 'react';

import {
  GoBackButton,
  PublishEventButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { ActivityFormContext } from '../create-activity.context';

function ToggleQuestionsActivated() {
  const formContext = React.useContext(ActivityFormContext);

  const questionsActivated = formContext.watch('addons.questionsActivated');

  const toggleQuestionsActivated = () => {
    formContext.setValue('addons.questionsActivated', !questionsActivated, {
      shouldDirty: true,
    });
  };

  return (
    <Switch
      checked={questionsActivated}
      onClick={toggleQuestionsActivated}
      tx="label.create-activity.addons.questions.toggle-questions"
    />
  );
}

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton
          to={`${rootPath}/co-hosting`}
          icon="arrow-left-circle-outline"
        />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton disabled icon="arrow-right-circle-outline" />
      </Tooltip>
    </Box>
  );
}

export function CreateActivityAddons() {
  return (
    <React.Fragment>
      <InnerContentContainer>
        <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
          <Box
            flex
            flexAlign="center"
            flexWrap="wrap"
            gap={16}
            height="fit-content"
          >
            <GoBackButton />

            <Text
              as="h1"
              color="pageTitle"
              tx="label.create-activity.tabs.addons.label"
              variant="titleMd"
            />
          </Box>

          <Box flex flexWrap="wrap" gap={16}>
            <SaveActivityAsDraftButton />

            <PublishEventButton />
          </Box>
        </Box>

        <ToggleQuestionsActivated />
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
