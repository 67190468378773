import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  DeleteMembershipTypeErrorResponse,
  MembershipTypeListItem,
  MembershipTypeWrapper,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';
import {
  MembershipTypeListItemDto,
  MembershipTypeWrapperDto,
} from './membership-types.dto';
import {
  membershipTypeListItemMapper,
  membershipTypeWrapperMapper,
} from './membership-types.mappers';

export const getMembershipTypeListItems = async (): Promise<
  GeneralApiResultWithData<MembershipTypeListItem[]>
> => {
  const res = await apisauce.get<MembershipTypeListItemDto[], any>(
    '/v2/membership-types',
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(membershipTypeListItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipTypeListItems', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getMembershipType = async (
  membershipTypeKey: string,
): Promise<GeneralApiResultWithData<MembershipTypeWrapper>> => {
  const res = await apisauce.get<MembershipTypeWrapperDto, any>(
    `/v2/membership-types/${membershipTypeKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = await membershipTypeWrapperMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipType', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deleteMembershipType = async (
  membershipTypeKey: string,
): Promise<GeneralApiResult<DeleteMembershipTypeErrorResponse>> => {
  const res = await apisauce.delete<void, DeleteMembershipTypeErrorResponse>(
    `/v2/membership-types/${membershipTypeKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
