import React from 'react';

export const InputRefContext = React.createContext<
  React.RefObject<HTMLInputElement>
>(React.createRef<HTMLInputElement>());

export function InputRefProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <InputRefContext.Provider value={inputRef}>
      {children}
    </InputRefContext.Provider>
  );
}
