import React from 'react';

import { Icon } from '../icon';
import { Link } from '../link';
import { Spinner } from '../spinner';
import { Tooltip } from '../tooltip';
import { Styled } from './icon-button.styled';
import { IconButtonContentProps, IconButtonProps } from './icon-button.types';

export function getIconButtonIconSize(size: IconButtonProps['size']) {
  switch (size) {
    case 'sm':
      return 20;

    default:
      return 24;
  }
}

export function getIconButtonBackgroundSize(size: IconButtonProps['size']) {
  switch (size) {
    case 'sm':
      return 32;

    default:
      return 40;
  }
}

export function getIconButtonSizeValues(
  size: IconButtonProps['size'],
  iconSize?: number,
) {
  return {
    iconSize: iconSize ?? getIconButtonIconSize(size),
    backgroundSize: getIconButtonBackgroundSize(size),
  };
}

function IconButtonContent(props: IconButtonContentProps) {
  const {
    children,
    disabled,
    icon,
    iconSize,
    isActive,
    isActiveIcon,
    isLoading,
  } = props;

  if (isLoading) {
    return <Spinner size={20} />;
  }

  if (children) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (icon) {
    return (
      <Icon
        zIndex={2}
        absolute
        name={isActive && isActiveIcon && !disabled ? isActiveIcon : icon}
        size={iconSize}
      />
    );
  }

  return null;
}

function _SolidIconButton(
  props: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    children,
    disabled,
    href,
    icon,
    isLoading,
    to,
    isActiveIcon,
    isActive,
    state,
    size,
    ...rest
  } = props;

  const { iconSize, backgroundSize } = getIconButtonSizeValues(
    size,
    props.iconSize,
  );

  const iconButton = (
    <Styled.SolidIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isActive={isActive}
      isLoading={isLoading}
      ref={ref}
      role="button"
      type="button"
      iconSize={iconSize}
      backgroundSize={backgroundSize}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.SolidIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}
export const SolidIconButton = React.forwardRef(_SolidIconButton);

export function OnDarkIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    size,
    ...rest
  } = props;

  const { iconSize, backgroundSize } = getIconButtonSizeValues(
    size,
    props.iconSize,
  );

  const iconButton = (
    <Styled.OnDarkIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      iconSize={iconSize}
      backgroundSize={backgroundSize}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.OnDarkIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export function SuccessIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    size,
    ...rest
  } = props;

  const { iconSize, backgroundSize } = getIconButtonSizeValues(
    size,
    props.iconSize,
  );

  const iconButton = (
    <Styled.SuccessIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      iconSize={iconSize}
      backgroundSize={backgroundSize}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.SuccessIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export function DangerIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    size,
    ...rest
  } = props;

  const { iconSize, backgroundSize } = getIconButtonSizeValues(
    size,
    props.iconSize,
  );

  const iconButton = (
    <Styled.DangerIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      iconSize={iconSize}
      backgroundSize={backgroundSize}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.DangerIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export const IconButton = React.forwardRef(
  (props: IconButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      disabled,
      href,
      icon,
      isActive,
      isActiveIcon,
      isLoading,
      state,
      to,
      size,
      tooltip,
      tooltipPlacement,
      tooltipTx,
      tooltipTxArgs,
      ...rest
    } = props;

    const { iconSize, backgroundSize } = getIconButtonSizeValues(
      size,
      props.iconSize,
    );

    const iconButton = (
      <Styled.IconButton
        aria-disabled={disabled}
        disabled={disabled}
        isLoading={isLoading}
        role="button"
        type="button"
        isActive={isActive}
        ref={ref}
        iconSize={iconSize}
        backgroundSize={backgroundSize}
        {...rest}
      >
        <IconButtonContent
          disabled={disabled}
          icon={icon}
          iconSize={iconSize}
          isActive={isActive}
          isActiveIcon={isActiveIcon}
          isLoading={isLoading}
        >
          {children}
        </IconButtonContent>
      </Styled.IconButton>
    );

    if (to || href) {
      return (
        <Link state={state} href={href} to={to} plain>
          {tooltipPlacement ? (
            <Tooltip
              placement={tooltipPlacement}
              titleTx={tooltipTx}
              title={tooltip}
              titleTxArgs={tooltipTxArgs}
            >
              {iconButton}
            </Tooltip>
          ) : (
            iconButton
          )}
        </Link>
      );
    }

    return tooltipPlacement ? (
      <Tooltip
        placement={tooltipPlacement}
        titleTx={tooltipTx}
        title={tooltip}
        titleTxArgs={tooltipTxArgs}
      >
        {iconButton}
      </Tooltip>
    ) : (
      iconButton
    );
  },
);
