import Joi from 'joi';

import { EmailSchema } from '../email';
import { CreateOrbiPayAccount } from './orbi-pay.model';
import { OrbiPaySchema } from './orbi-pay.schema';

export const CreateOrbiPayAccountValidation = Joi.object<CreateOrbiPayAccount>({
  acceptedTermsOfService: OrbiPaySchema.acceptedTermsOfService,
  businessType: OrbiPaySchema.businessType,
  email: EmailSchema.required(),
  accountHolderType: OrbiPaySchema.accountHolderType,
  accountHolderName: OrbiPaySchema.accountHolderName,
  countryCode: OrbiPaySchema.countryCode,
  vatPercent: OrbiPaySchema.vatPercent,
});
