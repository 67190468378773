import React from 'react';

import {
  CreateAccountLinkType,
  CreateStripeAccountLink,
  StripeRequirementType,
} from '../models';
import { createStripeAccountLinkThunk, useDispatch } from '../store';

interface NavigateStripeConnectFormOptions {
  type: CreateAccountLinkType;
  collect?: StripeRequirementType;
  refreshUrl?: string;
  returnUrl?: string;
}

export const useNavigateStripeConnectForm = () => {
  const dispatch = useDispatch();

  return React.useCallback(
    async (options: NavigateStripeConnectFormOptions) => {
      const payload: CreateStripeAccountLink = {
        refreshUrl:
          options.refreshUrl ?? `${window.location.origin}/orbi-pay/dashboard`,
        returnUrl:
          options.returnUrl ?? `${window.location.origin}/orbi-pay/dashboard`,
        collect: options.collect ?? 'currently_due',
        type: options.type,
      };

      const res = await dispatch(createStripeAccountLinkThunk(payload));

      if (typeof res.payload === 'string') {
        window.location.href = res.payload;
      }
    },
    [dispatch],
  );
};
