import { ImageData } from '@orbiapp/components';

export interface MembershipPurchaseListItem {
  fullName: string;
  membershipPeriodName: string;
  membershipPurchaseKey: string;
  price: number;
  purchasedAt: number;
  refundedAt: number | null;
  profilePicture: ImageData | null;
  userKey: string | null;
}

export type MembershipPurchasesOrderByKey = keyof Pick<
  MembershipPurchaseListItem,
  'purchasedAt' | 'fullName'
>;

export const membershipPurchasesSortableKeys: Record<
  MembershipPurchasesOrderByKey,
  MembershipPurchasesOrderByKey
> = {
  fullName: 'fullName',
  purchasedAt: 'purchasedAt',
};
