import { AccountRole } from '@orbiapp/components';

export function getTeamRoleTx(role: AccountRole): TxString {
  switch (role) {
    case 'admin':
      return 'label.team.invite-team-member.role.options.department_admin';
    case 'member':
      return 'label.team.invite-team-member.role.options.department_member';
  }
}
