export namespace WorkspacesSessionStorage {
  const SESSION_STORAGE_WORKSPACE_KEY = 'workspaceKey';

  export function getWorkspaceKey() {
    return sessionStorage.getItem(SESSION_STORAGE_WORKSPACE_KEY);
  }

  export function startSession(workspaceKey: string) {
    if (getWorkspaceKey() === workspaceKey) return;

    sessionStorage.setItem(SESSION_STORAGE_WORKSPACE_KEY, workspaceKey);
  }

  export function endSession() {
    sessionStorage.removeItem(SESSION_STORAGE_WORKSPACE_KEY);
  }
}

export namespace EmulateUserSessionStorage {
  const EMULATE_USER_ID = 'EMULATE_USER';

  export function getUserKey() {
    return sessionStorage.getItem(EMULATE_USER_ID);
  }

  export function startSession(userKey: string) {
    if (getUserKey() === userKey) return;

    sessionStorage.setItem(EMULATE_USER_ID, userKey);
  }

  export function endSession() {
    sessionStorage.removeItem(EMULATE_USER_ID);
  }
}
