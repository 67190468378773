import styled, { css } from 'styled-components';

const Indicator = styled('div')`
  ${(props) => {
    const { theme } = props;

    return css`
      align-items: center;
      background-color: ${theme.colors.indicatorBackground};
      border-radius: 100px;
      color: ${theme.colors.indicatorLabel};
      display: flex;
      justify-content: center;
      padding: 0 8px;
      width: fit-content;
    `;
  }}
`;

export const Styled = { Indicator };
