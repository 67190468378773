import styled, { css } from 'styled-components';

import { Box } from '../../box';

const Menu = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.tableRowMenuBackground};
      transform: scale(1);
      z-index: 300;
      position: fixed;

      ${!props.isOpen &&
      css`
        opacity: 0;
        transform: scale(0);
        pointer-events: none;
      `}
    `;
  }}
`;

export const Styled = { Menu };
