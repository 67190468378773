export const semesters = [
  'followers_semester_1',
  'followers_semester_2',
  'followers_semester_3',
  'followers_semester_4',
  'followers_semester_5',
  'followers_semester_6',
  'followers_semester_7',
  'followers_semester_8',
  'followers_semester_9',
  'followers_semester_10',
  'followers_semester_11',
  'followers_semester_12',
] as const;

export const audienceTypes = [
  ...semesters,
  'all',
  'attendees',
  'followers',
  'saves',
  'target_group',
  'team',
  'user',
] as const;

export type AudienceType = typeof audienceTypes[number];
export type Semester = typeof semesters[number];
