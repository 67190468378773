import { getAlert } from '../../utils';
import { RootState } from '../store.types';

export namespace GlobalUiStateSelector {
  export const selectAlert = (state: RootState) => {
    if (!state.globalUiState.alert?.alertType) {
      return null;
    }

    return getAlert(
      state.globalUiState.alert.alertType,
      state.globalUiState.alert?.args,
    );
  };
}
