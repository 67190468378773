import styled, { css } from 'styled-components';

import { baseComponentStyles, isUndefined, stringOrPx } from '../../utils';
import { LabelProps, TextProps } from './text.types';
import { getFontVariationSettings } from './text.utils';

const textStyles = css<TextProps | LabelProps>`
  ${(props) => {
    const {
      color,
      lineClamp,
      theme,
      textTransform,
      variant = 'bodyMd',
      userSelect,
      lineHeight,
    } = props;

    const fontStyles = theme.textVariants[variant];
    const fontVariationSettings = getFontVariationSettings(variant);

    return css`
      ${baseComponentStyles};
      ${fontVariationSettings};

      font-size: ${fontStyles.fontSize}px;
      font-family: ${fontStyles.fontFamily};
      letter-spacing: ${fontStyles.letterSpacing}px;
      line-height: ${fontStyles.lineHeight}px;

      ${userSelect &&
      css`
        user-select: ${userSelect};
      `}

      ${lineClamp &&
      css`
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${lineClamp};
        -webkit-box-orient: vertical;
      `}

      ${color &&
      css`
        color: ${theme.colors[color]} !important;
      `}

      ${textTransform &&
      css`
        text-transform: ${textTransform};
      `}

      ${!isUndefined(lineHeight) &&
      css`
        line-height: ${stringOrPx(lineHeight)};
      `}
    `;
  }}
`;

const Label = styled.label<LabelProps>`
  ${textStyles}
`;

const Paragraph = styled.p<TextProps>`
  ${textStyles}
`;

const Span = styled.span<TextProps>`
  ${textStyles}
`;

const H1 = styled.h1<TextProps>`
  ${textStyles}
`;

const H2 = styled.h2<TextProps>`
  ${textStyles}
`;

const H3 = styled.h3<TextProps>`
  ${textStyles}
`;

const H4 = styled.h4<TextProps>`
  ${textStyles}
`;

const H5 = styled.h5<TextProps>`
  ${textStyles}
`;

const H6 = styled.h6<TextProps>`
  ${textStyles}
`;

export const Styled = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Label,
  Paragraph,
  Span,
};
