import styled, { css } from 'styled-components';

import { stringOrPx } from '../../utils';
import { Box } from '../box';
import { getTextStyles } from '../text';
import {
  TEXTAREA_BORDER_RADIUS,
  TEXTAREA_MIN_WIDTH,
  TEXTAREA_X_PADDING,
  TEXTAREA_Y_PADDING,
} from './textarea.constants';
import { TextareaProps } from './textarea.types';

interface StyledTextareaProps {
  hasError: boolean;
  hasTinySelect?: boolean;
  hasTrailingElement: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  resize?: TextareaProps['resize'];
}

const TextareaBox = styled(Box)<{ floatLabel: boolean }>`
  ${(props) => {
    const { floatLabel } = props;

    return css`
      ${floatLabel &&
      css`
        p {
          transform: scaleY(100%);
        }
      `}

      :focus-within {
        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      }
    `;
  }}
`;

const TextareaBoxLabelBox = styled(Box)<{ floatLabel: boolean }>`
  ${(props) => {
    const { floatLabel, theme } = props;

    return css`
      label {
        ${getTextStyles('bodyMd', theme)}

        transition: ${theme.transitions.fast};
        color: ${theme.colors.inputLabel};
        top: 24px;
        left: ${TEXTAREA_X_PADDING}px;
        transition-property: all;
      }

      ${floatLabel &&
      css`
        label {
          ${getTextStyles('bodySm', theme)}
          color: ${theme.colors.inputLabelFocus};
          top: 16px;
        }
      `}

      :focus-within {
        label {
          ${getTextStyles('bodySm', theme)}
          color: ${theme.colors.inputLabelFocus} !important;
          top: 16px;
        }
      }
    `;
  }}
`;

const Textarea = styled.textarea<StyledTextareaProps>`
  display: block;
  border: none;
  outline: none;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  width: 100%;

  ${(props) => {
    const {
      hasError,
      hasTinySelect,
      hasTrailingElement,
      minHeight = 200,
      maxHeight,
      resize = 'both',
      theme,
    } = props;

    const textareaStyles = css`
      ${getTextStyles('bodyMd', theme)};

      min-width: ${TEXTAREA_MIN_WIDTH}px;
      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};
      color: ${theme.colors.inputText};
      font-family: ${theme.textVariants.bodyMd.fontFamily};
      border-radius: ${TEXTAREA_BORDER_RADIUS}px;
      padding-left: ${TEXTAREA_X_PADDING}px;
      padding-top: ${hasTinySelect
        ? TEXTAREA_Y_PADDING * 3
        : TEXTAREA_Y_PADDING * 2.2}px;
      padding-bottom: ${hasTrailingElement
        ? TEXTAREA_Y_PADDING * 3
        : TEXTAREA_Y_PADDING}px;
      padding-right: ${TEXTAREA_X_PADDING}px;
      word-wrap: break-word;

      min-height: ${stringOrPx(minHeight)};

      ${!maxHeight !== undefined &&
      css`
        max-height: ${stringOrPx(maxHeight)};
      `}

      resize: ${resize};

      :focus {
        ${!hasError &&
        css`
          border-color: ${theme.colors.inputBorderFocus};
          background-color: ${theme.colors.inputBackgroundFocus};
        `}

        ::placeholder {
          opacity: 0;
        }
      }

      ::placeholder {
        ${getTextStyles('bodySm', theme)};
      }

      :disabled {
        pointer-events: none;
        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
        color: ${theme.colors.inputTextDisabled};
      }
    `;

    if (hasError) {
      return css`
        ${textareaStyles};

        background-color: ${theme.colors.inputBackgroundError};
        border-color: ${theme.colors.inputBorderError};
        color: ${theme.colors.inputTextError};
      `;
    }

    return css`
      ${textareaStyles};
    `;
  }}
`;

export const Styled = { TextareaBox, Textarea, TextareaBoxLabelBox };
