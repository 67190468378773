import Joi from 'joi';

import { BoolSchema, MIN_PRICE } from '../../../shared';
import { ActivityForm } from '../../activity.model';
import { TicketType } from '../ticket-types.model';
import { MembershipDiscountErrors } from './membership-discount.errors';

namespace MembershipDiscountUtils {
  export const discount = (
    discount: number,
    helpers: Joi.CustomHelpers<ActivityForm>,
  ) => {
    const ancestors = helpers.state.ancestors;
    const ticketType: TicketType = ancestors[2];
    const newPrice = ticketType.price - discount;

    if (newPrice < 0) {
      return helpers.error(
        MembershipDiscountErrors.customErrors.priceBelowZero,
      );
    }

    if (newPrice > 0 && newPrice < MIN_PRICE) {
      return helpers.error(MembershipDiscountErrors.customErrors.priceBelowMin);
    }

    return discount;
  };

  export const addDiscount =
    (ticketPrice: number) =>
    (discount: number, helpers: Joi.CustomHelpers<ActivityForm>) => {
      const newPrice = ticketPrice - discount;

      if (newPrice < 0) {
        return helpers.error(
          MembershipDiscountErrors.customErrors.priceBelowZero,
        );
      }

      if (newPrice > 0 && newPrice < MIN_PRICE) {
        return helpers.error(
          MembershipDiscountErrors.customErrors.priceBelowMin,
        );
      }

      return discount;
    };
}

export namespace MembershipDiscountSchema {
  export const membershipTypeDiscountKey = Joi.string().allow(null).optional();

  export const isNew = BoolSchema.required();

  export const membershipTypeKey = Joi.string()
    .required()
    .messages(MembershipDiscountErrors.membershipTypeKey);

  export const discountType = Joi.string()
    .valid('fixed')
    .required()
    .messages(MembershipDiscountErrors.discountType);

  export const discount = Joi.number()
    .integer()
    .min(1)
    .custom(MembershipDiscountUtils.discount)
    .required()
    .messages(MembershipDiscountErrors.discount);

  export const addDiscount = (ticketPrice: number) =>
    Joi.number()
      .integer()
      .min(1)
      .custom(MembershipDiscountUtils.addDiscount(ticketPrice))
      .required()
      .messages(MembershipDiscountErrors.discount);

  export const departmentName = Joi.string().required();
  export const name = Joi.string().required();

  export const logoUrl = Joi.string().required();
}
