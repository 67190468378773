import { INITIAL_DATA_STATE } from '../store.constants';
import { externalJobsAdapter } from './jobs.adapter';
import { JobState } from './jobs.types';

export const initialJobsState: JobState = {
  hideJob: INITIAL_DATA_STATE,
  externalJobs: {
    ...INITIAL_DATA_STATE,
    data: externalJobsAdapter.getInitialState(),
  },
};
