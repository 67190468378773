import { DatasetListItem, PieChart, translate } from '@orbiapp/components';

import {
  MembershipTypeSelector,
  MembershipTypeStatsSelector,
  useSelector,
} from '../../../../../../store';
import { Styled } from './membership-charts.styled';

const monthTxStrings: TxString[] = [
  'label.months.january',
  'label.months.february',
  'label.months.march',
  'label.months.april',
  'label.months.may',
  'label.months.june',
  'label.months.july',
  'label.months.august',
  'label.months.september',
  'label.months.october',
  'label.months.november',
  'label.months.december',
];

const ActiveMembersChart = () => {
  const activeMembersCount = useSelector(
    MembershipTypeStatsSelector.selectActiveMembersCount,
  );
  const totalInactiveMembersCount = useSelector(
    MembershipTypeStatsSelector.selectTotalInactiveMembersCount,
  );

  if (activeMembersCount === 0 && totalInactiveMembersCount === 0) {
    return null;
  }

  const listItems: DatasetListItem[] = [
    {
      labelTx: 'label.memberships.view-membership.charts.active',
      value: activeMembersCount,
    },
    {
      labelTx: 'label.memberships.view-membership.charts.inactive',
      value: totalInactiveMembersCount,
    },
  ];

  const labels = [
    translate('label.memberships.view-membership.charts.active'),
    translate('label.memberships.view-membership.charts.inactive'),
  ];

  return (
    <PieChart
      datasets={[{ data: [activeMembersCount, totalInactiveMembersCount] }]}
      labels={labels}
      titleTx="label.memberships.view-membership.charts.active-members"
      listItems={listItems}
      unitPosition="right"
      unitTx="label.memberships.view-membership.charts.members"
      subtitleText={activeMembersCount}
    />
  );
};

const PeriodsChart = () => {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);
  const newMembersMonthly = useSelector(
    MembershipTypeStatsSelector.selectNewMembersMonthly,
  );

  const newMembersMontlyCount = newMembersMonthly.reduce(
    (newMembersMontlyCount, newMemberCountMonthly) =>
      newMembersMontlyCount + newMemberCountMonthly.count,
    0,
  );

  if (isIntegrated || newMembersMontlyCount === 0) {
    return null;
  }

  const { counts, months } = newMembersMonthly.reduce<{
    months: string[];
    counts: number[];
  }>(
    (acc, curr) => {
      return {
        months: [...acc.months, translate(monthTxStrings[curr.month])],
        counts: [...acc.counts, curr.count],
      };
    },
    { months: [], counts: [] },
  );

  const listItems = newMembersMonthly.map((newMemberCountMonthly) => ({
    label: translate(monthTxStrings[newMemberCountMonthly.month]),
    value: newMemberCountMonthly.count,
  }));

  return (
    <PieChart
      datasets={[{ data: counts }]}
      labels={months}
      titleTx="label.memberships.view-membership.charts.new-members-this-year"
      listItems={listItems}
      unitPosition="right"
      subtitleText={counts.reduce((acc, curr) => acc + curr, 0)}
      unitTx="label.memberships.view-membership.charts.members"
    />
  );
};

export const MembershipCharts = () => {
  return (
    <Styled.MembershipCharts>
      <ActiveMembersChart />
      <PeriodsChart />
    </Styled.MembershipCharts>
  );
};
