import { ImageCritera, ImageMeta, bytesToMb } from '@orbiapp/components';

import { GlobalUiStateAlert } from '../models';

export const imageMeetsCriteria = (
  criteria: ImageCritera,
  imageMeta: ImageMeta,
): GlobalUiStateAlert | null => {
  if (imageMeta.size > criteria.maxSize) {
    return {
      alertType: 'image-too-large-size',
      args: { maxFileSize: bytesToMb(criteria.maxSize) },
    };
  }

  if (imageMeta.width < criteria.minWidth) {
    return {
      alertType: 'image-too-small-width',
      args: { minWidth: criteria.minWidth.toString() },
    };
  }

  if (imageMeta.height < criteria.minHeight) {
    return {
      alertType: 'image-too-small-height',
      args: { minHeight: criteria.minHeight.toString() },
    };
  }

  if (imageMeta.height > criteria.maxHeight) {
    return {
      alertType: 'image-too-large-height',
      args: { maxHeight: criteria.maxHeight.toString() },
    };
  }

  if (imageMeta.width > criteria.maxWidth) {
    return {
      alertType: 'image-too-large-width',
      args: { maxWidth: criteria.maxWidth.toString() },
    };
  }

  return null;
};
