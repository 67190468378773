import {
  createInvitationsThunk,
  deleteInvitationThunk,
  getDepartmentInvitationThunk,
  getPendingInvitationsThunk,
  getUsersThunk,
  removeUserThunk,
  updateUserThunk,
} from './team.actions';
import { pendingInvitationsAdapter, usersAdapter } from './team.adapter';
import { TeamBuilder } from './team.types';

const createInvitationsBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(createInvitationsThunk.fulfilled, (state, action) => {
      state.createInvitations.status = 'idle';
      pendingInvitationsAdapter.addMany(
        state.pendingInvitations.data,
        action.payload,
      );
    })
    .addCase(createInvitationsThunk.pending, (state) => {
      state.createInvitations.status = 'pending';
      state.createInvitations.error = null;
    })
    .addCase(createInvitationsThunk.rejected, (state, action) => {
      state.createInvitations.status = 'idle';
      state.createInvitations.error = action.error;
    });
};

const deleteInvitationBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(deleteInvitationThunk.fulfilled, (state, action) => {
      state.deleteInvitation.status = 'idle';

      pendingInvitationsAdapter.removeOne(
        state.pendingInvitations.data,
        action.meta.arg,
      );
    })
    .addCase(deleteInvitationThunk.pending, (state) => {
      state.deleteInvitation.status = 'pending';
      state.deleteInvitation.error = null;
    })
    .addCase(deleteInvitationThunk.rejected, (state, action) => {
      state.deleteInvitation.status = 'idle';
      state.deleteInvitation.error = action.error;
    });
};

const getDepartmentInvitationBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getDepartmentInvitationThunk.fulfilled, (state, action) => {
      state.departmentInvitation.status = 'completed';
      state.departmentInvitation.data = action.payload;
    })
    .addCase(getDepartmentInvitationThunk.pending, (state) => {
      state.departmentInvitation.status = 'pending';
      state.departmentInvitation.error = null;
    })
    .addCase(getDepartmentInvitationThunk.rejected, (state, action) => {
      state.departmentInvitation.status = 'idle';
      state.departmentInvitation.error = action.error;
    });
};

const getPendingInvitationsBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getPendingInvitationsThunk.fulfilled, (state, action) => {
      state.pendingInvitations.status = 'idle';

      pendingInvitationsAdapter.setAll(
        state.pendingInvitations.data,
        action.payload,
      );
    })
    .addCase(getPendingInvitationsThunk.pending, (state) => {
      state.pendingInvitations.status = 'pending';
      state.pendingInvitations.error = null;
    })
    .addCase(getPendingInvitationsThunk.rejected, (state, action) => {
      state.pendingInvitations.status = 'idle';
      state.pendingInvitations.error = action.error;
    });
};

const getUsersBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(getUsersThunk.fulfilled, (state, action) => {
      state.users.status = 'idle';

      usersAdapter.setAll(state.users.data, action.payload);
    })
    .addCase(getUsersThunk.pending, (state) => {
      state.users.status = 'pending';
      state.users.error = null;
    })
    .addCase(getUsersThunk.rejected, (state, action) => {
      state.users.status = 'idle';
      state.users.error = action.error;
    });
};

const removeUserBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(removeUserThunk.fulfilled, (state, action) => {
      state.removeUser.status = 'idle';

      usersAdapter.removeOne(state.users.data, action.meta.arg.userKey);
    })
    .addCase(removeUserThunk.pending, (state) => {
      state.removeUser.status = 'pending';
      state.removeUser.error = null;
    })
    .addCase(removeUserThunk.rejected, (state, action) => {
      state.removeUser.status = 'idle';
      state.removeUser.error = action.error;
    });
};

const updateUserBuild = (builder: TeamBuilder) => {
  return builder
    .addCase(updateUserThunk.fulfilled, (state, action) => {
      state.updateUser.status = 'idle';

      usersAdapter.updateOne(state.users.data, {
        id: action.meta.arg.userKey,
        changes: {
          role: action.meta.arg.role,
        },
      });
    })
    .addCase(updateUserThunk.pending, (state) => {
      state.updateUser.status = 'pending';
      state.updateUser.error = null;
    })
    .addCase(updateUserThunk.rejected, (state, action) => {
      state.updateUser.status = 'idle';
      state.updateUser.error = action.error;
    });
};

export const extraReducers = (builder: TeamBuilder) => {
  createInvitationsBuild(builder);
  deleteInvitationBuild(builder);
  getDepartmentInvitationBuild(builder);
  getPendingInvitationsBuild(builder);
  getUsersBuild(builder);
  removeUserBuild(builder);
  updateUserBuild(builder);
};
