import { Box } from '@orbiapp/components';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes` 
  from {transform: scaleY(0) }
  to {transform: scaleY(1) }
`;

const TagsGrid = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px 12px;
      width: fit-content;

      @media (min-width: ${theme.breakpoints.xxs}px) {
        gap: 16px 24px;
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (min-width: ${theme.breakpoints.sm}px) {
        gap: 16px 24px;
        grid-template-columns: 1fr 1fr 1fr auto;
      }
    `;
  }}
`;

const PickCityBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      animation: ${fadeIn} ${theme.transitions.fast};
      background-color: ${theme.colors.pickCityCardBackground};
      box-shadow: ${theme.elevation.medium};
    `;
  }}
`;

export const Styled = { PickCityBox, TagsGrid };
