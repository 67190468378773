import { Point } from 'react-easy-crop';

import { TxArgs } from '../text';

export enum FileType {
  ImageJpeg = 'image/jpeg',
  ImagePng = 'image/png',
  ImageWebp = 'image/webp',
}

export interface Base64File {
  fileName: string;
  fileSize: number;
  fileType: FileType;
  base64: string;
}

export interface Base64FileDto {
  fileName: string;
  fileType: FileType;
  base64: string;
}

export interface CropState {
  aspect: number;
  base64File: Base64File | null;
  x: number;
  y: number;
  zoom: number;
}

export interface ImageCropperProps {
  closeText?: string;
  closeTx?: TxString;
  closeTxArgs?: TxArgs;
  cropState: CropState;
  isOpen: boolean;
  onClose: () => void;
  onSave: (newState: CropState) => void;
  saveText?: string;
  saveTx?: TxString;
  saveTxArgs?: TxArgs;
  titleText?: string;
  titleTx?: TxString;
  titleTxArgs?: TxArgs;
}

export interface CropAreaState {
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface ImageCropperState {
  x: number;
  y: number;
  zoom: number;
  aspect: number;
  croparea: CropAreaState;
}

export type ImageCropperReducerAction =
  | {
      type: 'set:zoom';
      payload: number;
    }
  | {
      type: 'set:crop';
      payload: Point;
    }
  | {
      type: 'increment:zoom';
    }
  | {
      type: 'decrement:zoom';
    }
  | {
      type: 'set:croparea';
      payload: CropAreaState;
    };
