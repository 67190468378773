import { Logger } from '../logger';
import { objectStoreNames } from './cache.constants';
import { ObjectStoreName } from './cache.types';

export let db: IDBDatabase | null = null;

const createObjectStores = (db: IDBDatabase) => {
  objectStoreNames.forEach((objectStoreOptions) => {
    const { name, options } = objectStoreOptions;

    if (!db.objectStoreNames.contains(name)) {
      db.createObjectStore(name, options);
      return;
    }
  });
};

export const openDatabase = (database: string) => {
  if (!window.__FLAGS__.ENABLE_CACHE) {
    Logger.info('openDatabase', {
      message: "This browser doesn't support IndexedDB",
    });

    return;
  }

  const request = indexedDB.open(database, 2);

  request.onerror = (e) => {
    const message = (e.target as IDBOpenDBRequest).error?.message;

    if (message) {
      Logger.error('openDatabase', { message });
    }

    db = null;
  };

  request.onsuccess = (e) => {
    const target = e.target as IDBOpenDBRequest;

    createObjectStores(target.result);
    db = target.result;
  };

  request.onblocked = (e) => {
    window.__FLAGS__.ENABLE_CACHE = false;
  };

  request.onupgradeneeded = (e) => {
    const target = e.target as IDBOpenDBRequest;

    createObjectStores(target.result);
    db = target.result;
  };
};

export const newTransaction = (
  objectStoreName: ObjectStoreName,
  mode: IDBTransactionMode,
) => {
  if (!db) return null;

  const transaction = db.transaction(objectStoreName, mode);
  return transaction.objectStore(objectStoreName);
};
