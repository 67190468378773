export namespace CheckboxQuestionErrors {
  export const question = {
    'string.empty': 'errors.question.empty',
  };
}

export namespace MultichoiceQuestionErrors {
  export const optionLabel = {
    'string.empty': 'errors.label.empty',
    'string.max': 'errors.label.max',
  };

  export const question = {
    'string.empty': 'errors.question.empty',
  };
}

export namespace FreetextQuestionErrors {
  export const question = {
    'string.empty': 'errors.question.empty',
  };
}
