import { PayloadAction } from '@reduxjs/toolkit';

import { PayoutsFiltering } from '../../models';
import { initialOrbiPayState } from './orbi-pay.initial';
import { OrbiPayState } from './orbi-pay.types';

const reset = (state: OrbiPayState) => {
  Object.assign(state, initialOrbiPayState);
};

const setPayoutsFiltering = (
  state: OrbiPayState,
  action: PayloadAction<Partial<PayoutsFiltering>>,
) => {
  const limit = action.payload.limit ?? state.payouts.payoutsFiltering.limit;
  const startDate =
    action.payload.startDate ?? state.payouts.payoutsFiltering.startDate;
  let endDate =
    action.payload.endDate ?? state.payouts.payoutsFiltering.endDate;

  if (startDate > endDate) {
    endDate = startDate;
  }

  state.payouts.payoutsFiltering = {
    limit,
    endDate,
    startDate,
  };
};

const clearPayoutError = (state: OrbiPayState) => {
  state.payout.error = null;
};

const clearBankAccountTokenId = (state: OrbiPayState) => {
  state.publicStripeKey = initialOrbiPayState.publicStripeKey;
};

const clearPayout = (state: OrbiPayState) => {
  state.payout = initialOrbiPayState.payout;
};

const clearCreateOrbiPayAccount = (state: OrbiPayState) => {
  state.createOrbiPayAccount = initialOrbiPayState.createOrbiPayAccount;
};

export const reducers = {
  reset,

  clearBankAccountTokenId,
  clearCreateOrbiPayAccount,
  clearPayout,
  clearPayoutError,
  setPayoutsFiltering,
};
