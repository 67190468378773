import {
  Base64File,
  Base64FileDto,
  base64FileSize,
  getUID,
} from '@orbiapp/components';

import {
  ActivityCheckbox,
  ActivityDraft,
  ActivityFreeText,
  ActivityMultiChoice,
  ActivityRequestedInfoOption,
  DiscountCode,
  RequestedInfo,
  RequestedInfoType,
  RequiredMembershipType,
  TicketType,
} from '../../../../../../models';
import { V1GetActivityDraft, V2GetActivityDraft } from './draft.dto';

export namespace V1DraftMappers {
  export const getActivityDraftMapper = (
    activityDraftDto: V1GetActivityDraft.ActivityDraftDto,
  ): ActivityDraft => {
    const requestedInfoMapper = (
      requestedInfo: V1GetActivityDraft.RequestedInfo[] | null,
    ) => {
      if (!requestedInfo) return null;

      return requestedInfo.map((requestedInfo): RequestedInfo => {
        switch (requestedInfo.type) {
          case RequestedInfoType.FreeText:
            const freetext: ActivityFreeText = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
            };
            return freetext;

          case RequestedInfoType.MultiChoice:
            const multichoiceOptionMapper = (
              option: V1GetActivityDraft.RequestedInfoOption,
            ): ActivityRequestedInfoOption => ({
              id: option.id,
              index: option.index,
              isNew: true,
              label: option.label,
              priceIncrement: option.priceIncrement ?? 0,
            });

            const multichoice: ActivityMultiChoice = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              options: requestedInfo.options.map(multichoiceOptionMapper),
            };
            return multichoice;

          default:
            const checkbox: ActivityCheckbox = {
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              id: requestedInfo.id,
              index: requestedInfo.index,
              isNew: true,
              priceIncrement: requestedInfo.priceIncrement ?? 0,
              question: requestedInfo.question,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              type: requestedInfo.type,
            };
            return checkbox;
        }
      });
    };

    const ticketTypeMapper = (
      ticketType: V1GetActivityDraft.TicketType,
    ): TicketType => {
      const discountCodes: DiscountCode[] | null =
        ticketType.discountCodes?.map(
          (discountCode): DiscountCode => ({
            code: discountCode.code,
            discount: discountCode.discount,
            discountCodeKey: discountCode.discountCodeKey || getUID(),
            discountType: 'fixed',
            isNew: true,
          }),
        ) ?? null;

      return {
        description: ticketType.description,
        discountCodes,
        endDate: ticketType.endDate,
        isNew: true,
        isTransferable: ticketType.isTransferable,
        maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
        membershipTypeDiscounts: null,
        name: ticketType.name,
        price: ticketType.price,
        requiredMembershipTypes: null,
        startDate: ticketType.startDate,
        ticketCount: ticketType.ticketCount,
        ticketTypeKey: ticketType.ticketTypeKey || getUID(),
      };
    };

    const requestedInfo = activityDraftDto.data.requestedInfo
      ? requestedInfoMapper(activityDraftDto.data.requestedInfo)
      : null;

    const tags = activityDraftDto.data.tags?.length
      ? activityDraftDto.data.tags
      : null;

    const ticketTypes =
      (activityDraftDto.data.ticketTypes?.length
        ? activityDraftDto.data.ticketTypes?.map(ticketTypeMapper)
        : null) ?? null;

    const _activityDraft: ActivityDraft = {
      key: activityDraftDto.key,
      lastUpdated: activityDraftDto.lastUpdated,
      data: {
        description: {
          categories: activityDraftDto.data.categories ?? [],
          contactDetails: activityDraftDto.data.contactDetails,
          description: activityDraftDto.data.description,
          endDate: activityDraftDto.data.endDate,
          startDate: activityDraftDto.data.startDate,
          title: activityDraftDto.data.title,
          location: activityDraftDto.data.location,
          links: activityDraftDto.data.links,
          tags,
        },
        media: {
          coverImage: base64FileMapper(activityDraftDto.data.coverImage),
        },
        participants: {
          segmentation: activityDraftDto.data.segmentation,
        },
        requestMoreInfo: {
          requestedInfo,
        },
        coHosting: {
          cohosts: activityDraftDto.data.cohosts,
        },
        tickets: {
          ticketTypes,
          showAttendance: activityDraftDto.data.showAttendance,
        },
        addons: {
          questionsActivated: activityDraftDto.data.questionsActivated,
        },
      },
    };

    return _activityDraft;
  };
}

export namespace V2DraftMappers {
  export const createActivityDraftMapper = (
    activityDraft: ActivityDraft,
  ): V2GetActivityDraft.ActivityDraftDto => {
    const requestedInfoMapper = (
      requestedInfo: RequestedInfo[] | null,
    ): V2GetActivityDraft.RequestedInfo[] | null => {
      if (!requestedInfo) return null;

      return requestedInfo.map((requestedInfo): RequestedInfo => {
        switch (requestedInfo.type) {
          case RequestedInfoType.FreeText:
            const freetext: ActivityFreeText = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
            };
            return freetext;

          case RequestedInfoType.MultiChoice:
            const multichoiceOptionMapper = (
              option: V2GetActivityDraft.RequestedInfoOption,
            ): ActivityRequestedInfoOption => ({
              id: option.id,
              index: option.index,
              isNew: true,
              label: option.label,
              priceIncrement: option.priceIncrement ?? 0,
            });

            const multichoice: ActivityMultiChoice = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              options: requestedInfo.options.map(multichoiceOptionMapper),
            };
            return multichoice;

          default:
            const checkbox: ActivityCheckbox = {
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              id: requestedInfo.id,
              index: requestedInfo.index,
              isNew: true,
              priceIncrement: requestedInfo.priceIncrement ?? 0,
              question: requestedInfo.question,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              type: requestedInfo.type,
            };
            return checkbox;
        }
      });
    };

    const ticketTypeMapper = (
      ticketType: TicketType,
    ): V2GetActivityDraft.TicketType => {
      const discountCodes: DiscountCode[] | null =
        ticketType.discountCodes?.map(
          (discountCode): DiscountCode => ({
            code: discountCode.code,
            discount: discountCode.discount,
            discountCodeKey: discountCode.discountCodeKey || getUID(),
            discountType: 'fixed',
            isNew: true,
          }),
        ) ?? null;

      return {
        description: ticketType.description,
        discountCodes,
        endDate: ticketType.endDate,
        isNew: true,
        isTransferable: ticketType.isTransferable,
        maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
        membershipTypeDiscounts: ticketType.membershipTypeDiscounts
          ? ticketType.membershipTypeDiscounts.map(
              (
                membershipTypeDiscount,
              ): V2GetActivityDraft.MembershipTypeDiscount => ({
                departmentName: membershipTypeDiscount.departmentName,
                discount: membershipTypeDiscount.discount,
                discountType: 'fixed',
                isNew: true,
                logoUrl: membershipTypeDiscount.logoUrl,
                membershipTypeDiscountKey:
                  membershipTypeDiscount.membershipTypeDiscountKey || getUID(),
                membershipTypeKey: membershipTypeDiscount.membershipTypeKey,
                name: membershipTypeDiscount.name,
              }),
            )
          : null,
        name: ticketType.name,
        price: ticketType.price,
        requiredMembershipTypes: ticketType.requiredMembershipTypes
          ? {
              combinationType:
                ticketType.requiredMembershipTypes.combinationType,
              membershipApplicationRequirements:
                ticketType.requiredMembershipTypes.membershipApplicationRequirements.map(
                  (membershipType): RequiredMembershipType => ({
                    departmentName: membershipType.departmentName,
                    key: membershipType.key,
                    logoUrl: membershipType.logoUrl,
                    membershipTypeKey: membershipType.membershipTypeKey,
                    name: membershipType.name,
                  }),
                ),
            }
          : null,
        startDate: ticketType.startDate,
        ticketCount: ticketType.ticketCount,
        ticketTypeKey: ticketType.ticketTypeKey || getUID(),
      };
    };

    const requestedInfo = activityDraft.data.requestMoreInfo?.requestedInfo
      ? requestedInfoMapper(activityDraft.data.requestMoreInfo.requestedInfo)
      : null;

    const tags = activityDraft.data.description.tags?.length
      ? activityDraft.data.description.tags
      : null;

    const ticketTypes =
      (activityDraft.data.tickets.ticketTypes?.length
        ? activityDraft.data.tickets.ticketTypes?.map(ticketTypeMapper)
        : null) ?? null;

    const showAttendance = activityDraft.data.tickets.showAttendance;

    const _activityDraft: V2GetActivityDraft.ActivityDraftDto = {
      key: activityDraft.key,
      lastUpdated: activityDraft.lastUpdated,
      data: {
        categories: activityDraft.data.description.categories,
        contactDetails: activityDraft.data.description.contactDetails,
        description: activityDraft.data.description.description,
        endDate: activityDraft.data.description.endDate,
        startDate: activityDraft.data.description.startDate,
        title: activityDraft.data.description.title,
        location: {
          coordinates: activityDraft.data.description.location.coordinates,
          label: activityDraft.data.description.location.label,
          city: activityDraft.data.description.location.city ?? null,
          link: activityDraft.data.description.location.link?.length
            ? activityDraft.data.description.location.link
            : null,
          description: activityDraft.data.description.location.description
            ? activityDraft.data.description.location.description
            : null,
        },
        links: activityDraft.data.description.links,
        tags,
        coverImage: activityDraft.data.media.coverImage,
        segmentation: activityDraft.data.participants.segmentation,
        requestedInfo,
        cohosts: activityDraft.data.coHosting.cohosts,
        ticketTypes,
        questionsActivated: activityDraft.data.addons.questionsActivated,

        showAttendance,
        showAttendees: showAttendance,
      },
    };

    return _activityDraft;
  };

  export const getActivityDraftMapper = (
    activityDraftDto: V2GetActivityDraft.ActivityDraftDto,
  ): ActivityDraft => {
    const requestedInfoMapper = (
      requestedInfo: V2GetActivityDraft.RequestedInfo[] | null,
    ) => {
      if (!requestedInfo) return null;

      return requestedInfo.map((requestedInfo): RequestedInfo => {
        switch (requestedInfo.type) {
          case RequestedInfoType.FreeText:
            const freetext: ActivityFreeText = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
            };
            return freetext;

          case RequestedInfoType.MultiChoice:
            const multichoiceOptionMapper = (
              option: V2GetActivityDraft.RequestedInfoOption,
            ): ActivityRequestedInfoOption => ({
              id: option.id,
              index: option.index,
              isNew: true,
              label: option.label,
              priceIncrement: option.priceIncrement ?? 0,
            });

            const multichoice: ActivityMultiChoice = {
              id: requestedInfo.id,
              type: requestedInfo.type,
              question: requestedInfo.question,
              isRequired: requestedInfo.isRequired,
              isNew: true,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              index: requestedInfo.index,
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              options: requestedInfo.options.map(multichoiceOptionMapper),
            };
            return multichoice;

          default:
            const checkbox: ActivityCheckbox = {
              enablePriceIncrement: requestedInfo.enablePriceIncrement ?? false,
              id: requestedInfo.id,
              index: requestedInfo.index,
              isNew: true,
              priceIncrement: requestedInfo.priceIncrement ?? 0,
              question: requestedInfo.question,
              ticketTypeKeys: requestedInfo.ticketTypeKeys ?? [],
              type: requestedInfo.type,
            };
            return checkbox;
        }
      });
    };

    const ticketTypeMapper = (
      ticketType: V2GetActivityDraft.TicketType,
    ): TicketType => {
      const discountCodes: DiscountCode[] | null =
        ticketType.discountCodes?.map(
          (discountCode): DiscountCode => ({
            code: discountCode.code,
            discount: discountCode.discount,
            discountCodeKey: discountCode.discountCodeKey || getUID(),
            discountType: 'fixed',
            isNew: true,
          }),
        ) ?? null;

      return {
        description: ticketType.description,
        discountCodes,
        endDate: ticketType.endDate,
        isNew: true,
        isTransferable: ticketType.isTransferable,
        maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
        membershipTypeDiscounts: ticketType.membershipTypeDiscounts ?? null,
        name: ticketType.name,
        price: ticketType.price,
        requiredMembershipTypes: ticketType.requiredMembershipTypes ?? null,
        startDate: ticketType.startDate,
        ticketCount: ticketType.ticketCount,
        ticketTypeKey: ticketType.ticketTypeKey || getUID(),
      };
    };

    const requestedInfo = activityDraftDto.data.requestedInfo
      ? requestedInfoMapper(activityDraftDto.data.requestedInfo)
      : null;

    const tags = activityDraftDto.data.tags?.length
      ? activityDraftDto.data.tags
      : null;

    const ticketTypes =
      (activityDraftDto.data.ticketTypes?.length
        ? activityDraftDto.data.ticketTypes?.map(ticketTypeMapper)
        : null) ?? null;

    const showAttendance =
      (activityDraftDto.data.showAttendance ||
        activityDraftDto.data.showAttendees) ??
      false;

    const _activityDraft: ActivityDraft = {
      key: activityDraftDto.key,
      lastUpdated: activityDraftDto.lastUpdated,
      data: {
        description: {
          categories: activityDraftDto.data.categories ?? [],
          contactDetails: activityDraftDto.data.contactDetails,
          description: activityDraftDto.data.description,
          endDate: activityDraftDto.data.endDate,
          startDate: activityDraftDto.data.startDate,
          title: activityDraftDto.data.title,
          location: activityDraftDto.data.location,
          links: activityDraftDto.data.links,
          tags,
        },
        media: {
          coverImage: base64FileMapper(activityDraftDto.data.coverImage),
        },
        participants: {
          segmentation: activityDraftDto.data.segmentation,
        },
        requestMoreInfo: {
          requestedInfo,
        },
        coHosting: {
          cohosts: activityDraftDto.data.cohosts,
        },
        tickets: {
          ticketTypes,
          showAttendance,
        },
        addons: {
          questionsActivated: activityDraftDto.data.questionsActivated,
        },
      },
    };

    return _activityDraft;
  };
}

function base64FileMapper(
  file: Base64FileDto | Base64File | null,
): Base64File | null {
  if (!file || 'fileSize' in file) {
    return file;
  }

  return { ...file, fileSize: base64FileSize(file.base64) };
}
