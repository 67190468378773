import styled from 'styled-components';

import { Box } from '../box';
import { ControlledCalendar } from '../calendar';

const SheetMenu = styled(Box)`
  list-style-type: none;
  margin-top: 32px;
`;

const StyledCalendar = styled(ControlledCalendar)`
  width: 100%;
  flex-grow: 1;
  margin-top: 32px;
`;

export const Styled = { SheetMenu, StyledCalendar };
