import React from 'react';

import { isNumber } from '../utils';

interface UseArrowsProps {
  minIndex?: number;
  maxIndex?: number;

  disabled?: boolean;

  onToggle?: (index: number) => void;
}

export const ArrowsContext = React.createContext<number>(0);

export function ArrowsProvider(props: React.PropsWithChildren<UseArrowsProps>) {
  const { children, ...rest } = props;

  const activeIndex = useArrows(rest);

  return (
    <ArrowsContext.Provider value={activeIndex}>
      {children}
    </ArrowsContext.Provider>
  );
}

export function useArrows(props: UseArrowsProps) {
  const { minIndex, maxIndex, disabled, onToggle } = props;

  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (disabled) return;

      if (e.key === ' ') {
        e.preventDefault();

        onToggle?.(activeIndex);
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();

        setActiveIndex((prev) => {
          if (isNumber(maxIndex) && prev >= maxIndex) {
            return prev;
          }

          return prev + 1;
        });
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();

        setActiveIndex((prev) => {
          if (isNumber(minIndex) && prev <= minIndex) {
            return prev;
          }

          return prev - 1;
        });
      }
    },
    [maxIndex, minIndex, disabled, onToggle, activeIndex],
  );

  React.useEffect(() => {
    if (isNumber(maxIndex) && activeIndex > maxIndex) {
      setActiveIndex(maxIndex);
    }

    if (isNumber(minIndex) && activeIndex < minIndex) {
      setActiveIndex(minIndex);
    }
  }, [maxIndex, minIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return activeIndex;
}
