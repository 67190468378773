import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './file-uploads.build';
import { initialFileUploadsState } from './file-uploads.initial';

const fileUploadsSlice = createSlice({
  name: 'file-uploads',
  initialState: initialFileUploadsState,
  reducers: {},
  extraReducers,
});

export const fileUploadsReducer = fileUploadsSlice.reducer;
