import * as Sentry from '@sentry/react';

import { assets } from '../../../assets';

function ErrorBoundaryFallback() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'between',
        height: '100vh',
        padding: '32px',
      }}
    >
      <img src={assets.orbiLogoOrange} alt="" />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h1
          style={{
            fontVariationSettings:
              '"opsz" 25, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738',
            fontSize: '25px',
            fontFamily: 'RobotoFlex-Title',
            letterSpacing: '0.25px',
            lineHeight: '32.5px',
            textAlign: 'center',
          }}
        >
          Something went wrong
        </h1>
        <p
          style={{
            fontVariationSettings:
              '"opsz" 20, "GRAD" 0, "slnt" 0, "XTRA" 468, "XOPQ" 96, "YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738',
            fontSize: 16,
            fontFamily: 'RobotoFlex-Body',
            letterSpacing: '0.16px',
            lineHeight: '25.6px',
            textAlign: 'center',
          }}
        >
          We're sorry, but something went wrong. Please try refreshing the page.
        </p>

        <a
          href="mailto:contact@orbiapp.io"
          style={{
            fontVariationSettings:
              '"YOPQ" 79, "YTLC" 514, "YTUC" 712, "YTAS" 750, "YTDE" -203, "YTFI" 738',
            fontSize: '16px',
            fontFamily: 'RobotoFlex-Body',
            letterSpacing: '0.16px',
            lineHeight: '25.6px',
            whiteSpace: 'nowrap',
            color: '#2B6CB0',
          }}
        >
          Contact support
        </a>
      </div>
    </div>
  );
}

export function ErrorBoundary(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}
