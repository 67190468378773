import {
  ToggleIsDisabledSelector,
  toggleIsDisabledThunk,
  useDispatch,
  useSelector,
} from '../store';

export function usePauseOffer(props: {
  offerKey: string | null;
  endDate: number | null;
  startDate: number | null;
}) {
  const { offerKey, endDate, startDate } = props;

  const pauseOfferStatus = useSelector(ToggleIsDisabledSelector.selectStatus);

  const dispatch = useDispatch();

  const now = Date.now();

  const toggleIsDisabled: React.MouseEventHandler<HTMLElement> = (e) => {
    if (!offerKey) return;

    e.preventDefault();

    dispatch(toggleIsDisabledThunk(offerKey));
  };

  const hidden =
    (endDate && now > endDate) || (startDate && startDate > now) || !offerKey;

  return {
    hidden,
    isLoading: pauseOfferStatus === 'pending',
    toggleIsDisabled,
  };
}
