import styled, { css } from 'styled-components';

import { Text } from '../text';

const LengthText = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      transform: scaleY(0);
      transform-origin: top;
      transition: all ${theme.transitions.fast};
      opacity: 0;
    `;
  }}
`;

export const Styled = { LengthText };
