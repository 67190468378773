import { joiResolver } from '@hookform/resolvers/joi';
import {
  Banner,
  Box,
  Button,
  Confirm,
  ConfirmText,
  ContentContainer,
  ControlledInput,
  ControlledTextarea,
  DangerIconButton,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  ResponsiveBox,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getFileFromUrl,
  translate,
  useConfirm,
  useFetch,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { UploadCoverImage, UploadLogo } from '../../../../../components';
import {
  CONTACT_NAME_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  GeneralInfoForm,
  GeneralInfoFormValidation,
  MEMBERSHIP_DESCRIPTION_MAX_LENGTH,
  MEMBERSHIP_NAME_MAX_LENGTH,
  MembershipTypeType,
  PHONE_MAX_LENGTH,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  DeleteMembershipTypeSelector,
  DynamicMembershipLinkSelector,
  MembershipTypeSelector,
  MembershipTypeStatsSelector,
  UpdateGeneralInfoSelector,
  deleteMembershipTypeThunk,
  updateGeneralInfoThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { MembershipCard } from '../../../components/membership-card';
import { InviteMembersButton } from '../../components/invite-members-modal';
import { CombinatonTypeSettingsCard, SettingCard } from '../components';
import { MembershipCharts } from './membership-charts';
import { Styled } from './overview.styled';

function getMembershipTypeSettingsCardSubtitleTx(
  type: MembershipTypeType,
): TxString {
  switch (type) {
    case 'invite_only':
      return 'label.memberships.view-membership.settings-cards.membership-type.invite-only';
    case 'needs_approval':
      return 'label.memberships.view-membership.settings-cards.membership-type.needs-approval';
    case 'standard':
      return 'label.memberships.view-membership.settings-cards.membership-type.standard';
  }
}

function PendingApplicationsBanner() {
  const pendingApplicationCount = useSelector(
    MembershipTypeSelector.selectPendingApplicationCount,
  );

  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );

  const membershipPeriods = useSelector(
    MembershipTypeSelector.selectMembershipPeriods,
  );

  if (!pendingApplicationCount || !membershipPeriods?.length) {
    return null;
  }

  return (
    <Banner
      buttonTo={`/memberships/${membershipTypeKey}/review-applications`}
      buttonTx="label.memberships.view-membership.pending-applications-banner.button"
      buttonTxArgs={{ count: pendingApplicationCount }}
      messageTx="label.memberships.view-membership.pending-applications-banner.subtitle"
      messageTxArgs={{ count: pendingApplicationCount }}
      titleTx="label.memberships.view-membership.pending-applications-banner.title"
      titleTxArgs={{ count: pendingApplicationCount }}
      variant="information"
    />
  );
}

function NoPeriodsBanner() {
  const membershipPeriods = useSelector(
    MembershipTypeSelector.selectMembershipPeriods,
  );
  const membershipTypeStatus = useSelector(MembershipTypeSelector.selectStatus);
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  if (
    membershipTypeStatus === 'pending' ||
    membershipPeriods?.length ||
    isIntegrated
  )
    return null;

  return (
    <Banner
      messageTx="label.memberships.dashboard.no-membership-periods-subtitle"
      titleTx="label.memberships.dashboard.no-membership-periods-title"
      variant="warning"
    />
  );
}

function OverViewMembershipCard() {
  const membershipCardData = useSelector(
    MembershipTypeSelector.selectMembershipCardData,
  );

  return (
    <MembershipCard
      maxWidth="100%"
      backgroundImageUrl={membershipCardData.backgroundImageUrl}
      hideWordmark={membershipCardData.isIntegrated}
      logoUrl={membershipCardData.logoUrl}
      name={membershipCardData.name}
    />
  );
}

function DynamicMembershipLinkGridItem() {
  const dynamicLink = useSelector(
    DynamicMembershipLinkSelector.selectDynamicLink,
  );
  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  const [hasCopied, setHasCopied] = React.useState(false);

  if (!dynamicLink || membershipType === 'invite_only') {
    return null;
  }

  const copyDynamicLinkToClipboard = () => {
    setHasCopied(true);

    navigator.clipboard.writeText(dynamicLink);

    window.setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <Box width="fit-content" flex flexDirection="column">
      <Text
        color="membershipOverviewListItemTitle"
        variant="bodySm"
        tx="label.memberships.view-membership.meta-data.membership-link"
      />
      <Styled.CopyMembershipLinkBox onClick={copyDynamicLinkToClipboard}>
        <Tooltip titleTx="label.general.copy-to-clipboard" placement="top">
          <Text
            cursor="pointer"
            variant="bodySm"
            maxWidth="46ch"
            text={dynamicLink}
          />
        </Tooltip>

        <Icon
          color={
            hasCopied ? 'copyToClipBoardIconActive' : 'copyToClipBoardIcon'
          }
          name="check-circle-solid"
          size={16}
        />
      </Styled.CopyMembershipLinkBox>
    </Box>
  );
}

function MembershipDescriptionAndContactDetails(props: {
  openModal: () => void;
}) {
  const { openModal } = props;

  const description = useSelector(
    MembershipTypeSelector.selectMembershipTypeDescription,
  );
  const contactDetails = useSelector(
    MembershipTypeSelector.selectContactDetails,
  );

  return (
    <Box maxWidth="100%" flex flexDirection="column" gap={16} flexGrow={1}>
      <Box
        width="fit-content"
        maxWidth="100%"
        relative
        flex
        flexDirection="column"
      >
        <Box absolute top={-8} right={0}>
          <IconButton onClick={openModal} icon="pencil-square-outline" />
        </Box>

        <Text
          color="membershipOverviewListItemTitle"
          variant="bodySm"
          tx="label.memberships.view-membership.meta-data.description"
        />

        <Text
          width="65ch"
          maxWidth="100%"
          variant="bodySm"
          overflowWrap="break-word"
          text={description ?? ''}
        />
      </Box>

      <Styled.ContactDetailsGrid>
        <Box width="fit-content" flex flexDirection="column">
          <Text
            color="membershipOverviewListItemTitle"
            variant="bodySm"
            tx="label.memberships.view-membership.meta-data.name"
          />
          <Text
            maxWidth="46ch"
            variant="bodySm"
            text={
              contactDetails?.name ||
              translate('label.memberships.view-membership.meta-data.no-name')
            }
          />
        </Box>

        <Box width="fit-content" flex flexDirection="column">
          <Text
            color="membershipOverviewListItemTitle"
            variant="bodySm"
            tx="label.memberships.view-membership.meta-data.phone"
          />
          <Text
            maxWidth="46ch"
            variant="bodySm"
            text={
              contactDetails?.phone ||
              translate('label.memberships.view-membership.meta-data.no-phone')
            }
          />
        </Box>

        <Box width="fit-content" flex flexDirection="column">
          <Text
            color="membershipOverviewListItemTitle"
            variant="bodySm"
            tx="label.memberships.view-membership.meta-data.email"
          />
          <Text
            variant="bodySm"
            maxWidth="46ch"
            text={
              contactDetails?.email ||
              translate('label.memberships.view-membership.meta-data.no-email')
            }
          />
        </Box>

        <DynamicMembershipLinkGridItem />
      </Styled.ContactDetailsGrid>
    </Box>
  );
}

function EditMembershipMetaDataForm(props: {
  defaultValues: GeneralInfoForm;
  closeModal: () => void;
}) {
  const { closeModal, defaultValues } = props;

  const dispatch = useDispatch();

  const updateGeneralInfoStatus = useSelector(
    UpdateGeneralInfoSelector.selectStatus,
  );

  const formMethods = useForm<GeneralInfoForm>({
    resolver: joiResolver(GeneralInfoFormValidation),
    defaultValues,
  });

  const verifyAndSubmit = formMethods.handleSubmit(
    async (data) => {
      await dispatch(
        updateGeneralInfoThunk({
          backgroundImage: data.backgroundImage,
          logo: data.logo,
          name: data.name,
          description: data.description,
          contactDetails: {
            email: data.contactDetails.email!,
            name: data.contactDetails.name!,
            phone: data.contactDetails.phone,
          },
        }),
      );
      closeModal();
    },
    (err) => {
      Logger.warning('updateMembershipGeneralInfo Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const isLoading = updateGeneralInfoStatus === 'pending';

  return (
    <FormProvider {...formMethods}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.memberships.view-membership.meta-data.modal-header" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <Box flex flexDirection="column" gap={16}>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.general-info.steps.1"
            />

            <ControlledInput
              labelTx="label.memberships.create-membership.name.label"
              maxLength={MEMBERSHIP_NAME_MAX_LENGTH}
              name="name"
              required
            />

            <ControlledTextarea
              labelTx="label.memberships.create-membership.description.label"
              maxLength={MEMBERSHIP_DESCRIPTION_MAX_LENGTH}
              name="description"
              required
            />
          </Box>

          <Box flex flexDirection="column" gap={16}>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.general-info.steps.2"
            />

            <UploadLogo name="logo" />
          </Box>

          <Box flex flexDirection="column" gap={16}>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.general-info.steps.3"
            />

            <UploadCoverImage name="backgroundImage" />
          </Box>

          <Box flex flexDirection="column" gap={16}>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.general-info.steps.4"
            />

            <ControlledInput
              labelTx="label.memberships.create-membership.general-info.contact-name.label"
              maxLength={CONTACT_NAME_MAX_LENGTH}
              name="contactDetails.name"
              required
            />

            <ControlledInput
              labelTx="label.memberships.create-membership.general-info.email.label"
              maxLength={EMAIL_MAX_LENGTH}
              name="contactDetails.email"
              required
              type="email"
            />

            <ControlledInput
              labelTx="label.memberships.create-membership.general-info.phone.label"
              maxLength={PHONE_MAX_LENGTH}
              name="contactDetails.phone"
              type="tel"
            />
          </Box>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button onClick={closeModal} tx="button.close" variant="tertiary" />

          <Button
            isLoading={isLoading}
            onClick={verifyAndSubmit}
            tx="label.memberships.view-membership.settings-cards.membership-type.button"
            variant="primary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </FormProvider>
  );
}

function useDefaultValues() {
  const membershipTypeGeneralInfo = useSelector(
    MembershipTypeSelector.selectGeneralInfo,
  );

  const getDefaultValues =
    React.useCallback(async (): Promise<GeneralInfoForm | null> => {
      if (!membershipTypeGeneralInfo) {
        return null;
      }

      const backgroundImage = membershipTypeGeneralInfo.backgroundImage
        ?.original.url
        ? await getFileFromUrl(
            membershipTypeGeneralInfo.backgroundImage.original.url,
          )
        : null;

      const logo = membershipTypeGeneralInfo.logo?.original.url
        ? await getFileFromUrl(membershipTypeGeneralInfo.logo.original.url)
        : null;

      return {
        contactDetails: membershipTypeGeneralInfo.contactDetails!,
        description: membershipTypeGeneralInfo.description!,
        name: membershipTypeGeneralInfo.name!,
        logo,
        backgroundImage,
      };
    }, [membershipTypeGeneralInfo]);

  const defaultValues = useFetch<GeneralInfoForm>(getDefaultValues);

  return defaultValues;
}

function MembershipMetaData() {
  const { closeModal, isOpen, openModal } = useModalState();

  const defaultValues = useDefaultValues();

  const content = (
    <React.Fragment>
      <OverViewMembershipCard />

      <MembershipDescriptionAndContactDetails openModal={openModal} />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ResponsiveBox
        maxWidth="100%"
        sm={
          <Box maxWidth="100%" flexWrap="wrap" gap={32} flex>
            {content}
          </Box>
        }
      >
        <Box maxWidth="100%" flexDirection="column" gap={32} flex>
          {content}
        </Box>
      </ResponsiveBox>

      {defaultValues && (
        <Modal width={600} isOpen={isOpen} onClose={closeModal}>
          <EditMembershipMetaDataForm
            closeModal={closeModal}
            defaultValues={defaultValues}
          />
        </Modal>
      )}
    </React.Fragment>
  );
}

function ActiveMembersSettingsCard() {
  const activeMembersCount = useSelector(
    MembershipTypeStatsSelector.selectActiveMembersCount,
  );
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );

  if (activeMembersCount === null) {
    return null;
  }

  return (
    <SettingCard
      icon="arrow-right-circle-outline"
      to={`/memberships/${membershipTypeKey}/members`}
      subtitleTx="label.memberships.view-membership.settings-cards.active-members.subtitle"
      subtitleTxArgs={{ count: activeMembersCount.toString() }}
      titleTx="label.memberships.view-membership.settings-cards.active-members.title"
    />
  );
}

function PendingApplicationsSettingsCard() {
  const pendingApplicationCount = useSelector(
    MembershipTypeSelector.selectPendingApplicationCount,
  );

  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );

  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  if (membershipType !== 'needs_approval') {
    return null;
  }

  return (
    <SettingCard
      icon="arrow-right-circle-outline"
      subtitleTx="label.memberships.view-membership.settings-cards.pending-applications.subtitle"
      subtitleTxArgs={{ count: (pendingApplicationCount ?? 0).toString() }}
      titleTx="label.memberships.view-membership.settings-cards.pending-applications.title"
      to={`/memberships/${membershipTypeKey}/review-applications`}
    />
  );
}

function ViewInvitesSettingsCard() {
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );

  const pendingInvitationCount = useSelector(
    MembershipTypeSelector.selectPendingInvitationsCount,
  );

  if (typeof pendingInvitationCount !== 'number') {
    return null;
  }

  return (
    <SettingCard
      icon="arrow-right-circle-outline"
      subtitleTx="label.memberships.view-membership.settings-cards.invites.subtitle"
      subtitleTxArgs={{ count: (pendingInvitationCount ?? 0).toString() }}
      titleTx="label.memberships.view-membership.settings-cards.invites.title"
      to={`/memberships/${membershipTypeKey}/invites`}
    />
  );
}

function MembershipTypeSettingsCard() {
  const membershipTypeType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  if (!membershipTypeType) {
    return null;
  }

  return (
    <SettingCard
      subtitleTx={getMembershipTypeSettingsCardSubtitleTx(membershipTypeType)}
      titleTx="label.memberships.view-membership.settings-cards.membership-type.title"
    />
  );
}

function MembershipSettingCards() {
  return (
    <Styled.SettingCardGrid>
      <MembershipTypeSettingsCard />

      <ViewInvitesSettingsCard />

      <ActiveMembersSettingsCard />

      <CombinatonTypeSettingsCard />

      <PendingApplicationsSettingsCard />
    </Styled.SettingCardGrid>
  );
}

function DeleteMembershipButton() {
  const { isOpen, openConfirm, closeConfirm } = useConfirm();

  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );
  const deleteMembershipTypeStatus = useSelector(
    DeleteMembershipTypeSelector.selectStatus,
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const deleteMembership = async () => {
    if (!membershipTypeKey) return;

    const res = await dispatch(deleteMembershipTypeThunk(membershipTypeKey));

    closeConfirm();

    if (res.meta.requestStatus === 'fulfilled') {
      navigate('/memberships');
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        placement="left"
        titleTx="label.memberships.view-membership.delete-membership"
      >
        <DangerIconButton icon="trash-outline" onClick={openConfirm} />
      </Tooltip>

      <Confirm
        confirmTx="prompt.delete-membership.confirm"
        cancelTx="prompt.delete-membership.cancel"
        isOpen={isOpen}
        onCancel={closeConfirm}
        onConfirm={deleteMembership}
        titleTx="prompt.delete-membership.title"
        isLoading={deleteMembershipTypeStatus === 'pending'}
      >
        <Box flex flexDirection="column" gap={4}>
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-1"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-2"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-3"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-4"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-5"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-6"
          />
          <ConfirmText
            maxWidth="65ch"
            tx="prompt.delete-membership.message-7"
          />
        </Box>
      </Confirm>
    </React.Fragment>
  );
}

export function ViewMembershipOverview() {
  const content = (
    <React.Fragment>
      <MembershipMetaData />

      <MembershipSettingCards />
    </React.Fragment>
  );

  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <Box flex flexJustify="between" flexWrap="wrap" gap={16}>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.view-membership.tabs.overview"
              variant="titleMd"
            />

            <Box flex flexWrap="wrap" flexAlign="center" gap={16}>
              <InviteMembersButton />

              <DeleteMembershipButton />
            </Box>
          </Box>

          <Box flex flexDirection="column" gap={32}>
            <PendingApplicationsBanner />

            <NoPeriodsBanner />

            <ResponsiveBox
              sm={
                <Box flexWrap="wrap" flex gap={32}>
                  {content}
                </Box>
              }
            >
              <Box
                width="100%"
                maxWidth="100%"
                flexDirection="column"
                flex
                gap={32}
              >
                {content}
              </Box>
            </ResponsiveBox>

            <MembershipCharts />
          </Box>
        </InnerContentContainer>
      </ContentContainer>
    </InnerPageContainer>
  );
}
