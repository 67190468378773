import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { pendingInvitationsAdapter, usersAdapter } from './team.adapter';

export namespace DeleteInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.team.deleteInvitation.status;
}

export namespace CreateInvitationsSelector {
  export const selectStatus = (state: RootState) =>
    state.team.createInvitations.status;
}

export namespace DepartmentInvitationSelector {
  const selectSelf = (state: RootState) => state.team.departmentInvitation;

  export const selectStatus = createSelector(
    selectSelf,
    (invitation) => invitation.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (invitation) => invitation.error,
  );

  export const selectData = createSelector(
    selectSelf,
    (invitation) => invitation.data,
  );

  export const selectIsExpired = createSelector(
    [selectData],
    (data) => data && Date.now() > data.expiresAt,
  );

  export const selectIsConsumed = createSelector(
    [selectData],
    (data) => data && data.consumedAt,
  );

  export const selectDepartmentDetails = createSelector(
    [selectData],
    (data) => data?.departmentDetails ?? null,
  );

  export const selectSignUpByInvitationContent = createSelector(
    [
      selectDepartmentDetails,
      selectIsConsumed,
      selectIsExpired,
      selectStatus,
      selectError,
    ],
    (
      departmentDetails,
      isConsumed,
      isExpired,
      teamInvitationStatus,
      error,
    ) => ({
      departmentDetails,
      isConsumed: Boolean(isConsumed),
      isExpired,
      teamInvitationStatus,
      error,
    }),
  );
}

export namespace PendingInvitationsSelector {
  const selectSelf = (state: RootState) => state.team.pendingInvitations;

  export const selectStatus = createSelector(
    selectSelf,
    (pendingInvitations) => pendingInvitations.status,
  );

  const selectData = createSelector(
    selectSelf,
    (pendingInvitations) => pendingInvitations.data,
  );

  const selectors = pendingInvitationsAdapter.getSelectors(selectData);

  export const selectAll = selectors.selectAll;

  export const selectAllEmails = createSelector([selectAll], (pending) =>
    pending.map((invite) => invite.email),
  );
}

export namespace UsersSelector {
  const selectSelf = (state: RootState) => state.team.users;

  export const selectStatus = createSelector(
    selectSelf,
    (users) => users.status,
  );

  const selectData = createSelector(selectSelf, (users) => users.data);

  const selectors = usersAdapter.getSelectors(selectData);

  export const selectAll = selectors.selectAll;

  export const selectCount = selectors.selectTotal;

  export const selectAllEmails = createSelector(selectAll, (users) =>
    users.map((user) => user.email),
  );
}

export namespace RemoveUserSelector {
  export const selectStatus = (state: RootState) =>
    state.team.removeUser.status;
}

export namespace UpdateUserSelector {
  export const selectStatus = (state: RootState) =>
    state.team.updateUser.status;
}
