import { getExternalJobsThunk, hideJobThunk } from './jobs.actions';
import { externalJobsAdapter } from './jobs.adapter';
import { JobBuilder } from './jobs.types';

const hideJobBuild = (builder: JobBuilder) => {
  return builder
    .addCase(hideJobThunk.fulfilled, (state, action) => {
      state.hideJob.status = 'idle';
      externalJobsAdapter.updateOne(state.externalJobs.data, {
        id: action.meta.arg,
        changes: { isHidden: true },
      });
    })
    .addCase(hideJobThunk.pending, (state) => {
      state.hideJob.status = 'pending';
      state.hideJob.error = null;
    })
    .addCase(hideJobThunk.rejected, (state, action) => {
      state.hideJob.status = 'idle';
      state.hideJob.error = action.error;
    });
};

const getExternalJobsBuild = (builder: JobBuilder) => {
  return builder
    .addCase(getExternalJobsThunk.fulfilled, (state, action) => {
      externalJobsAdapter.setAll(state.externalJobs.data, action.payload);
      state.externalJobs.status = 'idle';
    })
    .addCase(getExternalJobsThunk.pending, (state) => {
      state.externalJobs.status = 'pending';
      state.externalJobs.error = null;
    })
    .addCase(getExternalJobsThunk.rejected, (state, action) => {
      state.externalJobs.status = 'idle';
      state.externalJobs.error = action.error;
    });
};

export const extraReducers = (builder: JobBuilder) => {
  hideJobBuild(builder);
  getExternalJobsBuild(builder);
};
