import { Box, Checkbox, Text, isTxString } from '@orbiapp/components';
import React from 'react';

import { segmentationValues } from '../../../../../../models';
import { DepartmentSelector, useSelector } from '../../../../../../store';
import { ActivityFormContext } from '../../create-activity.context';
import { SegmentationPickerSemester } from './segmentation-picker-semester';
import { SegmentationPickerYear } from './segmentation-picker-year';
import { Styled } from './segmentation-picker.styled';

export function SegmentationPicker() {
  const segmentationType = useSelector(
    DepartmentSelector.selectSegmentationType,
  );

  const formContext = React.useContext(ActivityFormContext);

  const setSegmentationValues = (values: number[]) => {
    formContext.setValue('participants.segmentation', values, {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
  };

  const segmentation = formContext.watch('participants.segmentation');

  const getAllSelected = () => {
    if (!segmentation || segmentation.length === 0) return false;

    return segmentationValues.every((value) => {
      return segmentation.includes(value);
    });
  };

  const toggleAll = () =>
    setSegmentationValues(getAllSelected() ? [] : segmentationValues);

  return (
    <Box flex flexDirection="column" gap={16}>
      <Checkbox
        checked={getAllSelected()}
        onChange={toggleAll}
        tx="label.create-activity.participants.target-group.pick-grade-radio-all"
      />

      <Styled.SegmentationButtonGrid
        flex
        flexWrap="wrap"
        gap={10}
        isSemester={segmentationType === 'semester'}
      >
        {segmentationType === 'year' ? (
          <SegmentationPickerYear />
        ) : (
          <SegmentationPickerSemester />
        )}
      </Styled.SegmentationButtonGrid>

      {isTxString(
        formContext.formState.errors.participants?.segmentation?.message,
      ) && (
        <Text
          color="errorLabel"
          tx={formContext.formState.errors.participants?.segmentation?.message}
          variant="bodyMd"
        />
      )}
    </Box>
  );
}
