import React from 'react';

import { useOnClickOutside } from '../../helpers';
import { ReactionType, Reactions } from '../../models';
import { sumRecordValues } from '../../utils/object';
import { Box } from '../box';
import { Icon, IconName } from '../icon';
import { Link } from '../link';
import { useModalState } from '../modal';
import { Text } from '../text';
import { Styled } from './reactions-group.styled';

export interface ReactionsGroupProps {
  reactions: Reactions;
  onReactionsClick?: () => void;

  selectedReaction?: ReactionType | null;
  onSelectedReactionChange?: (reaction: ReactionType | null) => void;
}

interface ReactionsGroupMenuItemProps {
  icon: IconName;
  onClick: () => void;
}

interface ReactionsIconGroupProps {
  reactions: Reactions;
  selectedReaction?: ReactionType | null;
}

interface ReactionsGroupCountProps {
  reactionsCount: number;
  onReactionsClick?: () => void;
}

function getSelectedReactionIcon(
  selectedReaction?: ReactionsGroupProps['selectedReaction'],
): IconName {
  switch (selectedReaction) {
    case 'fire':
      return 'fire';

    case 'party_popper':
      return 'party-popper';

    case 'red_heart':
      return 'heart';

    case 'thumbs_up':
      return 'thumbs-up';

    default:
      return 'thumbs-up';
  }
}

function ReactionsGroupMenuItem(props: ReactionsGroupMenuItemProps) {
  return (
    <Box
      onClick={props.onClick}
      cursor="pointer"
      flexAlign="center"
      flexJustify="center"
      flex
      p={8}
    >
      <Icon name={props.icon} size={20} />
    </Box>
  );
}

type ReactionsGroupIconItem = {
  ml?: number;
  icon: IconName;
  count?: number;
  isSelected?: boolean;
};
const ml = -5;

export function ReactionsGroupItem(props: ReactionsGroupIconItem) {
  return (
    <Styled.ReactionsGroupItem isSelected={props.isSelected} ml={props.ml}>
      <Icon name={props.icon} size={16} />
    </Styled.ReactionsGroupItem>
  );
}

function renderReactionsIconGroupItem(
  item: ReactionsGroupIconItem,
  index: number,
) {
  if (!item.count) {
    return null;
  }

  return (
    <ReactionsGroupItem
      isSelected={item.isSelected}
      ml={item.ml}
      key={index}
      icon={item.icon}
    />
  );
}

function ReactionsIconGroup(props: ReactionsIconGroupProps) {
  const { selectedReaction } = props;
  const { fireCount, thumbsUpCount, redHeartCount, partyPopperCount } =
    props.reactions;

  const items: ReactionsGroupIconItem[] = [
    {
      ml: 0,
      icon: 'fire',
      count: fireCount,
      isSelected: selectedReaction === 'fire',
    },
    {
      ml: fireCount ? ml : 0,
      icon: 'thumbs-up',
      count: thumbsUpCount,
      isSelected: selectedReaction === 'thumbs_up',
    },
    {
      ml: thumbsUpCount || fireCount ? ml : 0,
      icon: 'heart',
      count: redHeartCount,
      isSelected: selectedReaction === 'red_heart',
    },
    {
      ml: redHeartCount || thumbsUpCount || fireCount ? ml : 0,
      icon: 'party-popper',
      count: partyPopperCount,
      isSelected: selectedReaction === 'party_popper',
    },
  ];

  return <Box flex>{items.map(renderReactionsIconGroupItem)}</Box>;
}

function ReactionsGroupCount(props: ReactionsGroupCountProps) {
  if (!props.reactionsCount) {
    return null;
  }

  if (props.onReactionsClick) {
    return <Link small variant="secondary" text={props.reactionsCount} />;
  }

  return (
    <Text lineHeight="unset" variant="bodySm" text={props.reactionsCount} />
  );
}

export function ReactionsGroup(props: ReactionsGroupProps) {
  const reactionsCount = sumRecordValues(props.reactions);

  const modalState = useModalState();

  const clickOutsideRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: clickOutsideRef,
    handler: modalState.closeModal,
    isActive: modalState.isOpen,
  });

  if (!reactionsCount && !props.onSelectedReactionChange) {
    return null;
  }

  const handleReactionChange = () => {
    if (props.selectedReaction) {
      props.onSelectedReactionChange?.(null);
      return;
    }

    modalState.toggleModal();
  };

  const selectReaction = (reaction: ReactionType) => () => {
    props.onSelectedReactionChange?.(reaction);
    modalState.closeModal();
  };

  return (
    <Box flexAlign="center" flex gap={8}>
      {props.onSelectedReactionChange && (
        <Box ref={clickOutsideRef} relative>
          <Styled.SelectReactionButton
            isToggled={!!props.selectedReaction}
            onClick={handleReactionChange}
          >
            <Icon
              name={getSelectedReactionIcon(props.selectedReaction)}
              size={20}
            />
          </Styled.SelectReactionButton>

          <Styled.ReactionsMenu
            backgroundColor="selectReactionMenuBackground"
            absolute
            flex
            isOpen={modalState.isOpen}
            r={16}
            bottom="100%"
            mb={8}
          >
            <ReactionsGroupMenuItem
              icon="fire"
              onClick={selectReaction('fire')}
            />
            <ReactionsGroupMenuItem
              icon="thumbs-up"
              onClick={selectReaction('thumbs_up')}
            />
            <ReactionsGroupMenuItem
              icon="heart"
              onClick={selectReaction('red_heart')}
            />
            <ReactionsGroupMenuItem
              icon="party-popper"
              onClick={selectReaction('party_popper')}
            />
          </Styled.ReactionsMenu>
        </Box>
      )}

      <Box
        flexAlign="center"
        flex
        gap={4}
        onClick={props.onReactionsClick}
        cursor={props.onReactionsClick ? 'pointer' : 'unset'}
      >
        <ReactionsIconGroup
          selectedReaction={props.selectedReaction}
          reactions={props.reactions}
        />

        <ReactionsGroupCount
          onReactionsClick={props.onReactionsClick}
          reactionsCount={reactionsCount}
        />
      </Box>
    </Box>
  );
}
