import { Box, BoxProps } from '../../components';
import { Styled } from './content-container.styled';

export function ContentContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box flex flexDirection="column" flexGrow={1} overflow="hidden" {...rest}>
      {children}
    </Box>
  );
}

export function InnerContentContainer(props: BoxProps) {
  return (
    <Styled.InnerContentContainer
      flex
      flexDirection="column"
      flexGrow={1}
      maxWidth="100%"
      mx="auto"
      width={1920}
      {...props}
    />
  );
}

export function InnerContentContainerAlt(props: BoxProps) {
  return (
    <Styled.InnerContentContainerAlt
      flex
      flexDirection="column"
      flexGrow={1}
      flexAlign="center"
      gap={16}
      backgroundColor="innerContentContainerAlt"
      {...props}
    />
  );
}
