import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { translate } from '../../../i18n';
import { theme } from '../../../theme';
import { Box } from '../../box';
import { Card, CardHeader } from '../../card';
import { Icon } from '../../icon';
import { IconButton } from '../../icon-button';
import { Text } from '../../text';
import '../charts.types';
import { EmptyStateProps } from '../charts.types';
import { colors } from './pie-chart.constants';
import { Styled } from './pie-chart.styled';
import {
  DatasetListItem,
  PieChartBodyProps,
  PieChartProps,
} from './pie-chart.types';

function getOptions(): ChartOptions<'doughnut'> {
  return {
    plugins: {
      tooltip: {
        mode: 'point',
        intersect: false,
        backgroundColor: theme.colors['chartTooltipBackground'],
        titleColor: theme.colors['chartTooltipColor'],
        bodyColor: theme.colors['chartTooltipColor'],
        borderColor: theme.colors['chartTooltipBorder'],
        borderWidth: 1,
        padding: 12,
        titleMarginBottom: 8,
        bodySpacing: 8,
        usePointStyle: true,
      },
      legend: { display: false },
    },
    normalized: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
  };
}

function PieChartEmptyState(props: EmptyStateProps) {
  const {
    placeholderTitle,
    placeholderTitleTx,
    placeholderTitleTxArgs,
    placeholderSubtitle,
    placeholderSubtitleTx,
    placeholderSubtitleTxArgs,
  } = props;

  return (
    <Box
      flex
      flexAlign="center"
      flexGrow={1}
      flexJustify="center"
      flexDirection="column"
      gap={16}
      height="100%"
    >
      <Icon
        size={24}
        name="presentation-chart-bar-outline"
        color="chartEmptyStateIcon"
      />

      <Box flex flexDirection="column" gap={4}>
        {(placeholderTitle || placeholderTitleTx) && (
          <Text
            color="chartEmptyStateText"
            text={placeholderTitle}
            textAlign="center"
            tx={placeholderTitleTx}
            txArgs={placeholderTitleTxArgs}
            variant="bodyMd"
          />
        )}

        {(placeholderSubtitle || placeholderSubtitleTx) && (
          <Text
            color="chartEmptyStateText"
            text={placeholderSubtitle}
            textAlign="center"
            tx={placeholderSubtitleTx}
            txArgs={placeholderSubtitleTxArgs}
            variant="bodySm"
          />
        )}
      </Box>
    </Box>
  );
}

function ChartBody(props: PieChartBodyProps) {
  const {
    datasets,
    labels,

    placeholderTitle,
    placeholderTitleTx,
    placeholderTitleTxArgs,

    placeholderSubtitle,
    placeholderSubtitleTx,
    placeholderSubtitleTxArgs,
  } = props;

  const isEmpty = datasets.every((dataset) =>
    dataset.data.every((value) => value === 0),
  );

  if (isEmpty) {
    return (
      <PieChartEmptyState
        placeholderTitle={placeholderTitle}
        placeholderTitleTx={placeholderTitleTx}
        placeholderTitleTxArgs={placeholderTitleTxArgs}
        placeholderSubtitle={placeholderSubtitle}
        placeholderSubtitleTx={placeholderSubtitleTx}
        placeholderSubtitleTxArgs={placeholderSubtitleTxArgs}
      />
    );
  }

  const data: React.ComponentProps<typeof Doughnut>['data'] = {
    labels,
    datasets: datasets.map((dataset) => ({
      backgroundColor: colors,
      borderWidth: 1,
      data: dataset.data,
      label: dataset.labelTx
        ? translate(dataset.labelTx, dataset.labelTxArgs ?? {}).toString()
        : dataset.label,
    })),
  };

  return (
    <Doughnut data={data} height={240} options={getOptions()} width={240} />
  );
}

function renderListItem(listItem: DatasetListItem, index: number) {
  const { label, labelTx, value } = listItem;

  return (
    <Box
      flex
      flexAlign="center"
      flexJustify="between"
      width="100%"
      gap={8}
      key={`chart-back-list-item-${index}`}
      overflow="hidden"
    >
      <Box
        overflow="hidden"
        flexGrow={1}
        width="100%"
        flex
        gap={8}
        flexAlign="center"
      >
        <Styled.BackgroundColorBox index={index} />

        <Text
          overflow="hidden"
          text={label}
          textOverflow="ellipsis"
          tx={labelTx}
          variant="bodySm"
          whiteSpace="nowrap"
        />
      </Box>

      <Text whiteSpace="nowrap" text={value} variant="bodySm" />
    </Box>
  );
}

export function PieChart(props: PieChartProps) {
  const {
    actionElement,
    datasets,
    listItems,
    labels,

    avatarFallbackLetter,
    avatarSrc,
    avatarVariant,

    subtitle,
    subtitleText,
    subtitleTx,
    subtitleTxArgs,

    description,
    descriptionTx,
    descriptionTxArgs,

    title,
    titleTx,
    titleTxArgs,

    placeholderTitle,
    placeholderTitleTx,
    placeholderTitleTxArgs,

    placeholderSubtitle,
    placeholderSubtitleTx,
    placeholderSubtitleTxArgs,

    statusVariant,
    statusVariantText,
    statusVariantTextVariant,
    statusVariantTx,
    statusVariantTxArgs,

    unit,
    unitPosition,
    unitTx,

    ...rest
  } = props;

  const [isFlipped, setIsFlipped] = React.useState(false);

  const toggleIsFlipped = () => setIsFlipped((isFlipped) => !isFlipped);

  const pieChartCardHeader = (
    <CardHeader
      avatarFallbackLetter={avatarFallbackLetter}
      avatarSrc={avatarSrc}
      avatarVariant={avatarVariant}
      actionElement={actionElement}
      description={description}
      descriptionTx={descriptionTx}
      descriptionTxArgs={descriptionTxArgs}
      title={title}
      titleTx={titleTx}
      titleTxArgs={titleTxArgs}
      statusVariant={statusVariant}
      statusVariantText={statusVariantText}
      statusVariantTextVariant={statusVariantTextVariant}
      statusVariantTx={statusVariantTx}
      statusVariantTxArgs={statusVariantTxArgs}
      subtitle={subtitle}
      subtitleText={subtitleText}
      subtitleTx={subtitleTx}
      subtitleTxArgs={subtitleTxArgs}
      unit={unit}
      unitPosition={unitPosition}
      unitTx={unitTx}
    />
  );

  return (
    <Card backgroundColor="chartBackground" relative {...rest}>
      {pieChartCardHeader}

      <Box mx="auto" height={240}>
        <ChartBody
          datasets={datasets}
          listItems={listItems}
          labels={labels}
          placeholderTitle={placeholderTitle}
          placeholderTitleTx={placeholderTitleTx}
          placeholderTitleTxArgs={placeholderTitleTxArgs}
          placeholderSubtitle={placeholderSubtitle}
          placeholderSubtitleTx={placeholderSubtitleTx}
          placeholderSubtitleTxArgs={placeholderSubtitleTxArgs}
        />
      </Box>

      {listItems && listItems.length > 0 && (
        <React.Fragment>
          <Styled.ChartBack
            overflow="auto"
            left={0}
            absolute
            isFlipped={isFlipped}
          >
            <Box height="100%" gap={8} flex flexDirection="column" p={16}>
              {pieChartCardHeader}

              {listItems.map(renderListItem)}
            </Box>
          </Styled.ChartBack>

          <Box absolute bottom={16} right={16}>
            <IconButton
              icon={isFlipped ? 'chevron-up' : 'chevron-down'}
              isActive={isFlipped}
              onClick={toggleIsFlipped}
            />
          </Box>
        </React.Fragment>
      )}
    </Card>
  );
}
