import { Box, Button, Text } from '@orbiapp/components';
import React from 'react';
import { useLocation, useRouteError } from 'react-router-dom';

import { Logger } from '../../../services';
import { Styled } from './not-found.styled';

function getRouteErrorMessage(err: unknown): string {
  if (err instanceof Error) {
    return err.message;
  }

  if (typeof err === 'string') {
    return err;
  }

  return 'Unknown error';
}

export const NotFound = (props: { continueTo?: string }) => {
  const { continueTo } = props;

  const location = useLocation();

  const hasLogged = React.useRef(false);

  const routeError = useRouteError();

  React.useEffect(() => {
    if (hasLogged.current) return;

    Logger.error('NotFound', {
      message: getRouteErrorMessage(routeError),
      pathname: location.pathname,
    });

    hasLogged.current = true;
  }, [hasLogged, location.pathname, routeError]);

  return (
    <Styled.NotFound
      backgroundColor="notFoundPageBackground"
      fixed
      flex
      flexJustify="center"
      height="100%"
      width="100%"
      left={0}
      top={0}
      zIndex={1000}
    >
      <Styled.NotFoundContent
        flex
        flexDirection="column"
        gap={24}
        p={48}
        r={10}
      >
        <Box flex flexDirection="column" gap={8}>
          <Text maxWidth="50ch" tx="label.not-found.title" variant="titleMd" />
          <Text
            maxWidth="50ch"
            tx="label.not-found.subtitle"
            variant="bodyMd"
          />
        </Box>

        <Button
          tx="button.continue"
          to={continueTo ?? '/'}
          variant="primary"
          icon="arrow-right-circle-outline"
          iconPlacement="right"
        />
      </Styled.NotFoundContent>
    </Styled.NotFound>
  );
};
