import { joiResolver } from '@hookform/resolvers/joi';
import { FormBox, InnerContentContainerAlt } from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import { NavBlocker } from '../../../../components';
import { UpdateOfferForm, UpdateOfferValidation } from '../../../../models';
import {
  ContactDetails,
  Countries,
  DiscountCodes,
  EndDate,
  GeneralInformation,
  Locations,
  OfferCategories,
  RequiredMemberships,
  StudentVerification,
  TypeOfOffer,
} from '../components';
import { OfferFormHeader } from './offer-form-header';

export function OfferForm(props: { defaultValues: UpdateOfferForm }) {
  const { defaultValues } = props;

  const formMethods = useForm<UpdateOfferForm>({
    defaultValues,
    resolver: joiResolver(UpdateOfferValidation),
  });

  return (
    <FormProvider {...formMethods}>
      <InnerContentContainerAlt>
        <NavBlocker shouldBlock={formMethods.formState.isDirty} />

        <OfferFormHeader />

        <FormBox>
          <GeneralInformation />

          <ContactDetails />

          <OfferCategories />

          <Countries />

          <TypeOfOffer />

          <DiscountCodes />

          <Locations />

          <StudentVerification />

          <EndDate />

          <RequiredMemberships />
        </FormBox>
      </InnerContentContainerAlt>
    </FormProvider>
  );
}
