import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  OptionProps,
  Radio,
  Select,
  Text,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateOfferForm } from '../../../../../models';

const CONSUME_WAIT_MINUTE_OPTIONS: OptionProps[] = [
  {
    value: '0',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.no-cooldown',
  },
  {
    value: '1',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.1min',
  },
  {
    value: '10',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.10min',
  },
  {
    value: '60',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.1h',
  },
  {
    value: '240',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.4h',
  },
  {
    value: '720',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.12h',
  },
  {
    value: '1440',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.1d',
  },
  {
    value: '4320',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.3d',
  },
  {
    value: '10080',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.7d',
  },
  {
    value: '43200',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.30d',
  },
  {
    value: '525600',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.365d',
  },
  {
    value: 'null',
    tx: 'label.offer-form.consume-wait-minutes.dropdown.none',
  },
];

export function TypeOfOffer() {
  const formMethods = useFormContext<CreateOfferForm>();
  const [isConsumable, consumeWaitMinutes] = formMethods.watch([
    'isConsumable',
    'consumeWaitMinutes',
  ]);

  const setIsConsumable = () => {
    const options = {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    };
    formMethods.setValue('isConsumable', true, options);
    formMethods.setValue('files', null, options);
    formMethods.setValue('code', null, options);
    formMethods.setValue('link', null, options);
    formMethods.setValue('consumeWaitMinutes', 0, options);
  };

  const setIsOnline = () => {
    const options = {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    };
    formMethods.setValue('isConsumable', false, options);
    formMethods.setValue('consumeWaitMinutes', null, options);
  };

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    let value: CreateOfferForm['consumeWaitMinutes'] = Number(e.target.value);
    if (isNaN(value)) {
      value = null;
    }

    formMethods.setValue('consumeWaitMinutes', value, {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });
  };

  const value = `${consumeWaitMinutes}`;

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.type-of-offer"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: 5 }}
        />
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <Box flex gap={16}>
          <Radio
            checked={!isConsumable}
            dotPlacement="left"
            onChange={setIsOnline}
            tx="label.offer-form.type-of-offer.online.label"
            subtitleTx="label.offer-form.type-of-offer.online.description"
          />

          <Radio
            checked={isConsumable}
            dotPlacement="left"
            onChange={setIsConsumable}
            tx="label.offer-form.type-of-offer.local.label"
            subtitleTx="label.offer-form.type-of-offer.local.description"
          />
        </Box>

        {isConsumable ? (
          <Box flex flexDirection="column" gap={8}>
            <Select
              value={value}
              onChange={onChange}
              labelTx="label.offer-form.consume-wait-minutes.label"
              options={CONSUME_WAIT_MINUTE_OPTIONS}
              invertMenu
            />
            <Text
              tx="label.offer-form.consume-wait-minutes.description"
              variant="bodyMd"
              color="formSectionDescription"
            />
          </Box>
        ) : (
          <ControlledInput labelTx="label.offer-form.link" name="link" />
        )}
      </Box>
    </FormSection>
  );
}
