import React from 'react';
import { useController } from 'react-hook-form';

import { useCombinedRefs } from '../../helpers';
import { translate } from '../../i18n';
import { isTxString } from '../../utils';
import { Box } from '../box';
import { InputElementBox } from '../input-element-box';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import {
  TEXTAREA_MIN_WIDTH,
  TEXTAREA_X_PADDING,
  TEXTAREA_Y_PADDING,
} from './textarea.constants';
import { Styled } from './textarea.styled';
import { ControlledTextareaProps, TextareaProps } from './textarea.types';

export const ControlledTextarea = React.forwardRef(
  (
    props: ControlledTextareaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const {
      defaultValue,
      deps,
      disabled,
      maxLength,
      name,
      onBlur,
      onChange,
      ...rest
    } = props;

    const controller = useController({
      defaultValue,
      name,
      rules: { deps, maxLength },
    });

    const onChangeHandler: React.ChangeEventHandler<HTMLTextAreaElement> = (
      e,
    ) => {
      controller.field.onChange(e);
      onChange?.(e);
    };

    const onBlurHandler: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
      controller.field.onBlur();
      onBlur?.(e);
    };

    const errorTx = isTxString(controller.fieldState.error?.message)
      ? controller.fieldState.error?.message
      : undefined;

    const combinedRefs = useCombinedRefs(ref, controller.field.ref);

    return (
      <Textarea
        currentLength={controller.field.value?.length}
        disabled={disabled}
        errorTx={errorTx}
        maxLength={maxLength}
        name={controller.field.name}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        ref={combinedRefs}
        value={controller.field.value ?? ''}
        {...rest}
      />
    );
  },
);

function useTextarea(props: {
  maxLength?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
}) {
  const { maxLength, onChange, onBlur, onFocus } = props;

  const handleFocus: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onFocus?.(e);
  };

  const handleBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    onBlur?.(e);
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }

    const length = e.target.value.length;

    if (maxLength === undefined) {
      onChange?.(e);
      return;
    }

    if (length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
      onChange?.(e);
      return;
    }

    onChange?.(e);
  };

  return { handleBlur, handleChange, handleFocus };
}

function _Textarea(
  props: TextareaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const {
    autoFocus,
    currentLength,
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    label,
    labelTx,
    labelTxArgs,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    trailingElements,
    value,
    tinySelect,
    ...rest
  } = props;

  const hasError = !!error || !!errorTx;

  const textareaBoxRef = React.useRef<HTMLDivElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const inputHelperRef = React.useRef<HTMLDivElement>(null);

  const combinedRefs = useCombinedRefs(ref, textareaRef);

  const { handleBlur, handleChange, handleFocus } = useTextarea({
    maxLength,
    onBlur,
    onChange,
    onFocus,
  });

  React.useEffect(() => {
    if (!textareaRef.current) return;

    const observer = new ResizeObserver(() => {
      const width = textareaRef.current?.style.getPropertyValue('width');
      if (width === undefined || !textareaBoxRef.current) return;

      const pxWidth = parseInt(width.replace('px', ''));

      textareaBoxRef.current.style.width =
        pxWidth <= TEXTAREA_MIN_WIDTH ? `${TEXTAREA_MIN_WIDTH}px` : width;
    });

    observer.observe(textareaRef.current);

    return () => {
      observer.disconnect();
    };
  }, [textareaRef, textareaBoxRef]);

  React.useEffect(() => {
    const current = textareaRef.current;
    if (!current) return;

    if (autoFocus) {
      current.focus();
      current.setSelectionRange(current.value.length, current.value.length);
    }
  }, [autoFocus, textareaRef]);

  return (
    <Styled.TextareaBox
      maxWidth="100%"
      floatLabel={!!value}
      ref={textareaBoxRef}
      relative
    >
      <Box relative>
        <Styled.TextareaBoxLabelBox
          floatLabel={!!value || !!placeholderTx || !!placeholder}
          relative
        >
          {!tinySelect && (
            <InputLabel
              disabled={disabled}
              hasError={hasError}
              text={label}
              tx={labelTx}
              txArgs={labelTxArgs}
            />
          )}

          {tinySelect && (
            <Box
              top={TEXTAREA_Y_PADDING}
              left={TEXTAREA_X_PADDING}
              width="fit-content"
              absolute
            >
              {tinySelect}
            </Box>
          )}

          <Styled.Textarea
            autoFocus={autoFocus}
            hasTinySelect={!!tinySelect}
            hasTrailingElement={Boolean(trailingElements?.length)}
            disabled={disabled}
            hasError={hasError}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={combinedRefs}
            value={value}
            placeholder={
              placeholderTx
                ? translate(placeholderTx, placeholderTxArgs ?? {})
                : placeholder
            }
            {...rest}
          />
        </Styled.TextareaBoxLabelBox>

        <InputElementBox textarea items={trailingElements} />
      </Box>

      <InputHelper
        currentLength={currentLength}
        disabled={disabled}
        hasError={hasError}
        helperText={error || helperText}
        helperTx={errorTx || helperTx}
        helperTxArgs={errorTxArgs || helperTxArgs}
        maxLength={maxLength}
        pl={TEXTAREA_X_PADDING}
        ref={inputHelperRef}
      />
    </Styled.TextareaBox>
  );
}

export const Textarea = React.forwardRef(_Textarea);
