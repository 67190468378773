import { Text } from '@orbiapp/components';
import styled from 'styled-components';

const ReferAFriend = styled(Text)`
  text-decoration: underline;
`;

export const Styled = {
  ReferAFriend,
};
