import { sumOver } from '@orbiapp/components';
import Joi from 'joi';

import { getActivityDescriptionMaxLength } from '../../utils';
import { ContactDetailsValidation } from '../contact-details';
import { CoverImageValidation } from '../file';
import { BoolSchema } from '../shared';
import {
  LINK_LABEL_MAX_LENGTH,
  LINK_MAX_LENGTH,
  MAX_NUMBER_OF_ACTIVITY_CATEGORIES,
  MIN_NUMBER_OF_ACTIVITY_CATEGORIES,
  TAGS,
  TAG_REGEXP,
  TITLE_MAX_LENGTH,
} from './activity.constants';
import { ActivityErrors, LinkErrors } from './activity.errors';
import { CreateActivityForm } from './activity.model';
import { ActivityLocationSchema } from './location';
import { RequestedInfo, RequestedInfoItemValidation } from './requested-info';
import {
  CreateTicketTypeValidation,
  MinTicketCounts,
  TICKET_MAX_COUNT,
  TicketType,
  UpdateTicketTypeValidation,
} from './ticket-types';

namespace ActivityUtils {
  export const requestedInfo = (requestedInfoItems: RequestedInfo[]) => {
    if (requestedInfoItems.length === 0) return null;

    return requestedInfoItems;
  };

  export const ticketTypes = (
    ticketTypes: TicketType[],
    helpers: Joi.CustomHelpers<CreateActivityForm>,
  ) => {
    const totalTicketCount = sumOver(ticketTypes, 'ticketCount');

    if (totalTicketCount > TICKET_MAX_COUNT) {
      return helpers.error(
        ActivityErrors.customErrors.ticketCountGreaterThanMax,
      );
    }

    return ticketTypes;
  };

  export const tags = (
    tags: string[],
    helpers: Joi.CustomHelpers<CreateActivityForm>,
  ) => {
    const hasDuplicates = new Set(tags).size !== tags.length;

    if (hasDuplicates) {
      return helpers.error('string.duplicate');
    }

    return tags;
  };
}

export namespace LinkSchema {
  export const url = Joi.string()
    .uri()
    .max(LINK_MAX_LENGTH)
    .required()
    .messages(LinkErrors.url);

  export const label = Joi.string()
    .trim()
    .max(LINK_LABEL_MAX_LENGTH)
    .required()
    .messages(LinkErrors.label);
}

export namespace ActivitySchema {
  export const activityKey = Joi.string().required();

  export const contactDetails = ContactDetailsValidation.required();

  export const coverImage = CoverImageValidation.required().messages(
    ActivityErrors.coverImage,
  );

  export const description = (options: { enableTags: boolean }) =>
    Joi.string()
      .trim()
      .max(getActivityDescriptionMaxLength(options.enableTags))
      .required()
      .messages(ActivityErrors.description);

  export const links = Joi.array()
    .items(Joi.object({ url: LinkSchema.url, label: LinkSchema.label }))
    .allow(null)
    .required();

  export const location = ActivityLocationSchema.required();

  export const questionsActivated = BoolSchema.required();

  export const segmentation = Joi.array()
    .min(1)
    .max(12)
    .items(Joi.number().min(1).max(12).integer().required())
    .required()
    .messages(ActivityErrors.segmentation);

  export const title = Joi.string()
    .trim()
    .max(TITLE_MAX_LENGTH)
    .required()
    .messages(ActivityErrors.title);

  export const showAttendance = BoolSchema.required();

  export const endDate = Joi.number()
    .min(Joi.ref('startDate'))
    .required()
    .messages(ActivityErrors.endDate);

  export const startDate = Joi.number()
    .required()
    .messages(ActivityErrors.startDate);

  export const updateStartdate = Joi.number().required();

  export const requestedInfo = Joi.array()
    .items(RequestedInfoItemValidation)
    .custom(ActivityUtils.requestedInfo)
    .allow(null)
    .required();

  export const ticketTypes = Joi.array()
    .items(CreateTicketTypeValidation)
    .custom(ActivityUtils.ticketTypes)
    .allow(null)
    .required()
    .messages(ActivityErrors.ticketTypes);

  export const tags = Joi.array()
    .items(Joi.string().regex(TAG_REGEXP))
    .custom(ActivityUtils.tags)
    .min(1)
    .max(TAGS.length)
    .allow(null)
    .messages(ActivityErrors.tags)
    .required();

  export const updateTicketType = (options: {
    minTicketCounts: MinTicketCounts;
  }) =>
    Joi.array()
      .items(UpdateTicketTypeValidation(options))
      .allow(null)
      .required()
      .messages(ActivityErrors.updateTicketType);

  export const categories = Joi.array()
    .min(MIN_NUMBER_OF_ACTIVITY_CATEGORIES)
    .max(MAX_NUMBER_OF_ACTIVITY_CATEGORIES)
    .required()
    .messages(ActivityErrors.categories);
}
