export const clientProblems = [
  'bad-request',
  'unauthorized',
  'forbidden',
  'not-found',
  'rejected',
  'too-many-requests',
] as const;

export type ClientProblem<T> = {
  kind: (typeof clientProblems)[number];
  message?: string | T;
};

type ServerProblem =
  | { kind: 'timeout'; temporary: true }
  | { kind: 'cannot-connect'; temporary: true }
  | { kind: 'server' }
  | { kind: 'unknown'; temporary: true }
  | { kind: 'bad-data' }
  | { kind: 'cancelled' };

export type GeneralApiProblem<T = undefined> = ClientProblem<T> | ServerProblem;

export type GeneralApiResult<T = undefined> =
  | { kind: 'ok' }
  | GeneralApiProblem<T>;

export type GeneralApiResultWithData<D, T = undefined> =
  | { kind: 'ok'; data: D }
  | GeneralApiProblem<T>;

export type GetPageAndNextPage<DataType> = [
  GeneralApiResultWithData<DataType[]>,
  GeneralApiResultWithData<DataType[]>,
];
