import React from 'react';
import { useController } from 'react-hook-form';

import { useCombinedRefs, usePasswordInput } from '../../helpers';
import { isTxString } from '../../utils';
import { Input } from '../input';
import { getPasswordStrength } from '../password-strength';
import {
  ControlledPasswordInputProps,
  PasswordInputProps,
} from './password-input.types';

function _ControlledPasswordInput(
  props: ControlledPasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    errorTxArgs,
    defaultValue,
    deps,
    disabled,
    name,
    onBlur,
    onChange,
    onClear,
    minCharacters,
    minNumbers,
    minSpecialCharacters,
    ...rest
  } = props;

  const controller = useController({
    defaultValue,
    name,
    rules: { deps },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    controller.field.onChange(e);
    onChange?.(e);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const onClearHandler = () => {
    onClear?.();
    controller.field.onChange({ target: { value: '' } });
  };

  const value = controller.field.value ?? '';

  const strength = getPasswordStrength(value, {
    minCharacters,
    minNumbers,
    minSpecialCharacters,
  });

  const _errorTxArgs = {
    ...errorTxArgs,
    count:
      strength.length < minCharacters
        ? minCharacters
        : strength.numbers < minNumbers
        ? minNumbers
        : strength.specialCharacters < minSpecialCharacters
        ? minSpecialCharacters
        : 0,
  };

  const errorTx = isTxString(controller.fieldState.error?.message, _errorTxArgs)
    ? controller.fieldState.error?.message
    : undefined;

  return (
    <PasswordInput
      disabled={disabled}
      errorTx={errorTx}
      errorTxArgs={_errorTxArgs}
      minCharacters={minCharacters}
      minNumbers={minNumbers}
      minSpecialCharacters={minSpecialCharacters}
      name={controller.field.name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onClear={onClearHandler}
      ref={combinedRefs}
      value={value}
      {...rest}
    />
  );
}

function _PasswordInput(
  props: PasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    minCharacters,
    minNumbers,
    minSpecialCharacters,

    value,
    disabled,
    onClear,
    ...rest
  } = props;

  const { inputType, toggleShowPassword, icon } = usePasswordInput();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(ref, inputRef);

  const handleClear = () => {
    onClear?.();
    inputRef.current?.focus();
  };

  const toggleInputType = () => {
    toggleShowPassword();
    inputRef.current?.focus();
  };

  return (
    <Input
      value={value}
      type={inputType}
      ref={combinedRefs}
      passwordStrengthOptions={{
        minCharacters,
        minNumbers,
        minSpecialCharacters,
      }}
      trailingElements={[
        {
          type: 'button',
          icon,
          onClick: toggleInputType,
          disabled,
        },
        {
          disabled,
          type: 'button',
          icon: 'x-circle-outline',
          onClick: handleClear,
          hidden: !value || !onClear,
        },
      ]}
      {...rest}
    />
  );
}

export const ControlledPasswordInput = React.forwardRef(
  _ControlledPasswordInput,
);
export const PasswordInput = React.forwardRef(_PasswordInput);
