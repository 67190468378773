import { Box, BoxProps } from '../box';
import { Text, TxArgs } from '../text';
import { Styled } from './form-section.styled';

export function FormSection(props: BoxProps) {
  return (
    <Styled.FormSection
      backgroundColor="formSectionBackground"
      flex
      flexDirection="column"
      gap={24}
      p={16}
      r={8}
      {...props}
    />
  );
}

interface FormSectionHeaderProps extends BoxProps {
  header?: string;
  headerTx?: TxString;
  headerTxArgs?: TxArgs;
  description?: string;
  descriptionTx?: TxString;
  descriptionTxArgs?: TxArgs;
}

export function FormSectionHeader(props: FormSectionHeaderProps) {
  const {
    children,
    header,
    headerTx,
    headerTxArgs,
    description,
    descriptionTx,
    descriptionTxArgs,
    ...rest
  } = props;

  const hasHeader = header || headerTx;
  const hasDescription = description || descriptionTx;

  return (
    <Box flex flexDirection="column" gap={4} {...rest}>
      {(hasHeader || hasDescription) && (
        <Box flex flexDirection="column" gap={4}>
          {hasHeader && (
            <Text
              text={header}
              tx={headerTx}
              txArgs={headerTxArgs}
              height={21}
              variant="bodyMdBold"
              color="formSectionHeader"
            />
          )}

          {hasDescription && (
            <Text
              text={description}
              tx={descriptionTx}
              txArgs={descriptionTxArgs}
              height={21}
              variant="bodyMd"
              color="formSectionDescription"
            />
          )}
        </Box>
      )}
      {children}
    </Box>
  );
}

export function FormSectionHeaderRow(props: BoxProps) {
  return (
    <Box
      flex
      flexDirection="row"
      flexAlign="start"
      flexJustify="between"
      gap={16}
      {...props}
    />
  );
}
