import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateReactionParams,
  DeleteReactionParams,
  GetReactionActorsPageParams,
  Page,
  PartialActorWithReaction,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import { postsActions } from '../posts';
import { ThunkApiConfig } from '../store.types';

export const createReactionThunk = createAsyncThunk<
  void,
  CreateReactionParams,
  ThunkApiConfig
>('reactions/create', async (params, thunkAPI) => {
  switch (params.target.type) {
    case 'post':
      thunkAPI.dispatch(postsActions.updatePostReactions(params));
      break;

    case 'post_comment':
      thunkAPI.dispatch(postsActions.updatePostCommentReactions(params));
      break;
  }

  const res = await OrbiApi.call(v1.reactions.createReaction, params);

  if (res.kind === 'ok') return;

  return thunkAPI.rejectWithValue(res);
});

export const deleteReactionThunk = createAsyncThunk<
  void,
  DeleteReactionParams,
  ThunkApiConfig
>('reactions/delete', async (params, thunkAPI) => {
  switch (params.target.type) {
    case 'post':
      thunkAPI.dispatch(postsActions.updatePostReactions(params));
      break;

    case 'post_comment':
      thunkAPI.dispatch(postsActions.updatePostCommentReactions(params));
      break;
  }

  const res = await OrbiApi.call(v1.reactions.deleteReaction, params);

  if (res.kind === 'ok') return;

  return thunkAPI.rejectWithValue(res);
});

export const getReactionsThunk = createAsyncThunk<
  Page<PartialActorWithReaction>,
  GetReactionActorsPageParams,
  ThunkApiConfig
>('reactions/get-many', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.reactions.getReactions, params);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
