import { db, newTransaction } from '../cache';

const upsert = <T>(payload: T): Promise<string | null> => {
  return new Promise(async (resolve, reject) => {
    const store = newTransaction('activity-draft', 'readwrite');
    if (!(db && store)) return null;

    const query = store.put(payload);
    query.onsuccess = (e) => resolve((e.target as IDBRequest).result);
    query.onerror = () => reject(null);
  });
};

const get = <T>(key: string): Promise<T | null> => {
  return new Promise(async (resolve, reject) => {
    const store = newTransaction('activity-draft', 'readonly');
    if (!(db && store)) return null;

    const query = store.get(key);
    query.onsuccess = (e) => resolve((e.target as IDBRequest).result);
    query.onerror = () => reject(null);
  });
};

const getAll = <T>(): Promise<T[] | null> => {
  return new Promise(async (resolve, reject) => {
    const store = newTransaction('activity-draft', 'readonly');
    if (!(db && store)) return null;

    const query = store.getAll();
    query.onsuccess = (e) => resolve((e.target as IDBRequest).result);
    query.onerror = () => reject(null);
  });
};

const remove = (key: string): Promise<string | null> => {
  return new Promise(async (resolve, reject) => {
    const store = newTransaction('activity-draft', 'readwrite');
    if (!(db && store)) return null;

    const query = store.delete(key);
    query.onsuccess = () => resolve(key);
    query.onerror = () => reject(null);
  });
};

export const activityDraftCache = { upsert, get, getAll, remove };
