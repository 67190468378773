import Joi from 'joi';

import { BoolSchema, MIN_PRICE } from '../../../shared';
import { ActivityForm } from '../../activity.model';
import { TicketType } from '../ticket-types.model';
import {
  DISCOUNT_CODE_MAX_LENGTH,
  DISCOUNT_CODE_REGEXP,
} from './discount-code.constants';
import { DiscountCodeErrors } from './discount-code.errors';
import { DiscountCode } from './discount-code.model';

namespace DiscountCodeUtils {
  export const code = (
    discountCode: string,
    helpers: Joi.CustomHelpers<ActivityForm>,
  ) => {
    const discountCodes: string[] = helpers.state.ancestors[1].map(
      ({ code }: DiscountCode) => code,
    );

    discountCodes.splice(discountCodes.indexOf(discountCode), 1);

    if (discountCodes.includes(discountCode)) {
      return helpers.error(DiscountCodeErrors.customErrors.codeNotUnique);
    }

    return discountCode;
  };

  export const discount = (
    discount: number,
    helpers: Joi.CustomHelpers<ActivityForm>,
  ) => {
    const ancestors = helpers.state.ancestors;
    const ticketType: TicketType = ancestors[2];
    const newPrice = ticketType.price - discount;

    if (newPrice < 0) {
      return helpers.error(DiscountCodeErrors.customErrors.priceBelowZero);
    }

    if (newPrice > 0 && newPrice < MIN_PRICE) {
      return helpers.error(DiscountCodeErrors.customErrors.priceBelowMin);
    }

    return discount;
  };

  export const addDiscountCode =
    (options: { ticketPrice: number }) =>
    (discount: number, helpers: Joi.CustomHelpers<ActivityForm>) => {
      const newPrice = options.ticketPrice - discount;

      if (newPrice < 0) {
        return helpers.error(DiscountCodeErrors.customErrors.priceBelowZero);
      }

      if (newPrice > 0 && newPrice < MIN_PRICE) {
        return helpers.error(DiscountCodeErrors.customErrors.priceBelowMin);
      }

      return discount;
    };
}

namespace BaseDiscountCodeSchema {
  export const discountCodeKey = Joi.string().allow(null).optional();

  export const discountType = Joi.string()
    .valid('fixed')
    .required()
    .messages(DiscountCodeErrors.type);
}

export namespace AddDiscountCodeSchema {
  export const discountCodeKey = BaseDiscountCodeSchema.discountCodeKey;
  export const discountType = BaseDiscountCodeSchema.discountType;

  export const code = Joi.string()
    .required()
    .min(5)
    .max(DISCOUNT_CODE_MAX_LENGTH)
    .regex(DISCOUNT_CODE_REGEXP)
    .messages(DiscountCodeErrors.addCode);

  export const discount = (options: { ticketPrice: number }) =>
    Joi.number()
      .integer()
      .min(1)
      .custom(DiscountCodeUtils.addDiscountCode(options))
      .required()
      .messages(DiscountCodeErrors.discount);
}

export namespace DiscountCodeSchema {
  export const discountCodeKey = BaseDiscountCodeSchema.discountCodeKey;
  export const discountType = BaseDiscountCodeSchema.discountType;

  export const isNew = BoolSchema.required();

  export const discount = Joi.number()
    .integer()
    .min(1)
    .custom(DiscountCodeUtils.discount)
    .required()
    .messages(DiscountCodeErrors.discount);

  export const code = Joi.string()
    .required()
    .min(5)
    .max(DISCOUNT_CODE_MAX_LENGTH)
    .regex(DISCOUNT_CODE_REGEXP)
    .custom(DiscountCodeUtils.code)
    .messages(DiscountCodeErrors.code);
}
