import {
  ActivityUser,
  ActivityUserCheckboxAnswer,
  ActivityUserFreetextAnswer,
  ActivityUserMultichoiceAnswer,
  RequestedInfoType,
} from '../../../../../../../models';
import { TicketUserDto } from './users.dto';

export const ticketUserMapper = (
  ticketUserDto: TicketUserDto,
): ActivityUser => {
  const requestedInfoAnswers: ActivityUser['requestedInfoAnswers'] = [];

  ticketUserDto.checkboxAnswers?.forEach((checkboxAnswer) => {
    const activityUserCheckboxAnswer: ActivityUserCheckboxAnswer = {
      isChecked: checkboxAnswer.isChecked,
      key: checkboxAnswer.checkboxQuestionKey,
      question: checkboxAnswer.question,
      type: RequestedInfoType.Checkbox,
    };

    requestedInfoAnswers.push(activityUserCheckboxAnswer);
  });

  ticketUserDto.freetextAnswers?.forEach((freetextAnswer) => {
    const activityUserFreetextAnswer: ActivityUserFreetextAnswer = {
      answer: freetextAnswer.answer,
      key: freetextAnswer.freetextQuestionKey,
      question: freetextAnswer.question,
      type: RequestedInfoType.FreeText,
    };

    requestedInfoAnswers.push(activityUserFreetextAnswer);
  });

  ticketUserDto.multichoiceAnswers?.forEach((multichoiceAnswer) => {
    const activityUserMultichoiceAnswer: ActivityUserMultichoiceAnswer = {
      key: multichoiceAnswer.multichoiceQuestionKey,
      label: multichoiceAnswer.label,
      optionKey: multichoiceAnswer.multichoiceOptionKey,
      question: multichoiceAnswer.question,
      type: RequestedInfoType.MultiChoice,
    };

    requestedInfoAnswers.push(activityUserMultichoiceAnswer);
  });

  return {
    claimedAt: ticketUserDto.claimedAt,
    email: ticketUserDto.email,
    foodPreferences: ticketUserDto.foodPreferences,
    fullName: ticketUserDto.fullName,
    requestedInfoAnswers:
      requestedInfoAnswers.length !== 0 ? requestedInfoAnswers : null,
    semester: ticketUserDto.semester,
    ticketTypeKey: ticketUserDto.ticketTypeKey,
    ticketTypeName: ticketUserDto.ticketTypeName,
    userKey: ticketUserDto.userKey,
  };
};
