import React from 'react';

import { translate } from '../../i18n';
import { Text, TextProps } from '../text';

interface CollapsibleTextProps extends TextProps {
  threshold?: number;
}

const SHOW_MORE_TRHESHOLD = 200;

export function CollapsibleText(props: CollapsibleTextProps) {
  const [showFullText, setShowFullText] = React.useState(false);

  const toggleshowFullText = () => {
    setShowFullText((prev) => !prev);
  };

  const threshold = props.threshold || SHOW_MORE_TRHESHOLD;

  const text = (
    props.tx ? translate(props.tx, props.txArgs) : props.text
  )?.toString();

  if (!text) {
    return null;
  }

  const isCollapsible = text.length > threshold;

  return (
    <Text
      {...props}
      cursor={isCollapsible ? 'pointer' : 'default'}
      onClick={isCollapsible ? toggleshowFullText : undefined}
      text={
        text.length > threshold && !showFullText
          ? `${text.substring(0, threshold)}...`
          : text
      }
      whiteSpace="pre-wrap"
      overflowWrap="break-word"
    />
  );
}
