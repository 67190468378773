import { GeneralApiResultWithData } from '@orbiapp/components';

import { PartialOrgNode } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';

export const getCountries = async (): Promise<
  GeneralApiResultWithData<PartialOrgNode[]>
> => {
  const res = await apisauce.get<PartialOrgNode[], any>('/v1/countries');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getCountries', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
