export const LAYOUT_Z_INDEX = {
  profileMenu: 170,
  contentSidebar: 160,
  contentSidebarMd: 150,
  pageToolbar: 151,
  pageSidebar: 130,
  pageSidebarBackdrop: 120,

  scanTickets: {
    closeScanModalButton: 190,
    scanModal: 170,
    mustFocusWindow: 180,
    scanResultCard: 175,
  },
};
