import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

import { useCombinedRefs } from '../../helpers';
import { Box, BoxProps } from '../box';
import { IconButton, IconButtonProps } from '../icon-button';

interface DragDropItemProps extends BoxProps {
  id: string;
}

interface DragDropProps<T extends { id: string }> {
  children: React.ReactNode;

  items: T[];

  onChange: (items: T[]) => void;
}

const DragDropItemContext = React.createContext({
  id: '',
});

export function DragDropItemHandle(props: Partial<IconButtonProps>) {
  const { id } = React.useContext(DragDropItemContext);

  const { attributes, listeners, isDragging } = useSortable({ id });

  return (
    <IconButton
      cursor={isDragging ? 'grabbing' : 'grab'}
      icon="drag"
      {...attributes}
      {...listeners}
      {...props}
    />
  );
}

export function useDragDropItem() {
  const { id } = React.useContext(DragDropItemContext);
  const { isDragging, isSorting } = useSortable({ id });

  return { isDragging, isSorting };
}

function _DragDropItem(
  props: DragDropItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { id, children, ...rest } = props;

  const { setNodeRef, transform, transition } = useSortable({ id });

  const combinedRefs = useCombinedRefs(ref, setNodeRef);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <DragDropItemContext.Provider value={{ id }}>
      <Box style={style} ref={combinedRefs} {...rest}>
        {children}
      </Box>
    </DragDropItemContext.Provider>
  );
}
export const DragDropItem = React.forwardRef(_DragDropItem);

export function DragDrop<T extends { id: string }>(props: DragDropProps<T>) {
  const { children, items, onChange } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.active.id !== e.over?.id) {
      const oldIndex = items.findIndex((item) => item.id === e.active.id);
      const newIndex = items.findIndex((item) => item.id === e.over?.id);

      const newState = arrayMove(items, oldIndex, newIndex);

      onChange(newState);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}
