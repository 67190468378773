import { Box, Icon } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const Dot = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      width: 4px;
      height: 4px;
      border-radius: 50%;

      background-color: ${theme.colors.reviewApplicationsHistoryDotBackground};
    `;
  }}
`;

const InfoIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      color: ${theme.colors.reviewApplicationsInfoIconColor};
    `;
  }}
`;

const NoteBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border-left: 2px solid ${theme.colors.reviewApplicationsNoteBoxBorder};
    `;
  }}
`;

const SelectOptionBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.selectOptionBoxBackground};
      border: 1px solid ${theme.colors.selectOptionBoxBorder};
    `;
  }}
`;

export const Styled = { Dot, InfoIcon, NoteBox, SelectOptionBox };
