export namespace AccountErrors {
  export const firstName = {
    'string.empty': 'errors.first-name.empty',
  };

  export const lastName = {
    'string.empty': 'errors.last-name.empty',
  };

  export const password = {
    'string.empty': 'errors.password.empty',
    'min-length': 'errors.password.min-length',
    'min-numbers': 'errors.password.min-numbers',
    'min-special-characters': 'errors.password.min-special-characters',
  };
}
