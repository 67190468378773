import { toScu } from '@orbiapp/components';

import {
  ActivityCategory,
  ActivityLink,
  ActivityRequestedInfoOption,
  CreateActivityForm,
  CreateCoHostRequest,
  DiscountCode,
  MembershipTypeDiscount,
  RequestedInfo,
  RequestedInfoType,
  RequiredMembershipType,
  TicketType,
} from '../../../../../models';
import { injectMetadataInDescription } from '../../../../../utils';
import {
  CheckboxQuestionDto,
  DiscountCodeDto,
  FreetextQuestionDto,
  LinkDto,
  MembershipTypeDiscountDto,
  MultiChoiceOptionDto,
  MultichoiceQuestionDto,
  TicketTypeDto,
} from './create-activity.dto';
import { CreateActivityDto } from './create-activity.dto';

const cohostMapper = (cohost: CreateCoHostRequest): string =>
  cohost.departmentKey;

const linkMapper = (link: ActivityLink): LinkDto => ({
  label: link.label,
  url: link.url,
});

const membershipTypeDiscountMapper = (
  membershipTypeDiscount: MembershipTypeDiscount,
): MembershipTypeDiscountDto => ({
  discount: toScu(membershipTypeDiscount.discount),
  membershipTypeKey: membershipTypeDiscount.membershipTypeKey,
});

const membershipTypeKeyMapper = ({
  membershipTypeKey,
}: RequiredMembershipType): string => membershipTypeKey;

const discountCodeMapper = (discountCode: DiscountCode): DiscountCodeDto => ({
  code: discountCode.code,
  discount: toScu(discountCode.discount),
});

const ticketTypeMapper = (ticketType: TicketType): TicketTypeDto => ({
  description: ticketType.description?.length ? ticketType.description : null,
  discountCodes: ticketType.discountCodes?.length
    ? ticketType.discountCodes.map(discountCodeMapper)
    : null,
  endDate: ticketType.endDate,
  isTransferable: ticketType.isTransferable,
  maxTicketCountPerUser: ticketType.maxTicketCountPerUser,
  membershipTypeDiscounts: ticketType.membershipTypeDiscounts?.length
    ? ticketType.membershipTypeDiscounts.map(membershipTypeDiscountMapper)
    : null,
  name: ticketType.name,
  price: toScu(ticketType.price),
  requiredMembershipTypes: ticketType.requiredMembershipTypes
    ?.membershipApplicationRequirements.length
    ? {
        combinationType: ticketType.requiredMembershipTypes.combinationType,
        membershipTypeKeys:
          ticketType.requiredMembershipTypes.membershipApplicationRequirements.map(
            membershipTypeKeyMapper,
          ),
      }
    : null,
  startDate: ticketType.startDate,
  ticketCount: ticketType.ticketCount,
  ticketTypeKey: ticketType.ticketTypeKey,
});

const multichoiceOptionMapper = (
  option: ActivityRequestedInfoOption,
  index: number,
): MultiChoiceOptionDto => ({
  index,
  label: option.label,
  priceIncrement: toScu(option.priceIncrement),
});

const requestedInfoMapper = (requestedInfo: RequestedInfo[] | null) => {
  const checkboxQuestions: CheckboxQuestionDto[] = [];
  const freetextQuestions: FreetextQuestionDto[] = [];
  const multichoiceQuestions: MultichoiceQuestionDto[] = [];

  if (!requestedInfo) {
    return { checkboxQuestions, freetextQuestions, multichoiceQuestions };
  }

  requestedInfo.forEach((requestedInfo) => {
    const index =
      checkboxQuestions.length +
      freetextQuestions.length +
      multichoiceQuestions.length;

    switch (requestedInfo.type) {
      case RequestedInfoType.Checkbox:
        checkboxQuestions.push({
          index,
          priceIncrement: toScu(requestedInfo.priceIncrement),
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
        });
        return;

      case RequestedInfoType.FreeText:
        freetextQuestions.push({
          index,
          isRequired: requestedInfo.isRequired,
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
        });
        return;

      case RequestedInfoType.MultiChoice:
        multichoiceQuestions.push({
          index,
          isRequired: requestedInfo.isRequired,
          question: requestedInfo.question,
          ticketTypeKeys: Array.from(new Set(requestedInfo.ticketTypeKeys)),
          multichoiceOptions: requestedInfo.options.map(
            multichoiceOptionMapper,
          ),
        });
        return;
    }
  });

  return { checkboxQuestions, freetextQuestions, multichoiceQuestions };
};

const categoryMapper = (category: ActivityCategory): string =>
  category.activityCategoryKey;

export const createActivityMapper = (
  createActivityForm: CreateActivityForm,
): CreateActivityDto => {
  const { checkboxQuestions, freetextQuestions, multichoiceQuestions } =
    requestedInfoMapper(createActivityForm.requestMoreInfo.requestedInfo);

  const showAttendance = createActivityForm.tickets.ticketTypes?.length
    ? createActivityForm.tickets.showAttendance
    : false;

  const createActivityDto: CreateActivityDto = {
    checkboxQuestions:
      checkboxQuestions.length !== 0 ? checkboxQuestions : null,
    cohosts: createActivityForm.coHosting.cohosts?.map(cohostMapper) ?? null,
    contactDetails: {
      email: createActivityForm.description.contactDetails.email,
      name: createActivityForm.description.contactDetails.name,
      phone: createActivityForm.description.contactDetails.phone?.length
        ? createActivityForm.description.contactDetails.phone
        : null,
    },
    coverImage: {
      base64: createActivityForm.media.coverImage!.base64,
      fileName: createActivityForm.media.coverImage!.fileName,
      fileType: createActivityForm.media.coverImage!.fileType,
    },
    description:
      createActivityForm.description.tags?.length ||
      createActivityForm.description.location.city
        ? injectMetadataInDescription(
            createActivityForm.description.description,
            createActivityForm.description.tags,
            createActivityForm.description.location.city,
          )
        : createActivityForm.description.description,
    endDate: createActivityForm.description.endDate,
    freetextQuestions:
      freetextQuestions.length !== 0 ? freetextQuestions : null,
    links: createActivityForm.description.links?.map(linkMapper) ?? null,
    location: {
      coordinates: createActivityForm.description.location.coordinates,
      description: createActivityForm.description.location.description
        ? createActivityForm.description.location.description
        : null,
      label: createActivityForm.description.location.label,
      link: createActivityForm.description.location.link?.length
        ? createActivityForm.description.location.link
        : null,
    },
    multichoiceQuestions:
      multichoiceQuestions.length !== 0 ? multichoiceQuestions : null,
    questionsActivated: createActivityForm.addons.questionsActivated,
    segmentation: createActivityForm.participants.segmentation,
    startDate: createActivityForm.description.startDate,
    ticketTypes: !!createActivityForm.tickets.ticketTypes?.length
      ? createActivityForm.tickets.ticketTypes.map(ticketTypeMapper)
      : null,
    title: createActivityForm.description.title,
    categories: createActivityForm.description.categories.map(categoryMapper),

    showAttendance,
    showAttendees: showAttendance,
  };

  return createActivityDto;
};
