import { Question } from '../../models';
import { UpdateQuestionThunkParams } from './questions.types';

export const getNewQuestionState = (
  question: Question,
  payload: UpdateQuestionThunkParams,
) => {
  const now = Date.now();
  const answerUpdatedAt = question.answerUpdatedAt;

  const newQuestionState: Question = {
    ...question,
    ...payload,
  };

  answerUpdatedAt
    ? (newQuestionState.answerUpdatedAt = now)
    : (newQuestionState.answeredAt = now);

  return newQuestionState;
};
