import { BoxProps, useFullscreen } from '@orbiapp/components';

import { Styled } from './scan-result-card-container.styled';

export function ScanResultCardContainer(props: BoxProps) {
  const { children } = props;

  const { isFullscreen } = useFullscreen();

  return (
    <Styled.ScanResultCard
      flex
      flexDirection="column"
      flexJustify="between"
      isFullscreen={isFullscreen}
      gap={24}
      px={32}
      py={32}
      r={8}
    >
      {children}
    </Styled.ScanResultCard>
  );
}
