import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  Department,
  DepartmentForm,
  UpdateDepartmentResponse,
} from '../../models';
import { OrbiApi, v2 } from '../../services';
import { signOutThunk } from '../auth';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';

export const getDepartmentThunk = createAsyncThunk<
  Department,
  undefined,
  ThunkApiConfig
>('department/get', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.department.getDepartment, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  thunkAPI.dispatch(signOutThunk());
  return thunkAPI.rejectWithValue(res);
});

export const updateDepartmentThunk = createAsyncThunk<
  UpdateDepartmentResponse,
  DepartmentForm,
  ThunkApiConfig
>('department/update', async (updateDepartmentForm, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.department.updateDepartment,
    updateDepartmentForm,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-department:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('update-department:error'));
  return thunkAPI.rejectWithValue(res);
});
