import { Avatar, Box, Text } from '@orbiapp/components';
import styled from 'styled-components';

const MembershipCard = styled(Box)<{ src: string }>`
  padding: 16px;

  width: 328px;
  height: 205px;
  border-radius: 16px;

  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.8) 36.46%
    ),
    url(${(props) => props.src});

  background-size: cover;
  background-position: center;
`;

const MembershipCardLogo = styled(Avatar)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border: 2px solid ${(props) => props.theme.colors.neutral.white};
`;

const MembershipCardName = styled(Text)`
  color: ${(props) => props.theme.colors.neutral.white};
`;

export const Styled = {
  MembershipCard,
  MembershipCardLogo,
  MembershipCardName,
};
