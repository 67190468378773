import { Pagination } from '@orbiapp/components';

import { AsyncState } from '../models';
import { PaginatedState } from './store.types';

export const INITIAL_DATA_STATE: AsyncState<any> = {
  data: null,
  error: null,
  status: 'idle',
};

export const INITIAL_PAGINATION_STATE: Omit<Pagination, 'orderBy'> = {
  offset: 0,
  limit: 25,
  sortOrder: 'desc',
};

export const INITIAL_PAGINATED_STATE: Omit<
  PaginatedState<any, any, undefined>,
  'items' | 'orderBy'
> = {
  error: null,
  pageInfo: {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
  },

  sortOrder: 'desc',
  status: 'idle',
  totalCount: 0,

  pageSize: 25,

  extra: undefined,
};
