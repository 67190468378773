import styled, { css } from 'styled-components';

import { Placement } from '../../utils';
import { Box } from '../box';
import { SnackbarProps } from './snackbar.types';

function getPlacementStyles(placement: Placement) {
  switch (placement) {
    case 'bottom':
      return css`
        bottom: 32px;
        left: 50%;
        transform: translateX(-50%);
      `;

    case 'bottom-end':
      return css`
        bottom: 32px;
        right: 32px;
      `;

    case 'bottom-start':
      return css`
        bottom: 32px;
        left: 32px;
      `;

    case 'left':
      return css`
        left: 32px;
        top: 50%;
        transform: translateY(-50%);
      `;

    case 'left-end':
      return css`
        left: 32px;
        bottom: 32px;
      `;

    case 'left-start':
      return css`
        left: 32px;
        top: 32px;
      `;

    case 'right':
      return css`
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
      `;

    case 'right-end':
      return css`
        right: 32px;
        bottom: 32px;
      `;

    case 'right-start':
      return css`
        right: 32px;
        top: 32px;
      `;

    case 'top':
      return css`
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
      `;

    case 'top-end':
      return css`
        top: 32px;
        right: 32px;
      `;

    case 'top-start':
      return css`
        top: 32px;
        left: 32px;
      `;
  }
}

const Snackbar = styled(Box)<SnackbarProps>`
  ${(props) => {
    const { placement } = props;

    return css`
      ${getPlacementStyles(placement)}
    `;
  }}
`;

export const Styled = { Snackbar };
