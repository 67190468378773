import { createEntityAdapter } from '@reduxjs/toolkit';
import { PostDto } from 'dtos/student-dashboard/v1/post/post.dto';

import { PartialPostComment } from '../../models';

export const postsAdapter = createEntityAdapter<PostDto, string>({
  selectId: (post) => post.postKey,
});

export const postCommentsAdapter = createEntityAdapter<
  PartialPostComment,
  string
>({
  selectId: (comment) => comment.postCommentKey,
  sortComparer: (a, b) => a.createdAt - b.createdAt,
});
