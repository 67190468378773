import {
  BreadcrumbsToolbar,
  LoadingContainer,
  PageContainer,
} from '@orbiapp/components';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import {
  MembershipTypeSelector,
  createDynamicMembershipLinkThunk,
  getMembershipMemberListItemStats,
  getMembershipTypeStatsThunk,
  getMembershipTypeThunk,
  membershipsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { NotFound } from '../../../shared';
import {
  InviteMembersModal,
  InviteMembersProvider,
} from '../components/invite-members-modal';

export function ViewMembership() {
  const membershipTypeStatus = useSelector(MembershipTypeSelector.selectStatus);
  const membershipTypeIsNull = useSelector(MembershipTypeSelector.selectIsNull);
  const membershipTypeError = useSelector(MembershipTypeSelector.selectError);
  const membershipTypeName = useSelector(
    MembershipTypeSelector.selectMembershipTypeName,
  );
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);
  const { membershipTypeKey } = useParams<{ membershipTypeKey: string }>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!membershipTypeKey) {
      navigate('/memberships');
      return;
    }

    dispatch(getMembershipTypeThunk(membershipTypeKey));
    dispatch(getMembershipTypeStatsThunk(membershipTypeKey));
    dispatch(getMembershipMemberListItemStats(membershipTypeKey));
    dispatch(createDynamicMembershipLinkThunk(membershipTypeKey));

    return () => {
      dispatch(membershipsActions.clearMembership());
    };
  }, [dispatch, membershipTypeKey, navigate]);

  if (membershipTypeError) {
    return <NotFound continueTo="/memberships" />;
  }

  if (membershipTypeStatus === 'pending' || membershipTypeIsNull) {
    return <LoadingContainer />;
  }

  return (
    <InviteMembersProvider>
      <PageContainer>
        <BreadcrumbsToolbar
          flexGrow={0.4}
          breadcrumbListItems={[
            {
              to: '/memberships',
              tx: 'label.breadcrumbs.memberships.memberships',
            },
            {
              to: `/memberships/${membershipTypeKey}`,
              text: membershipTypeName ?? '',
              truncate: true,
            },
          ]}
          tabButtonListItems={
            isIntegrated
              ? [
                  {
                    tx: 'label.memberships.view-membership.tabs.overview',
                    to: `/memberships/${membershipTypeKey}/overview`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.members',
                    to: `/memberships/${membershipTypeKey}/members`,
                  },
                ]
              : [
                  {
                    tx: 'label.memberships.view-membership.tabs.overview',
                    to: `/memberships/${membershipTypeKey}/overview`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.members',
                    to: `/memberships/${membershipTypeKey}/members`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.questions',
                    to: `/memberships/${membershipTypeKey}/questions`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.periods',
                    to: `/memberships/${membershipTypeKey}/periods`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.required-memberships',
                    to: `/memberships/${membershipTypeKey}/required-memberships`,
                  },
                  {
                    tx: 'label.memberships.view-membership.tabs.membership-purchases',
                    to: `/memberships/${membershipTypeKey}/purchases`,
                  },
                ]
          }
        />

        <Outlet />
      </PageContainer>

      <InviteMembersModal />
    </InviteMembersProvider>
  );
}
