import styled, { css } from 'styled-components';

import { Box } from '../../components';
import gradient2 from './gradient-2.png';

const SplitScreenLeftSide = styled(Box)`
  background-image: url(${gradient2});
  background-size: cover;
`;

const SplitScreenContainer = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      overflow-x: hidden;
      flex-direction: column-reverse;

      @media (min-width: ${theme.breakpoints.sm}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    `;
  }}
`;

export const Styled = { SplitScreenLeftSide, SplitScreenContainer };
