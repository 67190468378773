import {
  addressesAdapter,
  departmentsAdapter,
  membershipTypesAdapter,
  usersAdapter,
} from './search.adapter';
import { initialSearchState } from './search.initial';
import { SearchState } from './search.types';

const reset = (state: SearchState) => {
  Object.assign(state, initialSearchState);
};

const clearMembershipTypes = (state: SearchState) => {
  membershipTypesAdapter.removeAll(state.membershipTypes.data);
};

const clearUsers = (state: SearchState) => {
  usersAdapter.removeAll(state.users.data);
};

const clearAddresses = (state: SearchState) => {
  addressesAdapter.removeAll(state.addresses.data);
};

const clearCoodinates = (state: SearchState) => {
  state.coordinates = initialSearchState.coordinates;
};

const clearDepartments = (state: SearchState) => {
  departmentsAdapter.removeAll(state.departments.data);
};

export const reducers = {
  reset,
  clearMembershipTypes,
  clearUsers,
  clearAddresses,
  clearCoodinates,
  clearDepartments,
};
