import styled, { css } from 'styled-components';

const TableHeader = styled.thead`
  ${(props) => {
    const { theme } = props;

    return css`
      position: sticky;
      top: 0;
      z-index: 10;
      border-bottom: 1px solid ${theme.colors.tableHeaderBorder};
      background-color: ${theme.colors.tableHeaderBackground};
    `;
  }}
`;

export const Styled = { TableHeader };
