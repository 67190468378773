import React from 'react';

import { Box } from '../box';
import { SolidIconButton } from '../icon-button';
import { Styled } from './snackbar.styled';
import { SnackbarProps } from './snackbar.types';

export function Snackbar(props: SnackbarProps) {
  const { children, autoHideDuration, onClose, ...rest } = props;

  React.useEffect(() => {
    if (!autoHideDuration) return;

    const timeout = window.setTimeout(() => onClose?.(), autoHideDuration);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [autoHideDuration, onClose]);

  return (
    <Styled.Snackbar
      fixed
      flex
      flexAlign="center"
      flexJustify="center"
      {...rest}
    >
      <Box relative>
        {onClose && (
          <Box absolute right={-20} top={-20}>
            <SolidIconButton
              isActiveIcon="x-circle-solid"
              icon="x-circle-outline"
              onClick={onClose}
            />
          </Box>
        )}

        {children}
      </Box>
    </Styled.Snackbar>
  );
}
