import { GeneralApiResultWithData } from '@orbiapp/components';

import { RequestedInfoStats } from '../../../../../../../models';
import { Logger } from '../../../../../../logger';
import { apisauce } from '../../../../../api';
import { getGeneralApiProblem } from '../../../../../api.utils';
import { RequestedInfoStatsDto } from './requested-info.dto';
import { requestedInfoStatsMapper } from './requested-info.mappers';

export const getRequestedInfoStats = async (
  activityKey: string,
): Promise<GeneralApiResultWithData<RequestedInfoStats>> => {
  const res = await apisauce.get<RequestedInfoStatsDto, any>(
    `/v2/activities/${activityKey}/stats/requested-info`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = requestedInfoStatsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getRequestedInfoStats', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
