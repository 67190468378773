import Joi from 'joi';

import { MembershipDiscountSchema } from './membership-discount.schema';

export const MembershipTypeDiscountValidation = Joi.object({
  departmentName: MembershipDiscountSchema.departmentName,
  discount: MembershipDiscountSchema.discount,
  discountType: MembershipDiscountSchema.discountType,
  isNew: MembershipDiscountSchema.isNew,
  logoUrl: MembershipDiscountSchema.logoUrl,
  membershipTypeDiscountKey: MembershipDiscountSchema.membershipTypeDiscountKey,
  membershipTypeKey: MembershipDiscountSchema.membershipTypeKey,
  name: MembershipDiscountSchema.name,
});

export const CreateMembershipTypeDiscountsFormValidation = (
  ticketPrice: number,
) =>
  Joi.object({
    membershipTypeDiscounts: Joi.array().items(
      Joi.object({
        departmentName: MembershipDiscountSchema.departmentName,
        discount: MembershipDiscountSchema.addDiscount(ticketPrice),
        discountType: MembershipDiscountSchema.discountType,
        isNew: MembershipDiscountSchema.isNew,
        logoUrl: MembershipDiscountSchema.logoUrl,
        membershipTypeDiscountKey:
          MembershipDiscountSchema.membershipTypeDiscountKey,
        membershipTypeKey: MembershipDiscountSchema.membershipTypeKey,
        name: MembershipDiscountSchema.name,
      }),
    ),
  });
