import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { Box } from '../box';
import { TinySelectInputProps } from './tiny-select.types';

const TinySelectInput = styled(Box)<TinySelectInputProps>`
  ${(props) => {
    const { disabled, isOpen, theme } = props;

    const formFieldStyles = css`
      ${baseComponentStyles};

      outline: none;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      min-width: 52px;
      cursor: pointer;
    `;

    if (disabled) {
      return css`
        ${formFieldStyles};

        pointer-events: none;

        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
      `;
    }

    if (isOpen) {
      return css`
        ${formFieldStyles};

        border-color: ${theme.colors.inputBorderFocus};
        background-color: ${theme.colors.inputBackgroundFocus};
      `;
    }

    return css`
      ${formFieldStyles};

      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};
    `;
  }}
`;

export const Styled = { TinySelectInput };
