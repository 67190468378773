import Joi from 'joi';

import { combinationTypes } from '../../../shared';

export namespace RequiredMembershipsSchema {
  export const combinationType = Joi.string()
    .valid(...combinationTypes)
    .required();

  export const membershipApplicationRequirements = Joi.array().items(
    Joi.object({
      key: Joi.string().required(),
      departmentName: Joi.string().required(),
      logoUrl: Joi.string().required(),
      membershipTypeKey: Joi.string().required(),
      name: Joi.string().required(),
    }),
  );
}
