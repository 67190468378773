import { Box, Text } from '@orbiapp/components';
import React from 'react';

import { Styled } from './summary-list.styled';
import { SummaryListItemProps } from './summary-list.types';

export function SummaryList(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Box flex flexDirection="column" gap={16}>
      {children}
    </Box>
  );
}

export function SummaryListItem(props: SummaryListItemProps) {
  const {
    children,
    subtitle,
    subtitleTx,
    subtitleTxArgs,
    title,
    titleTx,
    titleTxArgs,
  } = props;

  return (
    <Box>
      <Text
        text={title}
        tx={titleTx}
        txArgs={titleTxArgs}
        variant="bodySm"
        color="summaryListItemTitle"
      />

      {children || (
        <Text
          variant="bodySm"
          tx={subtitleTx}
          txArgs={subtitleTxArgs}
          text={subtitle}
        />
      )}
    </Box>
  );
}

export function SummaryListItemGrid(props: React.PropsWithChildren) {
  const { children } = props;

  return <Styled.SummaryListItemGrid>{children}</Styled.SummaryListItemGrid>;
}
