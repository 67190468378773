import { Time } from '@orbiapp/components';

export const CUSTOM_TIMEOUTS = {
  createActivity: 50 * Time.Second,
  createMembership: 15 * Time.Second,
  getActivities: 50 * Time.Second,
  getActivity: 50 * Time.Second,
  getActivityRecords: 50 * Time.Second,
  getActivityUsers: 50 * Time.Second,
  getTicketsHistory: 50 * Time.Second,
  getFoodPreferencesItems: 50 * Time.Second,
  getFreeTextListItems: 50 * Time.Second,
  getOrbiPaySettings: 50 * Time.Second,
  getPayout: 10 * Time.Minute,
  getTicketListItems: 50 * Time.Second,
  updateActivity: 50 * Time.Second,
  getMembershipMembersStats: 50 * Time.Second,
  getAccount: 20 * Time.Second,
  createPost: 5 * Time.Minute,
};
