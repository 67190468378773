import action from './action.json';
import button from './button.json';
import errors from './errors.json';
import label from './label.json';
import link from './link.json';
import placeholder from './placeholder.json';
import prompt from './prompt.json';
import title from './title.json';
import units from './units.json';

export const en = {
  action,
  button,
  errors,
  label,
  link,
  placeholder,
  prompt,
  title,
  units,
};
