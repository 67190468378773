import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import {
  TicketListItem,
  TicketListItemOrderByKey,
} from '../../../../../../../models';
import { Logger } from '../../../../../../logger';
import { apisauce } from '../../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../../api.constants';
import { getGeneralApiProblem } from '../../../../../api.utils';
import { TicketListItemDto } from './sold.dto';
import { ticketListItemMapper } from './sold.mappers';

export const getTicketListItems = async (params: {
  activityKey: string;
  pagination: Pagination<TicketListItemOrderByKey>;
  search: string | undefined;
}): Promise<GeneralApiResultWithData<TicketListItem[]>> => {
  const { activityKey, pagination, search } = params;

  const res = await apisauce.get<TicketListItemDto[], any>(
    `/v1/activities/${activityKey}/tickets/sold`,
    {
      ...pagination,
      search: search?.length ? search : undefined,
    },
    { timeout: CUSTOM_TIMEOUTS.getTicketListItems },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(ticketListItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTicketListItems', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
