import Joi from 'joi';

import { EmailSchema } from '../email';
import { PhoneSchema } from '../phone';
import { CONTACT_NAME_MAX_LENGTH } from './contact-details.constants';
import { ContactDetailsErrors } from './contact-details.errors';

export namespace ContactDetailsSchema {
  export const email = EmailSchema.required().messages(
    ContactDetailsErrors.email,
  );

  export const name = Joi.string()
    .max(CONTACT_NAME_MAX_LENGTH)
    .required()
    .messages(ContactDetailsErrors.name);

  export const phone = PhoneSchema.allow(null, '')
    .required()
    .messages(ContactDetailsErrors.phone);
}
