import { PageSize, Reactions } from '@orbiapp/components';

import {
  CreateReactionParams,
  DeleteReactionParams,
  GetPostsPageParams,
} from '../../models';

export function getReactionsState(
  currentState: Reactions,
  payload: CreateReactionParams | DeleteReactionParams,
) {
  if ('type' in payload) {
    const reactions = { ...currentState, self: payload.type };

    switch (payload.type) {
      case 'fire':
        reactions.fireCount += 1;
        break;

      case 'party_popper':
        reactions.partyPopperCount += 1;
        break;

      case 'red_heart':
        reactions.redHeartCount += 1;
        break;

      case 'thumbs_up':
        reactions.thumbsUpCount += 1;
        break;
    }

    return reactions;
  }

  const reactions = { ...currentState, self: null };
  switch (currentState.self) {
    case 'fire':
      reactions.fireCount -= 1;
      break;

    case 'party_popper':
      reactions.partyPopperCount -= 1;
      break;

    case 'red_heart':
      reactions.redHeartCount -= 1;
      break;

    case 'thumbs_up':
      reactions.thumbsUpCount -= 1;
      break;
  }

  return reactions;
}

export function getPostsParams(
  currentState: Pick<
    GetPostsPageParams,
    'activityKeys' | 'q' | 'targetType' | 'orderBy' | 'sortOrder'
  > & { pageSize: PageSize },
  params: Partial<GetPostsPageParams>,
): GetPostsPageParams {
  return {
    orderBy: currentState.orderBy,
    sortOrder: currentState.sortOrder,

    activityKeys: currentState.activityKeys,
    q: currentState.q,
    targetType: currentState.targetType,

    first: !params.first && !params.last ? currentState.pageSize : params.first,
    after: params.after,

    last: params.last,
    before: params.before,

    ...params,
  };
}
