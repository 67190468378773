import { Pagination, fromScu } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CheckIntegrationErrorResponse,
  CombinationType,
  CreateIntegratedMembershipTypeForm,
  CreateMembershipApplicationRequirementsResult,
  CreateMembershipTypeForm,
  DeleteMembershipTypeErrorResponse,
  DeletePeriodErrorResponse,
  GeneralInfoForm,
  IntegratedMembershipMetadata,
  MembersOrderByKey,
  MembershipApplication,
  MembershipApplicationListItem,
  MembershipApplicationOrderByKey,
  MembershipApplicationRequirement,
  MembershipCheckboxQuestionForm,
  MembershipFreetextQuestionForm,
  MembershipInviteListItem,
  MembershipInviteOrderByKey,
  MembershipInvitesForm,
  MembershipInvitesResult,
  MembershipMember,
  MembershipMemberListItem,
  MembershipMemberListItemStats,
  MembershipMultichoiceQuestionForm,
  MembershipPeriodForm,
  MembershipPurchaseListItem,
  MembershipPurchasesOrderByKey,
  MembershipTypeListItem,
  MembershipTypeStats,
  MembershipTypeWrapper,
  RefundMembershipPeriodErrorResponse,
  RejectMembershipApplicationForm,
  RequestMoreInfoOnMembershipApplicationForm,
  UpdateMembershipTypeGeneralInfoResponse,
} from '../../models';
import {
  OrbiApi,
  createDynamicLink,
  getPageAndNextPage,
  isClientProblem,
  v2,
} from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import {
  membershipApplicationsAdapter,
  membershipInvitesAdapater,
  membershipMembersAdapter,
  membershipPurchaseListItemsAdapter,
} from './memberships.adapter';

export const createMembershipTypeThunk = createAsyncThunk<
  string,
  CreateMembershipTypeForm,
  ThunkApiConfig
>(
  'memberships/create-membership-type',
  async (createMembershipTypeForm, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.membershipTypes.createMembershipType,
      createMembershipTypeForm,
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('create-membership:success'));
      return res.data;
    }

    thunkAPI.dispatch(setAlert('create-membership:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getMembershipsTypesThunk = createAsyncThunk<
  MembershipTypeListItem[],
  undefined,
  ThunkApiConfig
>('memberships/get-all', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.membershipTypes.getMembershipTypeListItems,
    undefined,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getMembershipTypeThunk = createAsyncThunk<
  MembershipTypeWrapper,
  string,
  ThunkApiConfig
>('memberships/get-membership-type', async (membershipTypeKey, thunkAPI) => {
  const state = thunkAPI.getState();

  if (state.memberships.membershipType.data) {
    return state.memberships.membershipType.data;
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.getMembershipType,
    membershipTypeKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createDynamicMembershipLinkThunk = createAsyncThunk<
  string,
  string,
  ThunkApiConfig
>(
  'memberships/create-dynamic-membership-link',
  async (membershipTypeKey, thunkAPI) => {
    try {
      const res = await createDynamicLink({
        page: 'membership',
        key: membershipTypeKey,
      });

      return res.data.shortLink;
    } catch (err) {
      return thunkAPI.rejectWithValue({ kind: 'rejected' });
    }
  },
);

export const deleteMembershipTypeThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig<DeleteMembershipTypeErrorResponse>
>('memberships/delete-membership-type', async (membershipTypeKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.membershipTypes.deleteMembershipType,
    membershipTypeKey,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-membership-type:success'));
    return;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('delete-membership-type:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('delete-membership-type:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(
    setAlert('delete-membership-type:invitations-not-zero', {
      count: res.message.metadata.count,
    }),
  );
  return thunkAPI.rejectWithValue(res);
});

export const acceptApplicationThunk = createAsyncThunk<
  string,
  undefined,
  ThunkApiConfig
>('memberships/accept-application', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;
  const membershipApplicationKey =
    state.memberships.membershipApplication.data?.membershipApplicationKey;

  if (!membershipTypeKey || !membershipApplicationKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.applications.acceptMembershipApplication,
    {
      membershipApplicationKey,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('accept-application:success'));
    return membershipApplicationKey;
  }

  thunkAPI.dispatch(setAlert('accept-application:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getMembershipApplicationsThunk = createAsyncThunk<
  MembershipApplicationListItem[],
  Pagination<MembershipApplicationOrderByKey>,
  ThunkApiConfig
>('memberships/get-membership-applications', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    membershipApplicationsAdapter
      .getSelectors()
      .selectAll(state.memberships.membershipApplications.data),
    v2.membershipTypes.applications.getMembershipApplications,
    {
      membershipTypeKey,
      pagination,
      includeStatus: state.memberships.membershipApplications.includeStatus,
    },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const rejectApplicationThunk = createAsyncThunk<
  void,
  RejectMembershipApplicationForm,
  ThunkApiConfig
>(
  'memberships/reject-application',
  async (rejectMembershipApplicationForm, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.applications.rejectMembershipApplication,
      {
        membershipTypeKey,
        rejectMembershipApplicationForm,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('reject-application:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('reject-application:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const requestMoreInfoOnMembershipApplicationThunk = createAsyncThunk<
  void,
  RequestMoreInfoOnMembershipApplicationForm,
  ThunkApiConfig
>(
  'memberships/request-more-info-on-application',
  async (requestedMoreInfoOnMembershipApplicationForm, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.applications.requestMoreInfoOnMembershipApplication,
      {
        membershipTypeKey,
        requestedMoreInfoOnMembershipApplicationForm,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('request-more-info:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('request-more-info:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getMembershipApplicationThunk = createAsyncThunk<
  MembershipApplication,
  string,
  ThunkApiConfig
>(
  'memberships/get-membership-application',
  async (membershipApplicationKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.applications.getMembershipApplication,
      {
        membershipTypeKey,
        membershipApplicationKey,
      },
    );

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const createCheckboxThunk = createAsyncThunk<
  string,
  MembershipCheckboxQuestionForm,
  ThunkApiConfig
>('memberships/create-checkbox', async (createCheckboxQuestion, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.checkboxQuestions.createCheckboxQuestion,
    {
      createCheckboxQuestion,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('add-membership-question:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('add-membership-question:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateCheckboxThunk = createAsyncThunk<
  void,
  MembershipCheckboxQuestionForm,
  ThunkApiConfig
>('memberships/update-checkbox', async (updateCheckboxQuestion, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.checkboxQuestions.updateCheckboxQuestion,
    {
      updateCheckboxQuestion,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-membership-question:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('update-membership-question:error'));
  return thunkAPI.rejectWithValue(res);
});

export const deleteCheckboxQuestionThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  'memberships/delete-checkbox',
  async (membershipCheckboxQuestionKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.checkboxQuestions.deleteCheckboxQuestion,
      {
        membershipCheckboxQuestionKey,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-question:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-question:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const createFreetextThunk = createAsyncThunk<
  string,
  MembershipFreetextQuestionForm,
  ThunkApiConfig
>('memberships/create-freetext', async (createFreetextQuestion, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.freetextQuestions.createFreetextQuestion,
    {
      createFreetextQuestion,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('add-membership-question:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('add-membership-question:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateFreetextThunk = createAsyncThunk<
  void,
  MembershipFreetextQuestionForm,
  ThunkApiConfig
>('memberships/update-freetext', async (updateFreetextQuestion, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.freetextQuestions.updateFreetextQuestion,
    {
      updateFreetextQuestion,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-membership-question:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('update-membership-question:error'));
  return thunkAPI.rejectWithValue(res);
});

export const deleteFreetextQuestionThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  'memberships/delete-freetext',
  async (membershipFreetextQuestionKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.freetextQuestions.deleteFreetextQuestion,
      {
        membershipFreetextQuestionKey,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-question:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-question:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const createMultichoiceQuestionThunk = createAsyncThunk<
  string,
  MembershipMultichoiceQuestionForm,
  ThunkApiConfig
>(
  'memberships/create-multichoice',
  async (createMultichoiceQuestion, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.multichoiceQuestions.createMultichoiceQuestion,
      {
        createMultichoiceQuestion,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('add-membership-question:success'));
      return res.data;
    }

    thunkAPI.dispatch(setAlert('add-membership-question:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const updateMultichoiceThunk = createAsyncThunk<
  void,
  MembershipMultichoiceQuestionForm,
  ThunkApiConfig
>(
  'memberships/update-multichoice',
  async (updateMultichoiceQuestion, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.multichoiceQuestions.updateMultichoiceQuestion,
      {
        updateMultichoiceQuestion,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('update-membership-question:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('update-membership-question:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const deleteMultichoiceQuestionThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  'memberships/delete-multichoice',
  async (membershipMultichoiceQuestionKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.multichoiceQuestions.deleteMultichoiceQuestion,
      {
        membershipMultichoiceQuestionKey,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-question:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-question:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const updateGeneralInfoThunk = createAsyncThunk<
  UpdateMembershipTypeGeneralInfoResponse,
  GeneralInfoForm,
  ThunkApiConfig
>('memberships/update-general-info', async (generalInfo, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.generalInfo.updateGeneralInfo,
    {
      membershipTypeKey,
      generalInfo,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-membership:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('update-membership:error'));
  return thunkAPI.rejectWithValue(res);
});

export const checkIntegrationThunk = createAsyncThunk<
  void,
  IntegratedMembershipMetadata,
  ThunkApiConfig<CheckIntegrationErrorResponse>
>('memberships/check', async (membershipCredentials, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.membershipTypes.integrated.checkIntegration,
    membershipCredentials,
  );

  if (res.kind === 'ok') {
    return;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('check-integration:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('check-integration:error'));
    return thunkAPI.rejectWithValue(res);
  }

  switch (res.message.code) {
    case 0:
      thunkAPI.dispatch(setAlert('check-integration:unsupported-source'));
      break;

    case 1:
      thunkAPI.dispatch(setAlert('check-integration:invalid-url'));
      break;

    case 2:
      thunkAPI.dispatch(setAlert('check-integration:invalid-api-key'));
      break;

    case 3:
      const args = { source: membershipCredentials.source };
      thunkAPI.dispatch(setAlert('check-integration:already-exists', args));
      break;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createIntegratedMembershipTypeThunk = createAsyncThunk<
  string,
  CreateIntegratedMembershipTypeForm,
  ThunkApiConfig
>(
  'memberships/create-integrated-membership-type',
  async (createIntegratedMembershipTypeForm, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.membershipTypes.integrated.createIntegratedMembershipType,
      createIntegratedMembershipTypeForm,
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('create-membership:success'));
      return res.data;
    }

    thunkAPI.dispatch(setAlert('create-membership:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const createMembershipInvitationsThunk = createAsyncThunk<
  MembershipInvitesResult,
  MembershipInvitesForm,
  ThunkApiConfig
>(
  'memberships/create-membership-invitations',
  async (membershipInvitesForm, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.invites.createMembershipInvitations,
      { membershipTypeKey, membershipInvitesForm },
    );

    if (res.kind === 'ok') {
      return res.data;
    }

    thunkAPI.dispatch(setAlert('create-membership-invitations:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getMembershipInvitesThunk = createAsyncThunk<
  MembershipInviteListItem[],
  Pagination<MembershipInviteOrderByKey>,
  ThunkApiConfig
>('memberships/get-membership-invites', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    membershipInvitesAdapater
      .getSelectors()
      .selectAll(state.memberships.membershipInvites.data),
    v2.membershipTypes.invites.getMembershipInvites,
    {
      membershipTypeKey,
      pagination,
      includeStatus: state.memberships.membershipInvites.includeStatus,
    },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const withdrawInviteThunk = createAsyncThunk<
  string,
  string,
  ThunkApiConfig
>('memberships/withdraw-invite', async (membershipApplicationKey, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.invites.withdrawMembershipInvite,
    {
      membershipApplicationKey,
      membershipTypeKey,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('withdraw-invite:success'));
    return membershipApplicationKey;
  }

  thunkAPI.dispatch(setAlert('withdraw-invite:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getMembershipMemberThunk = createAsyncThunk<
  MembershipMember,
  string,
  ThunkApiConfig
>('memberships/get-membership-member', async (userKey, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.members.getMembershipMember,
    {
      membershipTypeKey,
      userKey,
    },
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getMembershipMembersThunk = createAsyncThunk<
  MembershipMemberListItem[],
  Pagination<MembersOrderByKey>,
  ThunkApiConfig
>('memberships/get-membership-members', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const search = state.memberships.membershipMembers.search;
  if (search) {
    const res = await OrbiApi.call(
      v2.membershipTypes.members.getMembershipMembers,
      { membershipTypeKey, pagination, search },
    );

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    membershipMembersAdapter
      .getSelectors()
      .selectAll(state.memberships.membershipMembers.data),
    v2.membershipTypes.members.getMembershipMembers,
    { membershipTypeKey, pagination, search: undefined },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const createMembershipApplicationRequirementsThunk = createAsyncThunk<
  CreateMembershipApplicationRequirementsResult,
  MembershipApplicationRequirement[],
  ThunkApiConfig
>(
  'memberships/create-membership-requirements',
  async (membershipApplicationRequirements, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.membershipApplicationRequirements
        .createMembershipApplicationRequirements,
      { membershipTypeKey, membershipApplicationRequirements },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('add-requirement:success'));
      return res.data;
    }

    thunkAPI.dispatch(setAlert('add-requirement:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const deleteRequirementThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  'memberships/delete-requirement',
  async (membershipApplicationRequirementKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.membershipApplicationRequirements.deleteRequirement,
      {
        membershipApplicationRequirementKey,
        membershipTypeKey,
      },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-requirement:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-requirement:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const updateCombinationTypeThunk = createAsyncThunk<
  void,
  CombinationType,
  ThunkApiConfig
>('memberships/update-combination-type', async (combinationType, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(
    v2.membershipTypes.membershipApplicationRequirements.updateCombinationType,
    {
      membershipTypeKey,
      combinationType,
    },
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-membership:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('update-membership:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getMembershipTypeStatsThunk = createAsyncThunk<
  MembershipTypeStats,
  string,
  ThunkApiConfig
>(
  'memberships/get-membership-type-stats',
  async (membershipTypeKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.membershipTypes.stats.getMembershipTypeStats,
      membershipTypeKey,
    );

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const getMembershipMemberListItemStats = createAsyncThunk<
  MembershipMemberListItemStats[],
  string,
  ThunkApiConfig
>(
  'memberships/get-membership-member-list-item-stats',
  async (membershipTypeKey, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.membershipTypes.stats.getMembershipMemberListItemStats,
      membershipTypeKey,
    );

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const createPeriodThunk = createAsyncThunk<
  string,
  MembershipPeriodForm,
  ThunkApiConfig
>('memberships/create-period', async (createPeriod, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.membershipTypes.periods.createPeriod, {
    createPeriod,
    membershipTypeKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('add-period:success'));
    return res.data;
  }

  thunkAPI.dispatch(setAlert('add-period:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updatePeriodThunk = createAsyncThunk<
  void,
  MembershipPeriodForm,
  ThunkApiConfig
>('memberships/update-period', async (updatePeriod, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.membershipTypes.periods.updatePeriod, {
    updatePeriod,
    membershipTypeKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-period:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('update-period:error'));
  return thunkAPI.rejectWithValue(res);
});

export const deletePeriodThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig<DeletePeriodErrorResponse>
>('memberships/delete-period', async (membershipPeriodKey, thunkAPI) => {
  const state = thunkAPI.getState();

  const membershipTypeKey =
    state.memberships.membershipType.data?.membershipType.membershipTypeKey;

  if (!membershipTypeKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v2.membershipTypes.periods.deletePeriod, {
    membershipPeriodKey,
    membershipTypeKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('delete-period:success'));
    return;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('delete-period:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('delete-period:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message.code !== 'number') {
    thunkAPI.dispatch(setAlert('delete-period:error'));
    return thunkAPI.rejectWithValue(res);
  }

  const membershipTypeData = state.memberships.membershipType.data;

  if (membershipTypeData?.type === 'integration') {
    return thunkAPI.rejectWithValue(res);
  }

  const period = membershipTypeData?.membershipType?.periods?.find(
    (period) => period.key === membershipPeriodKey,
  );

  if (!period) {
    thunkAPI.dispatch(setAlert('delete-period:error'));
    return thunkAPI.rejectWithValue(res);
  }

  switch (res.message.code) {
    case 0:
      thunkAPI.dispatch(
        setAlert('delete-period:invitations-not-zero', {
          count: res.message.metadata.count,
        }),
      );
      break;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getMembershipPurchaseListItemsThunk = createAsyncThunk<
  MembershipPurchaseListItem[],
  Pagination<MembershipPurchasesOrderByKey>,
  ThunkApiConfig
>(
  'memberships/get-membership-purchase-list-items',
  async (pagination, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const [currentPage, nextPage] = await getPageAndNextPage(
      membershipPurchaseListItemsAdapter
        .getSelectors()
        .selectAll(state.memberships.membershipPurchaseListItems.data),
      v2.membershipTypes.membershipPurchases.getMembershipPurchases,
      { membershipTypeKey, pagination },
    );

    if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
    if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

    return [...currentPage.data, ...nextPage.data];
  },
);

export const refundMembershipPurchaseThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig<RefundMembershipPeriodErrorResponse>
>(
  'memberships/refund-membership-purchase',
  async (membershipPurchaseKey, thunkAPI) => {
    const state = thunkAPI.getState();

    const membershipTypeKey =
      state.memberships.membershipType.data?.membershipType.membershipTypeKey;

    if (!membershipTypeKey) {
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v2.membershipTypes.membershipPurchases.refundMembershipPurchase,
      { membershipTypeKey, membershipPurchaseKey },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('refund-membership-period:success'));
      return;
    }

    if (!isClientProblem(res)) {
      thunkAPI.dispatch(setAlert('refund-membership-period:error'));
      return thunkAPI.rejectWithValue(res);
    }

    if (typeof res.message === 'string' || !res.message) {
      thunkAPI.dispatch(setAlert('refund-membership-period:error'));
      return thunkAPI.rejectWithValue(res);
    }

    thunkAPI.dispatch(
      setAlert('refund-membership-period:limit-exceeded', {
        refundLimit: fromScu(res.message.metadata.refundLimit),
      }),
    );
    return thunkAPI.rejectWithValue(res);
  },
);

export const getSuggestedMembershipsThunk = createAsyncThunk<
  MembershipApplicationRequirement[],
  { includeHidden: boolean },
  ThunkApiConfig
>('memberships/get-suggested-memberships', async (params, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.membershipTypes.suggestedMembershipApplicationRequirements
      .getSuggestedMembershipApplicationRequirements,
    params,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
