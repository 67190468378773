import React from 'react';

import { Styled } from './box.styled';
import { BoxProps, WrapBoxProps } from './box.types';

function _Box(props: BoxProps, ref: React.ForwardedRef<HTMLDivElement | null>) {
  const { children, ...rest } = props;

  return (
    <Styled.Box ref={ref} {...rest}>
      {children}
    </Styled.Box>
  );
}

function _WrapBox(
  props: WrapBoxProps,
  ref: React.ForwardedRef<HTMLDivElement | null>,
) {
  const { children, ...rest } = props;

  return (
    <Styled.WrapBox ref={ref} {...rest}>
      {children}
    </Styled.WrapBox>
  );
}

export const Box = React.forwardRef(_Box);
export const WrapBox = React.forwardRef(_WrapBox);
