import { Base64File, EditMeta, ImageData, Location } from '@orbiapp/components';

import { PartialMembershipType } from '../membership-type';
import { CombinationType } from '../shared';
import { OfferCategory } from './offer-category';

export interface CreatedOffer {
  offerKey: string;
  coverImage: ImageData;
}

export interface UpdatedOffer {
  coverImage: ImageData;
}

export interface CreateOfferForm {
  offerCategoryKey: string;
  title: string;
  description: string;
  coverImage: Base64File;
  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;
  code: string | null;
  link: string | null;
  locations: Location[] | null;
  requireStudentVerification: boolean;
  files:
    | {
        fileType: 'text/csv';
        fileName: string;
        codes: string[];
      }[]
    | null;
  isConsumable: boolean;
  consumeWaitMinutes: number | null;
  requiredMembershipTypes: {
    combinationType: CombinationType;
    membershipTypes: PartialMembershipType[];
  } | null;

  countryKeys: string[];
}

export interface UpdateOfferForm {
  offerCategoryKey: string;
  title: string;
  description: string;
  coverImage: Base64File;
  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;
  code: string | null;
  link: string | null;
  locations: Location[] | null;
  requireStudentVerification: boolean;
  files:
    | {
        fileType: 'text/csv';
        fileName: string;
        codes: string[];
      }[]
    | null;
  isConsumable: boolean;
  consumeWaitMinutes: number | null;
  requiredMembershipTypes: {
    combinationType: CombinationType;
    membershipTypes: PartialMembershipType[];
  } | null;

  countryKeys: string[];
}

type OfferOneCodeType = {
  type: 'one';
  code: string;
};

type OfferManyCodesType = {
  type: 'many';

  files: {
    fileType: 'text/csv';
    fileName: string;
    codes: string[];
    consumedCodeCount: number;
  }[];
};

type OfferCodeType = OfferOneCodeType | OfferManyCodesType;

type OfferOnlineType = {
  type: 'online';

  link: string;
  code: OfferCodeType | null;
};

type OfferLocalType = {
  type: 'consumable';

  consumeWaitMinutes: number | null;
};

export type Offer = {
  offerKey: string;

  editMeta: EditMeta;

  offerCategory: OfferCategory;

  disabledAt: number | null;

  title: string;
  description: string;
  coverImage: ImageData;
  startDate: number;
  endDate: number | null;
  contactName: string;
  contactEmail: string;
  locations: Location[] | null;
  requireStudentVerification: boolean;
  requiredMembershipTypes: {
    combinationType: CombinationType;
    membershipTypes: PartialMembershipType[];
  } | null;

  countryKeys: string[];
} & (OfferOnlineType | OfferLocalType);

export interface PartialOffer
  extends Pick<
    Offer,
    | 'offerKey'
    | 'editMeta'
    | 'title'
    | 'contactName'
    | 'disabledAt'
    | 'startDate'
    | 'endDate'
  > {}

export type OffersOrderByKey =
  | keyof Pick<PartialOffer, 'title' | 'contactName' | 'startDate' | 'endDate'>
  | 'updatedAt'
  | 'updatedBy';

export const offersSortableKeys: Record<OffersOrderByKey, OffersOrderByKey> = {
  title: 'title',
  contactName: 'contactName',
  startDate: 'startDate',
  endDate: 'endDate',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy',
};
