import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  CombinationType,
  CreateMembershipApplicationRequirementsResult,
  MembershipApplicationRequirement,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { CreateMembershipApplicationRequirementsResultDto } from './membership-application-requirements.dto';
import {
  createMembershipApplicationRequirementsMapper,
  createMembershipApplicationRequirementsResultMapper,
} from './membership-application-requirements.mappers';

export const updateCombinationType = async (params: {
  membershipTypeKey: string;
  combinationType: CombinationType;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, combinationType } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/membership-application-requirements/combination-type`,
    { combinationType },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createMembershipApplicationRequirements = async (params: {
  membershipTypeKey: string;
  membershipApplicationRequirements: MembershipApplicationRequirement[];
}): Promise<
  GeneralApiResultWithData<CreateMembershipApplicationRequirementsResult>
> => {
  const { membershipTypeKey, membershipApplicationRequirements } = params;

  const res = await apisauce.post<
    CreateMembershipApplicationRequirementsResultDto,
    any
  >(
    `/v2/membership-types/${membershipTypeKey}/membership-application-requirements`,
    createMembershipApplicationRequirementsMapper(
      membershipApplicationRequirements,
    ),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = createMembershipApplicationRequirementsResultMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createMembershipApplicationRequirements', {
      res: JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deleteRequirement = async (params: {
  membershipTypeKey: string;
  membershipApplicationRequirementKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipApplicationRequirementKey } = params;

  const res = await apisauce.delete<void, any>(
    `/v2/membership-types/${membershipTypeKey}/membership-application-requirements/${membershipApplicationRequirementKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
