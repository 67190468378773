import { INITIAL_DATA_STATE } from '../store.constants';
import { offerCategoriesAdapter, partialOffersAdapter } from './offers.adapter';
import { OffersState } from './offers.types';

export const initialOffersState: OffersState = {
  createOffer: INITIAL_DATA_STATE,
  offer: INITIAL_DATA_STATE,
  offers: {
    ...INITIAL_DATA_STATE,
    data: partialOffersAdapter.getInitialState(),
    pagination: {
      limit: 25,
      offset: 0,
      orderBy: 'startDate',
      sortOrder: 'desc',
    },
    search: '',
  },
  updateOffer: INITIAL_DATA_STATE,
  offerCategories: {
    ...INITIAL_DATA_STATE,
    data: offerCategoriesAdapter.getInitialState(),
  },

  toggleIsDisabled: INITIAL_DATA_STATE,

  offersLink: INITIAL_DATA_STATE,
};
