import {
  Box,
  ControlledInput,
  FormSection,
  FormSectionHeader,
  Icon,
  IconButton,
  Switch,
  Text,
  UploadCsvResult,
  isTxString,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { UploadCsv } from '../../../../../components';
import { CreateOfferForm } from '../../../../../models';
import { useOfferFormsSteps } from '../../helpers';

const CODE_MAX_LENGTH = 6;

function getCode(code: string) {
  if (code.length === CODE_MAX_LENGTH) {
    return code;
  }

  if (code.length > CODE_MAX_LENGTH) {
    return code.substring(0, CODE_MAX_LENGTH) + '...';
  }

  return code;
}

function PreviewCodes({ codes }: { codes: string[] }) {
  if (codes.length === 0) {
    return null;
  }

  return (
    <Box flex flexAlign="center" gap={2} flexWrap ml={-6}>
      <Text
        text={'(' + getCode(codes[0]) + (codes.length === 1 ? ')' : '')}
        variant="bodySm"
        color="offersFormUploadCsvRowCodesUploaded"
      />

      {codes.length > 1 && (
        <Text
          text="-"
          variant="bodySm"
          color="offersFormUploadCsvRowCodesUploaded"
        />
      )}

      {codes.length > 1 && (
        <Text
          text={getCode(codes[codes.length - 1]) + ')'}
          variant="bodySm"
          color="offersFormUploadCsvRowCodesUploaded"
        />
      )}

      <Text
        tx="label.offer-form.discount-codes.codes-uploaded"
        variant="bodySm"
        color="offersFormUploadCsvRowCodesUploaded"
      />
    </Box>
  );
}

function FileRow({
  file,
  index,
}: {
  file: { fileName: string; codes: string[]; consumedCodeCount: number | null };
  index: number;
}) {
  const formMethods = useFormContext<CreateOfferForm>();

  const onDelete = () => {
    const files = formMethods.getValues('files');

    if (!Array.isArray(files)) {
      return;
    }

    formMethods.setValue(
      'files',
      files.filter((_, i) => i !== index),
      {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      },
    );
  };

  return (
    <Box gap={8} flex flexAlign="center">
      <Icon name="document-text-outline" color="offersFormUploadCsvRowIcon" />
      <Text
        text={file.fileName}
        variant="bodySm"
        color="offersFormUploadCsvRowFileName"
      />
      <Text
        tx="label.offer-form.discount-codes.codes-uploaded-count"
        txArgs={{
          count: file.codes.length,
        }}
        variant="bodySm"
        color="offersFormUploadCsvRowCodesUploaded"
      />
      <PreviewCodes codes={file.codes} />
      {file.consumedCodeCount !== null && (
        <Text
          tx="label.offer-form.discount-codes.codes-consumed"
          txArgs={{ count: file.consumedCodeCount }}
          variant="bodySm"
          color="offersFormUploadCsvRowCodesConsumed"
        />
      )}
      <IconButton icon="x-circle-outline" onClick={onDelete} ml="auto" />
    </Box>
  );
}

function renderFileRow(
  file: { fileType: 'text/csv'; fileName: string; codes: string[] },
  index: number,
) {
  return (
    <FileRow
      key={`file-${index}`}
      file={{
        codes: file.codes,
        consumedCodeCount: null,
        fileName: file.fileName,
      }}
      index={index}
    />
  );
}

export function DiscountCodes() {
  const formMethods = useFormContext<CreateOfferForm>();
  const isConsumable = formMethods.watch('isConsumable');

  const steps = useOfferFormsSteps();

  if (isConsumable) {
    return null;
  }

  const files = formMethods.watch('files');
  const uploadFilesMode = Array.isArray(files);

  const onToggleClick = () => {
    if (uploadFilesMode) {
      formMethods.setValue('files', null, {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      });

      return;
    }

    formMethods.setValue('files', [], {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });

    formMethods.setValue('code', null, {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });
  };

  const onUpload = (res: UploadCsvResult) => {
    if (res.rows.length === 0) {
      return;
    }

    const files = formMethods.getValues('files');

    if (!Array.isArray(files)) {
      return;
    }

    formMethods.setValue(
      'files',
      [...files, { codes: res.rows, fileName: res.name, fileType: 'text/csv' }],
      {
        shouldDirty: true,
        shouldValidate: formMethods.formState.isSubmitted,
      },
    );
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Box flex gap={4}>
          <Text
            tx="label.offer-form.headers.discount-codes"
            variant="bodyMdBold"
            color="formSectionHeader"
            txArgs={{ step: steps.discountCodes! }}
          />
          <Text
            tx="label.general.optional"
            variant="bodyMdItalic"
            color="formSectionHeader"
          />
        </Box>
      </FormSectionHeader>

      <Box flex flexDirection="column" gap={16}>
        <Switch
          checked={uploadFilesMode}
          tx="label.offer-form.discount-codes.switch-text"
          onClick={onToggleClick}
        />

        {uploadFilesMode ? (
          <React.Fragment>
            <Box flex gap={8} flexDirection="column">
              {files.map(renderFileRow)}
            </Box>
            <UploadCsv onUpload={onUpload} />

            {isTxString(formMethods.formState.errors.files?.message) && (
              <Text
                color="errorLabel"
                tx={formMethods.formState.errors.files?.message}
              />
            )}
          </React.Fragment>
        ) : (
          <ControlledInput
            labelTx="label.offer-form.discount-codes.code"
            name="code"
          />
        )}
      </Box>
    </FormSection>
  );
}
