import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import { postCommentsAdapter, postsAdapter } from './posts.adapter';

export namespace PostsSelector {
  const selectSelf = (state: RootState) => state.posts.posts;

  export const selectIsLoading = createSelector(
    selectSelf,
    (posts) => posts.status === 'pending',
  );

  const selectData = createSelector(selectSelf, (posts) => posts.items);

  const postsSelectors = postsAdapter.getSelectors(selectData);

  export const selectIsEmpty = createSelector(
    selectSelf,
    (posts) => posts.items.ids.length === 0,
  );

  export const selectAll = postsSelectors.selectAll;

  export const selectById = postsSelectors.selectById;

  export const selectIds = postsSelectors.selectIds;

  export const selectStartCursor = createSelector(
    selectSelf,
    (posts) => posts.pageInfo.startCursor,
  );

  export const selectEndCursor = createSelector(
    selectSelf,
    (posts) => posts.pageInfo.endCursor,
  );

  export const selectOrderBy = createSelector(
    selectSelf,
    (posts) => posts.orderBy,
  );

  export const selectSortOrder = createSelector(
    selectSelf,
    (posts) => posts.sortOrder,
  );

  export const selectPageSize = createSelector(
    selectSelf,
    (posts) => posts.pageSize,
  );

  export const selectHasPreviousPage = createSelector(
    selectSelf,
    (posts) => posts.pageInfo.hasPreviousPage,
  );

  export const selectHasNextPage = createSelector(
    selectSelf,
    (posts) => posts.pageInfo.hasNextPage,
  );

  export const selectTotalCount = createSelector(
    selectSelf,
    (posts) => posts.totalCount,
  );

  export const selectActivityKeys = createSelector(
    selectSelf,
    (posts) => posts.extra.activityKeys,
  );

  export const selectQ = createSelector(selectSelf, (posts) => posts.extra.q);

  export const selectTargetType = createSelector(
    selectSelf,
    (posts) => posts.extra.targetType,
  );
}

export namespace CreatePostSelector {
  const selectSelf = (state: RootState) => state.posts.createPost;

  export const selectStatus = createSelector(
    selectSelf,
    (createPost) => createPost.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (createPost) => createPost.error,
  );
}

export namespace PostSelector {
  const selectSelf = (state: RootState) => state.posts.post;

  export const selectIsLoading = createSelector(
    selectSelf,
    (post) => post.status === 'pending',
  );

  export const selectData = createSelector(selectSelf, (post) => post.data);

  export const selectError = createSelector(selectSelf, (post) => post.error);

  export const selectCommentCount = createSelector(
    selectData,
    (post) => post?.postCommentCount ?? 0,
  );

  export const selectActorKey = createSelector(
    selectData,
    (post) => post?.actor.actorKey ?? null,
  );

  export const selectMessage = createSelector(
    selectData,
    (post) => post?.message ?? null,
  );

  export const selectPostTarget = createSelector(
    selectData,
    (post) => post?.target ?? null,
  );

  export const selectPostKey = createSelector(
    selectData,
    (post) => post?.postKey,
  );

  export const selectIsDeleted = createSelector(
    selectData,
    (post) => post?.isDeleted,
  );
}

export namespace UpdatePostSelector {
  const selectSelf = (state: RootState) => state.posts.updatePost;

  export const selectStatus = createSelector(
    selectSelf,
    (updatePost) => updatePost.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (updatePost) => updatePost.error,
  );
}

export namespace UpdatePostCommentSelector {
  const selectSelf = (state: RootState) => state.posts.updatePostComment;

  export const selectStatus = createSelector(
    selectSelf,
    (updatePostComment) => updatePostComment.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (updatePostComment) => updatePostComment.error,
  );
}

export namespace DeletePostSelector {
  const selectSelf = (state: RootState) => state.posts.deletePostComment;

  export const selectStatus = createSelector(
    selectSelf,
    (deletePostComment) => deletePostComment.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (deletePostComment) => deletePostComment.error,
  );
}

export namespace PostCommentsSelector {
  const selectSelf = (state: RootState) => state.posts.postComments;

  export const selectStatus = createSelector(
    selectSelf,
    (postComments) => postComments.status,
  );

  const selectData = createSelector(selectSelf, (posts) => posts.items);

  const postCommentsSelectors = postCommentsAdapter.getSelectors(selectData);

  export const selectIsEmpty = createSelector(
    selectSelf,
    (posts) => posts.items.ids.length === 0,
  );

  export const selectAll = postCommentsSelectors.selectAll;

  export const selectById = postCommentsSelectors.selectById;

  export const selectIds = postCommentsSelectors.selectIds;

  export const selectPageSize = createSelector(
    selectSelf,
    (postComments) => postComments.pageSize,
  );

  export const selectOrderBy = createSelector(
    selectSelf,
    (postComments) => postComments.orderBy,
  );

  export const selectStartCursor = createSelector(
    selectSelf,
    (postComments) => postComments.pageInfo.startCursor,
  );

  export const selectSortOrder = createSelector(
    selectSelf,
    (postComments) => postComments.sortOrder,
  );
}

export namespace DeletePostCommentSelector {
  const selectSelf = (state: RootState) => state.posts.deletePostComment;

  export const selectStatus = createSelector(
    selectSelf,
    (deletePostComment) => deletePostComment.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (deletePostComment) => deletePostComment.error,
  );
}

export namespace CreatePostCommentSelector {
  const selectSelf = (state: RootState) => state.posts.createPostComment;

  export const selectStatus = createSelector(
    selectSelf,
    (createPostComment) => createPostComment.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (createPostComment) => createPostComment.error,
  );
}
