import { Box } from '@orbiapp/components';
import styled from 'styled-components';

const PickPeriodsGrid = styled(Box)`
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

export const Styled = { PickPeriodsGrid };
