import { Box } from '../box';
import { IconButton } from '../icon-button';
import { TinySelectOptionProps } from '../tiny-select';
import { PaginatorProps } from './paginator.types';

const pageSizes = [10, 25, 50, 100] as const;
export type PageSize = (typeof pageSizes)[number];

export const paginatorOptions: TinySelectOptionProps<PageSize>[] =
  pageSizes.map((size) => ({ value: size, text: size.toString() }));

export function Paginator(props: PaginatorProps) {
  const {
    hasNextPage,
    hasPrevPage,
    onGoToNextPage,
    onGoToPreviousPage,
    onGoToFirstPage,
    onGoToLastPage,
  } = props;

  return (
    <Box gap={48} flex>
      <Box gap={8} flex>
        {onGoToFirstPage && (
          <IconButton
            icon="chevron-double-left"
            onClick={onGoToFirstPage}
            disabled={!hasPrevPage}
          />
        )}
        <IconButton
          icon="chevron-left"
          onClick={onGoToPreviousPage}
          disabled={!hasPrevPage}
        />
      </Box>

      <Box gap={8} flex>
        <IconButton
          icon="chevron-right"
          onClick={onGoToNextPage}
          disabled={!hasNextPage}
        />
        {onGoToLastPage && (
          <IconButton
            icon="chevron-double-right"
            onClick={onGoToLastPage}
            disabled={!hasNextPage}
          />
        )}
      </Box>
    </Box>
  );
}
