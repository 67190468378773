import { Pagination } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Question, QuestionOrderByKey } from '../../models';
import { OrbiApi, getPageAndNextPage, v1 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { questionsAdapter } from './questions.adapter';
import { UpdateQuestionThunkParams } from './questions.types';

export const getUnhandledQuestionsCountThunk = createAsyncThunk<
  number,
  undefined,
  ThunkApiConfig
>('questions/get/unhandledCount', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.questions.getUnhandledQuestionsCount,
    undefined,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getQuestionsThunk = createAsyncThunk<
  Question[],
  Pagination<QuestionOrderByKey>,
  ThunkApiConfig
>('questions/get', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();
  const hideHandled = state.questions.questions.hideHandled;

  const [currentPage, nextPage] = await getPageAndNextPage(
    questionsAdapter.getSelectors().selectAll(state.questions.questions.data),
    v1.questions.getQuestions,
    { hideHandled, pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getQuestionsByActivityThunk = createAsyncThunk<
  Question[],
  string,
  ThunkApiConfig
>('questions/get/activity', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.questions.getQuestionsByActivity,
    activityKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const updateQuestionThunk = createAsyncThunk<
  void,
  UpdateQuestionThunkParams,
  ThunkApiConfig
>('questions/put', async (updateQuestionThunkParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const previousAnswer = state.questions.selectedQuestion?.answer;

  const { activityKey, questionKey, answer } = updateQuestionThunkParams;

  const res = await OrbiApi.call(v1.questions.updateQuestion, {
    activityKey,
    questionKey,
    questionForm: { answer },
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert(
        previousAnswer ? 'update-question:success' : 'answer-question:success',
      ),
    );
    thunkAPI.dispatch(getUnhandledQuestionsCountThunk());
    return;
  }

  thunkAPI.dispatch(
    setAlert(
      previousAnswer ? 'update-question:error' : 'answer-question:error',
    ),
  );
  return thunkAPI.rejectWithValue(res);
});

export const setQuestionToggleIsHiddenThunk = createAsyncThunk<
  void,
  { activityKey: string; questionKey: string; isHidden: boolean },
  ThunkApiConfig
>(
  'questions/put/question/toggleIsHidden',
  async (toggleIsHiddenParams, thunkAPI) => {
    const { activityKey, questionKey, isHidden } = toggleIsHiddenParams;

    const res = await OrbiApi.call(v1.questions.setQuestionToggleIsHidden, {
      activityKey,
      isHidden,
      questionKey,
    });

    if (res.kind === 'ok') {
      thunkAPI.dispatch(getUnhandledQuestionsCountThunk());
      return;
    }

    return thunkAPI.rejectWithValue(res);
  },
);
