import { joiResolver } from '@hookform/resolvers/joi';
import { FormBox, InnerContentContainerAlt, Time } from '@orbiapp/components';
import { FormProvider, useForm } from 'react-hook-form';

import { NavBlocker } from '../../../../components';
import { CreateOfferForm, CreateOfferValidation } from '../../../../models';
import { CreateOfferSelector, useSelector } from '../../../../store';
import {
  ContactDetails,
  Countries,
  DiscountCodes,
  EndDate,
  GeneralInformation,
  Locations,
  OfferCategories,
  RequiredMemberships,
  StudentVerification,
  TypeOfOffer,
} from '../components';
import { OfferFormHeader } from './offer-form-header';

export function OfferForm() {
  const createdOfferKey = useSelector(CreateOfferSelector.selectData);

  const formMethods = useForm<CreateOfferForm>({
    defaultValues: {
      code: null,
      consumeWaitMinutes: null,
      contactEmail: '',
      contactName: '',
      description: '',
      endDate: null,
      files: null,
      isConsumable: false,
      link: null,
      locations: [],
      coverImage: undefined,
      offerCategoryKey: undefined,
      requireStudentVerification: true,
      requiredMembershipTypes: null,
      startDate: Date.now() + Time.Day,
      title: '',
      countryKeys: [],
    },
    resolver: joiResolver(CreateOfferValidation),
  });

  return (
    <FormProvider {...formMethods}>
      <InnerContentContainerAlt>
        {!createdOfferKey && (
          <NavBlocker shouldBlock={formMethods.formState.isDirty} />
        )}

        <OfferFormHeader />

        <FormBox>
          <GeneralInformation />

          <ContactDetails />

          <OfferCategories />

          <Countries />

          <TypeOfOffer />

          <DiscountCodes />

          <Locations />

          <StudentVerification />

          <EndDate />

          <RequiredMemberships />
        </FormBox>
      </InnerContentContainerAlt>
    </FormProvider>
  );
}
