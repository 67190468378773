import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { StyledImageProps } from './image.types';

const Image = styled.img<StyledImageProps>`
  ${(props) => {
    const { aspectRatio, objectFit } = props;

    return css`
      ${baseComponentStyles}

      ${aspectRatio &&
      css`
        aspect-ratio: ${aspectRatio};
      `}

      ${objectFit &&
      css`
        object-fit: ${objectFit};
      `}
    `;
  }}
`;

export const Styled = { Image };
