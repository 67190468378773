import React, { useCallback } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';

import { constructBase64 } from '../../utils/image';
import { Box } from '../box';
import { Button } from '../button';
import {
  Modal,
  ModalBodyContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
} from '../modal';
import { Range } from '../range';
import {
  Base64File,
  CropAreaState,
  FileType,
  ImageCropperProps,
  ImageCropperReducerAction,
  ImageCropperState,
} from './image-cropper.types';

export const SLIDER_SETTINGS = {
  min: 100,
  max: 300,
  step: 1,
};

function getImageFromSrc(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));

    image.setAttribute('crossOrigin', 'anonymous');
    image.src = src;
  });
}

export async function getCroppedImg(
  src: string,
  crop: CropAreaState,
  fileType: FileType,
) {
  const image = await getImageFromSrc(src);

  const canvas = document.createElement('canvas');
  canvas.width = crop.width;
  canvas.height = crop.height;

  const ctx = canvas.getContext('2d');

  ctx?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height,
  );

  const base64 = canvas.toDataURL(fileType, 0.5);

  return base64;
}

function reducer(
  state: ImageCropperState,
  action: ImageCropperReducerAction,
): ImageCropperState {
  switch (action.type) {
    case 'set:zoom':
      return { ...state, zoom: action.payload };

    case 'set:crop':
      return { ...state, ...action.payload };

    case 'set:croparea':
      return { ...state, croparea: action.payload };

    case 'increment:zoom':
      if (state.zoom === SLIDER_SETTINGS.max / 100) return state;
      return { ...state, zoom: state.zoom + SLIDER_SETTINGS.step / 100 };

    case 'decrement:zoom':
      if (state.zoom === SLIDER_SETTINGS.min / 100) return state;
      return { ...state, zoom: state.zoom - SLIDER_SETTINGS.step / 100 };
  }
}

export function ImageCropper(props: ImageCropperProps) {
  const {
    closeText,
    closeTx,
    closeTxArgs,
    cropState,
    isOpen,
    onClose,
    onSave,
    saveText,
    saveTx,
    saveTxArgs,
    titleText,
    titleTx,
    titleTxArgs,
  } = props;

  const [imageCropperState, dispatch] = React.useReducer<
    React.Reducer<ImageCropperState, ImageCropperReducerAction>
  >(reducer, {
    x: cropState.x,
    y: cropState.y,
    zoom: cropState.zoom,
    aspect: cropState.aspect,
    croparea: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  });

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [base64File, setBase64File] = React.useState<Base64File | null>(null);

  const incrementZoom = () => dispatch({ type: 'increment:zoom' });
  const decrementZoom = () => dispatch({ type: 'decrement:zoom' });

  const setZoom = (zoom: number) => {
    dispatch({ type: 'set:zoom', payload: zoom });
  };

  const onSliderChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setZoom(parseInt(e.target.value) / 100);
  };

  const setCrop = (crop: Point) => {
    dispatch({ type: 'set:crop', payload: crop });
  };

  const onCropComplete = React.useCallback(
    (_: Area, croparea: CropAreaState) => {
      dispatch({ type: 'set:croparea', payload: croparea });
    },
    [],
  );

  const onSaveClick = useCallback(async () => {
    setIsLoading(true);

    if (!base64File) {
      setIsLoading(false);
      return;
    }

    const croppedImage = await getCroppedImg(
      constructBase64(base64File),
      {
        height: imageCropperState.croparea.height,
        width: imageCropperState.croparea.width,
        x: imageCropperState.croparea.x,
        y: imageCropperState.croparea.y,
      },
      base64File.fileType,
    );

    if (!croppedImage) {
      setIsLoading(false);
      return;
    }

    const base64 = croppedImage?.split(',')[1];

    onSave({
      aspect: imageCropperState.aspect,
      x: imageCropperState.x,
      y: imageCropperState.y,
      zoom: imageCropperState.zoom,
      base64File: {
        base64,
        fileSize: base64File.fileSize,
        fileName: base64File.fileName,
        fileType: base64File.fileType,
      },
    });

    setIsLoading(false);
  }, [
    base64File,
    imageCropperState.aspect,
    imageCropperState.croparea.height,
    imageCropperState.croparea.width,
    imageCropperState.croparea.x,
    imageCropperState.croparea.y,
    imageCropperState.x,
    imageCropperState.y,
    imageCropperState.zoom,
    onSave,
  ]);

  React.useEffect(() => {
    if (
      cropState.base64File &&
      base64File &&
      cropState.base64File.fileName === base64File.fileName
    )
      return;

    setBase64File(cropState.base64File);
  }, [base64File, cropState.base64File, cropState.base64File?.fileName]);

  React.useEffect(() => {
    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSaveClick();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEnterPress);
    } else {
      document.removeEventListener('keydown', handleEnterPress);
    }

    return () => {
      document.removeEventListener('keydown', handleEnterPress);
    };
  }, [onSaveClick, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeaderContainer>
        <ModalTitle text={titleText} tx={titleTx} txArgs={titleTxArgs} />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <Box maxWidth="100%" minHeight={400} width={760} relative>
          <Cropper
            aspect={cropState.aspect}
            crop={{ x: imageCropperState.x, y: imageCropperState.y }}
            cropShape={cropState.aspect === 1 ? 'round' : 'rect'}
            image={constructBase64(base64File)}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid
            zoom={imageCropperState.zoom}
          />
        </Box>

        <Range
          max={SLIDER_SETTINGS.max}
          min={SLIDER_SETTINGS.min}
          onChange={onSliderChange}
          onMinusClick={decrementZoom}
          onPlusClick={incrementZoom}
          step={SLIDER_SETTINGS.step}
          value={imageCropperState.zoom * 100}
        />
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          onClick={onClose}
          text={closeText}
          tx={closeTx}
          txArgs={closeTxArgs}
          variant="tertiary"
        />
        <Button
          isLoading={isLoading}
          onClick={onSaveClick}
          text={saveText}
          tx={saveTx}
          txArgs={saveTxArgs}
          variant="primary"
        />
      </ModalFooterContainer>
    </Modal>
  );
}
