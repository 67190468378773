import { ActivityListItem } from '../../../../../../models';

export const activityListItemMapper = (
  activityListItem: ActivityListItem,
): ActivityListItem => ({
  departmentKey: activityListItem.departmentKey,
  endDate: activityListItem.endDate,
  activityKey: activityListItem.activityKey,
  totalTicketCount: activityListItem.totalTicketCount,
  startDate: activityListItem.startDate,
  title: activityListItem.title,
  stats: {
    numberOfClicks: activityListItem.stats.numberOfClicks,
    numberOfQuestions: activityListItem.stats.numberOfQuestions,
    numberOfSoldTickets: activityListItem.stats.numberOfSoldTickets,
  },
});
