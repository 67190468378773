import { Color } from '@orbiapp/theme';
import React from 'react';

import { Styled } from './input-label.styled';
import {
  GetInputLabelColorOptions,
  InputLabelProps,
} from './input-label.types';

function getInputLabelColor(options: GetInputLabelColorOptions): Color {
  const { disabled, hasError } = options;

  if (disabled) {
    return 'inputLabelDisabled';
  }

  if (hasError) {
    return 'inputLabelError';
  }

  return 'inputLabel';
}

function _InputLabel(
  props: InputLabelProps,
  ref: React.ForwardedRef<HTMLLabelElement>,
) {
  const { color, disabled, hasError, ...rest } = props;

  const inputLabelColor =
    color ??
    getInputLabelColor({
      disabled,
      hasError,
    });

  return (
    <Styled.InputLabel
      color={inputLabelColor}
      maxWidth="32ch"
      overflow="hidden"
      ref={ref}
      textOverflow="ellipsis"
      variant="bodyMd"
      whiteSpace="nowrap"
      {...rest}
    />
  );
}

export const InputLabel = React.forwardRef(_InputLabel);
