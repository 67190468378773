export namespace DepartmentErrors {
  export const coverImage = {
    'object.base': 'errors.cover-image.empty',
  };

  export const name = {
    'string.empty': 'errors.name.empty',
  };

  export const facebookUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const instagramUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const twitterUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const youtubeUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const websiteUrl = {
    'string.uri': 'errors.url.invalid',
  };

  export const logo = {
    'object.base': 'errors.logo.empty',
  };
}
