import { Pagination, getPaginatorProps } from '@orbiapp/components';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import React from 'react';

import { Thunk, useDispatch } from '../store';

interface UseDataGridPaginationProps<DataType, OrderBy> {
  data: DataType[];
  pagination: Pagination<OrderBy>;
  reset: ActionCreatorWithoutPayload<string>;
  thunk: Thunk<DataType, OrderBy>;
}

export function useDataGridPagination<
  DataType,
  OrderBy extends string | undefined,
>(props: UseDataGridPaginationProps<DataType, OrderBy>) {
  const { data, pagination, reset, thunk } = props;

  const dispatch = useDispatch();

  const onSort = React.useCallback(
    (orderBy: string) => {
      if (!orderBy) return;

      dispatch(reset());
      dispatch(
        thunk({
          limit: pagination.limit,
          offset: 0,
          orderBy: orderBy as OrderBy,
          sortOrder: pagination.sortOrder === 'asc' ? 'desc' : 'asc',
        }),
      );
    },
    [dispatch, pagination.limit, pagination.sortOrder, reset, thunk],
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      dispatch(reset());
      dispatch(thunk({ ...pagination, offset: 0, limit: pageSize }));
    },
    [dispatch, pagination, reset, thunk],
  );

  const onPaginate = React.useCallback(
    (newPage: number) =>
      dispatch(thunk({ ...pagination, offset: newPage * pagination.limit })),
    [pagination, thunk, dispatch],
  );

  React.useEffect(() => {
    reset();
    onPaginate(pagination.offset / pagination.limit);
  }, []); // eslint-disable-line

  return {
    onPageSizeChange,
    onPaginate,
    onSort,
    paginatorProps: getPaginatorProps(data, pagination),
    rows: data.slice(pagination.offset, pagination.offset + pagination.limit),
  };
}
