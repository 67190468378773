import { getUID } from '@orbiapp/components';

import {
  MembershipCheckboxQuestionForm,
  MembershipFreetextQuestionForm,
  MembershipMultichoiceQuestionForm,
  MembershipTypeQuestionForm,
  RequestedInfoType,
} from '../models';

export function getMembershipRequestedInfoDefaultValues(
  type: RequestedInfoType,
): MembershipTypeQuestionForm {
  switch (type) {
    case RequestedInfoType.Checkbox:
      const checkbox: MembershipCheckboxQuestionForm = {
        isRequired: false,
        key: getUID(),
        question: '',
        type: RequestedInfoType.Checkbox,
      };

      return checkbox;
    case RequestedInfoType.FreeText:
      const freeText: MembershipFreetextQuestionForm = {
        isRequired: false,
        key: getUID(),
        question: '',
        type: RequestedInfoType.FreeText,
      };

      return freeText;
    case RequestedInfoType.MultiChoice:
      const multiChoice: MembershipMultichoiceQuestionForm = {
        isRequired: false,
        key: getUID(),
        options: [
          {
            index: 0,
            label: '',
            key: getUID(),
            isNew: true,
          },
          {
            index: 1,
            label: '',
            key: getUID(),
            isNew: true,
          },
        ],
        question: '',
        type: RequestedInfoType.MultiChoice,
      };

      return multiChoice;
  }
}

export const getRequestedInfoTypeTx = (
  requestedInfoType: RequestedInfoType,
): TxString => {
  switch (requestedInfoType) {
    case RequestedInfoType.Checkbox:
      return 'label.create-activity.request-more-info.options.checkbox';
    case RequestedInfoType.FreeText:
      return 'label.create-activity.request-more-info.options.free-text';
    case RequestedInfoType.MultiChoice:
      return 'label.create-activity.request-more-info.options.multi-choice';
  }
};

export const getRequestedInfoTypeTooltipTx = (
  requestedInfoType: RequestedInfoType,
): TxString => {
  switch (requestedInfoType) {
    case RequestedInfoType.Checkbox:
      return 'label.create-activity.request-more-info.pick-request-type.checkbox';
    case RequestedInfoType.FreeText:
      return 'label.create-activity.request-more-info.pick-request-type.free-text';
    case RequestedInfoType.MultiChoice:
      return 'label.create-activity.request-more-info.pick-request-type.multi-choice';
  }
};
