import React from 'react';

import { theme } from '../../theme';
import { BreakpointValues } from './responsive-box.types';

export function getActiveBreakpoint(
  ww: number,
  breakpointValues: BreakpointValues,
): keyof BreakpointValues | null {
  const orderedBreakpoints: {
    key: keyof BreakpointValues;
    maxWidth: number;
  }[] = [
    { key: 'children', maxWidth: theme.breakpoints.xxs },
    { key: 'xxs', maxWidth: theme.breakpoints.xs },
    { key: 'xs', maxWidth: theme.breakpoints.sm },
    { key: 'sm', maxWidth: theme.breakpoints.md },
    { key: 'md', maxWidth: theme.breakpoints.lg },
    { key: 'lg', maxWidth: theme.breakpoints.xl },
    { key: 'xl', maxWidth: Infinity },
  ];

  for (let i = 0; i < orderedBreakpoints.length; i++) {
    const { maxWidth } = orderedBreakpoints[i];

    if (ww < maxWidth) {
      for (let j = i; j >= 0; j--) {
        const { key } = orderedBreakpoints[j];
        const element = breakpointValues[key];

        if (React.isValidElement(element) || element === null) {
          return key;
        }
      }

      break;
    }
  }

  return null;
}
