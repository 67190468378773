import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { stringOrPx } from '../../../utils';
import { Box } from '../../box';
import { TABLE_ROW_HEIGHT } from '../table.constants';
import { StyledTableCellProps } from './table-cell.types';

const TableCellLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  white-space: nowrap;
  text-align: left;
  display: block;
  height: ${TABLE_ROW_HEIGHT}px;
`;

const TableCell = styled.td<StyledTableCellProps>`
  ${(props) => {
    const { fixedLeft, fixedRight, width } = props;

    return css`
      background-color: inherit;
      white-space: nowrap;
      text-align: left;
      height: ${TABLE_ROW_HEIGHT}px;
      max-width: ${stringOrPx(width)};
      width: ${stringOrPx(width)};
      min-width: ${stringOrPx(width)};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ${fixedLeft &&
      css`
        z-index: 5;
        position: sticky;
        left: 0;
      `}

      ${fixedRight &&
      css`
        position: sticky;
        z-index: 5;
        right: 0;
      `}
    `;
  }}
`;

const TableCellContent = styled(Box)<{ hoverCell?: boolean }>`
  ${(props) => {
    const { hoverCell, theme } = props;

    return css`
      @media (min-width: ${theme.breakpoints.xs}px) {
        ${hoverCell &&
        css`
          opacity: 0;
        `}
      }
    `;
  }}
`;

export const Styled = { TableCell, TableCellContent, TableCellLink };
