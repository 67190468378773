import * as applications from './applications';
import * as checkboxQuestions from './checkbox-questions';
import * as createMembershipType from './create-membership-type.endpoints';
import * as freetextQuestions from './freetext-questions';
import * as generalInfo from './general-info';
import * as integrated from './integrated';
import * as invites from './invites';
import * as members from './members';
import * as membershipApplicationRequirements from './membership-application-requirements';
import * as membershipPurchases from './membership-purchases';
import * as _membershipTypes from './membership-types.endpoints';
import * as multichoiceQuestions from './multichoice-questions';
import * as periods from './periods';
import * as stats from './stats';
import * as suggestedMembershipApplicationRequirements from './suggested-membership-application-requirements';

export const membershipTypes = {
  ..._membershipTypes,
  ...createMembershipType,
  applications,
  checkboxQuestions,
  freetextQuestions,
  generalInfo,
  integrated,
  invites,
  members,
  membershipApplicationRequirements,
  membershipPurchases,
  multichoiceQuestions,
  periods,
  stats,
  suggestedMembershipApplicationRequirements,
};
