import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Icon } from '../icon';
import { MenuItem } from '../menu';
import { Text } from '../text';

const SwitchLink = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      display: none;
      color: ${theme.colors['linkSecondaryLabel']};
      text-decoration: underline;
      text-decoration-color: ${theme.colors['linkSecondaryDecoration']};
    `;
  }}
`;

const WorkspaceMenuItem = styled(MenuItem)`
  :hover {
    ${SwitchLink} {
      display: flex;
    }
  }
`;

const SelectWorkspaceBoxIcon = styled(Icon)``;

const SelectWorkspaceBoxText = styled(Text)``;

const SelectWorkspaceBox = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      background-color: ${theme.colors['selectWorkspaceBackground']};

      ${SelectWorkspaceBoxIcon} {
        color: ${theme.colors['selectWorkspaceIcon']};
      }

      ${SelectWorkspaceBoxText} {
        color: ${theme.colors['selectWorkspaceLabel']};
      }

      ${isOpen &&
      css`
        background-color: ${theme.colors['selectWorkspaceBackgroundToggled']};

        ${SelectWorkspaceBoxIcon} {
          color: ${theme.colors['selectWorkspaceIconToggled']};
        }

        ${SelectWorkspaceBoxText} {
          color: ${theme.colors['selectWorkspaceLabelToggled']};
        }
      `}

      :hover {
        background-color: ${theme.colors['selectWorkspaceBackgroundHover']};

        ${SelectWorkspaceBoxIcon} {
          color: ${theme.colors['selectWorkspaceIconHover']};
        }

        ${SelectWorkspaceBoxText} {
          color: ${theme.colors['selectWorkspaceLabelHover']};
        }
      }

      :active {
        background-color: ${theme.colors['selectWorkspaceBackgroundActive']};

        ${SelectWorkspaceBoxIcon} {
          color: ${theme.colors['selectWorkspaceIconActive']};
        }

        ${SelectWorkspaceBoxText} {
          color: ${theme.colors['selectWorkspaceLabelActive']};
        }
      }
    `;
  }}
`;

export const Styled = {
  SelectWorkspaceBox,
  SelectWorkspaceBoxIcon,
  SelectWorkspaceBoxText,
  SwitchLink,
  WorkspaceMenuItem,
};
