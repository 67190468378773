import {
  MembershipApplicationAnswer,
  MembershipMemberListItemStats,
  MembershipStatsPeriod,
  MembershipTypeStats,
  NewMemberCountMonthly,
  RequestedInfoType,
} from '../../../../../../models';
import {
  MembershipMemberListItemStatsDto,
  MembershipTypeStatsDto,
} from './stats.dto';

export const membershipMemberListItemStatsMapper = (
  membershipMemberListItemStats: MembershipMemberListItemStatsDto,
): MembershipMemberListItemStats => {
  const answers: MembershipApplicationAnswer[] = [];
  const periods: MembershipStatsPeriod[] = [];

  membershipMemberListItemStats.periods.forEach((period) => {
    periods.push({
      createdAt: period.createdAt,
      key: period.membershipPeriodKey,
      name: period.name,
      validFrom: period.validFrom,
      validTo: period.validTo,
    });
  });

  membershipMemberListItemStats.checkboxAnswers?.forEach((answer) => {
    answers.push({
      index: answer.index,
      isChecked: answer.isChecked,
      key: answer.checkboxQuestionKey,
      question: answer.question,
      type: RequestedInfoType.Checkbox,
    });
  });

  membershipMemberListItemStats.freetextAnswers?.forEach((answer) => {
    answers.push({
      answer: answer.answer,
      index: answer.index,
      key: answer.freetextQuestionKey,
      question: answer.question,
      type: RequestedInfoType.FreeText,
    });
  });

  membershipMemberListItemStats.multichoiceAnswers?.forEach((answer) => {
    answers.push({
      index: answer.index,
      key: answer.multichoiceQuestionKey,
      label: answer.label,
      question: answer.question,
      type: RequestedInfoType.MultiChoice,
    });
  });

  return {
    answers: answers.length ? answers : null,
    email: membershipMemberListItemStats.email,
    foodPreferences: membershipMemberListItemStats.foodPreferences,
    fullName: membershipMemberListItemStats.fullName,
    membershipId: membershipMemberListItemStats.membershipId,
    periods,
    segmentation: membershipMemberListItemStats.segmentation,
    semester: membershipMemberListItemStats.semester,
    userKey: membershipMemberListItemStats.userKey,
  };
};

export const membershipTypeStatsMapper = (
  membershipTypeStatsDto: MembershipTypeStatsDto,
): MembershipTypeStats => {
  const newMembersMonthly: NewMemberCountMonthly[] =
    membershipTypeStatsDto.newMembersMonthly.map((newMemberCountMonthly) => ({
      month: newMemberCountMonthly.month,
      count: newMemberCountMonthly.count,
    }));

  return {
    activeMemberCount: membershipTypeStatsDto.activeMemberCount,
    totalMemberCount: membershipTypeStatsDto.totalMemberCount,
    newMembersMonthly,
  };
};
