import {
  ContentContainer,
  InnerPageContainer,
  PageContainer,
} from '@orbiapp/components';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { activityActions, useDispatch } from '../../../../store';
import { AddCoHostsSidebar, CoHostsSidebarProvider } from './co-hosting';
import { ActivityFormNavBlocker, TabHeader } from './components';
import { ActivityFormProvider } from './create-activity.context';
import { CreateActivityProps } from './create-activity.types';
import {
  ActivityLinkSidebar,
  ActivityLinkSidebarProvider,
} from './description';
import {
  PickRequestedInfoFormSidebar,
  PickRequestedInfoTypeProvider,
  PickRequestedInfoTypeSidebar,
  RequestedInfoFormProvider,
} from './request-more-info';
import { TicketFormSidebar, TicketsSidebarProvider } from './tickets';

export function CreateActivity(props: CreateActivityProps) {
  const { mode = 'create', defaultValues } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(activityActions.clearCreateActivity());
    };
  }, [dispatch]);

  React.useEffect(() => {
    if (mode !== 'draft') {
      dispatch(activityActions.clearActivityDraft());
    }
  }, [dispatch, mode]);

  return (
    <ActivityFormProvider mode={mode} defaultValues={defaultValues}>
      <TicketsSidebarProvider>
        <CoHostsSidebarProvider>
          <PickRequestedInfoTypeProvider>
            <RequestedInfoFormProvider>
              <ActivityLinkSidebarProvider>
                <ActivityFormNavBlocker />

                <PageContainer>
                  <TabHeader />

                  <InnerPageContainer>
                    <ContentContainer>
                      <Outlet />
                    </ContentContainer>

                    <ActivityLinkSidebar />
                    <AddCoHostsSidebar />
                    <TicketFormSidebar />
                    <PickRequestedInfoTypeSidebar />
                    <PickRequestedInfoFormSidebar />
                  </InnerPageContainer>
                </PageContainer>
              </ActivityLinkSidebarProvider>
            </RequestedInfoFormProvider>
          </PickRequestedInfoTypeProvider>
        </CoHostsSidebarProvider>
      </TicketsSidebarProvider>
    </ActivityFormProvider>
  );
}
