import { Currency, parseCurrency } from '@orbiapp/components';

export function getReferralRewardAmountsInCurrency(currency: Currency): {
  maxRewardWithCurrency: string;
  maxRewardWithoutCurrency: string;
  rewardPerUser: string;
} {
  switch (currency) {
    case 'DKK': {
      const maxReward = 10000;
      const rewardPerUser = 5;

      const maxRewardParsed = parseCurrency(maxReward, currency);

      return {
        maxRewardWithCurrency: maxRewardParsed.slice(0, -3),
        maxRewardWithoutCurrency: maxRewardParsed.slice(0, -3).slice(4),
        rewardPerUser: parseCurrency(rewardPerUser, currency),
      };
    }

    case 'NOK': {
      const maxReward = 10000;
      const rewardPerUser = 5;

      const maxRewardParsed = parseCurrency(maxReward, currency);

      return {
        maxRewardWithCurrency: maxRewardParsed.slice(0, -3),
        maxRewardWithoutCurrency: maxRewardParsed.slice(0, -3).slice(4),
        rewardPerUser: parseCurrency(rewardPerUser, currency),
      };
    }

    case 'SEK': {
      const maxReward = 10000;
      const rewardPerUser = 5;

      const maxRewardParsed = parseCurrency(maxReward, currency);

      return {
        maxRewardWithCurrency: maxRewardParsed.slice(0, -3),
        maxRewardWithoutCurrency: maxRewardParsed.slice(0, -3).slice(4),
        rewardPerUser: parseCurrency(rewardPerUser, currency),
      };
    }

    case 'EUR': {
      const maxReward = 1000;
      const rewardPerUser = 0.5;

      const maxRewardParsed = parseCurrency(maxReward, currency);

      return {
        maxRewardWithCurrency: maxRewardParsed.slice(0, -3),
        maxRewardWithoutCurrency: maxRewardParsed.slice(0, -3).slice(4),
        rewardPerUser: parseCurrency(rewardPerUser, currency),
      };
    }
  }
}
