import { joiResolver } from '@hookform/resolvers/joi';
import {
  Avatar,
  Box,
  Button,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledDatePicker,
  ControlledInput,
  ControlledTextarea,
  Divider,
  EmptyState,
  INITIAL_MODAL_STATE,
  INPUT_X_PADDING,
  INPUT_Y_PADDING,
  Icon,
  IconButton,
  InnerContentContainer,
  Link,
  List,
  ListItem,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Radio,
  SearchInput,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  createUniqueArray,
  flattenFieldErrorsObject,
  formatDate,
  getUID,
  isTxString,
  parseCurrency,
  translate,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  useMembershipSearch,
  useSuggestedMembershipApplicationRequirements,
} from '../../../../../helpers';
import {
  AddDiscountCodeValidation,
  CombinationType,
  CreateMembershipTypeDiscountsForm,
  CreateMembershipTypeDiscountsFormValidation,
  CreateTicketTypeFormValidation,
  DISCOUNT_CODE_MAX_LENGTH,
  DiscountCode,
  MIN_PRICE,
  MembershipApplicationRequirement,
  MembershipTypeDiscount,
  PartialMembershipType,
  RequiredMembershipType,
  TICKET_DESCRIPTION_MAX_LENGTH,
  TICKET_MAX_COUNT,
  TICKET_MIN_COUNT,
  TICKET_TYPE_NAME_MAX_LENGTH,
  TicketType,
  UpdateTicketTypeFormValidation,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  ActivityDataSelector,
  OrbiPaySettingsSelector,
  getSuggestedMembershipsThunk,
  searchActions,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  getCurrencyLabelText,
  getOptionalLabelText,
} from '../../../../../utils';
import {
  GoBackButton,
  PublishEventButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { ActivityFormContext } from '../create-activity.context';
import { Styled } from './tickets.styled';
import { TicketsSidebarContextType } from './tickets.types';

const ticketTypeDefaultValues: TicketType = {
  description: '',
  discountCodes: null,
  endDate: Date.now(),
  isNew: true,
  isTransferable: true,
  maxTicketCountPerUser: 1,
  membershipTypeDiscounts: null,
  name: '',
  price: 5,
  requiredMembershipTypes: null,
  startDate: Date.now(),
  ticketCount: 1,
  ticketTypeKey: getUID(),
};

const ticketsContextDefaultValue: TicketsSidebarContextType['data'] = {
  defaultValues: null,
  mode: 'create',
};

const TicketsSidebarContext = React.createContext<TicketsSidebarContextType>({
  data: ticketsContextDefaultValue,
  membershipDiscountsRef: React.createRef(),
  discountCodesRef: React.createRef(),
  priceInputRef: React.createRef(),
  setData: () => {},
  ticketPriceAndDiscountsErrorBoxRef: React.createRef(),
  requiredMembershipsModalState: INITIAL_MODAL_STATE,
  membershipDiscountsModalState: INITIAL_MODAL_STATE,
  discountCodesModalState: INITIAL_MODAL_STATE,
});

export function TicketsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [data, setData] = React.useState<TicketsSidebarContextType['data']>(
    ticketsContextDefaultValue,
  );

  const requiredMembershipsModalState = useModalState();
  const membershipDiscountsModalState = useModalState();
  const discountCodesModalState = useModalState();

  const priceInputRef = React.useRef<HTMLInputElement>(null);
  const membershipDiscountsRef = React.useRef<HTMLDivElement>(null);
  const ticketPriceAndDiscountsErrorBoxRef = React.useRef<HTMLDivElement>(null);
  const discountCodesRef = React.useRef<HTMLDivElement>(null);

  const value = {
    data,
    membershipDiscountsRef,
    priceInputRef,
    setData,
    ticketPriceAndDiscountsErrorBoxRef,
    discountCodesRef,
    requiredMembershipsModalState,
    membershipDiscountsModalState,
    discountCodesModalState,
  };

  return (
    <TicketsSidebarContext.Provider value={value}>
      {children}
    </TicketsSidebarContext.Provider>
  );
}

function AllowTicketTransferToggle() {
  const ticketTypeFormContext = useFormContext<TicketType>();

  const isTransferable = ticketTypeFormContext.watch('isTransferable');

  const switchAllowTicketTransfer = () => {
    ticketTypeFormContext.setValue('isTransferable', !isTransferable, {
      shouldDirty: true,
    });
  };

  return (
    <Switch
      checked={isTransferable}
      onClick={switchAllowTicketTransfer}
      tx="label.create-activity.tickets.ticket-items.ticket.ticket-transfer"
    />
  );
}

function RequiredMembershipsModalContent(props: {
  onCancel: () => void;
  onSave: (data: RequiredMembershipType[]) => void;
  alreadySelectedMembershipTypeKeys: string[];
}) {
  const { alreadySelectedMembershipTypeKeys, onCancel, onSave } = props;

  const dispatch = useDispatch();

  const { search, searchResult, searchString, setSearchString } =
    useMembershipSearch(['invite_only', 'needs_approval', 'standard']);

  const [selectedMemberships, setSelectedMemberships] = React.useState<
    RequiredMembershipType[]
  >([]);

  const suggestedMemberships = useSuggestedMembershipApplicationRequirements(
    5,
    [
      ...alreadySelectedMembershipTypeKeys,
      ...selectedMemberships.map((item) => item.membershipTypeKey),
    ],
  );

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderSearchResultMembership = (
    searchResultMembership: PartialMembershipType,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: searchResultMembership.departmentName,
          logoUrl: searchResultMembership.logo.thumbnail64.url,
          key: getUID(),
          membershipTypeKey: searchResultMembership.membershipTypeKey,
          name: searchResultMembership.name,
        },
      ]);
    };

    return (
      <ListItem
        key={`search-result-membership-${searchResultMembership.membershipTypeKey}`}
        avatarSrc={searchResultMembership.logo.thumbnail64.url}
        onClick={addMembershipToRequiredMemberships}
        subtitle={searchResultMembership.departmentName}
        title={searchResultMembership.name}
      />
    );
  };

  const renderSuggestedMembership = (
    suggestedMembership: MembershipApplicationRequirement,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: suggestedMembership.departmentName,
          logoUrl: suggestedMembership.logoUrl,
          key: getUID(),
          membershipTypeKey: suggestedMembership.membershipTypeKey,
          name: suggestedMembership.name,
        },
      ]);
    };

    return (
      <ListItem
        key={`suggested-membership-${suggestedMembership.membershipTypeKey}`}
        avatarSrc={suggestedMembership.logoUrl}
        onClick={addMembershipToRequiredMemberships}
        subtitle={suggestedMembership.departmentName}
        title={suggestedMembership.name}
      />
    );
  };

  const renderSelectedMembership = (
    selectedMembership: RequiredMembershipType,
  ) => {
    const removeMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) =>
        prev.filter(
          (item) =>
            item.membershipTypeKey !== selectedMembership.membershipTypeKey,
        ),
      );
    };

    return (
      <ListItem
        icon="check-circle-solid"
        iconColor="listItemIcon"
        key={`selected-membership-${selectedMembership.membershipTypeKey}`}
        avatarSrc={selectedMembership.logoUrl}
        onClick={removeMembershipToRequiredMemberships}
        subtitle={selectedMembership.departmentName}
        title={selectedMembership.name}
      />
    );
  };

  const handleSave = () => {
    onSave(selectedMemberships);
    setSelectedMemberships([]);
    dispatch(searchActions.clearMembershipTypes());
  };

  React.useEffect(() => {
    dispatch(
      getSuggestedMembershipsThunk({
        includeHidden: true,
      }),
    );
  }, [dispatch]);

  const selectedMembershipTypeKeysSet = new Set([
    ...selectedMemberships.map((item) => item.membershipTypeKey),
    ...alreadySelectedMembershipTypeKeys,
  ]);

  const uniqueSearchResults = searchResult.filter(
    (item) => !selectedMembershipTypeKeysSet.has(item.membershipTypeKey),
  );

  const shouldRenderSearchResults = uniqueSearchResults.length > 0;
  const shouldRenderSelectedMemberships = selectedMemberships.length > 0;
  const shouldRenderSuggestedMemberships =
    uniqueSearchResults.length === 0 &&
    Array.isArray(suggestedMemberships) &&
    suggestedMemberships.length > 0;

  return (
    <ModalContentContainer>
      <ModalHeaderContainer flex flexDirection="column" gap={32}>
        <ModalTitle tx="label.create-activity.tickets.add-required-memberships" />

        <SearchInput
          onChange={handleSearchChange}
          placeholderTx="placeholder.search-for-memberships"
          value={searchString}
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        {searchString.length > 0 && !shouldRenderSearchResults && (
          <Text
            textAlign="center"
            tx="placeholder.no-search-results"
            variant="bodySm"
          />
        )}

        {shouldRenderSearchResults && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.search-results"
            />

            <List>{uniqueSearchResults.map(renderSearchResultMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSuggestedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.suggested-memberships"
            />

            <List>{suggestedMemberships.map(renderSuggestedMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSelectedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.required-memberships"
            />

            <List>{selectedMemberships.map(renderSelectedMembership)}</List>
          </React.Fragment>
        )}
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button tx="button.cancel" onClick={onCancel} variant="tertiary" />
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function RequiredMembershipsList() {
  const ticketTypeFormContext = useFormContext<TicketType>();

  const membershipApplicationRequirements = ticketTypeFormContext.watch(
    'requiredMembershipTypes.membershipApplicationRequirements',
  );

  if (
    !Array.isArray(membershipApplicationRequirements) ||
    membershipApplicationRequirements.length === 0
  ) {
    return null;
  }

  const renderSelectedMembershipApplicationRequirementListItem = (
    requiredMembershipType: RequiredMembershipType,
  ) => {
    const removeSelectedMembershipApplicationRequirement = () => {
      ticketTypeFormContext.setValue(
        'requiredMembershipTypes.membershipApplicationRequirements',
        membershipApplicationRequirements.filter(
          (item) =>
            item.membershipTypeKey !== requiredMembershipType.membershipTypeKey,
        ),
        { shouldDirty: true },
      );
    };

    return (
      <ListItem
        icon="trash-outline"
        key={`required-membership-type-${requiredMembershipType.membershipTypeKey}`}
        avatarSrc={requiredMembershipType.logoUrl}
        onIconClick={removeSelectedMembershipApplicationRequirement}
        subtitle={requiredMembershipType.departmentName}
        title={requiredMembershipType.name}
      />
    );
  };

  return (
    <List>
      {membershipApplicationRequirements.map(
        renderSelectedMembershipApplicationRequirementListItem,
      )}
    </List>
  );
}

function RequiredMembershipsToggle() {
  const ticketTypeFormContext = useFormContext<TicketType>();

  const requiredMembershipTypes = ticketTypeFormContext.watch(
    'requiredMembershipTypes',
  );

  const switchAllowTicketTransfer = () => {
    ticketTypeFormContext.setValue(
      'requiredMembershipTypes',
      requiredMembershipTypes
        ? null
        : {
            combinationType: 'any',
            membershipApplicationRequirements: [],
          },
      {
        shouldDirty: true,
      },
    );
  };

  return (
    <Switch
      checked={!!requiredMembershipTypes}
      onClick={switchAllowTicketTransfer}
      tx="label.create-activity.tickets.ticket-items.ticket.memberships.required-memberships"
    />
  );
}

function PickCombinationType() {
  const ticketTypeFormContext = useFormContext<TicketType>();

  const [combinationType, membershipApplicationRequirements] =
    ticketTypeFormContext.watch([
      'requiredMembershipTypes.combinationType',
      'requiredMembershipTypes.membershipApplicationRequirements',
    ]);

  const handleCombinationTypeChange =
    (combinationType: CombinationType) => () => {
      ticketTypeFormContext.setValue(
        'requiredMembershipTypes.combinationType',
        combinationType,
      );
    };

  if ((membershipApplicationRequirements ?? []).length < 2) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={4}>
      <Text
        variant="bodySmBold"
        tx="label.create-activity.tickets.ticket-items.ticket.combination-type.label"
      />

      <Box gap={16} flexWrap flex>
        <Radio
          checked={combinationType === 'all'}
          onChange={handleCombinationTypeChange('all')}
          tx="label.create-activity.tickets.ticket-items.ticket.combination-type.all"
        />
        <Radio
          checked={combinationType === 'any'}
          onChange={handleCombinationTypeChange('any')}
          tx="label.create-activity.tickets.ticket-items.ticket.combination-type.any"
        />
      </Box>
    </Box>
  );
}

function RequiredMembershipsForm() {
  const { requiredMembershipsModalState } = React.useContext(
    TicketsSidebarContext,
  );

  const { openModal } = requiredMembershipsModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  if (!ticketTypeFormContext.getValues('requiredMembershipTypes')) {
    return null;
  }

  return (
    <React.Fragment>
      <Divider />

      <Box flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column">
          <Text
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.required-memberships"
            variant="bodyMdBold"
          />
          <Text
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.required-memberships-description"
            variant="bodyMd"
          />
        </Box>

        <PickCombinationType />

        <RequiredMembershipsList />

        <Button
          mx="auto"
          onClick={openModal}
          tx="label.create-activity.tickets.ticket-items.ticket.memberships.actions.add-required-membership"
          variant="secondary"
        />
      </Box>
    </React.Fragment>
  );
}

function AddRequiredMembershipsModal() {
  const dispatch = useDispatch();

  const { requiredMembershipsModalState } = React.useContext(
    TicketsSidebarContext,
  );

  const { closeModal, isOpen } = requiredMembershipsModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  const membershipApplicationRequirements = ticketTypeFormContext.watch(
    'requiredMembershipTypes.membershipApplicationRequirements',
  );

  if (!membershipApplicationRequirements) {
    return null;
  }

  const alreadySelectedMembershipTypeKeys =
    membershipApplicationRequirements.map((item) => item.membershipTypeKey);

  const closeAndReset = () => {
    closeModal();

    window.setTimeout(() => {
      dispatch(searchActions.clearMembershipTypes());
    }, 200);
  };

  const handleOnSave = (data: RequiredMembershipType[]) => {
    ticketTypeFormContext.setValue(
      'requiredMembershipTypes.membershipApplicationRequirements',
      [...membershipApplicationRequirements, ...data],
      {
        shouldDirty: true,
      },
    );

    closeAndReset();
  };

  return (
    <Modal width={800} onClose={closeAndReset} isOpen={isOpen}>
      <RequiredMembershipsModalContent
        alreadySelectedMembershipTypeKeys={alreadySelectedMembershipTypeKeys}
        onCancel={closeAndReset}
        onSave={handleOnSave}
      />
    </Modal>
  );
}

function MembershipDiscountsModalContent(props: {
  onCancel: () => void;
  onSave: (data: MembershipTypeDiscount[]) => void;
  alreadySelectedMembershipTypeKeys: string[];
}) {
  const { alreadySelectedMembershipTypeKeys, onCancel, onSave } = props;

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const price = useWatch<{ price: number }>({
    name: 'price',
  });

  const dispatch = useDispatch();

  const { search, searchResult, searchString, setSearchString } =
    useMembershipSearch(['invite_only', 'needs_approval', 'standard']);

  const formMethods = useForm<CreateMembershipTypeDiscountsForm>({
    defaultValues: {
      membershipTypeDiscounts: [],
    },
    resolver: joiResolver(CreateMembershipTypeDiscountsFormValidation(price)),
  });

  const selectedMemberships = formMethods.watch('membershipTypeDiscounts');

  const suggestedMemberships = useSuggestedMembershipApplicationRequirements(
    5,
    [
      ...alreadySelectedMembershipTypeKeys,
      ...selectedMemberships.map((item) => item.membershipTypeKey),
    ],
  );

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderSearchResultMembership = (
    partialMembershipType: PartialMembershipType,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      formMethods.setValue('membershipTypeDiscounts', [
        ...selectedMemberships,
        {
          departmentName: partialMembershipType.departmentName,
          discount: 0,
          isNew: true,
          logoUrl: partialMembershipType.logo.thumbnail64.url,
          membershipTypeKey: partialMembershipType.membershipTypeKey,
          name: partialMembershipType.name,
          membershipTypeDiscountKey: getUID(),
          discountType: 'fixed',
        },
      ]);
    };

    return (
      <ListItem
        key={`search-result-membership-${partialMembershipType.membershipTypeKey}`}
        avatarSrc={partialMembershipType.logo.thumbnail64.url}
        onClick={addMembershipToRequiredMemberships}
        subtitle={partialMembershipType.departmentName}
        title={partialMembershipType.name}
      />
    );
  };

  const renderSuggestedMembership = (
    suggestedMembersip: MembershipApplicationRequirement,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      formMethods.setValue('membershipTypeDiscounts', [
        ...selectedMemberships,
        {
          departmentName: suggestedMembersip.departmentName,
          discount: 0,
          isNew: true,
          logoUrl: suggestedMembersip.logoUrl,
          membershipTypeKey: suggestedMembersip.membershipTypeKey,
          name: suggestedMembersip.name,
          membershipTypeDiscountKey: getUID(),
          discountType: 'fixed',
        },
      ]);
    };

    return (
      <ListItem
        key={`suggested-membership-${suggestedMembersip.membershipTypeKey}`}
        avatarSrc={suggestedMembersip.logoUrl}
        onClick={addMembershipToRequiredMemberships}
        subtitle={suggestedMembersip.departmentName}
        title={suggestedMembersip.name}
      />
    );
  };

  const renderSelectedMembership = (
    selectedMembership: MembershipTypeDiscount,
    index: number,
  ) => {
    const removeMembershipToRequiredMemberships = () => {
      formMethods.setValue(
        'membershipTypeDiscounts',
        selectedMemberships.filter(
          (item) =>
            item.membershipTypeKey !== selectedMembership.membershipTypeKey,
        ),
      );
    };

    return (
      <ListItem
        flexAlign="start"
        key={`selected-membership-discount-${selectedMembership.membershipTypeKey}`}
      >
        <Box flex flexJustify="between" flexWrap="wrap" width="100%" gap={32}>
          <Box flex gap={16}>
            <Avatar src={selectedMembership.logoUrl} />

            <Box>
              <Text
                maxWidth="20ch"
                overflow="hidden"
                text={selectedMembership.name}
                textOverflow="ellipsis"
                variant="bodyMd"
                whiteSpace="nowrap"
              />
              <Text
                maxWidth="20ch"
                overflow="hidden"
                text={selectedMembership.departmentName}
                textOverflow="ellipsis"
                variant="bodySm"
                whiteSpace="nowrap"
              />
            </Box>
          </Box>

          <Box flex gap={16}>
            <ControlledInput
              label={getCurrencyLabelText(
                'label.create-activity.tickets.ticket-items.ticket.memberships.amount',
                currency,
              )}
              name={`membershipTypeDiscounts.${index}.discount`}
              type="number"
              width={260}
            />

            <IconButton
              icon="trash-outline"
              mt={20}
              onClick={removeMembershipToRequiredMemberships}
            />
          </Box>
        </Box>
      </ListItem>
    );
  };

  const handleSave = formMethods.handleSubmit(
    (data) => {
      onSave(data.membershipTypeDiscounts);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('saveEventMembershipTypeDiscounts Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    dispatch(
      getSuggestedMembershipsThunk({
        includeHidden: true,
      }),
    );
  }, [dispatch]);

  const selectedMembershipTypeKeysSet = new Set([
    ...selectedMemberships.map((item) => item.membershipTypeKey),
    ...alreadySelectedMembershipTypeKeys,
  ]);

  const uniqueSearchResults = createUniqueArray(
    searchResult,
    'membershipTypeKey',
  ).filter(
    (item) => !selectedMembershipTypeKeysSet.has(item.membershipTypeKey),
  );

  const shouldRenderSearchResults = uniqueSearchResults.length > 0;
  const shouldRenderSelectedMemberships = selectedMemberships.length > 0;
  const shouldRenderSuggestedMemberships =
    uniqueSearchResults.length === 0 &&
    Array.isArray(suggestedMemberships) &&
    suggestedMemberships.length > 0;

  return (
    <ModalContentContainer>
      <ModalHeaderContainer flex flexDirection="column" gap={32}>
        <ModalTitle tx="label.create-activity.tickets.add-membership-discounts" />

        <SearchInput
          onChange={handleSearchChange}
          placeholderTx="placeholder.search-for-memberships"
          value={searchString}
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        {searchString.length > 0 && !shouldRenderSearchResults && (
          <Text
            textAlign="center"
            tx="placeholder.no-search-results"
            variant="bodySm"
          />
        )}

        {shouldRenderSearchResults && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.search-results"
            />

            <List>{uniqueSearchResults.map(renderSearchResultMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSuggestedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.suggested-memberships"
            />

            <List>{suggestedMemberships.map(renderSuggestedMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSelectedMemberships && (
          <FormProvider {...formMethods}>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.required-memberships"
            />

            <List>{selectedMemberships.map(renderSelectedMembership)}</List>
          </FormProvider>
        )}
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button tx="button.cancel" onClick={onCancel} variant="tertiary" />
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function MembershipDiscountsToggle() {
  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );
  const ticketTypeFormContext = useFormContext<TicketType>();

  const membershipTypeDiscounts = ticketTypeFormContext.watch(
    'membershipTypeDiscounts',
  );

  const switchAllowTicketTransfer = () => {
    ticketTypeFormContext.setValue(
      'membershipTypeDiscounts',
      membershipTypeDiscounts ? null : [],
      {
        shouldDirty: true,
      },
    );
  };

  if (!allowPaidTickets) {
    return null;
  }

  return (
    <Switch
      checked={!!membershipTypeDiscounts}
      onClick={switchAllowTicketTransfer}
      tx="label.create-activity.tickets.ticket-items.ticket.memberships.membership-discounts"
    />
  );
}

function MembershipDiscountsList() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const ticketTypeFormContext = useFormContext<TicketType>();

  const membershipTypeDiscounts = ticketTypeFormContext.watch(
    'membershipTypeDiscounts',
  );

  if (
    !Array.isArray(membershipTypeDiscounts) ||
    membershipTypeDiscounts.length === 0
  ) {
    return null;
  }

  const renderSelectedMembershipDiscount = (
    membershipDiscount: MembershipTypeDiscount,
  ) => {
    const removeSelectedMembershipApplicationRequirement = () => {
      ticketTypeFormContext.setValue(
        'membershipTypeDiscounts',
        membershipTypeDiscounts.filter(
          (item) =>
            item.membershipTypeKey !== membershipDiscount.membershipTypeKey,
        ),
        {
          shouldDirty: true,
          shouldValidate: ticketTypeFormContext.formState.isSubmitted,
        },
      );

      if (ticketTypeFormContext.formState.isSubmitted) {
        ticketTypeFormContext.trigger('price');
      }
    };

    return (
      <ListItem
        chipText={parseCurrency(membershipDiscount.discount, currency)}
        chipVariant={1}
        icon="trash-outline"
        key={`membership-discount-${membershipDiscount.membershipTypeKey}`}
        avatarSrc={membershipDiscount.logoUrl}
        onIconClick={removeSelectedMembershipApplicationRequirement}
        subtitle={membershipDiscount.departmentName}
        title={membershipDiscount.name}
      />
    );
  };

  return (
    <List>{membershipTypeDiscounts.map(renderSelectedMembershipDiscount)}</List>
  );
}

function MembershipDiscountsForm() {
  const { membershipDiscountsRef, membershipDiscountsModalState } =
    React.useContext(TicketsSidebarContext);

  const { openModal } = membershipDiscountsModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  if (!ticketTypeFormContext.getValues('membershipTypeDiscounts')) {
    return null;
  }

  return (
    <React.Fragment>
      <Divider />

      <Box ref={membershipDiscountsRef} flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column">
          <Text
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.membership-discounts"
            variant="bodyMdBold"
          />
          <Text
            variant="bodyMd"
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.membership-discounts-description"
          />

          {ticketTypeFormContext.formState.errors?.membershipTypeDiscounts && (
            <Text
              mt={5}
              tx="errors.membership-discounts.min-price-affected"
              variant="bodySm"
              color="errorLabel"
            />
          )}
        </Box>

        <MembershipDiscountsList />

        <Button
          mx="auto"
          onClick={openModal}
          tx="label.create-activity.tickets.ticket-items.ticket.memberships.actions.add-membership-discount"
          variant="secondary"
        />
      </Box>
    </React.Fragment>
  );
}

function AddMembershipDiscountsModal() {
  const dispatch = useDispatch();

  const { membershipDiscountsModalState } = React.useContext(
    TicketsSidebarContext,
  );

  const { closeModal, isOpen } = membershipDiscountsModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  const membershipTypeDiscounts = ticketTypeFormContext.watch(
    'membershipTypeDiscounts',
  );

  if (!membershipTypeDiscounts) {
    return null;
  }

  const alreadySelectedMembershipTypeKeys = membershipTypeDiscounts.map(
    (item) => item.membershipTypeKey,
  );

  const closeAndReset = () => {
    closeModal();

    window.setTimeout(() => {
      dispatch(searchActions.clearMembershipTypes());
    }, 200);
  };

  const handleOnSave = (data: MembershipTypeDiscount[]) => {
    ticketTypeFormContext.setValue(
      'membershipTypeDiscounts',
      [...membershipTypeDiscounts, ...data],
      {
        shouldDirty: true,
      },
    );

    closeAndReset();
  };

  return (
    <Modal width={800} onClose={closeAndReset} isOpen={isOpen}>
      <MembershipDiscountsModalContent
        alreadySelectedMembershipTypeKeys={alreadySelectedMembershipTypeKeys}
        onCancel={closeAndReset}
        onSave={handleOnSave}
      />
    </Modal>
  );
}

function DiscountCodesToggle() {
  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );
  const ticketTypeFormContext = useFormContext<TicketType>();

  const discountCodes = ticketTypeFormContext.watch('discountCodes');

  const switchAllowTicketTransfer = () => {
    ticketTypeFormContext.setValue('discountCodes', discountCodes ? null : [], {
      shouldDirty: true,
    });
  };

  if (!allowPaidTickets) {
    return null;
  }

  return (
    <Switch
      checked={!!discountCodes}
      onClick={switchAllowTicketTransfer}
      tx="label.create-activity.tickets.ticket-items.ticket.memberships.discount-codes"
    />
  );
}

function DiscountCodesModalContent(props: {
  onCancel: () => void;
  onSave: (data: DiscountCode) => void;
}) {
  const { onCancel, onSave } = props;

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const ticketTypeFormContext = useFormContext<TicketType>();

  const ticketPrice = ticketTypeFormContext.watch('price');

  const formMethods = useForm<DiscountCode>({
    defaultValues: {
      code: '',
      discount: 0,
      discountCodeKey: getUID(),
      discountType: 'fixed',
      isNew: true,
    },
    resolver: joiResolver(AddDiscountCodeValidation({ ticketPrice })),
  });

  const handleSave = formMethods.handleSubmit(
    (data) => {
      onSave(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('saveEventDiscountCode Validation', {
        err,
      });
    },
  );

  return (
    <ModalContentContainer>
      <ModalHeaderContainer flex flexDirection="column" gap={32}>
        <ModalTitle tx="label.create-activity.tickets.add-discount-code" />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <FormProvider {...formMethods}>
          <ControlledInput
            deps={ticketTypeFormContext
              .getValues('discountCodes')!
              .map((_, i) => `discountCodes.${i}.code`)}
            labelTx="label.create-activity.tickets.ticket-items.ticket.memberships.code"
            name="code"
            required
            maxLength={DISCOUNT_CODE_MAX_LENGTH}
          />

          <ControlledInput
            label={getCurrencyLabelText(
              'label.create-activity.tickets.ticket-items.ticket.memberships.amount',
              currency,
            )}
            name="discount"
            required
            type="number"
          />
        </FormProvider>
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button tx="button.cancel" onClick={onCancel} variant="tertiary" />
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function DiscountCodesList() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const ticketTypeFormContext = useFormContext<TicketType>();

  const discountCodes = ticketTypeFormContext.watch('discountCodes');

  const renderDiscountCode = (discountCode: DiscountCode) => {
    const removeDiscountCode = () => {
      ticketTypeFormContext.setValue(
        'discountCodes',
        discountCodes!.filter(
          (item) => item.discountCodeKey !== discountCode.discountCodeKey,
        ),
        {
          shouldDirty: true,
          shouldValidate: ticketTypeFormContext.formState.isSubmitted,
        },
      );

      if (ticketTypeFormContext.formState.isSubmitted) {
        ticketTypeFormContext.trigger('price');
      }
    };

    return (
      <ListItem
        key={`discount-code-${discountCode.discountCodeKey}`}
        title={discountCode.code}
        icon="trash-outline"
        onIconClick={removeDiscountCode}
        chipVariant={1}
        chipText={parseCurrency(discountCode.discount, currency)}
      />
    );
  };

  if (!Array.isArray(discountCodes) || discountCodes.length === 0) {
    return null;
  }

  return <List>{discountCodes.map(renderDiscountCode)}</List>;
}

function DiscountCodesForm() {
  const { discountCodesRef, discountCodesModalState } = React.useContext(
    TicketsSidebarContext,
  );

  const { openModal } = discountCodesModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  if (!ticketTypeFormContext.getValues('discountCodes')) {
    return null;
  }

  return (
    <React.Fragment>
      <Divider />

      <Box ref={discountCodesRef} flex flexDirection="column" gap={16}>
        <Box flex flexDirection="column">
          <Text
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.discount-codes"
            variant="bodyMdBold"
          />
          <Text
            tx="label.create-activity.tickets.ticket-items.ticket.memberships.discount-codes-description"
            variant="bodyMd"
          />

          {ticketTypeFormContext.formState.errors?.discountCodes && (
            <Text
              mt={5}
              tx="errors.discount-codes.min-price-affected"
              variant="bodySm"
              color="errorLabel"
            />
          )}
        </Box>

        <DiscountCodesList />

        <Button
          mx="auto"
          onClick={openModal}
          tx="label.create-activity.tickets.add-discount-codes"
          variant="secondary"
        />
      </Box>
    </React.Fragment>
  );
}

function AddDiscountCodesModal() {
  const { discountCodesModalState } = React.useContext(TicketsSidebarContext);

  const { closeModal, isOpen } = discountCodesModalState;

  const ticketTypeFormContext = useFormContext<TicketType>();

  const discountCodes = ticketTypeFormContext.watch('discountCodes');

  if (!discountCodes) {
    return null;
  }

  const handleOnSave = (discountCode: DiscountCode) => {
    ticketTypeFormContext.setValue(
      'discountCodes',
      [...discountCodes, discountCode],
      {
        shouldDirty: true,
      },
    );

    closeModal();
  };

  return (
    <Modal width={800} onClose={closeModal} isOpen={isOpen}>
      {isOpen && (
        <DiscountCodesModalContent
          onCancel={closeModal}
          onSave={handleOnSave}
        />
      )}
    </Modal>
  );
}

function getTicketPriceDeps(
  discountCodes: DiscountCode[] | null,
  membershipTypeDiscounts: MembershipTypeDiscount[] | null,
) {
  const deps: string[] = [];

  discountCodes?.forEach((_, i) => {
    deps.push(`.discountCodes.${i}.discount`);
  });

  membershipTypeDiscounts?.forEach((_, i) => {
    deps.push(`membershipTypeDiscounts.${i}.discount`);
  });

  return deps;
}

function TicketPriceInput() {
  const orbiPaySettingsStatus = useSelector(
    OrbiPaySettingsSelector.selectStatus,
  );

  const [membershipTypeDiscounts, discountCodes] = useWatch({
    name: ['membershipTypeDiscounts', 'discountCodes'],
  });

  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  return (
    <Box>
      <ControlledInput
        deps={getTicketPriceDeps(discountCodes, membershipTypeDiscounts)}
        disabled={!allowPaidTickets || orbiPaySettingsStatus === 'pending'}
        label={getCurrencyLabelText(
          'label.create-activity.tickets.ticket-items.ticket.price',
          currency,
        )}
        name="price"
        required
        type="number"
      />

      {orbiPaySettingsStatus !== 'pending' && !allowPaidTickets && (
        <Box
          flex
          flexDirection="column"
          gap={3}
          mt={5}
          px={INPUT_X_PADDING}
          py={INPUT_Y_PADDING}
        >
          <Text
            color="errorLabel"
            variant="bodyMd"
            tx="label.create-activity.tickets.ticket-items.disabled-message"
          />
          <Link tx="link.general.fill-out-missing-info" to="/orbi-pay" />
        </Box>
      )}
    </Box>
  );
}

function TicketPriceAndDiscountsError() {
  const { errors } = useFormState<TicketType>();

  const {
    membershipDiscountsRef,
    priceInputRef,
    ticketPriceAndDiscountsErrorBoxRef,
    discountCodesRef,
  } = React.useContext(TicketsSidebarContext);

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  if (
    !errors.price ||
    (!errors.discountCodes && !errors.membershipTypeDiscounts) ||
    (!errors.price &&
      errors.discountCodes &&
      !errors.price &&
      errors.membershipTypeDiscounts)
  ) {
    return null;
  }

  const scrollToMembershipDiscounts = () => {
    membershipDiscountsRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const scrollToDiscountsCodes = () => {
    discountCodesRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const focusPriceInput = () => {
    priceInputRef.current?.focus();
  };

  return (
    <Styled.TicketPriceAndDiscountsErrorBox
      flex
      flexDirection="column"
      gap={16}
      p={16}
      r={4}
      ref={ticketPriceAndDiscountsErrorBoxRef}
    >
      <Box flex flexDirection="column" gap={8}>
        <Box gap={8} flexAlign="center" flex>
          <Icon color="errorBoxIcon" name="exclamation-triangle-outline" />
          <Text
            color="errorBoxTitle"
            tx="errors.price-and-discounts.title"
            variant="bodyMdBold"
          />
        </Box>
        <Box flex flexDirection="column" gap={6}>
          <Text
            color="errorBoxLabel"
            tx="errors.price-and-discounts.subtitle"
            variant="bodyMd"
          />

          <Box px={8} gap={4} flex flexDirection="column">
            <Text
              color="errorBoxLabel"
              tx="errors.price-and-discounts.cannot-be-below-zero"
              variant="bodyMd"
            />
            <Text
              color="errorBoxLabel"
              tx="errors.price-and-discounts.can-be-zero"
              variant="bodyMd"
            />
            <Text
              color="errorBoxLabel"
              tx="errors.price-and-discounts.greater-than"
              txArgs={{ minPrice: parseCurrency(MIN_PRICE, currency) }}
              variant="bodyMd"
            />
          </Box>
        </Box>
      </Box>

      <Box flexWrap="wrap" gap={2} flex>
        <Text
          color="errorBoxLabel"
          tx="errors.price-and-discounts.update"
          variant="bodyMd"
          whiteSpace="nowrap"
        />
        <Styled.ClickableText
          color="errorBoxLabel"
          onClick={focusPriceInput}
          variant="bodyMd"
          whiteSpace="nowrap"
          text={`"${translate('errors.price-and-discounts.price-per-ticket')}"`}
        />
        <Text
          color="errorBoxLabel"
          tx="errors.price-and-discounts.or"
          variant="bodyMd"
          whiteSpace="nowrap"
        />

        {errors.membershipTypeDiscounts ? (
          <React.Fragment>
            <Styled.ClickableText
              color="errorBoxLabel"
              onClick={scrollToMembershipDiscounts}
              variant="bodyMd"
              whiteSpace="nowrap"
              text={`"${translate(
                'errors.price-and-discounts.membership-discount',
              )}"`}
            />
            <Text
              color="errorBoxLabel"
              tx="errors.price-and-discounts.continue"
              variant="bodyMd"
              whiteSpace="nowrap"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Styled.ClickableText
              color="errorBoxLabel"
              onClick={scrollToDiscountsCodes}
              variant="bodyMd"
              whiteSpace="nowrap"
              text={`"${translate(
                'errors.price-and-discounts.discount-code',
              )}"`}
            />
            <Text
              color="errorBoxLabel"
              tx="errors.price-and-discounts.continue"
              variant="bodyMd"
              whiteSpace="nowrap"
            />
          </React.Fragment>
        )}
      </Box>
    </Styled.TicketPriceAndDiscountsErrorBox>
  );
}

export function TicketFormSidebar() {
  const location = useLocation();

  const { data, ticketPriceAndDiscountsErrorBoxRef, priceInputRef, setData } =
    React.useContext(TicketsSidebarContext);

  const activityFormContext = React.useContext(ActivityFormContext);

  const formContext = React.useContext(ActivityFormContext);
  const minTicketCounts = useSelector(
    ActivityDataSelector.selectMinTicketCounts,
  );

  const [activityStartDate, activityEndDate] = activityFormContext.getValues([
    'description.startDate',
    'description.endDate',
  ]);

  const formMethods = useForm<TicketType>({
    defaultValues: {
      ...ticketTypeDefaultValues,
      startDate: activityStartDate,
      endDate: activityEndDate,
      ticketTypeKey: getUID(),
    },
    resolver: joiResolver(
      activityFormContext.mode === 'update'
        ? UpdateTicketTypeFormValidation({ minTicketCounts, activityEndDate })
        : CreateTicketTypeFormValidation({ activityEndDate }),
    ),
  });

  const closeSidebar = () => setData({ mode: 'create', defaultValues: null });

  const saveTicket = formMethods.handleSubmit(
    (ticketType) => {
      const currentState = formContext.getValues('tickets.ticketTypes') ?? [];

      if (data.mode === 'create') {
        formContext.setValue(
          'tickets.ticketTypes',
          [...currentState, ticketType],
          {
            shouldDirty: true,
            shouldValidate: formContext.formState.isSubmitted,
          },
        );
      } else {
        const index = currentState.findIndex(
          (item) => item.ticketTypeKey === ticketType.ticketTypeKey,
        );

        if (index === -1) {
          return;
        }

        currentState[index] = ticketType;

        formContext.setValue('tickets.ticketTypes', currentState, {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        });

        formContext.trigger(`tickets.ticketTypes.${index}`);
      }

      formMethods.reset();
      closeSidebar();
    },
    (err) => {
      Logger.warning('saveTicket Validation', {
        err: flattenFieldErrorsObject(err),
      });

      if (
        err.membershipTypeDiscounts &&
        err.price &&
        ticketPriceAndDiscountsErrorBoxRef.current
      ) {
        ticketPriceAndDiscountsErrorBoxRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
  );

  const ticketMinCount =
    minTicketCounts[formMethods.getValues('ticketTypeKey')] ?? TICKET_MIN_COUNT;

  React.useEffect(() => {
    formMethods.reset(
      data.defaultValues ?? {
        ...ticketTypeDefaultValues,
        startDate: activityStartDate,
        endDate: activityEndDate,
        ticketTypeKey: getUID(),
      },
    );
  }, [activityEndDate, activityStartDate, data.defaultValues, formMethods]);

  if (!location.pathname.endsWith('/tickets')) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <ContentSidebar
        isOpen={!!data.defaultValues}
        onClose={closeSidebar}
        width={470}
      >
        <ContentSidebarContentContainer>
          <Text
            variant="bodyLgBold"
            tx={
              data.mode === 'create'
                ? 'label.create-activity.tickets.add-ticket.label'
                : 'label.create-activity.tickets.edit-ticket.label'
            }
          />

          <TicketPriceAndDiscountsError />

          <ControlledInput
            labelTx="label.create-activity.tickets.ticket-items.ticket.name"
            maxLength={TICKET_TYPE_NAME_MAX_LENGTH}
            name="name"
            required
          />

          <TicketPriceInput />

          <ControlledInput
            deps={['maxTicketCountPerUser']}
            errorTxArgs={{ min: ticketMinCount }}
            labelTx="label.create-activity.tickets.ticket-items.ticket.max-count"
            max={TICKET_MAX_COUNT}
            min={ticketMinCount}
            name="ticketCount"
            ref={priceInputRef}
            required
            type="number"
          />

          <ControlledInput
            deps={['ticketCount']}
            labelTx="label.create-activity.tickets.ticket-items.ticket.max-per-user"
            min={1}
            name="maxTicketCountPerUser"
            required
            type="number"
          />

          <ControlledTextarea
            label={getOptionalLabelText(
              'label.create-activity.tickets.ticket-items.ticket.description',
            )}
            maxLength={TICKET_DESCRIPTION_MAX_LENGTH}
            name="description"
          />

          <ControlledDatePicker
            deps={['endDate']}
            labelTx="label.create-activity.tickets.ticket-items.ticket.start-date"
            name="startDate"
            required
            type="datetime-local"
          />

          <ControlledDatePicker
            name="endDate"
            deps={['startDate']}
            labelTx="label.create-activity.tickets.ticket-items.ticket.end-date"
            required
            errorTxArgs={{
              activityEndDate: formatDate(activityEndDate, 'YYYY-MM-DD HH:mm'),
            }}
            type="datetime-local"
          />

          <Box px={8} gap={16} flex flexWrap="wrap">
            <AllowTicketTransferToggle />

            <RequiredMembershipsToggle />

            <MembershipDiscountsToggle />

            <DiscountCodesToggle />
          </Box>

          <RequiredMembershipsForm />

          <MembershipDiscountsForm />

          <DiscountCodesForm />
        </ContentSidebarContentContainer>

        <ContentSidebarFooterContainer flexJustify="end">
          <Button tx="button.save" variant="secondary" onClick={saveTicket} />
        </ContentSidebarFooterContainer>
      </ContentSidebar>

      <AddMembershipDiscountsModal />

      <AddDiscountCodesModal />

      <AddRequiredMembershipsModal />
    </FormProvider>
  );
}

function ToggleShowAttendance() {
  const formContext = React.useContext(ActivityFormContext);

  const showAttendance = formContext.watch('tickets.showAttendance');

  const toggleShowAttendance = () => {
    formContext.setValue('tickets.showAttendance', !showAttendance, {
      shouldDirty: true,
    });
  };

  return (
    <Switch
      checked={showAttendance}
      onClick={toggleShowAttendance}
      tx="label.create-activity.tickets.show-attendance.label"
    />
  );
}

function TicketTypes() {
  const formContext = React.useContext(ActivityFormContext);

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );
  const minTicketCounts = useSelector(
    ActivityDataSelector.selectMinTicketCounts,
  );

  const { data, setData } = React.useContext(TicketsSidebarContext);

  const ticketTypes = formContext.watch('tickets.ticketTypes');

  const [activityStartDate, activityEndDate] = formContext.getValues([
    'description.startDate',
    'description.endDate',
  ]);

  const addTicketType = () => {
    setData({
      mode: 'create',
      defaultValues: {
        ...ticketTypeDefaultValues,
        price: allowPaidTickets ? 5 : 0,
        startDate: activityStartDate,
        endDate: activityEndDate,
        ticketTypeKey: getUID(),
      },
    });
  };

  const renderTicketTypeTableRow = (ticketType: TicketType, index: number) => {
    const selectRow = () => {
      setData({
        mode: 'edit',
        defaultValues: ticketType,
      });
    };

    const removeTicketType: React.MouseEventHandler<HTMLButtonElement> = (
      e,
    ) => {
      e.stopPropagation();

      formContext.setValue(
        'tickets.ticketTypes',
        (ticketTypes ?? []).filter(
          (item) => item.ticketTypeKey !== ticketType.ticketTypeKey,
        ),
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );

      formContext
        .getValues('requestMoreInfo.requestedInfo')
        ?.forEach((requestedInfoItem, i) => {
          formContext.setValue(
            `requestMoreInfo.requestedInfo.${i}.ticketTypeKeys`,
            requestedInfoItem.ticketTypeKeys.filter(
              (ticketTypeKey) => ticketTypeKey !== ticketType.ticketTypeKey,
            ),
          );
        });

      setData({
        mode: 'create',
        defaultValues: null,
      });
    };

    const endDateError =
      formContext.formState.errors?.tickets?.ticketTypes?.[index]?.endDate
        ?.message;

    const startDateError =
      formContext.formState.errors?.tickets?.ticketTypes?.[index]?.startDate
        ?.message;

    const disableDeleteTicketType =
      formContext.mode === 'update' &&
      minTicketCounts[ticketType.ticketTypeKey] &&
      minTicketCounts[ticketType.ticketTypeKey] > 0;

    return (
      <TableRow
        highlight={
          ticketType.ticketTypeKey === data.defaultValues?.ticketTypeKey
        }
        onClick={selectRow}
        key={ticketType.ticketTypeKey}
      >
        <TableCell text={ticketType.name} />
        <TableCell text={parseCurrency(ticketType.price, currency)} />

        {isTxString(startDateError) ? (
          <TableCell>
            <Icon color="errorIcon" mr={8} name="exclamation-triangle-solid" />
            <Text variant="bodySm" color="errorLabel" tx={startDateError} />
          </TableCell>
        ) : (
          <TableCell
            text={formatDate(ticketType.startDate, 'DD MMM YYYY HH:mm')}
          />
        )}

        {isTxString(endDateError) ? (
          <TableCell>
            <Icon color="errorIcon" mr={8} name="exclamation-triangle-solid" />
            <Text variant="bodySm" color="errorLabel" tx={endDateError} />
          </TableCell>
        ) : (
          <TableCell
            text={formatDate(ticketType.endDate, 'DD MMM YYYY HH:mm')}
          />
        )}

        <TableCell text={ticketType.ticketCount} />
        <TableCell text={ticketType.maxTicketCountPerUser} />
        <TableCell width={40} hoverCell fixedRight>
          {disableDeleteTicketType ? (
            <Tooltip
              placement="left"
              titleTx="label.create-activity.tickets.has-sold-tickets"
              titleTxArgs={{
                count: minTicketCounts[ticketType.ticketTypeKey],
              }}
            >
              <IconButton icon="trash-outline" disabled />
            </Tooltip>
          ) : (
            <IconButton icon="trash-outline" onClick={removeTicketType} />
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
        <Box
          height="fit-content"
          flexAlign="center"
          gap={16}
          flexWrap="wrap"
          flex
        >
          <GoBackButton />

          <Text
            as="h1"
            color="pageTitle"
            tx="label.create-activity.tabs.tickets.label"
            variant="titleMd"
          />
        </Box>

        <Box flex flexWrap="wrap" gap={16}>
          <SaveActivityAsDraftButton />

          <PublishEventButton />
        </Box>
      </Box>

      <Box gap={16} flex flexDirection="column">
        <Box flex flexJustify="between">
          <Text
            text={getOptionalLabelText('label.create-activity.tickets.steps.1')}
            variant="bodyMdBold"
          />

          <Tooltip
            placement="left"
            titleTx="label.create-activity.tickets.add-ticket.label"
          >
            <IconButton
              disabled={data.defaultValues !== null}
              icon="plus-circle-outline"
              onClick={addTicketType}
            />
          </Tooltip>
        </Box>

        {ticketTypes?.length ? (
          <React.Fragment>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.name" />
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.price" />
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.start-date" />
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.end-date" />
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.max-count" />
                  <TableHead tx="label.create-activity.tickets.ticket-items.ticket.max-per-user" />
                  <TableHead fixedRight />
                </TableRow>
              </TableHeader>

              <TableBody>{ticketTypes.map(renderTicketTypeTableRow)}</TableBody>
            </Table>
          </React.Fragment>
        ) : (
          <EmptyState
            buttonOnClick={addTicketType}
            buttonTx="label.create-activity.tickets.add-ticket.label"
            titleTx="label.create-activity.tickets.empty-state.label"
          />
        )}
      </Box>

      {(ticketTypes ?? []).length > 0 && (
        <Box gap={16} flex flexDirection="column">
          <Box flex flexJustify="between">
            <Text
              tx="label.create-activity.tickets.steps.2"
              variant="bodyMdBold"
            />
          </Box>

          <ToggleShowAttendance />
        </Box>
      )}
    </React.Fragment>
  );
}

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton
          to={`${rootPath}/participants`}
          icon="arrow-left-circle-outline"
        />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton
          to={`${rootPath}/request-more-info`}
          icon="arrow-right-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

export function CreateActivityTickets() {
  return (
    <React.Fragment>
      <InnerContentContainer>
        <TicketTypes />
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
