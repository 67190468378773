import styled, { css } from 'styled-components';

import { Box } from '../../box';
import { colors } from './pie-chart.constants';

const ChartBack = styled(Box)<{ isFlipped: boolean }>`
  ${(props) => {
    const { isFlipped, theme } = props;

    return css`
      width: 100%;
      height: 0;
      top: 0;
      border-radius: inherit;
      background-color: ${theme.colors.chartBackground};
      transition: ${theme.transitions.default};
      position: absolute;

      ${isFlipped &&
      css`
        pointer-events: all;
        height: 100%;
        width: 100%;
      `}
    `;
  }}
`;

const BackgroundColorBox = styled(Box)<{ index: number }>`
  ${(props) => {
    const { index } = props;

    const color = colors[index % colors.length] as string;

    return css`
      width: 8px;
      height: 8px;
      min-width: 8px;
      min-height: 8px;
      background-color: ${color};
      border-radius: 50%;
    `;
  }}
`;

export const Styled = { ChartBack, BackgroundColorBox };
