import {
  searchAddressesThunk,
  searchCoordinatesThunk,
  searchDepartmentsThunk,
  searchMembershipTypesThunk,
  searchUsersThunk,
} from './search.actions';
import {
  addressesAdapter,
  departmentsAdapter,
  membershipTypesAdapter,
  usersAdapter,
} from './search.adapter';
import { SearchBuilder } from './search.types';

function searchCoordinatesBuild(builder: SearchBuilder) {
  return builder
    .addCase(searchCoordinatesThunk.pending, (state) => {
      state.coordinates.status = 'pending';
      state.coordinates.error = null;
    })
    .addCase(searchCoordinatesThunk.fulfilled, (state, action) => {
      state.coordinates.status = 'idle';
      state.coordinates.data = action.payload;
    })
    .addCase(searchCoordinatesThunk.rejected, (state, action) => {
      state.coordinates.status = 'idle';
      state.coordinates.error = action.error;
    });
}

function searchAddressesBuild(builder: SearchBuilder) {
  return builder
    .addCase(searchAddressesThunk.pending, (state) => {
      state.addresses.status = 'pending';
      state.addresses.error = null;
    })
    .addCase(searchAddressesThunk.fulfilled, (state, action) => {
      state.addresses.status = 'idle';
      addressesAdapter.setAll(state.addresses.data, action.payload);
    })
    .addCase(searchAddressesThunk.rejected, (state, action) => {
      state.addresses.status = 'idle';
      state.addresses.error = action.error;
    });
}

function searchMembershipTypesBuild(builder: SearchBuilder) {
  return builder
    .addCase(searchMembershipTypesThunk.pending, (state) => {
      state.membershipTypes.status = 'pending';
      state.membershipTypes.error = null;
    })
    .addCase(searchMembershipTypesThunk.fulfilled, (state, action) => {
      state.membershipTypes.status = 'idle';
      membershipTypesAdapter.setAll(state.membershipTypes.data, action.payload);
    })
    .addCase(searchMembershipTypesThunk.rejected, (state, action) => {
      state.membershipTypes.status = 'idle';
      state.membershipTypes.error = action.error;
    });
}

function searchUsersBuild(builder: SearchBuilder) {
  return builder
    .addCase(searchUsersThunk.pending, (state) => {
      state.users.status = 'pending';
      state.users.error = null;
    })
    .addCase(searchUsersThunk.fulfilled, (state, action) => {
      state.users.status = 'idle';
      usersAdapter.setAll(state.users.data, action.payload);
    })
    .addCase(searchUsersThunk.rejected, (state, action) => {
      state.users.status = 'idle';
      state.users.error = action.error;
    });
}

function searchDepartmentsBuild(builder: SearchBuilder) {
  return builder
    .addCase(searchDepartmentsThunk.pending, (state) => {
      state.departments.status = 'pending';
      state.departments.error = null;
    })
    .addCase(searchDepartmentsThunk.fulfilled, (state, action) => {
      state.departments.status = 'idle';
      departmentsAdapter.setAll(state.departments.data, action.payload);
    })
    .addCase(searchDepartmentsThunk.rejected, (state, action) => {
      state.departments.status = 'idle';
      state.departments.error = action.error;
    });
}

export const extraReducers = (builder: SearchBuilder) => {
  searchCoordinatesBuild(builder);
  searchAddressesBuild(builder);
  searchMembershipTypesBuild(builder);
  searchUsersBuild(builder);
  searchDepartmentsBuild(builder);
};
