export const borderRadii = {
  l: 20,
  m: 16.5,
  ms: 14.5,
  none: 0,
  s: 8,
  xl: 22,
  xs: 4,
  xxs: 2,
};

export type BorderRadius = keyof typeof borderRadii;
