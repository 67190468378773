export namespace MembershipIntegrationErrors {
  export const apiKey = {
    'string.empty': 'errors.api-key.empty',
    'string.max': 'errors.api-key.max',
  };

  export const integrationUrl = {
    'string.empty': 'errors.integration-url.empty',
    'string.max': 'errors.integration-url.max',
    'string.uri': 'errors.integration-url.invalid',
  };

  export const refreshToken = {
    'string.empty': 'errors.refresh-token.empty',
    'string.max': 'errors.refresh-token.max',
    'string.uri': 'errors.refresh-token.invalid',
  };

  export const membershipSource = {
    'any.only': 'errors.select.none-selected',
  };

  export const description = {
    'string.empty': 'errors.description.empty',
    'string.max': 'errors.description.max',
  };

  export const logo = {
    'object.base': 'errors.logo.empty',
  };

  export const name = {
    'string.empty': 'errors.name.empty',
    'string.max': 'errors.name.max',
  };

  export const backgroundImage = {
    'any.required': 'errors.background-image.empty',
    'object.base': 'errors.background-image.empty',
    'any.only': 'errors.background-image.empty',
  };
}
