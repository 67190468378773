export namespace DiscountCodeErrors {
  export const customErrors = {
    codeNotUnique: 'string.unique',
    priceBelowZero: 'ticket-price-below-zero',
    priceBelowMin: 'price-below-min',
  };

  export const type = {
    'any.only': 'errors.select.none-selected',
  };

  export const addCode = {
    'string.empty': 'errors.discount-code.empty',
    'string.min': 'errors.discount-code.min',
    'string.max': 'errors.discount-code.max',
    'string.pattern.base': 'errors.discount-code.invalid',
  };

  export const code = {
    'string.empty': 'errors.discount-code.empty',
    'string.min': 'errors.discount-code.min',
    'string.max': 'errors.discount-code.max',
    'string.pattern.base': 'errors.discount-code.invalid',
    'string.unique': 'errors.discount-code.unique',
  };

  export const discount = {
    'price-below-min': 'errors.discount.price-below-min',
    'ticket-price-below-zero': 'errors.discount.ticket-price-below-zero',
    'number.base': 'errors.discount.price-below-min',
    'number.min': 'errors.discount.min',
    'number.integer': 'errors.discount.integer',
    'number.max': 'errors.discount.max',
    'number.any': 'errors.discount.integer',
  };
}
