import React from 'react';

import { PayoutSelector, useSelector } from '../../store';

const DownloadPayoutSnackbar = React.lazy(async () => {
  const { DownloadPayoutSnackbar } = await import('./download-payout-snackbar');

  return { default: DownloadPayoutSnackbar };
});

export function PayoutsSnackbarGate() {
  const payoutData = useSelector(PayoutSelector.selectData);
  const payoutStatus = useSelector(PayoutSelector.selectStatus);

  const loadDownloadPayoutSnackbar =
    payoutData !== null || payoutStatus === 'pending';

  if (!loadDownloadPayoutSnackbar) {
    return null;
  }

  return (
    <React.Suspense>
      <DownloadPayoutSnackbar />
    </React.Suspense>
  );
}
