import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ControlledInput,
  ControlledTextarea,
  IconButton,
  Image,
  InnerContentContainer,
  Text,
  Tooltip,
  constructBase64,
  flattenFieldErrorsObject,
  translate,
} from '@orbiapp/components';
import { breakpoints } from '@orbiapp/theme';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { UploadCoverImage, UploadLogo } from '../../../../../components';
import {
  CONTACT_NAME_MAX_LENGTH,
  CreateIntegratedMembershipTypeForm,
  EMAIL_MAX_LENGTH,
  GeneralInfoForm,
  GeneralInfoFormValidation,
  MEMBERSHIP_DESCRIPTION_MAX_LENGTH,
  MEMBERSHIP_NAME_MAX_LENGTH,
  PHONE_MAX_LENGTH,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { getOptionalLabelText } from '../../../../../utils';
import { MembershipCard } from '../../../components/membership-card';
import { CreateIntegratedMembershipFormNavBlocker } from '../components';

const GeneralInfoContext = React.createContext({
  closeSidebar: () => {},
  openSidebar: () => {},
  sidebarIsOpen: false,
  toggleSidebar: () => {},
});

function GeneralInfoProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [sidebarIsOpen, setSidebarIsOpen] = React.useState(
    window.innerWidth >= breakpoints.sm,
  );

  const openSidebar = () => setSidebarIsOpen(true);
  const closeSidebar = () => setSidebarIsOpen(false);
  const toggleSidebar = () => setSidebarIsOpen((isOpen) => !isOpen);

  return (
    <GeneralInfoContext.Provider
      value={{ closeSidebar, openSidebar, sidebarIsOpen, toggleSidebar }}
    >
      {children}
    </GeneralInfoContext.Provider>
  );
}

function ToggleSidebarButton() {
  const { toggleSidebar, sidebarIsOpen } = React.useContext(GeneralInfoContext);

  return (
    <Button
      icon={sidebarIsOpen ? 'x-mark' : undefined}
      ml="auto"
      onClick={toggleSidebar}
      tx={
        sidebarIsOpen
          ? 'label.memberships.create-membership.general-info.preview.close-preview'
          : 'label.memberships.create-membership.general-info.preview.open-preview'
      }
      variant="secondary"
    />
  );
}

function MembershipDetails() {
  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.create-membership.general-info.steps.1"
      />

      <ControlledInput
        labelTx="label.memberships.create-membership.name.label"
        maxLength={MEMBERSHIP_NAME_MAX_LENGTH}
        name="name"
        required
      />

      <ControlledTextarea
        labelTx="label.memberships.create-membership.description.label"
        maxLength={MEMBERSHIP_DESCRIPTION_MAX_LENGTH}
        name="description"
        required
      />
    </Box>
  );
}

function ContactDetails() {
  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.memberships.create-membership.general-info.steps.4"
      />

      <Box flex flexDirection="column" gap={24}>
        <ControlledInput
          labelTx="label.memberships.create-membership.general-info.contact-name.label"
          maxLength={CONTACT_NAME_MAX_LENGTH}
          name="contactDetails.name"
          required
        />

        <ControlledInput
          labelTx="label.memberships.create-membership.general-info.email.label"
          maxLength={EMAIL_MAX_LENGTH}
          name="contactDetails.email"
          required
          type="email"
        />

        <ControlledInput
          label={getOptionalLabelText(
            'label.memberships.create-membership.general-info.phone.label',
          )}
          maxLength={PHONE_MAX_LENGTH}
          name="contactDetails.phone"
          type="tel"
        />
      </Box>
    </Box>
  );
}

function GeneralInfoSidebar() {
  const { watch } = useFormContext<GeneralInfoForm>();

  const { closeSidebar, sidebarIsOpen } = React.useContext(GeneralInfoContext);

  const [backgroundImage, logo, name] = watch([
    'backgroundImage',
    'logo',
    'name',
  ]);

  const membershipCardRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (sidebarIsOpen) {
      membershipCardRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sidebarIsOpen, membershipCardRef]);

  return (
    <ContentSidebar width={470} isOpen={sidebarIsOpen} onClose={closeSidebar}>
      <ContentSidebarContentContainer>
        <Box flex flexDirection="column">
          <Text
            tx="label.memberships.create-membership.general-info.preview.title"
            variant="bodyMdBold"
          />
          <Text
            tx="label.memberships.create-membership.general-info.preview.subtitle"
            variant="bodyMd"
          />
        </Box>

        <Box flex flexAlign="center" flexDirection="column" gap={16}>
          <Image width={328} height={205} src="/assets/membership-card.svg" />

          <MembershipCard
            ref={membershipCardRef}
            hideWordmark
            name={
              name ||
              translate(
                'label.memberships.create-membership.general-info.preview.membership-name',
              )
            }
            logoUrl={constructBase64(logo)}
            backgroundImageUrl={constructBase64(backgroundImage)}
          />

          <Image width={328} height={205} src="/assets/membership-card.svg" />
        </Box>
      </ContentSidebarContentContainer>
    </ContentSidebar>
  );
}

export function CreateIntegratedMembershipGeneralInfo() {
  const navigate = useNavigate();

  const createIntegratedMembershipTypeFormContext =
    useFormContext<CreateIntegratedMembershipTypeForm>();

  const [backgroundImage, contactDetails, description, logo, name] =
    createIntegratedMembershipTypeFormContext.getValues([
      'backgroundImage',
      'contactDetails',
      'description',
      'logo',
      'name',
    ]);

  const formMethods = useForm<GeneralInfoForm>({
    resolver: joiResolver(GeneralInfoFormValidation),
    defaultValues: {
      backgroundImage,
      contactDetails,
      description,
      logo,
      name,
    },
  });

  if (!createIntegratedMembershipTypeFormContext.formState.isDirty) {
    return <Navigate to="/memberships/create-integration/select-service" />;
  }

  const setValues = (data: GeneralInfoForm) => {
    const options = { shouldDirty: true };

    createIntegratedMembershipTypeFormContext.setValue(
      'backgroundImage',
      data.backgroundImage,
      options,
    );
    createIntegratedMembershipTypeFormContext.setValue(
      'contactDetails',
      data.contactDetails,
      options,
    );
    createIntegratedMembershipTypeFormContext.setValue(
      'description',
      data.description,
      options,
    );
    createIntegratedMembershipTypeFormContext.setValue(
      'logo',
      data.logo,
      options,
    );
    createIntegratedMembershipTypeFormContext.setValue(
      'name',
      data.name,
      options,
    );
  };

  const handleNext = formMethods.handleSubmit(
    (data) => {
      setValues(data);

      navigate('/memberships/create-integration/summary');
    },
    (err) => {
      Logger.warning('createMembershipIntegrationGeneralInfo Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const handlePrevious = () => {
    setValues(formMethods.getValues());

    navigate('/memberships/create-integration/select-service');
  };

  return (
    <FormProvider {...formMethods}>
      <CreateIntegratedMembershipFormNavBlocker shouldBlock />

      <GeneralInfoProvider>
        <ContentContainer>
          <InnerContentContainer>
            <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
              <Text
                color="pageTitle"
                as="h1"
                tx="label.memberships.create-membership.tabs.general-info"
                variant="titleMd"
              />

              <ToggleSidebarButton />
            </Box>

            <Box flex flexDirection="column" gap={24}>
              <MembershipDetails />

              <Box flex flexDirection="column" gap={16}>
                <Text
                  variant="bodyMdBold"
                  tx="label.memberships.create-membership.general-info.steps.2"
                />

                <UploadLogo name="logo" />
              </Box>

              <Box flex flexDirection="column" gap={16}>
                <Text
                  variant="bodyMdBold"
                  tx="label.memberships.create-membership.general-info.steps.3"
                />

                <UploadCoverImage name="backgroundImage" />
              </Box>
              <ContactDetails />
            </Box>
          </InnerContentContainer>

          <Box p={32} flex flexJustify="between" gap={16}>
            <Tooltip titleTx="button.previous" placement="right">
              <IconButton
                onClick={handlePrevious}
                icon="arrow-left-circle-outline"
              />
            </Tooltip>

            <Tooltip placement="left" titleTx="button.continue">
              <IconButton
                icon="arrow-right-circle-outline"
                onClick={handleNext}
              />
            </Tooltip>
          </Box>
        </ContentContainer>

        <GeneralInfoSidebar />
      </GeneralInfoProvider>
    </FormProvider>
  );
}
