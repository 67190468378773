import {
  CreatePostForm,
  GetPostCommentsPageParams,
} from '../../../../../models';
import { CreatePostDto } from './posts.dto';

export function createPostMapper(
  createPostForm: CreatePostForm,
): CreatePostDto {
  let fileUploadKeys: string[] | null = null;
  if (createPostForm.fileUploads.length) {
    fileUploadKeys = createPostForm.fileUploads
      .map((fileUpload) => fileUpload.fileUploadKey)
      .filter((key): key is string => key !== null);
  }

  return {
    fileUploadKeys,
    message: createPostForm.message.length ? createPostForm.message : null,
    pushTo: createPostForm.pushTo,
    target: {
      key: createPostForm.target.key,
      type: createPostForm.target.type ?? 'department',
    },
  };
}

export function getPostCommentsMapper(
  params: GetPostCommentsPageParams,
): GetPostCommentsPageParams {
  return {
    ...params,
    activityKey: undefined,
  };
}
