import { Address } from '@orbiapp/components';
import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  PartialDepartment,
  PartialMembershipType,
  PartialUser,
} from '../../models';

export const addressesAdapter = createEntityAdapter<Address, string>({
  selectId: (location) => location.address,
});

export const membershipTypesAdapter = createEntityAdapter<
  PartialMembershipType,
  string
>({
  selectId: (membershipType) => membershipType.membershipTypeKey,
});

export const usersAdapter = createEntityAdapter<PartialUser, string>({
  selectId: (user) => user.userKey,
});

export const departmentsAdapter = createEntityAdapter<
  PartialDepartment,
  string
>({
  selectId: (department) => department.departmentKey,
});
