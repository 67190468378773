import styled, { css } from 'styled-components';

import { baseComponentStyles, getOrbiGradientBorder } from '../../utils';
import { ButtonVariant, GetButtonColorsReturn } from './button.types';

interface StyledButtonProps {
  isLoading?: boolean;
  isDark?: boolean;
  variant: ButtonVariant;
  large?: boolean;
}

function getOnDarkButtonColors(variant: ButtonVariant): GetButtonColorsReturn {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: 'buttonPrimaryOnDarkBackground',
        backgroundColorActive: 'buttonPrimaryOnDarkBackgroundActive',
        backgroundColorDisabled: 'buttonPrimaryOnDarkBackgroundDisabled',
        backgroundColorHover: 'buttonPrimaryOnDarkBackgroundHover',
        borderColor: 'buttonPrimaryOnDarkBorder',
        borderColorActive: 'buttonPrimaryOnDarkBorderActive',
        borderColorDisabled: 'buttonPrimaryOnDarkBorderDisabled',
        borderColorHover: 'buttonPrimaryOnDarkBorderHover',
        labelColor: 'buttonPrimaryOnDarkLabel',
        labelColorActive: 'buttonPrimaryOnDarkLabelActive',
        labelColorDisabled: 'buttonPrimaryOnDarkLabelDisabled',
        labelColorHover: 'buttonPrimaryOnDarkLabelHover',
      };

    case 'secondary':
      return {
        backgroundColor: 'buttonSecondaryOnDarkBackground',
        backgroundColorActive: 'buttonSecondaryOnDarkBackgroundActive',
        backgroundColorDisabled: 'buttonSecondaryOnDarkBackgroundDisabled',
        backgroundColorHover: 'buttonSecondaryOnDarkBackgroundHover',
        borderColor: 'buttonSecondaryOnDarkBorder',
        borderColorActive: 'buttonSecondaryOnDarkBorderActive',
        borderColorDisabled: 'buttonSecondaryOnDarkBorderDisabled',
        borderColorHover: 'buttonSecondaryOnDarkBorderHover',
        labelColor: 'buttonSecondaryOnDarkLabel',
        labelColorActive: 'buttonSecondaryOnDarkLabelActive',
        labelColorDisabled: 'buttonSecondaryOnDarkLabelDisabled',
        labelColorHover: 'buttonSecondaryOnDarkLabelHover',
      };

    case 'tertiary':
      return {
        backgroundColor: 'buttonTertiaryOnDarkBackground',
        backgroundColorActive: 'buttonTertiaryOnDarkBackgroundActive',
        backgroundColorDisabled: 'buttonTertiaryOnDarkBackgroundDisabled',
        backgroundColorHover: 'buttonTertiaryOnDarkBackgroundHover',
        borderColor: 'buttonTertiaryOnDarkBorder',
        borderColorActive: 'buttonTertiaryOnDarkBorderActive',
        borderColorDisabled: 'buttonTertiaryOnDarkBorderDisabled',
        borderColorHover: 'buttonTertiaryOnDarkBorderHover',
        labelColor: 'buttonTertiaryOnDarkLabel',
        labelColorActive: 'buttonTertiaryOnDarkLabelActive',
        labelColorDisabled: 'buttonTertiaryOnDarkLabelDisabled',
        labelColorHover: 'buttonTertiaryOnDarkLabelHover',
      };

    case 'destructive':
      return {
        backgroundColor: 'buttonDestructiveOnDarkBackground',
        backgroundColorActive: 'buttonDestructiveOnDarkBackgroundActive',
        backgroundColorDisabled: 'buttonDestructiveOnDarkBackgroundDisabled',
        backgroundColorHover: 'buttonDestructiveOnDarkBackgroundHover',
        borderColor: 'buttonDestructiveOnDarkBorder',
        borderColorActive: 'buttonDestructiveOnDarkBorderActive',
        borderColorDisabled: 'buttonDestructiveOnDarkBorderDisabled',
        borderColorHover: 'buttonDestructiveOnDarkBorderHover',
        labelColor: 'buttonDestructiveOnDarkLabel',
        labelColorActive: 'buttonDestructiveOnDarkLabelActive',
        labelColorDisabled: 'buttonDestructiveOnDarkLabelDisabled',
        labelColorHover: 'buttonDestructiveOnDarkLabelHover',
      };
  }
}

function getButtonColors(variant: ButtonVariant): GetButtonColorsReturn {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: 'buttonPrimaryBackground',
        backgroundColorActive: 'buttonPrimaryBackgroundActive',
        backgroundColorDisabled: 'buttonPrimaryBackgroundDisabled',
        backgroundColorHover: 'buttonPrimaryBackgroundHover',
        borderColor: 'buttonPrimaryBorder',
        borderColorActive: 'buttonPrimaryBorderActive',
        borderColorDisabled: 'buttonPrimaryBorderDisabled',
        borderColorHover: 'buttonPrimaryBorderHover',
        labelColor: 'buttonPrimaryLabel',
        labelColorActive: 'buttonPrimaryLabelActive',
        labelColorDisabled: 'buttonPrimaryLabelDisabled',
        labelColorHover: 'buttonPrimaryLabelHover',
      };

    case 'secondary':
      return {
        backgroundColor: 'buttonSecondaryBackground',
        backgroundColorActive: 'buttonSecondaryBackgroundActive',
        backgroundColorDisabled: 'buttonSecondaryBackgroundDisabled',
        backgroundColorHover: 'buttonSecondaryBackgroundHover',
        borderColor: 'buttonSecondaryBorder',
        borderColorActive: 'buttonSecondaryBorderActive',
        borderColorDisabled: 'buttonSecondaryBorderDisabled',
        borderColorHover: 'buttonSecondaryBorderHover',
        labelColor: 'buttonSecondaryLabel',
        labelColorActive: 'buttonSecondaryLabelActive',
        labelColorDisabled: 'buttonSecondaryLabelDisabled',
        labelColorHover: 'buttonSecondaryLabelHover',
      };

    case 'tertiary':
      return {
        backgroundColor: 'buttonTertiaryBackground',
        backgroundColorActive: 'buttonTertiaryBackgroundActive',
        backgroundColorDisabled: 'buttonTertiaryBackgroundDisabled',
        backgroundColorHover: 'buttonTertiaryBackgroundHover',
        borderColor: 'buttonTertiaryBorder',
        borderColorActive: 'buttonTertiaryBorderActive',
        borderColorDisabled: 'buttonTertiaryBorderDisabled',
        borderColorHover: 'buttonTertiaryBorderHover',
        labelColor: 'buttonTertiaryLabel',
        labelColorActive: 'buttonTertiaryLabelActive',
        labelColorDisabled: 'buttonTertiaryLabelDisabled',
        labelColorHover: 'buttonTertiaryLabelHover',
      };

    case 'destructive':
      return {
        backgroundColor: 'buttonDestructiveBackground',
        backgroundColorActive: 'buttonDestructiveBackgroundActive',
        backgroundColorDisabled: 'buttonDestructiveBackgroundDisabled',
        backgroundColorHover: 'buttonDestructiveBackgroundHover',
        borderColor: 'buttonDestructiveBorder',
        borderColorActive: 'buttonDestructiveBorderActive',
        borderColorDisabled: 'buttonDestructiveBorderDisabled',
        borderColorHover: 'buttonDestructiveBorderHover',
        labelColor: 'buttonDestructiveLabel',
        labelColorActive: 'buttonDestructiveLabelActive',
        labelColorDisabled: 'buttonDestructiveLabelDisabled',
        labelColorHover: 'buttonDestructiveLabelHover',
      };
  }
}

const Button = styled.button<StyledButtonProps>`
  ${(props) => {
    const { isLoading, theme, variant, isDark, large } = props;

    const {
      backgroundColor,
      backgroundColorActive,
      backgroundColorDisabled,
      backgroundColorHover,
      borderColor,
      borderColorActive,
      borderColorDisabled,
      borderColorHover,
      labelColor,
      labelColorActive,
      labelColorDisabled,
      labelColorHover,
    } = isDark ? getOnDarkButtonColors(variant) : getButtonColors(variant);

    const { fontSize, letterSpacing, fontFamily, lineHeight } =
      theme.textVariants.buttonMd;

    const buttonStyles = css`
      border-radius: 100px;
      border: none;
      outline: none;
      outline: none;
      width: fit-content;
      cursor: pointer;
      font-size: ${fontSize}px;
      letter-spacing: ${letterSpacing}px;
      line-height: ${lineHeight}px;
      font-family: ${fontFamily};

      ${large
        ? css`
            height: 48px;
            padding: 12px 32px;
          `
        : css`
            padding: 8px 24px;
          `}
    `;

    return css`
      ${baseComponentStyles};
      ${buttonStyles};

      background-color: ${theme.colors[backgroundColor]};
      box-shadow: 0 0 0 1px ${theme.colors[borderColor]};
      color: ${theme.colors[labelColor]};

      ${isLoading &&
      css`
        pointer-events: none;
      `}

      :hover {
        background-color: ${theme.colors[backgroundColorHover]};
        box-shadow: 0 0 0 1px ${theme.colors[borderColorHover]};
        color: ${theme.colors[labelColorHover]};
      }

      :active {
        box-shadow: 0 0 0 2px ${theme.colors[borderColorActive]};
        color: ${theme.colors[labelColorActive]};
        background-color: ${theme.colors[backgroundColorActive]};

        ${variant === 'primary' &&
        css`
          ${getOrbiGradientBorder(2)};
        `}
      }

      :disabled {
        pointer-events: none;
        background-color: ${theme.colors[backgroundColorDisabled]};
        box-shadow: 0 0 0 1px ${theme.colors[borderColorDisabled]};
        color: ${theme.colors[labelColorDisabled]};
        opacity: 50%;
      }
    `;
  }}
`;

const ChildrenBox = styled.span<{ isLoading?: boolean }>`
  ${(props) => {
    const { isLoading } = props;

    return css`
      ${isLoading &&
      css`
        opacity: 0;
      `}
    `;
  }}
`;

export const Styled = { Button, ChildrenBox };
