import { Color } from '@orbiapp/theme';
import styled, { css } from 'styled-components';

import { Box } from '../box';

const Banner = styled(Box)<{ backgroundColor: Color }>`
  ${(props) => {
    const { backgroundColor, theme } = props;

    return css`
      background-color: ${theme.colors[backgroundColor]};
    `;
  }}
`;

const BannerInnerBox = styled(Box)`
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
`;

const BannerCloseIconBox = styled(Box)<{ backgroundColor: Color }>`
  ${(props) => {
    const { backgroundColor, theme } = props;

    return css`
      background-color: ${theme.colors[backgroundColor]};
    `;
  }}
`;

const BannerShadow = styled(Box)<{ backgroundColor: Color }>`
  ${(props) => {
    const { backgroundColor, theme } = props;

    return css`
      background: ${theme.colors[backgroundColor]};
    `;
  }}
`;

export const Styled = {
  Banner,
  BannerCloseIconBox,
  BannerInnerBox,
  BannerShadow,
};
