import { PostDto } from 'dtos/student-dashboard/v1/post/post.dto';

import {
  createPostCommentThunk,
  createPostThunk,
  deletePostCommentThunk,
  deletePostThunk,
  getPostCommentsThunk,
  getPostThunk,
  getPostsThunk,
  updatePostCommentThunk,
  updatePostThunk,
} from './posts.actions';
import { postCommentsAdapter, postsAdapter } from './posts.adapter';
import { initialPostsState } from './posts.initial';
import { PostsBuilder } from './posts.types';
import { getPostsParams } from './posts.utils';

const getPostsBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getPostsThunk.fulfilled, (state, action) => {
      state.posts.status = 'idle';

      postsAdapter.setAll(state.posts.items, action.payload.items);

      state.posts.pageInfo = action.payload.pageInfo;
      state.posts.totalCount = action.payload.totalCount;
    })
    .addCase(getPostsThunk.pending, (state, action) => {
      state.posts.status = 'pending';
      state.posts.error = null;

      const params = getPostsParams(
        {
          activityKeys: state.posts.extra.activityKeys,
          q: state.posts.extra.q,
          targetType: state.posts.extra.targetType,
          orderBy: state.posts.orderBy,
          sortOrder: state.posts.sortOrder,
          pageSize: state.posts.pageSize,
        },
        action.meta.arg,
      );

      state.posts.extra = {
        activityKeys: params.activityKeys,
        q: params.q,
        targetType: params.targetType,

        lastRequestParams: params,
      };
      state.posts.orderBy = params.orderBy;
      state.posts.pageSize =
        params.first ?? params.last ?? initialPostsState.posts.pageSize;
      state.posts.sortOrder = params.sortOrder;
    })
    .addCase(getPostsThunk.rejected, (state, action) => {
      state.posts.status = 'idle';
      state.posts.error = action.error;
    });
};

const getPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getPostThunk.fulfilled, (state, action) => {
      state.post.status = 'idle';
      state.post.data = action.payload;

      postsAdapter.updateOne(state.posts.items, {
        id: action.payload.postKey,
        changes: action.payload,
      });
    })
    .addCase(getPostThunk.pending, (state, action) => {
      state.post.status = 'pending';
      state.post.error = null;

      if (action.meta.arg === state.post.data?.postKey) return;

      const post = postsAdapter
        .getSelectors()
        .selectById(state.posts.items, action.meta.arg);

      state.post.data = post;

      postCommentsAdapter.removeAll(state.postComments.items);
    })
    .addCase(getPostThunk.rejected, (state, action) => {
      state.post.status = 'idle';
      state.post.error = action.error;
    });
};

const createPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(createPostThunk.fulfilled, (state, action) => {
      state.createPost.status = 'idle';
      state.post.data = action.payload;
      postCommentsAdapter.removeAll(state.postComments.items);
    })
    .addCase(createPostThunk.pending, (state) => {
      state.createPost.status = 'pending';
      state.createPost.error = null;
    })
    .addCase(createPostThunk.rejected, (state, action) => {
      state.createPost.status = 'idle';
      state.createPost.error = action.error;
    });
};

const updatePostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(updatePostThunk.fulfilled, (state, action) => {
      state.updatePost.status = 'idle';

      const changes: Partial<PostDto> = {
        message: action.meta.arg.message,
        updatedAt: Date.now(),
      };

      if (state.post.data?.postKey === action.meta.arg.postKey) {
        state.post.data = { ...state.post.data, ...changes };
      }

      postsAdapter.updateOne(state.posts.items, {
        id: action.meta.arg.postKey,
        changes,
      });
    })
    .addCase(updatePostThunk.pending, (state) => {
      state.updatePost.status = 'pending';
      state.updatePost.error = null;
    })
    .addCase(updatePostThunk.rejected, (state, action) => {
      state.updatePost.status = 'idle';
      state.updatePost.error = action.error;
    });
};

const updatePostCommentBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(updatePostCommentThunk.fulfilled, (state, action) => {
      state.updatePostComment.status = 'idle';

      postCommentsAdapter.updateOne(state.postComments.items, {
        id: action.meta.arg.postCommentKey,
        changes: action.payload,
      });
    })
    .addCase(updatePostCommentThunk.pending, (state) => {
      state.updatePostComment.status = 'pending';
      state.updatePostComment.error = null;
    })
    .addCase(updatePostCommentThunk.rejected, (state, action) => {
      state.updatePostComment.status = 'idle';
      state.updatePostComment.error = action.error;
    });
};

const deletePostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(deletePostThunk.fulfilled, (state, action) => {
      state.deletePost.status = 'idle';

      const changes: Partial<PostDto> = {
        updatedAt: Date.now(),
        message: null,
        isDeleted: true,
        files: null,
      };

      if (state.post.data?.postKey === action.meta.arg) {
        state.post.data = { ...state.post.data, ...changes };
      }

      postsAdapter.updateOne(state.posts.items, {
        id: action.meta.arg,
        changes,
      });
    })
    .addCase(deletePostThunk.pending, (state) => {
      state.deletePost.status = 'pending';
      state.deletePost.error = null;
    })
    .addCase(deletePostThunk.rejected, (state, action) => {
      state.deletePost.status = 'idle';
      state.deletePost.error = action.error;
    });
};

const getPostCommentsBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getPostCommentsThunk.fulfilled, (state, action) => {
      state.postComments.status = 'idle';

      postCommentsAdapter.addMany(
        state.postComments.items,
        action.payload.items,
      );

      state.postComments.orderBy = action.meta.arg.orderBy;
      state.postComments.pageInfo = action.payload.pageInfo;
      state.postComments.sortOrder = action.meta.arg.sortOrder;
      state.postComments.totalCount = action.payload.totalCount;
    })
    .addCase(getPostCommentsThunk.pending, (state) => {
      state.postComments.status = 'pending';
      state.postComments.error = null;
    })
    .addCase(getPostCommentsThunk.rejected, (state, action) => {
      state.postComments.status = 'idle';
      state.postComments.error = action.error;
    });
};

const deletePostCommentBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(deletePostCommentThunk.fulfilled, (state, action) => {
      state.deletePostComment.status = 'idle';

      postCommentsAdapter.updateOne(state.postComments.items, {
        id: action.meta.arg.postCommentKey,
        changes: {
          isDeleted: true,
          message: '',
          updatedAt: Date.now(),
        },
      });
    })
    .addCase(deletePostCommentThunk.pending, (state) => {
      state.deletePostComment.status = 'pending';
      state.deletePostComment.error = null;
    })
    .addCase(deletePostCommentThunk.rejected, (state, action) => {
      state.deletePostComment.status = 'idle';
      state.deletePostComment.error = action.error;
    });
};

const createPostCommentBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(createPostCommentThunk.fulfilled, (state, action) => {
      state.createPostComment.status = 'idle';

      postCommentsAdapter.addOne(state.postComments.items, action.payload);

      const post = state.posts.items.entities[action.meta.arg.postKey];
      if (post) {
        postsAdapter.updateOne(state.posts.items, {
          id: action.meta.arg.postKey,
          changes: {
            postCommentCount: post.postCommentCount + 1,
            updatedAt: Date.now(),
          },
        });
      }

      if (
        state.post.data &&
        state.post.data.postKey === action.meta.arg.postKey
      ) {
        state.post.data.postCommentCount = state.post.data.postCommentCount + 1;
      }
    })
    .addCase(createPostCommentThunk.pending, (state) => {
      state.createPostComment.status = 'pending';
      state.createPostComment.error = null;
    })
    .addCase(createPostCommentThunk.rejected, (state, action) => {
      state.createPostComment.status = 'idle';
      state.createPostComment.error = action.error;
    });
};

export const extraReducers = (builder: PostsBuilder) => {
  getPostsBuild(builder);
  getPostBuild(builder);
  createPostBuild(builder);
  updatePostBuild(builder);
  deletePostBuild(builder);
  getPostCommentsBuild(builder);
  deletePostCommentBuild(builder);
  createPostCommentBuild(builder);
  updatePostCommentBuild(builder);
};
