import { Box } from '@orbiapp/components';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const SettingCard = styled(Box)<{ enableHover: boolean }>`
  ${(props) => {
    const { enableHover, theme } = props;

    return css`
      background-color: ${theme.colors.settingCardBackground};
      border: 1px solid ${theme.colors.settingCardBorder};
      border-radius: 4px;
      height: fit-content;
      width: 100%;

      @media (min-width: ${theme.breakpoints.xs}px) {
        width: 300px;
      }

      ${enableHover &&
      css`
        cursor: pointer;

        :hover {
          background-color: ${theme.colors.settingCardBackgroundHover};
        }
      `}
    `;
  }}
`;

const CardLinkWrapper = styled(Link)`
  text-decoration: none;
`;

export const Styled = {
  CardLinkWrapper,
  SettingCard,
};
