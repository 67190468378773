import { StatusVariant } from '@orbiapp/components';

import {
  CoHost,
  CohostRequestStatus,
  DESCRIPTION_MAX_LENGTH,
  TAGS,
} from '../models';

export function getActivityDescriptionMaxLength(enableTags: boolean) {
  return enableTags
    ? DESCRIPTION_MAX_LENGTH - TAGS.reduce((acc, tag) => acc + tag.length, 0)
    : DESCRIPTION_MAX_LENGTH;
}

export function getCoHostRequests(
  cohosts: CoHost[],
  cohostRequestStatus?: CohostRequestStatus,
) {
  return cohosts.map((cohost) => ({
    name: cohost.name,
    logoUrl: cohost.logoUrl,
    departmentKey: cohost.departmentKey,
    orgNodesBranch: cohost.orgNodesBranch,
    cohostRequestStatus: cohostRequestStatus || cohost.cohostRequestStatus,
  }));
}

export function getActivityStatusOptions(options: {
  startDate: number;
  endDate: number;
}): {
  tx: TxString;
  variant: StatusVariant;
} {
  const { startDate, endDate } = options;

  if (endDate === 0 || startDate === 0) {
    return {
      variant: 'error',
      tx: 'label.activity-status.unpublished',
    };
  }

  const isExpired = endDate < Date.now();

  if (isExpired) {
    return {
      variant: 'error',
      tx: 'label.activity-status.expired',
    };
  }

  return {
    variant: 'success',
    tx: 'label.activity-status.published',
  };
}
