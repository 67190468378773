import Joi from 'joi';

import { ContactDetailsValidation } from '../contact-details';
import { EmailSchema } from '../email';
import { CoverImageValidation, LogoValidation } from '../file';
import { BoolSchema } from '../shared';
import {
  MAX_MEMBERSHIP_INVITES_COUNT,
  MEMBERSHIP_DESCRIPTION_MAX_LENGTH,
  MEMBERSHIP_NAME_MAX_LENGTH,
} from './membership-type.constants';
import { MembershipTypeErrors } from './membership-type.errors';
import { membershipTypeTypes } from './membership-type.model';
import { CreateMembershipPeriodValidation } from './period';
import {
  CreateMembershipCheckboxQuestionValidation,
  CreateMembershipFreetextQuestionValidation,
  CreateMembershipMultichoiceQuestionValidation,
} from './questions';
import { CreateRequiredMembershipTypesValidation } from './required-memberships';

export namespace MembershipTypeSchema {
  export const description = Joi.string()
    .max(MEMBERSHIP_DESCRIPTION_MAX_LENGTH)
    .required()
    .messages(MembershipTypeErrors.description);

  export const logo = LogoValidation.required().messages(
    MembershipTypeErrors.logo,
  );

  export const backgroundImage = CoverImageValidation.required().messages(
    MembershipTypeErrors.backgroundImage,
  );

  export const contactDetails = ContactDetailsValidation.required();

  export const name = Joi.string()
    .max(MEMBERSHIP_NAME_MAX_LENGTH)
    .required()
    .messages(MembershipTypeErrors.name);

  export const createMembershipQuestions = Joi.array()
    .items(
      CreateMembershipCheckboxQuestionValidation.optional(),
      CreateMembershipFreetextQuestionValidation.optional(),
      CreateMembershipMultichoiceQuestionValidation.optional(),
    )
    .min(1)
    .allow(null)
    .required();

  export const periods = Joi.array()
    .items(CreateMembershipPeriodValidation.required())
    .allow(null)
    .required();

  export const requiredMembershipTypes =
    CreateRequiredMembershipTypesValidation.allow(null).required();

  export const type = Joi.string()
    .valid(...membershipTypeTypes)
    .required()
    .messages(MembershipTypeErrors.type);

  export const enabled = BoolSchema.required();

  export const emails = Joi.array()
    .items(EmailSchema)
    .max(MAX_MEMBERSHIP_INVITES_COUNT)
    .required();

  export const membershipPeriodKeys = Joi.array()
    .items(Joi.string().required())
    .required()
    .messages(MembershipTypeErrors.membershipPeriodKeys);
}
