import Joi from 'joi';

import {
  POST_MAX_FILE_UPLOADS,
  POST_MESSAGE_MAX_LENGTH,
} from './post.constants';
import { PostErrors } from './post.errors';
import { postTargetTypes } from './post.model';

export namespace CreatePostSchema {
  export const target = Joi.object({
    type: Joi.string()
      .valid(...postTargetTypes)
      .required(),
    key: Joi.string().required().messages(PostErrors.target.key),
  }).required();

  export const fileUploads = Joi.array()
    .items(
      Joi.object({
        base64: Joi.string().required(),
        fileUploadKey: Joi.string().allow(null).required(),
        id: Joi.string().required(),
        status: Joi.string().valid('pending', 'success', 'error').required(),
      }),
    )
    .max(POST_MAX_FILE_UPLOADS)
    .required();

  export const message = Joi.when('fileUploads', {
    is: fileUploads.min(1),
    then: Joi.string().allow('').max(POST_MESSAGE_MAX_LENGTH),
    otherwise: Joi.string().max(POST_MESSAGE_MAX_LENGTH).required(),
  }).messages(PostErrors.message);

  export const pushTo = Joi.string()
    .valid('followers', 'attendees', 'active_members')
    .allow(null);
}

export namespace UpdatePostSchema {
  export const message = Joi.string().max(POST_MESSAGE_MAX_LENGTH).required();
}
