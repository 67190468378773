import Joi from 'joi';

import { combinationTypes } from '../../shared';

export namespace MembershipApplicationRequirementSchema {
  export const membershipApplicationRequirementKey = Joi.string().required();

  export const departmentName = Joi.string().required();

  export const logoUrl = Joi.string().required();

  export const membershipTypeKey = Joi.string().required();

  export const name = Joi.string().required();

  export const orgNodesBranch = Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      orgNodeKey: Joi.string().required(),
      parentKey: Joi.string().allow(null).required(),
    }),
  );

  export const membershipApplicationRequirements = Joi.array().items(
    Joi.object({
      membershipApplicationRequirementKey,
      departmentName,
      logoUrl,
      membershipTypeKey,
      name,
      orgNodesBranch,
    }),
  );

  export const combinationType = Joi.string()
    .valid(...combinationTypes)
    .required();
}
