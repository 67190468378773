import { Box } from '../../box';
import { PageSize, Paginator, PaginatorProps } from '../../paginator';
import { Text } from '../../text';
import { TinySelect, TinySelectOptionProps } from '../../tiny-select';

interface TablePaginationProps extends PaginatorProps {
  tx: TxString;

  currentPage?: number;

  hasPrevPage: boolean;
  hasNextPage: boolean;

  onPageSizeChange: (pageSize: PageSize) => void;
  onPaginate?: (page: number) => void;

  pageSize: PageSize;

  paginatorOptions: TinySelectOptionProps<PageSize>[];
}

export function TablePagination(props: TablePaginationProps) {
  const {
    tx,

    currentPage = 0,

    hasPrevPage,
    hasNextPage,

    onPageSizeChange,
    onPaginate,

    onGoToNextPage,
    onGoToPreviousPage,

    onGoToFirstPage,
    onGoToLastPage,

    pageSize,

    paginatorOptions,
  } = props;

  const goToPreviousPage = () => {
    onGoToPreviousPage?.();
    onPaginate?.(currentPage - 1);
  };

  const goToNextPage = () => {
    onGoToNextPage?.();
    onPaginate?.(currentPage + 1);
  };

  return (
    <Box flexJustify="end" gap={24} flexAlign="center" flex>
      <Box flex gap={16} flexAlign="center">
        <Text tx={tx} variant="bodySm" whiteSpace="nowrap" />

        <TinySelect
          invertMenu
          onChange={onPageSizeChange}
          options={paginatorOptions}
          value={pageSize}
        />
      </Box>

      <Paginator
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        onGoToNextPage={goToNextPage}
        onGoToPreviousPage={goToPreviousPage}
        onGoToFirstPage={onGoToFirstPage}
        onGoToLastPage={onGoToLastPage}
      />
    </Box>
  );
}
