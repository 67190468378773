import Joi from 'joi';

import { MembershipIntegrationSchema } from './integrated.schema';

export const SelectServiceFormValidation = Joi.alternatives(
  MembershipIntegrationSchema.montania,
  MembershipIntegrationSchema.memlist,
).required();

export const CreateMembershipIntegrationValidation = Joi.object({
  backgroundImage: MembershipIntegrationSchema.backgroundImage,
  description: MembershipIntegrationSchema.description,
  logo: MembershipIntegrationSchema.logo,
  metadata: SelectServiceFormValidation,
  name: MembershipIntegrationSchema.name,
});
