import Joi from 'joi';

import { NOTE_MAX_LENGTH } from './application.constants';
import { ApplicationErrors } from './application.errors';
import { needsMoreInfoReasons } from './application.model';

export namespace ApplicationSchema {
  export const note = Joi.string()
    .required()
    .max(NOTE_MAX_LENGTH)
    .messages(ApplicationErrors.note);

  export const reason = Joi.string()
    .valid(...Object.values(needsMoreInfoReasons))
    .required();

  export const membershipApplicationKey = Joi.string().required();
}
