import {
  Avatar,
  Box,
  FormHeader,
  Icon,
  IconButton,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
} from '@orbiapp/components';
import { FieldErrors } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { PublishButton } from '../../../../../components';
import { CreateOfferForm } from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  AccountSelector,
  CreateOfferSelector,
  createOfferThunk,
  globalUiStateActions,
  setAlert,
  useDispatch,
  useSelector,
} from '../../../../../store';

export function OfferFormHeader() {
  const userKey = useSelector(AccountSelector.selectUserKey);
  const fullName = useSelector(AccountSelector.selectFullName);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);
  const createOfferStatus = useSelector(CreateOfferSelector.selectStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const publishOffer = async (createOfferForm: CreateOfferForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(createOfferThunk(createOfferForm));
    if (res.payload && 'offerKey' in res.payload) {
      navigate(`/offers/${res.payload.offerKey}`);
    }
  };

  const handleError = (err: FieldErrors<CreateOfferForm>) => {
    Logger.warning('createOffer Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  return (
    <FormHeader flex flexDirection="column" gap={12} width="100%">
      <Box
        flex
        flexAlign="center"
        flexJustify="between"
        gap={24}
        width="100%"
        flexWrap
      >
        <Box flexGrow={1} gap={8} flex flexDirection="column">
          <Box gap={8} flexAlign="center" flex>
            <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
              <IconButton to="/offers" icon="chevron-left" />
            </Tooltip>

            <Text
              color="pageTitle"
              variant="titleMd"
              tx="title.offers.create-offer"
              as="h1"
            />
          </Box>
        </Box>

        <PublishButton
          onSubmit={publishOffer}
          onError={handleError}
          isLoading={createOfferStatus === 'pending'}
        />
      </Box>

      {fullName && userKey && (
        <Box flexWrap flexAlign="center" gap={16} flex>
          <Box flex flexAlign="center" mr={48} gap={16}>
            <Icon
              color="formHeaderEditedByMetaIcon"
              name="user-circle-outline"
            />
            <Text
              color="formHeaderEditedByMetaTitle"
              tx="label.offer-form.created-by"
              variant="bodySm"
            />

            <Box flexAlign="center" gap={8} flex>
              <Avatar
                variant={getAvatarVariantFromString(userKey)}
                width={32}
                height={32}
                fallbackLetter={fullName[0]}
                src={profilePicture?.thumbnail64.url}
              />

              <Text
                color="formHeaderEditedByMeta"
                text={fullName}
                textTransform="capitalize"
                variant="bodySm"
              />
            </Box>
          </Box>
        </Box>
      )}
    </FormHeader>
  );
}
