import { createSelector } from '@reduxjs/toolkit';

import { segmentationType } from '../../models';
import { RootState } from '../store.types';

export namespace DepartmentSelector {
  const selectSelf = (state: RootState) => state.department.department;

  export const selectStatus = createSelector(
    selectSelf,
    (departmentSettings) => departmentSettings.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (departmentSettings) => departmentSettings.error,
  );

  export const selectData = createSelector(
    selectSelf,
    (departmentSettings) => departmentSettings.data,
  );

  export const selectDepartmentKey = createSelector(
    selectData,
    (data) => data?.departmentKey,
  );

  export const selectEnableTags = createSelector(
    selectData,
    (data) => data?.enableTags ?? false,
  );

  export const selectIsSemester = createSelector(
    selectData,
    (data) => data?.segmentationType === 'semester',
  );

  export const selectName = createSelector(selectData, (data) => data?.name);

  export const selectFollowerCount = createSelector(
    selectData,
    (data) => data?.followerCount,
  );

  export const selectIsCareerAdmin = createSelector(
    selectData,
    (data) => data?.isCareerAdmin,
  );

  export const selectSegmentationType = createSelector(
    selectData,
    (data) => data?.segmentationType ?? segmentationType[0],
  );

  export const selectLogo = createSelector(selectData, (data) => data?.logo);

  export const selectDepartmentLocations = createSelector(
    selectData,
    (data) => data?.locations,
  );

  export const selectReferredAt = createSelector(
    selectData,
    (data) => data?.referredAt ?? null,
  );
}

export namespace UpdateDepartmentSelector {
  export const selectStatus = (state: RootState) =>
    state.department.updateDepartment.status;
}
