import { PayloadAction } from '@reduxjs/toolkit';

import { Question } from '../../models';
import {
  activityQuestionsAdapter,
  questionsAdapter,
} from './questions.adapter';
import { initialQuestionsState } from './questions.initial';
import { QuestionState } from './questions.types';

const reset = (state: QuestionState) => {
  Object.assign(state, initialQuestionsState);
};

const clearQuestions = (state: QuestionState) => {
  state.questions = initialQuestionsState.questions;
};

const toggleHideHandled = (state: QuestionState) => {
  state.questions.hideHandled = !state.questions.hideHandled;
  questionsAdapter.removeAll(state.questions.data);
};

const clearSelectedQuestion = (state: QuestionState) => {
  state.selectedQuestion = null;
};

const clearViewActivityQuestionData = (state: QuestionState) => {
  activityQuestionsAdapter.removeAll(state.activityQuestions.data);
  state.selectedActivityQuestion = null;
};

const selectQuestion = (
  state: QuestionState,
  action: PayloadAction<Question>,
) => {
  state.selectedQuestion = action.payload;
};

const clearSelectedActivityQuestion = (state: QuestionState) => {
  state.selectedActivityQuestion = null;
};

const selectActivityQuestion = (
  state: QuestionState,
  action: PayloadAction<Question>,
) => {
  state.selectedActivityQuestion = action.payload;
};

export const reducers = {
  reset,

  clearQuestions,
  clearSelectedActivityQuestion,
  clearSelectedQuestion,
  clearViewActivityQuestionData,
  selectActivityQuestion,
  selectQuestion,
  toggleHideHandled,
};
