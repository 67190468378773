import { Time } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DepartmentInvitation,
  InviteTeamMembersForm,
  PendingDepartmentInvitation,
  UpdateUserParams,
  User,
} from '../../models';
import { OrbiApi, v2 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';

export const deleteInvitationThunk = createAsyncThunk<
  undefined,
  string,
  ThunkApiConfig
>('team/delete-invitation', async (companyInvitationKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.team.deleteInvitation,
    companyInvitationKey,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('remove-pending-invitation:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('remove-pending-invitation:success'));
  return thunkAPI.rejectWithValue(res);
});

export const getDepartmentInvitationThunk = createAsyncThunk<
  DepartmentInvitation,
  string,
  ThunkApiConfig
>('team/get-department-invitation', async (companyInvitationKey, thunkAPI) => {
  const state = thunkAPI.getState();

  if (state.team.departmentInvitation.data) {
    return state.team.departmentInvitation.data;
  }

  const res = await OrbiApi.call(
    v2.team.getCompanyInvitationThunk,
    companyInvitationKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getPendingInvitationsThunk = createAsyncThunk<
  PendingDepartmentInvitation[],
  undefined,
  ThunkApiConfig
>('team/get-invitation', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.team.getPendingInvitations, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getUsersThunk = createAsyncThunk<
  User[],
  undefined,
  ThunkApiConfig
>('team/get-users', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.team.getUsers, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createInvitationsThunk = createAsyncThunk<
  PendingDepartmentInvitation[],
  InviteTeamMembersForm,
  ThunkApiConfig
>('team/create-invitations', async (inviteTeamMembersFormParams, thunkAPI) => {
  const res = await Promise.all(
    inviteTeamMembersFormParams.invitations.map((invite) =>
      OrbiApi.call(v2.team.createInvitation, invite),
    ),
  );

  if (!res.every((r) => r.kind === 'ok')) {
    thunkAPI.dispatch(setAlert('create-invitations:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const pendingInvitations: PendingDepartmentInvitation[] = [];

  res.forEach((invitation, index) => {
    if (invitation.kind === 'ok') {
      pendingInvitations.push({
        departmentInvitationKey: invitation.data,
        email: inviteTeamMembersFormParams.invitations[index].email,
        expiresAt: Date.now() + 2 * Time.Week,
        role: inviteTeamMembersFormParams.invitations[index].role,
      });
    }
  });

  thunkAPI.dispatch(setAlert('create-invitations:success'));
  return pendingInvitations;
});

export const removeUserThunk = createAsyncThunk<
  void,
  {
    userKey: string;
    fullName: string;
  },
  ThunkApiConfig
>('team/remove-user', async (removeUserParams, thunkAPI) => {
  const res = await OrbiApi.call(v2.team.removeUser, removeUserParams.userKey);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert('remove-department-member:success', {
        fullName: removeUserParams.fullName,
      }),
    );
    return;
  }

  thunkAPI.dispatch(
    setAlert('remove-department-member:error', {
      fullName: removeUserParams.fullName,
    }),
  );
  return thunkAPI.rejectWithValue(res);
});

export const updateUserThunk = createAsyncThunk<
  void,
  UpdateUserParams,
  ThunkApiConfig
>('team/update-user', async (updateUserParams, thunkAPI) => {
  const res = await OrbiApi.call(v2.team.updateUser, updateUserParams);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(
      setAlert('update-team-member:success', {
        fullName: updateUserParams.fullName,
      }),
    );
    return;
  }

  thunkAPI.dispatch(
    setAlert('update-team-member:error', {
      fullName: updateUserParams.fullName,
    }),
  );
  return thunkAPI.rejectWithValue(res);
});
