export const ORBI_URL = 'https://orbiapp.io';

export const LEARN_MORE_ABOUT_WORKSPACES_URL =
  'https://orbiapp.io/knowledge/what-is-workspaces';

export const LEARN_MORE_ABOUT_TEAM_ROLES_URL =
  'https://orbiapp.io/knowledge/how-to-manage-your-team-roles';

export const OPEN_APP_URL = 'https://onelink.to/6at797';

export const LEARN_MORE_ABOUT_MEMBERSHIPS_URL =
  'https://orbiapp.io/knowledge/different-memberships-explained';

export const STRIPE_TERMS_URL =
  'https://stripe.com/en-gb-se/legal/connect-account';

export const CREATE_ACCOUNT_URL = 'https://orbiapp.io/sign-up';

export const DEPARTMENT_REFERRAL_BASE_URL =
  'https://orbiapp.io/referrals?orbi_referral_referred_by_department_key=';

export const DEPARTMENT_REFERRAL_KNOWLEDGE_URL =
  'https://orbiapp.io/knowledge/association#referrals';

export const TERMS_AND_CONDITIONS_URL =
  'https://orbiapp.io/knowledge/terms-and-conditions-associations';

export const EVENT_IMAGES_SAFE_ZONES_URL =
  'https://orbiapp.io/knowledge/event-images-safe-zones';
