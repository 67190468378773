import { CoHostRequestListItem } from '../../../../../../models';
import {
  CohostRequestDto,
  UpdateCoHostingRequestParams,
  UpdateCohostRequestDto,
} from './co-host-requests.dto';

export const coHostRequestMapper = (
  coHostRequestDto: CohostRequestDto,
): CoHostRequestListItem => ({
  cohostRequestKey: coHostRequestDto.cohostRequestKey,
  activityCoverImageUrl: coHostRequestDto.activityCoverImageUrl,
  activityEndDate: coHostRequestDto.activityEndDate,
  activityStartDate: coHostRequestDto.activityStartDate,
  activityContact: {
    email: coHostRequestDto.activityContact.email,
    name: coHostRequestDto.activityContact.name,
    phone: coHostRequestDto.activityContact.phone,
  },
  activityKey: coHostRequestDto.activityKey,
  activityTitle: coHostRequestDto.activityTitle,
  createdAt: coHostRequestDto.createdAt,
  hostingDepartmentName: coHostRequestDto.hostingDepartmentName,
  hostingDepartmentLogoUrl: coHostRequestDto.hostingDepartmentLogoUrl,
  hostingDepartmentOrgNodeName: coHostRequestDto.hostingDepartmentOrgNodeName,
  hostingDepartmentKey: coHostRequestDto.hostingDepartmentKey,
});

export const updateCoHostRequestMapper = ({
  cohostRequestKey,
  ...rest
}: UpdateCoHostingRequestParams): [string, UpdateCohostRequestDto] => [
  cohostRequestKey,
  rest,
];
