import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateOrbiPayAccount,
  CreateStripeAccountLink,
  OrbiPaySettings,
  Payout,
  PayoutListItem,
  PayoutsFiltering,
} from '../../models';
import { OrbiApi, v1 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';

export const getOrbiPaySettingsThunk = createAsyncThunk<
  OrbiPaySettings,
  undefined,
  ThunkApiConfig
>('orbi-pay/get-settings', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.orbiPay.orbiPaySettings.data) {
    return state.orbiPay.orbiPaySettings.data;
  }

  const res = await OrbiApi.call(v1.orbiPay.getOrbiPaySettings, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createOrbiPayAccountThunk = createAsyncThunk<
  string,
  CreateOrbiPayAccount,
  ThunkApiConfig
>('orbi-pay/create-account', async (createOrbiPayAccout, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.orbiPay.createOrbiPayAccount,
    createOrbiPayAccout,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  thunkAPI.dispatch(setAlert('join-orbi-pay'));
  return thunkAPI.rejectWithValue(res);
});

export const createStripeAccountLinkThunk = createAsyncThunk<
  string,
  CreateStripeAccountLink,
  ThunkApiConfig
>('orbi-pay/create-account-link', async (createStripeAccountLink, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.orbiPay.getStripeAccountLink,
    createStripeAccountLink,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getPayoutsThunk = createAsyncThunk<
  PayoutListItem[],
  PayoutsFiltering,
  ThunkApiConfig
>('orbi-pay/get-payouts', async (params, thunkAPI) => {
  const res = await OrbiApi.call(v1.orbiPay.getPayouts, params);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getPayoutThunk = createAsyncThunk<Payout, string, ThunkApiConfig>(
  'orbi-pay/get-payout',
  async (payoutId, thunkAPI) => {
    const res = await OrbiApi.call(v1.orbiPay.getPayout, payoutId);

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  },
);

export const getStripePublicKeyThunk = createAsyncThunk<
  string | null,
  undefined,
  ThunkApiConfig
>('orbi-pay/get-stripe-publishable-key', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.orbiPay.getPublicStripeKey, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
