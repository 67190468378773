import { createEntityAdapter } from '@reduxjs/toolkit';

import { PartialActorWithReaction } from '../../models';

export const reactionsAdapter = createEntityAdapter<
  PartialActorWithReaction,
  string
>({
  selectId: (reaction) => reaction.actorKey,
});
