import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
} from '@orbiapp/components';
import React from 'react';

import {
  CountriesSelector,
  LocationsSelector,
  OfferCategoriesSelector,
  getCountriesThunk,
  getLocationsThunk,
  getOfferCategoriesThunk,
  offersActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { OfferForm } from './create-offer-form';

const CREATE_OFFER_BREADCRUMBS: BreadcrumbListItem[] = [
  {
    to: '/offers',
    tx: 'label.breadcrumbs.offers.offers',
  },
  {
    to: '/offers/create-offer',
    tx: 'label.breadcrumbs.offers.create-offer',
  },
];

export function CreateOffer() {
  const dispatch = useDispatch();

  const offerCategoriesStatus = useSelector(
    OfferCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(LocationsSelector.selectStatus);
  const countriesStatus = useSelector(CountriesSelector.selectStatus);

  React.useEffect(() => {
    dispatch(getOfferCategoriesThunk());
    dispatch(getLocationsThunk());
    dispatch(getCountriesThunk());

    return () => {
      dispatch(offersActions.resetCreateOffer());
    };
  }, [dispatch]);

  if (isAnyPending(offerCategoriesStatus, locationsStatus, countriesStatus)) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={CREATE_OFFER_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <OfferForm />
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
