import { Box, Button, Text } from '@orbiapp/components';

import { Styled } from './unsaved-changes-box.styled';

export function UnsavedChangesBox(props: {
  onRevert: () => void;
  onSave: () => void;
  isLoading?: boolean;
}) {
  const { onRevert, onSave, isLoading } = props;

  return (
    <Styled.UnsavedChangesBoxStyled
      flex
      gap={24}
      p={16}
      sticky
      top={16}
      backgroundColor="unsavedChangesBoxBackground"
      r={8}
      zIndex={10}
      flexAlign="center"
      flexJustify="between"
    >
      <Text tx="label.general.unsaved-changes" />

      <Box flex gap={8}>
        <Button
          disabled={isLoading}
          onClick={onRevert}
          tx="button.revert"
          variant="secondary"
        />
        <Button
          isLoading={isLoading}
          onClick={onSave}
          tx="button.save"
          variant="primary"
        />
      </Box>
    </Styled.UnsavedChangesBoxStyled>
  );
}
