import Joi from 'joi';

import {
  AddDiscountCodeSchema,
  DiscountCodeSchema,
} from './discount-code.schema';

export const DiscountCodeItemValidation = Joi.object({
  code: DiscountCodeSchema.code,
  discount: DiscountCodeSchema.discount,
  discountCodeKey: DiscountCodeSchema.discountCodeKey,
  discountType: DiscountCodeSchema.discountType,
  isNew: DiscountCodeSchema.isNew,
});

export const AddDiscountCodeValidation = (options: { ticketPrice: number }) =>
  Joi.object({
    code: AddDiscountCodeSchema.code,
    discount: AddDiscountCodeSchema.discount(options),
    discountCodeKey: AddDiscountCodeSchema.discountCodeKey,
    discountType: AddDiscountCodeSchema.discountType,
    isNew: DiscountCodeSchema.isNew,
  });
