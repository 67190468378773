import styled from 'styled-components';

const ErrorSnackbarList = styled.ul``;

const ErrorSnackbarListItem = styled.li`
  list-style-type: disc;
  margin-left: 24px;
`;

export const Styled = { ErrorSnackbarList, ErrorSnackbarListItem };
