import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './jobs.build';
import { initialJobsState } from './jobs.initial';
import { reducers } from './jobs.reducers';

const jobsSlice = createSlice({
  name: 'jobs',
  reducers,
  initialState: initialJobsState,
  extraReducers,
});

export const jobsReducer = jobsSlice.reducer;
