import { useLocation } from 'react-router';

import { Box, BoxProps } from '../box';
import { BreadCrumb, BreadCrumbProps, BreadCrumbs } from '../breadcrumbs';
import { TabButton } from '../tab-button';
import { TxProps } from '../text';
import { Toolbar, ToolbarContentContainer } from '../toolbar';

export interface BreadcrumbListItem extends TxProps {
  to: string;
  truncate?: boolean;
}

export interface TabButtonListItem extends TxProps {
  disabled?: boolean;
  disabledClick?: boolean;
  isError?: boolean;
  to: string;
}

interface BreadcrumbsToolbarProps extends BoxProps {
  breadcrumbListItems: BreadcrumbListItem[];
  tabButtonListItems?: TabButtonListItem[];
}

export function BreadcrumbsToolbar(props: BreadcrumbsToolbarProps) {
  const { breadcrumbListItems, tabButtonListItems, ...rest } = props;

  const location = useLocation();

  const renderBreadcrumb = (
    breadcrumbListItem: BreadcrumbListItem,
    index: number,
  ) => {
    const truncateProps: BreadCrumbProps = breadcrumbListItem.truncate
      ? {
          maxWidth: '28ch',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }
      : {};

    return (
      <BreadCrumb
        text={breadcrumbListItem.text}
        to={breadcrumbListItem.to}
        tx={breadcrumbListItem.tx}
        txArgs={breadcrumbListItem.txArgs}
        isLast={index === breadcrumbListItems.length - 1}
        key={`breadcrumb-key-${index}`}
        {...truncateProps}
      />
    );
  };

  const renderTabButton = (
    tabButtonListItem: TabButtonListItem,
    index: number,
  ) => {
    return (
      <TabButton
        disabled={tabButtonListItem.disabled}
        isActive={location.pathname === tabButtonListItem.to}
        isError={tabButtonListItem.isError}
        key={`tab-button-key-${index}`}
        text={tabButtonListItem.text}
        to={!tabButtonListItem.disabledClick ? tabButtonListItem.to : undefined}
        tx={tabButtonListItem.tx}
        txArgs={tabButtonListItem.txArgs}
      />
    );
  };

  return (
    <Toolbar
      backgroundColor="tabHeaderBackground"
      bottomBorderColor="toolbarBottomBorder"
      zIndex={1}
    >
      <ToolbarContentContainer py={8} minHeight={56} flexAlign="center">
        <BreadCrumbs flexGrow={1} {...rest}>
          {breadcrumbListItems.map(renderBreadcrumb)}
        </BreadCrumbs>

        {!!tabButtonListItems?.length && (
          <Box flexGrow={1} gap={16} flex>
            {tabButtonListItems.map(renderTabButton)}
          </Box>
        )}
      </ToolbarContentContainer>
    </Toolbar>
  );
}
