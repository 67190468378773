import React from 'react';

import { useCombinedRefs } from '../../helpers';
import { translate } from '../../i18n';
import { Box } from '../box';
import { InputBox } from '../input-box';
import { InputElementBox } from '../input-element-box/input-element-box';
import { InputHelper } from '../input-helper';
import {
  SEARCH_INPUT_HEIGHT,
  SEARCH_INPUT_X_PADDING,
} from './search-input.constants';
import { Styled } from './search-input.styled';
import { SearchInputProps } from './search-input.types';

function useSearchInput(props: {
  maxLength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
}) {
  const { maxLength, onBlur, onChange, onFocus } = props;

  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus?.(e);
    setIsFocused(true);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur?.(e);
    setIsFocused(false);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const length = e.target.value.length;

    if (maxLength === undefined) {
      onChange?.(e);
      return;
    }

    if (length > maxLength) {
      return;
    }

    onChange?.(e);
  };

  return { isFocused, handleBlur, handleChange, handleFocus };
}

function _SearchInput(
  props: SearchInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    trailingElements,
    leadingElements,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    type,
    value,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const combinedRefs = useCombinedRefs(ref, inputRef);

  const hasError = !!error || !!errorTx;

  const { isFocused, handleBlur, handleChange, handleFocus } = useSearchInput({
    maxLength,
    onBlur,
    onChange,
    onFocus,
  });

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <Box>
      <InputBox
        onClick={focusInput}
        height={SEARCH_INPUT_HEIGHT}
        px={SEARCH_INPUT_X_PADDING}
        hasError={hasError}
        disabled={disabled}
        isFocused={isFocused}
      >
        <InputElementBox items={leadingElements} />

        <Styled.Input
          disabled={disabled}
          hasError={hasError}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={combinedRefs}
          type={type}
          value={value}
          placeholder={
            placeholderTx
              ? translate(placeholderTx, placeholderTxArgs ?? {})
              : placeholder
          }
          {...rest}
        />

        <InputElementBox items={trailingElements} />
      </InputBox>

      <InputHelper
        currentLength={value?.toString().length}
        disabled={disabled}
        hasError={hasError}
        helperText={error || helperText}
        helperTx={errorTx || helperTx}
        helperTxArgs={errorTxArgs || helperTxArgs}
        maxLength={maxLength}
      />
    </Box>
  );
}

export const SearchInput = React.forwardRef(_SearchInput);
