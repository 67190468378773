import { css } from 'styled-components';
import styled from 'styled-components';

import { Box } from '../box';
import { getTextStyles } from '../text';

interface StyledInputBoxProps {
  floatLabel: boolean;
  hasError?: boolean;
  disabled?: boolean;
  isFocused: boolean;
  focusTop?: number;
}

const InputBox = styled(Box)<StyledInputBoxProps>`
  ${(props) => {
    const { floatLabel, focusTop = 18, theme } = props;

    const getInputStyles = () => {
      if (props.disabled) {
        return css`
          background-color: ${theme.colors.inputBackgroundDisabled};
          border-color: ${theme.colors.inputBorderDisabled};
          color: ${theme.colors.inputTextDisabled};

          input {
            pointer-events: none;
            color: ${theme.colors.inputTextDisabled};
          }

          input::placeholder {
            opacity: 0.5;
          }
        `;
      }

      if (props.hasError) {
        return css`
          background-color: ${theme.colors.inputBackgroundError};
          border-color: ${theme.colors.inputBorderError};
          color: ${theme.colors.inputTextError};
          cursor: text;

          input {
            color: ${theme.colors.inputTextError};
          }
        `;
      }

      return css`
        background-color: ${theme.colors.inputBackground};
        border-color: ${theme.colors.inputBorder};
        color: ${theme.colors.inputText};
        cursor: text;

        input {
          color: ${theme.colors.inputText};
        }
      `;
    };

    return css`
      ${getInputStyles()};

      border-width: 1px;
      border-style: solid;

      label {
        ${getTextStyles('bodyMd', theme)}
        transition: ${theme.transitions.fast};
        color: ${theme.colors.inputLabel};
        transition-property: top, font-variation-settings, font-size;
        top: 50%;
        transform: translateY(-50%);
      }

      ${floatLabel &&
      css`
        label {
          ${getTextStyles('bodySm', theme)};
          top: ${focusTop}px;
          color: ${theme.colors.inputLabelFocus};
        }
      `}

      ${props.isFocused &&
      css`
        label {
          ${getTextStyles('bodySm', theme)}
          top: ${focusTop}px;
          color: ${theme.colors.inputLabelFocus} !important;
        }
      `}

      ${props.isFocused &&
      !props.hasError &&
      css`
        background-color: ${theme.colors.inputBackgroundFocus};
        border-color: ${theme.colors.inputBorderFocus};
        color: ${theme.colors.inputTextFocus};
      `}
    `;
  }}
`;

export const Styled = { InputBox };
