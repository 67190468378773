import { ImageData } from '@orbiapp/components';

import { MembershipPeriod } from '../period';

export interface MembershipInvitesForm {
  emails: string[];
  membershipPeriodKeys: string[] | null;
}

export const membershipInviteStatuses = [
  'pending_review',
  'rejected',
  'withdrawn',
  'needs_more_info',
  'awaiting_completion',
  'completed',
  'bounced',
] as const;

export type MembershipInviteStatus = (typeof membershipInviteStatuses)[number];

export interface MembershipInviteResult {
  email: string;
  fullName: string | null;
  membershipApplicationKey: string;
  userKey: string | null;
  profilePicture: ImageData | null;
  result:
    | 'invited'
    | 'modified'
    | 'already_member'
    | 'user_not_found'
    | 'already_invited'
    | 'has_pending_application';
}

export interface MembershipInvitesResult {
  invites: MembershipInviteResult[];
}

export interface MembershipInviteListItem {
  createdAt: number;
  email: string;
  fullName: string | null;
  membershipApplicationKey: string;
  periods: MembershipPeriod[] | null;
  status: MembershipInviteStatus;
  profilePicture: ImageData | null;
  userKey: string | null;
}

export type MembershipInviteOrderByKey = keyof Pick<
  MembershipInviteListItem,
  'fullName' | 'email' | 'createdAt' | 'status'
>;

export const membershipInvitesSortableKeys = {
  createdAt: 'createdAt',
  email: 'email',
  fullName: 'fullName',
  status: 'status',
};
