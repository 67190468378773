import { useDebounce } from '@orbiapp/components';
import React from 'react';

import {
  MembershipTypeType,
  PartialMembershipType,
  StatusType,
} from '../models';
import { searchMembershipTypesThunk, useDispatch } from '../store';

export function useMembershipSearch(types: MembershipTypeType[]) {
  const [searchResult, setSearchResult] = React.useState<
    PartialMembershipType[]
  >([]);
  const [status, setStatus] = React.useState<StatusType>('idle');
  const [searchString, setSearchString] = React.useState<string>('');

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const search = (searchString: string) => {
    setSearchString(searchString);

    if (!searchString.length) return;

    debounce(async () => {
      setStatus('pending');

      const res = await dispatch(
        searchMembershipTypesThunk({
          searchString,
          types,
        }),
      );

      if (Array.isArray(res.payload)) {
        setSearchResult(res.payload);
      }

      setStatus('completed');
    });
  };

  return { searchResult, status, search, searchString, setSearchString };
}
