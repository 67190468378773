import Joi from 'joi';

import { MembershipPeriodSchema } from './period.schema';

export const CreateMembershipPeriodValidation = Joi.object({
  key: MembershipPeriodSchema.key,
  lifetime: MembershipPeriodSchema.lifetime,
  name: MembershipPeriodSchema.name,
  price: MembershipPeriodSchema.price,
  saleEnd: MembershipPeriodSchema.saleEnd,
  saleStart: MembershipPeriodSchema.saleStart,
  validFrom: MembershipPeriodSchema.validFrom,
  validTo: MembershipPeriodSchema.validTo,
});
