export interface ImageUrl {
  url: string;
}

export interface ImageData {
  blurHash: string;
  original: ImageUrl;
  thumbnail64: ImageUrl;
  thumbnail256: ImageUrl;
  thumbnail512: ImageUrl;
  thumbnail1024: ImageUrl;
}

export interface ImageMeta {
  height: number;
  size: number;
  width: number;
}

export interface ImageCritera {
  maxHeight: number;
  maxSize: number;
  maxWidth: number;
  minHeight: number;
  minWidth: number;
}

const ONE_MEGABYTE_IN_BYTES = 1000000;

export const LOGO_IMAGE_MAX_SIZE_IN_BYTES = ONE_MEGABYTE_IN_BYTES * 2;
export const COVER_IMAGE_MAX_SIZE_IN_BYTES = ONE_MEGABYTE_IN_BYTES * 5;
export const POST_IMAGE_MAX_SIZE_IN_BYTES = ONE_MEGABYTE_IN_BYTES * 10;

export const COVER_IMAGE_CRITERIA: ImageCritera = {
  maxHeight: 6000,
  maxSize: COVER_IMAGE_MAX_SIZE_IN_BYTES,
  maxWidth: 6000,
  minHeight: 600,
  minWidth: 960,
};

export const LOGO_CRITERIA: ImageCritera = {
  maxHeight: 3000,
  maxSize: LOGO_IMAGE_MAX_SIZE_IN_BYTES,
  maxWidth: 3000,
  minHeight: 256,
  minWidth: 256,
};

export const POST_IMAGE_CRITERIA: ImageCritera = {
  maxSize: POST_IMAGE_MAX_SIZE_IN_BYTES,
  maxHeight: 4096,
  maxWidth: 4096,
  minHeight: 100,
  minWidth: 100,
};
