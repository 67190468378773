import { getUID } from '@orbiapp/components';

import { MembershipApplicationRequirement } from '../../../../../../models';
import { MembershipApplicationRequirementDto } from './suggested-membership-application-requirements.dto';

export const membershipApplicationRequirementsMapper = (
  membershipApplicationRequirementsDto: MembershipApplicationRequirementDto,
): MembershipApplicationRequirement => {
  return {
    departmentName: membershipApplicationRequirementsDto.departmentName,
    logoUrl: membershipApplicationRequirementsDto.logoUrl,
    membershipApplicationRequirementKey: getUID(),
    membershipTypeKey: membershipApplicationRequirementsDto.membershipTypeKey,
    name: membershipApplicationRequirementsDto.name,
  };
};
