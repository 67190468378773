import { Box, Icon, Text } from '@orbiapp/components';

import { Styled } from './setting-card.styled';
import { SettingCardProps } from './setting-card.types';

export function SettingCard(props: SettingCardProps) {
  const { icon, onClick, subtitleTx, subtitleTxArgs, titleTx, to } = props;

  const settingCard = (
    <Styled.SettingCard
      enableHover={!!onClick || !!to}
      flex
      flexAlign="center"
      flexJustify="between"
      gap={16}
      onClick={onClick}
      p={16}
      maxWidth="100%"
    >
      <Box gap={8} flex flexDirection="column">
        <Text color="settingCardTitle" variant="bodySm" tx={titleTx} />
        <Text
          variant="bodyMdBold"
          tx={subtitleTx}
          txArgs={subtitleTxArgs}
          color="settingCardSubtitle"
        />
      </Box>

      {icon && <Icon color="settingCardIcon" name={icon} />}
    </Styled.SettingCard>
  );

  if (to) {
    return (
      <Styled.CardLinkWrapper to={to}>{settingCard}</Styled.CardLinkWrapper>
    );
  }

  return settingCard;
}
