import Joi from 'joi';

import { AccountSchema } from './account.schema';

export const CreateAccountByInvitationValidation = Joi.object({
  firstName: AccountSchema.firstName,
  lastName: AccountSchema.lastName,
  password: AccountSchema.password,
  language: AccountSchema.language,
});

export const UpdateAccountValidation = Joi.object({
  firstName: AccountSchema.firstName,
  lastName: AccountSchema.lastName,
  language: AccountSchema.language,
});
