import {
  Box,
  Button,
  Text,
  UploadCsvResult,
  parseCSVCodes,
} from '@orbiapp/components';
import React from 'react';

import { GlobalUiStateAlert } from '../../models';
import { getAlert } from '../../utils';
import { Styled } from './upload-csv.styled';
import { UploadCsvError, UploadCsvInputProps } from './upload-csv.types';

function useDragDrop(
  ref: React.RefObject<HTMLDivElement>,
  onDrop: (files: File[]) => void,
) {
  const [dragOver, setDragOver] = React.useState(false);

  const added = React.useRef(false);

  React.useEffect(() => {
    const uploadContainer = ref.current;

    if (!uploadContainer || added.current) {
      return;
    }

    added.current = true;

    uploadContainer.addEventListener('dragover', (e) => {
      e.preventDefault();
      setDragOver(true);
    });

    uploadContainer.addEventListener('dragleave', (e) => {
      e.preventDefault();
      setDragOver(false);
    });

    uploadContainer.addEventListener('drop', (e) => {
      e.preventDefault();
      setDragOver(false);

      const files = e.dataTransfer?.files;

      if (files) {
        onDrop(Array.from(files));
      }
    });
  }, [ref, setDragOver, onDrop]);

  return dragOver;
}

function UploadCsvInput(props: UploadCsvInputProps) {
  const {
    buttonText,
    buttonTx,
    buttonTxArgs,
    children,
    disabled,
    onUpload,
    onError,
  } = props;

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const uploadContainerRef = React.useRef<HTMLDivElement>(null);

  const handleUpload = async (files: File[]) => {
    if (disabled) return;

    const file = files[0];

    if (file.type !== 'text/csv') {
      onError?.({ message: 'fileTypeNotAllowed' });
      return;
    }

    const uploadResult = await parseCSVCodes(file);

    onUpload(uploadResult);
  };

  const dragOver = useDragDrop(uploadContainerRef, handleUpload);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(Array.from(e.target.files));
    }

    e.target.value = '';
  };

  const openFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <Styled.Upload
      aria-disabled={disabled}
      disabled={disabled}
      flex
      flexAlign="center"
      flexDirection="column"
      flexJustify="center"
      gap={16}
      onClick={openFileInput}
      p={16}
      r={8}
      height={126}
      ref={uploadContainerRef}
      relative
      width="100%"
    >
      <input
        hidden
        onChange={handleFileSelect}
        ref={fileInputRef}
        type="file"
        accept=".csv"
      />

      {dragOver ? (
        <Styled.UploadIcon color="uploadIcon" name="upload" />
      ) : (
        <Box gap={16} flex flexDirection="column">
          <span></span>

          <Button
            text={buttonText}
            tx={buttonTx}
            txArgs={buttonTxArgs}
            variant="secondary"
            mx="auto"
            disabled={disabled}
          />

          <Box flex flexAlign="center" flexDirection="column" gap={4}>
            {children}
          </Box>
        </Box>
      )}
    </Styled.Upload>
  );
}

export function UploadCsv({
  onUpload,
}: {
  onUpload: (uploadCsvResult: UploadCsvResult) => void;
}) {
  const [uploadError, setUploadError] =
    React.useState<GlobalUiStateAlert | null>(null);

  const handleUpload = (uploadResult: UploadCsvResult) => {
    setUploadError(null);

    onUpload(uploadResult);
  };

  const handleUploadError = (err: UploadCsvError) => {
    let error: GlobalUiStateAlert | null = null;

    switch (err.message) {
      case 'fileTypeNotAllowed': {
        error = {
          alertType: 'file-type-not-allowed',
        };

        break;
      }
    }

    setUploadError(error);
  };

  return (
    <Box maxWidth="100%" flex flexAlign="start" flexDirection="column" gap={8}>
      <UploadCsvInput
        buttonTx="button.upload-file"
        onError={handleUploadError}
        onUpload={handleUpload}
      >
        <Text
          tx="label.offer-form.discount-codes.upload-csv-description"
          variant="bodySm"
          maxWidth={200}
          textAlign="center"
        />
      </UploadCsvInput>

      {uploadError && (
        <Text
          color="errorLabel"
          tx={getAlert(uploadError.alertType).subtitleTx}
          txArgs={uploadError.args}
          variant="bodyMd"
        />
      )}
    </Box>
  );
}
