import { CohostRequestStatus } from '../models';

export function getCoHostingStatusTx(status: CohostRequestStatus): TxString {
  switch (status) {
    case 'not-sent':
      return 'label.co-hosting-status.not-sent';
    case 'accepted':
      return 'label.co-hosting-status.success';
    case 'rejected':
      return 'label.co-hosting-status.error';
    case 'canceled':
      return 'label.co-hosting-status.warning';
    case 'pending':
      return 'label.co-hosting-status.info';
  }
}
