import styled, { css } from 'styled-components';

import { Box } from '../box';
import { StyledCalloutProps } from './callout.types';

const Callout = styled(Box)<StyledCalloutProps>`
  ${(props) => {
    const { theme, borderColor } = props;

    return css`
      border: 1px solid ${theme.colors[borderColor]};
    `;
  }}
`;

export const Styled = { Callout };
