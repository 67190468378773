import { getAuth, signOut } from '@firebase/auth';
import { FirebaseError } from '@firebase/util';
import {
  Account,
  EmulateUserSessionStorage,
  SignInForm,
  WorkspacesSessionStorage,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestResetPasswordEmailErrorResponse } from '../../models';
import {
  OrbiApi,
  firebase,
  isClientProblem,
  signIn,
  signInWithCustomToken,
  v1,
  v2,
} from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import { handleFirebaseError } from './auth.utils';

export const getAuthAccountThunk = createAsyncThunk<
  Account | null,
  undefined,
  ThunkApiConfig
>('auth/get-auth-account', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (!state.auth.authState.data.isEmulating) {
    return null;
  }

  const res = await OrbiApi.call(v2.account.getAuthAccount, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const signInThunk = createAsyncThunk<void, SignInForm, ThunkApiConfig>(
  'auth/sign-in',
  async (authForm, thunkAPI) => {
    const res = await signIn(authForm.email, authForm.password);

    if (res instanceof FirebaseError) {
      const { alertType, rejectValue } = handleFirebaseError(res);

      thunkAPI.dispatch(setAlert(alertType));
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
);

export const signInWithCustomTokenThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('auth/sign-in-with-custom-token', async (customToken, thunkAPI) => {
  const res = await signInWithCustomToken(customToken);

  if (res instanceof FirebaseError) {
    const { alertType, rejectValue } = handleFirebaseError(res);

    thunkAPI.dispatch(setAlert(alertType));
    return thunkAPI.rejectWithValue(rejectValue);
  }
});

export const resetPasswordThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig<RequestResetPasswordEmailErrorResponse>
>('auth/reset-password', async (email, thunkAPI) => {
  const state = thunkAPI.getState();

  let redirectUrl = `${window.location.origin}/sign-in?email=${email}`;

  if (state.team.departmentInvitation.data) {
    const { departmentInvitationKey } = state.team.departmentInvitation.data;
    redirectUrl = `${window.location.origin}/sign-up/${departmentInvitationKey}`;
  }

  const res = await OrbiApi.call(v1.auth.requestResetPasswordMail, {
    email,
    redirectUrl,
  });

  if (res.kind === 'too-many-requests') {
    thunkAPI.dispatch(setAlert('reset-password:too-many-requests'));
    return thunkAPI.rejectWithValue(res);
  }

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('reset-password:success', { email }));
    return;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('reset-password:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('reset-password:error'));
    return thunkAPI.rejectWithValue(res);
  }

  switch (res.message.code) {
    case 0:
      thunkAPI.dispatch(setAlert('reset-password:email-badly-formatted'));
      break;

    case 1:
      thunkAPI.dispatch(setAlert('reset-password:email-not-found'));
      break;

    default:
      thunkAPI.dispatch(setAlert('reset-password:error'));
      break;
  }

  return thunkAPI.rejectWithValue(res);
});

export const sendEmailVerificationThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('auth/send-email-verification', async (email, thunkAPI) => {
  const res = await OrbiApi.call(v1.auth.sendEmailVerification, {
    email,
    provider: 'password',
    redirectUrl: window.location.origin,
    requireStudentEmailDomain: false,
  });

  if (res.kind === 'too-many-requests') {
    thunkAPI.dispatch(setAlert('send-email-verification:too-many-requests'));
    return thunkAPI.rejectWithValue(res);
  }

  if (res.kind !== 'ok') {
    thunkAPI.dispatch(setAlert('send-email-verification:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(setAlert('send-email-verification:success', { email }));
});

export const signOutThunk = createAsyncThunk('auth/sign-out', () => {
  EmulateUserSessionStorage.endSession();
  WorkspacesSessionStorage.endSession();

  signOut(getAuth(firebase));
});

export const getCustomSignInTokenThunk = createAsyncThunk<
  string,
  undefined,
  ThunkApiConfig
>('auth/get-custom-sign-in-token', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.auth.getCustomSignInToken, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
