import './orbi-loader.css';
import orbiLogo from './orbi-logo-orange.png';
import spinner from './spinner.svg';

export function OrbiLoader(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="orbi-loader" {...props}>
      <img className="logo" src={orbiLogo} alt="" />
      <img className="spinner" src={spinner} alt="" />
    </div>
  );
}
