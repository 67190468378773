import { Box } from '../../components';

export function SidebarContainer(props: React.PropsWithChildren) {
  const { children } = props;

  return (
    <Box overflow="hidden" flexGrow={1} flex>
      {children}
    </Box>
  );
}
