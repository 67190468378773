import { SegmentationType } from '../../../../../../models';

export const getSegmentatonTypeTx = (segmentationType: SegmentationType) => {
  switch (segmentationType) {
    case 'semester':
      return 'units.semester';
    case 'year':
      return 'units.year';
  }
};
