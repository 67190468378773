import {
  BreadcrumbsToolbar,
  InnerPageContainer,
  PageContainer,
} from '@orbiapp/components';
import { Outlet } from 'react-router';

export function Settings() {
  return (
    <PageContainer>
      <BreadcrumbsToolbar
        flexGrow={0.8}
        breadcrumbListItems={[
          {
            tx: 'label.breadcrumbs.settings.settings',
            to: '/settings',
          },
        ]}
        tabButtonListItems={[
          {
            tx: 'label.settings.profile',
            to: '/settings/account-settings',
          },
          {
            tx: 'label.settings.department',
            to: '/settings/department-settings',
          },
          {
            tx: 'label.settings.team',
            to: '/settings/team-settings',
          },
        ]}
      />

      <InnerPageContainer>
        <Outlet />
      </InnerPageContainer>
    </PageContainer>
  );
}
