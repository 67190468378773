import { PostTargetType } from 'dtos/base/post-target-type';

import { GetPageParams, SortOrder } from '../page';

export const postTargetTypes = [
  'activity',
  'department',
  'membershipType',
] as const satisfies PostTargetType[];

export type PostOrderByKey =
  | 'targetType'
  | 'createdAt'
  | 'authorName'
  | 'message'
  | 'commentCount'
  | 'reactionCount';

export interface GetPostsPageParams extends GetPageParams {
  activityKeys?: string[];
  targetType?: PostTargetType | 'all';

  orderBy: PostOrderByKey;
  sortOrder: SortOrder;

  q?: string;
}

export interface CreatePostFormFileUpload {
  base64: string;
  fileUploadKey: string | null;

  id: string;

  status: 'pending' | 'success' | 'error';
}

export interface CreatePostForm {
  target: {
    type: PostTargetType | undefined;
    key: string;
  };
  message: string;
  fileUploads: CreatePostFormFileUpload[];
  pushTo: 'followers' | 'attendees' | 'active_members' | null;
}

export interface EditPostForm {
  postKey: string;
  message: string;
}

export const postSortableKeys: Record<PostOrderByKey, PostOrderByKey> = {
  createdAt: 'createdAt',
  targetType: 'targetType',
  authorName: 'authorName',
  message: 'message',
  commentCount: 'commentCount',
  reactionCount: 'reactionCount',
};
