import React from 'react';
import ReactDOM from 'react-dom';

export function Portal(
  props: React.PropsWithChildren<{
    id?: string;
  }>,
) {
  const { children, id = 'modal-root' } = props;

  const [modalContainer] = React.useState(document.createElement('div'));

  React.useEffect(() => {
    let modalRoot = document.getElementById(id);

    if (!modalRoot) {
      const tempEl = document.createElement('div');
      tempEl.id = id;
      document.body.append(tempEl);
      modalRoot = tempEl;
    }

    modalRoot.appendChild(modalContainer);

    return () => {
      modalRoot?.removeChild(modalContainer);
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return ReactDOM.createPortal(children, modalContainer);
}
