import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './countries.build';
import { initialCountriesState } from './countries.initial';
import { reducers } from './countries.reducers';

const countriesSlice = createSlice({
  name: 'countries',
  initialState: initialCountriesState,
  reducers,
  extraReducers,
});

export const countriesReducer = countriesSlice.reducer;
