import { ResetPasswordForm, SignInForm } from '@orbiapp/components';
import Joi from 'joi';

import { AuthSchema } from './auth.schema';

export const SignInValidation = Joi.object<SignInForm>({
  email: AuthSchema.email,
  password: AuthSchema.password,
});

export const ResetPasswordValidation = Joi.object<ResetPasswordForm>({
  email: AuthSchema.email,
});
