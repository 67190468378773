import { SwitchableWorkspace, Workspace } from '../models';

export function getWorkspacesOfType<
  T extends Workspace,
  K extends Workspace['type'],
>(workspaces: T[], ...type: K[]): Extract<T, { type: K }>[] {
  return workspaces.filter((workspace): workspace is Extract<T, { type: K }> =>
    type.includes(workspace.type as any),
  );
}

export function getLastUsedWorkspace<T extends SwitchableWorkspace['type']>(
  workspaces: SwitchableWorkspace[],
  type: T,
) {
  if (!workspaces.length) {
    return null;
  }

  const workspacesOfType = getWorkspacesOfType(workspaces, type);

  if (!workspacesOfType.length) {
    return null;
  }

  const sortedWorkspaces = workspacesOfType.sort(
    (a, b) => b.lastUsedAt - a.lastUsedAt,
  );

  const lastUsedWorkspace = sortedWorkspaces[0];

  if (!lastUsedWorkspace) {
    return null;
  }

  return lastUsedWorkspace;
}

export function getOnlyHasCompanyWorkspace(workspaces: Workspace[]) {
  if (!workspaces.length) {
    return false;
  }

  return workspaces.every((workspace) => workspace.type === 'company');
}

export function getOnlyHasStudentWorkspace(workspaces: Workspace[]) {
  if (!workspaces.length) {
    return false;
  }

  return workspaces.every((workspace) => workspace.type === 'student');
}

export function getOnlyHasDepartmenWorkspaces(
  workspaces: SwitchableWorkspace[],
) {
  if (!workspaces.length) {
    return false;
  }

  return workspaces.every((workspace) => workspace.type === 'department');
}
