import React from 'react';

import {
  Box,
  Icon,
  IconButton,
  Spinner,
  TEXTAREA_X_PADDING,
  TEXTAREA_Y_PADDING,
} from '..';
import { INPUT_X_PADDING } from '../../constants';
import { Styled } from './input-element-box.styled';
import { InputElement, InputElementBoxProps } from './input-element-box.types';

function renderInputElement(element: InputElement, index: number) {
  if (element.hidden) {
    return null;
  }

  switch (element.type) {
    case 'button':
      return <IconButton key={index} {...element} />;

    case 'icon':
      return <Icon key={index} {...element} />;

    case 'spinner':
      return <Spinner key={index} {...element} />;
  }
}

export function InputElementBox(props: InputElementBoxProps) {
  const { items, textarea } = props;

  if (!items?.length) {
    return null;
  }

  if (textarea) {
    return (
      <Box
        flex
        gap={16}
        absolute
        right={TEXTAREA_X_PADDING}
        bottom={TEXTAREA_Y_PADDING}
      >
        {items.map(renderInputElement)}
      </Box>
    );
  }

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <Styled.InputElementBox
      flex
      flexAlign="center"
      gap={16}
      right={INPUT_X_PADDING}
      zIndex={1}
      onClick={handleClick}
    >
      {items.map(renderInputElement)}
    </Styled.InputElementBox>
  );
}
