import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  CheckIntegrationErrorResponse,
  CreateIntegratedMembershipTypeForm,
  IntegratedMembershipMetadata,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { createIntegratedMembershipTypeMapper } from './integrated.mappers';

export const checkIntegration = async (
  membershipCredentials: IntegratedMembershipMetadata,
): Promise<GeneralApiResult<CheckIntegrationErrorResponse>> => {
  const res = await apisauce.post<void, CheckIntegrationErrorResponse>(
    '/v2/membership-types/check-integration',
    membershipCredentials,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createIntegratedMembershipType = async (
  params: CreateIntegratedMembershipTypeForm,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ membershipTypeKey: string }, any>(
    '/v2/membership-types/integrated',
    createIntegratedMembershipTypeMapper(params),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.membershipTypeKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createIntegratedMembershipType', {
      res: JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
