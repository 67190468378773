import {
  Avatar,
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  EmptyState,
  IconButton,
  Image,
  InnerContentContainer,
  InnerPageContainer,
  List,
  ListItem,
  PageContainer,
  PageHeader,
  ResponsiveBox,
  SolidIconButton,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  formatDate,
  getAvatarVariantFromString,
  paginatorOptions,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import { useDataGridPagination } from '../../../../helpers';
import {
  ActivityDraft,
  ActivityListItem,
  ActivityOrderByKey,
  CoHostRequestListItem,
  activitySortableKeys,
} from '../../../../models';
import { Analytics } from '../../../../services';
import {
  ActivitiesSelector,
  ActivityDraftsSelector,
  CoHostingRequestsSelector,
  DepartmentSelector,
  UpdateCoHostingRequestSelector,
  activityActions,
  deleteActivityDraftThunk,
  getActivitiesThunk,
  getActivityDraftsThunk,
  updateCohostingRequestThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getActivityStatusOptions, isAnyPending } from '../../../../utils';
import { Styled } from './activities.styled';
import { CoHostRequestContextType } from './activities.types';

function trackCreateEventFromDashboard() {
  Analytics.track('click_create_event__dashboard');
}

const TABLE_COLUMN_WIDTHS = {
  title: 200,
  startDate: 100,
  endDate: 100,
  status: 200,
  tickets: 100,
  coHosted: 100,
  actions: 100,
};

const INITIAL_COHOST_REQUEST_CONTEXT_STATE: CoHostRequestContextType = {
  coHostRequest: null,
  acceptCoHostRequestConfirmState: {
    closeConfirm: () => {},
    confirmValue: null,
    isOpen: false,
    openConfirm: () => {},
  },
  rejectCoHostRequestConfirmState: {
    closeConfirm: () => {},
    confirmValue: null,
    isOpen: false,
    openConfirm: () => {},
  },

  setCoHostRequest: () => {},
};

const DeleteActivityDraftConfirmContext = React.createContext<
  ReturnType<typeof useConfirm<string>>
>({
  closeConfirm: () => {},
  confirmValue: null,
  isOpen: false,
  openConfirm: () => {},
});

function DeleteActivityDraftProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirmState = useConfirm<string>();

  return (
    <DeleteActivityDraftConfirmContext.Provider value={confirmState}>
      {children}
    </DeleteActivityDraftConfirmContext.Provider>
  );
}

const CoHostRequestContext = React.createContext<CoHostRequestContextType>(
  INITIAL_COHOST_REQUEST_CONTEXT_STATE,
);

function CoHostRequestProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [coHostRequest, setCoHostRequest] =
    React.useState<CoHostRequestListItem | null>(
      INITIAL_COHOST_REQUEST_CONTEXT_STATE.coHostRequest,
    );

  const acceptCoHostRequestConfirmState = useConfirm();
  const rejectCoHostRequestConfirmState = useConfirm();

  return (
    <CoHostRequestContext.Provider
      value={{
        acceptCoHostRequestConfirmState,
        coHostRequest,
        rejectCoHostRequestConfirmState,
        setCoHostRequest,
      }}
    >
      {children}
    </CoHostRequestContext.Provider>
  );
}

function CoHostingRequestRow(props: CoHostRequestListItem) {
  const {
    activityTitle,
    activityStartDate,
    activityEndDate,
    cohostRequestKey,
  } = props;

  const coHostRequestContext = React.useContext(CoHostRequestContext);

  const updateCoHostingRequestStatus = useSelector(
    UpdateCoHostingRequestSelector.selectStatus,
  );

  const isLoading = updateCoHostingRequestStatus === 'pending';

  const rejectCohostingRequest: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    coHostRequestContext.rejectCoHostRequestConfirmState.openConfirm(
      cohostRequestKey,
    );
  };

  const acceptCohostingRequest: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    coHostRequestContext.acceptCoHostRequestConfirmState.openConfirm(
      cohostRequestKey,
    );
  };

  const selectCoHostRequest = () => {
    coHostRequestContext.setCoHostRequest(props);
  };

  return (
    <TableRow
      highlight={
        coHostRequestContext.coHostRequest?.cohostRequestKey ===
        cohostRequestKey
      }
      onClick={selectCoHostRequest}
    >
      <TableCell width={TABLE_COLUMN_WIDTHS.title} text={activityTitle} />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.startDate}
        text={formatDate(activityStartDate, 'DD MMM YYYY HH:mm')}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.endDate}
        text={formatDate(activityEndDate, 'DD MMM YYYY HH:mm')}
      />
      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status tx="label.activities.table.invitation" variant="warning" />
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.tickets} text="" />
      <TableCell width={TABLE_COLUMN_WIDTHS.coHosted}>
        <Chip variant={1} tx="label.activities.table.co-hosted" />
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
        <Box flexJustify="end" width="100%" flex gap={16}>
          <Tooltip
            placement="left"
            titleTx="label.activities.co-host-request.accept"
          >
            <IconButton
              disabled={isLoading}
              icon="check-circle-outline"
              onClick={acceptCohostingRequest}
            />
          </Tooltip>
          <Tooltip
            placement="left"
            titleTx="label.activities.co-host-request.reject"
          >
            <IconButton
              disabled={isLoading}
              icon="x-circle-outline"
              onClick={rejectCohostingRequest}
            />
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderCoHostRequestTableRow(request: CoHostRequestListItem) {
  return <CoHostingRequestRow key={request.cohostRequestKey} {...request} />;
}

function ActivityTableRow(props: ActivityListItem) {
  const { activityKey, title, startDate, endDate, stats, totalTicketCount } =
    props;

  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const isCoHosted = !!departmentKey && departmentKey !== props.departmentKey;

  return (
    <TableRow to={`/activities/${activityKey}/description`}>
      <TableCell width={TABLE_COLUMN_WIDTHS.title} text={title} />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.startDate}
        text={startDate === 0 ? '' : formatDate(startDate, 'DD MMM YYYY HH:mm')}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.endDate}
        text={endDate === 0 ? '' : formatDate(endDate, 'DD MMM YYYY HH:mm')}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status {...getActivityStatusOptions({ startDate, endDate })} />
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.tickets}>
        {totalTicketCount ? (
          <Chip
            variant={2}
            text={`${stats.numberOfSoldTickets}/${totalTicketCount}`}
          />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.coHosted}>
        {isCoHosted ? (
          <Chip variant={1} tx="label.activities.table.co-hosted" />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
    </TableRow>
  );
}

function renderActivityTableRow(activity: ActivityListItem) {
  return <ActivityTableRow key={activity.activityKey} {...activity} />;
}

function ActivitiesEmptyState() {
  return (
    <EmptyState
      buttonOnClick={trackCreateEventFromDashboard}
      to="/activities/create-activity"
      buttonTx="label.activities.empty-state.button"
      titleTx="label.activities.empty-state.title"
    />
  );
}

function PendingInvitationsSidebarContent() {
  const coHostRequestContext = React.useContext(CoHostRequestContext);

  if (!coHostRequestContext.coHostRequest) {
    return null;
  }

  const rejectCohostingRequest: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    coHostRequestContext.rejectCoHostRequestConfirmState.openConfirm(
      coHostRequestContext.coHostRequest?.cohostRequestKey,
    );
  };

  const acceptCohostingRequest: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    coHostRequestContext.acceptCoHostRequestConfirmState.openConfirm(
      coHostRequestContext.coHostRequest?.cohostRequestKey,
    );
  };

  return (
    <React.Fragment>
      <Text
        variant="bodyLgBold"
        tx="label.activities.co-host-request.pending-request"
      />

      <Box relative>
        <Avatar
          top={16}
          left={16}
          absolute
          src={
            coHostRequestContext.coHostRequest.hostingDepartmentLogoUrl ??
            undefined
          }
          fallbackLetter={
            coHostRequestContext.coHostRequest.hostingDepartmentName[0]
          }
          variant={getAvatarVariantFromString(
            coHostRequestContext.coHostRequest.hostingDepartmentKey,
          )}
        />

        <Image
          width="100%"
          r={8}
          src={coHostRequestContext.coHostRequest.activityCoverImageUrl}
        />
      </Box>

      <List>
        <ListItem
          titleTx="label.activities.co-host-request-sidebar.department"
          subtitle={coHostRequestContext.coHostRequest.hostingDepartmentName}
        />

        <ListItem
          titleTx="label.activities.co-host-request-sidebar.contact-name"
          subtitle={coHostRequestContext.coHostRequest.activityContact.name}
        />

        <ListItem
          titleTx="label.activities.co-host-request-sidebar.contact-email"
          subtitle={coHostRequestContext.coHostRequest.activityContact.email}
        />

        {coHostRequestContext.coHostRequest.activityContact.phone && (
          <ListItem
            titleTx="label.activities.co-host-request-sidebar.contact-phone"
            subtitle={coHostRequestContext.coHostRequest.activityContact.phone}
          />
        )}
      </List>

      <Styled.SelectOptionBox
        flex
        flexAlign="center"
        flexJustify="between"
        width="100%"
        px={24}
        py={16}
        r={8}
      >
        <Text tx="label.general.select-an-option" variant="bodyMdBold" />

        <Box gap={16} flex>
          <Tooltip
            placement="left"
            titleTx="label.activities.co-host-request.accept"
          >
            <IconButton
              icon="check-circle-outline"
              onClick={acceptCohostingRequest}
            />
          </Tooltip>

          <Tooltip
            placement="left"
            titleTx="label.activities.co-host-request.reject"
          >
            <IconButton
              icon="x-circle-outline"
              onClick={rejectCohostingRequest}
            />
          </Tooltip>
        </Box>
      </Styled.SelectOptionBox>
    </React.Fragment>
  );
}

function PendingInvitationSidebar() {
  const coHostRequestContext = React.useContext(CoHostRequestContext);

  const closeSidebar = () => {
    coHostRequestContext.setCoHostRequest(null);
  };

  return (
    <ContentSidebar
      isOpen={!!coHostRequestContext.coHostRequest}
      onClose={closeSidebar}
      width={400}
    >
      <ContentSidebarContentContainer>
        <PendingInvitationsSidebarContent />
      </ContentSidebarContentContainer>
    </ContentSidebar>
  );
}

function ActivityDraftTableRow(
  props: ActivityDraft & { activityDraftKey: string },
) {
  const { activityDraftKey, data } = props;

  const { openConfirm } = React.useContext(DeleteActivityDraftConfirmContext);

  const totalTicketCount = data.tickets.ticketTypes?.reduce(
    (acc, ticket) => acc + ticket.ticketCount,
    0,
  );

  const isCoHosted = !!data.coHosting.cohosts?.length;

  const openConfirmDeleteDraft = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    openConfirm(activityDraftKey);
  };

  return (
    <React.Fragment>
      <TableRow to={`/activities/draft/${activityDraftKey}/description`}>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.title}
          text={data.description.title}
        />

        <TableCell
          width={TABLE_COLUMN_WIDTHS.startDate}
          text={formatDate(data.description.startDate, 'DD MMM YYYY HH:mm')}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.endDate}
          text={formatDate(data.description.endDate, 'DD MMM YYYY HH:mm')}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status variant="warning" tx="label.activity-status.draft" />
        </TableCell>
        <TableCell width={TABLE_COLUMN_WIDTHS.tickets}>
          {totalTicketCount ? (
            <Chip variant={2} text={`0/${totalTicketCount}`} />
          ) : (
            ''
          )}
        </TableCell>
        <TableCell width={TABLE_COLUMN_WIDTHS.coHosted}>
          {isCoHosted ? (
            <Chip variant={1} tx="label.activities.table.co-hosted" />
          ) : (
            ''
          )}
        </TableCell>
        <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
          <Box width="100%" flex flexJustify="end">
            <Tooltip titleTx="label.activities.delete-draft" placement="left">
              <IconButton
                icon="trash-outline"
                onClick={openConfirmDeleteDraft}
              />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function DeleteActivityDraftConfirm() {
  const { confirmValue, closeConfirm, isOpen } = React.useContext(
    DeleteActivityDraftConfirmContext,
  );

  const dispatch = useDispatch();

  const deleteActivityDraft = async () => {
    if (!confirmValue) return;

    await dispatch(deleteActivityDraftThunk({ draftId: confirmValue }));

    closeConfirm();
  };

  return (
    <Confirm
      cancelTx="prompt.delete-activity-draft.cancel"
      confirmTx="prompt.delete-activity-draft.confirm"
      isOpen={isOpen}
      messageTx="prompt.delete-activity-draft.message"
      onCancel={closeConfirm}
      onConfirm={deleteActivityDraft}
      titleTx="prompt.delete-activity-draft.title"
    />
  );
}

function renderActivityDraftTableRow(activityDraft: ActivityDraft) {
  return (
    <ActivityDraftTableRow
      activityDraftKey={activityDraft.key}
      {...activityDraft}
    />
  );
}

export function ActivitiesTable() {
  const activities = useSelector(ActivitiesSelector.selectAll);
  const activitiesPagination = useSelector(ActivitiesSelector.selectPagination);
  const activitiesStatus = useSelector(ActivitiesSelector.selectStatus);
  const pendingCoHostingRequests = useSelector(
    CoHostingRequestsSelector.selectAll,
  );
  const coHostingRequestsStatus = useSelector(
    CoHostingRequestsSelector.selectStatus,
  );
  const activityDraftsStatus = useSelector(ActivityDraftsSelector.selectStatus);
  const activityDraftsData = useSelector(ActivityDraftsSelector.selectAll);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<ActivityListItem, ActivityOrderByKey>({
      data: activities,
      pagination: activitiesPagination,
      reset: activityActions.clearActivities,
      thunk: getActivitiesThunk,
    });

  const isLoading = isAnyPending(
    activitiesStatus,
    coHostingRequestsStatus,
    activityDraftsStatus,
  );

  if (isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.activities.table.title" />
            <TableHead tx="label.activities.table.startDate" />
            <TableHead tx="label.activities.table.endDate" />
            <TableHead tx="label.activities.table.status" />
            <TableHead tx="label.activities.table.tickets" />
            <TableHead tx="label.activities.table.co-hosted" />
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody data-testid="activities-loading-state-table-body">
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (
    rows.length === 0 &&
    pendingCoHostingRequests.length === 0 &&
    activityDraftsData.length === 0
  ) {
    return <ActivitiesEmptyState />;
  }

  return (
    <React.Fragment>
      <DeleteActivityDraftConfirm />

      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={activitiesPagination.orderBy}
          sortableColumns={Object.values(activitySortableKeys)}
          sortOrder={activitiesPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.activities.table.title"
              name={activitySortableKeys.title}
            />
            <TableHead
              tx="label.activities.table.startDate"
              name={activitySortableKeys.startDate}
            />
            <TableHead
              tx="label.activities.table.endDate"
              name={activitySortableKeys.endDate}
            />
            <TableHead tx="label.activities.table.status" />
            <TableHead tx="label.activities.table.tickets" />
            <TableHead tx="label.activities.table.co-hosted" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody data-testid="activities-table">
          {pendingCoHostingRequests.map(renderCoHostRequestTableRow)}
          {activityDraftsData.map(renderActivityDraftTableRow)}
          {rows.map(renderActivityTableRow)}
        </TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function ActivitiesToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.activities.activities" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

function CreateActivityButton() {
  const activitiesCount = useSelector(ActivitiesSelector.selectCount);
  const draftsCount = useSelector(ActivityDraftsSelector.selectCount);

  if (activitiesCount === 0 && draftsCount === 0) {
    return null;
  }

  return (
    <ResponsiveBox
      xs={
        <Button
          onClick={trackCreateEventFromDashboard}
          to="/activities/create-activity/description"
          tx="button.activity.new-event"
          variant="primary"
        />
      }
    >
      <Tooltip placement="left" titleTx="button.activity.new-event">
        <SolidIconButton
          to="/activities/create-activity/description"
          icon="plus-circle-outline"
          onClick={trackCreateEventFromDashboard}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function RejectCoHostInvitationConfirm() {
  const dispatch = useDispatch();

  const coHostRequestContext = React.useContext(CoHostRequestContext);

  const updateCoHostingRequestStatus = useSelector(
    UpdateCoHostingRequestSelector.selectStatus,
  );

  const rejectCohostingRequest = async () => {
    const res = await dispatch(
      updateCohostingRequestThunk({
        cohostRequestKey:
          coHostRequestContext.rejectCoHostRequestConfirmState.confirmValue!,
        status: 'rejected',
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      if (
        coHostRequestContext.coHostRequest?.cohostRequestKey ===
        coHostRequestContext.rejectCoHostRequestConfirmState.confirmValue
      ) {
        coHostRequestContext.setCoHostRequest(null);
      }

      coHostRequestContext.rejectCoHostRequestConfirmState.closeConfirm();
    }
  };

  return (
    <Confirm
      cancelTx="label.activities.reject-co-host-request.cancel"
      confirmTx="label.activities.reject-co-host-request.confirm"
      isOpen={coHostRequestContext.rejectCoHostRequestConfirmState.isOpen}
      messageTx="label.activities.reject-co-host-request.message"
      isLoading={updateCoHostingRequestStatus === 'pending'}
      onCancel={
        coHostRequestContext.rejectCoHostRequestConfirmState.closeConfirm
      }
      onConfirm={rejectCohostingRequest}
      titleTx="label.activities.reject-co-host-request.title"
    />
  );
}

function AcceptCoHostInvitationConfirm() {
  const dispatch = useDispatch();

  const coHostRequestContext = React.useContext(CoHostRequestContext);

  const updateCoHostingRequestStatus = useSelector(
    UpdateCoHostingRequestSelector.selectStatus,
  );

  const acceptCohostingRequest = async () => {
    const res = await dispatch(
      updateCohostingRequestThunk({
        cohostRequestKey:
          coHostRequestContext.acceptCoHostRequestConfirmState.confirmValue!,
        status: 'accepted',
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      if (
        coHostRequestContext.coHostRequest?.cohostRequestKey ===
        coHostRequestContext.acceptCoHostRequestConfirmState.confirmValue
      ) {
        coHostRequestContext.setCoHostRequest(null);
      }

      coHostRequestContext.acceptCoHostRequestConfirmState.closeConfirm();
    }
  };

  return (
    <Confirm
      cancelTx="label.activities.accept-co-host-request.cancel"
      confirmButttonVariant="primary"
      confirmTx="label.activities.accept-co-host-request.confirm"
      isOpen={coHostRequestContext.acceptCoHostRequestConfirmState.isOpen}
      messageTx="label.activities.accept-co-host-request.message"
      onCancel={
        coHostRequestContext.acceptCoHostRequestConfirmState.closeConfirm
      }
      onConfirm={acceptCohostingRequest}
      isLoading={updateCoHostingRequestStatus === 'pending'}
      titleTx="label.activities.accept-co-host-request.title"
    />
  );
}

export function Activities() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getActivityDraftsThunk());
  }, [dispatch]);

  return (
    <CoHostRequestProvider>
      <DeleteActivityDraftProvider>
        <PageContainer>
          <ActivitiesToolbar />

          <InnerPageContainer>
            <ContentContainer>
              <InnerContentContainer>
                <Box flex flexWrap="wrap" gap={16} flexJustify="between">
                  <PageHeader
                    headingTx="title.activities.dashboard"
                    subtitleTx="title.activities.subtitle"
                  />
                  <CreateActivityButton />
                </Box>

                <ActivitiesTable />
              </InnerContentContainer>
            </ContentContainer>

            <PendingInvitationSidebar />

            <RejectCoHostInvitationConfirm />
            <AcceptCoHostInvitationConfirm />
          </InnerPageContainer>
        </PageContainer>
      </DeleteActivityDraftProvider>
    </CoHostRequestProvider>
  );
}
