import { GeneralApiResultWithData, Location } from '@orbiapp/components';

import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';

export const getLocations = async (): Promise<
  GeneralApiResultWithData<Location[]>
> => {
  const res = await apisauce.get<Location[], any>('/v1/locations');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getLocations', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
