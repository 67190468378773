import { Coordinates } from '../models';

export const isCoordinates = (value: any): value is Coordinates => {
  return (
    !Array.isArray(value) &&
    typeof value === 'object' &&
    value !== null &&
    'latitude' in value &&
    'longitude' in value &&
    typeof value.latitude === 'number' &&
    typeof value.longitude === 'number'
  );
};
