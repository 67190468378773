import React from 'react';

import { Box } from '../../box';
import { Icon } from '../../icon';
import { Text } from '../../text';
import { Tooltip } from '../../tooltip';
import { TableHeaderContext } from '../table-header';
import { Styled } from './table-head.styled';
import { TableHeadProps } from './table-head.types';

export function TableHead(props: TableHeadProps) {
  const { children, name, tx, txArgs, text, tooltip, ...rest } = props;

  const tableHeaderContext = React.useContext(TableHeaderContext);

  const handleTableHeadClick = () => {
    if (!name || !tableHeaderContext.onSort) return;

    tableHeaderContext.onSort(name);
  };

  const enableSorting =
    !!tableHeaderContext &&
    !!name &&
    !!tableHeaderContext.onSort &&
    !!tableHeaderContext.sortableColumns?.includes(name);

  const showSortIcon =
    enableSorting &&
    !!tableHeaderContext.orderBy &&
    !!name &&
    tableHeaderContext.orderBy === name;

  return (
    <Styled.TableHead {...rest}>
      <Styled.TableHeadContent
        cursor={enableSorting ? 'pointer' : 'unset'}
        flex
        flexAlign="center"
        gap={16}
        onClick={enableSorting ? handleTableHeadClick : undefined}
      >
        <Box flex gap={4} flexAlign="center">
          {children || (
            <Text variant="bodySm" tx={tx} text={text} txArgs={txArgs} />
          )}

          {tooltip && (
            <Tooltip {...tooltip}>
              <Icon name="question-mark-circle-outline" size={20} />
            </Tooltip>
          )}
        </Box>

        <Styled.SortingIcon
          enableSorting={showSortIcon}
          name={
            tableHeaderContext?.sortOrder === 'asc'
              ? 'arrow-small-up'
              : 'arrow-small-down'
          }
          size={16}
        />
      </Styled.TableHeadContent>
    </Styled.TableHead>
  );
}
