import { Time } from '@orbiapp/components';

import { INITIAL_DATA_STATE } from '../store.constants';
import { OrbiPayState } from './orbi-pay.types';

const payouts = {
  ...INITIAL_DATA_STATE,
  payoutsFiltering: {
    limit: 100,
    endDate: Date.now(),
    startDate: Date.now() - 14 * Time.Day,
  },
};

export const initialOrbiPayState: OrbiPayState = {
  payouts,
  createOrbiPayAccount: INITIAL_DATA_STATE,
  createStripeAccountLink: INITIAL_DATA_STATE,
  orbiPaySettings: INITIAL_DATA_STATE,
  payout: INITIAL_DATA_STATE,
  publicStripeKey: INITIAL_DATA_STATE,
};
