import Joi from 'joi';

import { ApplicationSchema } from './application.schema';

export const RequestMoreInfoOnMembershipApplicationFormValidation = Joi.object({
  membershipApplicationKey: ApplicationSchema.membershipApplicationKey,
  note: ApplicationSchema.note,
  reason: ApplicationSchema.reason,
});

export const RejectMembershipApplicationFormValidation = Joi.object({
  membershipApplicationKey: ApplicationSchema.membershipApplicationKey,
  note: ApplicationSchema.note,
});
