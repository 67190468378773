import styled, { css } from 'styled-components';

import { Box } from '../box';

interface SelectionCardProps {
  toggled: boolean;
  disabled?: boolean;
}

const SelectionCard = styled(Box)<SelectionCardProps>`
  ${(props) => {
    const { disabled, theme, toggled } = props;

    return css`
      border-radius: 16px;
      padding: 32px;
      cursor: pointer;
      background-color: ${theme.colors.selectionCardBackground};

      position: relative;

      ${disabled
        ? css`
            border: 2px solid ${theme.colors.selectionCardBorderDisabled};
            pointer-events: none;

            :before {
              content: '';
              position: absolute;
              inset: 0;
              z-index: 4;
              border-radius: inherit;
              opacity: 0.5;
              background-color: ${theme.colors.selectionCardBackgroundDisabled};
              cursor: auto;
            }
          `
        : css`
            border: 2px solid ${theme.colors.selectionCardBorder};
          `}

      ${toggled &&
      !disabled &&
      css`
        border: 2px solid ${theme.colors.selectionCardBorderToggled};
      `}

    @media (max-width: 980px) {
        width: 100%;
      }
    `;
  }}
`;

export const Styled = { SelectionCard };
