import {
  DepartmentInvitation,
  PendingDepartmentInvitation,
  User,
} from '../../../../../models';
import {
  DepartmentInvitationDto,
  PendingDepartmentInvitationDto,
  UserDto,
} from './team.dto';

export function pendingDepartmentInvitationMapper(
  pendingDeparmtentInvitationDto: PendingDepartmentInvitationDto,
): PendingDepartmentInvitation {
  return {
    departmentInvitationKey:
      pendingDeparmtentInvitationDto.departmentInvitationKey,
    email: pendingDeparmtentInvitationDto.email,
    expiresAt: pendingDeparmtentInvitationDto.expiresAt,
    role: pendingDeparmtentInvitationDto.role,
  };
}

export function userMapper(userDto: UserDto): User {
  return {
    userKey: userDto.userKey,

    email: userDto.email,

    firstName: userDto.firstName,
    lastName: userDto.lastName,

    role: userDto.role,

    profilePicture: userDto.profilePicture,
  };
}

export function departmentInvitationMapper(
  departmentInvitationDto: DepartmentInvitationDto,
): DepartmentInvitation {
  return {
    departmentInvitationKey: departmentInvitationDto.departmentInvitationKey,

    departmentDetails: departmentInvitationDto.departmentDetails,

    email: departmentInvitationDto.email,
    role: departmentInvitationDto.role,

    consumedAt: departmentInvitationDto.consumedAt,
    expiresAt: departmentInvitationDto.expiresAt,

    userExists: departmentInvitationDto.userExists,
  };
}
