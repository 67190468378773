import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { MembershipCheckboxQuestionForm } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import {
  createCheckboxMapper,
  updateCheckboxMapper,
} from './checkbox-questions.mappers';

export const createCheckboxQuestion = async (params: {
  membershipTypeKey: string;
  createCheckboxQuestion: MembershipCheckboxQuestionForm;
}): Promise<GeneralApiResultWithData<string>> => {
  const { membershipTypeKey, createCheckboxQuestion } = params;

  const res = await apisauce.post<
    { membershipCheckboxQuestionKey: string },
    any
  >(
    `/v2/membership-types/${membershipTypeKey}/checkbox-questions`,
    createCheckboxMapper(createCheckboxQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.membershipCheckboxQuestionKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createCheckboxQuestion', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateCheckboxQuestion = async (params: {
  membershipTypeKey: string;
  updateCheckboxQuestion: MembershipCheckboxQuestionForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, updateCheckboxQuestion } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/checkbox-questions/${updateCheckboxQuestion.key}`,
    updateCheckboxMapper(updateCheckboxQuestion),
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const deleteCheckboxQuestion = async (params: {
  membershipTypeKey: string;
  membershipCheckboxQuestionKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipCheckboxQuestionKey } = params;

  const res = await apisauce.delete<void, any>(
    `/v2/membership-types/${membershipTypeKey}/checkbox-questions/${membershipCheckboxQuestionKey}`,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
