import { GeneralApiResultWithData } from '@orbiapp/components';

import { ActivityUser } from '../../../../../../../models';
import { Logger } from '../../../../../../logger';
import { apisauce } from '../../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../../api.constants';
import { getGeneralApiProblem } from '../../../../../api.utils';
import { TicketUserDto } from './users.dto';
import { ticketUserMapper } from './users.mappers';

export const getActivityUsers = async (
  activityKey: string,
): Promise<GeneralApiResultWithData<ActivityUser[]>> => {
  const res = await apisauce.get<TicketUserDto[], any>(
    `/v2/activities/${activityKey}/stats/users`,
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getActivityUsers },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(ticketUserMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivityUsers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
