import React from 'react';

import { QUERY_CUSTOM_TOKEN_KEY, QUERY_WORKSPACE_KEY } from '../constants';
import { WorkspacesSessionStorage } from '../services';

const queryParams = new URLSearchParams(window.location.search);
const customToken = queryParams.get(QUERY_CUSTOM_TOKEN_KEY);
const workspaceKey =
  queryParams.get(QUERY_WORKSPACE_KEY) ||
  WorkspacesSessionStorage.getWorkspaceKey();

export function useSignInWithCustomToken(
  onTokenFound: (customToken: string) => Promise<void>,
) {
  const [canContinue, setCanContinue] = React.useState(false);

  const getQueryParams = React.useCallback(async () => {
    let url = `${window.location.pathname}`;

    if (customToken) {
      await onTokenFound(customToken);
      queryParams.delete(QUERY_CUSTOM_TOKEN_KEY);
    }

    if (workspaceKey) {
      WorkspacesSessionStorage.startSession(workspaceKey);
      queryParams.delete(QUERY_WORKSPACE_KEY);
    }

    if (queryParams.size > 0) {
      url += `?${queryParams}`;
    }

    window.history.replaceState({}, '', url);
    setCanContinue(true);
  }, [onTokenFound]);

  React.useEffect(() => {
    getQueryParams();
  }, [getQueryParams]);

  return canContinue;
}
