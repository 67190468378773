import {
  PartialOrgNode,
  Referral,
  ReferredDepartment,
} from '../../../../../models';
import {
  PartialDepartmentDto,
  PartialOrgNodeDto,
  ReferralDto,
} from './referrals.dto';

const partialOrgNodeMapper = (
  partialOrgNodeDto: PartialOrgNodeDto,
): PartialOrgNode => ({
  name: partialOrgNodeDto.name,
  orgNodeKey: partialOrgNodeDto.orgNodeKey,
  parentKey: partialOrgNodeDto.orgNodeKey,
  type: partialOrgNodeDto.type,
});

const referredDepartmentMapper = (
  partialDepartmentDto: PartialDepartmentDto,
): ReferredDepartment => ({
  createdAt: partialDepartmentDto.createdAt,
  departmentKey: partialDepartmentDto.departmentKey,
  logo: partialDepartmentDto.logo,
  name: partialDepartmentDto.name,
  orgNodeBranch: partialDepartmentDto.orgNodeBranch.map(partialOrgNodeMapper),
  rewardUserCount: partialDepartmentDto.rewardUserCount,
});

export const referralOrNullMapper = (
  referralDto: ReferralDto,
): Referral | null => {
  if (!referralDto.referredDepartment) {
    return null;
  }

  return {
    createdAt: referralDto.createdAt,
    referralKey: referralDto.referralKey,
    referredDepartment: referredDepartmentMapper(
      referralDto.referredDepartment,
    ),
    rewardCompletedAt: referralDto.rewardCompletedAt,
    rewardFailedAt: referralDto.rewardFailedAt,
  };
};
