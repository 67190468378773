import {
  Box,
  ControlledDatePicker,
  FormSection,
  FormSectionHeader,
  Switch,
  Text,
} from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import { CreateOfferForm } from '../../../../../models';
import { useOfferFormsSteps } from '../../helpers';

export function EndDate() {
  const formMethods = useFormContext<CreateOfferForm>();
  const endDate = formMethods.watch('endDate');

  const steps = useOfferFormsSteps();

  const toggleEndDate = () => {
    formMethods.setValue('endDate', endDate ? null : Date.now(), {
      shouldDirty: true,
      shouldValidate: formMethods.formState.isSubmitted,
    });
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Text
          tx="label.offer-form.headers.expiration-date"
          variant="bodyMdBold"
          color="formSectionHeader"
          txArgs={{ step: steps.expirationDate }}
        />
      </FormSectionHeader>

      <Switch
        tx="label.offer-form.end-date-toggle"
        checked={!!endDate}
        onClick={toggleEndDate}
      />

      {!!endDate && (
        <Box flexGrow={1}>
          <ControlledDatePicker
            labelTx="label.offer-form.end-date"
            name="endDate"
            type="datetime-local"
          />
        </Box>
      )}
    </FormSection>
  );
}
