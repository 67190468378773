export enum Language {
  'EN' = 'en',
  'SV' = 'sv',
}

export function getBrowserLanguage() {
  switch (navigator.language) {
    case 'sv-SE':
      return Language.SV;

    default:
      return Language.EN;
  }
}
