import styled, { css } from 'styled-components';

import { Box } from '../box';
import { GetStatusColorsReturn } from './status.types';

export const Status = styled(Box)<GetStatusColorsReturn>`
  ${(props) => {
    const { theme, backgroundColor, borderColor, labelColor } = props;

    return css`
      width: fit-content;
      align-items: center;
      padding: 4px 8px;
      border-radius: 4px;
      display: flex;
      gap: 4px;
      background-color: ${theme.colors[backgroundColor]};
      border: 1px solid ${theme.colors[borderColor]};
      color: ${theme.colors[labelColor]};
    `;
  }}
`;

export const Ellipse = styled.div<{
  backgroundColor: GetStatusColorsReturn['backgroundColor'];
}>`
  ${(props) => {
    const { theme, backgroundColor } = props;

    return css`
      width: 4px;
      height: 4px;
      border-radius: 50%;

      background-color: ${theme.colors[backgroundColor]};
    `;
  }}
`;

export const Styled = { Ellipse, Status };
