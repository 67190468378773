export enum RequestedInfoType {
  FreeText = 'FREE_TEXT',
  MultiChoice = 'MULTI_CHOICE',
  Checkbox = 'CHECKBOX',
}

export const requestedInfoTypes = [
  RequestedInfoType.FreeText,
  RequestedInfoType.MultiChoice,
  RequestedInfoType.Checkbox,
] as const;
