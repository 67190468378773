import {
  borderRadii,
  breakpoints,
  colors,
  elevation,
  spacing,
  textVariants,
  transitions,
} from '@orbiapp/theme';

export const componentsColors = {
  ...colors,
};

export interface Theme {
  borderRadii: typeof borderRadii;
  breakpoints: typeof breakpoints;
  colors: typeof componentsColors;
  elevation: typeof elevation;
  spacing: typeof spacing;
  textVariants: typeof textVariants;
  transitions: typeof transitions;
}

export const theme: Theme = {
  borderRadii,
  breakpoints,
  colors: componentsColors,
  elevation,
  spacing,
  textVariants,
  transitions,
};

export function useTheme() {
  return theme;
}
