import React from 'react';

import { formatDate } from '../../utils';
import { Avatar, AvatarVariant } from '../avatar';
import { Box } from '../box';
import { Chip } from '../chip';
import { CollapsibleText } from '../collapsible-text';
import { IconButton } from '../icon-button';
import { Menu, MenuItem, MenuItemProps, useAnchoredMenu } from '../menu';
import { ReactionsGroup, ReactionsGroupProps } from '../reactions-group';
import { Text } from '../text';
import { Styled } from './post-comment-card.styled';

interface PostCommentCardProps extends ReactionsGroupProps {
  message: string | null;

  avatarSrc?: string;
  avatarFallbackLetter?: string;
  avatarVariant?: AvatarVariant;

  publishedAt: number;

  title: string;

  menuItems?: MenuItemProps[];

  isDeleted?: boolean;

  authorTx?: TxString;
}

const SHOW_MORE_TRHESHOLD = 200;

export function PostCommentCard(props: PostCommentCardProps) {
  const anchoredMenu = useAnchoredMenu<HTMLButtonElement>({
    placement: 'bottom-end',
  });

  const renderMenuItem = (item: MenuItemProps, index: number) => {
    const menuItemClickHandler = item.onClick
      ? (e: React.MouseEvent<HTMLDivElement>) => {
          item.onClick?.(e);
          anchoredMenu.closeMenu();
        }
      : undefined;

    return <MenuItem key={index} {...item} onClick={menuItemClickHandler} />;
  };

  return (
    <Styled.PostCommentCard
      backgroundColor="backgroundPrimary"
      flex
      flexDirection="column"
      gap={8}
      p={16}
      r={8}
    >
      <Box gap={8} flex>
        <Box width={32} height={32}>
          <Avatar
            width={32}
            height={32}
            src={props.avatarSrc}
            fallbackLetter={props.avatarFallbackLetter}
            variant={props.avatarVariant}
          />
        </Box>

        <Box flex flexDirection="column" overflow="hidden">
          <Box overflow="hidden" flexAlign="center" gap={4} flex>
            <Text
              overflow="hidden"
              text={`${props.title} ·`}
              whiteSpace="nowrap"
              variant="bodySmBold"
              textOverflow="ellipsis"
            />

            <Text
              text={formatDate(props.publishedAt, 'timeAgo')}
              color="textSecondary"
              variant="bodyXs"
              whiteSpace="nowrap"
            />
          </Box>

          {props.message && (
            <CollapsibleText
              text={props.message}
              threshold={SHOW_MORE_TRHESHOLD}
              variant={props.isDeleted ? 'bodyMdItalic' : undefined}
              color={props.isDeleted ? 'textSecondary' : undefined}
            />
          )}
        </Box>

        <Box ml="auto" gap={16} flexAlign="start" flex>
          {props.authorTx && (
            <Chip flex flexAlign="center" variant={3}>
              <Text tx={props.authorTx} variant="bodyXs" />
            </Chip>
          )}

          <Box ref={anchoredMenu.clickOutsideRef}>
            <IconButton
              ref={anchoredMenu.anchorRef}
              isActive={anchoredMenu.isOpen}
              onClick={anchoredMenu.toggleMenu}
              icon="ellipsis-vertical"
              disabled={!props.menuItems?.length}
            />

            <Menu
              mt={16}
              fixed
              isOpen={anchoredMenu.isOpen}
              zIndex={1901}
              width={210}
              ref={anchoredMenu.menuRef}
            >
              {props.menuItems?.map(renderMenuItem)}
            </Menu>
          </Box>
        </Box>
      </Box>

      <ReactionsGroup
        onReactionsClick={props.onReactionsClick}
        reactions={props.reactions}
        onSelectedReactionChange={props.onSelectedReactionChange}
        selectedReaction={props.selectedReaction}
      />
    </Styled.PostCommentCard>
  );
}
