import { getUID } from '@orbiapp/components';

import {
  ShrodingersTicket,
  TicketHistoryListItem,
} from '../../../../../../models';
import { ShrodingersTicketDto, TicketHistoryDto } from './tickets.dto';

export const ticketHistoryListItemMapper = (
  ticketHistoryDto: TicketHistoryDto,
): TicketHistoryListItem => ({
  ticketHistoryListItemKey: getUID(),
  ticketEmailTransfer: ticketHistoryDto.ticketEmailTransfer
    ? {
        email: ticketHistoryDto.ticketEmailTransfer.email,
        fullName: ticketHistoryDto.ticketEmailTransfer.fullName,
        transferredAt: ticketHistoryDto.ticketEmailTransfer.transferredAt,
      }
    : null,
  purchaseCreatedAt: ticketHistoryDto.purchaseCreatedAt,
  ticketRefundedAt: ticketHistoryDto.ticketRefundedAt,
  ticketTypeName: ticketHistoryDto.ticketTypeName,
  userFullName: ticketHistoryDto.userFullName,
});

export const shrodingersTicketMapper = (
  shrodingersTicketDto: ShrodingersTicketDto,
): ShrodingersTicket => ({
  activityTitle: shrodingersTicketDto.activityTitle,
  claimedAt: shrodingersTicketDto.claimedAt,
  requiredMemberships:
    shrodingersTicketDto.requiredMemberships?.map((requiredMembershipDto) => ({
      expiredAt: requiredMembershipDto.expiredAt,
      membershipTypeKey: requiredMembershipDto.membershipTypeKey,
      membershipTypeName: requiredMembershipDto.membershipTypeName,
      validFrom: requiredMembershipDto.validFrom,
    })) ?? null,
  status: shrodingersTicketDto.status,
  statusUpdatedAt: shrodingersTicketDto.statusUpdatedAt,
  ticketHolder: shrodingersTicketDto.ticketHolder
    ? {
        email: shrodingersTicketDto.ticketHolder.email,
        name: shrodingersTicketDto.ticketHolder.name,
        type: shrodingersTicketDto.ticketHolder.type,
      }
    : null,
  ticketPurchaseKey: shrodingersTicketDto.ticketPurchaseKey,
  ticketTypeName: shrodingersTicketDto.ticketTypeName,
});
