import {
  Box,
  Icon,
  IconName,
  Status,
  StatusVariant,
  Text,
} from '@orbiapp/components';
import { Color } from '@orbiapp/theme';

import { ShrodingersTicketStatus } from '../../../../../../models';

function statusToResultCardHeaderSettings(status: ShrodingersTicketStatus): {
  icon: IconName;
  iconColor: Color;
  statusTx: TxString;
  titleColor: Color;
  titleTx: TxString;
  variant: StatusVariant;
} {
  switch (status) {
    case 'alreadyConsumed':
      return {
        icon: 'exclamation-triangle-outline',
        iconColor: 'statusErrorIcon',
        statusTx:
          'label.scan-qr-codes.scan-result-card.already-consumed.status-text',
        titleColor: 'statusErrorLabel',
        titleTx: 'label.scan-qr-codes.scan-result-card.already-consumed.title',
        variant: 'error',
      };

    case 'emailTransferred':
      return {
        icon: 'exclamation-triangle-outline',
        iconColor: 'statusWarningIcon',
        statusTx:
          'label.scan-qr-codes.scan-result-card.email-transferred.status-text',
        titleColor: 'statusWarningLabel',
        titleTx: 'label.scan-qr-codes.scan-result-card.email-transferred.title',
        variant: 'warning',
      };

    case 'refunded':
      return {
        icon: 'exclamation-triangle-outline',
        iconColor: 'statusWarningIcon',
        statusTx: 'label.scan-qr-codes.scan-result-card.refunded.status-text',
        titleColor: 'statusWarningLabel',
        titleTx: 'label.scan-qr-codes.scan-result-card.refunded.title',
        variant: 'warning',
      };

    case 'successfullyConsumed':
      return {
        icon: 'check-circle-solid',
        iconColor: 'statusSuccessIcon',
        statusTx:
          'label.scan-qr-codes.scan-result-card.successfully-consumed.status-text',
        titleColor: 'statusSuccessLabel',
        titleTx:
          'label.scan-qr-codes.scan-result-card.successfully-consumed.title',
        variant: 'success',
      };

    case 'transferred':
      return {
        icon: 'exclamation-triangle-outline',
        iconColor: 'statusWarningIcon',
        statusTx:
          'label.scan-qr-codes.scan-result-card.transferred.status-text',
        titleColor: 'statusWarningLabel',
        titleTx: 'label.scan-qr-codes.scan-result-card.transferred.title',
        variant: 'warning',
      };
  }
}

export function ScanResultCardHeader(props: {
  status: ShrodingersTicketStatus;
}) {
  const { status } = props;

  const { icon, iconColor, statusTx, titleColor, titleTx, variant } =
    statusToResultCardHeaderSettings(status);

  return (
    <Box flex flexDirection="column" gap={8}>
      <Box gap={8} flexAlign="center" flex>
        <Icon name={icon} color={iconColor} />

        <Text variant="bodyLgBold" tx={titleTx} color={titleColor} />
      </Box>

      <Status ellipsis tx={statusTx} variant={variant} />
    </Box>
  );
}
