import { Text } from '../text';
import { Styled } from './status.styled';
import {
  GetStatusColorsReturn,
  StatusProps,
  StatusVariant,
} from './status.types';

function getStatusColors(variant: StatusVariant): GetStatusColorsReturn {
  switch (variant) {
    case 'error':
      return {
        backgroundColor: 'statusErrorBackground',
        borderColor: 'statusErrorBorder',
        labelColor: 'statusErrorLabel',
      };

    case 'info':
      return {
        backgroundColor: 'statusInfoBackground',
        borderColor: 'statusInfoBorder',
        labelColor: 'statusInfoLabel',
      };

    case 'warning':
      return {
        backgroundColor: 'statusWarningBackground',
        borderColor: 'statusWarningBorder',
        labelColor: 'statusWarningLabel',
      };

    default:
      return {
        backgroundColor: 'statusSuccessBackground',
        borderColor: 'statusSuccessBorder',
        labelColor: 'statusSuccessLabel',
      };
  }
}

export function Status(props: StatusProps) {
  const { children, text, tx, txArgs, variant, ellipsis, ...rest } = props;

  const statusColors = getStatusColors(variant);

  return (
    <Styled.Status {...statusColors} {...rest}>
      {ellipsis && <Styled.Ellipse backgroundColor={statusColors.labelColor} />}

      {children || (
        <Text
          lineHeight="unset"
          as="span"
          text={text}
          tx={tx}
          txArgs={txArgs}
          variant="bodySm"
        />
      )}
    </Styled.Status>
  );
}
