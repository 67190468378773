import { Color } from '@orbiapp/theme';
import styled, { css } from 'styled-components';

import { Box } from '../box';

const Alert = styled(Box)<{ borderColor: Color; backgroundColor: Color }>`
  ${(props) => {
    const { backgroundColor, borderColor, theme } = props;

    return css`
      display: grid;
      grid-template-columns: 1fr;
      border: 2px solid ${theme.colors[borderColor]};
      background-color: ${theme.colors[backgroundColor]};

      @media (min-width: ${theme.breakpoints.xs}px) {
        grid-template-columns: 70px auto;
        padding-right: 16px;
      }
    `;
  }}
`;

const AlertIconBox = styled(Box)<{ backgroundColor: Color }>`
  ${(props) => {
    const { backgroundColor, theme } = props;

    return css`
      background-color: ${theme.colors[backgroundColor]};
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      display: none;

      @media (min-width: ${theme.breakpoints.xs}px) {
        display: flex;
      }
    `;
  }}
`;

export const Styled = { Alert, AlertIconBox };
