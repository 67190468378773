import { PayloadAction } from '@reduxjs/toolkit';

import { TicketListItem } from '../../models';
import { initialActivitiesState } from './activities.initial';
import { ActivityState } from './activities.types';

const reset = (state: ActivityState) => {
  Object.assign(state, initialActivitiesState);
};

const clearCreateActivity = (state: ActivityState) => {
  state.createActivity = initialActivitiesState.createActivity;
};

const clearActivities = (state: ActivityState) => {
  state.activities = initialActivitiesState.activities;
};

const clearViewActivityData = (state: ActivityState) => {
  state.activity = initialActivitiesState.activity;

  state.activityUsers = initialActivitiesState.activityUsers;
  state.freetextListItems = initialActivitiesState.freetextListItems;
  state.discountStats = initialActivitiesState.discountStats;
  state.requestedInfoStats = initialActivitiesState.requestedInfoStats;

  state.ticketListItems = initialActivitiesState.ticketListItems;
  state.selectedTicketListItem = initialActivitiesState.selectedTicketListItem;
};

const clearActivityDraft = (state: ActivityState) => {
  state.activityDraft = initialActivitiesState.activityDraft;
  state.createActivityDraft = initialActivitiesState.createActivityDraft;
};

const clearFreetextListItems = (state: ActivityState) => {
  state.freetextListItems = initialActivitiesState.freetextListItems;
};

const clearTicketListItems = (state: ActivityState) => {
  state.ticketListItems = {
    ...initialActivitiesState.ticketListItems,
    search: state.ticketListItems.search,
  };
};

const clearTicketsHistory = (state: ActivityState) => {
  state.ticketsHistory = initialActivitiesState.ticketsHistory;
};

const clearSelectedTicketListItem = (state: ActivityState) => {
  state.selectedTicketListItem = initialActivitiesState.selectedTicketListItem;
};

const setSoldTicket = (
  state: ActivityState,
  action: PayloadAction<TicketListItem>,
) => {
  state.selectedTicketListItem = action.payload;
};

const clearScanResultData = (state: ActivityState) => {
  state.scanTicket = initialActivitiesState.scanTicket;
};

const clearActivityUsersData = (state: ActivityState) => {
  state.activityUsersData = initialActivitiesState.activityUsersData;
};

const clearSuggestActivityCategories = (state: ActivityState) => {
  state.suggestActivityCategories =
    initialActivitiesState.suggestActivityCategories;
};

const setTicketListItemsSearch = (
  state: ActivityState,
  action: PayloadAction<string>,
) => {
  state.ticketListItems.search = action.payload;
};

export const reducers = {
  reset,

  clearActivities,
  clearCreateActivity,
  clearViewActivityData,

  clearActivityDraft,

  clearFreetextListItems,

  clearScanResultData,

  clearSelectedTicketListItem,
  clearTicketListItems,

  clearTicketsHistory,

  setSoldTicket,

  clearActivityUsersData,

  clearSuggestActivityCategories,

  setTicketListItemsSearch,
};
