import React from 'react';

import { Box } from '../box';
import { Icon } from '../icon';
import { Menu, MenuItem, useMenu } from '../menu';
import { Text } from '../text';
import { Styled } from './tiny-select.styled';
import {
  TinySelectInputProps,
  TinySelectOptionProps,
  TinySelectProps,
} from './tiny-select.types';

export const TinySelectInput = React.forwardRef(
  (props: TinySelectInputProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const {
      placeholderText,
      placeholderTx,
      placeholderTxArgs,
      children,
      disabled,
      isOpen,
      value,
      chevronIconSize,
      ...rest
    } = props;

    return (
      <Styled.TinySelectInput
        disabled={disabled}
        flex
        flexAlign="center"
        flexJustify="between"
        gap={8}
        isOpen={isOpen}
        px={8}
        py={4}
        role="button"
        ref={ref}
        {...rest}
      >
        {children ?? (
          <React.Fragment>
            {value ? (
              <Text
                text={value}
                variant="bodySm"
                color={disabled ? 'inputTextDisabled' : 'inputText'}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                lineHeight="unset"
              />
            ) : (
              <Text
                text={placeholderText}
                variant="bodySm"
                tx={placeholderTx}
                txArgs={placeholderTxArgs}
                color={disabled ? 'inputTextDisabled' : 'inputText'}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                lineHeight="unset"
              />
            )}

            <Icon
              color={disabled ? 'inputIconDisabled' : 'inputIcon'}
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              size={chevronIconSize}
            />
          </React.Fragment>
        )}
      </Styled.TinySelectInput>
    );
  },
);

export function TinySelect<T extends string | number>(
  props: TinySelectProps<T>,
) {
  const {
    chevronIconSize = 20,
    defaultIsOpen,
    disabled = false,
    invertMenu,
    maxHeight,
    minWidth,
    onChange,
    options,
    renderOptionValue,
    renderValue,
    value,
    ...rest
  } = props;

  const menuState = useMenu({ defaultIsOpen });

  const renderTinySelectOption = (option: TinySelectOptionProps<T>) => {
    const handleMenuItemClick = () => {
      menuState.closeMenu();
      onChange(option.value);
    };

    return (
      <MenuItem
        onClick={handleMenuItemClick}
        isSelected={value === option.value || option.isSelected}
        key={option.value}
        disabled={option.disabled}
        notPickable={option.notPickable}
      >
        {renderOptionValue ? (
          renderOptionValue(option)
        ) : (
          <Text text={option.text} tx={option.tx} variant="bodySm" as="span" />
        )}
      </MenuItem>
    );
  };

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <TinySelectInput
        chevronIconSize={chevronIconSize}
        disabled={disabled}
        isOpen={menuState.isOpen}
        onClick={menuState.toggleMenu}
        value={value}
        {...rest}
      >
        {renderValue?.(options.find((option) => option.value === value)!)}
      </TinySelectInput>

      <Menu
        absolute
        bottom={invertMenu ? '100%' : undefined}
        width="100%"
        isOpen={menuState.isOpen}
        mb={invertMenu ? 10 : undefined}
        mt={invertMenu ? undefined : 10}
        top={invertMenu ? undefined : '100%'}
        maxHeight={maxHeight}
        minWidth={minWidth}
        ref={menuState.menuRef}
      >
        {options.map(renderTinySelectOption)}
      </Menu>
    </Box>
  );
}
