import styled, { css } from 'styled-components';

import { Box } from '../box';

interface StyledInputProps {
  hasError: boolean;
}

const InputBox = styled(Box)<{ hasValue: boolean }>`
  ${(props) => {
    const { hasValue } = props;

    return css`
      ${hasValue &&
      css`
        p {
          transform: scaleY(100%);
        }
      `}

      :focus-within {
        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      }
    `;
  }}
`;

const Input = styled.input<StyledInputProps>`
  border: none;
  outline: none;
  width: 100%;
  font-weight: 300;
  height: 100%;
`;

export const Styled = {
  Input,
  InputBox,
};
