import { fromScu, getUID } from '@orbiapp/components';

import {
  MembershipApplicationAnswer,
  MembershipCheckboxAnswer,
  MembershipFreetextAnswer,
  MembershipMember,
  MembershipMemberListItem,
  MembershipMemberListItemPeriod,
  MembershipMemberPeriod,
  MembershipMultichoiceAnswer,
  RequestedInfoType,
} from '../../../../../../models';
import {
  CheckboxAnswerDto,
  FreetextAnswerDto,
  MembershipMemberDto,
  MembershipMemberListItemDto,
  MembershipMemberPeriodDto,
  MembershipPeriodDto,
  MultichoiceAnswerDto,
} from './members.dto';

const checkboxAnswerMapper = (
  answer: CheckboxAnswerDto,
): MembershipCheckboxAnswer => ({
  index: answer.index,
  isChecked: answer.isChecked,
  key: getUID(),
  question: answer.question,
  type: RequestedInfoType.Checkbox,
});

const freetextAnswerMapper = (
  answer: FreetextAnswerDto,
): MembershipFreetextAnswer => ({
  answer: answer.answer,
  index: answer.index,
  key: getUID(),
  question: answer.question,
  type: RequestedInfoType.FreeText,
});

const multichoiceAnswerMapper = (
  answer: MultichoiceAnswerDto,
): MembershipMultichoiceAnswer => ({
  index: answer.index,
  key: getUID(),
  label: answer.label,
  question: answer.question,
  type: RequestedInfoType.MultiChoice,
});

export const membershipMemberMapper = (
  membershipMemberListItemDto: MembershipMemberListItemDto,
): MembershipMemberListItem => {
  const membershipPeriodMapper = (
    period: MembershipPeriodDto,
  ): MembershipMemberListItemPeriod => ({
    key: getUID(),
    membershipPurchaseKey: period.membershipPurchaseKey,
    name: period.name,
    validFrom: period.validFrom,
    validTo: period.validTo,
  });

  return {
    email: membershipMemberListItemDto.email,
    fullName: membershipMemberListItemDto.fullName,
    periods: membershipMemberListItemDto.periods.map(membershipPeriodMapper),
    profilePicture: membershipMemberListItemDto.profilePicture,
    userKey: membershipMemberListItemDto.userKey,
  };
};

export const getMembershipMemberMapper = (
  getMembershipMemberDto: MembershipMemberDto,
): MembershipMember => {
  const periodMapper = (
    period: MembershipMemberPeriodDto,
  ): MembershipMemberPeriod => ({
    key: getUID(),
    membershipPurchaseKey: period.membershipPurchaseKey,
    name: period.name,
    price: period.price !== null ? fromScu(period.price) : null,
    validFrom: period.validFrom,
    validTo: period.validTo,
  });

  const answers: MembershipApplicationAnswer[] = [
    ...(getMembershipMemberDto.checkboxAnswers?.map(checkboxAnswerMapper) ??
      []),
    ...(getMembershipMemberDto.freetextAnswers?.map(freetextAnswerMapper) ??
      []),
    ...(getMembershipMemberDto.multichoiceAnswers?.map(
      multichoiceAnswerMapper,
    ) ?? []),
  ];
  const periods = getMembershipMemberDto.periods.map(periodMapper);

  return {
    answers: answers.length ? answers : null,
    email: getMembershipMemberDto.email,
    fullName: getMembershipMemberDto.fullName,
    membershipId: getMembershipMemberDto.membershipId,
    periods,
    segmentation: getMembershipMemberDto.segmentation,
    semester: getMembershipMemberDto.semester,
    userKey: getMembershipMemberDto.userKey,
    profilePicture: getMembershipMemberDto.profilePicture,
  };
};
