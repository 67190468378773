import {
  Box,
  IconButton,
  InnerContentContainer,
  Text,
  Tooltip,
} from '@orbiapp/components';
import React from 'react';

import {
  GoBackButton,
  PublishEventButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { SegmentationPicker } from './segmentation-picker';

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton to={`${rootPath}/media`} icon="arrow-left-circle-outline" />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton
          to={`${rootPath}/tickets`}
          icon="arrow-right-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

export function CreateActivityParticipants() {
  return (
    <React.Fragment>
      <InnerContentContainer>
        <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
          <Box
            flex
            flexAlign="center"
            flexWrap="wrap"
            gap={16}
            height="fit-content"
          >
            <GoBackButton />

            <Text
              as="h1"
              color="pageTitle"
              tx="label.create-activity.tabs.participants.label"
              variant="titleMd"
            />
          </Box>

          <Box flex flexWrap="wrap" gap={16}>
            <SaveActivityAsDraftButton />

            <PublishEventButton />
          </Box>
        </Box>

        <Box flex flexDirection="column" gap={24}>
          <Text
            tx="label.create-activity.participants.steps.1"
            variant="bodyMdBold"
          />

          <SegmentationPicker />
        </Box>
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
