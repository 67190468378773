import React from 'react';

import { Box } from '../box';
import { IconButton } from '../icon-button';
import { Styled } from './range.styled';

type HTMLInputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface RangeProps extends HTMLInputProps {
  onMinusClick?: () => void;
  onMinusDisabled?: boolean;
  onPlusClick?: () => void;
  onPlusDisabled?: boolean;
}

export function Range(props: RangeProps) {
  const {
    onMinusClick,
    onMinusDisabled,
    onPlusClick,
    onPlusDisabled,
    ...rest
  } = props;

  return (
    <Box gap={8} width="100%" flex>
      {onMinusClick && (
        <IconButton
          disabled={onMinusDisabled}
          icon="minus-circle-outline"
          onClick={onMinusClick}
        />
      )}

      <Styled.RangeInput type="range" {...rest} />

      {onPlusClick && (
        <IconButton
          disabled={onPlusDisabled}
          icon="plus-circle-outline"
          onClick={onPlusClick}
        />
      )}
    </Box>
  );
}
