import {
  Box,
  Button,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  SelectionCard,
} from '@orbiapp/components';
import React from 'react';

import { QuickActionsContext } from '../quick-actions';

type MembershipType = 'membership' | 'integration';

export function CreateMembershipModal() {
  const { createMembershipModalState } = React.useContext(QuickActionsContext);

  const [selectedMembershipType, setSelectedMembershipType] =
    React.useState<MembershipType>('membership');

  const selectMembershipType = (membershipType: MembershipType) => () => {
    setSelectedMembershipType(membershipType);
  };

  return (
    <Modal
      width={850}
      isOpen={createMembershipModalState.isOpen}
      onClose={createMembershipModalState.closeModal}
    >
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle
            tx="title.memberships.select-membership-type"
            textAlign="center"
          />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <Box gap={24} flex flexJustify="center" flexWrap="wrap">
            <SelectionCard
              minWidth={300}
              flexBasis={0}
              flexGrow={1}
              onClick={selectMembershipType('membership')}
              subtitleTx="label.memberships.pick-membership-type.membership.subtitle"
              titleTx="label.memberships.pick-membership-type.membership.title"
              toggled={selectedMembershipType === 'membership'}
            />

            <SelectionCard
              minWidth={300}
              flexBasis={0}
              flexGrow={1}
              onClick={selectMembershipType('integration')}
              subtitleTx="label.memberships.pick-membership-type.integration.subtitle"
              titleTx="label.memberships.pick-membership-type.integration.title"
              toggled={selectedMembershipType === 'integration'}
            />
          </Box>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button
            tx="button.cancel"
            variant="tertiary"
            onClick={createMembershipModalState.closeModal}
          />

          <Button
            onClick={createMembershipModalState.closeModal}
            to={
              selectedMembershipType === 'membership'
                ? '/memberships/create-membership/general-info'
                : '/memberships/create-integration/select-service'
            }
            tx="button.continue"
            variant="primary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>
  );
}
