import { createAsyncThunk } from '@reduxjs/toolkit';

import { Referral } from '../../models';
import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const getReferralsThunk = createAsyncThunk<
  Referral[],
  undefined,
  ThunkApiConfig
>('referrals/get-referrals', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v1.referrals.getReferrals, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
