import styled, { css } from 'styled-components';

import { Box } from '../box';

const FormSection = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border: 1px solid ${theme.colors.formSectionBorder};
    `;
  }};
`;

export const Styled = { FormSection };
