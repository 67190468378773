import styled, { css } from 'styled-components';

import { Box } from '../box';
import { Text, getTextStyles } from '../text';

const Card = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      border: 1px solid ${theme.colors.cardBorder};
    `;
  }}
`;

const CardHeaderTitle = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      ${getTextStyles('bodyMd', theme)};

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${getTextStyles('bodyLg', theme)};
      }
    `;
  }}
`;

const CardHeaderSubtitle = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      ${getTextStyles('titleSm', theme)};

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${getTextStyles('titleLg', theme)};
      }
    `;
  }}
`;

const CardHeaderDescription = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      ${getTextStyles('bodySm', theme)};

      @media (min-width: ${theme.breakpoints.lg}px) {
        ${getTextStyles('bodyMd', theme)};
      }
    `;
  }}
`;

const CardHeaderUnit = styled(Text)`
  ${(props) => {
    const { theme } = props;

    return css`
      @media (min-width: ${theme.breakpoints.lg}px) {
        margin-bottom: 4px;
      }
    `;
  }}
`;

export const Styled = {
  Card,
  CardHeaderTitle,
  CardHeaderSubtitle,
  CardHeaderDescription,
  CardHeaderUnit,
};
