import styled, { css } from 'styled-components';

import { Box } from '../box';

const ImageGalleryGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  overflow: auto;
`;

const ImageGalleryGridItem = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      img {
        transition: transform ${theme.transitions.default};
      }

      :hover {
        img {
          transform: scale(1.5);
        }
      }
    `;
  }}
`;

export const Styled = { ImageGalleryGrid, ImageGalleryGridItem };
