import { Text } from '../text';
import { Styled } from './chip-stats.styled';
import {
  GetStatsChipColorsReturn,
  StatsChipProps,
  StatsChipVariant,
} from './chip-stats.types';

function getStatsChipColors(
  variant: StatsChipVariant,
): GetStatsChipColorsReturn {
  switch (variant) {
    case 'success':
      return {
        backgroundColor: 'statsChipSuccessBackground',
        borderColor: 'statsChipSuccessBorder',
        labelColor: 'statsChipSuccessLabel',
      };

    case 'danger':
      return {
        backgroundColor: 'statsChipDangerBackground',
        borderColor: 'statsChipDangerBorder',
        labelColor: 'statsChipDangerLabel',
      };

    default:
      return {
        backgroundColor: 'statsChipSuccessBackground',
        borderColor: 'statsChipSuccessBorder',
        labelColor: 'statsChipSuccessLabel',
      };
  }
}

export function StatsChip(props: StatsChipProps) {
  const {
    backgroundColor,
    borderColor,
    children,
    labelColor,
    text,
    tx,
    txArgs,
    variant,
    textVariant = 'bodySmBold',
    ...rest
  } = props;

  const chipColors = getStatsChipColors(variant);

  return (
    <Styled.StatsChip
      backgroundColor={backgroundColor || chipColors.backgroundColor}
      borderColor={borderColor || chipColors.borderColor}
      labelColor={labelColor || chipColors.labelColor}
      {...rest}
    >
      {children || (
        <Text
          as="span"
          tx={tx}
          txArgs={txArgs}
          text={text}
          variant={textVariant}
        />
      )}
    </Styled.StatsChip>
  );
}
