import { Checkbox } from '@orbiapp/components';
import React from 'react';

import { segmentationValues } from '../../../../../../../models';
import { ActivityFormContext } from '../../../create-activity.context';
import { getSegmentatonTypeTx } from '../segmentation-picker.utils';

export function SegmentationPickerYear() {
  const formContext = React.useContext(ActivityFormContext);

  const setSegmentationValues = React.useCallback(
    (values: number[]) => {
      formContext.setValue('participants.segmentation', values, {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      });
    },
    [formContext],
  );

  const segmentation = formContext.watch('participants.segmentation');

  const renderSegmentationCheckbox = (value: number) => {
    if (value % 2 !== 0) return null;

    const isChecked =
      segmentation.includes(value - 1) && segmentation.includes(value);

    const toggleSegmentationValue = () => {
      const newState = [...segmentation];

      if (!isChecked) {
        newState.push(value - 1, value);
        setSegmentationValues(newState.sort((a, b) => a - b));
        return;
      }

      newState.splice(segmentation.indexOf(value - 1), 2);
      setSegmentationValues(newState.sort((a, b) => a - b));
    };

    return (
      <Checkbox
        onChange={toggleSegmentationValue}
        key={`toggle-button-seg-${value}`}
        checked={isChecked}
        tx={getSegmentatonTypeTx('year')}
        txArgs={{ year: value / 2 }}
      />
    );
  };

  return (
    <React.Fragment>
      {segmentationValues.map(renderSegmentationCheckbox)}
    </React.Fragment>
  );
}
