import Joi from 'joi';

import { ContactDetailsValidation } from '../../contact-details';
import { CoverImageValidation, LogoValidation } from '../../file';
import {
  MEMBERSHIP_DESCRIPTION_MAX_LENGTH,
  MEMBERSHIP_NAME_MAX_LENGTH,
} from '../membership-type.constants';
import {
  INTEGRATION_API_KEY_MAX_LENGTH,
  INTEGRATION_URL_MAX_LENGTH,
} from './integrated.constants';
import { MembershipIntegrationErrors } from './integrated.errors';
import { integrationSource } from './integrated.model';

export namespace MembershipIntegrationSchema {
  export const apiKey = Joi.string()
    .max(INTEGRATION_API_KEY_MAX_LENGTH)
    .required()
    .messages(MembershipIntegrationErrors.apiKey);

  export const integrationUrl = Joi.string()
    .uri()
    .max(INTEGRATION_URL_MAX_LENGTH)
    .required()
    .messages(MembershipIntegrationErrors.integrationUrl);

  export const montania = Joi.object({
    source: Joi.string().valid('montania'),
    apiKey,
    integrationUrl: integrationUrl,
  });

  export const memlist = Joi.object({
    source: Joi.string().valid('memlist'),
    integrationUrl: integrationUrl,
    refreshToken: Joi.string()
      .required()
      .messages(MembershipIntegrationErrors.refreshToken),
  });

  export const membershipSource = Joi.string()
    .valid(...integrationSource)
    .messages(MembershipIntegrationErrors.membershipSource);

  export const description = Joi.string()
    .max(MEMBERSHIP_DESCRIPTION_MAX_LENGTH)
    .required()
    .messages(MembershipIntegrationErrors.description);

  export const logo = LogoValidation.required().messages(
    MembershipIntegrationErrors.logo,
  );

  export const backgroundImage = CoverImageValidation.required().messages(
    MembershipIntegrationErrors.backgroundImage,
  );

  export const name = Joi.string()
    .max(MEMBERSHIP_NAME_MAX_LENGTH)
    .required()
    .messages(MembershipIntegrationErrors.name);

  export const contactDetails = ContactDetailsValidation.required();
}
