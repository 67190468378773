import { sumOver } from '@orbiapp/components';

import {
  ActivityListItem,
  CreateActivityForm,
  UpdateActivityForm,
} from '../../models';
import { Logger, createDynamicLink } from '../../services';

export const createActivityFormToActivityListItem = (
  createActivityForm: CreateActivityForm,
  activityKey: string,
  departmentKey: string,
): ActivityListItem => {
  const totalTicketCount =
    createActivityForm.tickets.ticketTypes?.reduce((prev, curr) => {
      return prev + curr.ticketCount;
    }, 0) ?? 0;

  return {
    departmentKey,
    startDate: createActivityForm.description.startDate,
    endDate: createActivityForm.description.endDate,
    title: createActivityForm.description.title,
    activityKey,
    totalTicketCount,
    stats: {
      numberOfClicks: 0,
      numberOfQuestions: 0,
      numberOfSoldTickets: 0,
    },
  };
};

export const updateActivityFormToActivityListItem = (
  updateActivityForm: UpdateActivityForm,
  departmentKey: string,
): Omit<ActivityListItem, 'stats'> => ({
  activityKey: updateActivityForm.activityKey,
  departmentKey,
  endDate: updateActivityForm.description.endDate,
  startDate: updateActivityForm.description.startDate,
  title: updateActivityForm.description.title,
  totalTicketCount: updateActivityForm.tickets.ticketTypes
    ? sumOver(updateActivityForm.tickets.ticketTypes, 'ticketCount')
    : 0,
});

export const createQrScanTokenUrl = async (
  activityKey: string,
  sessionId: string,
) => {
  try {
    const res = await createDynamicLink({
      page: 'ticketScanner',
      key: activityKey,
      sessionId,
    });

    return res.data.shortLink;
  } catch (err) {
    Logger.error('createQrScanTokenUrl', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return null;
  }
};
