import styled, { css } from 'styled-components';

import { Box } from '../box';

const ReactionsGroupItem = styled(Box)<{ isSelected?: boolean }>`
  ${(props) => {
    const { theme, isSelected } = props;

    return css`
      padding: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 1px solid
        ${isSelected
          ? theme.colors.selectReactionButtonBorderToggled
          : theme.colors.borderPrimary};
      background-color: ${theme.colors.backgroundPrimary};
      box-shadow: ${theme.elevation.weak};
    `;
  }}
`;

const SelectReactionButton = styled.button<{ isToggled?: boolean }>`
  ${(props) => {
    const { theme } = props;

    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;

      border: 1px solid ${theme.colors.selectReactionButtonBorder};
      background-color: ${theme.colors.selectReactionButtonBackground};
      box-shadow: ${theme.elevation.weak};

      :active {
        transform: scale(0.95);
      }

      ${props.isToggled &&
      css`
        border-color: ${theme.colors.selectReactionButtonBorderToggled};
      `}
    `;
  }}
`;

const ReactionsMenu = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      box-shadow: ${theme.elevation.strong};
      opacity: 0;
      pointer-events: none;

      ${isOpen &&
      css`
        pointer-events: unset;
        opacity: 1;
      `}
    `;
  }}
`;

export const Styled = {
  ReactionsGroupItem,
  SelectReactionButton,
  ReactionsMenu,
};
