import { getLocationsThunk } from './locations.actions';
import { locationsAdapter } from './locations.adapter';
import { LocationsBuilder } from './locations.types';

function getLocationsBuild(builder: LocationsBuilder) {
  return builder
    .addCase(getLocationsThunk.pending, (state) => {
      state.locations.status = 'pending';
      state.locations.error = null;
    })
    .addCase(getLocationsThunk.fulfilled, (state, action) => {
      state.locations.status = 'idle';
      locationsAdapter.setAll(state.locations.data, action.payload);
    })
    .addCase(getLocationsThunk.rejected, (state, action) => {
      state.locations.status = 'idle';
      state.locations.error = action.error;
    });
}

export const extraReducers = (builder: LocationsBuilder) => {
  getLocationsBuild(builder);
};
