import { PublishEvent } from '@orbiapp/components';

import { Logger } from '../logger';

// https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events

// Event names must be 40 characters or fewer, can only contain alpha-numeric characters and underscores, and must start with an alphabetic character.
// Parameter names including item parameters must be 40 characters or fewer, can only contain alpha-numeric characters and underscores, and must start with an alphabetic character.
// Parameter values including item parameter values must be 100 characters or fewer.

export namespace Analytics {
  type TrackEvent =
    | 'create_orbi_pay_account'
    | 'click_create_event__quick_menu'
    | 'click_create_event__dashboard'
    | 'click_create_post__quick_menu'
    | 'click_create_post__dashboard'
    | 'click_create_offer__quick_menu'
    | 'click_create_offer__dashboard'
    | 'click_sidebar_item_referrals'
    | 'click_referrals_open_link'
    | 'click_referrals_copy_link'
    | 'click_referrals_read_more'
    | 'membership_bulk_invite'
    | 'convert_draft_banner'
    | 'convert_draft_convert'
    | 'convert_draft_delete'
    | 'dismiss_posts_improved_modal'
    | 'continue_posts_improved_modal'
    | PublishEvent;

  type EventData = {
    param1?: string;
    param2?: string;
    param3?: string;
  };

  export function track(event: TrackEvent, eventData?: EventData) {
    try {
      const data: Record<string, any> = {
        event: `sd_${event}`,
      };

      if (eventData) {
        data['eventData'] = eventData;
      }

      const noTagsFired = window.dataLayer.push(data);

      if (noTagsFired) {
        throw new Error('Failed to push data to dataLayer');
      }
    } catch (err) {
      Logger.exception(err);
    }
  }
}
