import { Box } from '@orbiapp/components';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';

const DownloadCsv = styled(CSVLink)`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: inherit;
  padding: 8px;
`;

const TableBox = styled(Box)`
  position: fixed;
  opacity: 0;
  pointer-events: none;
`;

export const Styled = { DownloadCsv, TableBox };
