import Joi from 'joi';

import { CreatePostSchema, UpdatePostSchema } from './post.schema';

export const CreatePostValidation = Joi.object({
  target: CreatePostSchema.target,
  message: CreatePostSchema.message,
  fileUploads: CreatePostSchema.fileUploads,
  pushTo: CreatePostSchema.pushTo,
});

export const EditPostValidation = Joi.object({
  message: UpdatePostSchema.message,
});
