import {
  INITIAL_MODAL_STATE,
  ModalState,
  ReactionType,
  Reactions,
  ReactionsModal,
  ReactionsModalHeaderItemProps,
  ReactionsRowProps,
  getAvatarVariantFromString,
  useModalState,
} from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';

import {
  GetReactionActorsPageParams,
  PartialActorWithReaction,
} from '../../models';
import {
  ReactionsSelector,
  getReactionsThunk,
  reactionsActions,
  useDispatch,
  useSelector,
} from '../../store';

interface ReactionsModalContextType
  extends Pick<GetReactionActorsPageParams, 'reactionType' | 'targetKey'> {
  reactions: Reactions;
}

export const ReactionsModalContext =
  React.createContext<ModalState<ReactionsModalContextType>>(
    INITIAL_MODAL_STATE,
  );

function reactionsMapper(reactions: PartialActorWithReaction[]) {
  return reactions.map((reaction): ReactionsRowProps => {
    switch (reaction.type) {
      case 'company':
        return {
          avatarSrc: reaction.company.logo.thumbnail64.url,
          avatarFallback: reaction.company.name.charAt(0),
          avatarVariant: getAvatarVariantFromString(
            reaction.company.companyKey,
          ),
          title: reaction.company.name,
          reactionType: reaction.reaction.type,
        };

      case 'department':
        return {
          avatarSrc: reaction.department.logo?.thumbnail64.url,
          avatarFallback: reaction.department.name.charAt(0),
          avatarVariant: getAvatarVariantFromString(
            reaction.department.departmentKey,
          ),
          title: reaction.department.name,
          reactionType: reaction.reaction.type,
        };

      default:
        return {
          avatarSrc: reaction.user?.profilePicture?.thumbnail64.url,
          avatarFallback: reaction.actorName.charAt(0),
          avatarVariant: getAvatarVariantFromString(reaction.actorKey),
          title: reaction.actorName,
          reactionType: reaction.reaction.type,
        };
    }
  });
}

function ReactionsModalWrapper() {
  const reactions = useSelector(ReactionsSelector.selectAll);
  const isLoading = useSelector(ReactionsSelector.selectIsLoading);
  const pageSize = useSelector(ReactionsSelector.selectPageSize);
  const endCursor = useSelector(ReactionsSelector.selectEndCursor);

  const dispatch = useDispatch();

  const modalState = React.useContext(ReactionsModalContext);

  const headerItems = React.useMemo(() => {
    const headerItems: ReactionsModalHeaderItemProps[] = [
      { tx: 'label.general.all' },
    ];
    if (modalState.value?.reactions.fireCount) {
      headerItems.push({ icon: 'fire', reactionType: 'fire' });
    }
    if (modalState.value?.reactions.thumbsUpCount) {
      headerItems.push({ icon: 'thumbs-up', reactionType: 'thumbs_up' });
    }
    if (modalState.value?.reactions.redHeartCount) {
      headerItems.push({ icon: 'heart', reactionType: 'red_heart' });
    }
    if (modalState.value?.reactions.partyPopperCount) {
      headerItems.push({ icon: 'party-popper', reactionType: 'party_popper' });
    }

    return headerItems;
  }, [modalState.value?.reactions]);

  const handleTabChange = React.useCallback(
    (reactionType?: ReactionType) => {
      if (!modalState.value) return;

      dispatch(reactionsActions.resetReactions());

      dispatch(
        getReactionsThunk({
          first: pageSize,
          targetKey: modalState.value.targetKey,
          reactionType: reactionType ?? 'all',
        }),
      );
    },
    [dispatch, modalState.value, pageSize],
  );

  const handleReachedEnd = React.useCallback(() => {
    if (!modalState.value || !endCursor || isLoading) return;

    dispatch(
      getReactionsThunk({
        first: pageSize,
        targetKey: modalState.value.targetKey,
        reactionType: modalState.value.reactionType,
        after: endCursor,
      }),
    );
  }, [dispatch, endCursor, isLoading, modalState.value, pageSize]);

  const handleClose = () => {
    modalState.closeModal();

    window.setTimeout(() => {
      dispatch(reactionsActions.resetReactions());
    }, transitonDuration.default);
  };

  return (
    <ReactionsModal
      isLoading={isLoading}
      items={reactionsMapper(reactions)}
      onReachedEnd={handleReachedEnd}
      onTabChange={handleTabChange}
      onClose={handleClose}
      headerItems={headerItems}
      isOpen={modalState.isOpen}
    />
  );
}

export function ReactionsModalProvider(props: React.PropsWithChildren) {
  const modalState = useModalState<ReactionsModalContextType>();

  return (
    <ReactionsModalContext.Provider value={modalState}>
      <ReactionsModalWrapper />

      {props.children}
    </ReactionsModalContext.Provider>
  );
}
