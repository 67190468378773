import { Icon } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const DeleteFileIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      cursor: pointer;
      color: ${theme.colors.alertErrorIcon};

      rect {
        fill: white;
      }
    `;
  }}
`;

export const Styled = { DeleteFileIcon };
