import styled from 'styled-components';

import { Box } from '../box';

const NavigationButtonBox = styled(Box)`
  transform: translateY(-50%);
`;

const Video = styled.video`
  object-fit: cover;
  max-width: 100%;
`;

const ReactSwiperWrapper = styled(Box)`
  .swiper-wrapper {
    display: flex;
  }
`;

export const Styled = { NavigationButtonBox, Video, ReactSwiperWrapper };
