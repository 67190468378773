import {
  Address,
  Coordinates,
  GeneralApiResultWithData,
  SearchAddressesParams,
  SearchCoordinatesParams,
} from '@orbiapp/components';

import {
  MembershipTypeType,
  PartialDepartment,
  PartialMembershipType,
  PartialUser,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getGeneralApiProblem } from '../../../api.utils';

export const searchCoordinates = async (
  params: SearchCoordinatesParams,
): Promise<GeneralApiResultWithData<Coordinates>> => {
  const res = await apisauce.post<Coordinates, any>(
    '/v1/search/coordinates',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchCoordinates', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const searchAddresses = async (
  params: SearchAddressesParams,
): Promise<GeneralApiResultWithData<Address[]>> => {
  const res = await apisauce.post<Address[], any>(
    '/v1/search/addresses',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchAddresses', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const searchMembershipTypes = async (params: {
  searchString: string;
  types: MembershipTypeType[];
}): Promise<GeneralApiResultWithData<PartialMembershipType[]>> => {
  const res = await apisauce.post<PartialMembershipType[], any>(
    '/v1/search/membership-types',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchMembershipTypes', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const searchUsers = async (params: {
  searchString: string;
}): Promise<GeneralApiResultWithData<PartialUser[]>> => {
  const res = await apisauce.post<PartialUser[], any>(
    '/v1/search/users',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchUsers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const searchDepartments = async (params: {
  searchString: string;
}): Promise<GeneralApiResultWithData<PartialDepartment[]>> => {
  const res = await apisauce.post<PartialDepartment[], any>(
    '/v1/search/departments',
    params,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('searchDepartments', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
