import {
  BreadcrumbsToolbar,
  LoadingContainer,
  PageContainer,
  TabButtonListItem,
  useCheckFeatureFlag,
} from '@orbiapp/components';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  ActivityDataSelector,
  ActivitySelector,
  activityActions,
  createDynamicActivityLinkThunk,
  getActivityDiscountStatsThunk,
  getActivityThunk,
  getRequestedInfoStatsThunk,
  getTicketsHistoryThunk,
  questionsActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { NotFound } from '../../../shared';

function TabHeader() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const showQuestions = useCheckFeatureFlag('student_dashboard_show_questions');

  if (!activityTitle || !activityKey) {
    return null;
  }

  const tabButtons: TabButtonListItem[] = [
    {
      tx: 'label.view-activity.tabs.description',
      to: `/activities/${activityKey}/description`,
    },
    {
      tx: 'label.view-activity.tabs.tickets',
      to: `/activities/${activityKey}/tickets`,
      disabled: !isActivityOwner,
    },
    {
      tx: 'label.view-activity.tabs.tickets-history',
      to: `/activities/${activityKey}/tickets-history`,
      disabled: !isActivityOwner,
    },
    {
      tx: 'label.view-activity.tabs.posts',
      to: `/activities/${activityKey}/posts`,
      disabled: !isActivityOwner,
    },
  ];

  if (showQuestions) {
    tabButtons.splice(1, 0, {
      tx: 'label.view-activity.tabs.questions',
      to: `/activities/${activityKey}/questions`,
      disabled: !isActivityOwner,
    });
  }

  return (
    <BreadcrumbsToolbar
      flexGrow={0.5}
      breadcrumbListItems={[
        { to: '/activities', tx: 'label.breadcrumbs.activities.activities' },
        {
          text: activityTitle,
          to: `/activities/${activityKey}`,
          truncate: true,
        },
      ]}
      tabButtonListItems={tabButtons}
    />
  );
}

export function ViewActivity() {
  const activityStatus = useSelector(ActivitySelector.selectStatus);
  const activityError = useSelector(ActivitySelector.selectError);
  const activity = useSelector(ActivityDataSelector.selectData);

  const { activityKey } = useParams<{ activityKey: string }>();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getActivity = React.useCallback(async () => {
    if (!activityKey) {
      navigate('/activities');
      return;
    }

    await dispatch(getActivityThunk(activityKey));

    dispatch(createDynamicActivityLinkThunk(activityKey));
    dispatch(getRequestedInfoStatsThunk(activityKey));
    dispatch(getTicketsHistoryThunk(activityKey));
    dispatch(getActivityDiscountStatsThunk(activityKey));
  }, [activityKey, dispatch, navigate]);

  React.useEffect(() => {
    getActivity();

    return () => {
      dispatch(activityActions.clearViewActivityData());
      dispatch(questionsActions.clearViewActivityQuestionData());
      dispatch(activityActions.clearTicketsHistory());
      dispatch(activityActions.clearActivityUsersData());
    };
  }, [activityKey, getActivity, dispatch]);

  if (activityError) {
    return <NotFound continueTo="/activities" />;
  }

  if (activityStatus === 'pending' || !activity) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <TabHeader />

      <Outlet />
    </PageContainer>
  );
}
