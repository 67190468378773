import { isCoordinates } from '@orbiapp/components';
import React from 'react';

import { searchCoordinatesThunk, useDispatch } from '../store';

export function useCoordinatesSearch(sessionToken: string) {
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();

  const search = async (placeId: string) => {
    if (!placeId.length) return;

    setIsLoading(true);

    const res = await dispatch(
      searchCoordinatesThunk({ sessionToken, placeId }),
    );

    setIsLoading(false);

    return isCoordinates(res.payload) ? res.payload : null;
  };

  return { isLoading, search };
}
