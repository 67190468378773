import React from 'react';

import { Avatar } from '../avatar';
import { Box, BoxProps } from '../box';
import { Chip } from '../chip';
import { Icon } from '../icon';
import { IconButton } from '../icon-button';
import { Status } from '../status';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './list.styled';
import { ListItemProps } from './list.types';

export function ListItem(props: ListItemProps) {
  const {
    avatarFallbackLetter,
    avatarSrc,
    avatarVariant,
    children,
    chipText,
    chipTx,
    chipVariant,
    icon,
    iconColor,
    iconDisabled,
    iconTooltipPlacement,
    iconTooltipText,
    iconTooltipTx,
    onClick,
    onIconClick,
    statusText,
    statusTx,
    statusVariant,
    subtitle,
    subtitleTx,
    title,
    titleTx,
    ...rest
  } = props;

  return (
    <Styled.ListItem
      flex
      flexAlign="center"
      flexJustify="between"
      gap={16}
      onClick={onClick}
      p={16}
      role="listitem"
      {...rest}
    >
      {children || (
        <React.Fragment>
          <Box overflow="hidden" flex gap={16} flexAlign="center">
            {(avatarSrc || avatarFallbackLetter) && (
              <Avatar
                variant={avatarVariant}
                fallbackLetter={avatarFallbackLetter}
                src={avatarSrc}
              />
            )}

            <Box overflow="hidden">
              <Text
                maxWidth={icon || chipVariant ? '24ch' : '40ch'}
                overflow="hidden"
                text={title}
                textOverflow="ellipsis"
                tx={titleTx}
                variant="bodyMd"
                whiteSpace="nowrap"
              />
              <Text
                maxWidth={icon || chipVariant ? '24ch' : '40ch'}
                overflow="hidden"
                text={subtitle}
                textOverflow="ellipsis"
                tx={subtitleTx}
                variant="bodySm"
                whiteSpace="nowrap"
              />
            </Box>
          </Box>

          {(icon || chipVariant || statusVariant) && (
            <Box flexAlign="center" flex gap={16}>
              {chipVariant && (
                <Chip
                  whiteSpace="nowrap"
                  variant={chipVariant}
                  tx={chipTx}
                  text={chipText}
                />
              )}

              {statusVariant && (
                <Status
                  variant={statusVariant}
                  tx={statusTx}
                  text={statusText}
                />
              )}

              {icon &&
                (onIconClick ? (
                  iconTooltipPlacement ? (
                    <Tooltip
                      placement={iconTooltipPlacement}
                      title={iconTooltipText}
                      titleTx={iconTooltipTx}
                    >
                      <IconButton
                        disabled={iconDisabled}
                        icon={icon}
                        onClick={onIconClick}
                      />
                    </Tooltip>
                  ) : (
                    <IconButton
                      disabled={iconDisabled}
                      icon={icon}
                      onClick={onIconClick}
                    />
                  )
                ) : (
                  <Icon color={iconColor} name={icon} />
                ))}
            </Box>
          )}
        </React.Fragment>
      )}
    </Styled.ListItem>
  );
}

export function List(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box role="list" flex flexDirection="column" {...rest}>
      {children}
    </Box>
  );
}
