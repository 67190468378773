import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  ActivityDraft,
  ActivityListItem,
  CoHostRequestListItem,
  FreeTextListItem,
  QrScanToken,
  TicketHistoryListItem,
  TicketListItem,
} from '../../models';

export const coHostingAdapter = createEntityAdapter<
  CoHostRequestListItem,
  string
>({
  selectId: (coHostRequestListItem) => coHostRequestListItem.cohostRequestKey,
});

export const draftAdapter = createEntityAdapter<ActivityDraft, string>({
  selectId: (draft) => draft.key,
  sortComparer: (a, b) => b.lastUpdated - a.lastUpdated,
});

export const activityListItemAdapter = createEntityAdapter<
  ActivityListItem,
  string
>({
  selectId: (activityListItem) => activityListItem.activityKey,
});

export const qrScanTokensAdapter = createEntityAdapter<QrScanToken, string>({
  selectId: (qrScanToken) => qrScanToken.qrScanTokenKey,
});

export const freetextListItemsAdapter = createEntityAdapter<
  FreeTextListItem,
  string
>({
  selectId: (freetextListItem) => freetextListItem.freetextListItemKey,
});

export const ticketsAdapter = createEntityAdapter<TicketListItem, string>({
  selectId: (ticketListItem) => ticketListItem.ticketKey,
});

export const ticketsHistoryAdapter = createEntityAdapter<
  TicketHistoryListItem,
  string
>({
  selectId: (ticketHistoryListLitem) =>
    ticketHistoryListLitem.ticketHistoryListItemKey,
});
