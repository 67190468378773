import * as _activities from './activities.endpoints';
import * as categories from './categories';
import * as listed from './listed';
import * as stats from './stats';

export const activities = {
  ..._activities,
  categories,
  listed,
  stats,
};
