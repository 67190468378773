import { Button } from '@orbiapp/components';

import {
  UndoScanTicketSelector,
  undoScanTicketThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';

export function UndoScanButton() {
  const undoScanStatus = useSelector(UndoScanTicketSelector.selectStatus);

  const dispatch = useDispatch();

  const undoScanTicket = () => {
    dispatch(undoScanTicketThunk());
  };

  return (
    <Button
      isLoading={undoScanStatus === 'pending'}
      onClick={undoScanTicket}
      mx="auto"
      tx="label.scan-qr-codes.scan-result-card.undo"
      variant="destructive"
    />
  );
}
