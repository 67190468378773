import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const UnverifiedStatus = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.statusInfoBackground};
    `;
  }}
`;

export const Styled = { UnverifiedStatus };
