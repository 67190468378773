interface RemoveTicketError {
  code: 0;
  message: string;
  metadata: {
    reservedTicketCount: number;
    ticketTypeKey: string;
  };
}

interface ReduceTicketCountError {
  code: 1;
  message: string;
  metadata: {
    availableTicketCount: number;
    ticketTypeKey: string;
  };
}

interface UpdateActivityError {
  code: null;
  message: string;
  metadata: null;
}

export type UpdateActivityErrorResponse =
  | string
  | RemoveTicketError
  | ReduceTicketCountError
  | UpdateActivityError
  | undefined;

export namespace LinkErrors {
  export const url = {
    'string.uri': 'errors.url.invalid',
    'string.empty': 'errors.url.invalid',
    'string.max': 'errors.url.max',
  };

  export const label = {
    'string.empty': 'errors.url-label.empty',
    'string.max': 'errors.url-label.max',
  };
}

export namespace ActivityErrors {
  export const customErrors = {
    ticketCountGreaterThanMax: 'total',
  };

  export const coverImage = {
    'any.required': 'errors.cover-image.empty',
    'object.base': 'errors.cover-image.empty',
    'any.only': 'errors.cover-image.empty',
  };

  export const description = {
    'string.empty': 'errors.description.empty',
  };

  export const segmentation = {
    'array.min': 'errors.segmentation.empty',
    'array.includesRequiredUnknowns': 'errors.segmentation.empty',
  };

  export const title = {
    'string.empty': 'errors.title.empty',
  };

  export const endDate = {
    'number.min': 'errors.end-date.min',
    'number.base': 'errors.end-date.min',
  };

  export const startDate = {
    'number.min': 'errors.start-date.min',
    'number.base': 'errors.start-date.min',
  };

  export const ticketTypes = {
    total: 'errors.max-tickets.total',
    'array.min': 'errors.tickets.empty',
  };

  export const updateTicketType = {
    total: 'errors.max-tickets.total',
    'array.min': 'errors.tickets.empty',
  };

  export const tag = {
    'string.empty': 'errors.tag.empty',
    'string.pattern.base': 'errors.tag.invalid',
    'string.alphanum': 'errors.tag.invalid',
    'string.max': 'errors.tag.max',
  };

  export const tags = {
    'array.min': 'errors.tags.empty',
    'string.duplicate': 'errors.tags.duplicate',
  };

  export const categories = {
    'array.min': 'errors.categories.empty',
    'array.max': 'errors.categories.max',
  };
}
