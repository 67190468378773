import { constructQueryURL } from '@orbiapp/components';

import {
  ANDROID_PACKAGE_NAME,
  IOS_APP_STORE_ID,
  IOS_BUNDLE_ID,
  firebaseConfig,
} from '../config';

interface Suffix {
  option: 'SHORT' | 'UNGUESSABLE';
}

interface AndroidInfo {
  androidPackageName?: string;
  androidFallbackLink?: string;
  androidMinPackageVersionCode?: string;
}

interface DesktopInfo {
  desktopFallbackLink: string;
}

interface IosInfo {
  iosBundleId?: string;
  iosFallbackLink?: string;
  iosCustomScheme?: string;
  iosIpadFallbackLink?: string;
  iosIpadBundleId?: string;
  iosAppStoreId?: string;
}

interface NavigationInfo {
  enableForcedRedirect: boolean;
}

interface GooglePlayAnalytics {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
}

interface ItunesConnectAnalytics {
  at?: string;
  ct?: string;
  mt?: string;
  pt?: string;
}

interface AnalyticsInfo {
  googlePlayAnalytics?: GooglePlayAnalytics;
  itunesConnectAnalytics?: ItunesConnectAnalytics;
}

interface SocialMetaTagInfo {
  socialTitle?: string;
  socialDescription?: string;
  socialImageLink?: string;
}

interface DynamicLinkInfo {
  domainUriPrefix: string;
  link: string;
  androidInfo: AndroidInfo;
  desktopInfo: DesktopInfo;
  iosInfo: IosInfo;
  navigationInfo?: NavigationInfo;
  analyticsInfo?: AnalyticsInfo;
  socialMetaTagInfo: SocialMetaTagInfo;
}

interface DynamicLinkInfoOptions {
  dynamicLinkInfo: DynamicLinkInfo;
  suffix: Suffix;
}

const ANDROID_INFO: AndroidInfo = {
  androidPackageName: ANDROID_PACKAGE_NAME,
};

const DESKTOP_INFO: DesktopInfo = {
  desktopFallbackLink: 'https://orbiapp.io/app-required',
};

const DOMAIN_URI_PREFIX = `https://${firebaseConfig.dynamicLinkPath}`;

const IOS_INFO: IosInfo = {
  iosAppStoreId: IOS_APP_STORE_ID,
  iosBundleId: IOS_BUNDLE_ID,
};

const SOCIAL_META_TAG_INFO: SocialMetaTagInfo = {
  socialTitle: 'Orbi',
  socialDescription: 'Welcome to Orbi!\nPlease open the link to continue.',
};

const SUFFIX: Suffix = {
  option: 'SHORT',
};

export const getDynamicLinkOptions = (
  queryParams: Record<string, string>,
  socialMetaTagInfo: SocialMetaTagInfo = SOCIAL_META_TAG_INFO,
): DynamicLinkInfoOptions => {
  const link = encodeURI(
    constructQueryURL(`https://${firebaseConfig.dynamicLinkPath}`, queryParams),
  );

  const dynamicLinkInfo: DynamicLinkInfo = {
    androidInfo: ANDROID_INFO,
    desktopInfo: DESKTOP_INFO,
    domainUriPrefix: DOMAIN_URI_PREFIX,
    iosInfo: IOS_INFO,
    link,
    socialMetaTagInfo,
  };

  const dynamicLinkOptions: DynamicLinkInfoOptions = {
    suffix: SUFFIX,
    dynamicLinkInfo,
  };

  return dynamicLinkOptions;
};
