import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { StyledTabButtonProps } from './tab-button.types';

const TabButton = styled.button<StyledTabButtonProps>`
  ${(props) => {
    const { theme, disabled, isActive, isError, onClick, to } = props;

    const { fontSize, letterSpacing, fontFamily, lineHeight } =
      theme.textVariants.buttonSm;

    const tabButtonStyles = css`
      ${baseComponentStyles};

      dispaly: flex;
      align-items: center;
      justify-content: center;

      font-size: ${fontSize}px;
      letter-spacing: ${letterSpacing}px;
      line-height: ${lineHeight}px;
      font-family: ${fontFamily};

      text-decoration: none;
      border-radius: 100px;
      padding: 4px 12px;
      width: fit-content;

      ${(onClick || to) &&
      !disabled &&
      css`
        cursor: pointer;

        :hover {
          background-color: ${theme.colors.tabButtonBackgroundHover};
          border: 1px solid ${theme.colors.tabButtonBorderHover};
          color: ${theme.colors.tabButtonLabelHover};
        }

        :active {
          transform: scale(0.96);

          background-color: ${theme.colors.tabButtonBackgroundActive};
          border: 1px solid ${theme.colors.tabButtonBorderActive};
          color: ${theme.colors.tabButtonLabelActive};
        }
      `}
    `;

    if (disabled) {
      return css`
        ${tabButtonStyles};

        pointer-events: none;
        color: ${theme.colors.tabButtonLabelDisabled};
        border: 1px solid ${theme.colors.tabButtonBorderDisabled};
        background-color: ${theme.colors.tabButtonBackgroundDisabled};
      `;
    }

    if (isError) {
      if (isActive) {
        return css`
          ${tabButtonStyles};

          background-color: ${theme.colors.tabButtonErrorToggledBackground};
          border: 1px solid ${theme.colors.tabButtonErrorToggledBorder};
          color: ${theme.colors.tabButtonErrorToggledLabel};
        `;
      }

      return css`
        ${tabButtonStyles};

        background-color: ${theme.colors.tabButtonErrorBackground};
        border: 1px solid ${theme.colors.tabButtonErrorIcon};
        color: ${theme.colors.tabButtonErrorLabel};
      `;
    }

    if (isActive) {
      return css`
        ${tabButtonStyles};

        background-color: ${theme.colors.tabButtonBackgroundToggled};
        border: 1px solid ${theme.colors.tabButtonBorderToggled};
        color: ${theme.colors.tabButtonLabelToggled};
      `;
    }

    return css`
      ${tabButtonStyles};

      background-color: ${theme.colors.tabButtonBackground};
      border: 1px solid ${theme.colors.tabButtonBorder};
      color: ${theme.colors.tabButtonLabel};
    `;
  }}
`;

export const Styled = { TabButton };
