import { QrScanToken } from '../../../../../../models';
import { QrScanTokenDto } from './qr-scan-tokens.dto';

export const qrScanTokenMapper = (
  qrScanTokenDto: QrScanTokenDto,
): QrScanToken => ({
  createdAt: qrScanTokenDto.createdAt,
  qrScanTokenKey: qrScanTokenDto.qrScanTokenKey,
  url: qrScanTokenDto.url,
});
