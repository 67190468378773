import {
  Box,
  Button,
  ControlledSelect,
  ControlledTextarea,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalSubtitle,
  ModalTitle,
  Select,
  Switch,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm, POST_MESSAGE_MAX_LENGTH } from '../../../models';
import {
  MembershipTypeListItemsSelector,
  getMembershipsTypesThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { getOptionalLabelText } from '../../../utils';
import { UploadFiles, UploadedImages } from '../components';
import { CreatePostFormProps } from '../create-post-modal.types';

function PickMembershipSelect() {
  const membershipTypesStatus = useSelector(
    MembershipTypeListItemsSelector.selectStatus,
  );
  const membershipTypes = useSelector(
    MembershipTypeListItemsSelector.selectAll,
  );

  if (!membershipTypes?.length) {
    return (
      <Select
        disabled={membershipTypesStatus === 'pending'}
        labelTx="label.posts.create-post-modal.membership"
        value=""
        onChange={() => {}}
        options={[
          {
            notPickable: true,
            tx: 'placeholder.no-memberships',
            value: ' ',
          },
        ]}
        trailingElements={[
          {
            type: 'spinner',
            hidden: membershipTypesStatus !== 'pending',
            size: 16,
          },
        ]}
      />
    );
  }

  return (
    <ControlledSelect
      name="target.key"
      menuMaxHeight={240}
      labelTx="label.posts.create-post-modal.membership"
      options={membershipTypes
        .map((membershipType) => ({
          text: membershipType.name,
          value: membershipType.membershipTypeKey,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))}
    />
  );
}

function NotificationsSelect() {
  const formContext = useFormContext<CreatePostForm>();

  const pushTo = formContext.watch('pushTo');

  const togglePushNotifications = () => {
    formContext.setValue(
      'pushTo',
      pushTo === 'active_members' ? null : 'active_members',
    );
  };

  return (
    <Box
      p={8}
      gap={16}
      flex
      flexDirection="column"
      backgroundColor="backgroundTeritary"
      r={8}
    >
      <Switch
        text={getOptionalLabelText(
          'label.posts.create-post-modal.send-push-notification',
        )}
        subtitleTx="label.posts.create-post-modal.members"
        onClick={togglePushNotifications}
        checked={pushTo === 'active_members'}
      />
    </Box>
  );
}

export function MembershipPostForm(props: CreatePostFormProps) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMembershipsTypesThunk());
  }, [dispatch]);

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle tx="label.posts.create-post-modal.new-membership-post" />
        <ModalSubtitle
          mt={16}
          tx="label.posts.create-post-modal.membership-post-subtitle"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <PickMembershipSelect />

        <ControlledTextarea
          name="message"
          labelTx="label.posts.create-post-modal.message"
          maxLength={POST_MESSAGE_MAX_LENGTH}
        />

        <NotificationsSelect />

        <UploadedImages />
      </ModalBodyContainer>

      <ModalFooterContainer>
        <UploadFiles />

        <Box gap={16} flexJustify="end" flex>
          <Button
            tx="button.cancel"
            variant="tertiary"
            onClick={props.closeAndReset}
          />

          <Button
            tx="label.posts.create-post-modal.post"
            variant="primary"
            onClick={props.createPost}
            isLoading={props.isLoading}
          />
        </Box>
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
